import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Autenticar from './Autenticar';
import Alerts from '../helpers/Alerts';

export default function Login(props) {

  const [carregarCardLogin, setCarregarCardLogin] = useState(false);

  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const autenticado = props.autenticado;
  const usuario = props.usuario;
  const handleAutenticadoChange = props.handleAutenticadoChange;

  let redirectTo = "/";

  if(location.state?.referrer?.pathname != null && location.state?.referrer?.pathname !== "/login" && location.state?.referrer?.pathname !== "/logout"){
    redirectTo = location.state.referrer.pathname;
  }
  if(location.state?.referrer?.search != null && location.state?.referrer?.search !== ""){
    redirectTo = redirectTo + location.state?.referrer?.search;
  }


  const limparInterface = () => {
    setLogin("");
    setSenha("");
  }

  useEffect(() => {
    window.$('body').addClass('login-page'); // adminlte.css
    // console.log('location', props.location);
    // console.log('redirectTo', redirectTo);
    document.title = 'Login - Sistema de Controle Interno';
    
    return () => {
      window.$('body').removeClass('login-page');
      document.title = 'Sistema de Controle Interno';
    }
  }, []);


  useEffect(() => {
    if (autenticado && (usuario != null)) {
      navigate(redirectTo, { replace: true });
    } else {
      if(parseInt(Autenticar.getRefreshToken() && (Autenticar.getTokenExpirationDate() - Date.now()) <= 3600000)){
        Autenticar.refreshToken(Autenticar.getRefreshToken())
              .catch(erro => {
                handleAutenticadoChange(false, {});
                Autenticar.clearLocalToken();
                Alerts.erro("Realize o login novamente.", "alertPlaceholder");
                console.log(erro);
          setCarregarCardLogin(true);

              })
      }
      if(Autenticar.getLocalToken()){
        Autenticar.getUsuarioFromToken(Autenticar.getLocalToken())
          .then(data => {
            handleAutenticadoChange(true, data);
            navigate(redirectTo, { replace: true });
          })
          .catch(erro => {
            handleAutenticadoChange(false, {});
            Autenticar.clearLocalToken();
            Alerts.erro("Realize o login novamente.", "alertPlaceholder");
            console.log(erro);
            setCarregarCardLogin(true);
          })
        
      } else {
        setCarregarCardLogin(true);
      }
      
    }
  }, [autenticado, usuario, handleAutenticadoChange, redirectTo, navigate]);

  const logar = (e) => {
    e.preventDefault();
    e.stopPropagation();

    Autenticar.getToken(login, senha)
        .then(data => {
            Autenticar.getUsuarioFromToken(data.access_token)
              .then(data => {
                props.handleAutenticadoChange(true, data);
                navigate(redirectTo, { replace: true });

              })
              .catch(erro => {
                props.handleAutenticadoChange(false, {});
                console.log('erro', erro)
              })
            
        })
        .catch(erro => {
            console.log('erro', erro);
            limparInterface();
            Alerts.erro("Falha no login. Tente Novamente.", "alertPlaceholder");
        })
    
  }

  return (
    <>
      <div className="login-box mt-5 pt-5">
        <div id="alertPlaceholder"></div>
        { carregarCardLogin ? 
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <img src="/logo_controladoria.png" className="login-box-msg" height="100%" width="100%" alt="Logo Controladoria"/>
            </div>
            <div className="card-body">
              <p className="login-box-msg">Insira Usuário e Senha para iniciar</p>
              <form onSubmit={logar}>
                <div className="input-group mb-3">
                  <input type="login-box" className="form-control" placeholder="Usuário" onChange={(event)=>setLogin(event.target.value)} value={login} maxLength={150}/>
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-user" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input type="password" className="form-control" placeholder="Senha" onChange={(event)=>setSenha(event.target.value)} value={senha} maxLength={150}/>
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-center">
                  <div className="col-4">
                    <button type="button" onClick={limparInterface} className="btn btn-primary btn-block">Limpar</button>
                  </div>
                  <div className="col-4">
                    <button type="submit" className="btn btn-primary btn-block">Entrar</button>
                  </div>
                </div>
              </form>
              <br />
              <p className="mb-1">
                <a href="forgot-password.html">Esqueceu a senha?</a>
              </p>
              <p className="mb-0">
                <a href="register.html" className="text-center">Ajuda</a>
              </p>
            </div>
          </div> :
          <></>
        }
      </div>
    </>
  )
}
