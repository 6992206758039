import { Buffer } from "buffer";

class Autenticar {

    constructor(){
        this.autenticado = false;
    }

    getToken(login, senha) {
        return new Promise((resolve, reject) => {
            // const dados = {
            //     "username": login,
            //     "password": senha,
            //     "grant_type": "password"
                
            // }

            // const auth = "Basic " + new Buffer("congem:senha").toString("base64");
            const auth = "Basic " + Buffer.from("congem:senha", "utf8").toString("base64");
            // console.log('auth', auth);

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': auth
                },
                body: new URLSearchParams({
                    "username": login,
                    "password": senha,
                    "grant_type": "password"
                })
            };
    
            const url = window.servidor + '/oauth/token'

            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if(data.access_token === undefined){
                        if(data.error === undefined){
                            // console.error("Credenciais inválidas.");
                            throw new Error('Credenciais inválidas.')
                            
                        }
                        else{
                            // console.error(data.error + ": " + data.error_description);
                            throw new Error(data.error_description);
                        }
                    }
                    else {
                        this.setAutenticado(true);
                        let expiration_date = Date.now() + data.expires_in*1000;
                        // console.log('expiration_date', expiration_date);
                        // console.log('Date.now()', Date.now());
                        // console.log('data.expires_in', data.expires_in);
                        // console.log('expiration_date - Date.now()', expiration_date - Date.now());

                        localStorage.setItem("access_token", data.access_token);
                        localStorage.setItem("expires_in", data.expires_in);
                        localStorage.setItem("refresh_token", data.refresh_token);
                        localStorage.setItem("expiration_date", expiration_date);
                        resolve(data);
                    }
                })
                .catch(erro => {
                    reject(erro);
                });

        });
    }

    refreshToken(refreshToken) {
        return new Promise((resolve, reject) => {

            // const auth = "Basic " + new Buffer("programacaojava:java").toString("base64");
            const auth = "Basic " + Buffer.from("congem:senha", "utf8").toString("base64");

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': auth
                },
                body: new URLSearchParams({
                    "refresh_token": refreshToken,
                    "grant_type": "refresh_token"
                })
            };
    
            const url = window.servidor + '/oauth/token'
            // console.log('refreshToken', refreshToken)

            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    // console.log('data', data);
                    // console.log('access_token', data.access_token);
                    if(data.access_token === undefined){
                        if(data.error === undefined){
                            // console.error("Falha ao renovar token.");
                            throw new Error('Falha ao tentar renovar token.');

                        }
                        else{
                            // console.error(data.error + ": " + data.error_description);
                            throw new Error(data.error_description);

                        }
                    }
                    else {
                        let expiration_date = Date.now() + data.expires_in*1000;
                        
                        localStorage.setItem("access_token", data.access_token);
                        localStorage.setItem("expires_in", data.expires_in);
                        localStorage.setItem("refresh_token", data.refresh_token);
                        localStorage.setItem("expiration_date", expiration_date);
                        resolve(data);
                    }
                    
                })
                .catch(erro => {
                    reject(erro);
                });

        });
    }

    getUsuarioFromToken(token) {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            };

            const url = window.servidor + '/v1/protegido/usuario/me'

            fetch(url, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if(data.error === undefined){
                        this.autenticado = true;
                        // console.log('usuario', data);
                        resolve(data);
                    }
                    else{
                        // console.error(data.error + ": " + data.error_description);
                        throw new Error(data.error_description);

                    }
                })
                .catch(erro => {
                    this.autenticado = false;
                    // console.log('erro', erro);
                    reject(erro);
                });

        });
    }

    getLocalToken() {
        return localStorage.getItem("access_token")
    }

    getTokenExpirationDate() {
        return localStorage.getItem("expiration_date");
    }

    getRefreshToken() {
        return localStorage.getItem("refresh_token");
    }

    clearLocalToken() {
        localStorage.removeItem("access_token");
        localStorage.removeItem("expires_in");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("expiration_date");
    }

    estaAutenticado(){
        return this.autenticado;
    }

    setAutenticado(bool){
        this.autenticado = bool;
    }

}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new Autenticar();