import Requisicao from "../../helpers/Requisicao";
import Utilidades from "../../helpers/Utilidades";
import analisePDF from "../analises/AnalisePDF";


async function ModeloPDF(modeloId, usuario) {

  const modelo = await Requisicao.getModeloPorId(modeloId);
  const unidadeImprimir = await Requisicao.getUnidadeById(1);

  let m = modelo;

  let a = {
    processoId: null,
    unidadeOrigemId: null,
    dataAnalise: null,
    leiLicitacao: m.leiLicitacao,
    modalidadeLicitacao: null,
    tipoAnalise: m.tipoDaAnalise,
    valorEstimado: null,
    filaAnalise: "EM_ANALISE",
    statusAnalise: "EM_EDICAO",
    desativada: false,
    isModeloSet: false,
    unidadeResponsavelId: null,
  };
  a.nome = m.nome;
  a.textoInicial = m.textoInicial;
  a.textoFinal = m.textoFinal;
  a.textoInicialExtra = "";
  a.textoInicialExtraHTML = "";
  a.textoFinalExtra = "";
  a.textoFinalExtraHTML = "";
  a.textoEncaminhamento = "";
  a.textoEncaminhamentoHTML = "";

  a.dataAnalise = null;
  a.dataRevisao = null;
  a.observacaoTextoInicialExtra = "";
  a.observacaoTextoFinalExtra = "";

  m = Utilidades.removerIdsBlocosModelo(m);
  m = Utilidades.ordenarBlocosCamposModelo(m);

  let blocosIniciais = m.modelosBlocos;
  blocosIniciais.forEach((bloco) => {
    bloco.recomendacoes = [];
  });
  a.blocos = blocosIniciais;

  let campos = [];
  m.camposComplementares.forEach((modeloCampo) => {
    let campo = {};
    campo.id = null;
    campo.ordem = modeloCampo.ordem;
    campo.nome = modeloCampo.nome;
    campo.tipo = modeloCampo.tipo;
    campo.infoAdicional = modeloCampo.infoAdicional;
    campo.observacao = modeloCampo.observacao;
    campos.push(campo);
  });
  a.camposComplementares = campos;
  a.isModeloSet = true;


  if (a.blocos != null && a.blocos.length > 0) {
    a.blocos.sort(Utilidades.sortPorOrdem);

    a.blocos.forEach((bloco) => {
      if (bloco.filhos != null && bloco.filhos.length > 0) {
        bloco.filhos.sort(Utilidades.sortPorOrdem);
        bloco.filhos.forEach((filho) => {
          if (filho.netos != null && filho.netos.length > 0) {
            filho.netos.sort(Utilidades.sortPorOrdem);
          }
        });
      }
      if (bloco.recomendacoes != null && bloco.recomendacoes.length > 0) {
        bloco.recomendacoes.sort(Utilidades.sortPorOrdem);
      }
    });

  }

  a.camposComplementares.sort(Utilidades.sortPorOrdem);

  console.log('a', a);

  let processo = {
    orgaoSolicitante: "CONTROLADORIAGERAL",
    numero: "",
    volumeseApensos: "",
    objeto: "",
  }

  unidadeImprimir.nomeOrdenadorDespesas = '';
  unidadeImprimir.matriculaOrdenadorDespesas = '';

  analisePDF(a, processo, unidadeImprimir, usuario);

}

export default ModeloPDF;
