// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select2-sm .select2-container {
    height: calc(1.5em + 0.5rem + 2px) !important;
    padding: 0 0;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.select2-sm .select2-container > .selection {
    height: calc(1.5em + 0.5rem + 2px) !important;
    padding: 0 0;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.select2-sm .select2-container > .selection > :first-child {
    height: calc(1.5em + 0.5rem + 2px) !important;
    padding: 0 0;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.select2-sm .select2-selection__rendered {
    height: calc(1.5em + 0.5rem + 2px) !important;
}


`, "",{"version":3,"sources":["webpack://./src/modules/pages/contratos/Contratos.css"],"names":[],"mappings":"AAAA;IACI,6CAA6C;IAC7C,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,6CAA6C;IAC7C,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,6CAA6C;IAC7C,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,6CAA6C;AACjD","sourcesContent":[".select2-sm .select2-container {\n    height: calc(1.5em + 0.5rem + 2px) !important;\n    padding: 0 0;\n    font-size: 0.875rem;\n    line-height: 1.5;\n    border-radius: 0.2rem;\n}\n\n.select2-sm .select2-container > .selection {\n    height: calc(1.5em + 0.5rem + 2px) !important;\n    padding: 0 0;\n    font-size: 0.875rem;\n    line-height: 1.5;\n    border-radius: 0.2rem;\n}\n\n.select2-sm .select2-container > .selection > :first-child {\n    height: calc(1.5em + 0.5rem + 2px) !important;\n    padding: 0 0;\n    font-size: 0.875rem;\n    line-height: 1.5;\n    border-radius: 0.2rem;\n}\n\n.select2-sm .select2-selection__rendered {\n    height: calc(1.5em + 0.5rem + 2px) !important;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
