import Tippy from '@tippyjs/react';
import React from 'react'
import CampoNeto from './CampoNeto';

// let nextId = 1;
// let setupInicial = true;

export default function CampoFilho(props) {
  const filho = props.filho;
  const onDeleteFilho = props.onDeleteFilho;
  const onChangeFilho = props.onChangeFilho;
  const ordem = filho.ordem;
  const netos = filho.netos ?? [];
  const moveUp = props.moveUp;
  const moveDown = props.moveDown;
  // const netos = filho.netos;

  // useEffect(()=>{
  //   if(setupInicial && netos?.length > 0){
  //     for (let i = 0; i < netos?.length; i++) {
  //       if(netos[i].ordem >= nextId){
  //         nextId = netos[i].ordem + 1;
  //       }
  //       console.log('netos['+i+'].ordem', netos[i].ordem)
  //       console.log('neto nextId', nextId)
  //     }
  //     setupInicial = false;
  //     console.log('netos nextId', nextId)
  //   }
  // }, [netos]);

  const handleIdChange = (e) => {
    onChangeFilho({...filho, idCampo: e.target.value});
  }

  const handleTextoChange = (e) => {
    onChangeFilho({...filho, texto: e.target.value});
  }

  const adicionarCampoNeto = () => {
    // onChangeFilho({
    //   ...filho,
    //   netos: [
    //     ...netos, {
    //       ordem: nextId++,
    //       idCampo: "",
    //       texto:""
    //     }
    //   ]
    // });

    let nextOrdem = 1;
    if(netos?.length > 0){
      for (let i = 0; i < netos?.length; i++) {
        if(netos[i].ordem >= nextOrdem){
          nextOrdem = netos[i].ordem + 1;
        }
      }
    }
    onChangeFilho({
      ...filho,
      netos: [
        ...netos, {
          ordem: nextOrdem,
          idCampo: "",
          texto:""
        }
      ]
    });
  }

  const alterarCampoNeto = (neto) => {
    onChangeFilho({
      ...filho,
      netos: netos.map((n) => {
        if(n.ordem === neto.ordem){
          return neto;
        } else {
          return n;
        }
      })
    });
  }

  const deletarCampoNeto = (ordem) => {
    onChangeFilho({
      ...filho,
      netos: netos.filter((n) => parseInt(n.ordem) !== ordem)
    });
  }

  const swapArrayElements = (arr, indexA, indexB) => {
    var temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
    return arr;
  };

  const moverNetoParaCima = (f) => {
    if (netos != null && netos?.length > 0) {
      let gkids = netos;

      if (gkids[0]?.ordem !== f?.ordem) {
        let index = null;
        for (let i = 0; i < gkids.length; i++) {
          if (gkids[i]?.ordem === f?.ordem) {
            index = i;
            break;
          }
        }
        if (index != null && index !== 0) {
          let temp = gkids[index].ordem;
          gkids[index].ordem = gkids[index - 1].ordem;
          gkids[index - 1].ordem = temp;
          gkids = swapArrayElements(gkids, index, index - 1);
          onChangeFilho({
            ...filho,
            netos: gkids,
          });
        }
      }
    }
  }

  const moverNetoParaBaixo = (f) => {
    if (netos != null && netos?.length > 0) {
      let gkids = netos;

      if (gkids[gkids.length - 1]?.ordem !== f?.ordem) {
        let index = null;
        for (let i = 0; i < gkids.length; i++) {
          if (gkids[i]?.ordem === f?.ordem) {
            index = i;
            break;
          }
        }
        if (index != null && index !== (gkids.length - 1)) {
          let temp = gkids[index].ordem;
          gkids[index].ordem = gkids[index + 1].ordem;
          gkids[index + 1].ordem = temp;
          gkids = swapArrayElements(gkids, index, index + 1);
          onChangeFilho({
            ...filho,
            netos: gkids,
          });
        }
      }
    }
  }

  return (
    <tr>
      <td>
        <input type="text" className="form-control" placeholder="ID" value={filho.idCampo} onChange={handleIdChange}/>
      </td>
      <td>
        <div className='d-flex flex-row'>
          <textarea rows={1} className="form-control" placeholder="Digite o item da manifestação quanto a análise." value={filho.texto} onChange={handleTextoChange}/>
          <Tippy content="Mover para cima">
            <button className="btn text-info" type='button' onClick={()=>{moveUp(filho)}}><span className='fas fa-chevron-up'></span></button>
          </Tippy>
          <Tippy content="Mover para baixo">
            <button className="btn text-info" type='button' onClick={()=>{moveDown(filho)}}><span className='fas fa-chevron-down'></span></button>
          </Tippy>
          <Tippy content="Deletar">
            <button className="btn text-danger" onClick={() => {
              if(window.confirm("Deseja excluir o campo?") === true){
                onDeleteFilho(ordem);
              }
            }} type='button'><span className='fa fa-trash'></span></button>
          </Tippy>
        </div>
        <div className='d-flex flex-column'>
        {
          netos && netos.map((neto) => (
            <CampoNeto key={neto.ordem} neto={neto} 
              onDeleteNeto={deletarCampoNeto} onChangeNeto={alterarCampoNeto} 
              moveUp={moverNetoParaCima} moveDown={moverNetoParaBaixo} 
            ></CampoNeto>
          ))
        }
        </div>
      </td>
      <td className='text-center'>
        <Tippy content="Adicionar subcampo">
          <button type='button' className="btn text-primary" onClick={() => adicionarCampoNeto()}>
            <i className="fa fa-plus" />
          </button>
        </Tippy>
      </td>
    </tr>
  )
}
