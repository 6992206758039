import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Autenticar from '../../login/Autenticar';
import "react-datepicker/dist/react-datepicker.css";
import LoadingElement from '../../components/LoadingElement';
import { isValid, parseISO } from 'date-fns';
import DatePicker from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import Requisicao from '../../helpers/Requisicao';
import Utilidades from '../../helpers/Utilidades';
import EnumUtils from '../../helpers/EnumUtils';
import './Contratos.css'


export default function PesquisaContrato({ usuario, autenticado }) {

  const [searchParams, setSearchParams] = useSearchParams();
  const orgaoParam = searchParams.get("orgao") ?? "";
  const tipoAquisicaoParam = searchParams.get("tipoAquisicao") ?? "";
  const subtipoAquisicaoParam = searchParams.get("subtipoAquisicao") ?? "";

  const [subtipoParamSet, setSubtipoParamSet] = useState(false);

  const [numeroContrato, setNumeroContrato] = useState(searchParams.get("numeroContrato") ?? "");
  const [sufixoNumeroContrato, setSufixoNumeroContrato] = useState(searchParams.get("sufixoNumeroContrato") ?? "");
  const [numeroProcesso, setNumeroProcesso] = useState(searchParams.get("numeroProcesso") ?? "");
  const [numeroLicitacao, setNumeroLicitacao] = useState(searchParams.get("numeroLicitacao") ?? "");
  const [orgao, setOrgao] = useState("");
  const [objeto, setObjeto] = useState(searchParams.get("objeto") ?? "");
  const [empresa, setEmpresa] = useState(searchParams.get("empresa") ?? "");

  const [dataTermino, setDataTermino] = useState(null);
  const dataTerminoParam = searchParams.get("dataTermino") ?? "";
  const [comparadorDataTermino, setComparadorDataTermino] = useState(searchParams.get("comparadorDataTermino") ?? "eq");


  const [listaOrgaos, setListaOrgaos] = useState([]);
  const [listaTipoAditivoContrato, setListaTipoAditivoContrato] = useState([]);
  const [listaTipoAquisicao, setListaTipoAquisicao] = useState([]);
  const [listaSubtipoAquisicao, setListaSubtipoAquisicao] = useState([]);

  const [tipoAquisicao, setTipoAquisicao] = useState("-1");
  const [subtipoAquisicao, setSubtipoAquisicao] = useState("-1");


  const [resultado, setResultado] = useState({});

  const [pageSize, setPageSize] = useState("10");

  const [nenhumEncontrado, setNenhumEncontrado] = useState(false);

  const [pesquisando, setPesquisando] = useState(false);

  useEffect(() => {
    window.$(".select2bs4").select2({
      theme: "bootstrap4",
    });
    window.$("#comboboxListaOrgaos").on('change', function (e) {
      setOrgao(e.target.value);
    });
    window.$('#changePageModal').on('hidden.bs.modal', function (e) {
      document.getElementById("changePageModalInput").value = "";
    })
    window.$("#comboboxTipoAquisicao").on('change', function (e) {
      e.target?.classList.remove("is-invalid");
      setTipoAquisicao(e.target.value);
      setSubtipoAquisicao("-1");
    });
    window.$("#comboboxSubtipoAquisicao").on('change', function (e) {
      setSubtipoAquisicao(e.target.value);
    });
    carregarListaOrgaos();
    carregarListaTipoAditivoContrato();
    carregarListaTipoESubtipoAquisicao();

    document.title = 'Pesquisar Contratos - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
  }, []);

  useEffect(() => {
    if (searchParams != null && searchParams.toString() !== "") {
      if (searchParams.has("s") && searchParams.get("s") !== "") {
        setPageSize(searchParams.get("s"));
      }
      pesquisar();
    }
    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    if (dataTerminoParam != null && dataTerminoParam !== "" && isValid(parseISO(dataTerminoParam))) {
      setDataTermino(parseISO(dataTerminoParam));
    }
  }, [dataTerminoParam]);

  const getPagination = () => {
    let totalPaginas = parseInt(resultado.totalPaginas) ?? 0;
    let pag = []

    if (parseInt(resultado.numeroPagina) === 0) {
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Primeira</button></li>);
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Anterior</button></li>);
    } else {
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(0)}>Primeira</button></li>);
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado?.numeroPagina) - 1)}>Anterior</button></li>);
    }

    // for
    if (totalPaginas >= 9) {
      if (parseInt(resultado.numeroPagina) >= 5) {
        pag.push(<li className="page-item"><button type="button" className="page-link" data-toggle="modal" data-target="#changePageModal">...</button></li>);
      }

      for (let i = Math.max(parseInt(resultado.numeroPagina) - 4, 0); i < parseInt(resultado.numeroPagina); i++) {
        pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
      }

      pag.push(<li className="page-item active"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado.numeroPagina))}>{parseInt(resultado.numeroPagina) + 1}</button></li>);

      for (let i = parseInt(resultado.numeroPagina) + 1; i <= parseInt(resultado.numeroPagina) + 4 && i < totalPaginas; i++) {
        pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
      }

      if ((totalPaginas - parseInt(resultado.numeroPagina)) >= 6) {
        pag.push(<li className="page-item"><button type="button" className="page-link" data-toggle="modal" data-target="#changePageModal">...</button></li>);
      }
    } else {
      for (let i = 0; i < totalPaginas; i++) {
        if (i === parseInt(resultado.numeroPagina)) {
          pag.push(<li className="page-item active"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
        } else {
          pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
        }
      }
    }

    if (parseInt(resultado?.numeroPagina) + 1 === parseInt(resultado?.totalPaginas)) {
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Próxima</button></li>);
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Última</button></li>);
    } else {
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado?.numeroPagina) + 1)}>Próxima</button></li>);
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado?.totalPaginas) - 1)}>Última</button></li>);
    }

    return pag;
  }

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
    let params = searchParams;
    params.set("p", "0");
    params.set("s", e.target.value);
    setSearchParams(params);
  }

  const handleChangePage = (page) => {
    let params = searchParams;
    params.set("p", page);
    setSearchParams(params);
  }

  const getAllPesquisa = () => {
    let params = new URLSearchParams();

    if (numeroContrato != null && numeroContrato !== "") {
      params.set("numeroContrato", numeroContrato);
    }
    if (sufixoNumeroContrato != null && sufixoNumeroContrato !== "") {
      params.set("sufixoNumeroContrato", sufixoNumeroContrato);
    }
    if (numeroProcesso != null && numeroProcesso !== "") {
      params.set("numeroProcesso", numeroProcesso);
    }
    if (numeroLicitacao != null && numeroLicitacao !== "") {
      params.set("numeroLicitacao", numeroLicitacao);
    }
    if (orgao != null && orgao !== "" & orgao !== "-1") {
      params.set("orgao", orgao);
    }
    if (objeto != null && objeto !== "") {
      params.set("objeto", objeto);
    }
    if (empresa != null && empresa !== "") {
      params.set("empresa", empresa);
    }
    if (tipoAquisicao != null && tipoAquisicao !== "" & tipoAquisicao !== "-1") {
      params.set("tipoAquisicao", tipoAquisicao);
    }
    if (subtipoAquisicao != null && subtipoAquisicao !== "" & subtipoAquisicao !== "-1") {
      params.set("subtipoAquisicao", subtipoAquisicao);
    }

    if (dataTermino != null && dataTermino !== "") {
      params.set("dataTermino", dataTermino.toISOString().substring(0, 10));

      if (comparadorDataTermino != null && comparadorDataTermino !== "") {
        params.set("comparadorDataTermino", comparadorDataTermino);
      }
    }

    // console.log('params.toString()', params.toString())

    if(params?.toString() !== "" || (resultado?.elementos != null && resultado?.elementos instanceof Array && resultado?.elementos?.length > 0)){
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/contrato/pesquisa/all?" + params.toString();

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log('data', data)
          donwloadCSV(data);
        })
        .catch((erro) => console.log(erro));
    }
  }

  const pesquisar = () => {
    setNenhumEncontrado(false);
    setPesquisando(true);
    setResultado({});

    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/contrato/pesquisa?" + searchParams.toString();

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.totalElementos === 0 || data.totalElementos === null) {
          setNenhumEncontrado(true);
        }
        setPesquisando(false);
        console.log('data', data);
        setResultado(data);
      })
      .catch((erro) => console.log(erro));
  }

  const carregarListaOrgaos = () => {
    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/unidade/getorgaos";

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setListaOrgaos(data);
      })
      .catch((erro) => console.log(erro));
  };

  const carregarListaTipoAditivoContrato = () => {
    Requisicao.getListaTipoAditivoContrato().then((data) => setListaTipoAditivoContrato(data)).catch(erro => console.log('erro', erro));
  }
  
  const carregarListaTipoESubtipoAquisicao = () => {
    Requisicao.getListaTipoAquisicao().then((data) => setListaTipoAquisicao(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaSubtipoAquisicao().then((data) => setListaSubtipoAquisicao(data)).catch(erro => console.log('erro', erro));
  }

  // preencher comboboxListaOrgaos
  useEffect(() => {
    window.$("#comboboxListaOrgaos").empty();
    let combobox = document.querySelector("#comboboxListaOrgaos").options;

    combobox.add(new Option("Selecione a Secretaria Solicitante", "-1"));
    listaOrgaos.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    if (listaOrgaos != null && listaOrgaos.length > 0) {
      for (let i = 0; i < combobox.length; i++) {
        if (orgaoParam === combobox[i].value) {
          combobox.selectedIndex = i;
          setOrgao(orgaoParam);
        }
      }
    } else {
      combobox.selectedIndex = 0;
    }

  }, [listaOrgaos, orgaoParam]);

  // preencher comboboxTipoAquisicao
  useEffect(() => {
    window.$(`#comboboxTipoAquisicao`).empty();
    let combobox = document.querySelector(`#comboboxTipoAquisicao`).options;

    combobox.add(new Option("Selecione o tipo de aquisição...", "-1"));
    listaTipoAquisicao?.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    combobox[0].disabled = true;

    // combobox.selectedIndex = 0;

    // if (tipoAquisicao != null) {
    //   for (let i = 0; i < combobox.length; i++) {
    //     if (tipoAquisicao === combobox[i].value) {
    //       window.$(`#comboboxTipoAquisicao`).val(combobox[i].value).trigger("change");
    //     }
    //   }
    // }

    if (listaTipoAquisicao != null && listaTipoAquisicao.length > 0) {
      for (let i = 0; i < combobox.length; i++) {
        if (tipoAquisicaoParam === combobox[i].value) {
          combobox.selectedIndex = i;
          setTipoAquisicao(tipoAquisicaoParam);
          // setSubtipoAquisicao("-1");
        }
      }
    } else {
      combobox.selectedIndex = 0;
    }
  }, [listaTipoAquisicao, tipoAquisicaoParam]);

  // Preencher e atualizar comboboxSubtipoAquisicao sempre que comboboxTipoAquisicao muda
  useEffect(() => {
    window.$(`#comboboxSubtipoAquisicao`).empty();
    let combobox = document.querySelector(`#comboboxSubtipoAquisicao`).options;

    combobox.add(new Option("Selecione o subtipo da aquisição...", "-1"));
    // combobox.add(new Option("(Em Branco)", "-1"));
    listaSubtipoAquisicao?.forEach((element) => {
      if (tipoAquisicao === element.aquisicaoValue) {
        combobox.add(new Option(element.nome, element.value));
      }
    });

    // combobox[0].disabled = true;
    combobox.selectedIndex = 0;

    
    if (listaSubtipoAquisicao != null && listaSubtipoAquisicao.length > 0 && subtipoParamSet !== true) {
      for (let i = 0; i < combobox.length; i++) {
        if (subtipoAquisicaoParam === combobox[i].value) {
          combobox.selectedIndex = i;
          setSubtipoAquisicao(subtipoAquisicaoParam);
          setSubtipoParamSet(true);
        }
      }
    } else {
      combobox.selectedIndex = 0;
    }

    // if (subtipoAquisicao != null) {
    //   for (let i = 0; i < combobox.length; i++) {
    //     if (subtipoAquisicao === combobox[i].value) {
    //       window.$(`#comboboxSubtipoAquisicao`).val(combobox[i].value).trigger("change");
    //     }
    //   }
    // }

  }, [tipoAquisicao, listaSubtipoAquisicao, subtipoAquisicaoParam, subtipoParamSet]);

  // useEffect(() => {
  //   console.log('tipoAquisicao', tipoAquisicao)
  //   console.log('subtipoAquisicao', subtipoAquisicao)
  //   console.log('tipoAquisicaoParam', tipoAquisicaoParam)
  //   console.log('subtipoAquisicaoParam', subtipoAquisicaoParam)
  //   console.log('listaTipoAquisicao', listaTipoAquisicao)
  //   console.log('listaSubtipoAquisicao', listaSubtipoAquisicao)
  // })

  const handlePesquisar = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let params = new URLSearchParams();

    if (numeroContrato != null && numeroContrato !== "") {
      params.set("numeroContrato", numeroContrato);
    }
    if (sufixoNumeroContrato != null && sufixoNumeroContrato !== "") {
      params.set("sufixoNumeroContrato", sufixoNumeroContrato);
    }
    if (numeroProcesso != null && numeroProcesso !== "") {
      params.set("numeroProcesso", numeroProcesso);
    }
    if (numeroLicitacao != null && numeroLicitacao !== "") {
      params.set("numeroLicitacao", numeroLicitacao);
    }
    if (orgao != null && orgao !== "" & orgao !== "-1") {
      params.set("orgao", orgao);
    }
    if (objeto != null && objeto !== "") {
      params.set("objeto", objeto);
    }
    if (empresa != null && empresa !== "") {
      params.set("empresa", empresa);
    }
    if (tipoAquisicao != null && tipoAquisicao !== "" & tipoAquisicao !== "-1") {
      params.set("tipoAquisicao", tipoAquisicao);
    }
    if (subtipoAquisicao != null && subtipoAquisicao !== "" & subtipoAquisicao !== "-1") {
      params.set("subtipoAquisicao", subtipoAquisicao);
    }

    if (dataTermino != null && dataTermino !== "") {
      params.set("dataTermino", dataTermino.toISOString().substring(0, 10));

      if (comparadorDataTermino != null && comparadorDataTermino !== "") {
        params.set("comparadorDataTermino", comparadorDataTermino);
      }
    }

    params.set("p", "0");
    params.set("s", pageSize.toString());

    if (params.toString() !== searchParams.toString()) {
      setSearchParams(params);
    } else {
      pesquisar();
    }
  }



  const limparInterface = () => {
    setNumeroContrato("");
    setSufixoNumeroContrato("");
    setNumeroProcesso("");
    setNumeroLicitacao("");
    setObjeto("");
    setEmpresa("");
    setDataTermino(null);
    setComparadorDataTermino("eq");
    window.$('#comboboxListaOrgaos').val("-1").trigger("change");
    window.$('#comboboxTipoAquisicao').val("-1").trigger("change");
    window.$('#comboboxSubtipoAquisicao').val("-1").trigger("change");
    setResultado({});
    setSearchParams('');
  }

  const donwloadCSV = (data) => {

    // let csv = Utilidades.JSONToCSVConverter(resultado.elementos);
    let csv = Utilidades.JSONToCSVConverter(data);

    if(csv != null && csv !== ""){
      let link = document.createElement("a");
      link.id = "lnkDwnldLnk";

      //this part will append the anchor tag and remove it after automatic click
      document.body.appendChild(link);

      // let blob = new Blob(["\ufeff", csv], { type: 'text/csv' });
      let blob = new Blob(["\ufeff", csv]);
      // var csvUrl = window.webkitURL.createObjectURL(blob);
      var csvUrl = window.URL.createObjectURL(blob);
      // var filename =  (ReportTitle || 'UserExport') + '.csv';
      var filename =  'Contratos.csv';
      window.$("#lnkDwnldLnk")
          .attr({
              'download': filename,
              'href': csvUrl
          });

      window.$('#lnkDwnldLnk')[0].click();
      document.body.removeChild(link);
    }

  }


  return (
    <>
      <div className="modal fade" id="changePageModal" tabIndex={-1} aria-labelledby="changePageModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <form className="modal-content" onSubmit={(e) => {
            e.preventDefault();
            let page = parseInt(document.getElementById("changePageModalInput").value);
            if (page > 0 && page <= parseInt(resultado?.totalPaginas)) {
              handleChangePage(page - 1);
              // document.getElementById("changePageModalInput").value = "";
              window.$("#changePageModal").modal("hide");
            }
          }}>
            <div className="modal-header">
              <h5 className="modal-title" id="changePageModalLabel">Insira o nº da página</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input className="form-control" id="changePageModalInput" type="number" onWheel={(e) => e.target.blur()} placeholder={`1 - ${resultado?.totalPaginas}`} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
              <button type="submit" className="btn btn-primary">Ir</button>
            </div>
          </form>
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Pesquisar Contratos</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Pesquisar Contratos</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12" onSubmit={handlePesquisar}>
          <div className="card">
            <div className="card-body">
              <h4 className='my-3'>Pesquisar Contratos</h4>
              <div className='d-flex flex-column flex-md-row my-3'>
                <div className='col col-md-3'>
                  <label htmlFor='divPesquisaNumeroContrato'>Número do Contrato</label>
                  <div id='divPesquisaNumeroContrato' className='input-group'>
                    <input type='text' id='inputPesquisaNumeroContrato' className='form-control form-control-sm' placeholder='Digite o número do contrato...'
                      // pattern='^[0-9]+/[0-9]+$'
                      value={numeroContrato}
                      onChange={(e) => setNumeroContrato(e.target.value)} />
                    <select id='comboboxSufixoNumeroContrato' className='form-control form-control-sm col-5'
                      value={sufixoNumeroContrato} onChange={(e) => setSufixoNumeroContrato(e.target.value)}>
                      <option value=''></option>
                      <option value='FMS'>FMS</option>
                      <option value='SEMINF'>SEMINF</option>
                      <option value='SEMED'>SEMED</option>
                    </select>
                  </div>
                  {/* <input id="inputPesquisaNumeroContrato" type="text" className='form-control form-control-sm d-flex flex-column' value={numeroContrato} onChange={(e)=>setNumeroContrato(e.target.value)} placeholder={"Digite o número do contrato..."} /> */}
                </div>
                <div className='col col-md-3'>
                  <label htmlFor='inputPesquisaNumeroProcesso'>Número do Processo</label>
                  <input id="inputPesquisaNumeroProcesso" type="text" className='form-control form-control-sm' value={numeroProcesso} onChange={(e) => setNumeroProcesso(e.target.value)} placeholder={"Digite o número do processo..."} />
                </div>
                <div className='col col-md-3'>
                  <label htmlFor='inputPesquisaNumeroLicitacao'>Número da Licitação</label>
                  <input id="inputPesquisaNumeroLicitacao" type="text" className='form-control form-control-sm' value={numeroLicitacao} onChange={(e) => setNumeroLicitacao(e.target.value)} placeholder={"Digite o número da licitação..."} />
                </div>
                <div className='col col-md-3'>
                  <label htmlFor='inputPesquisaEmrpesa'>Empresa</label>
                  <input id="inputPesquisaEmrpesa" type="text" className='form-control form-control-sm d-flex flex-column' value={empresa} onChange={(e) => setEmpresa(e.target.value)} placeholder={"Digite o nome da empresa..."} />
                </div>
              </div>
              <div className='d-flex flex-column flex-md-row my-3'>
                <div className='col col-md-6 select2-sm'>
                  <label htmlFor='comboboxListaOrgaos'>Secretaria Solicitante</label>
                  <select id="comboboxListaOrgaos" className="form-control form-control-sm select2bs4" style={{ width: '100%' }}></select>
                </div>
                <div className='col col-md-3'>
                  <label htmlFor='divPesquisaDataTermino'>Data Término</label>
                  <div id='divPesquisaDataTermino' className='input-group'>
                    <select id='comboboxComparadorDataTermino' className='form-control form-control-sm col-3 col-md-2 rounded-left'
                      style={{ minWidth: "70px" }}
                      value={comparadorDataTermino} onChange={(e) => setComparadorDataTermino(e.target.value)}>
                      {/* <option value=''></option> */}
                      <option value='eq'>{'='}</option>
                      <option value='lte'>{'<='}</option>
                      <option value='gte'>{'>='}</option>
                    </select>
                    <div className='col px-0'>
                      <div className='d-flex flex-column flex-grow-1'>
                        <DatePicker id="datepickerDataTermino" className="form-control form-control-sm dataTerminoInput"
                          isClearable
                          autoComplete='off'
                          placeholderText='Selecione a data de término...'
                          selected={dataTermino}
                          onChange={(date) => {
                            setDataTermino(date);
                          }}
                          strictParsing
                          showYearDropdown
                          yearDropdownItemNumber={8}
                          scrollableYearDropdown
                          dateFormatCalendar="MMMM"
                          locale={ptBR} dateFormat="dd/MM/yyyy" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-column flex-md-row my-3'>
                <div className='col col-md-6'>
                  <label htmlFor='inputPesquisaObjeto'>Objeto</label>
                  <textarea id="inputPesquisaObjeto" rows={1} className='form-control form-control-sm d-flex flex-column' value={objeto} onChange={(e) => setObjeto(e.target.value)} placeholder={"Digite o objeto..."} />
                </div>
                <div className='col col-md-3 select2-sm'>
                  <label htmlFor='comboboxTipoAquisicao'>Tipo de Aquisição</label>
                  <select id="comboboxTipoAquisicao" className="form-control form-control-sm select2bs4" style={{ width: '100%' }}></select>
                </div>
                <div className='col col-md-3 select2-sm'>
                  <label htmlFor='comboboxSubtipoAquisicao'>Subtipo de Aquisição</label>
                  <select id="comboboxSubtipoAquisicao" className="form-control form-control-sm select2bs4" style={{ width: '100%' }}></select>
                </div>
              </div>
              <div className='d-flex flex-row'>
                <div className=''>
                  <button type='submit' className='btn btn-primary mx-2'>Pesquisar</button>
                  <button type='button' className='btn btn-outline-primary mx-2' onClick={limparInterface}>Limpar</button>
                </div>
                {/* <div>
                  <button type='button' className='btn btn-outline-secondary mx-2' onClick={() => getAllPesquisa()}>CSV</button>
                </div> */}
                <div className="btn-group mx-4" role="group">
                  <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                    Download
                  </button>
                  <div className="dropdown-menu">
                    <button type='button' className="dropdown-item btn btn-secondary" onClick={() => getAllPesquisa()}>CSV</button>
                    {/* <button type='button' className="dropdown-item btn btn-secondary">Dropdown 2</button> */}
                  </div>
                </div>
              </div>
              <hr />
              <div className='my-3'>
                {
                  (pesquisando) ?
                    <div className='pt-5'>
                      <LoadingElement />
                    </div> :
                    <></>
                }
                {
                  (resultado.elementos != null && resultado.elementos instanceof Array && resultado.elementos.length > 0) ?
                    <>
                      <div className='col'>
                        <table className="table table-striped table-bordered">
                          <thead>
                            <tr>
                              {/* <th>ID</th> */}
                              <th>Número</th>
                              <th>Aditivo</th>
                              <th>Processo</th>
                              <th>Órgão Solicitante</th>
                              <th>Empresa</th>
                              <th>Valor</th>
                              <th>Data Término</th>
                              {
                                (usuario?.setor === "FISCALIZACAO_E_CONTROLE" || usuario?.tipoUsuario === "ADMINISTRADOR") ?
                                  <th>Botões</th> :
                                  <></>
                              }
                            </tr>
                          </thead>
                          <tbody>
                            {
                              resultado.elementos && resultado.elementos.map((contrato) => (
                                <tr key={contrato.id}>
                                  {/* <td>{contrato.id}</td> */}
                                  <td>{contrato.numeroContrato}{(contrato.sufixoNumeroContrato != null && contrato.sufixoNumeroContrato !== "" ? `-${contrato.sufixoNumeroContrato}` : "")}</td>
                                  <td>{
                                    (contrato?.tipoAditivoContrato === "TERMO_ADITIVO" || contrato?.tipoAditivoContrato === "TERMO_APOSTILAMENTO") ?
                                      `${contrato?.numAditivo}º ${EnumUtils.getNomeEnum(contrato?.tipoAditivoContrato, listaTipoAditivoContrato)}` :
                                      EnumUtils.getNomeEnum(contrato?.tipoAditivoContrato, listaTipoAditivoContrato)
                                  }</td>
                                  <td><Link to={`/processo/pesquisar?numero=${contrato.numeroProcesso}`} className="text-info">{contrato.numeroProcesso}</Link></td>
                                  <td>{EnumUtils.getNomeEnum(contrato?.orgaoResponsavel, listaOrgaos)}</td>
                                  <td>{contrato.empresa}</td>
                                  <td>{Utilidades.getValorFormatado(contrato?.valor)}</td>
                                  <td>{isValid(parseISO(contrato?.dataTermino)) ? parseISO(contrato?.dataTermino).toLocaleDateString('pt-BR') : ""}</td>
                                  {
                                    (usuario?.setor === "SUBCONTROLADORIA_GERAL" || usuario?.tipoUsuario === "ADMINISTRADOR") ?
                                      <td className="project-actions text-right">
                                        <Link className="btn btn-warning btn-sm mr-2" to={`/contrato/editar/${contrato.id}`}>
                                          <i className="far fa-file-alt"></i>
                                          &nbsp;Editar
                                        </Link>
                                        <Link className="btn btn-info btn-sm mr-2" to={`/contrato/exibir/${contrato.id}`}>
                                          <i className="far fa-file-alt"></i>
                                          &nbsp;Exibir
                                        </Link>
                                      </td> :
                                      <>
                                        {
                                          (usuario?.permissoes?.includes("PERM_SCF_VER_CONTRATO") || usuario?.setor === "CONSULTA") ?
                                            <td className="project-actions text-right">
                                              <Link className="btn btn-info btn-sm mr-2" to={`/contrato/exibir/${contrato.id}`}>
                                                <i className="far fa-file-alt"></i>
                                                &nbsp;Exibir
                                              </Link>
                                            </td> :
                                            <></>
                                        }
                                      </>
                                  }
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </> : <></>
                }
                {
                  (nenhumEncontrado) ?
                    <p className='text-center'>Nenhum contrato encontrado</p> :
                    <></>
                }
              </div>
            </div>
            {
              (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.setor === "SUBCONTROLADORIA_GERAL" || usuario?.permissoes?.includes("PERM_SCF_VER_CONTRATO")) ?
                <div className='m-3 text-right'>
                  <Link className="btn btn-primary" to={"/contrato/novo"}>Novo Contrato</Link>
                </div> : <></>
            }
          </div>
          <div className='px-2 row justify-content-between'>
            {
              resultado?.elementos != null && resultado?.elementos.length > 0 ?
                <nav aria-label="Page navigation">
                  <ul className="pagination row px-2">
                    {

                      getPagination().map((pag, index) => {
                        return <React.Fragment key={index}>
                          {pag}
                        </React.Fragment>
                      })

                    }
                  </ul>
                </nav>
                : <div></div>
            }
            <select id="comboboxPageSize" className="form-control col-md-1 text-center" value={pageSize} onChange={handleChangePageSize}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>

        </form>

      </div>
    </>
  )
}

