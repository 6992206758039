import Tippy from '@tippyjs/react';
import { isValid, parseISO } from 'date-fns';
import React from 'react'
import { Link } from 'react-router-dom';
import Utilidades from '../../helpers/Utilidades';

export default function AvisoCollapse(props) {
  const aviso = props.aviso;
  const nomesOrgaos = props.nomesOrgaos;
  const nomesTiposAviso = props.nomesTiposAviso;
  const usuario = props.usuario;

  return (
    <>
      <div className="card collapsed-card card-navy">
        <div className="card-header" data-card-widget="collapse" style={{ cursor: "pointer" }}>
          <h3 className="card-title text-bold">Aviso de Licitação - Processo Nº {aviso?.numeroProcesso ?? "S/N"} / Licitação Nº {aviso?.numeroLicitacao ?? "S/N"}</h3>
          <div className="card-tools">
            <Tippy content="Mostar análises">
              <button type="button" className="btn btn-tool" data-card-widget="collapse" title="">
                <i className="fas fa-plus" />
              </button>
            </Tippy>
          </div>
        </div>
        <div className="card-body p-0">
          <div className='my-3 mx-3 row'>
            <div className='col'>
              <label>Número do Processo</label>
              <p>{aviso.numeroProcesso}</p>
            </div>
            <div className='col'>
              <label>Número da Licitação</label>
              <p>{aviso.numeroLicitacao}</p>
            </div>
            <div className='col'>
              <label>Órgão Solicitante</label>
              <p>{nomesOrgaos[aviso.orgaoSolicitante]}</p>
            </div>
          </div>
          <div className='my-3 mx-3 row'>
            <div className='col'>
              <label>Objeto do Processo</label>
              <p className='fix-linebreak'>{aviso.objetoProcesso}</p>
            </div>
            <div className='col'>
              <label>Objeto Portal da Transparência</label>
              <p className='fix-linebreak'>{aviso.objetoPortalTransparencia}</p>
            </div>
          </div>
          <div className='my-3 mx-3 row'>
            <div className='col'>
              <label>Valor (Processo)</label>
              <p className='fix-linebreak'>{aviso.valorProcesso}</p>
            </div>
            <div className='col'>
              <label>Valor (Portal da Transparência)</label>
              <p className='fix-linebreak'>{aviso.valorPortalTransparencia}</p>
            </div>
          </div>
          {/* <div className='d-flex flex-row justify-content-between p-2'> */}
          <div className='d-flex flex-row justify-content-center p-2'>
            <Link to={`/avisolicitacao/exibir/${aviso.id}`} type='button' className='btn btn-sm btn-primary'>Ver Avisos</Link>
          {
            (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.setor === "SUBCONTROLADORIA_GERAL") ?
              <Link to={`/avisolicitacao/editar/${aviso.id}`} type='button' className='btn btn-sm btn-primary'>Editar Avisos</Link> : <></>
          }
          </div>
          <table className="table table-striped table-bordered">
            <thead>
              <tr>
                <th colSpan={8} className="text-center">Avisos Publicados</th>
              </tr>
              <tr>
                <th>ID</th>
                <th>Tipo do Aviso</th>
                <th>Data do Aviso</th>
                <th>Data da Publicação</th>
                <th>Data do Procedimento</th>
                <th>Data Limite de Envio TCE</th>
                <th>Observações</th>
              </tr>
            </thead>
            <tbody>
              {
                aviso.publicacoes && aviso.publicacoes.map((publicacao) => (
                  <tr key={publicacao.id}>
                    <td>{publicacao.id}</td>
                    <td>{nomesTiposAviso[publicacao?.tipoAvisoLicitacao]}</td>
                    <td>{isValid(parseISO(publicacao?.dataAviso)) ? parseISO(publicacao?.dataAviso).toLocaleDateString('pt-BR') : ""}</td>
                    <td>{isValid(parseISO(publicacao?.dataPublicacaoDiarioOficial)) ? parseISO(publicacao?.dataPublicacaoDiarioOficial).toLocaleDateString('pt-BR') : ""}</td>
                    <td>{isValid(parseISO(publicacao?.dataProcedimentoLicitatorio)) ? parseISO(publicacao?.dataProcedimentoLicitatorio).toLocaleDateString('pt-BR') : ""}</td>
                    <td>{isValid(parseISO(publicacao?.dataLimiteEnvioTCE)) ? parseISO(publicacao?.dataLimiteEnvioTCE).toLocaleDateString('pt-BR') : ""}</td>
                    <td>{publicacao?.observacoes}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          
        </div>
      </div>

    </>
  )
}
