import React from 'react'

export default function FooterAuditoria(props) {
  // const version = 'v1.0.1.11';
  const version = props.version;
  return (
    <div className='hide-print'>
        {/* Main Footer */}
        <footer className="main-footer">
          {/* To the right */}
          <div className="float-right d-none d-sm-inline">
            {version} - Auditoria Geral do Município
          </div>
          {/* Default to the left */}
          <strong>Controladoria Geral do Município de Macaé © 2023 - <a href="https://macae.rj.gov.br/" target={"_blank"} rel={"noreferrer"}>Macaé</a></strong>
        </footer>
    </div>
  )
}
