import React, { useState } from 'react'
import Tippy from '@tippyjs/react';
import DatePicker from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import TipoAditivo from './TipoAditivo';
import Utilidades from '../../../helpers/Utilidades';

export default function AditivoElement({ exibindo, aditivo, campo, changeAditivo, deletarAditivo, moveUp, moveDown}) {

  const [editandoNumero, setEditandoNumero] = useState(false);

  const month = ["janeiro","fevereiro","março","abril","maio","junho","julho","agosto","setembro","outubro","novembro","dezembro"];

  const handleChangeNumero = (value) => {
    changeAditivo({
      ...aditivo,
      numAditivo: value
    })
  }

  const handleEditarNumero = (value) => {
    // console.log('value', value);
    setEditandoNumero(value);
  }

  const botoes = (
    <>
      <Tippy content="Mover para cima">
        <button className="btn col-1 text-info mx-2" type='button' onClick={() => moveUp(aditivo)}><span className='fas fa-chevron-up'></span></button>
      </Tippy>
      <Tippy content="Mover para baixo">
        <button className="btn col-1 text-info mx-2" type='button' onClick={() => moveDown(aditivo)}><span className='fas fa-chevron-down'></span></button>
      </Tippy>
      <Tippy content="Deletar">
        <button className="btn col-1 text-danger mx-2" type='button' onClick={() => deletarAditivo(aditivo)}><span className='fa fa-trash'></span></button>
      </Tippy>
    </>
  )


  return (
    <>
      {
        (aditivo?.tipoAditivo === "CONTRATO_ORIGINAL") ?
          <>
            {
              (exibindo) ?
                <div className='col'>
                  <div className='row px-0 pb-3'>
                    <div className='col'>
                      <label>
                        {TipoAditivo.getNome(aditivo.tipoAditivo)}
                      </label>
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row mb-2'>
                    <div className='col'>
                      <label>Número do Contrato</label>
                      <p>
                        {aditivo?.numeroContrato}
                      </p>
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row mb-2'>
                    <div className='col'>
                      <label>Data de Início</label>
                      <p>
                        {aditivo?.dataInicial?.toLocaleString('pt-BR').substr(0, 10)}
                      </p>
                    </div>
                    <div className='col'>
                      <label>Data de Término</label>
                      <p>
                        {aditivo?.dataFinal?.toLocaleString('pt-BR').substr(0, 10)}
                      </p>
                    </div>
                    <div className='col'>
                      <label>Valor Mensal</label>
                      <p>
                        {Utilidades.getValorFormatado(aditivo?.valorMensal)}
                      </p>
                    </div>
                    <div className='col'>
                      <label>Valor Total</label>
                      <p>
                        {Utilidades.getValorFormatado(aditivo?.valor)}
                      </p>
                    </div>
                  </div>
                </div>
                :
                <div className='col'>
                  <div className='row px-0 pb-3'>
                    <div className='col'>
                      <label>
                        {TipoAditivo.getNome(aditivo.tipoAditivo)}
                      </label>
                    </div>
                    <div className='col text-right'>
                      {botoes}
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row mb-2'>
                    <div className='col-4 col-lg-3'>
                      <label>Número do Contrato&nbsp;
                        <Tippy content='O usuário deverá inserir o número do contrato original (número/ano) sem a sigla da Unidade Gestora.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                      <input type="text" id={`inputNumeroContrato-${campo.ordem}-${aditivo.ordem}`} className="form-control"
                        onChange={
                          (e) => {
                            changeAditivo({
                              ...aditivo,
                              numeroContrato: e.target.value
                            })
                          }
                        }
                        value={aditivo.numeroContrato}
                        placeholder={"Digite o número do Contrato"} />
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row mb-2'>
                    <div className='col col-lg-3'>
                      <label>Data de Início&nbsp;
                        <Tippy content='O usuário deverá inserir a data de início do contrato, conforme consta no instrumento.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                        <div>
                          <DatePicker id={`datepickerDataInicial-${campo.ordem}-${aditivo.ordem}`} className="form-control col"
                            selected={aditivo?.dataInicial}
                            placeholderText="Clique para selecionar..."
                            onChange={(date) => {
                              changeAditivo({
                                ...aditivo,
                                dataInicial: date
                              })
                            }}
                            strictParsing
                            locale={ptBR} dateFormat="dd/MM/yyyy" required />
                        </div>
                    </div>
                    <div className='col col-lg-3'>
                      <label>Data de Término&nbsp;
                        <Tippy content='O usuário deverá inserir a data de término do contrato, conforme consta no instrumento.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                        <div>
                          <DatePicker id={`datepickerDataFinal-${campo.ordem}-${aditivo.ordem}`} className="form-control col"
                            selected={aditivo?.dataFinal}
                            placeholderText="Clique para selecionar..."
                            onChange={(date) => {
                              changeAditivo({
                                ...aditivo,
                                dataFinal: date
                              })
                            }}
                            strictParsing
                            locale={ptBR} dateFormat="dd/MM/yyyy" required />
                        </div>
                    </div>
                    <div className='col col-lg-3'>
                      <label>Valor Mensal&nbsp;
                        <Tippy content='O usuário deverá inserir o valor mensal do contrato, que deverá constar no Termo de Referência/Projeto Básico.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                      <input type="number" id={`inputValorMensal-${campo.ordem}-${aditivo.ordem}`} className="form-control"
                        onChange={
                          (e) => {
                            let valorMensal = e.target.value.replace(/^0+/, '');
                            if (valorMensal.length === 0) {
                              valorMensal = '0';
                            }
                            if (valorMensal.charAt(0) === '.' || valorMensal.charAt(0) === ',') {
                              valorMensal = '0' + valorMensal;
                            }
                            changeAditivo({
                              ...aditivo,
                              valorMensal: valorMensal
                            })
                          }
                        }
                        value={aditivo.valorMensal}
                        onWheel={(e) => e.target.blur()}
                        placeholder={"Digite o valor mensal"} />
                    </div>
                    <div className='col col-lg-3'>
                      <label>Valor Total&nbsp;
                        <Tippy content='O usuário deverá inserir o valor total do contrato, que deverá constar no Termo de Referência/Projeto Básico.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                      <input type="number" id={`inputValor-${campo.ordem}-${aditivo.ordem}`} className="form-control"
                        onChange={
                          (e) => {
                            let valor = e.target.value.replace(/^0+/, '');
                            if (valor.length === 0) {
                              valor = '0';
                            }
                            if (valor.charAt(0) === '.' || valor.charAt(0) === ',') {
                              valor = '0' + valor;
                            }
                            changeAditivo({
                              ...aditivo,
                              valor: valor
                            })
                          }
                        }
                        value={aditivo.valor}
                        onWheel={(e) => e.target.blur()}
                        placeholder={"Digite o valor total"} />
                    </div>
                  </div>
                </div>
            }
          </> :
          <></>
      }
      {
        (aditivo?.tipoAditivo === "TERMO_ADITIVO") ?
          <>
            {
              (exibindo) ?
                <div className='col'>
                  <div className='row px-0 pb-3'>
                    <div className='col'>
                      <div className='row text-bold'>
                        <span>{aditivo?.numAditivo}{aditivo?.numAditivo > 0 ? "º" : ""}</span>
                        &nbsp;{TipoAditivo.getNome(aditivo.tipoAditivo)}
                      </div>
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row mb-2'>
                    <div className='col col-lg-3'>
                      <label>Data de Início</label>
                      <p>
                        {aditivo?.dataInicial?.toLocaleString('pt-BR').substr(0, 10)}
                      </p>
                    </div>
                    <div className='col col-lg-3'>
                      <label>Data de Término</label>
                      <p>
                        {aditivo?.dataFinal?.toLocaleString('pt-BR').substr(0, 10)}
                      </p>
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row mb-2'>
                    <div className='col col-lg-3'>
                      <label>Valor Acréscimo</label>
                      <p>
                        {Utilidades.getValorFormatado(aditivo?.valorAcrescimo)}
                      </p>
                    </div>
                    <div className='d-flex flex-column mx-2'>
                      {/* <br /> */}
                      <label>% Acréscimo</label>
                      <p>
                        {(aditivo?.percentualAcrescimo != null) ? aditivo?.percentualAcrescimo + "%" : ""}
                      </p>
                    </div>
                    <div className='col col-lg-3'>
                      <label>Valor Supressão</label>
                      <p>
                        {Utilidades.getValorFormatado(aditivo?.valorSupressao)}
                      </p>
                    </div>
                    <div className='d-flex flex-column mx-2'>
                      {/* <br /> */}
                      <label>% Supressão</label>
                      <p>
                        {(aditivo?.percentualSupressao != null) ? aditivo?.percentualSupressao + "%" : ""}
                      </p>
                    </div>
                    <div className='d-flex flex-column mx-2'>
                      {/* <br /> */}
                      <label>Reflexo Financeiro</label>
                      <p>
                        {(aditivo?.percentualReflexoFinanceiro != null) ? aditivo?.percentualReflexoFinanceiro + "%" : ""}
                      </p>
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row mb-2'>
                    <div className='col col-lg-3'>
                      <label>Valor Mensal</label>
                      <p>
                        {Utilidades.getValorFormatado(aditivo?.valorMensal)}
                      </p>
                    </div>
                    <div className='col col-lg-3'>
                      <label>Valor Total</label>
                      <p>
                        {Utilidades.getValorFormatado(aditivo?.valor)}
                      </p>
                    </div>
                  </div>
                </div>
                :
                <div className='col'>
                  <div className='row px-0 pb-3'>
                    <div className='col'>
                      {(editandoNumero) ?
                        <div className='row text-bold'>
                          <input type="number" className='form-control' style={{ width: "65px" }} onChange={(e) => { handleChangeNumero(e.target.value) }} value={aditivo?.numAditivo} placeholder="nº do termo" onWheel={(e) => e.target.blur()}></input>
                          <span className='pt-1'>&nbsp;{TipoAditivo.getNome(aditivo.tipoAditivo)}&nbsp;</span>
                          {/* <Tippy content="Concluir Edição"> */}
                          <button className="btn col-1 text-secondary mx-2 py-0" type='button' onClick={() => { handleEditarNumero(false) }}><span className='fas fa-check'></span></button>
                          {/* </Tippy> */}
                        </div> :
                        <div className='row text-bold'>
                          <span>{aditivo?.numAditivo}{aditivo?.numAditivo > 0 ? "º" : ""}</span>
                          &nbsp;{TipoAditivo.getNome(aditivo.tipoAditivo)}&nbsp;
                          {/* <Tippy content="Editar número"> */}
                          <button className="btn col-1 text-secondary mx-2 py-0" type='button' onClick={() => { handleEditarNumero(true) }}><span className='fas fa-edit'></span></button>
                          {/* </Tippy> */}
                        </div>
                      }
                    </div>
                    <div className='col text-right'>
                      {botoes}
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row mb-2'>
                    <div className='col col-lg-3'>
                      <label>Data de Início&nbsp;
                        <Tippy content='O usuário deverá inserir a data de início do contrato, conforme consta no instrumento.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                        <div>
                          <DatePicker id={`datepickerDataInicial-${campo.ordem}-${aditivo.ordem}`} className="form-control col"
                            selected={aditivo?.dataInicial}
                            placeholderText="Clique para selecionar..."
                            onChange={(date) => {
                              changeAditivo({
                                ...aditivo,
                                dataInicial: date
                              })
                            }}
                            strictParsing
                            locale={ptBR} dateFormat="dd/MM/yyyy" required />
                        </div>
                    </div>
                    <div className='col col-lg-3'>
                      <label>Data de Término&nbsp;
                        <Tippy content='O usuário deverá inserir a data de término do contrato, conforme consta no instrumento.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                        <div>
                          <DatePicker id={`datepickerDataFinal-${campo.ordem}-${aditivo.ordem}`} className="form-control col"
                            selected={aditivo?.dataFinal}
                            placeholderText="Clique para selecionar..."
                            onChange={(date) => {
                              changeAditivo({
                                ...aditivo,
                                dataFinal: date
                              })
                            }}
                            strictParsing
                            locale={ptBR} dateFormat="dd/MM/yyyy" required />
                        </div>
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row mb-2'>
                    <div className='col col-lg-3'>
                      <label>Valor Acréscimo&nbsp;
                        <Tippy content='O usuário deverá inserir o valor do acréscimo solicitado que deverá constar no Termo de Referência/Projeto Básico.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                      <input type="number" id={`inputValorAcrescimo-${campo.ordem}-${aditivo.ordem}`} className="form-control"
                        onChange={
                          (e) => {
                            let valorAcrescimo = e.target.value.replace(/^0+/, '');
                            if (valorAcrescimo.length === 0) {
                              valorAcrescimo = '0';
                            }
                            if (valorAcrescimo.charAt(0) === '.' || valorAcrescimo.charAt(0) === ',') {
                              valorAcrescimo = '0' + valorAcrescimo;
                            }
                            changeAditivo({
                              ...aditivo,
                              valorAcrescimo: valorAcrescimo
                            })
                          }
                        }
                        value={aditivo.valorAcrescimo}
                        onWheel={(e) => e.target.blur()}
                        placeholder={"Digite o valor de acréscimo"} />
                    </div>
                    <div className='d-flex flex-column mx-2'>
                      {/* <br /> */}
                      <label>% Acréscimo&nbsp;
                        <Tippy content='O usuário deverá inserir o percentual referente ao valor do acréscimo sobre o valor do contrato.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                      <div className='d-flex flex-row'>
                        <input type="text" id={`inputPercentualAcrescimo-${campo.ordem}-${aditivo.ordem}`} className="form-control"
                          onChange={
                            (e) => {
                              let percentualAcrescimo = e.target.value.replace(/^0+/, '');
                              if (percentualAcrescimo.length === 0) {
                                percentualAcrescimo = '0';
                              }
                              if (percentualAcrescimo.charAt(0) === '.' || percentualAcrescimo.charAt(0) === ',') {
                                percentualAcrescimo = '0' + percentualAcrescimo;
                              }
                              changeAditivo({
                                ...aditivo,
                                percentualAcrescimo: percentualAcrescimo
                              })
                            }
                          }
                          value={aditivo.percentualAcrescimo}
                          onWheel={(e) => e.target.blur()}
                          placeholder={"% Acréscimo"} />
                        <span className='pt-2'>&nbsp;%</span>
                      </div>
                    </div>
                    <div className='col col-lg-3'>
                      <label>Valor Supressão&nbsp;
                        <Tippy content='O usuário deverá inserir o valor da supressão solicitada que deverá constar no Termo de Referência/Projeto Básico.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                      <input type="number" id={`inputValorSupressao-${campo.ordem}-${aditivo.ordem}`} className="form-control"
                        onChange={
                          (e) => {
                            let valorSupressao = e.target.value.replace(/^0+/, '');
                            if (valorSupressao.length === 0) {
                              valorSupressao = '0';
                            }
                            if (valorSupressao.charAt(0) === '.' || valorSupressao.charAt(0) === ',') {
                              valorSupressao = '0' + valorSupressao;
                            }
                            changeAditivo({
                              ...aditivo,
                              valorSupressao: valorSupressao
                            })
                          }
                        }
                        value={aditivo.valorSupressao}
                        onWheel={(e) => e.target.blur()}
                        placeholder={"Digite o valor de supressão"} />
                    </div>
                    <div className='d-flex flex-column mx-2'>
                      {/* <br /> */}
                      <label>% Supressão&nbsp;
                        <Tippy content='O usuário deverá inserir o percentual referente ao valor da supressão sobre o valor do contrato.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                      <div className='d-flex flex-row'>
                        <input type="text" id={`inputPercentualSupressao-${campo.ordem}-${aditivo.ordem}`} className="form-control"
                          onChange={
                            (e) => {
                              let percentualSupressao = e.target.value.replace(/^0+/, '');
                              if (percentualSupressao.length === 0) {
                                percentualSupressao = '0';
                              }
                              if (percentualSupressao.charAt(0) === '.' || percentualSupressao.charAt(0) === ',') {
                                percentualSupressao = '0' + percentualSupressao;
                              }
                              changeAditivo({
                                ...aditivo,
                                percentualSupressao: percentualSupressao
                              })
                            }
                          }
                          value={aditivo.percentualSupressao}
                          onWheel={(e) => e.target.blur()}
                          placeholder={"% Supressão"} />
                        <span className='pt-2'>&nbsp;%</span>
                      </div>
                    </div>
                    <div className='d-flex flex-column mx-2'>
                      {/* <br /> */}
                      <label className='text-sm'>Reflexo Financeiro&nbsp;
                        <Tippy content='O usuário deverá inserir o percentual referente ao reflexo financeiro da supressão e acréscimo realizados sobre o valor do contrato.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                      <div className='d-flex flex-row'>
                        <input type="text" id={`inputReflexoFinanceiro-${campo.ordem}-${aditivo.ordem}`} className="form-control"
                          onChange={
                            (e) => {
                              let percentualReflexoFinanceiro = e.target.value.replace(/^0+/, '');
                              if (percentualReflexoFinanceiro.length === 0) {
                                percentualReflexoFinanceiro = '0';
                              }
                              if (percentualReflexoFinanceiro.charAt(0) === '.' || percentualReflexoFinanceiro.charAt(0) === ',') {
                                percentualReflexoFinanceiro = '0' + percentualReflexoFinanceiro;
                              }
                              changeAditivo({
                                ...aditivo,
                                percentualReflexoFinanceiro: percentualReflexoFinanceiro
                              })
                            }
                          }
                          value={aditivo.percentualReflexoFinanceiro}
                          onWheel={(e) => e.target.blur()}
                          placeholder={"Inserir porcentagem"} />
                        <span className='pt-2'>&nbsp;%</span>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row mb-2'>
                    <div className='col col-lg-3'>
                      <label>Valor Mensal&nbsp;
                        <Tippy content='O usuário deverá inserir o valor mensal do contrato, que deverá constar no Termo de Referência/Projeto Básico.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                      <input type="number" id={`inputValorMensal-${campo.ordem}-${aditivo.ordem}`} className="form-control"
                        onChange={
                          (e) => {
                            let valorMensal = e.target.value.replace(/^0+/, '');
                            if (valorMensal.length === 0) {
                              valorMensal = '0';
                            }
                            if (valorMensal.charAt(0) === '.' || valorMensal.charAt(0) === ',') {
                              valorMensal = '0' + valorMensal;
                            }
                            changeAditivo({
                              ...aditivo,
                              valorMensal: valorMensal
                            })
                          }
                        }
                        value={aditivo.valorMensal}
                        onWheel={(e) => e.target.blur()}
                        placeholder={"Digite o valor mensal"} />
                    </div>
                    <div className='col col-lg-3'>
                      <label>Valor Total&nbsp;
                        <Tippy content='O usuário deverá inserir o valor total do contrato, que deverá constar no Termo de Referência/Projeto Básico.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                      <input type="number" id={`inputValor-${campo.ordem}-${aditivo.ordem}`} className="form-control"
                        onChange={
                          (e) => {
                            let valor = e.target.value.replace(/^0+/, '');
                            if (valor.length === 0) {
                              valor = '0';
                            }
                            if (valor.charAt(0) === '.' || valor.charAt(0) === ',') {
                              valor = '0' + valor;
                            }
                            changeAditivo({
                              ...aditivo,
                              valor: valor
                            })
                          }
                        }
                        value={aditivo.valor}
                        onWheel={(e) => e.target.blur()}
                        placeholder={"Digite o valor total"} />
                    </div>
                  </div>
                </div>
            }
          </> :
          <></>
      }
      {
        (aditivo?.tipoAditivo === "TERMO_APOSTILAMENTO") ?
          <>
            {
              (exibindo) ?
                <>
                <div className='col'>
                  <div className='row px-0 pb-3'>
                    <div className='col'>
                      <div className='row text-bold'>
                        <span>{aditivo?.numAditivo}{aditivo?.numAditivo > 0 ? "º" : ""}</span>
                        &nbsp;{TipoAditivo.getNome(aditivo.tipoAditivo)}
                      </div>
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row mb-2'>
                    <div className='col col-lg-3'>
                      <label>Periodicidade Início</label>
                      <p>
                        {aditivo?.periodicidadeInicioMes}/{aditivo?.periodicidadeInicioAno}
                      </p>
                    </div>
                    <div className='col col-lg-3'>
                      <label>Periodicidade Fim</label>
                      <p>
                      {aditivo?.periodicidadeFimMes}/{aditivo?.periodicidadeFimAno}
                      </p>
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row mb-2'>
                    <div className='col col-lg-3'>
                      <label>Valor Total</label>
                      <p>
                        {Utilidades.getValorFormatado(aditivo?.valor)}
                      </p>
                    </div>
                    <div className='col col-lg-3'>
                      <label>% Apostilamento</label>
                      <p>
                        {(aditivo?.percentualApostilamento != null) ? aditivo?.percentualApostilamento + "%" : ""}
                      </p>
                    </div>
                    <div className='col col-lg-3'>
                      <label>Valor Atualizado do Contrato</label>
                      <p>
                        {Utilidades.getValorFormatado(aditivo?.valorAtualizadoContrato)}
                      </p>
                    </div>
                  </div>
                </div>
                </>
                :
                <div className='col'>
                  <div className='row px-0 pb-3'>
                    <div className='col'>
                      {(editandoNumero) ?
                        <div className='row text-bold'>
                          <input type="number" className='form-control' style={{ width: "65px" }} onChange={(e) => { handleChangeNumero(e.target.value) }} value={aditivo?.numAditivo} placeholder="nº do termo" onWheel={(e) => e.target.blur()}></input>
                          <span className='pt-1'>&nbsp;{TipoAditivo.getNome(aditivo.tipoAditivo)}&nbsp;</span>
                          {/* <Tippy content="Concluir Edição"> */}
                          <button className="btn col-1 text-secondary mx-2 py-0" type='button' onClick={() => { handleEditarNumero(false) }}><span className='fas fa-check'></span></button>
                          {/* </Tippy> */}
                        </div> :
                        <div className='row text-bold'>
                          <span>{aditivo?.numAditivo}{aditivo?.numAditivo > 0 ? "º" : ""}</span>
                          &nbsp;{TipoAditivo.getNome(aditivo.tipoAditivo)}&nbsp;
                          {/* <Tippy content="Editar número"> */}
                          <button className="btn col-1 text-secondary mx-2 py-0" type='button' onClick={() => { handleEditarNumero(true) }}><span className='fas fa-edit'></span></button>
                          {/* </Tippy> */}
                        </div>
                      }
                    </div>
                    <div className='col text-right'>
                      {botoes}
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-lg-row mb-2'>
                    <div className='col col-lg-3'>
                      <div className='col'>
                        <label>Periodicidade Início&nbsp;
                        <Tippy content='O usuário deverá inserir a periodicidade inicial (mês/ano), considerada no cálculo, que deverá constar no Termo de Referência/Nota de Solicitação de Despesas.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                      </div>
                      <div className='d-flex flex-md-row flex-column'>
                        <DatePicker id={`datepickerDataInicial-${campo.ordem}-${aditivo.ordem}`} className="form-control col"
                          selected={aditivo?.dataInicial}
                          placeholderText="Selecione o mês e ano..."
                          autoComplete='off'
                          onChange={(date) => {
                            changeAditivo({
                              ...aditivo,
                              dataInicial: date,
                              periodicidadeInicioMes: month[date.getMonth()],
                              periodicidadeInicioAno: date.getFullYear()
                            })
                          }}
                          showMonthYearPicker
                          showTwoColumnMonthYearPicker
                          locale={ptBR} dateFormat="MMMM/yyyy" required />
                      </div>

                    </div>
                    <div className='col col-lg-3'>
                      <div className='col'>
                        <label>Periodicidade Fim&nbsp;
                        <Tippy content='O usuário deverá inserir a periodicidade final (mês/ano), considerada no cálculo, que deverá constar no Termo de Referência/Nota de Solicitação de Despesas.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                      </div>
                      <div className='d-flex flex-md-row flex-column'>
                        <DatePicker id={`datepickerDataFinal-${campo.ordem}-${aditivo.ordem}`} className="form-control col"
                          selected={aditivo?.dataFinal}
                          placeholderText="Selecione o mês e ano..."
                          autoComplete='off'
                          onChange={(date) => {
                            changeAditivo({
                              ...aditivo,
                              dataFinal: date,
                              periodicidadeFimMes: month[date.getMonth()],
                              periodicidadeFimAno: date.getFullYear()
                            })
                          }}
                          showMonthYearPicker
                          showTwoColumnMonthYearPicker
                          locale={ptBR} dateFormat="MMMM/yyyy" required />
                      </div>
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-lg-row mb-2'>
                    <div className='col col-lg-3'>
                      <label>Valor Total&nbsp;
                        <Tippy content='O usuário deverá inserir o valor total do contrato, que deverá constar no Termo de Referência/Projeto Básico.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                      <input type="number" id={`inputValor-${campo.ordem}-${aditivo.ordem}`} className="form-control"
                        onChange={
                          (e) => {
                            let valor = e.target.value.replace(/^0+/, '');
                            if (valor.length === 0) {
                              valor = '0';
                            }
                            if (valor.charAt(0) === '.' || valor.charAt(0) === ',') {
                              valor = '0' + valor;
                            }
                            changeAditivo({
                              ...aditivo,
                              valor: valor
                            })
                          }
                        }
                        value={aditivo.valor}
                        onWheel={(e) => e.target.blur()}
                        placeholder={"Digite o valor total"} />
                    </div>
                    <div className='d-flex flex-column mx-2'>
                      {/* <br /> */}
                      <label>% Apostilamento&nbsp;
                        <Tippy content='O usuário deverá inserir o percentual referente ao valor do reajuste/repactuação sobre o valor do contrato.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                      <div className='d-flex flex-row'>
                        <input type="text" id={`inputPercentualApostilamento-${campo.ordem}-${aditivo.ordem}`} className="form-control"
                          onChange={
                            (e) => {
                              let percentualApostilamento = e.target.value.replace(/^0+/, '');
                              if (percentualApostilamento.length === 0) {
                                percentualApostilamento = '0';
                              }
                              if (percentualApostilamento.charAt(0) === '.' || percentualApostilamento.charAt(0) === ',') {
                                percentualApostilamento = '0' + percentualApostilamento;
                              }
                              changeAditivo({
                                ...aditivo,
                                percentualApostilamento: percentualApostilamento
                              })
                            }
                          }
                          value={aditivo.percentualApostilamento}
                          onWheel={(e) => e.target.blur()}
                          placeholder={"% Acréscimo"} />
                        <span className='pt-2'>&nbsp;%</span>
                      </div>
                    </div>
                    <div className='col col-lg-3'>
                      <label>Valor Atualizado do Contrato&nbsp;
                        <Tippy content='O usuário deverá inserir o valor atualizado do contrato, ou seja, o valor originário somado ao valor do reajuste/repactuação, conforme informado no Termo de Referência/Nota de Solicitação de Despesas.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                      <input type="number" id={`inputValorAtualizadoContrato-${campo.ordem}-${aditivo.ordem}`} className="form-control"
                        onChange={
                          (e) => {
                            let valorAtualizadoContrato = e.target.value.replace(/^0+/, '');
                            if (valorAtualizadoContrato.length === 0) {
                              valorAtualizadoContrato = '0';
                            }
                            if (valorAtualizadoContrato.charAt(0) === '.' || valorAtualizadoContrato.charAt(0) === ',') {
                              valorAtualizadoContrato = '0' + valorAtualizadoContrato;
                            }
                            changeAditivo({
                              ...aditivo,
                              valorAtualizadoContrato: valorAtualizadoContrato
                            })
                          }
                        }
                        value={aditivo.valorAtualizadoContrato}
                        onWheel={(e) => e.target.blur()}
                        placeholder={"Digite o valor atualizado do contrato"} />
                    </div>
                  </div>
                </div>
            }
          </> :
          <></>
      }
      {
        (aditivo?.tipoAditivo === "ATA") ?
          <>
            {
              (exibindo) ?
                <div className='col'>
                  <div className='row px-0 pb-3'>
                    <div className='col'>
                      <label>
                        {TipoAditivo.getNome(aditivo.tipoAditivo)}
                      </label>
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row mb-2'>
                    <div className='col'>
                      <label>Número da Ata</label>
                      <p>
                        {aditivo?.numeroAta}
                      </p>
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row mb-2'>
                    <div className='col'>
                      <label>Data de Início</label>
                      <p>
                        {aditivo?.dataInicial?.toLocaleString('pt-BR').substr(0, 10)}
                      </p>
                    </div>
                    <div className='col'>
                      <label>Data de Término</label>
                      <p>
                        {aditivo?.dataFinal?.toLocaleString('pt-BR').substr(0, 10)}
                      </p>
                    </div>
                    <div className='col'>
                      <label>Valor Total</label>
                      <p>
                        {Utilidades.getValorFormatado(aditivo?.valor)}
                      </p>
                    </div>
                  </div>
                </div>
                :
                <div className='col'>
                  <div className='row px-0 pb-3'>
                    <div className='col'>
                      <label>
                        {TipoAditivo.getNome(aditivo.tipoAditivo)}
                      </label>
                    </div>
                    <div className='col text-right'>
                      {botoes}
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row mb-2'>
                    <div className='col-4 col-lg-3'>
                      <label>Número da Ata&nbsp;
                        <Tippy content='O usuário deverá inserir o número da Ata.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                      <input type="text" id={`inputNumeroAta-${campo.ordem}-${aditivo.ordem}`} className="form-control"
                        onChange={
                          (e) => {
                            changeAditivo({
                              ...aditivo,
                              numeroAta: e.target.value
                            })
                          }
                        }
                        value={aditivo.numeroAta}
                        placeholder={"Digite o número da Ata"} />
                    </div>
                  </div>
                  <div className='d-flex flex-column flex-md-row mb-2'>
                    <div className='col col-lg-3'>
                      <label>Data de Início&nbsp;
                        <Tippy content='O usuário deverá inserir a data de início do contrato, conforme consta no instrumento.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                        <div>
                          <DatePicker id={`datepickerDataInicial-${campo.ordem}-${aditivo.ordem}`} className="form-control col"
                            selected={aditivo?.dataInicial}
                            placeholderText="Clique para selecionar..."
                            onChange={(date) => {
                              changeAditivo({
                                ...aditivo,
                                dataInicial: date
                              })
                            }}
                            strictParsing
                            locale={ptBR} dateFormat="dd/MM/yyyy" required />
                        </div>
                    </div>
                    <div className='col col-lg-3'>
                      <label>Data de Término&nbsp;
                        <Tippy content='O usuário deverá inserir a data de término do contrato, conforme consta no instrumento.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                        <div>
                          <DatePicker id={`datepickerDataFinal-${campo.ordem}-${aditivo.ordem}`} className="form-control col"
                            selected={aditivo?.dataFinal}
                            placeholderText="Clique para selecionar..."
                            onChange={(date) => {
                              changeAditivo({
                                ...aditivo,
                                dataFinal: date
                              })
                            }}
                            strictParsing
                            locale={ptBR} dateFormat="dd/MM/yyyy" required />
                        </div>
                    </div>
                    <div className='col col-lg-3'>
                      <label>Valor Total&nbsp;
                        <Tippy content='O usuário deverá inserir o valor total do contrato, que deverá constar no Termo de Referência/Projeto Básico.'>
                          <i className="fas fa-info-circle text-info"></i>
                        </Tippy></label>
                      <input type="number" id={`inputValor-${campo.ordem}-${aditivo.ordem}`} className="form-control"
                        onChange={
                          (e) => {
                            let valor = e.target.value.replace(/^0+/, '');
                            if (valor.length === 0) {
                              valor = '0';
                            }
                            if (valor.charAt(0) === '.' || valor.charAt(0) === ',') {
                              valor = '0' + valor;
                            }
                            changeAditivo({
                              ...aditivo,
                              valor: valor
                            })
                          }
                        }
                        value={aditivo.valor}
                        onWheel={(e) => e.target.blur()}
                        placeholder={"Digite o valor total"} />
                    </div>
                  </div>
                </div>
            }
          </> :
          <></>
      }

    </>
  )
}
