import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Autenticar from '../../login/Autenticar';

export default function PesquisaUnidade() {

  const [searchParams, setSearchParams] = useSearchParams();

  const [nome, setNome] = useState(searchParams.get("nome") ?? "");
  const [resultado, setResultado] = useState({});

  const orgaoParam = searchParams.get("orgao") ?? "";

  const [orgao, setOrgao] = useState("");
  const [listaOrgaos, setListaOrgaos] = useState([]);

  const [pageNumber, setPageNumber] = useState("0");
  const [pageSize, setPageSize] = useState("10");

  const [nenhumEncontrado, setNenhumEncontrado] = useState(false);

  const [pesquisando, setPesquisando] = useState(false);

  

  useEffect(() => {
    if(searchParams != null && searchParams.toString() !== ""){
      if(searchParams.has("p") && searchParams.get("p") === ""){
        setPageNumber(searchParams.get("p"));
      }
      if(!searchParams.has("s") || searchParams.get("s") === ""){
        setPageSize(searchParams.get("s"));
      }
      pesquisar();

    }
    // eslint-disable-next-line
  }, [searchParams]);

  const getPagination = () => {
    let totalPaginas = parseInt(resultado.totalPaginas) ?? 0;
    let pag = []
    for (let i = 0; i < totalPaginas; i++) {
      if(i === parseInt(resultado.numeroPagina)){
        pag.push(<li className="page-item active"><button type="button" className="page-link" onClick={()=>handleChangePage(i)}>{i+1}</button></li>);
      } else {
        pag.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(i)}>{i+1}</button></li>);
      }
    }

    return pag;
    
  }

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
    let params = searchParams;
    params.set("p", "0");
    params.set("s", e.target.value);
    setSearchParams(params);
  }

  const handleChangePage = (page) => {
    let params = searchParams;
    params.set("p", page);
    setSearchParams(params);
  }

  const pesquisar = () => {
    setNenhumEncontrado(false);
    setPesquisando(true);

    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/unidade/pesquisa?" + searchParams.toString();

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if(data.totalElementos === 0 || data.totalElementos === null){
          // alert("Nenhum processo encontrado.");
          setNenhumEncontrado(true);
          
        }
        setPesquisando(false);
        setResultado(data);
      })
      .catch((erro) => console.log(erro));
  }

  const handlePesquisar = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let params = new URLSearchParams();

    if(nome != null && nome !== ""){
      params.set("nome", nome);
    }
    if(orgao != null && orgao !== "" & orgao !== "-1"){
      params.set("orgao", orgao);
    }

    params.set("p", pageNumber.toString());
    params.set("s", pageSize.toString());

    if(params.toString() !== searchParams.toString){
      setSearchParams(params);
    } else {
      pesquisar();
    }
  }

  const carregarListaOrgaos = () => {
    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/unidade/getorgaos";

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setListaOrgaos(data);
      })
      .catch((erro) => console.log(erro));
  };

  // preencher comboboxListaOrgaos
  useEffect(() => {
    window.$("#comboboxListaOrgaos").empty();
    let combobox = document.querySelector("#comboboxListaOrgaos").options;

    combobox.add(new Option("Selecione o Órgão Solicitante", "-1"));
    listaOrgaos.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    if(listaOrgaos != null && listaOrgaos.length > 0){
      // let orgaoParam = orgao;
      for (let i = 0; i < combobox.length; i++) {
        if(orgaoParam === combobox[i].value){
          combobox.selectedIndex = i;
          setOrgao(orgaoParam);
        }
      }
    } else {
      combobox.selectedIndex = 0;
    }

  }, [listaOrgaos, orgaoParam]);

  useEffect(()=>{
    window.$(".select2bs4").select2({
      theme: "bootstrap4",
    });
    window.$("#comboboxListaOrgaos").on('change', function (e) {
      setOrgao(e.target.value);
    });
    carregarListaOrgaos();

    document.title = 'Pesquisar Unidade - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
  }, []);

  const limparInterface = () => {
    setNome("");
    window.$('#comboboxListaOrgaos').val("-1").trigger("change");    
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Pesquisa de Unidades</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Pesquisa de Unidades</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12" onSubmit={handlePesquisar}>
          <div className="card">
            <div className="card-body">
              <h4 className='my-3'>Pesquisa de Unidades</h4>
              <div className='my-3 row'>
                <div className='col'>
                  <label htmlFor='inputPesquisaNome'>Nome</label>
                  <input id="inputPesquisaNome" type="text" className='form-control d-flex flex-column' value={nome} onChange={(e)=>setNome(e.target.value)} placeholder={"Digite o nome da Unidade"} />
                </div>
              </div>
              <div className='my-3 row'>
                <div className='col'>
                  <label htmlFor='comboboxListaOrgaos'>Órgão Solicitante</label>
                  <select id="comboboxListaOrgaos" className="form-control select2bs4" style={{ width: '100%' }}></select>
                </div>
              </div>
              <div className='row'>
                <button type='submit' className='btn btn-primary mx-2'>Pesquisar</button>
                <button type='button' className='btn btn-outline-primary mx-2' onClick={limparInterface}>Limpar</button>
              </div>
              <hr />
              <div className='my-3'>
                {
                  (pesquisando) ?
                  <div className="text-center mt-3 col px-0 mx-0">
                    <div className="spinner-border" role="status">
                    </div>
                  </div> :
                  <></>
                }
                {
                  resultado?.elementos != null && resultado?.elementos.length > 0 ?
                  <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Setor</th>
                            <th scope="col">Órgão Responsável</th>
                            <th scope="col" className="text-center">Editar</th>
                        </tr>
                    </thead>
                    <tbody className="align-middle">
                        {resultado.elementos && resultado.elementos.map((elemento) => {
                            return <tr key={elemento.id}>
                                <th scope="row">{elemento.id}</th>
                                <td>{elemento.nome}</td>
                                <td>{elemento.setor}</td>
                                <td>{elemento.orgaoResponsavel}</td>
                                <td className="text-center"><Link to={`/unidade/editar/${elemento.id}`} className="btn btn-success"><i className="fas fa-edit"></i></Link></td>
                            </tr>
                        })}
                    </tbody>
                  </table> :
                  <></>
                }
                {
                  (nenhumEncontrado) ?
                  <p className='text-center'>Nenhuma unidade encontrada</p> :
                  <></>
                }
              </div>
            </div>
            <div className='m-3 text-right'>
              <Link className="btn btn-primary" to={"/unidade/cadastro"}>Nova Unidade</Link>
            </div>
          </div>
          <div className='row justify-content-between px-2'>
            {
              resultado?.elementos != null && resultado?.elementos.length > 0 ?
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {
                      parseInt(resultado.numeroPagina) === 0 ?
                      <li className="page-item disabled"><button type="button" className="page-link">Anterior</button></li> :
                      <li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(parseInt(resultado.numeroPagina)-1)}>Anterior</button></li>
                    }
                    {

                      getPagination().map((pag, index) => {
                        return <React.Fragment key={index}>
                          {pag}
                        </React.Fragment>
                      })

                    }
                    {
                      parseInt(resultado?.numeroPagina) + 1 === parseInt(resultado?.totalPaginas) ?
                      <li className="page-item disabled"><button type="button" className="page-link">Próxima</button></li> :
                      <li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(parseInt(resultado.numeroPagina)+1)}>Próxima</button></li>

                    }
                  </ul>
                </nav>
                : <div></div>
            }
            <select id="comboboxPageSize" className="form-control col-1 text-center" value={pageSize} onChange={handleChangePageSize}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>

        </form>
        
      </div>
    </>
  )
}
