import '../../../Print.css'
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Autenticar from '../../login/Autenticar';
import Bloco from './blocos/Bloco';
import "react-datepicker/dist/react-datepicker.css";
import CampoComplementar from './campos/CampoComplementar';
import { parseISO } from 'date-fns';
import EnumUtils from '../../helpers/EnumUtils';
import Requisicao from '../../helpers/Requisicao';
import Utilidades from '../../helpers/Utilidades';

export default function VisualizarAnalise({ usuario, autenticado }) {

  const { analiseId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [textoInicial, setTextoInicial] = useState("");
  const [textoFinal, setTextoFinal] = useState("");
  const [textoInicialExtra, setTextoInicialExtra] = useState("");
  const [textoFinalExtra, setTextoFinalExtra] = useState("");
  const [textoInicialExtraHTML, setTextoInicialExtraHTML] = useState("");
  const [textoFinalExtraHTML, setTextoFinalExtraHTML] = useState("");
  const [blocos, setBlocos] = useState([]);
  const [campos, setCampos] = useState([]);

  const [textoEncaminhamento, setTextoEncaminhamento] = useState("");

  const [nomeResponsavel, setNomeResponsavel] = useState("");
  const [nomeOrdenadorDespesas, setNomeOrdenadorDespesas] = useState("");
  const [matriculaResponsavel, setMatriculaResponsavel] = useState("");
  const [matriculaOrdenadorDespesas, setMatriculaOrdenadorDespesas] = useState("");
  const [nomeAnalista, setNomeAnalista] = useState("");
  const [matriculaAnalista, setMatriculaAnalista] = useState("");

  const [dataAnalise, setDataAnalise] = useState("");


  const [analise, setAnalise] = useState(null);
  const [processo, setProcesso] = useState(null);

  const [orgaoNome, setOrgaoNome] = useState("");
  const [modalidadeLicitacaoNome, setModalidadeLicitacaoNome] = useState("");

  const [logoUnidade, setLogoUnidade] = useState("");
  const [carimbo, setCarimbo] = useState("");

  const carregarAnalise = () => {
    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/analise/get/" + analiseId;

    fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json()
        }
      })
      .then((data) => {
        console.log('analise', data);
        if (data != null) {
          setupAnalise(data);
        } else {
          alert("Análise não econtrada ou você não tem permissão para acessá-la.");
          navigate("/processo/pesquisar", { state: { referrer: location }, replace: true });
        }
      })
      .catch((erro) => console.log(erro));
  };

  const carregarProcesso = (processoId) => {
    Requisicao.getProcessoDadosBasicosPorId(processoId)
      .then(data => {
        console.log('processo', data);
        // if (data.unidade != null) {
        //   if (data.unidade?.nomeResponsavel != null && data.unidade?.nomeResponsavel !== "") {
        //     setNomeResponsavel(data.unidade.nomeResponsavel ?? "");
        //   }
        //   if (data.unidade?.matriculaOrdenadorDespesas != null && data.unidade?.matriculaOrdenadorDespesas !== "") {
        //     setMatriculaOrdenadorDespesas(data.unidade.matriculaOrdenadorDespesas ?? "");
        //   }
        //   setLogoUnidade(data.unidade.fotoUnidade ?? "");
        //   setCarimbo(data.unidade.carimboPaginacao ?? "");
        // }
        setProcesso(data);
        EnumUtils.getNomeOrgao(data?.orgaoSolicitante)
          .then(data => {
            setOrgaoNome(data)
          })
          .catch(erro => {
            setOrgaoNome(data?.orgaoSolicitante);
            console.log(erro);
          });
      })
      .catch((erro) => console.log(erro));


    // const token = Autenticar.getLocalToken();

    // const requestOptions = {
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + token,
    //   }
    // };

    // const url = window.servidor + "/v1/protegido/processo/get/" + processoId;

    // fetch(url, requestOptions)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log('processo', data);
    //     if (data.unidade != null) {
    //       if (data.unidade?.nomeResponsavel != null && data.unidade?.nomeResponsavel !== "") {
    //         setNomeResponsavel(data.unidade.nomeResponsavel ?? "");
    //       }
    //       if (data.unidade?.matriculaOrdenadorDespesas != null && data.unidade?.matriculaOrdenadorDespesas !== "") {
    //         setMatriculaOrdenadorDespesas(data.unidade.matriculaOrdenadorDespesas ?? "");
    //       }
    //       setLogoUnidade(data.unidade.fotoUnidade ?? "");
    //       setCarimbo(data.unidade.carimboPaginacao ?? "");
    //     }
    //     setProcesso(data);
    //     EnumUtils.getNomeOrgao(data?.orgaoSolicitante)
    //       .then(data => {
    //         setOrgaoNome(data)
    //       })
    //       .catch(erro => {
    //         setOrgaoNome(data?.orgaoSolicitante);
    //         console.log(erro);
    //       });
    //   })
    //   .catch((erro) => console.log(erro));
  };

  const carregarUnidadeResponsavel = (unidadeResponsavelId) => {
    Requisicao.getUnidadeById(unidadeResponsavelId)
      .then(data => {
        if (data != null) {
          setNomeResponsavel(data?.nomeResponsavel ?? "");
          setNomeOrdenadorDespesas(data?.nomeOrdenadorDespesas ?? "");
          setMatriculaResponsavel(data?.matriculaResponsavel ?? "");
          setMatriculaOrdenadorDespesas(data?.matriculaOrdenadorDespesas ?? "");
          setLogoUnidade(data.fotoUnidade ?? "");
          setCarimbo(data.carimboPaginacao ?? "");
        }
      })
  }

  const setupAnalise = (a) => {
    setupDadosAnalise(a);
    carregarProcesso(a?.processoId);
    carregarUnidadeResponsavel(a?.unidadeResponsavelId);
  }

  const setupDadosAnalise = (a) => {
    setTextoInicial(a.textoInicial ?? "");
    setTextoFinal(a.textoFinal ?? "");
    setTextoInicialExtra(a.textoInicialExtra ?? "");
    setTextoFinalExtra(a.textoFinalExtra ?? "");
    setTextoInicialExtraHTML(a.textoInicialExtraHTML ?? "");
    setTextoFinalExtraHTML(a.textoFinalExtraHTML ?? "");
    setNomeAnalista(a.nomeAnalista ?? "");
    setMatriculaAnalista(a.matriculaAnalista ?? "");

    if (a.dataAnalise != null && a.dataAnalise !== "") {
      let date = parseISO(a.dataAnalise);
      let options = { month: "long" };
      let mes = date.toLocaleDateString("pt-BR", options);
      let dateString = "Macaé, " + date.getDate() + " de " + mes + " de " + date.getFullYear() + ".";

      setDataAnalise(dateString);

    }

    setTextoEncaminhamento(a.textoEncaminhamento ?? "");

    if (a.blocos != null && a.blocos.length > 0) {

      a.blocos.sort(sortPorOrdem);

      a.blocos.forEach((bloco) => {
        if (bloco.filhos != null && bloco.filhos.length > 0) {
          bloco.filhos.sort(sortPorOrdem);
          bloco.filhos.forEach((filho) => {
            if (filho.netos != null && filho.netos.length > 0) {
              filho.netos.sort(sortPorOrdem);
            }
          });
        }
        if (bloco.recomendacoes != null && bloco.recomendacoes.length > 0) {
          bloco.recomendacoes.sort(sortPorOrdem);
        }
      });
      setBlocos(a.blocos);
    }

    if (a.camposComplementares != null && a.camposComplementares.length > 0) {

      a.camposComplementares.sort(sortPorOrdem);

      a.camposComplementares.forEach((campo) => {
        if (campo.tipo === "ADITIVO" || (campo.campoCompAditivo != null && campo?.campoCompAditivo?.length > 0)) {
          campo.campoCompAditivo.forEach((aditivo) => {
            if (aditivo?.dataInicial != null) {
              aditivo.dataInicial = parseISO(aditivo.dataInicial);
            }
            if (aditivo?.dataFinal != null) {
              aditivo.dataFinal = parseISO(aditivo.dataFinal);
            }
          })
          campo.campoCompAditivo.sort(sortPorOrdem);
        }
      })
      setCampos(a.camposComplementares);
    }

    EnumUtils.getNomeModalidadeLicitacao(a?.modalidadeLicitacao)
      .then(data => {
        setModalidadeLicitacaoNome(data);
      })
      .catch(erro => {
        console.log('erro', erro);
        setModalidadeLicitacaoNome(a?.modalidadeLicitacao);
      })

    setAnalise(a);

  }


  const sortPorOrdem = (a, b) => {
    if (a.ordem < b.ordem) {
      return -1;
    }
    if (a.ordem > b.ordem) {
      return 1;
    }

    return 0;
  }

  // setup inicial
  useEffect(() => {
    if (autenticado) {
      window.$(".select2bs4").select2({
        theme: "bootstrap4",
      });
      carregarAnalise();
    }

    document.title = 'Visualizar Análise - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
    // eslint-disable-next-line
  }, [autenticado]);

  const styles = {
    logo: {
      maxHeight: "130px",
      width: "auto"
    },
    carimbo: {
      maxHeight: "150px",
      width: "auto"
    },
    footer: {
      borderTopStyle: "solid",
      borderWidth: "3px",
      borderColor: "#0098da",
      width: "141vw"
    },
    header: {
      borderBottomStyle: "solid",
      borderWidth: "3px",
      borderColor: "#0098da",
      width: "141vw"
    }

  }

  return (
    <>
      <table className='paging aumentar-fonte-impressao' style={{ width: "100%" }}>
        <thead><tr><td>
          <div className="header-space" style={{ height: "170px" }}>&nbsp;</div>
        </td></tr></thead>
        <tbody>
          <tr><td>
            <div className="content-wrapper">
              {/* <tr><td> */}
                <div className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-6">
                        <h1 className="m-0">Visualizar Análise</h1>
                      </div>
                      <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                          <li className="breadcrumb-item">
                            <Link to={"/"}>Página Inicial</Link>
                          </li>
                          <li className="breadcrumb-item">
                            <Link to={`/analise/${analiseId}`}>Análise</Link>
                          </li>
                          <li className="breadcrumb-item active">Visualizar Análise</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              {/* </td></tr>
              <tr><td> */}
                <div className="col-md-12 hide-print">
                {/* <div className="col-md-12"> */}
                  <div className="card">
                    <div className="card-body">
                      {
                        processo != null ?
                          <div className="mt-3 col px-0 mx-0">
                            <h2 className="text-center">Processo Nº {processo.numero}</h2>
                            {
                              (analise?.nome != null) ?
                                <div className='my-3'>
                                  <h4 className='text-center'>{analise.nome}</h4>
                                </div> :
                                <></>
                            }
                            <br />
                            <div className='row'>
                              <div className='col-6'>
                                <label>Unidade Resposável</label>
                                <p>
                                  {processo.unidade.nome}
                                </p>
                              </div>
                              <div className='col-6'>
                                <label>Órgão Solicitante</label>
                                <p>
                                  {orgaoNome}
                                </p>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-6'>
                                <label>Objeto do Processo</label>
                                <p className='text-justify fix-linebreak'>
                                  {processo.objeto}
                                </p>
                              </div>
                              <div className='col-6'>
                                <label>Volumes e Apensos</label>
                                <p>
                                  {processo.volumeseApensos}
                                </p>
                              </div>
                            </div>
                          </div> :
                          <>
                            <div className="text-center mt-3 col px-0 mx-0">
                              <div className="spinner-border" role="status">
                              </div>
                            </div>
                          </>
                      }
                      {
                        (analise?.isModeloSet) ?
                          <>
                            <div className='row'>
                              <div className='col-6'>
                                <label>Valor</label>
                                <p className='text-justify fix-linebreak'>
                                  {(Number.isNaN(Number(Utilidades.paraFloatPontoDecimal(analise.valorEstimado)))) ? analise.valorEstimado : Number(Utilidades.paraFloatPontoDecimal(analise.valorEstimado)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </p>
                              </div>
                              <div className='col-6'>
                                <label>Modalidade da Licitação</label>
                                <p>
                                  {modalidadeLicitacaoNome}
                                </p>
                              </div>
                            </div>
                            <div className='row'>
                              {
                                campos && campos.map((campo) => {
                                  if (campo.tipo === "ADITIVO" || campo.tipo === "CAMPOCONJUNTO_PFPJ_CPFCPNJ_TEXTO" || campo.tipo === "CAMPO_COMPLEMENTAR_CONFORMIDADE") {
                                    return (
                                      <div className='col-12' key={campo.ordem}>
                                        <CampoComplementar campo={campo} onChangeCampo={() => { }} exibindo={true} />
                                      </div>);
                                  } else {
                                    return (
                                      <div className='col-6' key={campo.ordem}>
                                        <CampoComplementar campo={campo} onChangeCampo={() => { }} exibindo={true} />
                                      </div>);
                                  }
                                })
                              }
                            </div>
                            <div className='my-3'>
                              <p className='text-justify fix-linebreak'>
                                {"\t" + textoInicial.replaceAll("\t", "").replaceAll("\n", "\n\t")}
                              </p>
                            </div>
                            <div className='my-3'>
                              { 
                                (textoInicialExtraHTML != null && textoInicialExtraHTML !== "") ?
                                  <div dangerouslySetInnerHTML={{__html: textoInicialExtraHTML}}></div> :
                                  <p className='text-justify fix-linebreak'>
                                    {"\t" + textoInicialExtra.replaceAll("\t", "").replaceAll("\n", "\n\t")}
                                  </p>
                              }
                            </div>

                            <div className='my-3'>
                              {
                                blocos && blocos.map((bloco) => (
                                  <Bloco key={bloco.ordem} bloco={bloco} onChangeBloco={() => { }} exibindo={true} usuario={usuario} leiLicitacao={analise?.leiLicitacao} tipoAnalise={analise?.tipoAnalise}
                                  isConformidade={(analise?.tipoAnalise === "ANALISE_DE_CONFORMIDADE" || analise?.tipoAnalise === "REVISAO_ANALISE_DE_CONFORMIDADE") ? true : false}/>
                                ))
                              }
                            </div>
                            <div className='my-3 quebrar-antes'>
                              <p className='text-justify fix-linebreak'>
                                {"\t" + textoFinal.replaceAll("\t", "").replaceAll("\n", "\n\t")}
                              </p>
                            </div>
                            <div className='my-3'>
                              {
                                (textoFinalExtraHTML != null && textoFinalExtraHTML !== "") ?
                                  <div dangerouslySetInnerHTML={{__html: textoFinalExtraHTML}}></div> :
                                  <p className='text-justify fix-linebreak'>
                                    {"\t" + textoFinalExtra.replaceAll("\t", "").replaceAll("\n", "\n\t")}
                                  </p>
                              }
                            </div>
                            {/* <br /> */}
                            <div className='my-3 quebrar-antes'>
                              <p className='text-justify fix-linebreak'>
                                {"\t" + textoEncaminhamento.replaceAll("\t", "").replaceAll("\n", "\n\t")}
                              </p>
                            </div>
                            {/* <br /> */}
                            {/* <div className='my-3'>
                          {
                            dataAnalise != null ?
                              <div className='text-center'>
                                <p className='text-bold'>
                                  {dataAnalise}
                                </p>
                              </div> :
                              <></>
                          }
                        </div>
                        <br /> */}
                            {/* <br className='show-print'/> */}
                            {/* <br className='show-print'/> */}
                            {/* <div className='my-3 imprimir-inteiro'> */}
                            {/* <div className='my-3'>
                          <div className='row px-0' style={{}}>
                            <div className='col-4 text-center px-0 mx-3' style={{ border: "1px solid black", width: "30%" }}>
                              <p className='row mx-0 text-bold' style={{ borderBottom: "1px solid black"}}>Analisado por:</p>
                              <br />
                              <br />
                              <br />
                              <p>{nomeAnalista}</p>
                              <p>Mat. {matriculaAnalista}</p>
                            </div>
                            <div className=' col-4 text-center px-0 mx-3' style={{ border: "1px solid black", width: "30%" }}>
                              <p className='row mx-0 text-bold' style={{ borderBottom: "1px solid black"}}>Revisado por:</p>
                              {
                                (analise?.nomeRevisor != null && analise?.nomeRevisor !== "" && analise?.matriculaRevisor != null && analise?.matriculaRevisor !== "") ? 
                                <>
                                  <br />
                                  <br />
                                  <br />
                                  <p>{analise.nomeRevisor}</p>
                                  <p>Mat. {analise.matriculaRevisor}</p>
                                </> :
                                <>
                                  <br />
                                  <br />
                                  <br />
                                  <p>{nomeResponsavel}</p>
                                  <p>Mat. {matriculaOrdenadorDespesas}</p>
                                </>
                              }
                            </div>
                          </div>
                        </div> */}
                            {/* <div className='my-3 hide-print'>
                          <div className='d-flex flex-row justify-content-center imp'>
                            <div className='col-4 text-center px-0 mx-3' style={{ border: "1px solid black" }}>
                              <label className='d-flex flex-grow-1 justify-content-center' style={{ borderBottom: "1px solid black" }}>Analisado por:</label>
                              <br />
                              <br />
                              <br />
                              <p>{nomeAnalista}</p>
                              <p>Mat. {matriculaAnalista}</p>
                            </div>
                            <div className='col-4 text-center px-0 mx-3' style={{ border: "1px solid black" }}>
                              <label className='d-flex flex-grow-1 justify-content-center' style={{ borderBottom: "1px solid black" }}>Revisado por:</label>
                              {
                                (analise?.nomeRevisor != null && analise?.nomeRevisor !== "" && analise?.matriculaRevisor != null && analise?.matriculaRevisor !== "") ?
                                  <>
                                    <br />
                                    <br />
                                    <br />
                                    <p>{analise.nomeRevisor}</p>
                                    <p>Mat. {analise.matriculaRevisor}</p>
                                  </> :
                                  <>
                                    <br />
                                    <br />
                                    <br />
                                    <p>{nomeResponsavel}</p>
                                    <p>Mat. {matriculaResponsavel}</p>
                                  </>
                              }
                            </div>
                          </div>
                        </div>
                        <div className='show-print d-block'><br /></div>
                        <div className='my-3 hide-print'>
                          <div className='d-flex flex-row justify-content-center imp'>
                            <div className='col-6 text-center px-0 mx-3' style={{ border: "1px solid black" }}>
                              <label className='d-flex flex-grow-1 justify-content-center' style={{ borderBottom: "1px solid black" }}>Data:</label>
                              <br />
                              <br />
                              <br />
                              <p>{nomeOrdenadorDespesas}</p>
                              {
                                (Number(analise?.unidadeResponsavelId) === 1) ?
                                  <p>Controlador Geral do Município</p> :
                                  <></>
                              }
                              <p>Mat. {matriculaOrdenadorDespesas}</p>
                            </div>
                          </div>
                        </div> */}
                            {/* <br /> */}
                            {
                              (analise?.isModeloSet) ?
                                <>
                                  <div className=''>
                                  {/* <div className='imprimir-inteiro-com-margem'> */}
                                    <div className='my-3'>
                                      {
                                        dataAnalise != null ?
                                          <div className='text-center'>
                                            <p className='text-bold'>
                                              {dataAnalise}
                                            </p>
                                          </div> :
                                          <></>
                                      }
                                    </div>
                                    <br />
                                    <div className='row' style={{ marginLeft: "15%" }}>
                                      <div className='col-5 text-center px-0 mx-2' style={{ border: "1px solid black" }}>
                                        <label className=''>Analisado por:</label>
                                        {/* <label className='' style={{ borderBottom: "1px solid black" }}>Analisado por:</label> */}
                                        <br />
                                        <br />
                                        <br />
                                        <p className='mb-0'>{nomeAnalista}</p>
                                        <p className='mb-0'>Mat. {matriculaAnalista}</p>
                                      </div>
                                      <div className='col-5 text-center px-0 mx-2' style={{ border: "1px solid black" }}>
                                        <label className=''>Revisado por:</label>
                                        {/* <label className='' style={{ borderBottom: "1px solid black" }}>Revisado por:</label> */}
                                        {
                                          (analise?.nomeRevisor != null && analise?.nomeRevisor !== "" && analise?.matriculaRevisor != null && analise?.matriculaRevisor !== "") ?
                                            <>
                                              <br />
                                              <br />
                                              <br />
                                              <p className='mb-0'>{analise.nomeRevisor}</p>
                                              <p className='mb-0'>Mat. {analise.matriculaRevisor}</p>
                                            </> :
                                            <>
                                              <br />
                                              <br />
                                              <br />
                                              <p className='mb-0'>{nomeResponsavel}</p>
                                              <p className='mb-0'>Mat. {matriculaResponsavel}</p>
                                            </>
                                        }
                                      </div>
                                    </div>
                                    <br />
                                    <div className='row' style={{ marginLeft: "25%" }}>
                                      <div className='col-8 text-center px-0 mx-2' style={{ border: "1px solid black" }}>
                                        <label className=''>Ciente em:</label>
                                        {/* <label className='' style={{ borderBottom: "1px solid black" }}>Data:</label> */}
                                        <br />
                                        <br />
                                        <br />
                                        <p className='mb-0'>{nomeOrdenadorDespesas}</p>
                                        {
                                          (Number(analise?.unidadeResponsavelId) === 1) ?
                                            <p className='mb-0'>Controlador Geral do Município</p> :
                                            <></>
                                        }
                                        <p className='mb-0'>Mat. {matriculaOrdenadorDespesas}</p>
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                </> :
                                <></>
                            }
                            {/* <div className='imprimir-inteiro'>
                          <div className='row'>
                            <div className='col-6 text-center px-0 mx-3' style={{ border: "1px solid black" }}>
                              <label className='' style={{ borderBottom: "1px solid black" }}>Data:</label>
                              <br />
                              <br />
                              <br />
                              <p className='mb-0'>{nomeOrdenadorDespesas}</p>
                              {
                                (Number(analise?.unidadeResponsavelId) === 1) ?
                                  <p className='mb-0'>Controlador Geral do Município</p> :
                                  <></>
                              }
                              <p className='mb-0'>Mat. {matriculaOrdenadorDespesas}</p>
                            </div>
                          </div>
                        </div> */}
                            {/* <div className="d-flex flex-row justify-content-end px-3 pb-3"> */}
                            {/* <div className="text-right px-3 pb-3">
                              <button type="button" className="btn btn-primary mx-2 hide-print" onClick={() => { window.print(); return false; }}>Imprimir</button>
                            </div> */}
                          </>
                          : <></>
                      }
                    </div>
                  </div>
                </div>
              {/* </td></tr> */}
            </div>
          </td></tr>
          {/* <tr className='imprimir-inteiro'></tr> */}
          {/* <tr><td>
          {
            (analise?.isModeloSet) ?
            <>
              <br />
              <div className='my-3 imprimir-inteiro show-print'>
                <div className='d-flex flex-row justify-content-center imp'>
                  <div className='col-4 text-center px-0 mx-3' style={{ border: "1px solid black" }}>
                    <label className='d-flex flex-grow-1 justify-content-center' style={{ borderBottom: "1px solid black" }}>Analisado por:</label>
                    <br />
                    <br />
                    <br />
                    <p className='mb-0'>{nomeAnalista}</p>
                    <p className='mb-0'>Mat. {matriculaAnalista}</p>
                  </div>
                  <div className='col-4 text-center px-0 mx-3' style={{ border: "1px solid black" }}>
                    <label className='d-flex flex-grow-1 justify-content-center' style={{ borderBottom: "1px solid black" }}>Revisado por:</label>
                    {
                      (analise?.nomeRevisor != null && analise?.nomeRevisor !== "" && analise?.matriculaRevisor != null && analise?.matriculaRevisor !== "") ? 
                      <>
                        <br />
                        <br />
                        <br />
                        <p className='mb-0'>{analise.nomeRevisor}</p>
                        <p className='mb-0'>Mat. {analise.matriculaRevisor}</p>
                      </> :
                      <>
                        <br />
                        <br />
                        <br />
                        <p className='mb-0'>{nomeResponsavel}</p>
                        <p className='mb-0'>Mat. {matriculaResponsavel}</p>
                      </>
                    }
                  </div>
                </div>
              </div>
              <br />
            </> :
            <></>
          }
        </td></tr>
        <tr><td>
        <div className='my-3 imprimir-inteiro show-print'>
          <div className='d-flex flex-row justify-content-center imp'>
            <div className='col-6 text-center px-0 mx-3' style={{ border: "1px solid black" }}>
              <label className='d-flex flex-grow-1 justify-content-center' style={{ borderBottom: "1px solid black" }}>Data:</label>
              <br />
              <br />
              <br />
              <p className='mb-0'>{nomeOrdenadorDespesas}</p>
              {
                (Number(analise?.unidadeResponsavelId) === 1) ?
                <p className='mb-0'>Controlador Geral do Município</p> :
                <></>
              }
              <p className='mb-0'>Mat. {matriculaOrdenadorDespesas}</p>
            </div>
          </div>
        </div>
        </td></tr> */}
        </tbody>
        <tfoot><tr><td>
          <div className="footer-space">&nbsp;</div>
        </td></tr></tfoot>
      </table>
      <div className="header-print">
        <div className='row pb-2 mb-2' style={styles.header}>
          <div className='col-4'>
            {(logoUnidade !== "") ? <img className="" src={logoUnidade} style={styles.logo} alt="Logo da Unidade" /> : <></>}
          </div>
          <div className="col-4" style={{ width: "550px" }}></div>
          <div className='col-4 text-right'>
            {(carimbo !== "") ? <img className="" src={carimbo} style={styles.carimbo} alt="Carimbo de Paginação" /> : <></>}
          </div>
        </div>
      </div>
      <div className="footer-print">
        <div className="py-2 row justify-content-between" style={styles.footer}>
          <div className='pt-3'>
            Processo nº {processo?.numero} - {processo?.volumeseApensos}
          </div>
          <img src="/assinatura_vert.png" alt='' style={{ width: "40px" }} />
        </div>
      </div>
    </>
  )
}

