import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import Requisicao from '../../helpers/Requisicao';

// var controlBarIniciada = false;

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function ControlBarSFC({ usuario, autenticado }) {

  const [emAnalise, setEmAnalise] = useState(0);
  // const [emAnaliseVerificacao, setEmAnaliseVerificacao] = useState(0);
  const [emRevisao, setEmRevisao] = useState(0);
  // const [emRevisaoVerificacao, setEmRevisaoVerificacao] = useState(0);
  const [emManifestacao, setEmManifestacao] = useState(0);
  // const [emManifestacaoVerificacao, setEmManifestacaoVerificacao] = useState(0);
  const [finalizado, setFinalizado] = useState(0);
  const [emAnaliseIndividual, setEmAnaliseIndividual] = useState(0);
  // const [emAnaliseVerificacaoIndividual, setEmAnaliseVerificacaoIndividual] = useState(0);
  const [emRevisaoIndividual, setEmRevisaoIndividual] = useState(0);
  // const [emRevisaoVerificacaoIndividual, setEmRevisaoVerificacaoIndividual] = useState(0);
  const [emManifestacaoIndividual, setEmManifestacaoIndividual] = useState(0);
  // const [emManifestacaoVerificacaoIndividual, setEmManifestacaoVerificacaoIndividual] = useState(0);
  const [encaminhadas, setEncaminhadas] = useState(0);
  const [encaminhadasIndividual, setEncaminhadasIndividual] = useState(0);
  const [emVerificacao, setEmVerificacao] = useState(0);
  const [emVerificacaoIndividual, setEmVerificacaoIndividual] = useState(0);
  const [finalizadoIndividual, setFinalizadoIndividual] = useState(0);
  const [ultimaAtualizacao, setUltimaAtualizacao] = useState(Date.now());

  const [filaIndividual, setFilaIndividual] = useState(false);

  const [alertasConfig, setAlertasConfig] = useState("GERAL");

  // const [quantidades, setQuantidades] = useState({});

  // const osInstance = window.OverlayScrollbars(document.querySelector('#filas-control-sidebar'), {
  //   overflow: {
  //     y: 'scroll',
  //   }
  // });

  // useEffect(()=>{
  // window.$('[data-toggle="control-sidebar"]').controlSidebar();
  // }, [])
  // useEffect(()=>{
  //   window.$("#filas-control-sidebar").overlayScrollbars({
  //     className:"os-theme-dark"
  //   })
  // }, [])

  // useEffect(()=>{
  //   carregarDados();
  //   let updateIntervalId = setInterval(()=>{
  //     updateDados();
  //   }, 10 * 1000);
  //   return () => {
  //     clearInterval(updateIntervalId);
  //   }
  // }, []);

  useInterval(() => {
    // if(filaIndividual){
      updateDadosIndividual();
    // } else {
      updateDados();
    // }
  }, 60 * 1000);

  useEffect(() => {
    carregarDados();
    carregarDadosIndividual();
  }, []);

  // useEffect(() => {
  //   if((Date.now() - ultimaAtualizacao) > 60 * 1000){
  //     if(filaIndividual){
  //       carregarDadosIndividual();
  //     } else {
  //       carregarDados();
  //     }
  //   }
  // }, [filaIndividual, ultimaAtualizacao])

  const carregarDados = () => {
    Requisicao.getAllQtdByUnidadeUsuario()
      .then(data => {
        let qtd = 0;
        data?.filter(fila => (fila.filaAnalise === "EM_ANALISE" && fila.statusAnalise === "EM_EDICAO")).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        setEmAnalise(qtd);

        // qtd = 0;
        // data?.filter(fila => (fila.filaAnalise === "EM_ANALISE" && fila.statusAnalise === "EM_VERIFICACAO")).forEach(fila => {
        //   qtd += fila?.quantidade ?? 0;
        // });
        // setEmAnaliseVerificacao(qtd);

        qtd = 0;
        data?.filter(fila => ((fila.filaAnalise === "EM_REVISAO" && fila.statusAnalise === "EM_EDICAO") || 
          (fila.filaAnalise === "EM_REVISAO" && fila.statusAnalise === "ENCAMINHADA"))).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        setEmRevisao(qtd);

        // qtd = 0;
        // data?.filter(fila => (fila.filaAnalise === "EM_REVISAO" && fila.statusAnalise === "EM_VERIFICACAO")).forEach(fila => {
        //   qtd += fila?.quantidade ?? 0;
        // });
        // setEmRevisaoVerificacao(qtd);

        qtd = 0;
        data?.filter(fila => ((fila.filaAnalise === "EM_MANIFESTACAO" && fila.statusAnalise === "EM_EDICAO") || 
          (fila.filaAnalise === "EM_MANIFESTACAO" && fila.statusAnalise === "ENCAMINHADA"))).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        setEmManifestacao(qtd);

        // qtd = 0;
        // data?.filter(fila => (fila.filaAnalise === "EM_MANIFESTACAO" && fila.statusAnalise === "EM_VERIFICACAO")).forEach(fila => {
        //   qtd += fila?.quantidade ?? 0;
        // });
        // setEmManifestacaoVerificacao(qtd);

        qtd = 0;
        data?.filter(fila => (fila.filaAnalise === "FINALIZADO" || fila.statusAnalise === "FINALIZADA")).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        setFinalizado(qtd);

        qtd = 0;
        data?.filter(fila => ((fila.filaAnalise === "EM_REVISAO" && fila.statusAnalise === "ENCAMINHADA"))).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        setEncaminhadas(qtd);
        
        qtd = 0;
        data?.filter(fila => (fila.statusAnalise === "EM_VERIFICACAO")).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        setEmVerificacao(qtd);
        
      })
      setUltimaAtualizacao(Date.now());
  }

  const updateDados = () => {

    Requisicao.getAllQtdByUnidadeUsuario()
      .then(data => {
        let qtd = 0;
        data?.filter(fila => (fila.filaAnalise === "EM_ANALISE" && fila.statusAnalise === "EM_EDICAO")).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        if (parseInt(qtd) > parseInt(emAnalise) && alertasConfig === "GERAL") {
          setEmAnalise(qtd);
          window.toastr["info"]("Nova análise criada em sua unidade!");
        } else {
          setEmAnalise(qtd);
        }

        // qtd = 0;
        // data?.filter(fila => (fila.filaAnalise === "EM_ANALISE" && fila.statusAnalise === "EM_VERIFICACAO")).forEach(fila => {
        //   qtd += fila?.quantidade ?? 0;
        // });
        // if (qtd > emAnaliseVerificacao) {
        //   setEmAnaliseVerificacao(qtd);
        //   window.toastr["info"]("Nova análise para verificação em sua unidade!");
        // } else {
        //   setEmAnaliseVerificacao(qtd);
        // }

        qtd = 0;
        data?.filter(fila => ((fila.filaAnalise === "EM_REVISAO" && fila.statusAnalise === "EM_EDICAO") || 
          (fila.filaAnalise === "EM_REVISAO" && fila.statusAnalise === "ENCAMINHADA"))).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        if (qtd > emRevisao && alertasConfig === "GERAL") {
          setEmRevisao(qtd);
          window.toastr["info"]("Nova análise para revisão!");
        } else {
          setEmRevisao(qtd);
        }

        // qtd = 0;
        // data?.filter(fila => (fila.filaAnalise === "EM_REVISAO" && fila.statusAnalise === "EM_VERIFICACAO")).forEach(fila => {
        //   qtd += fila?.quantidade ?? 0;
        // });
        // if (qtd > emRevisaoVerificacao) {
        //   setEmRevisaoVerificacao(qtd);
        //   window.toastr["info"]("Nova Revisão de análise para verificação em sua unidade!");
        // } else {
        //   setEmRevisaoVerificacao(qtd);
        // }

        qtd = 0;
        data?.filter(fila => ((fila.filaAnalise === "EM_MANIFESTACAO" && fila.statusAnalise === "EM_EDICAO") || 
          (fila.filaAnalise === "EM_MANIFESTACAO" && fila.statusAnalise === "ENCAMINHADA"))).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        if (qtd > emManifestacao && alertasConfig === "GERAL") {
          setEmManifestacao(qtd);
          window.toastr["info"]("Nova manifestação criada em sua unidade!");
        } else {
          setEmManifestacao(qtd);
        }

        // qtd = 0;
        // data?.filter(fila => (fila.filaAnalise === "EM_MANIFESTACAO" && fila.statusAnalise === "EM_VERIFICACAO")).forEach(fila => {
        //   qtd += fila?.quantidade ?? 0;
        // });
        // if (qtd > emManifestacaoVerificacao) {
        //   setEmManifestacaoVerificacao(qtd);
        //   window.toastr["info"]("Nova manifestação para verificação em sua unidade!");
        // } else {
        //   setEmManifestacaoVerificacao(qtd);
        // }

        qtd = 0;
        data?.filter(fila => (fila.filaAnalise === "FINALIZADO" || fila.statusAnalise === "FINALIZADA")).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        setFinalizado(qtd);

        qtd = 0;
        data?.filter(fila => ((fila.filaAnalise === "EM_REVISAO" && fila.statusAnalise === "ENCAMINHADA"))).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        if (qtd > encaminhadas && alertasConfig === "GERAL") {
          setEncaminhadas(qtd);
          window.toastr["info"]("Analise encaminhada.");
        } else {
          setEncaminhadas(qtd);
        }

        qtd = 0;
        data?.filter(fila => (fila.statusAnalise === "EM_VERIFICACAO")).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        if (qtd > emVerificacao && alertasConfig === "GERAL") {
          setEmVerificacao(qtd);
          window.toastr["info"]("Nova Análise para verificação.");
        } else {
          setEmVerificacao(qtd);
        }
        
      });
      setUltimaAtualizacao(Date.now());

  }

  const carregarDadosIndividual = () => {
    Requisicao.getAllQtdByUnidadeUsuarioAndUsuario()
      .then(data => {
        let qtd = 0;
        data?.filter(fila => (fila.filaAnalise === "EM_ANALISE" && fila.statusAnalise === "EM_EDICAO")).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        setEmAnaliseIndividual(qtd);

        // qtd = 0;
        // data?.filter(fila => (fila.filaAnalise === "EM_ANALISE" && fila.statusAnalise === "EM_VERIFICACAO")).forEach(fila => {
        //   qtd += fila?.quantidade ?? 0;
        // });
        // setEmAnaliseVerificacaoIndividual(qtd);

        qtd = 0;
        data?.filter(fila => ((fila.filaAnalise === "EM_REVISAO" && fila.statusAnalise === "EM_EDICAO") || 
          (fila.filaAnalise === "EM_REVISAO" && fila.statusAnalise === "ENCAMINHADA"))).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        setEmRevisaoIndividual(qtd);

        // qtd = 0;
        // data?.filter(fila => (fila.filaAnalise === "EM_REVISAO" && fila.statusAnalise === "EM_VERIFICACAO")).forEach(fila => {
        //   qtd += fila?.quantidade ?? 0;
        // });
        // setEmRevisaoVerificacaoIndividual(qtd);

        qtd = 0;
        data?.filter(fila => ((fila.filaAnalise === "EM_MANIFESTACAO" && fila.statusAnalise === "EM_EDICAO") || 
          (fila.filaAnalise === "EM_MANIFESTACAO" && fila.statusAnalise === "ENCAMINHADA"))).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        setEmManifestacaoIndividual(qtd);

        // qtd = 0;
        // data?.filter(fila => (fila.filaAnalise === "EM_MANIFESTACAO" && fila.statusAnalise === "EM_VERIFICACAO")).forEach(fila => {
        //   qtd += fila?.quantidade ?? 0;
        // });
        // setEmManifestacaoVerificacaoIndividual(qtd);

        qtd = 0;
        data?.filter(fila => (fila.filaAnalise === "FINALIZADO" || fila.statusAnalise === "FINALIZADA")).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        setFinalizadoIndividual(qtd);

        qtd = 0;
        data?.filter(fila => ((fila.filaAnalise === "EM_REVISAO" && fila.statusAnalise === "ENCAMINHADA"))).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        setEncaminhadasIndividual(qtd);
        
        qtd = 0;
        data?.filter(fila => (fila.statusAnalise === "EM_VERIFICACAO")).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        setEmVerificacaoIndividual(qtd);
      });
      setUltimaAtualizacao(Date.now());
  }

  const updateDadosIndividual = () => {

    Requisicao.getAllQtdByUnidadeUsuarioAndUsuario()
      .then(data => {
        let qtd = 0;
        data?.filter(fila => (fila.filaAnalise === "EM_ANALISE" && fila.statusAnalise === "EM_EDICAO")).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        if (parseInt(qtd) > parseInt(emAnaliseIndividual) && alertasConfig === "INDIVIDUAL") {
          setEmAnaliseIndividual(qtd);
          window.toastr["info"]("Nova análise criada em sua unidade!");
        } else {
          setEmAnaliseIndividual(qtd);
        }

        // qtd = 0;
        // data?.filter(fila => (fila.filaAnalise === "EM_ANALISE" && fila.statusAnalise === "EM_VERIFICACAO")).forEach(fila => {
        //   qtd += fila?.quantidade ?? 0;
        // });
        // if (qtd > emAnaliseVerificacaoIndividual) {
        //   setEmAnaliseVerificacaoIndividual(qtd);
        //   window.toastr["info"]("Nova análise para verificação em sua unidade!");
        // } else {
        //   setEmAnaliseVerificacaoIndividual(qtd);
        // }

        qtd = 0;
        data?.filter(fila => ((fila.filaAnalise === "EM_REVISAO" && fila.statusAnalise === "EM_EDICAO") || 
          (fila.filaAnalise === "EM_REVISAO" && fila.statusAnalise === "ENCAMINHADA"))).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        if (qtd > emRevisaoIndividual && alertasConfig === "INDIVIDUAL") {
          setEmRevisaoIndividual(qtd);
          window.toastr["info"]("Nova análise para revisão!");
        } else {
          setEmRevisaoIndividual(qtd);
        }

        // qtd = 0;
        // data?.filter(fila => (fila.filaAnalise === "EM_REVISAO" && fila.statusAnalise === "EM_VERIFICACAO")).forEach(fila => {
        //   qtd += fila?.quantidade ?? 0;
        // });
        // if (qtd > emRevisaoVerificacaoIndividual) {
        //   setEmRevisaoVerificacaoIndividual(qtd);
        //   window.toastr["info"]("Nova Revisão de análise para verificação em sua unidade!");
        // } else {
        //   setEmRevisaoVerificacaoIndividual(qtd);
        // }

        qtd = 0;
        data?.filter(fila => ((fila.filaAnalise === "EM_MANIFESTACAO" && fila.statusAnalise === "EM_EDICAO") || 
          (fila.filaAnalise === "EM_MANIFESTACAO" && fila.statusAnalise === "ENCAMINHADA"))).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        if (qtd > emManifestacaoIndividual && alertasConfig === "INDIVIDUAL") {
          setEmManifestacaoIndividual(qtd);
          window.toastr["info"]("Nova manifestação criada em sua unidade!");
        } else {
          setEmManifestacaoIndividual(qtd);
        }

        // qtd = 0;
        // data?.filter(fila => (fila.filaAnalise === "EM_MANIFESTACAO" && fila.statusAnalise === "EM_VERIFICACAO")).forEach(fila => {
        //   qtd += fila?.quantidade ?? 0;
        // });
        // if (qtd > emManifestacaoVerificacaoIndividual) {
        //   setEmManifestacaoVerificacaoIndividual(qtd);
        //   window.toastr["info"]("Nova manifestação para verificação em sua unidade!");
        // } else {
        //   setEmManifestacaoVerificacaoIndividual(qtd);
        // }

        qtd = 0;
        data?.filter(fila => (fila.filaAnalise === "FINALIZADO" || fila.statusAnalise === "FINALIZADA")).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        setFinalizadoIndividual(qtd);

        qtd = 0;
        data?.filter(fila => ((fila.filaAnalise === "EM_REVISAO" && fila.statusAnalise === "ENCAMINHADA"))).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        if (qtd > encaminhadasIndividual && alertasConfig === "INDIVIDUAL") {
          setEncaminhadasIndividual(qtd);
          window.toastr["info"]("Analise encaminhada.");
        } else {
          setEncaminhadasIndividual(qtd);
        }

        qtd = 0;
        data?.filter(fila => (fila.statusAnalise === "EM_VERIFICACAO")).forEach(fila => {
          qtd += fila?.quantidade ?? 0;
        });
        if (qtd > emVerificacaoIndividual && alertasConfig === "INDIVIDUAL") {
          setEmVerificacaoIndividual(qtd);
          window.toastr["info"]("Nova Análise para verificação.");
        } else {
          setEmVerificacaoIndividual(qtd);
        }
      });
      setUltimaAtualizacao(Date.now());

  }

  const isAdministrador = () => {
    if (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR") {
      return true;
    }
    return false;
  }

  const isAnalistaOuResponsavelUDCI = () => {
    if (usuario?.tipoUsuario === "SCF_ANALISTA_UDCI" || usuario?.tipoUsuario === "SCF_REVISOR_UDCI") {
      return true;
    }
    return false;
  }

  // data-overlayscrollbars-initialize overflow-auto
  return (
    <div>
      <aside id="filas-control-sidebar" className="control-sidebar control-sidebar-dark overflow-auto">
        <div className="p-4">
          
          <div className='row'>
            <h5 className='mt-2'>Filas&nbsp;&nbsp;</h5>
            <button type='button' className={(!filaIndividual) ? 'btn text-warning' : 'btn text-white'}
            onClick={() => setFilaIndividual(false)}
            >Geral</button>
            <span className='mt-1'> / </span>
            <button type='button' className={(filaIndividual) ? 'btn text-warning' : 'btn text-white'}
            onClick={() => setFilaIndividual(true)}
            >Individual</button>
          </div>
          <br />
          {
            (filaIndividual) ?
            <>
              <div className="list-group">
                <Link to={'/analise/filas/individual?fila=EM_ANALISE&status=EM_EDICAO'} 
                className="list-group-item d-flex justify-content-end align-items-center text-sm" data-widget="control-sidebar" data-slide="true">
                  Em Análise
                  <span className="ml-4 badge badge-primary badge-pill">{emAnaliseIndividual}</span>
                </Link>
                <Link to={'/analise/filas/individual?status=EM_VERIFICACAO'} 
                className="list-group-item d-flex justify-content-end align-items-center text-sm" data-widget="control-sidebar" data-slide="true">
                  Em Verificação
                  <span className="ml-4 badge badge-primary badge-pill">{emVerificacaoIndividual}</span>
                </Link>
                {
                  (isAnalistaOuResponsavelUDCI()) ? 
                  <>
                    <Link to={'/analise/filas/individual?fila=EM_REVISAO&status=ENCAMINHADA'} 
                    className="list-group-item d-flex justify-content-end align-items-center text-sm" data-widget="control-sidebar" data-slide="true">
                      Encaminhadas
                      <span className="ml-4 badge badge-primary badge-pill">{encaminhadasIndividual}</span>
                    </Link>
                  </> : 
                  <>
                    <Link to={'/analise/filas/individual?fila=EM_REVISAO&status=EM_EDICAO&status2=ENCAMINHADA'} 
                    className="list-group-item d-flex justify-content-end align-items-center text-sm" data-widget="control-sidebar" data-slide="true">
                      Em Revisão
                      <span className="ml-4 badge badge-primary badge-pill">{emRevisaoIndividual}</span>
                    </Link>
                  </>
                }

                {/* <Link to={'/analise/filas/individual?fila=EM_REVISAO&status=EM_VERIFICACAO'} 
                className="list-group-item d-flex justify-content-end align-items-center text-sm" data-widget="control-sidebar" data-slide="true">
                  Em Verificação da Revisão no OC
                  <span className="ml-4 badge badge-primary badge-pill">{emRevisaoVerificacaoIndividual}</span>
                </Link> */}
                <Link to={'/analise/filas/individual?fila=EM_MANIFESTACAO&status=EM_EDICAO&status2=ENCAMINHADA'} 
                className="list-group-item d-flex justify-content-end align-items-center text-sm" data-widget="control-sidebar" data-slide="true">
                  Em Manifestação
                  <span className="ml-4 badge badge-primary badge-pill">{emManifestacaoIndividual}</span>
                </Link>
                {/* <Link to={'/analise/filas/individual?fila=EM_MANIFESTACAO&status=EM_VERIFICACAO'} 
                className="list-group-item d-flex justify-content-end align-items-center text-sm" data-widget="control-sidebar" data-slide="true">
                  Em Cumprimento das Recomendações (Verificação)
                  <span className="ml-4 badge badge-primary badge-pill">{emManifestacaoVerificacaoIndividual}</span>
                </Link> */}
                <Link to={'/analise/filas/individual?fila=FINALIZADO&status=FINALIZADA'} 
                className="list-group-item d-flex justify-content-end align-items-center text-sm" data-widget="control-sidebar" data-slide="true">
                  Finalizado
                  <span className="ml-4 badge badge-primary badge-pill">{finalizadoIndividual}</span>
                </Link>
              </div>
            </> :
            <div className="list-group">
              <Link to={'/analise/filas?fila=EM_ANALISE&status=EM_EDICAO'} 
              className="list-group-item d-flex justify-content-end align-items-center text-sm" data-widget="control-sidebar" data-slide="true">
                Em Análise
                <span className="ml-4 badge badge-primary badge-pill">{emAnalise}</span>
              </Link>
              <Link to={'/analise/filas?status=EM_VERIFICACAO'} 
              className="list-group-item d-flex justify-content-end align-items-center text-sm" data-widget="control-sidebar" data-slide="true">
                Em Verificação
                <span className="ml-4 badge badge-primary badge-pill">{emVerificacao}</span>
              </Link>
              {
                (isAnalistaOuResponsavelUDCI()) ? 
                <>
                  <Link to={'/analise/filas?fila=EM_REVISAO&status=ENCAMINHADA'} 
                  className="list-group-item d-flex justify-content-end align-items-center text-sm" data-widget="control-sidebar" data-slide="true">
                    Encaminhadas
                    <span className="ml-4 badge badge-primary badge-pill">{encaminhadas}</span>
                  </Link>
                </> : 
                <>
                  <Link to={'/analise/filas?fila=EM_REVISAO&status=EM_EDICAO&status2=ENCAMINHADA'} 
                  className="list-group-item d-flex justify-content-end align-items-center text-sm" data-widget="control-sidebar" data-slide="true">
                    Em Revisão
                    <span className="ml-4 badge badge-primary badge-pill">{emRevisao}</span>
                  </Link>
                </>
              }
            

              {/* <Link to={'/analise/filas?fila=EM_REVISAO&status=EM_VERIFICACAO'} 
              className="list-group-item d-flex justify-content-end align-items-center text-sm" data-widget="control-sidebar" data-slide="true">
                Em Verificação da Revisão no OC
                <span className="ml-4 badge badge-primary badge-pill">{emRevisaoVerificacao}</span>
              </Link> */}
              <Link to={'/analise/filas?fila=EM_MANIFESTACAO&status=EM_EDICAO&status2=ENCAMINHADA'} 
              className="list-group-item d-flex justify-content-end align-items-center text-sm" data-widget="control-sidebar" data-slide="true">
                Em Manifestação
                <span className="ml-4 badge badge-primary badge-pill">{emManifestacao}</span>
              </Link>
              {/* <Link to={'/analise/filas?fila=EM_MANIFESTACAO&status=EM_VERIFICACAO'} 
              className="list-group-item d-flex justify-content-end align-items-center text-sm" data-widget="control-sidebar" data-slide="true">
                Em Cumprimento das Recomendações (Verificação)
                <span className="ml-4 badge badge-primary badge-pill">{emManifestacaoVerificacao}</span>
              </Link> */}
              <Link to={'/analise/filas?fila=FINALIZADO&status=FINALIZADA'} 
              className="list-group-item d-flex justify-content-end align-items-center text-sm" data-widget="control-sidebar" data-slide="true">
                Finalizado
                <span className="ml-4 badge badge-primary badge-pill">{finalizado}</span>
              </Link>
            </div>
          }
        </div>
        <div className='p-4'>
          <h6>Alertas de nova análise</h6>
          <div className="form-group">
            <div className="form-check">
              <input type="radio" id="radioAlertaNovaAnaliseGeral" className="form-check-input" name="radioAlertasNovaAnalise" value="GERAL" defaultChecked 
              onChange={(e)=> {
                setAlertasConfig(e.target.value);
              }}/>
              <label className="form-check-label" htmlFor="radioAlertaNovaAnaliseGeral">
                Unidade
              </label>
            </div>
            <div className="form-check">
              <input type="radio" id="radioAlertaNovaAnaliseIndividual" className="form-check-input" name="radioAlertasNovaAnalise" value="INDIVIDUAL" 
              onChange={(e)=> {
                setAlertasConfig(e.target.value);
              }}/>
              <label className="form-check-label" htmlFor="radioAlertaNovaAnaliseIndividual">
                Individual
              </label>
            </div>
            <div className="form-check">
              <input type="radio" id="radioAlertaNovaAnaliseDesligado" className="form-check-input" name="radioAlertasNovaAnalise" value="DESLIGADO" 
              onChange={(e)=> {
                setAlertasConfig(e.target.value);
              }}/>
              <label className="form-check-label" htmlFor="radioAlertaNovaAnaliseDesligado">
                Desligado
              </label>
            </div>
          </div>
        </div>
        <div className='px-4'>
          <div className='row'>
            <h5 className='mt-2'>Acompanhamento</h5>
            <div className="list-group col">
              <Link to={'/analise/filas/individual/abertas?dias=21'} 
                className="list-group-item text-center text-sm" data-widget="control-sidebar" data-slide="true">
                Análises em Aberto
              </Link>
              {
                (usuario.unidadeId === 1) ?
                  <Link to={'/processo/emobservacao'} 
                    className="list-group-item text-center text-sm" data-widget="control-sidebar" data-slide="true">
                      Processos em Observação
                  </Link> : <></>
              }
            </div>
          </div>
        </div>
      </aside>
    </div>
  )
}
