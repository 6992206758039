import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Main from './Main';
import { BrowserRouter } from 'react-router-dom';

window.servidor = 'https://ailrxm.hospedagemelastica.com.br/sistemacongem-v1';
// window.servidor = 'http://localhost:8080';
//window.servidor = ''

window.arvoreiniciada = false;

// console.log('window.servidor', window.servidor);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <Main />
    </BrowserRouter>
  // </React.StrictMode>
);

