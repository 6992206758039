import React, { useRef, useState } from 'react'
import Nota from './Nota';

export default function BlocoDeNotas({usuario, autenticado}) {

  const [notas, setNotas] = useState([]);
  const ordem = useRef(1);


  const handleChangeNota = (n) => {
    setNotas(notas?.map((nota) => {
        if(nota.ordem === n.ordem){
          return n;
        } else {
          return nota;
        }
      })
    )
  }

  const adicionarNota = () => {
    setNotas([
      ...notas,
      {id:null, ordem: ordem.current, texto: ""}
    ])
    ordem.current = ordem.current + 1;
  }

  return (
    <div className='col' style={{maxHeight: '80vh', overflowY: 'auto'}}>
      <div>
        {
          notas && notas.map((nota) => (
            <Nota key={nota.ordem} nota={nota} onChange={handleChangeNota} />
            ))
        }
      </div>
      <div className='my-2 text-center'>
        <button type='button' className='btn btn-outline-secondary' onClick={adicionarNota}>+ Nota</button>
      </div>
    </div>
  )
}
