import React, { useEffect, useState } from 'react'
import LoadingElement from '../../components/LoadingElement'
import Requisicao from '../../helpers/Requisicao';
import Autenticar from '../../login/Autenticar';
import { Link, useSearchParams } from 'react-router-dom';
import Utilidades from '../../helpers/Utilidades';
import { isValid, parseISO } from 'date-fns';
import DiasUteisComponent from './DiasUteisComponent';

export default function Fila() {

  const [loading, setLoading] = useState(false);

  const [nomesTipos, setNomesTipos] = useState({});
  const [nomesFilas, setNomesFilas] = useState({});
  const [nomesStatus, setNomesStatus] = useState({});
  // const nomesStatus = {
  //   "EM_EDICAO": "Edição",
  //   "EM_VERIFICACAO": "Verificação",
  //   "ENCAMINHADA": "Encaminhada",
  //   "FINALIZADA": "Finalizada"
  // }

  const [nomeFila, setNomeFila] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();
  const [pageSize, setPageSize] = useState("10");

  const [resultado, setResultado] = useState({});


  useEffect(() => {
    window.$('#changePageModal').on('hidden.bs.modal', function (e) {
      document.getElementById("changePageModalInput").value = "";
    })
    carregarTiposAnalise();
    carregarFilasAnalise();
    carregarStatusAnalise();
    pesquisar();
    

    document.title = 'Fila - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
    // eslint-disable-next-line
  }, []);

  const carregarTiposAnalise = () => {
    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/analise/tiposanalise";

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        let nomesTiposReq = {};
        data.forEach((tipo) => {
          nomesTiposReq[tipo.value] = tipo.nome;
        });
        setNomesTipos(nomesTiposReq);
      })
      .catch((erro) => console.log(erro));
  };

  const carregarFilasAnalise = () => {
    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/analise/filasanalise";

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        let nomesFilasReq = {};
        data.forEach((tipo) => {
          nomesFilasReq[tipo.value] = tipo.nome;
        });
        setNomesFilas(nomesFilasReq);
      })
      .catch((erro) => console.log(erro));
  };

  const carregarStatusAnalise = () => {
    Requisicao.getListaStatusAnalise()
      .then((data) => {
        let nomesStatusReq = {};
        data.forEach((tipo) => {
          nomesStatusReq[tipo.value] = tipo.nome;
        });
        setNomesStatus(nomesStatusReq);
      })
      .catch((erro) => console.log(erro));
  }

  useEffect(() => {
    getNomeFila();
    // eslint-disable-next-line
  }, [nomesFilas, nomesStatus]);

  useEffect(() => {
    if (searchParams != null) {
      if (searchParams.has("s") && searchParams.get("s") !== "") {
        setPageSize(searchParams.get("s"));
      }
      pesquisar();
    }
    getNomeFila();
    // eslint-disable-next-line
  }, [searchParams]);



  const getPagination = () => {
    let totalPaginas = parseInt(resultado.totalPaginas) ?? 0;
    let pag = []

    if (parseInt(resultado.numeroPagina) === 0) {
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Primeira</button></li>);
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Anterior</button></li>);
    } else {
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(0)}>Primeira</button></li>);
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado?.numeroPagina) - 1)}>Anterior</button></li>);
    }

    // for
    if (totalPaginas >= 9) {
      if (parseInt(resultado.numeroPagina) >= 5) {
        pag.push(<li className="page-item"><button type="button" className="page-link" data-toggle="modal" data-target="#changePageModal">...</button></li>);
      }

      for (let i = Math.max(parseInt(resultado.numeroPagina) - 4, 0); i < parseInt(resultado.numeroPagina); i++) {
        pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
      }

      pag.push(<li className="page-item active"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado.numeroPagina))}>{parseInt(resultado.numeroPagina) + 1}</button></li>);

      for (let i = parseInt(resultado.numeroPagina) + 1; i <= parseInt(resultado.numeroPagina) + 4 && i < totalPaginas; i++) {
        pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
      }

      if ((totalPaginas - parseInt(resultado.numeroPagina)) >= 6) {
        pag.push(<li className="page-item"><button type="button" className="page-link" data-toggle="modal" data-target="#changePageModal">...</button></li>);
      }
    } else {
      for (let i = 0; i < totalPaginas; i++) {
        if (i === parseInt(resultado.numeroPagina)) {
          pag.push(<li className="page-item active"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
        } else {
          pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
        }
      }
    }

    if (parseInt(resultado?.numeroPagina) + 1 === parseInt(resultado?.totalPaginas)) {
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Próxima</button></li>);
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Última</button></li>);
    } else {
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado?.numeroPagina) + 1)}>Próxima</button></li>);
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado?.totalPaginas) - 1)}>Última</button></li>);
    }

    return pag;
  }

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
    let params = searchParams;
    params.set("p", "0");
    params.set("s", e.target.value);
    setSearchParams(params);
  }

  const handleChangePage = (page) => {
    let params = searchParams;
    params.set("p", page);
    setSearchParams(params);
  }

  const pesquisar = () => {
    setLoading(true);
    setResultado({});

    Requisicao.getFilas(searchParams.toString())
      .then(data => {
        setResultado(data);
        setLoading(false);
      })
      .catch(erro => {
        console.log('erro', erro);
        setLoading(false);
      })
  }

  const getDiasUnidade = (analise) => {
    if(analise?.dataEntrada != null){
      let dataEntrada = parseISO(analise.dataEntrada);
      let dataSaida = (analise?.dataSaida != null) ? parseISO(analise.dataSaida) : Date.now();



      return Math.floor((dataSaida - dataEntrada)/(1000*60*60*24)).toString();
    } else {
      return "";
    }
  }

  const getNomeFila = () => {
    let fila = searchParams.has("fila") ? searchParams.get("fila") : "";
    let status = searchParams.has("status") ? searchParams.get("status") : "";
    let filaNome = nomesFilas[fila] ?? "";
    // let filaNome = EnumUtils.getNomeEnum(fila, await Requisicao.getListaFilasAnalise());
    let statusNome = nomesStatus[status] ?? ""

    setNomeFila((filaNome !== "") ? filaNome + ' - ' + statusNome : statusNome);

  }




  return (
    <>
      <div className="modal fade" id="changePageModal" tabIndex={-1} aria-labelledby="changePageModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <form className="modal-content" onSubmit={(e) => {
            e.preventDefault();
            let page = parseInt(document.getElementById("changePageModalInput").value);
            if (page > 0 && page <= parseInt(resultado?.totalPaginas)) {
              handleChangePage(page - 1);
              // document.getElementById("changePageModalInput").value = "";
              window.$("#changePageModal").modal("hide");
            }
          }}>
            <div className="modal-header">
              <h5 className="modal-title" id="changePageModalLabel">Insira o nº da página</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input className="form-control" id="changePageModalInput" type="number" onWheel={(e) => e.target.blur()} placeholder={`1 - ${resultado?.totalPaginas}`} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
              <button type="submit" className="btn btn-primary">Ir</button>
            </div>
          </form>
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Análises na Fila</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Análises na Fila</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="card mx-2">
          <div className="card-body">
            <h4 className='my-3'>{nomeFila}</h4>
            <hr />
            <div className='my-3'>
              {
                (loading) ?
                  <LoadingElement /> :
                  <>
                    {
                      (resultado != null && resultado.elementos != null && resultado.elementos.length > 0) ?
                        <table className="table">
                          <thead>
                          <tr>
                            <th>ID</th>
                            <th>Processo</th>
                            <th>Nome</th>
                            <th>Tipo de Análise</th>
                            <th>Data Entrada</th>
                            <th>Dias</th>
                            <th>Valor</th>
                            <th>Analista</th>
                            <th>Última Modificação</th>
                            {/* <th>Estado</th> */}
                            <th>Botões</th>
                          </tr>
                          </thead>
                          <tbody className="align-middle">
                            {
                              resultado.elementos && resultado.elementos.map((analise) => (
                                <tr key={analise.id}>
                                  <td>{analise.id}</td>
                                  <td><Link to={`/processo/pesquisar?numero=${analise?.numeroProcesso}`} className="text-info">{analise?.numeroProcesso}</Link></td>
                                  <td>
                                    {
                                      (analise?.statusAnalise === "ENCAMINHADA") ? 
                                      <span>
                                        <span className='text-success'>Encaminhada</span> - {analise?.nome ?? "Sem informações"}
                                      </span> :
                                      <>{analise?.nome ?? "Sem informações"}</>
                                    
                                    }
                                  </td>
                                  <td>{nomesTipos[analise.tipoAnalise] ?? ""}</td>
                                  <td>{parseISO(analise?.dataEntrada).toLocaleDateString('pt-BR')}</td>
                                  {/* <td><DiasUteisComponent dataEntrada={analise?.dataEntrada} dataSaida={analise?.dataSaida}/></td> */}
                                  <td>{getDiasUnidade(analise)}</td>
                                  <td>R$&nbsp;{Utilidades.aplicarMascaraValor(analise.valorEstimado)}</td>
                                  <td>{analise.nomeAnalista}</td>
                                  <td>{analise.usuarioUltimaEdicao}{isValid(parseISO(analise?.dataModificacao)) ? " - "+parseISO(analise?.dataModificacao).toLocaleString('pt-BR'): ""}</td>
                                  {/* <td className={(analise?.statusAnalise === "ENCAMINHADA") ? "text-primary" : ""}>{nomesStatus[analise?.statusAnalise] ?? ""}</td> */}
                                  <td className="project-actions text-right">
                                    <Link className="btn btn-warning btn-sm mr-2" to={`/analise/${analise.id}`}>
                                      <i className="far fa-file-alt"></i>
                                      &nbsp;Abrir
                                    </Link>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table> :
                        <p className='text-center'>Nenhuma análise encontrado</p>
                    }
                  </>
              }
            </div>
            {/* <hr /> */}
          </div>
        </div>
            <div className='mx-2 my-3'>
              <div className='px-2 row justify-content-between'>
                {
                  resultado?.elementos != null && resultado?.elementos.length > 0 ?
                    <nav aria-label="Page navigation">
                      <ul className="pagination row px-2">
                        {

                          getPagination().map((pag, index) => {
                            return <React.Fragment key={index}>
                              {pag}
                            </React.Fragment>
                          })

                        }
                      </ul>
                    </nav>
                    : <div></div>
                }
                <select id="comboboxPageSize" className="form-control col-md-1 text-center" value={pageSize} onChange={handleChangePageSize}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
      </div>
    </>
  )
}
