import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import Tippy from '@tippyjs/react';
import LoadingElement from '../../components/LoadingElement';
import Requisicao from '../../helpers/Requisicao';
import EnumUtils from '../../helpers/EnumUtils';
import Utilidades from '../../helpers/Utilidades';
import { parseISO, isValid } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import CampoComplementar from './campos/CampoComplementar';
import Bloco from './blocos/Bloco';
import Autenticar from '../../login/Autenticar';
import EncaminharModalBody from './EncaminharModalBody';
import SummernoteEditor from '../../components/SummernoteEditor';



function blocosReducer(blocos, action) {
  switch (action.type) {
    case 'set_blocos_iniciais': {
      return action.blocos;
    }
    case 'change_bloco': {
      return blocos.map((b) => {
        if (b.ordem === action.bloco.ordem) {
          return action.bloco;
        } else {
          return b;
        }
      });
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}

function camposReducer(campos, action) {
  switch (action.type) {
    case 'set_campos_iniciais': {
      return action.campos;
    }
    case 'change_campo': {
      return campos.map((b) => {
        if (b.ordem === action.campo.ordem) {
          return action.campo;
        } else {
          return b;
        }
      });
    }
    case 'delete_campo': {
      return campos.filter((c) => c.ordem !== action.campo.ordem);
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}


export default function Analise({ usuario, autenticado }) {

  //marcar o botao da area de encaminhamento quando exister texto de encaminhamento

  const { analiseId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState({
    processo: true,
    analise: true,
    modelo: false,
    salvando: false,
    alterandoFila: false
  });

  const [analise, setAnalise] = useState(null);
  const [processo, setProcesso] = useState(null);

  const [blocos, dispatchBlocos] = useReducer(blocosReducer, []);
  const [campos, dispatchCampos] = useReducer(camposReducer, []);

  const [fila, setFila] = useState("EM_ANALISE");
  const [emRevisao, setEmRevisao] = useState(false);

  const [listaModelos, setListaModelos] = useState([]);
  const [idModeloAnalise, setIdModeloAnalise] = useState(null);

  const [textAreaEncaminhamento, setTextAreaEncaminhamento] = useState("");
  const [textAreaEncaminhamentoHTML, setTextAreaEncaminhamentoHTML] = useState("");

  const [dataAnalise, setDataAnalise] = useState(null);
  const [dataRevisao, setDataRevisao] = useState(null);

  const [observacaoTextoInicialExtra, setObservacaoTextoInicialExtra] = useState("");
  const [observacaoTextoFinalExtra, setObservacaoTextoFinalExtra] = useState("");
  const [observacaoTextoEncaminhamento, setObservacaoTextoEncaminhamento] = useState("");
  const [visibleTIE, setVisibleTIE] = useState(false);
  const [visibleTFE, setVisibleTFE] = useState(false);
  const [visibleTEnc, setVisibleTEnc] = useState(false);

  const [editandoNomeAnalise, setEditandoNomeAnalise] = useState(false);

  const [acessoVerificado, setAcessoVerificado] = useState(false);

  const [editandoTextoInicial, setEditandoTextoInicial] = useState(false);
  const [editandoTextoFinal, setEditandoTextoFinal] = useState(false);

  const [novaFila, setNovaFila] = useState("");
  const [novoStatus, setNovoStatus] = useState("EM_EDICAO");
  const [tipoEncaminhamento, setTipoEncaminhamento] = useState("");
  const [orgaoDestino, setOrgaoDestino] = useState("");
  const [assessoriaJuridicaDestino, setAssessoriaJuridicaDestino] = useState("");
  const [orgaoOrcamentarioDestino, setOrgaoOrcamentarioDestino] = useState("");
  const [unidadeDestinoId, setUnidadeDestinoId] = useState("");
  const [observacoes, setObservacoes] = useState("");

  const [unidadeResponsavel, setUnidadeResponsavel] = useState(null);


  const [isConformidade, setIsconformidade] = useState(false);

  const [textoInicial, setTextoInicial] = useState("");
  const [textoInicialHTML, setTextoInicialHTML] = useState("");
  const [textoFinal, setTextoFinal] = useState("");
  const [textoFinalHTML, setTextoFinalHTML] = useState("");
  const [textoInicialExtra, setTextoInicialExtra] = useState("");
  const [textoInicialExtraHTML, setTextoInicialExtraHTML] = useState("");
  const [textoFinalExtra, setTextoFinalExtra] = useState("");
  const [textoFinalExtraHTML, setTextoFinalExtraHTML] = useState("");

  const [alterado, setAlterado] = useState(false);
  const alteracoes = useRef(0);


  // componentDidMount
  useEffect(() => {
    if (autenticado) {
      window.$(".select2bs4").select2({
        theme: "bootstrap4",
        dropdownParent: window.$('#modalAlterarModeloAnalise')
      });
      window.$("#comboboxModeloAnalise").on('change.select2', function (e) {
        setIdModeloAnalise(e.target.value);
      });
      carregarAnalise(analiseId);
    }

    document.title = 'Editar Análise - Sistema de Controle Interno';

    // console.log("events", window.$._data(document, "events"));
    // 
    window.$(document).on("DOMNodeInserted", '.note-editable', function (e) {
      if (e.target.tagName === "SPAN" && e.target.style?.getPropertyValue('background-color') === "") {
        window.$(e.target).replaceWith(window.$(e.target).contents());
      }
    });

    return (() => {
      window.$(document).off("DOMNodeInserted", '.note-editable');
      document.title = 'Sistema de Controle Interno';
    })
    // eslint-disable-next-line
  }, [autenticado, analiseId]);

  useEffect(() => {
    document.title = (alterado ? '* ' : '') + 'Editar Análise - Sistema de Controle Interno';
    console.log('alterado', alterado)

    // return (() => {
    //   if(alterado){
    //     if()
    //   }
    // })
  }, [alterado])

  useEffect(() => {

    if(alteracoes.current > 1 ){
      setAlterado(true);
    }

    alteracoes.current = alteracoes.current + 1;
    // console.log('blocos', blocos)
    // console.log('campos', campos)
    // console.log('textAreaEncaminhamentoHTML', textAreaEncaminhamentoHTML)
    // console.log('textoInicialExtraHTML', textoInicialExtraHTML)
    // console.log('textoFinalExtraHTML', textoFinalExtraHTML)
    
  }, [blocos,
      campos,
      textAreaEncaminhamentoHTML,
      textoInicialExtraHTML,
      textoFinalExtraHTML,
      textoInicialHTML,
      textoFinalHTML])

  useEffect(() => {
    const unloadCallback = (event) => {
        event.preventDefault();
        event.returnValue = "";
        return "";
    };

    // const confirmation = () => {
    //   // alert("Não salvo.")
     
    // }
    
    if(alterado){
      window.addEventListener("beforeunload", unloadCallback);
    }
    else {
      window.removeEventListener("beforeunload", unloadCallback);
    }

    return () => {
    // window.addEventListener("popstate", confirmation());
      window.removeEventListener("beforeunload", unloadCallback);
    }
}, [alterado]);

  useEffect(() => { // refazer isso TODO URGENTE
    // console.log("Verificando acesso...");
    if (!acessoVerificado && processo != null && analise?.filaAnalise != null) {
      if (usuario.tipoUsuario !== "ADMINISTRADOR" && usuario.tipoUsuario !== "SCF_ADMINISTRADOR") {
        // if (Number(usuario.unidadeId) != Number(processo?.unidade?.id)) {
        //   alert("Outra unidade é responsável por esta análise. Você não possui permissão para editá-la.");
        //   navigate("/analise/" + analiseId, { state: { referrer: location }, replace: true });
        // }
        // if (usuario.tipoUsuario == "SCF_ANALISTA_UDCI" && analise.filaAnalise !== "EM_ANALISE") {
        //   alert("Não é possível editar esta análise no momento. Você não possui permissão para editá-la");
        //   navigate("/analise/" + analiseId, { state: { referrer: location }, replace: true });
        // }
        // if (analise.filaAnalise === "EM_VERIFICACAO" && usuario.tipoUsuario !== "SCF_REVISOR_UDCI") {
        //   alert("Esta análise encontra-se em revisão pelo responsável na UDCI.\n" +
        //     "Caso seja necessária a mudança do estado da análise, contate o Órgão Central de Controle Interno e informe o id da análise.\n" +
        //     "ID da Análise: " + analiseId);
        //   navigate("/analise/" + analiseId, { state: { referrer: location }, replace: true });
        // }
        // if (analise.filaAnalise === "EM_VERIFICACAO_REVISAO" && usuario.tipoUsuario !== "SCF_REVISOR_UC") {
        //   alert("Esta análise encontra-se em revisão pelo responsável no Órgão Central de Controle Interno.\n" +
        //     "Caso seja necessária a mudança do estado da análise, contate o Órgão Central de Controle  Internoe informe o id da análise.\n" +
        //     "ID da Análise: " + analiseId);
        //   navigate("/analise/" + analiseId, { state: { referrer: location }, replace: true });
        // }
        if (analise?.desativada === true) {
          alert("Análise desativada. Não é possível editar esta análise.\nCaso necessite editá-la, contate o Órgão Central de Controle Interno.");
          navigate("/analise/" + analiseId, { state: { referrer: location }, replace: true });
        }
        if (usuario?.setor !== "FISCALIZACAO_E_CONTROLE") {
          alert("Você não possui permissão para editar esta análise.");
          navigate("/analise/" + analiseId, { state: { referrer: location }, replace: true });
        }
        if (analise?.statusAnalise === "FINALIZADA") {
          alert("Não é possível editar esta análise no momento. Esta análise está fechada para edição.\nCaso necessite abrí-la para edição, contate o Órgão Central de Controle Interno.");
          navigate("/analise/" + analiseId, { state: { referrer: location }, replace: true });
        }
        if (analise.filaAnalise === "FINALIZADO") {
          alert("Esta análise está finalizada. Não é possível editá-la.\nCaso necessite abrí-la para edição, contate o Órgão Central de Controle Interno.");
          navigate("/analise/" + analiseId, { state: { referrer: location }, replace: true });
        }
        if (Number(usuario.unidadeId) !== Number(analise?.unidadeResponsavelId)) {
          alert("Outro órgão/unidade é responsável por esta análise.\nVocê não possui permissão para editá-la.");
          navigate("/analise/" + analiseId, { state: { referrer: location }, replace: true });
        }
        if (usuario.tipoUsuario === "SCF_ANALISTA_UDCI" && analise.statusAnalise !== "EM_EDICAO") { // ???????????????????????????????????????? TODO
          alert("Não é possível editar esta análise no momento.\nVocê não possui permissão para editá-la");
          navigate("/analise/" + analiseId, { state: { referrer: location }, replace: true });
        }
        if ((usuario.tipoUsuario === "SCF_ANALISTA_UDCI" || usuario.tipoUsuario === "SCF_REVISOR_UDCI") && (analise.filaAnalise === "EM_REVISAO" || analise.filaAnalise === "EM_VERIFICACAO_REVISAO")) {
          alert("Não é possível editar esta análise no momento.\nVocê não possui permissão para editá-la");
          navigate("/analise/" + analiseId, { state: { referrer: location }, replace: true });
        }
      }
    }
  }, [usuario, processo, analise?.filaAnalise, analise?.statusAnalise, acessoVerificado, analise?.unidadeResponsavelId, analise?.desativada, analiseId, location, navigate]);

  const carregarAnalise = (id) => {
    Requisicao.getAnalisePorId(id)
      .then(data => {
        console.log('analise', data);
        if (data != null) {
          setLoading({
            ...loading,
            analise: false
          })
          // if(validarPermissoes(data)){
          setupAnalise(data);
          // } else {
          // navigate("/analise/" + analiseId, { state: { referrer: location }, replace: true });
          // }
        } else {
          alert("Análise não econtrada ou você não tem permissão para edtitá-la.");
          navigate("/processo/pesquisar", { state: { referrer: location }, replace: true });
        }
      })
      .catch((erro) => console.log(erro));
  }

  const carregarProcessoBasico = (id) => {
    Requisicao.getProcessoDadosBasicosPorId(id)
      .then(data => {
        if (data != null) {
          setLoading({
            ...loading,
            analise: false,
            processo: false
          })
          // console.log('processo', data)
          setupProcesso(data);
        } else {
          console.log("Processo vinculado à analise não encontrado.\n" +
            "Informe este erro ao administrador do sistema.\n" +
            "ID da análise: " + analiseId)
          navigate("/processo/pesquisar", { state: { referrer: location }, replace: true });
        }
      })
      .catch((erro) => console.log(erro));
  }

  const carregarListaModelos = (tipo, lei) => {
    Requisicao.getListaModelosLicitacaoPorTipoELei(tipo, lei)
      .then(data => {
        setListaModelos(data);
      })
      .catch((erro) => {
        console.log(erro);
      })
  }

  const carregarListaUsuariosPorUnidadeId = (unidadeId) => {
    Requisicao.getListaUsuarioPorUnidadeId(unidadeId)
      .then(data => {

      })
      .catch(erro => console.log('erro', erro));
  }

  const setupAnalise = async (a) => {
    if (a.processoId != null) {
      carregarProcessoBasico(a.processoId);
    } else {
      alert("Processo vinculado à analise não encontrado.\n" +
        "Informe este erro ao administrador do sistema.\n" +
        "ID da análise: " + analiseId)
      navigate("/processo/pesquisar", { state: { referrer: location }, replace: true });
    }

    if (a.filaAnalise != null) {
      setFila(a.filaAnalise);
      // if (a.filaAnalise === "EM_VERIFICACAO_REVISAO" || a.filaAnalise === "EM_VERIFICACAO" || (a.filaAnalise === "EM_MANIFESTACAO" && a.statusAnalise === "EM_EDICAO_REVISAO")) {
      //   setEmRevisao(true);
      // } else {
      //   setEmRevisao(false);
      // }
      if (a.statusAnalise === "EM_VERIFICACAO") {
        setEmRevisao(true);
      } else {
        setEmRevisao(false);
      }
    }

    // Setar o nome dos enums lei, modalidade e tipo
    a.leiLicitacaoNome = await EnumUtils.getNomeLeiLicitacao(a.leiLicitacao)
      .catch(erro => {
        console.log('erro', erro);
        a.leiLicitacaoNome = a.leiLicitacao;
      });

    a.modalidadeLicitacaoNome = await EnumUtils.getNomeModalidadeLicitacao(a.modalidadeLicitacao)
      .catch(erro => {
        console.log('erro', erro);
        a.modalidadeLicitacaoNome = a.modalidadeLicitacao;
      });

    a.tipoAnaliseNome = await EnumUtils.getNomeTipoAnalise(a.tipoAnalise)
      .catch(erro => {
        console.log('erro', erro);
        a.tipoAnaliseNome = a.tipoAnalise;
      });

    let uniResp = await Requisicao.getUnidadeById(a?.unidadeResponsavelId)
      .catch(erro => {
        console.log('erro', erro);

      })
    a.unidadeResponsavelNome = await uniResp?.nome;
    setUnidadeResponsavel(uniResp);


    // a.unidadeResponsavelNome = await Requisicao.getNomeUnidadeById(a?.unidadeResponsavelId)
    //   .catch(erro => {
    //     console.log('erro', erro);

    //   })

    a.valorEstimadoExibicao = Utilidades.aplicarMascaraValor(a.valorEstimado);
    a.dataEntradaExibicao = parseISO(a.dataEntrada).toLocaleString('pt-BR').substring(0, 10);

    setIsconformidade((a?.tipoAnalise === "ANALISE_DE_CONFORMIDADE" || a?.tipoAnalise === "REVISAO_ANALISE_DE_CONFORMIDADE") ? true : false);

    carregarListaModelos(a?.tipoAnalise, a?.leiLicitacao);

    if (a.blocos != null && a.blocos.length > 0) {

      a.blocos.sort(Utilidades.sortPorOrdem);

      a.blocos.forEach((bloco) => {
        if (bloco.filhos != null && bloco.filhos.length > 0) {
          bloco.filhos.sort(Utilidades.sortPorOrdem);
          bloco.filhos.forEach((filho) => {
            if (filho.netos != null && filho.netos.length > 0) {
              filho.netos.sort(Utilidades.sortPorOrdem);
            }
          });
        }
        if (bloco.recomendacoes != null && bloco.recomendacoes.length > 0) {
          bloco.recomendacoes.sort(Utilidades.sortPorOrdem);
        }
      });
      dispatchBlocos({
        type: "set_blocos_iniciais",
        blocos: a.blocos
      });
    }

    if (a.camposComplementares != null && a.camposComplementares.length > 0) {

      a.camposComplementares.sort(Utilidades.sortPorOrdem);

      a.camposComplementares.forEach((campo) => {
        if (campo.tipo === "ADITIVO" || (campo.campoCompAditivo != null && campo?.campoCompAditivo?.length > 0)) {
          campo.campoCompAditivo.forEach((aditivo) => {
            if (aditivo?.dataInicial != null) {
              aditivo.dataInicial = parseISO(aditivo.dataInicial);
            }
            if (aditivo?.dataFinal != null) {
              aditivo.dataFinal = parseISO(aditivo.dataFinal);
            }
          })
          campo.campoCompAditivo.sort(Utilidades.sortPorOrdem);
        }
        // if (campo.tipo === "CAMPO_COMPLEMENTAR_CONFORMIDADE" || (campo.campoCompConformidade != null && campo?.campoCompConformidade?.length > 0)) {
        //   campo.campoCompConformidade.forEach((campoConformidade) => {
        //     if (campoConformidade?.dataInicial != null) {
        //       campoConformidade.dataInicial = parseISO(campoConformidade.dataInicial);
        //     }
        //     if (campoConformidade?.dataFinal != null) {
        //       campoConformidade.dataFinal = parseISO(campoConformidade.dataFinal);
        //     }
        //   })
        //   campo.campoCompConformidade.sort(Utilidades.sortPorOrdem);
        // }
      })

      dispatchCampos({
        type: "set_campos_iniciais",
        campos: a.camposComplementares
      });
    }

    setTextoInicial(a?.textoInicial);
    setTextoInicialHTML(a?.textoInicialHTML);
    setTextoFinal(a?.textoFinal);
    setTextoFinalHTML(a?.textoFinalHTML);
    setTextoInicialExtra(a?.textoInicialExtra);
    setTextoInicialExtraHTML(a?.textoInicialExtraHTML);
    setTextoFinalExtra(a?.textoFinalExtra);
    setTextoFinalExtraHTML(a?.textoFinalExtraHTML);

    setObservacaoTextoInicialExtra(a?.observacaoTextoInicialExtra ?? "");
    setObservacaoTextoFinalExtra(a?.observacaoTextoFinalExtra ?? "");
    setObservacaoTextoEncaminhamento(a?.observacaoTextoEncaminhamento ?? "");

    // console.log('a.textoEncaminhamento', a.textoEncaminhamento)
    setTextAreaEncaminhamento(a.textoEncaminhamento);
    if (a.textoEncaminhamento != null && a.textoEncaminhamento !== "") {
      if (document.getElementById("radioEnc3")) {
        document.getElementById("radioEnc3").checked = true;
      }
    }
    // console.log('a.textoEncaminhamentoHTML', a.textoEncaminhamentoHTML)
    setTextAreaEncaminhamentoHTML(a.textoEncaminhamentoHTML);
    if (a.textoEncaminhamentoHTML != null && a.textoEncaminhamentoHTML !== "") {
      if (document.getElementById("radioEnc3")) {
        document.getElementById("radioEnc3").checked = true;
      }
    }

    if (a?.dataAnalise != null && a?.dataAnalise !== "") {
      // console.log('parseISO', parseISO("2023-03-23 15:49:20.799702"));
      // console.log('isValid', isValid(parseISO("2023-03-23 15:49:20.799702")));
      let dataAnalise = parseISO(a?.dataAnalise);
      setDataAnalise(isValid(dataAnalise) ? dataAnalise : new Date());
    }

    if (a?.dataRevisao != null && a?.dataRevisao !== "") {
      let dataRevisao = parseISO(a?.dataRevisao);
      setDataRevisao(isValid(dataRevisao) ? dataRevisao : new Date());
    }


    setNovaFila(a?.filaAnalise ?? "");
    setNovoStatus(a?.statusAnalise ?? "EM_EDICAO");



    // console.log('setupAnalise', a)
    setAnalise(a);

    setAlterado(false);

  }

  const setupProcesso = async (p) => {
    p.orgaoNome = await EnumUtils.getNomeOrgao(p.orgaoSolicitante)
      .catch(erro => {
        console.log('erro', erro);
        p.orgaoNome = p.orgaoSolicitante;
      })

    setProcesso(p);
  }

  const setupModelo = async (m) => {
    // console.log('m', m)
    let a = analise;
    
    if(m.nome === 'Manifestação - Artigo 5º, §3º do Decreto Municipal nº 154/2023'){
      // console.log('mesmo nome')
      a.textoInicialHTML = '<b><u>' + m.textoInicial + '</u></b>'
    }

    a.nome = m.nome;
    a.textoInicial = m.textoInicial;
    a.textoFinal = m.textoFinal;
    a.textoInicialExtra = "";
    a.textoInicialExtraHTML = "";
    a.textoFinalExtra = "";
    a.textoFinalExtraHTML = "";
    a.textoEncaminhamento = "";
    a.textoEncaminhamentoHTML = "";

    a.dataAnalise = null;
    a.dataRevisao = null;
    // a.dataSaida = null;
    // a.filaAnalise = "EM_ANALISE";
    // setFila("EM_ANALISE");
    a.matriculaAnalista = usuario.matricula;
    a.nomeAnalista = usuario.nome;
    a.observacaoTextoInicialExtra = "";
    a.observacaoTextoFinalExtra = "";

    m = Utilidades.removerIdsBlocosModelo(m);
    m = Utilidades.ordenarBlocosCamposModelo(m);

    let blocosIniciais = m.modelosBlocos;
    blocosIniciais.forEach((bloco) => {
      bloco.recomendacoes = [];
    });
    a.blocos = blocosIniciais;
    dispatchBlocos({
      type: 'set_blocos_iniciais',
      blocos: blocosIniciais
    });

    let campos = [];
    m.camposComplementares.forEach((modeloCampo) => {
      let campo = {};
      campo.id = null;
      campo.ordem = modeloCampo.ordem;
      campo.nome = modeloCampo.nome;
      campo.tipo = modeloCampo.tipo;
      campo.infoAdicional = modeloCampo.infoAdicional;
      campo.observacao = modeloCampo.observacao;
      campos.push(campo);
    });
    a.camposComplementares = campos;
    dispatchCampos({
      type: "set_campos_iniciais",
      campos: campos
    });
    a.isModeloSet = true;
    setupAnalise(a);
  }

  // set comboboxModeloAnalise
  useEffect(() => {
    window.$("#comboboxModeloAnalise").empty();
    let combobox = document.querySelector("#comboboxModeloAnalise").options;

    combobox.add(new Option("Selecione o Modelo da Análise", "-1"));
    listaModelos.forEach((element) => {
      combobox.add(new Option(element.nome, element.id));
    });

    combobox[0].disabled = true;
    combobox.selectedIndex = 0;

  }, [listaModelos]);

  const handleChangeModelo = () => {
    if (idModeloAnalise != null && idModeloAnalise !== "-1") {
      // if (analise?.nome != null) {
      if (analise?.isModeloSet === true) {
        if (window.confirm("Deseja mudar o modelo da análise?\nIsso apagará todos os dados inseridos atualmente.") === true) {
          setLoading({
            ...loading,
            modelo: true
          });
          Requisicao.getModeloPorId(idModeloAnalise)
            .then(data => {
              setupModelo(data);
              setLoading({
                ...loading,
                modelo: false
              });
              window.$("#modalAlterarModeloAnalise").modal("hide");
            })
            .catch(erro => {
              alert("Não foi possível carregar o modelo solicitado.\nVerifique sua conexão com a internet e tente novamente.");
              console.log("Não foi possível carregar o modelo solicitado. ID do Modelo: ", idModeloAnalise);
              console.log('erro', erro);
            });
        }
      } else {
        setLoading({
          ...loading,
          modelo: true
        });
        Requisicao.getModeloPorId(idModeloAnalise)
          .then(data => {
            setupModelo(data);
            setLoading({
              ...loading,
              modelo: false
            });
            window.$("#modalAlterarModeloAnalise").modal("hide");
          })
          .catch(erro => {
            alert("Não foi possível carregar o modelo solicitado.\nVerifique sua conexão com a internet e tente novamente.");
            console.log("Não foi possível carregar o modelo solicitado. ID do Modelo: ", idModeloAnalise);
            console.log('erro', erro);
          });
      }
    }
  }

  const handleChangeCampo = (campo) => {
    dispatchCampos({
      type: 'change_campo',
      campo: campo,
    });
  }

  const handleDeleteCampo = (campo) => {
    dispatchCampos({
      type: 'delete_campo',
      campo: campo
    })
  }

  const handleChangeBloco = (bloco) => {
    dispatchBlocos({
      type: 'change_bloco',
      bloco: bloco,
    });
  }

  const submitForm = (e) => {
    e.preventDefault();
    if (validateForm()) {
      gravar();
    }

  }

  const validateForm = () => {
    let valido = true;
    let motivo = "";

    if (campos != null && campos?.length > 0) {
      campos.forEach(campo => {
        if (campo.tipo === "ADITIVO" && campo?.campoCompAditivo != null && campo?.campoCompAditivo?.length > 0) {
          campo.campoCompAditivo.forEach(aditivo => {
            if (!isValid(aditivo?.dataInicial) || !isValid(aditivo?.dataFinal)) {
              valido = false;
              motivo += "Os campos Data de Início e Data de Término não podem estar vazios.\n";
            }
          })
        }
        // if (campo.tipo === "CAMPO_COMPLEMENTAR_CONFORMIDADE" && campo?.campoCompConformidade != null && campo?.campoCompConformidade?.length > 0) {
        //   campo.campoCompConformidade.forEach(campoConformidadee => {
        //     if (!isValid(campoConformidadee?.dataInicial) || !isValid(campoConformidadee?.dataFinal)) {
        //       valido = false;
        //       motivo += "Os campos Data de Início e Data de Término não podem estar vazios.\n";
        //     }
        //   })
        // }
      })
    }

    let recomendacaoVazia = false;
    if (blocos != null && blocos?.length > 0) {
      blocos.forEach(bloco => {
        if (bloco?.recomendacoes != null && bloco?.recomendacoes?.length > 0) {
          bloco.recomendacoes.forEach(recomendacao => {
            if (recomendacao?.texto == null || recomendacao?.texto === "") {
              recomendacaoVazia = true;
            }
          })
        }
      })
      if (recomendacaoVazia) {
        valido = false;
        motivo += "Você possui recomendações adicionadas com o campo de texto vazio. Delete a recomendação ou insira seu texto antes de salvá-la.\n"
      }
    }



    if (!valido) {
      alert(motivo);
    } else {
      let textareasRec = document.querySelectorAll('.textarea-recomendacao');
      // console.log('textareasRec', textareasRec)
      let clicked = false;
      if (textareasRec != null && textareasRec?.length > 0) {
        textareasRec?.forEach(ta => {
          // console.log('value', ta?.value)
          if (!clicked) {

            if ((ta?.value != null & ta?.value !== "") || (ta?.textContent != null && ta?.textContent !== "")) {
              if(usuario?.tipoUsuario !== "ADMINISTRADOR"){
                if (!(window.confirm("Você possui um texto de recomendação não adicionado ao documento.\nDeseja continuar assim mesmo?") === true)) {
                  valido = false;
                }
                clicked = true;
              }
            }
          }
        })
      }
    }

    return valido;
  }

  const getDados = () => {
    let dados = analise;

    dados.blocos = blocos;
    dados.camposComplementares = campos;

    if (emRevisao) {
      let tempDate = new Date();
      setDataRevisao(tempDate);
      dados.dataAnalise = dataAnalise.toJSON();
      dados.dataRevisao = tempDate.toJSON();
    } else {
      let tempDate = new Date();
      setDataAnalise(tempDate);
      setDataRevisao(null);
      dados.dataAnalise = tempDate.toJSON();
      dados.dataRevisao = null;
    }

    dados.filaAnalise = fila;
    // dados.filaAnalise = analise.filaAnalise != null ? analise.filaAnalise : "EM_ANALISE";

    dados.textoInicial = textoInicial;
    dados.textoInicialHTML = textoInicialHTML;
    dados.textoFinal = textoFinal;
    dados.textoFinalHTML = textoFinalHTML;
    dados.textoInicialExtra = textoInicialExtra;
    dados.textoInicialExtraHTML = textoInicialExtraHTML;
    dados.textoFinalExtra = textoFinalExtra;
    dados.textoFinalExtraHTML = textoFinalExtraHTML;


    dados.observacaoTextoInicialExtra = observacaoTextoInicialExtra;
    dados.observacaoTextoFinalExtra = observacaoTextoFinalExtra;
    dados.observacaoTextoEncaminhamento = observacaoTextoEncaminhamento;


    if (document.getElementById("radioEnc3") && document.getElementById("radioEnc3").checked === true) {
      dados.textoEncaminhamento = textAreaEncaminhamento;
      dados.textoEncaminhamentoHTML = textAreaEncaminhamentoHTML;
    }

    return dados;
  }

  const submitEncaminhar = (e) => {
    e.preventDefault();
    if (!loading.salvando && !loading.alterandoFila) {
      if (novaFila != null && novaFila !== "" && (novaFila !== analise?.filaAnalise || novoStatus !== analise?.statusAnalise || analise?.filaAnalise === "EM_MANIFESTACAO")) { // verificar
        setLoading({ ...loading, alterandoFila: true, salvando: true });
        window.toastr["info"]("Salvando...", '', { timeOut: 0, extendedTimeout: 0 });
        const dados = getDados();

        let prosseguir = false;

        if (emRevisao) {
          if (dados?.nomeRevisor === null || dados?.nomeRevisor === "" || dados?.nomeRevisor === usuario?.nome) {
            prosseguir = true;
            dados.nomeRevisor = usuario?.nome;
            dados.matriculaRevisor = usuario?.matricula;
          } else {
            if (window.confirm("Outro usuário é responsável por esta análise.\nSalvar esta análise irá alterar o revisor desta análise para o seu nome.\nDeseja prosseguir?") === true) {
              prosseguir = true;
              dados.nomeRevisor = usuario?.nome;
              dados.matriculaRevisor = usuario?.matricula;
            }
          }
        } else {
          if (dados?.nomeAnalista === null || dados?.nomeAnalista === "" || dados?.nomeAnalista === usuario?.nome) {
            prosseguir = true;
            dados.nomeAnalista = usuario?.nome;
            dados.matriculaAnalista = usuario?.matricula;
            dados.nomeRevisor = null;
            dados.matriculaRevisor = null;
          } else {
            if (window.confirm("Outro usuário é responsável por esta análise.\nSalvar esta análise irá alterar o analista desta análise para o seu nome.\nDeseja prosseguir?") === true) {
              prosseguir = true;
              dados.nomeAnalista = usuario?.nome;
              dados.matriculaAnalista = usuario?.matricula;
              dados.nomeRevisor = null;
              dados.matriculaRevisor = null;
            }
          }
        }

        if (prosseguir) {
          const token = Autenticar.getLocalToken();
          const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(dados)
          };

          const url = window.servidor + "/v1/protegido/analise/editar"

          fetch(url, requestOptions)
            .then((response) => {
              console.log('response', response);
              if (response.status !== 200) {
                setLoading({ ...loading, salvando: false, alterandoFila: false });
                throw new Error("Falha ao salvar analise.");
              } else {
                setLoading({ ...loading, salvando: false, alterandoFila: true });
                console.log("Analise salva com sucesso.");
                setAlterado(false);
                window.toastr.clear();
                encaminharRequest();
              }
            })
            .catch(erro => {
              console.log(erro);
              setLoading({ ...loading, salvando: false, alterandoFila: false });
            });
        } else {
          setLoading({ ...loading, alterandoFila: false, salvando: false });
          window.toastr.clear()
          window.$("#modalEncaminharAnalise").modal("hide");
        }

      } else {
        alert("Selecione uma das opções para encaminhamento.");
      }

    }
  }

  const encaminharRequest = () => {
    const dados = {
      analiseOrigemId: (analise?.analiseOrigemId != null) ? analise?.analiseOrigemId : analiseId,
      analiseId: analiseId,
      filaAnalise: novaFila,
      statusAnalise: novoStatus,
      tipoEncaminhamento: tipoEncaminhamento,
      orgaoDestino: orgaoDestino,
      consultoriaJuridicaDestino: assessoriaJuridicaDestino,
      orgaoOrcamentarioDestino: orgaoOrcamentarioDestino,
      unidadeDestinoId: unidadeDestinoId,
      observacoes: observacoes,
    }
    const token = Autenticar.getLocalToken();
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(dados)
    };

    const url = window.servidor + "/v1/protegido/analise/encaminhar"

    fetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          // alterarStatusRequest();
          alert("Encaminhado com sucesso.");
          window.toastr["success"]("Análise encaminhada com sucesso!");
          setLoading({ ...loading, alterandoFila: false, salvando: false });
          window.$("#modalEncaminharAnalise").modal("hide");
          navigate("/analise/" + analiseId, { state: { referrer: location }, replace: true });
        } else {
          alert("Falha ao encaminhar análise. Verifique os campos selecionados.");
          setLoading({ ...loading, alterandoFila: false, salvando: false });
        }
      })
      .catch(erro => {
        console.log(erro);
        setLoading({ ...loading, alterandoFila: false, salvando: false });
      });
  }

  const gravar = () => {
    setLoading({ ...loading, salvando: true });
    window.toastr["info"]("Salvando...", '', { timeOut: 0, extendedTimeout: 0 });
    const dados = getDados();
    // console.log('dados', dados)

    let prosseguir = false;

    if (emRevisao) {
      if (dados?.nomeRevisor === null || dados?.nomeRevisor === "" || dados?.nomeRevisor === usuario?.nome) {
        prosseguir = true;
        dados.nomeRevisor = usuario?.nome;
        dados.matriculaRevisor = usuario?.matricula;
      } else {
        if (window.confirm("Outro usuário é responsável por esta análise.\nSalvar esta análise irá alterar o revisor desta análise para o seu nome.\nDeseja prosseguir?") === true) {
          prosseguir = true;
          dados.nomeRevisor = usuario?.nome;
          dados.matriculaRevisor = usuario?.matricula;
        }
      }
    } else {
      if (dados?.nomeAnalista === null || dados?.nomeAnalista === "" || dados?.nomeAnalista === usuario?.nome) {
        prosseguir = true;
        dados.nomeAnalista = usuario?.nome;
        dados.matriculaAnalista = usuario?.matricula;
        dados.nomeRevisor = null;
        dados.matriculaRevisor = null;
      } else {
        if (window.confirm("Outro usuário é responsável por esta análise.\nSalvar esta análise irá alterar o analista desta análise para o seu nome.\nDeseja prosseguir?") === true) {
          prosseguir = true;
          dados.nomeAnalista = usuario?.nome;
          dados.matriculaAnalista = usuario?.matricula;
          dados.nomeRevisor = null;
          dados.matriculaRevisor = null;
        }
      }
    }


    // console.log('prosseguir', prosseguir);
    console.log('dados', dados)

    if (prosseguir) {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(dados)
      };

      const url = window.servidor + "/v1/protegido/analise/editar"

      fetch(url, requestOptions)
        .then((response) => {
          console.log('response', response);
          if (response.status !== 200) {
            setLoading({ ...loading, salvando: false });
            // if(response.status === 401){

            // }
            window.toastr["error"]("Falha ao salvar analise.");
            throw new Error("Falha ao salvar analise.");
          }
          if (response.status === 401) {
            window.toastr["info"]("Abra uma nova aba e verifique se está logado.");
          }
          return response.json();
        })
        .then((data) => {
          // console.log('data', data);
          setAlterado(false);
          alert("Analise salva com sucesso.");
          window.toastr.clear()
          window.toastr["success"]("Análise salva com sucesso!");
          setLoading({ ...loading, salvando: false });
          // if (window.confirm("Analise salva com sucesso.\nDeseja voltar para a tela de pesquisa?") === true) {
          //   navigate("/processo/pesquisar", { state:{referrer:location}, replace:true });
          // }
        })
        .catch(erro => {
          console.log(erro);
          setLoading({ ...loading, salvando: false });
        });
    } else {
      setLoading({ ...loading, salvando: false });
      window.toastr.clear();
    }

  }

  const inputCommentTIE = (
    <>
      <label>Adicionar Comentário</label>
      <textarea className="form-control my-2" rows={4} style={{ width: "320px" }} value={observacaoTextoInicialExtra} onChange={(e) => setObservacaoTextoInicialExtra(e.target.value)} />
      <div className="text-center my-2">
        <button type="button" className="btn btn-primary mx-2" onClick={() => { setObservacaoTextoInicialExtra(""); }}>Limpar</button>
        <button type="button" className="btn btn-primary mx-2" onClick={() => { setVisibleTIE(false); }}>Ok</button>
      </div>
    </>
  );

  const inputCommentTFE = (
    <>
      <label>Adicionar Comentário</label>
      <textarea className="form-control my-2" rows={4} style={{ width: "320px" }} value={observacaoTextoFinalExtra} onChange={(e) => setObservacaoTextoFinalExtra(e.target.value)} />
      <div className="text-center my-2">
        <button type="button" className="btn btn-primary mx-2" onClick={() => { setObservacaoTextoFinalExtra(""); }}>Limpar</button>
        <button type="button" className="btn btn-primary mx-2" onClick={() => { setVisibleTFE(false); }}>Ok</button>
      </div>
    </>
  );

  const inputCommentTEnc = (
    <>
      <label>Adicionar Comentário</label>
      <textarea className="form-control my-2" rows={4} style={{ width: "320px" }} value={observacaoTextoEncaminhamento} onChange={(e) => setObservacaoTextoEncaminhamento(e.target.value)} />
      <div className="text-center my-2">
        <button type="button" className="btn btn-primary mx-2" onClick={() => { setObservacaoTextoEncaminhamento(""); }}>Limpar</button>
        <button type="button" className="btn btn-primary mx-2" onClick={() => { setVisibleTEnc(false); }}>Ok</button>
      </div>
    </>
  );

  // const atalhoSalvarAnalise = useCallback((e) => {
  //   if ((e.ctrlKey|| e.metaKey) && e.key === 's') {
  //     // Prevent the Save dialog to open
  //     e.preventDefault();
  //     // Place your code here
  //     console.log('CTRL + S');
  //     if(loading.analise === false) {
  //       console.log("tentando salvar")
  //       submitForm();
  //     }
  //   }
  // }, [loading.analise, submitForm]);

  const isAdministrador = () => {
    if (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR") {
      return true;
    }
    return false;
  }


  useEffect(() => {
    const atalhoSalvarAnalise = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 's') {
        // Prevent the Save dialog to open
        e.preventDefault();
        // Place your code here
        // console.log('CTRL + S');
        if (loading.analise === false && loading.salvando === false) {
          // console.log("tentando salvar")
          document.getElementById('buttonSaveAnalise').click();
        }
      }
    }

    document.addEventListener('keydown', atalhoSalvarAnalise);
    return () => {
      document.removeEventListener('keydown', atalhoSalvarAnalise);
    }
  }, [loading.analise, loading.salvando]);

  return (
    <>
      {/* Modal alterar modelo da análise */}
      <div className="modal fade" id="modalAlterarModeloAnalise" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Alterar Modelo da Análise</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                <strong>Atenção:</strong> alterar o modelo da Análise apagará todos os dados
                da análise. Apenas altere o modelo da análise caso esteja absolutamente certo do que está fazendo.
              </p>
              <div className="my-3 row">
                <div className="col">
                  {
                    (analise?.isModeloSet !== true) ?
                      <>
                        <label htmlFor="comboboxModeloAnalise">Selecione o modelo da Análise</label>
                        <p>Para iniciar o preenchimento da análise, escolha o modelo correspondente:</p>
                      </> :
                      <>
                        <label>Alterar o modelo da Análise</label>
                        <p><strong>Modelo atual: </strong> {analise?.nome}</p>
                        <p>Esta ação apagará os dados que já foram preenchidos da análise.</p>
                      </>
                  }
                  <select id="comboboxModeloAnalise" className="form-control select2bs4">
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
              {
                (loading.modelo) ?
                  <button id="btnSelecionarModelo" type="button" className='btn btn-primary mx-2 d-flex flex-row' disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;Carregando...
                  </button>
                  :
                  <button id="btnSelecionarModelo" type="button" className='btn btn-primary mx-2' onClick={() => handleChangeModelo()}>Selecionar</button>
              }
            </div>
          </div>
        </div>
      </div>
      {/* Modal encanhminhar análise para... */}
      <div className="modal fade" id="modalEncaminharAnalise" tabIndex={-1} aria-labelledby="modalEncaminharAnaliseLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalEncaminharAnaliseLabel">Encaminhar análise para...</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {
                (analise != null) ?
                  <EncaminharModalBody usuario={usuario} autenticado={autenticado}
                    orgaoSolicitante={(processo != null) ? processo.orgaoSolicitante : ""}
                    // unidadeResponsavel={(processo != null) ? processo.unidade : null}
                    unidadeResponsavel={(unidadeResponsavel != null) ? unidadeResponsavel : (processo != null) ? processo.unidade : null}
                    tipoAnalise={analise.tipoAnalise}
                    filaAnalise={analise.filaAnalise}
                    statusAnalise={analise.statusAnalise}
                    setNovaFila={setNovaFila}
                    setNovoStatus={setNovoStatus}
                    setTipoEncaminhamento={setTipoEncaminhamento}
                    setOrgaoDestino={setOrgaoDestino}
                    setAssessoriaJuridicaDestino={setAssessoriaJuridicaDestino}
                    setOrgaoOrcamentarioDestino={setOrgaoOrcamentarioDestino}
                    setUnidadeDestinoId={setUnidadeDestinoId}
                    setObservacoes={setObservacoes}
                    observacoes={observacoes}

                  /> :
                  <></>
              }
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
              {
                (loading.alterandoFila) ?
                  <button type="button" className="btn btn-primary" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;Encaminhando...
                  </button>
                  :
                  <button type="button" className="btn btn-primary" onClick={submitEncaminhar}>Encaminhar</button>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Preenchimento de Análise</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`/analise/${analiseId}`}>Análise</Link>
                  </li>
                  <li className="breadcrumb-item active">Preenchimento de Análise</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12">
          <div className="card">
            <div className="card-body">
              {
                (analise?.nome != null) ?
                  <>

                    {
                      (isAdministrador()) ?
                        <>
                          {
                            (editandoNomeAnalise) ?
                              <div className='d-flex flex-row justify-content-center'>
                                <textarea className='form-control' rows={1} value={analise?.nome} onChange={(e) => setAnalise({ ...analise, nome: e.target.value })} placeholder='Nome da análise'></textarea>
                                &nbsp;
                                <Tippy content='Concluir edição'>
                                  <button className="btn text-secondary mx-2 py-0" type='button' onClick={() => setEditandoNomeAnalise(false)}><span className='fas fa-check'></span></button>
                                </Tippy>
                              </div> :
                              <div className='d-flex flex-row justify-content-center'>
                                <h2 className='text-center'>{analise?.nome}</h2>
                                &nbsp;
                                <Tippy content='Editar nome da análise'>
                                  <button className="btn text-secondary mx-2 py-0" type='button' onClick={() => setEditandoNomeAnalise(true)}><span className='fas fa-edit'></span></button>
                                </Tippy>
                              </div>
                          }
                        </> :
                        <>
                          <h2 className='text-center'>{analise?.nome}</h2>
                        </>
                    }
                  </> :
                  <h2 className='text-center'>{analise?.tipoAnaliseNome}</h2>
              }
              {(loading.processo === true) ? <LoadingElement /> : <></>}
              {
                (processo != null) ?
                  <div className="mt-3 col px-0 mx-0">
                    {/* <h4 className="text-center">Processo Nº {processo.numero}</h4> */}
                    <br />
                    <div className='row'>
                      <div className='col-6'>
                        <label>Número do Processo</label>
                        <p>
                          {processo.numero}
                        </p>
                      </div>
                      <div className='col-6'>
                        <label>Volumes e Apensos</label>
                        <p>
                          {processo?.volumeseApensos}
                        </p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <label>Unidade de Entrada do Processo</label>
                        <p>
                          {processo?.unidade?.nome}
                        </p>
                      </div>
                      <div className='col-6'>
                        <label>Órgão Solicitante</label>
                        <p>
                          {processo?.orgaoNome}
                        </p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col'>
                        <label>Objeto do Processo</label>
                        <p>
                          {processo?.objeto}
                        </p>
                      </div>
                    </div>
                  </div> :
                  <></>
              }
              {
                (analise != null) ?
                  <div className="col px-0 mx-0">
                    {/* <h5>Dados da Análise</h5> */}
                    {/* <br /> */}
                    <div className='row'>
                      <div className='col-6'>
                        <label>Lei da Licitação</label>
                        <p>
                          {analise?.leiLicitacaoNome}
                        </p>
                      </div>
                      <div className='col-6'>
                        <label>Modalidade da Licitação</label>
                        <p>
                          {analise?.modalidadeLicitacaoNome}
                        </p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <label>Tipo da Análise</label>
                        <p>
                          {analise?.tipoAnaliseNome}
                        </p>
                      </div>
                      <div className='col-6'>
                        <label>Unidade Resposável pela Análise</label>
                        <p>
                          {analise?.unidadeResponsavelNome}
                        </p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <label>Valor</label>
                        <p>
                          R$&nbsp;{analise?.valorEstimadoExibicao}
                        </p>
                      </div>
                      <div className='col-6'>
                        <label>Data de Entrada</label>
                        <p>
                          {analise?.dataEntradaExibicao}
                        </p>
                      </div>
                    </div>
                  </div> :
                  <></>
              }
              <div className="my-3">
                {
                  (analise?.isModeloSet !== true) ?
                    <>
                      <label>Selecione o modelo da Análise</label>
                      <p>Para iniciar o preenchimento da análise, escolha o modelo correspondente:</p>
                      <div className='text-center'>
                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#modalAlterarModeloAnalise">
                          Selecionar modelo da análise
                        </button>
                      </div>
                    </> :
                    <>
                      <label>Alterar o modelo da Análise</label>
                      <p><strong>Modelo atual: </strong> {analise?.nome}</p>
                      <p>Esta ação apagará os dados que já foram preenchidos da análise.</p>
                      <div className='text-center'>
                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#modalAlterarModeloAnalise">
                          Alterar modelo da análise
                        </button>
                      </div>
                    </>
                }
              </div>
              <br />
              <hr />
              {
                // (analise?.nome != null) ?
                (analise?.isModeloSet) ?
                  <>
                    <div className='my-3'>
                      {
                        campos && campos.map((campo) => (
                          <CampoComplementar key={campo.ordem} campo={campo} onChangeCampo={handleChangeCampo} onDeleteCampo={handleDeleteCampo} emRevisao={emRevisao} />
                        ))
                      }
                    </div>
                    <br />
                    <div className='my-3'>
                      <hr />
                      {
                        (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR") ?
                          <>
                            {
                              (editandoTextoInicial) ?
                                <>
                                  {/* <textarea className="form-control" rows={4} placeholder="Digite o Texto Inicial"
                                    value={analise?.textoInicial ?? ""}
                                    onChange={(e) => setAnalise({ ...analise, textoInicial: e.target.value })} >
                                  </textarea> */}
                                  <SummernoteEditor id='textareaTextoInicial'
                                    placeholder='Insira o texto inicial...'
                                    textSetState={setTextoInicial}
                                    codeSetState={setTextoInicialHTML}
                                    initialContent={(textoInicialHTML != null && textoInicialHTML !== "") ? textoInicialHTML : textoInicial}
                                    createOnFocus={true}
                                    disableHelp={true}
                                  />
                                  <Tippy content="Concluir Edição">
                                    <button className="btn btn-outline-secondary col col-sm-3 col-md-2 col-xl-1 my-2" type='button' 
                                      onClick={() => {
                                        window.$("#textareaTextoInicial").summernote('destroy');
                                        setEditandoTextoInicial(false)
                                      }}>
                                        Concluir&nbsp;<span className='far fa-check-square'></span>
                                    </button>
                                  </Tippy>
                                </> :
                                <>
                                  {/* <p className='text-justify fix-linebreak'>{analise?.textoInicial}</p> */}
                                  <div dangerouslySetInnerHTML={{__html: (textoInicialHTML != null && textoInicialHTML !== "") ? textoInicialHTML : textoInicial}}></div>
                                  <Tippy content="Editar Texto Inicial">
                                    <button className="btn btn-outline-secondary col col-sm-3 col-md-2 col-xl-1 my-2" type='button' onClick={() => setEditandoTextoInicial(true)}>Editar&nbsp;<span className='fas fa-edit'></span></button>
                                  </Tippy>
                                </>
                            }
                          </> :
                          // <p className='text-justify fix-linebreak'>{analise?.textoInicial}</p>
                          <div dangerouslySetInnerHTML={{__html: (textoInicialHTML != null && textoInicialHTML !== "") ? textoInicialHTML : textoInicial}}></div>
                      }
                    </div>
                    <div className='my-3'>
                      <div className='row'>
                        <label className='px-2 row align-items-center'>
                          Texto Inicial Complementar
                        </label>
                        {
                          emRevisao ?
                            <Tippy content={(observacaoTextoInicialExtra != null && observacaoTextoInicialExtra !== "") ? "Editar Comentário" : "Adicionar Comentário"} disabled={visibleTIE}>
                              <Tippy interactive={true} maxWidth={350} visible={visibleTIE} content={inputCommentTIE} >
                                <button className={(observacaoTextoInicialExtra != null && observacaoTextoInicialExtra !== "") ? 'btn text-danger' : 'btn text-warning'} type="button" onClick={visibleTIE ? () => setVisibleTIE(false) : () => setVisibleTIE(true)}>
                                  <i className="fas fa-comment-alt"></i>
                                </button>
                              </Tippy>
                            </Tippy> :
                            <>
                              {
                                (observacaoTextoInicialExtra != null && observacaoTextoInicialExtra !== "") ?
                                  <div>
                                    <Tippy interactive={true} maxWidth={350} hideOnClick="toggle" trigger="click" content={observacaoTextoInicialExtra} >
                                      <button className='btn text-danger' type="button">
                                        <i className="fas fa-comment-alt"></i>
                                      </button>
                                    </Tippy>
                                  </div> :
                                  <></>
                              }
                            </>
                        }
                      </div>
                      {/* <textarea id={'textareaTextoInicialComplementar'} className="form-control" rows={3} placeholder="Digite o texto complementar"
                        value={analise?.textoInicialExtra ?? ""}
                        onChange={(e) => setAnalise({ ...analise, textoInicialExtra: e.target.value })} >
                      </textarea> */}
                      {/* <div id={'textareaTextoInicialComplementar'}></div> */}
                      <SummernoteEditor id='textareaTextoInicialComplementar'
                        placeholder='Insira o texto inicial complementar...'
                        textSetState={setTextoInicialExtra}
                        codeSetState={setTextoInicialExtraHTML}
                        initialContent={(analise.textoInicialExtraHTML != null && analise.textoInicialExtraHTML !== "") ? analise.textoInicialExtraHTML : analise.textoInicialExtra}
                        createOnFocus={true}
                        disableHelp={true}
                      />


                      {/* <div id='editor-de-texto' className='border form-control' 
                      onFocus={() => {
                        console.log('focusDiv');
                        document.getElementById("editor-de-texto")?.classList.add("border-primary");
                      }} 
                      onBlur={() => {
                        console.log('blurDiv');
                        document.getElementById("editor-de-texto")?.classList.remove("border-primary")
                      }} 
                      contentEditable={true} 
                      style={{
                        height: '150px',
                        maxHeight: '150px',
                        overflowY: 'auto',
                        resize: 'vertical'
                      }}
                      dangerouslySetInnerHTML={{__html: editorCodeContent}}
                      ></div> */}
                      {/* <br />
                      <textarea id='editor-de-texto-textarea' className='form-control' rows={4} onFocus={() => console.log('focusTextarea')} onBlur={() => console.log('blurTextarea')}></textarea> */}
                    </div>
                    <br />
                    <div className='my-3'>
                      {
                        blocos && blocos.map((bloco) => (
                          <Bloco key={bloco.ordem} bloco={bloco} onChangeBloco={handleChangeBloco} emRevisao={emRevisao} usuario={usuario} leiLicitacao={analise?.leiLicitacao} tipoAnalise={analise?.tipoAnalise}
                            isConformidade={(analise?.tipoAnalise === "ANALISE_DE_CONFORMIDADE" || analise?.tipoAnalise === "REVISAO_ANALISE_DE_CONFORMIDADE") ? true : false} />
                        ))
                      }
                    </div>
                    <br />
                    {
                      (isConformidade) ?
                        <div className='my-3'>
                          <label>Conclusão</label>
                        </div> :
                        <></>
                    }
                    <div className='my-3'>
                      {
                        (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR") ?
                          <>
                            {
                              (editandoTextoFinal) ?
                                <>
                                  <SummernoteEditor id='textareaTextoFinal'
                                    placeholder='Insira o texto final...'
                                    textSetState={setTextoFinal}
                                    codeSetState={setTextoFinalHTML}
                                    initialContent={(textoFinalHTML != null && textoFinalHTML !== "") ? textoFinalHTML : textoFinal}
                                    createOnFocus={true}
                                    disableHelp={true}
                                  />
                                  <Tippy content="Concluir Edição">
                                    <button className="btn btn-outline-secondary col col-sm-3 col-md-2 col-xl-1 my-2" type='button' 
                                      onClick={() => {
                                        window.$("#textareaTextoFinal").summernote('destroy');
                                        setEditandoTextoFinal(false)
                                      }}>
                                        Concluir&nbsp;<span className='far fa-check-square'></span>
                                    </button>
                                  </Tippy>
                                </> :
                                <>
                                  <div dangerouslySetInnerHTML={{__html: (textoFinalHTML != null && textoFinalHTML !== "") ? textoFinalHTML : textoFinal}}></div>
                                  <Tippy content="Editar Texto Final">
                                    <button className="btn btn-outline-secondary col col-sm-3 col-md-2 col-xl-1 my-2" type='button' onClick={() => setEditandoTextoFinal(true)}>Editar&nbsp;<span className='fas fa-edit'></span></button>
                                  </Tippy>
                                </>
                            }
                          </> :
                          <div dangerouslySetInnerHTML={{__html: (textoFinalHTML != null && textoFinalHTML !== "") ? textoFinalHTML : textoFinal}}></div>
                      }
                      {/* {
                        (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR") ?
                          <>
                            {
                              (editandoTextoFinal) ?
                                <>
                                  <textarea className="form-control" rows={4} placeholder="Digite o Texto Final"
                                    value={analise?.textoFinal ?? ""}
                                    onChange={(e) => setAnalise({ ...analise, textoFinal: e.target.value })} >
                                  </textarea>
                                  <Tippy content="Concluir Edição">
                                    <button className="btn btn-outline-secondary col col-sm-3 col-md-2 col-xl-1 my-2" type='button' onClick={() => setEditandoTextoFinal(false)}>Concluir&nbsp;<span className='far fa-check-square'></span></button>
                                  </Tippy>
                                </> :
                                <>
                                  <p className='text-justify fix-linebreak'>{analise?.textoFinal}</p>
                                  <Tippy content="Editar Texto Final">
                                    <button className="btn btn-outline-secondary col col-sm-3 col-md-2 col-xl-1 my-2" type='button' onClick={() => setEditandoTextoFinal(true)}>Editar&nbsp;<span className='fas fa-edit'></span></button>
                                  </Tippy>
                                </>
                            }
                          </> :
                          <p className='text-justify fix-linebreak'>{analise?.textoFinal}</p>
                      } */}
                    </div>
                    <div className='my-3'>
                      <div className='row'>
                        <label className='px-2 row align-items-center'>
                          Texto Final Complementar
                        </label>
                        {
                          emRevisao ?
                            <Tippy content={(observacaoTextoFinalExtra != null && observacaoTextoFinalExtra !== "") ? "Editar Comentário" : "Adicionar Comentário"} disabled={visibleTFE}>
                              <Tippy interactive={true} maxWidth={350} visible={visibleTFE} content={inputCommentTFE} >
                                <button className={(observacaoTextoFinalExtra != null && observacaoTextoFinalExtra !== "") ? 'btn text-danger' : 'btn text-warning'} type="button" onClick={visibleTFE ? () => setVisibleTFE(false) : () => setVisibleTFE(true)}>
                                  <i className="fas fa-comment-alt"></i>
                                </button>
                              </Tippy>
                            </Tippy> :
                            <>
                              {
                                (observacaoTextoFinalExtra != null && observacaoTextoFinalExtra !== "") ?
                                  <div>
                                    <Tippy interactive={true} maxWidth={350} hideOnClick="toggle" trigger="click" content={observacaoTextoFinalExtra} >
                                      <button className='btn text-danger' type="button">
                                        <i className="fas fa-comment-alt"></i>
                                      </button>
                                    </Tippy>
                                  </div> :
                                  <></>
                              }
                            </>
                        }
                      </div>
                      <SummernoteEditor id='textareaTextoFinalComplementar'
                        placeholder='Insira o texto final complementar...'
                        // textSetState={(contents) => {setAnalise({ ...analise, textoFinalExtra: contents })}}
                        // codeSetState={(contents) => {setAnalise({ ...analise, textoFinalExtraHTML: contents })}}
                        // onChange={(contents, $editable) => {
                        //   setAnalise({
                        //     ...analise,
                        //     textoFinalExtra: $editable[0]?.textContent,
                        //     textoFinalExtraHTML: contents
                        //   })
                        // }}
                        // initialContent={'dsadsa<i>dsa<b>d<u>sadsa ds</u></b><u>ad</u></i><u>s</u>a<div></div>'} 
                        textSetState={setTextoFinalExtra}
                        codeSetState={setTextoFinalExtraHTML}
                        initialContent={(analise.textoFinalExtraHTML != null && analise.textoFinalExtraHTML !== "") ? analise.textoFinalExtraHTML : analise.textoFinalExtra}
                        createOnFocus={true}
                        disableHelp={true}
                      />
                      {/* <textarea className="form-control" rows={3} placeholder="Digite o texto complementar"
                        value={analise?.textoFinalExtra ?? ""}
                        onChange={(e) => setAnalise({ ...analise, textoFinalExtra: e.target.value })} >
                      </textarea> */}
                    </div>
                  </> :
                  <></>
              }
              <br />
              {
                // (analise?.isModeloSet) ? 
                (analise == null || analise?.isModeloSet) ?
                  <div className='my-3'>
                    <div className='row'>
                      <label className='mt-1'>Texto de Encaminhamento</label>
                      {
                        emRevisao ?
                          <Tippy content={(observacaoTextoEncaminhamento != null && observacaoTextoEncaminhamento !== "") ? "Editar Comentário" : "Adicionar Comentário"} disabled={visibleTEnc}>
                            <Tippy interactive={true} maxWidth={350} visible={visibleTEnc} content={inputCommentTEnc} >
                              <button className={(observacaoTextoEncaminhamento != null && observacaoTextoEncaminhamento !== "") ? 'btn text-danger' : 'btn text-warning'} type="button" onClick={visibleTEnc ? () => setVisibleTEnc(false) : () => setVisibleTEnc(true)}>
                                <i className="fas fa-comment-alt"></i>
                              </button>
                            </Tippy>
                          </Tippy> :
                          <>
                            {
                              (observacaoTextoEncaminhamento != null && observacaoTextoEncaminhamento !== "") ?
                                <div>
                                  <Tippy interactive={true} maxWidth={350} hideOnClick="toggle" trigger="click" content={observacaoTextoEncaminhamento} >
                                    <button className='btn text-danger' type="button">
                                      <i className="fas fa-comment-alt"></i>
                                    </button>
                                  </Tippy>
                                </div> :
                                <></>
                            }
                          </>
                      }
                    </div>
                    <table className="table table-bordered col">
                      <tbody>
                        <tr>
                          <td className="pt-4 pl-5" style={{ width: "5%" }}>
                            <div>
                              <input className="form-check-input input-texto-enc" type="radio" name="radioEncaminhamento" id="radioEnc1"
                                value={"À Controladoria Geral do Município de Macaé - Subcontroladoria de Fiscalização e Controle, " +
                                  "com a finalidade de análise das despesas referentes à solicitação, " +
                                  "para revisão quanto à análise."}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setAnalise({ ...analise, textoEncaminhamento: e.target.value, textoEncaminhamentoHTML: `<p>${e.target.value}</p>` });
                                  }
                                }}
                                required />
                            </div>
                          </td>
                          <td>
                            <label htmlFor='radioEnc1' className='font-weight-normal'>
                              À Controladoria Geral do Município de Macaé - Subcontroladoria de Fiscalização e Controle,
                              com a finalidade de análise das despesas referentes à solicitação,
                              para revisão quanto à análise.
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <th className="pt-4 pl-5" style={{ width: "5%" }}>
                            <div>
                              <input className="form-check-input input-texto-enc" type="radio" name="radioEncaminhamento" id="radioEnc2"
                                value="À UDCI para conhecimento de eventual recomendação do Órgão Central de Controle Interno, e posterior prosseguimento do procedimento."
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setAnalise({ ...analise, textoEncaminhamento: e.target.value, textoEncaminhamentoHTML: `<p>${e.target.value}</p>` });
                                  }
                                }}
                                required />
                            </div>
                          </th>
                          <td>
                            <label htmlFor='radioEnc2' className='font-weight-normal'>
                              À UDCI para conhecimento de eventual recomendação do Órgão Central de Controle Interno, e posterior prosseguimento do procedimento.
                            </label>
                          </td>
                        </tr>
                        {/* {
                          (processo?.orgaoNome != null) ?
                          <tr>
                            <th className="pt-4 pl-5" style={{ width: "5%" }}>
                              <div>
                                <input className="form-check-input input-texto-enc" type="radio" name="radioEncaminhamento" id="radioEnc4"
                                  value={`À ${processo.orgaoNome} para conhecimento e adequação.`}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setAnalise({ ...analise, textoEncaminhamento: e.target.value, textoEncaminhamentoHTML: `<p>${e.target.value}</p>` });
                                    }
                                  }}
                                  required />
                              </div>
                            </th>
                            <td>
                              <label htmlFor='radioEnc4' className='font-weight-normal'>
                                {`À ${processo.orgaoNome} para conhecimento e adequação.`}
                              </label>
                            </td>
                          </tr> :
                          <></>
                        } */}
                        <tr>
                          <th className="pt-4 pl-5" style={{ width: "5%" }}>
                            <div>
                              <input className="form-check-input input-texto-enc" type="radio" name="radioEncaminhamento" id="radioEnc3"
                                value="-1"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setAnalise({ ...analise, textoEncaminhamento: textAreaEncaminhamento, textoEncaminhamentoHTML: textAreaEncaminhamentoHTML });
                                  }
                                }}
                                required />
                            </div>
                          </th>
                          <td>
                            <div>
                              <SummernoteEditor id={`radioEnc3TextArea`}
                                placeholder='Digite o texto de encaminhamento...'
                                classNameEditable='resize-vertical'
                                height={100}
                                // textSetState={setTextAreaEncaminhamento}
                                // codeSetState={setTextAreaEncaminhamentoHTML}
                                onChange={(contents, $editable) => {
                                  // onChangeRecomendacao({...recomendacao, texto: $editable[0]?.textContent, html: contents});
                                  setTextAreaEncaminhamento($editable[0]?.textContent);
                                  setTextAreaEncaminhamentoHTML(contents);
                                  document.getElementById("radioEnc3").checked = true;
                                }}
                                createOnFocus={true}
                                disableHelp={true}
                                // airMode={true}
                                initialContent={(textAreaEncaminhamentoHTML != null && textAreaEncaminhamentoHTML !== "") ? textAreaEncaminhamentoHTML : textAreaEncaminhamento}
                              />
                            </div>
                            {/* <textarea id="radioEnc3TextArea" className="form-control" rows={3} placeholder="Digite o texto de encaminhamento"
                              value={textAreaEncaminhamento}
                              onChange={(e) => {
                                setTextAreaEncaminhamento(e.target.value);
                                document.getElementById("radioEnc3").checked = true;
                                // if (document.getElementById("radioEnc3").checked) {
                                // setAnalise({ ...analise, textoEncaminhamento: e.target.value });
                                // }
                              }}>
                            </textarea> */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div> :
                  <></>
              }
              <br />
              {/* <div className='my-3 row justify-content-center'>
                <div className='col-4 px-0'>
                  <label htmlFor="datepickerDataAnalise">Data da Análise</label>
                  <div className='d-flex flex-row'>
                    <DatePicker id="datepickerDataAnalise" className="form-control" selected={dataAnalise} onChange={(date) => setDataAnalise(date)} locale={ptBR} dateFormat="dd/MM/yyyy" required />
                    <button type='button' className="btn btn-primary ml-2" onClick={() => setDataAnalise(new Date())}>Hoje</button>
                  </div>
                </div>
              </div>
              <br /> */}
              <hr />
              <div className='my-3 row justify-content-center'>
                <div className='col-4 text-center mx-2' style={{ border: "solid 1px gray" }}>
                  <label>Analisado em: {Utilidades.getDataFormatada(dataAnalise)}</label>
                  <p>
                    {analise?.nomeAnalista}
                  </p>
                  <p>
                    {(analise?.matriculaAnalista !== "" && analise?.matriculaAnalista != null) ? "Mat. " + analise?.matriculaAnalista : ""}
                  </p>
                </div>
                <div className='col-4 text-center mx-2' style={{ border: "solid 1px gray" }}>
                  <label>Revisado em: {Utilidades.getDataFormatada(dataRevisao)}</label>
                  <p>
                    {analise?.nomeRevisor}
                  </p>
                  <p>
                    {(analise?.matriculaRevisor !== "" && analise?.matriculaRevisor != null) ? "Mat. " + analise?.matriculaRevisor : ""}
                  </p>
                </div>
              </div>
              <div className='my-3'>
                <p>Ao salvar uma análise, o nome e a matrícula do analista serão salvos automaticamente no campo "Analisado Por".</p>
                <p>Caso a análise esteja em verificação, o nome e a matrícula do revisor serão salvos automaticamente no campo "Revisado por".</p>
              </div>
              <div id="btnAlterarModelo" className="d-flex flex-row justify-content-between px-3 pb-3 mt-5">
                <div>
                  <button type='button' className='btn btn-primary' data-toggle="modal" data-target="#modalEncaminharAnalise">Encaminhar para...</button>
                </div>
                {/* <div>
                  <Link to={`/analise/${analiseId}`} className='btn btn-primary'>Retornar</Link>
                </div> */}
                <div>
                  <button type='button' className='btn btn-primary' onClick={() => {
                    if (window.confirm("Todos os dados não salvos serão perdidos.\nDeseja retornar à página de informações da análise?") === true) {
                      navigate(`/analise/${analiseId}`, { state: { referrer: location }, replace: false })
                    }
                  }}>Sair</button>
                </div>
                <div>
                  {
                    (loading.salvando) ?
                      <button type="button" className="btn btn-primary" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;Salvando...
                      </button>
                      :
                      <button type="button" id='buttonSaveAnalise' className="btn btn-primary" onClick={submitForm}>Salvar</button>
                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
