import React, { useEffect, useState } from 'react'
import Requisicao from '../../helpers/Requisicao';
import LoadingElement from '../../components/LoadingElement';

export default function EncaminharModalBody(props) {

  const usuario = props.usuario;
  const autenticado = props.autenticado;
  const orgaoSolicitante = props.orgaoSolicitante;
  const unidadeResponsavel = props.unidadeResponsavel;
  const tipoAnalise = props.tipoAnalise;
  const filaAnalise = props.filaAnalise;
  const statusAnalise = props.statusAnalise;
  const setNovaFila = props.setNovaFila;
  const setNovoStatus = props.setNovoStatus;
  const setTipoEncaminhamento = props.setTipoEncaminhamento;
  const setOrgaoDestino = props.setOrgaoDestino;
  const setAssessoriaJuridicaDestino = props.setAssessoriaJuridicaDestino;
  const setOrgaoOrcamentarioDestino = props.setOrgaoOrcamentarioDestino;
  const setUnidadeDestinoId = props.setUnidadeDestinoId;
  const setObservacoes = props.setObservacoes;
  const observacoes = props.observacoes;

  // const [listaTipoEncaminhamento, setListaTipoEncaminhamento] = useState([]);
  const [listaOrgaoDestino, setListaOrgaoDestino] = useState([]);
  const [listaAssessoriaJuridicaDestino, setListaAssessoriaJuridicaDestino] = useState([]);
  const [listaOrgaoOrcamentarioDestino, setListaOrgaoOrcamentarioDestino] = useState([]);
  const [listaUnidades, setListaUnidades] = useState([]);

  const [loading, setLoading] = useState(true);
  // const [loadingListaTipos, setLoadingListaTipos] = useState(true);
  const [loadingListaOrgaos, setLoadingListaOrgaos] = useState(true);
  const [loadingListaOrgaosOrcamentarios, setLoadingListaOrgaosOrcamentarios] = useState(true);
  const [loadingListaAssessoria, setLoadingListaAssessoria] = useState(true);
  const [loadingListaUnidade, setLoadingListaUnidade] = useState(true);

  const [eventsSet, setEventsSet] = useState(false);



  useEffect(() => {

    // carregarListaTipoEncaminhamento();
    carregarListaOrgaos();
    carregarListaOrgaosOrcamentarios();
    carregarListaAssessorias();
    carregarListaUnidades();
  }, []);

  useEffect(() => {
    if (loading === false) {
      window.$(".comboDestinoOptions").select2({
        theme: "bootstrap4",
        dropdownParent: window.$('#modalEncaminharAnalise')
      });

      if (eventsSet === false) {
        let combos = document.querySelectorAll('.comboOrgao');
        for (let i = 0; i < combos.length; i++) {
          combos[i].addEventListener('change.select2', function (e) {
            setOrgaoDestino(e.target.value);
          })
        }
        combos = document.querySelectorAll('.comboOrgaoOrcamentario');
        for (let i = 0; i < combos.length; i++) {
          combos[i].addEventListener('change.select2', function (e) {
            setOrgaoOrcamentarioDestino(e.target.value);
          })
        }
        combos = document.querySelectorAll('.comboAssessoriaJuridica');
        for (let i = 0; i < combos.length; i++) {
          combos[i].addEventListener('change.select2', function (e) {
            setAssessoriaJuridicaDestino(e.target.value);
          })
        }
        combos = document.querySelectorAll('.comboUnidade');
        for (let i = 0; i < combos.length; i++) {
          combos[i].addEventListener('change.select2', function (e) {
            setAssessoriaJuridicaDestino(e.target.value);
          })
        }
        setEventsSet(true);
      }
    }
  }, [loading, eventsSet, setOrgaoDestino, setOrgaoOrcamentarioDestino, setAssessoriaJuridicaDestino])

  //setLoading
  useEffect(() => {
    if (
      // loadingListaTipos === false &&
      loadingListaUnidade === false && 
      loadingListaOrgaos === false &&
      loadingListaOrgaosOrcamentarios === false &&
      loadingListaAssessoria === false
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [loadingListaOrgaos, loadingListaOrgaosOrcamentarios, loadingListaAssessoria, loadingListaUnidade]);

  // const carregarListaTipoEncaminhamento = () => {
  //   setLoadingListaTipos(true);
  //   Requisicao.getListaTiposEncaminhamento()
  //     .then(data => {
  //       setListaTipoEncaminhamento(data);
  //       setLoadingListaTipos(false);
  //     })
  //     .catch(erro => console.log('erro', erro));
  // }

  const carregarListaOrgaos = () => {
    setLoadingListaOrgaos(true);
    Requisicao.getListaOrgaos()
      .then(data => {
        setListaOrgaoDestino(data);
        setLoadingListaOrgaos(false);
      })
      .catch(erro => console.log('erro', erro));
  }

  const carregarListaOrgaosOrcamentarios = () => {
    setLoadingListaOrgaosOrcamentarios(true);
    Requisicao.getListaOrgaosOrcamentarios()
      .then(data => {
        setListaOrgaoOrcamentarioDestino(data);
        setLoadingListaOrgaosOrcamentarios(false);
      })
      .catch(erro => console.log('erro', erro));
  }

  const carregarListaAssessorias = () => {
    setLoadingListaAssessoria(true);
    Requisicao.getListaConsultoriasJuridicas()
      .then(data => {
        setListaAssessoriaJuridicaDestino(data);
        setLoadingListaAssessoria(false);
      })
      .catch(erro => console.log('erro', erro));
  }

  const carregarListaUnidades = () => {
    setLoadingListaUnidade(true);
    // Requisicao.getListaUnidades()
    //   .then(data => {
    //     setListaUnidades(data);
    //     setLoadingListaUnidade(false);
    //   })
    //   .catch(erro => console.log('erro', erro));
    Requisicao.getListaUnidadesPorSetor("FISCALIZACAO_E_CONTROLE")
      .then(data => {
        setListaUnidades(data);
        setLoadingListaUnidade(false);
      })
      .catch(erro => console.log('erro', erro));
  }

  // // set comboboxUnidade
  // useEffect(() => {
  //   window.$("#comboboxUnidade").empty();
  //   let combobox = document.querySelector("#comboboxUnidade").options;

  //   combobox.add(new Option("Selecione a Unidade de destino", "-1"));
  //   listaUnidades.forEach((element) => {
  //     combobox.add(new Option(element.nome, element.id));
  //   });

  //   combobox[0].disabled = true;
  //   combobox.selectedIndex = 0;
  // }, [listaUnidades]);

  const setupComboboxUnidade = (i) => {
    let id = "#comboboxUnidade" + i;
    window.$(id).on('change.select2', function (e) {
      setUnidadeDestinoId(e.target.value);
    });

    window.$(id).empty();
    let combobox = document.querySelector(id).options;

    combobox.add(new Option("Selecione a Unidade de destino", "-1"));
    listaUnidades?.forEach((element) => {
      combobox.add(new Option(element.nome, element.id));
    });

    combobox[0].disabled = true;
    combobox.selectedIndex = 0;
  }

  // setup comboboxOrgao
  const setupComboboxOrgao = (i) => {
    let id = "#comboboxOrgao" + i;
    window.$(id).on('change.select2', function (e) {
      setOrgaoDestino(e.target.value);
    });

    window.$(id).empty();
    let combobox = document.querySelector(id).options;

    combobox.add(new Option("Selecione o Órgão destino", "-1"));
    listaOrgaoDestino?.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    combobox[0].disabled = true;

    combobox.selectedIndex = 0;

    for (let index = 0; index < combobox.length; index++) {
      if (combobox[index].value === orgaoSolicitante) {
        combobox.selectedIndex = index;
        setOrgaoDestino(combobox[index].value);
      }
    }
  };

  // setup comboboxOrgaoOrcamentario
  const setupComboboxOrgaoOrcamentario = (i) => {
    let id = "#comboboxOrgaoOrcamentario" + i;
    window.$(id).on('change.select2', function (e) {
      setOrgaoOrcamentarioDestino(e.target.value);
    });

    window.$(id).empty();
    let combobox = document.querySelector(id).options;

    combobox.add(new Option("Selecione o Órgão Orçamentário de destino", "-1"));
    listaOrgaoOrcamentarioDestino?.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    combobox[0].disabled = true;

    combobox.selectedIndex = 0;

  };

  // setup comboboxAssessoriaJuridica
  const setupComboboxAssessoriaJuridica = (i) => {
    let id = "#comboboxAssessoriaJuridica" + i;
    window.$(id).on('change.select2', function (e) {
      setAssessoriaJuridicaDestino(e.target.value);
    });

    window.$(id).empty();
    let combobox = document.querySelector(id).options;

    combobox.add(new Option("Selecione a Consultoria Jurídica de destino", "-1"));
    listaAssessoriaJuridicaDestino?.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    combobox[0].disabled = true;

    combobox.selectedIndex = 0;

  };

  const cleanStateAndCombos = () => {
    setOrgaoDestino("");
    setAssessoriaJuridicaDestino("");
    setOrgaoOrcamentarioDestino("");
    setUnidadeDestinoId("");
    let combos = document.querySelectorAll(".comboDestino");
    if (combos != null && combos.length > 0) {
      combos.forEach(c => {
        c.classList.remove("d-block");
        c.classList.add("d-none");
      })
    }
  }

  return (
    <>
      <div className="modal-body">
        {
          (loading === true) ?
            <>
              <LoadingElement />
            </> :
            <>
              <h5>Escolha para onde você deseja encaminhar esta análise:</h5>
              <p>Esta análise será salva novamente antes de ser encaminhada.</p>
              {/* MUDAR TAMBÉM O ESTADO INDIVIDUAL DAS ANÁLISES */}
              {/* CRIAR ENCAMINHAMENTO - DESPACHO QUANDO O PROCESSO/ANALISE DEVE RETORNAR PARA UNIDADE/SECRETARIA */}

              {/* EM_ANALISE - EM_EDICAO */}
              {
                (filaAnalise === "EM_ANALISE" && statusAnalise === "EM_EDICAO") ?
                  <div className="form-group">
                    <div className="form-check">
                      <input type="radio" id="radioEcaminharParaVerificacao" className="form-check-input" name="radioEcaminharAnalise" value="EM_ANALISE"
                        onChange={(e) => {
                          if (e.target.checked) {
                            cleanStateAndCombos();
                            setNovaFila(e.target.value);
                            setNovoStatus("EM_VERIFICACAO");
                            setTipoEncaminhamento("INTERNO");
                          }
                        }} required />
                      <label htmlFor="radioEcaminharParaVerificacao" className="font-weight-normal">Encaminhar análise para verificação pelo revisor/responsável.</label>
                    </div>
                  </div> :
                  <></>
              }

              {/* EM_ANALISE - EM_VERIFICACAO */}
              {
                (filaAnalise === "EM_ANALISE" && statusAnalise === "EM_VERIFICACAO") ?
                  <div className="form-group">
                    <div className="form-check">
                      <input type="radio" id="radioEcaminharParaAnalise" className="form-check-input" name="radioEcaminharAnalise" value="EM_ANALISE"
                        onChange={(e) => {
                          if (e.target.checked) {
                            cleanStateAndCombos();
                            setNovaFila(e.target.value);
                            setNovoStatus("EM_EDICAO");
                            setTipoEncaminhamento("INTERNO");
                          }
                        }} required />
                      <label htmlFor="radioEcaminharParaAnalise" className="font-weight-normal">Encaminhar análise de volta para o analista para correção das observações.</label>
                    </div>
                    {
                      (tipoAnalise !== "ANALISE_PREVIA") ?
                        <div className="form-check">
                          <input type="radio" id="radioEcaminharParaRevisaoUC" className="form-check-input" name="radioEcaminharAnalise" value="EM_REVISAO"
                            onChange={(e) => {
                              if (e.target.checked) {
                                cleanStateAndCombos();
                                setNovaFila(e.target.value);
                                setNovoStatus("ENCAMINHADA");
                                setTipoEncaminhamento("UC");
                              }
                            }} />
                          <label htmlFor="radioEcaminharParaRevisaoUC" className="font-weight-normal">Encaminhar para o Órgão Central de Controle Interno para Revisão quanto à análise.</label>
                        </div> :
                        <></>
                    }
                    {
                      (tipoAnalise === "ANALISE_PREVIA" && (["ADMINISTRADOR", "SCF_ADMINISTRADOR", "SCF_REVISOR_UC"].includes(usuario?.tipoUsuario))) ?
                        <div className="form-check">
                          <input type="radio" id="radioEcaminharParaUDCI" className="form-check-input" name="radioEcaminharAnalise" value="EM_MANIFESTACAO"
                            onChange={(e) => {
                              if (e.target.checked) {
                                cleanStateAndCombos();
                                setNovaFila(e.target.value);
                                setNovoStatus("ENCAMINHADA");
                                setTipoEncaminhamento("UDCI");
                              }

                            }} />
                          <label htmlFor="radioEcaminharParaUDCI" className="font-weight-normal">Encaminhar para UDCI para cumprimento das recomendações.</label>
                        </div> :
                        <></>
                    }
                    {
                      (tipoAnalise === "ANALISE_PREVIA" && (["ADMINISTRADOR", "SCF_ADMINISTRADOR", "SCF_REVISOR_UC"].includes(usuario?.tipoUsuario))) ?
                        
                        <>
                          <div className="form-check">
                            <input type="radio" id="radioEcaminharParaUnidade" className="form-check-input" name="radioEcaminharAnalise" value="EM_MANIFESTACAO"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  cleanStateAndCombos();
                                  setNovaFila(e.target.value);
                                  setNovoStatus("ENCAMINHADA");
                                  setTipoEncaminhamento("UNIDADE");
                                  setupComboboxUnidade('1');
                                  document.getElementById("divComboboxUnidade1").classList.remove("d-none");
                                  document.getElementById("divComboboxUnidade1").classList.add("d-block");
                                }

                              }} />
                            <label htmlFor="radioEcaminharParaUnidade" className="font-weight-normal">Encaminhar para Unidade de Controle Interno para cumprimento das recomendações.</label>
                          </div>
                          <div id="divComboboxUnidade1" className='col-6 ml-3 d-none comboDestino'>
                            <label htmlFor="comboboxUnidade1">Selecione a Unidade de destino</label>
                            <select id="comboboxUnidade1" className="form-control select2bs4 comboDestinoOptions comboUnidade"></select>
                          </div>
                        </> :
                        <></>
                    }
                    {
                      // ((tipoAnalise === "ANALISE_PREVIA" && (["ADMINISTRADOR", "SCF_ADMINISTRADOR", "SCF_REVISOR_UC"].includes(usuario?.tipoUsuario)))
                      //   || (unidadeResponsavel != null && unidadeResponsavel.decretoDescentralizacao === true)
                      // ) ?
                      ((["ADMINISTRADOR", "SCF_ADMINISTRADOR", "SCF_REVISOR_UC"].includes(usuario?.tipoUsuario))
                        || (unidadeResponsavel != null && unidadeResponsavel.decretoDescentralizacao === true)
                      ) ?
                        <>
                          <div className="form-check">
                            <input type="radio" id="radioEcaminharParaFinalizado" className="form-check-input" name="radioEcaminharAnalise" value="EM_MANIFESTACAO"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  cleanStateAndCombos();
                                  setNovaFila(e.target.value);
                                  setNovoStatus("FINALIZADA");
                                  setTipoEncaminhamento("ORGAO");
                                  setupComboboxOrgao('1');
                                  document.getElementById("divComboboxOrgao1").classList.remove("d-none");
                                  document.getElementById("divComboboxOrgao1").classList.add("d-block");
                                }

                              }}
                            />
                            <label htmlFor="radioEcaminharParaFinalizado" className="font-weight-normal">Encaminhar para a Secretaria para cumprimento das recomendações.</label>
                          </div>
                          <div id="divComboboxOrgao1" className='col-6 ml-3 d-none comboDestino'>
                            <label htmlFor="comboboxOrgao1">Selecione a Secretaria de destino</label>
                            <select id="comboboxOrgao1" className="form-control select2bs4 comboDestinoOptions comboOrgao"></select>
                          </div>
                        </>
                        :
                        <></>
                    }
                    {
                      ((tipoAnalise === "ANALISE_DE_CONFORMIDADE" && (["ADMINISTRADOR", "SCF_ADMINISTRADOR", "SCF_REVISOR_UC"].includes(usuario?.tipoUsuario)))
                        || (tipoAnalise === "ANALISE_DE_CONFORMIDADE" && unidadeResponsavel != null && unidadeResponsavel.decretoDescentralizacao === true)
                      ) ?
                        <>
                          <div className="form-check">
                            <input type="radio" id="radioEcaminharParaFinalizadoConformidade" className="form-check-input" name="radioEcaminharAnalise" value="FINALIZADO"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  cleanStateAndCombos();
                                  setNovaFila(e.target.value);
                                  setNovoStatus("FINALIZADA");
                                  setTipoEncaminhamento("ORGAO");
                                  setupComboboxOrgao('2');
                                  document.getElementById("divComboboxOrgao2").classList.remove("d-none");
                                  document.getElementById("divComboboxOrgao2").classList.add("d-block");
                                }
                              }} />
                            <label htmlFor="radioEcaminharParaFinalizadoConformidade" className="font-weight-normal">Encaminhar Análise de Conformidade para Secretaria.</label>
                          </div>
                          <div id="divComboboxOrgao2" className='col-6 ml-3 d-none comboDestino'>
                            <label htmlFor="comboboxOrgao2">Selecione a Secretaria de destino</label>
                            <select id="comboboxOrgao2" className="form-control select2bs4 comboDestinoOptions comboOrgao"></select>
                          </div>
                        </>
                        :
                        <></>
                    }
                    {
                      ((["ADMINISTRADOR", "SCF_ADMINISTRADOR", "SCF_REVISOR_UC"].includes(usuario?.tipoUsuario))
                        || (unidadeResponsavel != null && unidadeResponsavel.decretoDescentralizacao === true)
                      ) ?
                        <>
                          <div className="form-check">
                            <input type="radio" id="radioEcaminharParaReservaOrcamentaria" className="form-check-input" name="radioEcaminharAnalise" value="FINALIZADO"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  cleanStateAndCombos();
                                  setNovaFila(e.target.value);
                                  setNovoStatus("FINALIZADA");
                                  setTipoEncaminhamento("RESERVA_ORCAMENTARIA");
                                  setupComboboxOrgaoOrcamentario('1');
                                  document.getElementById("divComboboxOrgaoOrcamentario1").classList.remove("d-none");
                                  document.getElementById("divComboboxOrgaoOrcamentario1").classList.add("d-block");
                                }
                              }} />
                            <label htmlFor="radioEcaminharParaReservaOrcamentaria" className="font-weight-normal">Encaminhar Análise para Reserva Orçamentária.</label>
                          </div>
                          <div id="divComboboxOrgaoOrcamentario1" className='col-6 ml-3 d-none comboDestino'>
                            <label htmlFor="comboboxOrgaoOrcamentario1">Selecione a Reserva Orçamentária de destino</label>
                            <select id="comboboxOrgaoOrcamentario1" className="form-control select2bs4 comboDestinoOptions comboOrgaoOrcamentario"></select>
                          </div>
                        </> : <></>
                    }
                    {
                      ((["ADMINISTRADOR", "SCF_ADMINISTRADOR", "SCF_REVISOR_UC"].includes(usuario?.tipoUsuario))
                        || (unidadeResponsavel != null && unidadeResponsavel.decretoDescentralizacao === true)
                      ) ?
                        <>
                          <div className="form-check">
                            <input type="radio" id="radioEcaminharParaAssessoriaJuridica" className="form-check-input" name="radioEcaminharAnalise" value="FINALIZADO"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  cleanStateAndCombos();
                                  setNovaFila(e.target.value);
                                  setNovoStatus("FINALIZADA");
                                  setTipoEncaminhamento("PARECER_JURIDICO");
                                  setupComboboxAssessoriaJuridica('1');
                                  document.getElementById("divComboboxAssessoriaJuridica1").classList.remove("d-none");
                                  document.getElementById("divComboboxAssessoriaJuridica1").classList.add("d-block");
                                }
                              }} />
                            <label htmlFor="radioEcaminharParaAssessoriaJuridica" className="font-weight-normal">Encaminhar Análise para Parecer Jurídico.</label>
                          </div>
                          <div id="divComboboxAssessoriaJuridica1" className='col-6 ml-3 d-none comboDestino'>
                            <label htmlFor="comboboxAssessoriaJuridica1">Selecione a Consultoria Jurídica de destino</label>
                            <select id="comboboxAssessoriaJuridica1" className="form-control select2bs4 comboDestinoOptions comboAssessoriaJuridica"></select>
                          </div>
                        </> : <></>
                    }

                    {
                      (tipoAnalise === "MANIFESTACAO") ?
                        <>
                          <div className="form-check">
                            <input type="radio" id="radioEcaminharDespachoParaSecretaria" className="form-check-input" name="radioEcaminharAnalise" value="FINALIZADO"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  cleanStateAndCombos();
                                  setNovaFila(e.target.value);
                                  setNovoStatus("FINALIZADA");
                                  setTipoEncaminhamento("ORGAO");
                                  setupComboboxOrgao('6');
                                  document.getElementById("divComboboxOrgao6").classList.remove("d-none");
                                  document.getElementById("divComboboxOrgao6").classList.add("d-block");
                                }
                              }} />
                            <label htmlFor="radioEcaminharDespachoParaSecretaria" className="font-weight-normal">Despachar processo de volta para Secretaria.</label>
                          </div>
                          <div id="divComboboxOrgao6" className='col-6 ml-3 d-none comboDestino'>
                            <label htmlFor="comboboxOrgao6">Selecione a Secretaria de destino</label>
                            <select id="comboboxOrgao6" className="form-control select2bs4 comboDestinoOptions comboOrgao"></select>
                          </div>
                        </> :
                        <></>
                    }
                  </div> :
                  <></>
              }

              {/* EM_REVISAO - EM_EDICAO */}
              {
                (filaAnalise === "EM_REVISAO" && statusAnalise === "EM_EDICAO") ?
                  <div className="form-group">
                    <div className="form-check">
                      <input type="radio" id="radioEcaminharParaVerificacao" className="form-check-input" name="radioEcaminharAnalise" value="EM_REVISAO"
                        onChange={(e) => {
                          if (e.target.checked) {
                            cleanStateAndCombos();
                            setNovaFila(e.target.value);
                            setNovoStatus("EM_VERIFICACAO");
                            setTipoEncaminhamento("INTERNO");
                          }
                        }} required />
                      <label htmlFor="radioEcaminharParaVerificacao" className="font-weight-normal">Encaminhar para verificação pelo revisor/responsável do Órgão Central de Controle Interno.</label>
                    </div>
                  </div>
                  :
                  <></>
              }

              {/* EM_REVISAO - EM_VERIFICACAO */}
              {
                (filaAnalise === "EM_REVISAO" && statusAnalise === "EM_VERIFICACAO") ?
                  <div className="form-group">
                    <div className="form-check">
                      <input type="radio" id="radioEcaminharDeVoltaParaRevisao" className="form-check-input" name="radioEcaminharAnalise" value="EM_REVISAO"
                        onChange={(e) => {
                          if (e.target.checked) {
                            cleanStateAndCombos();
                            setNovaFila(e.target.value);
                            setNovoStatus("EM_EDICAO");
                            setTipoEncaminhamento("INTERNO");
                          }
                        }} required />
                      <label htmlFor="radioEcaminharDeVoltaParaRevisao" className="font-weight-normal">Encaminhar de volta para o analista do Órgão Central de Controle Interno para correção das observações.</label>
                    </div>
                    <div className="form-check">
                      <input type="radio" id="radioEcaminharParaManifestacaoUDCI" className="form-check-input" name="radioEcaminharAnalise" value="EM_MANIFESTACAO"
                        onChange={(e) => {
                          if (e.target.checked) {
                            cleanStateAndCombos();
                            setNovaFila(e.target.value);
                            setNovoStatus("ENCAMINHADA");
                            setTipoEncaminhamento("UDCI");
                          }
                        }} />
                      <label htmlFor="radioEcaminharParaManifestacaoUDCI" className="font-weight-normal">Encaminhar para UDCI para cumprimento das recomendações.</label>
                    </div>
                    <>
                      <div className="form-check">
                        <input type="radio" id="radioEcaminharParaManifestacaoUnidade" className="form-check-input" name="radioEcaminharAnalise" value="EM_MANIFESTACAO"
                          onChange={(e) => {
                            if (e.target.checked) {
                              cleanStateAndCombos();
                              setNovaFila(e.target.value);
                              setNovoStatus("ENCAMINHADA");
                              setTipoEncaminhamento("UNIDADE");
                              setupComboboxUnidade('2');
                              document.getElementById("divComboboxUnidade2").classList.remove("d-none");
                              document.getElementById("divComboboxUnidade2").classList.add("d-block");
                            }
                          }} />
                        <label htmlFor="radioEcaminharParaManifestacaoUnidade" className="font-weight-normal">Encaminhar para Unidade de Controle Interno para cumprimento das recomendações.</label>
                      </div>
                      <div id="divComboboxUnidade2" className='col-6 ml-3 d-none comboDestino'>
                        <label htmlFor="comboboxUnidade2">Selecione a Unidade de destino</label>
                        <select id="comboboxUnidade2" className="form-control select2bs4 comboDestinoOptions comboUnidade"></select>
                      </div>
                    </>
                    {
                      (tipoAnalise === "REVISAO_ANALISE_DE_CONFORMIDADE" && (["ADMINISTRADOR", "SCF_ADMINISTRADOR", "SCF_REVISOR_UC"].includes(usuario?.tipoUsuario))) ?
                        <>
                          <div className="form-check">
                            <input type="radio" id="radioEcaminharParaFinalizado" className="form-check-input" name="radioEcaminharAnalise" value="FINALIZADO"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  cleanStateAndCombos();
                                  setNovaFila(e.target.value);
                                  setNovoStatus("FINALIZADA");
                                  setTipoEncaminhamento("ORGAO");
                                  setupComboboxOrgao('4');
                                  document.getElementById("divComboboxOrgao4").classList.remove("d-none");
                                  document.getElementById("divComboboxOrgao4").classList.add("d-block");
                                }
                              }} />
                            <label htmlFor="radioEcaminharParaFinalizado" className="font-weight-normal">Encaminhar Revisão de Análise de Conformidade para Secretaria.</label>
                          </div>
                          <div id="divComboboxOrgao4" className='col-6 ml-3 d-none comboDestino'>
                            <label htmlFor="comboboxOrgao4">Selecione a Secretaria de destino</label>
                            <select id="comboboxOrgao4" className="form-control select2bs4 comboDestinoOptions comboOrgao"></select>
                          </div>
                        </> :
                        <>
                          <div className="form-check">
                            <input type="radio" id="radioEcaminharParaManifestacaoSecretaria" className="form-check-input" name="radioEcaminharAnalise" value="EM_MANIFESTACAO"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  cleanStateAndCombos();
                                  setNovaFila(e.target.value);
                                  setNovoStatus("FINALIZADA");
                                  setTipoEncaminhamento("ORGAO");
                                  setupComboboxOrgao('3');
                                  document.getElementById("divComboboxOrgao3").classList.remove("d-none");
                                  document.getElementById("divComboboxOrgao3").classList.add("d-block");
                                }
                              }} />
                            <label htmlFor="radioEcaminharParaManifestacaoSecretaria" className="font-weight-normal">Encaminhar para Secretaria para cumprimento das recomendações.</label>
                          </div>
                          <div id="divComboboxOrgao3" className='col-6 ml-3 d-none comboDestino'>
                            <label htmlFor="comboboxOrgao3">Selecione a Secretaria de destino</label>
                            <select id="comboboxOrgao3" className="form-control select2bs4 comboDestinoOptions comboOrgao"></select>
                          </div>
                        </>
                    }
                    <>
                      <div className="form-check">
                        <input type="radio" id="radioEcaminharParaReservaOrcamentaria" className="form-check-input" name="radioEcaminharAnalise" value="FINALIZADO"
                          onChange={(e) => {
                            if (e.target.checked) {
                              cleanStateAndCombos();
                              setNovaFila(e.target.value);
                              setNovoStatus("FINALIZADA");
                              setTipoEncaminhamento("RESERVA_ORCAMENTARIA");
                              setupComboboxOrgaoOrcamentario('2');
                              document.getElementById("divComboboxOrgaoOrcamentario2").classList.remove("d-none");
                              document.getElementById("divComboboxOrgaoOrcamentario2").classList.add("d-block");
                            }
                          }} />
                        <label htmlFor="radioEcaminharParaReservaOrcamentaria" className="font-weight-normal">Encaminhar Análise para Reserva Orçamentária.</label>
                      </div>
                      <div id="divComboboxOrgaoOrcamentario2" className='col-6 ml-3 d-none comboDestino'>
                        <label htmlFor="comboboxOrgaoOrcamentario2">Selecione a Reserva Orçamentária de destino</label>
                        <select id="comboboxOrgaoOrcamentario2" className="form-control select2bs4 comboDestinoOptions comboOrgaoOrcamentario"></select>
                      </div>
                    </>
                    <>
                      <div className="form-check">
                        <input type="radio" id="radioEcaminharParaAssessoriaJuridica" className="form-check-input" name="radioEcaminharAnalise" value="FINALIZADO"
                          onChange={(e) => {
                            if (e.target.checked) {
                              cleanStateAndCombos();
                              setNovaFila(e.target.value);
                              setNovoStatus("FINALIZADA");
                              setTipoEncaminhamento("PARECER_JURIDICO");
                              setupComboboxAssessoriaJuridica('2');
                              document.getElementById("divComboboxAssessoriaJuridica2").classList.remove("d-none");
                              document.getElementById("divComboboxAssessoriaJuridica2").classList.add("d-block");
                            }
                          }} />
                        <label htmlFor="radioEcaminharParaAssessoriaJuridica" className="font-weight-normal">Encaminhar Análise para Parecer Jurídico.</label>
                      </div>
                      <div id="divComboboxAssessoriaJuridica2" className='col-6 ml-3 d-none comboDestino'>
                        <label htmlFor="comboboxAssessoriaJuridica2">Selecione a Consultoria Jurídica de destino</label>
                        <select id="comboboxAssessoriaJuridica2" className="form-control select2bs4 comboDestinoOptions comboAssessoriaJuridica"></select>
                      </div>
                    </>
                    {
                      (tipoAnalise === "MANIFESTACAO") ?
                        <>
                          <div className="form-check">
                            <input type="radio" id="radioEcaminharDespachoParaUDCI" className="form-check-input" name="radioEcaminharAnalise" value="EM_ANALISE"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  cleanStateAndCombos();
                                  setNovaFila(e.target.value);
                                  setNovoStatus("FINALIZADA");
                                  setTipoEncaminhamento("UDCI");
                                }
                              }} />
                            <label htmlFor="radioEcaminharDespachoParaUDCI" className="font-weight-normal">Despachar análise/processo de volta para UDCI.</label>
                          </div>
                        </> :
                        <></>
                    }
                    {
                      (tipoAnalise === "MANIFESTACAO") ?
                        <>
                          <div className="form-check">
                            <input type="radio" id="radioEcaminharDespachoParaUnidade" className="form-check-input" name="radioEcaminharAnalise" value="EM_ANALISE"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  cleanStateAndCombos();
                                  setNovaFila(e.target.value);
                                  setNovoStatus("FINALIZADA");
                                  setTipoEncaminhamento("UNIDADE");
                                  setupComboboxUnidade('3');
                                  document.getElementById("divComboboxUnidade3").classList.remove("d-none");
                                  document.getElementById("divComboboxUnidade3").classList.add("d-block");
                                }
                              }} />
                            <label htmlFor="radioEcaminharDespachoParaUnidade" className="font-weight-normal">Despachar análise/processo de volta para Unidade de Controle Interno.</label>
                          </div>
                          <div id="divComboboxUnidade3" className='col-6 ml-3 d-none comboDestino'>
                            <label htmlFor="comboboxUnidade3">Selecione a Unidade de destino</label>
                            <select id="comboboxUnidade3" className="form-control select2bs4 comboDestinoOptions comboUnidade"></select>
                          </div>
                        </> :
                        <></>
                    }
                  </div>
                  :
                  <></>
              }

              {/* EM_MANIFESTACAO - EM_EDICAO */}
              {
                (filaAnalise === "EM_MANIFESTACAO" && statusAnalise === "EM_EDICAO") ?
                  <>
                    <div className="form-group">
                      <div className="form-check">
                        <input type="radio" id="radioEcaminharParaVerificacao" className="form-check-input" name="radioEcaminharAnalise" value="EM_MANIFESTACAO"
                          onChange={(e) => {
                            if (e.target.checked) {
                              cleanStateAndCombos();
                              setNovaFila(e.target.value);
                              setNovoStatus("EM_VERIFICACAO");
                              setTipoEncaminhamento("INTERNO");
                            }
                          }} required />
                        <label htmlFor="radioEcaminharParaVerificacao" className="font-weight-normal">Encaminhar para verificação pelo revisor/responsável da Unidade.</label>
                      </div>
                    </div>
                  </> :
                  <></>
              }

              {/* EM_MANIFESTACAO - EM_VERIFICACAO */}
              {
                (filaAnalise === "EM_MANIFESTACAO" && statusAnalise === "EM_VERIFICACAO") ?
                  <>
                    <div className="form-group">
                      <div className="form-check">
                        <input type="radio" id="radioEcaminharDeVoltaParaAnalista" className="form-check-input" name="radioEcaminharAnalise" value="EM_MANIFESTACAO"
                          onChange={(e) => {
                            if (e.target.checked) {
                              cleanStateAndCombos();
                              setNovaFila(e.target.value);
                              setNovoStatus("EM_EDICAO");
                              setTipoEncaminhamento("INTERNO");
                            }
                          }} required />
                        <label htmlFor="radioEcaminharDeVoltaParaAnalista" className="font-weight-normal">Encaminhar de volta para o analista do Órgão Central de Controle Interno para correção das observações.</label>
                      </div>
                      <>
                        <div className="form-check">
                          <input type="radio" id="radioEcaminharParaSecretaria" className="form-check-input" name="radioEcaminharAnalise" value="EM_MANIFESTACAO"
                            onChange={(e) => {
                              if (e.target.checked) {
                                cleanStateAndCombos();
                                setNovaFila(e.target.value);
                                setNovoStatus("FINALIZADA");
                                setTipoEncaminhamento("ORGAO");
                                setupComboboxOrgao('5');
                                document.getElementById("divComboboxOrgao5").classList.remove("d-none");
                                document.getElementById("divComboboxOrgao5").classList.add("d-block");
                              }
                            }} required />
                          <label htmlFor="radioEcaminharParaSecretaria" className="font-weight-normal">Encaminhar para Secretaria para cumprimento das recomendações.</label>
                        </div>
                        <div id="divComboboxOrgao5" className='col-6 ml-3 d-none comboDestino'>
                          <label htmlFor="comboboxOrgao5">Selecione a Secretaria de destino</label>
                          <select id="comboboxOrgao5" className="form-control select2bs4 comboDestinoOptions comboOrgao"></select>
                        </div>
                      </>
                      <div className="form-check">
                        <input type="radio" id="radioEcaminharParaFinalizado" className="form-check-input" name="radioEcaminharAnalise" value="FINALIZADO"
                          onChange={(e) => {
                            if (e.target.checked) {
                              cleanStateAndCombos();
                              setNovaFila(e.target.value);
                              setNovoStatus("FINALIZADA");
                              setTipoEncaminhamento("INTERNO");
                            }
                          }} required />
                        <label htmlFor="radioEcaminharParaFinalizado" className="font-weight-normal">Verifica-se que todas as recomendações foram cumpridas/justificadas.</label>
                      </div>
                    </div>
                  </> :
                  <></>
              }

              {/* OBSERVAÇÕES */}
              <div className='my-3'>
                <label>Outras observações</label>
                <textarea className="form-control" rows={3} placeholder="Caso exista, insira aqui suas observações..."
                  value={observacoes}
                  onChange={(e) => setObservacoes(e.target.value)} ></textarea>
              </div>
            </>
        }
      </div>
    </>
  )
}
