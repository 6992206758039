import React, { Component } from 'react'
import { Route, Routes } from 'react-router-dom';
import Login from './modules/login/Login';
import Autenticar from './modules/login/Autenticar';
import 'tippy.js/dist/tippy.css';
import Rotas from './Rotas';

// import { withRouter } from './modules/helpers/withRouter';

class Main extends Component {

  constructor(props) {
    super(props)
    this.handleAutenticadoChange = this.handleAutenticadoChange.bind(this);
    this.logout = this.logout.bind(this);
  
    this.state = {
       autenticado: false,
       usuario: {}
    }
  }

  handleAutenticadoChange = (autenticado, usuario) => {
    this.setState({autenticado: autenticado, usuario: usuario});
    Autenticar.setAutenticado(autenticado);
  }

  logout = () => {
    Autenticar.clearLocalToken();
    Autenticar.setAutenticado(false);
    this.setState({autenticado: false, usuario: {}});
  }

  componentDidMount(){
    // if(!this.state.autenticado){
    //   return setTimeout(() => this.props.navigate("/login", { replace: true }));
    // }
    // console.log('this.props.location', this.props.location);
    window.toastr.options = {
      "closeButton": false,
      "debug": false,
      "newestOnTop": false,
      "progressBar": true,
      "positionClass": "toast-top-right",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "500",
      "hideDuration": "1000",
      "timeOut": "5000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
    }

    window.$(document).on('select2:open', (e) => {
      const selectId = e.target.id
  
      window.$(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function(key,value,){
          value.focus();
      })
    })
  }


  render() {
    return (
      <>
        <Routes>
          <Route path="/login" element={<Login autenticado={this.state.autenticado} usuario={this.state.usuario} handleAutenticadoChange={this.handleAutenticadoChange} />} />
          <Route path="/*" element={<Rotas autenticado={this.state.autenticado} usuario={this.state.usuario} logout={this.logout}/>} />
        </Routes>
      </>
    )
  }
}

// export default withRouter(Main);
export default Main;
