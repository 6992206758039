import React, { useEffect, useMemo, useState } from 'react'
import Tippy from '@tippyjs/react';
import Filho from './Filho';
import Recomendacao from './Recomendacao';
import SummernoteEditor from '../../../components/SummernoteEditor';
import { useParams } from 'react-router-dom';
import AdicionarRecomendacaoBancoModal from '../AdicionarRecomendacaoBancoModal';

let ordem = 1;

export default function Bloco(props) {

  const bloco = props.bloco;
  const usuario = props.usuario;
  const leiLicitacao = props.leiLicitacao;
  const tipoAnalise = props.tipoAnalise;
  const emRevisao = props.emRevisao;
  const onChangeBloco = props.onChangeBloco;
  const exibindo = props.exibindo ?? false;
  const isConformidade = props.isConformidade ?? false;

  const { analiseId } = useParams();

  const idsFilhos = useMemo(() => {
    let ids = [];
    bloco.filhos.forEach((filho) => {
      ids.push(filho.idCampo);
      filho.netos && filho.netos.forEach((neto) => {
        ids.push(neto.idCampo);
      });
    });
    return ids;
  }, [bloco.filhos]);

  const idCombobox = `comboboxIdsRecomendacao-${bloco.ordem}-${analiseId}`;

  const [textoRec, setTextoRec] = useState("");
  const [textoRecHTML, setTextoRecHTML] = useState("");
  const [idCampo, setIdCampo] = useState("");

  const [observacaoCampoDeTextoComplementar, setObservacaoCampoDeTextoComplementar] = useState(bloco?.observacaoCampoDeTextoComplementar ?? "");

  const [visibleTCC, setVisibleTCC] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showObservacaoTCC = () => {
    handleObservacaoTCCChange(observacaoCampoDeTextoComplementar);
    setVisibleTCC(true);
  }

  const hideObservacaoTCC = () => {
    handleObservacaoTCCChange(observacaoCampoDeTextoComplementar);
    setVisibleTCC(false);
  }
  const handleObservacaoTCCChange = (obs) => {
    onChangeBloco({ ...bloco, observacaoCampoDeTextoComplementar: observacaoCampoDeTextoComplementar })
  }

  const inputCommentTCC = (
    <>
      <label>Adicionar Comentário</label>
      <textarea className="form-control my-2" rows={4} style={{ width: "320px" }} value={observacaoCampoDeTextoComplementar} onChange={(e) => setObservacaoCampoDeTextoComplementar(e.target.value)} />
      <div className="text-center my-2">
        <button type="button" className="btn btn-primary mx-2" onClick={() => { setObservacaoCampoDeTextoComplementar(""); }}>Limpar</button>
        <button type="button" className="btn btn-primary mx-2" onClick={() => { hideObservacaoTCC(); }}>Ok</button>
      </div>
    </>
  );

  const buttonAdicionarComentario = (
    emRevisao && !exibindo ?
      <Tippy content={(observacaoCampoDeTextoComplementar != null && observacaoCampoDeTextoComplementar !== "") ? "Editar Comentário" : "Adicionar Comentário"} disabled={visibleTCC}>
        <Tippy interactive={true} maxWidth={350} visible={visibleTCC} content={inputCommentTCC} appendTo={document.body} >
          <button className={(observacaoCampoDeTextoComplementar != null && observacaoCampoDeTextoComplementar !== "") ? 'btn text-danger' : 'btn text-warning'} type="button" onClick={visibleTCC ? hideObservacaoTCC : showObservacaoTCC}>
            <i className="fas fa-comment-alt"></i>
          </button>
        </Tippy>
      </Tippy> :
      <></>
  );

  const buttonExibirComentario = (
    !emRevisao && !exibindo && observacaoCampoDeTextoComplementar != null && observacaoCampoDeTextoComplementar !== "" ?
      <Tippy interactive={true} maxWidth={350} visible={visibleTCC} content={observacaoCampoDeTextoComplementar} appendTo={document.body} >
        <button className='btn text-danger' type="button" onClick={visibleTCC ? hideObservacaoTCC : showObservacaoTCC}>
          <i className="fas fa-comment-alt"></i>
        </button>
      </Tippy> :
      <></>
  );

  useEffect(() => {

    if (idsFilhos != null && idsFilhos.length > 0 && !exibindo) {

      window.$("#" + idCombobox).empty();
      let combobox = document.getElementById(idCombobox).options;

      idsFilhos.forEach((element) => {
        combobox.add(new Option(element, element));
      });

      combobox.add(new Option("OUTROS", "OUTROS"));

      combobox.selectedIndex = 0;
      setIdCampo(localStorage.getItem(idCombobox) ?? document.getElementById(idCombobox).value);
    }
  }, [idsFilhos, idCombobox, exibindo])

  const handleChangeFilho = (filho) => {
    onChangeBloco({
      ...bloco,
      filhos: bloco.filhos.map((f) => {
        if (f.ordem === filho.ordem) {
          return filho;
        } else {
          return f;
        }
      })
    });
  }

  const swapArrayElements = (arr, indexA, indexB) => {
    var temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
    return arr;
  };

  const moverRecomedacaoParaCima = (r) => {
    if (bloco?.recomendacoes != null && bloco.recomendacoes.length > 0) {
      let recs = bloco.recomendacoes.slice();

      if (recs[0]?.ordem !== r?.ordem) {
        let index = null;
        for (let i = 0; i < recs.length; i++) {
          if (recs[i]?.ordem === r?.ordem) {
            index = i;
            break;
          }
        }
        if (index != null && index !== 0) {
          let temp = recs[index].ordem;
          recs[index].ordem = recs[index - 1].ordem;
          recs[index - 1].ordem = temp;
          recs = swapArrayElements(recs, index, index - 1);
          onChangeBloco({
            ...bloco,
            recomendacoes: [...recs],
          });
        }
      }
    }
  }

  const moverRecomedacaoParaBaixo = (r) => {
    if (bloco?.recomendacoes != null && bloco.recomendacoes.length > 0) {
      let recs = bloco.recomendacoes.slice();

      if (recs[recs.length - 1]?.ordem !== r?.ordem) {
        let index = null;
        for (let i = 0; i < recs.length; i++) {
          if (recs[i]?.ordem === r?.ordem) {
            index = i;
            break;
          }
        }
        if (index != null && index !== (recs.length - 1)) {
          let temp = recs[index].ordem;
          recs[index].ordem = recs[index + 1].ordem;
          recs[index + 1].ordem = temp;
          recs = swapArrayElements(recs, index, index + 1);
          onChangeBloco({
            ...bloco,
            recomendacoes: [...recs],
          });
        }
      }
    }
  }

  const deletarRecomendacao = (rec) => {
    let arrElmts = document.querySelectorAll(`.btn-concluir-edicao-bloco-${bloco.ordem}`);
    if (arrElmts != null && arrElmts.length > 0) {
      arrElmts.forEach(b => b?.click())
    }
    onChangeBloco({
      ...bloco,
      recomendacoes: bloco.recomendacoes.filter((r) => parseInt(rec.ordem) !== parseInt(r.ordem))
    })
  }

  const handleChangeRecomendacao = (rec) => {
    onChangeBloco({
      ...bloco,
      recomendacoes: bloco.recomendacoes.map((r) => {
        if (r.ordem === rec.ordem) {
          return rec;
        } else {
          return r;
        }
      })
    })
  }

  // useEffect(() => {
  //   console.log('textoRecHTML', textoRecHTML)
  // }, [textoRecHTML])

  const adicionarRecomendacao = (id) => {
    // if (textoRec !== "" || isConformidade) {
    if (textoRec !== "") {
      let campoId = idCampo;

      // if (!(bloco.filhos && bloco.filhos.length > 0)) {
      //   campoId = 'OUTROS';
      // }

      if (bloco.recomendacoes != null && bloco.recomendacoes.length > 0) {
        let arrElmts = document.querySelectorAll(`.btn-concluir-edicao-bloco-${bloco.ordem}`);
        if (arrElmts != null && arrElmts.length > 0) {
          arrElmts.forEach(b => b?.click())
        }
        bloco.recomendacoes.forEach((rec) => {
          if (rec.ordem >= ordem) {
            ordem = rec.ordem;
          }
          ordem++;
        })
        onChangeBloco({
          ...bloco,
          recomendacoes: [
            ...bloco.recomendacoes,
            {
              ordem: ordem++,
              idCampo: campoId,
              texto: textoRec,
              html: textoRecHTML
            }
          ]
        });
      } else {
        onChangeBloco({
          ...bloco,
          recomendacoes: [
            {
              ordem: ordem++,
              idCampo: campoId,
              texto: textoRec,
              html: textoRecHTML
            },
          ]
        });
      }
      setTextoRec("");
      setTextoRecHTML("");
      window.$('#' + id).summernote('code', '');
      if (!(bloco.filhos && bloco.filhos.length > 0)) {
        setIdCampo("");
      }
      localStorage.removeItem(id);
      localStorage.removeItem(idCombobox);
    }
  }

  const getIdAdicionarRecomendacao = (id) => {
    return id.replaceAll('.', '_').replaceAll(' ', '').replace(/[!"#$%&'()*+,/:;<=>?@[\\\]^`{|}~]/g,'');
  }

  return (
    <>
      {/* // <AdicionarRecomendacaoBancoModal blocoId={`-${bloco.ordem}-${analiseId}`} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} 
      //   onSelect={(html) => {
      //     window.$('#' + getIdAdicionarRecomendacao(`textareaAdicionarRecomendacaoBloco-${bloco.ordem}-${analiseId}`)).summernote('code', html);
      //   }}/> */}
      <div className="modal fade" id={`modalAdicionarRecomendacaoBanco-${bloco.ordem}-${analiseId}`} tabIndex={-1} aria-labelledby="modalAdicionarRecomendacaoBancoLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modalAdicionarRecomendacaoBancoLabel">Adicionar Recomendação</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setIsModalOpen(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {isModalOpen && (
            <AdicionarRecomendacaoBancoModal isOpen={isModalOpen} usuario={usuario} customId={`${bloco.ordem}-${analiseId}`}
              titulo={bloco?.titulo} lei={leiLicitacao} tipo={tipoAnalise}
              onSelect={(html) => {
                window.$('#' + getIdAdicionarRecomendacao(`textareaAdicionarRecomendacaoBloco-${bloco.ordem}-${analiseId}`)).summernote('code', html);
              }}/>
          )}

          <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => setIsModalOpen(false)}>Fechar</button>
            </div>
          </div>
        </div>
      </div>
    <div className='d-flex flex-row'>
      <table className="table table-bordered">
        <thead className='bloco-thead'>
          <tr style={{ backgroundColor: "#d5e3e0" }}>
            <th colSpan={(isConformidade) ? 4 : 3} scope="colgroup" className="text-center">{bloco.titulo}</th>
          </tr>
          {
            (bloco.possuiCampoDeTextoInicial) ?
              <>
                <tr>
                  <th colSpan={(isConformidade) ? 4 : 3} scope='colgroup' className='text-justify fix-linebreak font-weight-normal'>
                    {/* {bloco.campoDeTextoInicial} */}
                    {
                      bloco?.campoDeTextoInicialHTML !== null && bloco?.campoDeTextoInicialHTML !== "" ? <div dangerouslySetInnerHTML={{__html: bloco.campoDeTextoInicialHTML}}></div> : bloco.campoDeTextoInicial
                    }
                  </th>
                </tr>
              </> :
              <></>
          }
          {
            bloco.filhos && bloco.filhos.length > 0 ?
              <tr>
                <th scope="col" className="text-center" style={{ width: "5%" }}>ID</th>
                <th scope="col" className="text-center">Requisitos Mínimos</th>
                {
                  (isConformidade) ?
                    <th scope="col" className="text-center">Consta</th> :
                    <></>
                }
                <th scope="col" className="text-center" style={{ width: "15%" }}>Folhas</th>
              </tr> :
              <></>
          }
        </thead>
        <tbody>
          {
            bloco.filhos && bloco.filhos.map((filho) => (
              <Filho key={filho.ordem} filho={filho} onChangeFilho={handleChangeFilho} emRevisao={emRevisao} exibindo={exibindo} isConformidade={isConformidade} idBloco={bloco.ordem} />
            ))
          }
        </tbody>
        <tfoot className='bloco-tfoot'>
          <tr>
            <th colSpan={(isConformidade) ? 4 : 3} scope="colgroup" className="text-center">{(isConformidade) ? "Informações Adicionais e Recomendações" : "Recomendações"}</th>
          </tr>
          {
            exibindo ?
              <></> :
              <tr>
                <th colSpan={(isConformidade) ? 4 : 3} scope="colgroup">
                  {
                    bloco.filhos && bloco.filhos.length > 0 ?
                      <div className='d-flex flex-row' style={{ fontWeight: 'normal' }}>
                        <select id={`comboboxIdsRecomendacao-${bloco.ordem}-${analiseId}`} className='mx-1 form-control col-1 text-center' 
                        value={idCampo} 
                        // value={idCampo != null && idCampo !== "" ? idCampo : localStorage.getItem(`comboboxIdsRecomendacao-${bloco.ordem}-${analiseId}`)} 
                        onChange={(e) => { 
                          setIdCampo(e.target.value);
                          localStorage.setItem(`comboboxIdsRecomendacao-${bloco.ordem}-${analiseId}`, e.target.value);
                          }}>
                        </select>
                        {/* <textarea rows={1} className='form-control textarea-recomendacao' onChange={(e) => setTextoRec(e.target.value)} value={textoRec}></textarea> */}
                        <div className='col'>
                          <SummernoteEditor id={`textareaAdicionarRecomendacaoBloco-${bloco.ordem}-${analiseId}`}
                            placeholder='Insira o texto da recomendação...'
                            // classNameEditable='textarea-recomendacao resize-vertical'
                            classNameEditable='textarea-recomendacao'
                            classNameEditor=''
                            height={100}
                            textSetState={setTextoRec}
                            codeSetState={setTextoRecHTML}
                            createOnFocus={false}
                            // createOnFocus={localStorage.getItem(getIdAdicionarRecomendacao(`textareaAdicionarRecomendacaoBloco-${bloco.ordem}-${analiseId}`)) != null &&
                            //                localStorage.getItem(getIdAdicionarRecomendacao(`textareaAdicionarRecomendacaoBloco-${bloco.ordem}-${analiseId}`)) !== "" ? false : true}
                            disableHelp={true}
                            initialContent={localStorage.getItem(getIdAdicionarRecomendacao(`textareaAdicionarRecomendacaoBloco-${bloco.ordem}-${analiseId}`)) ?? ""}
                            saveOnLocalStorage={true}
                          // airMode={true}
                          />
                        </div>
                        <Tippy content="Adiciona o texto inserido à lista de recomendações. A recomendação deve ser adicionada à análise antes de salvá-la.">
                          <button type='button' className="btn text-primary col-1" onClick={() => adicionarRecomendacao(`textareaAdicionarRecomendacaoBloco-${bloco.ordem}-${analiseId}`)} style={{ width: "15%" }}><i className="fa fa-plus" /></button>
                        </Tippy>
                        <Tippy content="Pesquisar por uma recomendação no banco de recomendações da sua unidade.">
                          <button type='button' className="btn text-primary col-1" data-toggle="modal" data-target={`#modalAdicionarRecomendacaoBanco-${bloco.ordem}-${analiseId}`} 
                          style={{ width: "15%" }} onClick={() => setIsModalOpen(true)}><i className="fas fa-search" /></button>
                        </Tippy>
                      </div> :
                      <div className='d-flex flex-row' style={{ fontWeight: 'normal' }}>
                        {/* <input id={`inputIdsRecomendacao-${bloco.ordem}`} className='mx-1 form-control col-1' value={idCampo} onChange={(e) => { setIdCampo(e.target.value) }}></input> */}
                        {/* <textarea rows={1} className='form-control textarea-recomendacao' onChange={(e) => setTextoRec(e.target.value)} value={textoRec}></textarea> */}
                        <div className='col'>
                          <SummernoteEditor id={`textareaAdicionarRecomendacaoBloco-${bloco.ordem}-${analiseId}`}
                            placeholder='Insira o texto da recomendação...'
                            classNameEditable='textarea-recomendacao'
                            // classNameEditable='textarea-recomendacao resize-vertical'
                            classNameEditor=''
                            height={100}
                            textSetState={setTextoRec}
                            codeSetState={setTextoRecHTML}
                            createOnFocus={false}
                            // createOnFocus={localStorage.getItem(getIdAdicionarRecomendacao(`textareaAdicionarRecomendacaoBloco-${bloco.ordem}-${analiseId}`)) != null &&
                            //                localStorage.getItem(getIdAdicionarRecomendacao(`textareaAdicionarRecomendacaoBloco-${bloco.ordem}-${analiseId}`)) !== "" ? false : true}
                            disableHelp={true}
                            initialContent={localStorage.getItem(getIdAdicionarRecomendacao(`textareaAdicionarRecomendacaoBloco-${bloco.ordem}-${analiseId}`)) ?? ""}
                            saveOnLocalStorage={true}
                          // airMode={true}
                          />
                        </div>
                        <Tippy content="Adiciona o texto inserido à lista de recomendações. A recomendação deve ser adicionada à análise antes de salvá-la.">
                          <button type='button' className="btn text-primary col-1" 
                          onClick={() => {
                            document.getElementById(`textareaAdicionarRecomendacaoBloco-${bloco.ordem}-${analiseId}`).focus();
                            adicionarRecomendacao(`textareaAdicionarRecomendacaoBloco-${bloco.ordem}-${analiseId}`);
                          }} 
                          style={{ width: "15%" }}><i className="fa fa-plus" /></button>
                          {/* <button type='button' className="btn text-primary" onClick={() => adicionarRecomendacao()} style={{ width: "18%" }}><i className="fa fa-plus" /></button> */}
                        </Tippy>
                        <Tippy content="Pesquisar por uma recomendação no banco de recomendações da sua unidade.">
                          <button type='button' className="btn text-primary col-1" data-toggle="modal" data-target={`#modalAdicionarRecomendacaoBanco-${bloco.ordem}-${analiseId}`} 
                          style={{ width: "15%" }} onClick={() => setIsModalOpen(true)}><i className="fas fa-search" /></button>
                        </Tippy>
                      </div>
                  }

                </th>
              </tr>
          }
          {
            bloco.recomendacoes && bloco.recomendacoes.map((rec, index) => {
              return (
                <Recomendacao key={rec.ordem} recomendacao={rec} idBloco={bloco.ordem} index={index}
                  onDeleteRecomendacao={deletarRecomendacao}
                  onChangeRecomendacao={handleChangeRecomendacao}
                  moveUp={moverRecomedacaoParaCima}
                  moveDown={moverRecomedacaoParaBaixo}
                  emRevisao={emRevisao} exibindo={exibindo} isConformidade={isConformidade} />)
            })
          }
          {
            (bloco?.possuiCampoDeTextoComplementar) ?
              <>
                {
                  (exibindo) ?
                    <>
                      <tr>
                        <th colSpan={(isConformidade) ? 4 : 3} scope="colgroup" className='text-center'><br /></th>
                      </tr>
                      <tr>
                        <th colSpan={(isConformidade) ? 4 : 3} scope='colgroup' className='text-justify fix-linebreak'>
                          {bloco.campoDeTextoComplementar}
                        </th>
                      </tr>
                    </> :
                    <>
                      <tr>
                        <th colSpan={(isConformidade) ? 4 : 3} scope="colgroup" className='text-center'>
                          <label>Observações do Analista</label>
                          {buttonAdicionarComentario}{buttonExibirComentario}
                        </th>
                      </tr>
                      <tr>
                        <td colSpan={(isConformidade) ? 4 : 3}>
                          <textarea rows={4} className='form-control' placeholder='Insira as informações adicionais pertinentes...' value={bloco?.campoDeTextoComplementar}
                            onChange={(e) => onChangeBloco({
                              ...bloco,
                              possuiCampoDeTextoComplementar: true,
                              campoDeTextoComplementar: e.target.value
                            })}
                          ></textarea>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={(isConformidade) ? 4 : 3} className='text-center'>
                          <button type='button' className='btn btn-outline-danger' onClick={() => {
                            if (window.confirm("Deseja excluir o campo de Observações?") === true) {
                              onChangeBloco({
                                ...bloco,
                                possuiCampoDeTextoComplementar: false,
                                campoDeTextoComplementar: ""
                              })
                            }
                          }}>Remover Observações</button>
                        </td>
                      </tr>
                    </>
                }

              </> :
              <>
                {
                  (exibindo) ?
                    <></> :
                    <tr>
                      <td colSpan={(isConformidade) ? 4 : 3} className='text-center'>
                        <button type='button' className='btn btn-outline-secondary' onClick={() => {
                          onChangeBloco({
                            ...bloco,
                            possuiCampoDeTextoComplementar: true,
                            campoDeTextoComplementar: ""
                          })
                        }}>Adicionar Observações</button>
                      </td>
                    </tr>
                }
              </>
          }
          {
            (bloco.possuiCampoDeTextoFinal) ?
              <>
                <tr>
                  <th colSpan={(isConformidade) ? 4 : 3} scope="colgroup" className='text-center'><br /></th>
                </tr>
                <tr>
                  <th colSpan={(isConformidade) ? 4 : 3} scope='colgroup' className='text-justify fix-linebreak font-weight-normal'>
                    {/* {bloco.campoDeTextoFinal} */}
                    {
                      bloco?.campoDeTextoFinalHTML !== null && bloco?.campoDeTextoFinalHTML !== "" ? <div dangerouslySetInnerHTML={{__html: bloco.campoDeTextoFinalHTML}}></div> : bloco.campoDeTextoFinal
                    }
                  </th>
                </tr>
              </> :
              <></>
          }
        </tfoot>
      </table>
    </div></>
  )
}
