import React, { useEffect } from 'react';

export default function Home(props) {

  useEffect(() => {
    document.title = 'Página Inicial - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
  }, [])

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Página Inicial</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item active">Página Inicial</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col">
                <div className="card">
                  <div className="card-header text-center">
                    {/* <h5 className="m-0">Bem vindo(a) ao sistema de controle interno da CONGEM Macaé</h5> */}
                    <img src="/logo_controladoria.png" alt="Logo da CONGEM" className="brand-image" style={{ opacity: '.9', maxHeight: '20vh', maxWidth: "100%" }} />
                  </div>
                  <div className="card-body">
                    <p className="card-text text-justify fix-linebreak">{"\t"}
                      O Sistema Integrado de Controle Interno do Município de Macaé, é um sistema idealizado para abranger todas as manifestações dos órgãos de controle interno.
                    </p>
                    <p className="card-text text-justify fix-linebreak">{"\t"}
                      Com a descentralização das unidades, a fim de criar uma Rede de Controle Interno no Município, objetivou-se criar um sistema para que houvesse integridade e celeridade nas informações e comunicações.
                    </p>
                    <p className="card-text text-justify fix-linebreak">{"\t"}
                      Assim, o sistema visa nortear e orientar a atuação das Secretarias, através das respectivas Unidades Descentralizadas de Controle Interno (UDCI), mantendo a vinculação à Controladoria Geral do Município.
                    </p>
                    <p className="card-text text-justify fix-linebreak">{"\t"}
                      Para tanto, o sistema contém modelos de análise, índices explicativos, fluxos de tela, filas, status processuais, dentre outras funcionalidades e ferramentas.
                    </p>
                    <p className="card-text text-justify fix-linebreak">{"\t"}
                      Periodicamente, serão realizadas atualizações, que serão pautadas em sua utilidade, mantendo a padronização das tarefas para todos os operadores da Rede de controle.
                    </p>
                    <p className="card-text text-justify fix-linebreak">{"\t"}
                      Desta forma, objetiva-se otimizar o trabalho do Órgão Central de Controle Interno e das Unidades Descentralizadas de Controle Interno, mantendo um padrão para todas as análises, possibilitando a criação de níveis mínimos de gerenciamento do trabalho interno, e facilitando a comunicação dos órgãos que compreendem a rede de controle interno municipal.
                    </p>
                    <p></p>
                  </div>
                  {/* <div className="card-body">
                    <p className="card-text text-justify">&nbsp;&nbsp;&nbsp;&nbsp;
                      O Sistema Integrado de Controle Interno do Município de Macaé, tem por objetivo nortear e orientar todas as Secretarias através das Unidades Descentralizadas de Controle Interno (UDCI) do Município de Macaé.
                    </p>
                    <p className="card-text text-justify">&nbsp;&nbsp;&nbsp;&nbsp;
                      Conterá no sistema, manuais, fluxos de tela, modelos de análise, filas, status processuais entre outras ferramentas. Sua evolução sempre será pautada em usabilidade sempre mantendo a padronização das tarefas. Facilitando o trabalho do Órgão Central de Controle Interno e das Unidades Descentralizadas de Controle Interno (UDCI) mantendo um padrão para todas as análises criando níveis mínimos de gerenciamento do trabalho interno.
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
