import Tippy from '@tippyjs/react';
import React, { useState } from 'react'
import Neto from './Neto'

export default function Filho({filho, emRevisao, onChangeFilho, exibindo, isConformidade, idBloco}) {

    const observacao = filho?.observacao ?? "";

    const [comentario, setComentario] = useState(observacao);

    const [visible, setVisible] = useState(false);
    
    const show = () => {
        setComentario(observacao);
        setVisible(true);
      }
  
      const hide = () => {
        handleObservacaoChange(comentario); 
        setVisible(false);
      }

    const handleValorChange = (e) => {
        onChangeFilho({...filho, valor: e.target.value});
    }

    const handleObservacaoChange = (obs) => {
        onChangeFilho({...filho, observacao: obs});
    }

    const handleConstaChange = (consta) => {
      onChangeFilho({...filho, consta: consta})
    }


    const handleChangeNeto = (neto) => {
    onChangeFilho({
        ...filho,
        netos: filho.netos.map((n) => {
            if(n.ordem === neto.ordem){
                return neto;
            } else {
                return n;
            }
            })
        });
    }

    const inputComment = (
        <>
          <label>Adicionar Comentário</label>
          <textarea className="form-control my-2" rows={4} style={{width: "320px"}} value={comentario} onChange={(e)=>setComentario(e.target.value)}/>
          <div className="text-center my-2">
            <button type="button" className="btn btn-primary mx-2" onClick={()=>{setComentario("");}}>Limpar</button>
            <button type="button" className="btn btn-primary mx-2" onClick={()=>{hide();}}>Ok</button>
          </div>
        </>
      );
  
    const buttonAdicionarComentario = (
      emRevisao && !exibindo ?
      <Tippy content={(comentario != null && comentario !== "") ? "Editar Comentário" : "Adicionar Comentário"} disabled={visible}>
          <Tippy interactive={true} maxWidth={350} visible={visible} content={inputComment} appendTo={document.body} >
          <button className={(comentario != null && comentario !== "") ? 'btn text-danger': 'btn text-warning'} type="button" onClick={visible ? hide : show}>
              <i className="fas fa-comment-alt"></i>
          </button>
          </Tippy>
      </Tippy> :
      <></>
    );

    const buttonExibirComentario = (
        !emRevisao && !exibindo && comentario != null && comentario !== "" ?
          <Tippy interactive={true} maxWidth={350} visible={visible} content={comentario} appendTo={document.body} >
            <button className='btn text-danger' type="button" onClick={visible ? hide : show}>
              <i className="fas fa-comment-alt"></i>
            </button>
        </Tippy> :
        <></>
      );

  return (
    <>
        <tr>
            <td className='text-center text-bold' id={`campoFilhoNeto-${idBloco}-${filho.idCampo}`}>{filho.idCampo}</td>
            <td className='text-bold text-justify fix-linebreak'>{filho.texto}&nbsp;{buttonAdicionarComentario}{buttonExibirComentario}</td>
            {
              (isConformidade) ?
              <>
                {
                  (exibindo) ?
                  <td style={{width: "5%"}}>
                    <p className='text-center'>
                      {(filho?.consta) ? "SIM" : "NÃO"}
                    </p>
                  </td> :
                  <td style={{width: "5%"}}>
                    <input type='checkbox' className='form-control text-center reduzir-scale' checked={filho?.consta} onChange={(e) => handleConstaChange(e.target.checked)}></input>
                  </td>
                }
              </> :
              <></>
            }
            <td>
              {
                exibindo ?
                <>
                  <p className='text-center'>
                    {filho.valor ?? ""}
                  </p>
                </> :
                <>
                  <textarea rows={1} className='form-control' onChange={handleValorChange} value={filho.valor ?? ""}></textarea>
                </>
              }
            </td>
        </tr>
        {
            filho.netos && filho.netos.map((neto) => (
                <Neto key={neto.ordem} neto={neto} onChangeNeto={handleChangeNeto} emRevisao={emRevisao} exibindo={exibindo} idBloco={idBloco} isConformidade={isConformidade}/>
            ))
        }
    </>
  )
}
