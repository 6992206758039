import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Contatos(props) {

  useEffect(()=>{
    document.title = 'Contatos - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
  }, [])

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Contatos</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Contatos
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col">
                <div className="card">
                  <div className="card-body">
                    <p className="card-text text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <strong>Subcontroladoria de Controle e Fiscalização</strong>
                    </p>
                    <p className="card-text text-justify">&nbsp;&nbsp;&nbsp;&nbsp;
                        Prefeitura Municipal de Macaé - Controladoria Geral de Macaé - Av. Presidente Sodré, 534, <strong>3ºAndar</strong> Centro - CEP: 27913-080
                    </p>
                    <p className="card-text text-justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <strong>Tel.:</strong> (22) 2791-9008 <strong>Ramal:</strong> 194
                    </p>
                    <p className="card-text text-justify">&nbsp;&nbsp;&nbsp;&nbsp;
                        E-mail: <a target='_blank' rel='noreferrer' href='mailto:contratoslicitacoesmacae@gmail.com'>contratoslicitacoesmacae@gmail.com</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
