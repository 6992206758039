class TipoCampoConformidade {
    constructor(){
        this.tipos = {
            CONTRATO: "Contrato",
            ARP: "Ata de Registro de Preços",
            NOTA_DE_EMPENHO: "Nota de Empenho",
        };
    }


    getNome(tipoCampoConformidade) {
        return this.tipos[tipoCampoConformidade];
    }
    
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new TipoCampoConformidade();