import React, { useEffect, useRef, useState } from 'react'
import CampoFilho from './CampoFilho';
import Tippy from '@tippyjs/react';
import SummernoteEditor from '../../components/SummernoteEditor';

// let nextId = 2;
// let setupInicial = true;

export default function CampoPai(props) {
  
  const onDeleteBloco = props.onDeleteBloco;
  const onChangeBloco = props.onChangeBloco;
  const bloco = props.bloco;
  const filhos = bloco.filhos;
  const pti = bloco?.possuiCampoDeTextoInicial;
  const ptf = bloco?.possuiCampoDeTextoFinal;

  const [possuiCampoDeTextoInicial, setPossuiCampoDeTextoInicial] = useState(false);
  const [possuiCampoDeTextoFinal, setPossuiCampoDeTextoFinal] = useState(false);

  // const [campoDeTextoInicial, setCampoDeTextoInicial] = useState("");
  // const [campoDeTextoInicialHTML, setCampoDeTextoInicialHTML] = useState("");
  // const [campoDeTextoFinal, setCampoDeTextoFinal] = useState("");
  // const [campoDeTextoFinalHTML, setCampoDeTextoFinalHTML] = useState("");

  const [campoDeTextoInicialEInicialHTML, setCampoDeTextoInicialEInicialHTML] = useState({texto: "", html: ""});
  const [campoDeTextoFinalEFinalHTML, setCampoDeTextoFinalEFinalHTML] = useState({texto: "", html: ""});

  const cdti = useRef("");
  const cdtih = useRef("");
  const cdtf = useRef("");
  const cdtfh = useRef("");

  useEffect(()=>{
    if(pti != null){
      setPossuiCampoDeTextoInicial(pti);
    }
    if(ptf != null){
      setPossuiCampoDeTextoFinal(ptf);
    }
  }, [pti, ptf])

  // useEffect(() => {
  //   console.log('bloco', bloco);
  // }, [bloco])

  // useEffect(()=>{
  //   if(setupInicial && filhos?.length > 0){
  //     for (let i = 0; i < filhos?.length; i++) {
  //       if(filhos[i].ordem >= nextId){
  //         nextId = filhos[i].ordem + 1;
  //       }  
  //       console.log('filhos['+i+'].ordem', filhos[i].ordem)
  //       console.log('filho nextId', nextId)
  //     }
  //     setupInicial = false;
  //     console.log('filhos nextId', nextId);
  //   }
  // }, [filhos]);

  const adicionarCampoFilho = () => {
    // onChangeBloco({
    //   ...bloco,
    //   filhos: [
    //     ...filhos, {
    //       ordem: nextId++,
    //       idCampo: "",
    //       texto: ""
    //     }
    //   ]
    // });


    let nextOrdem = 1;
    if(filhos?.length > 0){
      for (let i = 0; i < filhos?.length; i++) {
        if(filhos[i].ordem >= nextOrdem){
          nextOrdem = filhos[i].ordem + 1;
        }
      }
    }

    onChangeBloco({
      ...bloco,
      filhos: [
        ...filhos, {
          ordem: nextOrdem,
          idCampo: "",
          texto: ""
        }
      ]
    });
  }

  const deletarCampoFilho = (ordem) => {
    onChangeBloco({
      ...bloco,
      filhos: filhos.filter((f) => parseInt(f.ordem) !== ordem)
    });
  }

  const alterarCampoFilho = (filho) => {
    onChangeBloco({
      ...bloco,
      filhos: filhos.map((f) => {
        if (f.ordem === filho.ordem) {
          return filho;
        } else {
          return f;
        }
      })
    });
  }


  // const alterarCampoDeTextoInicial = (texto) => {
  //   onChangeBloco({
  //     ...bloco,
  //     possuiCampoDeTextoInicial: true,
  //     campoDeTextoInicial: texto
  //   })
  // }

  // const alterarCampoDeTextoInicialHTML = (texto) => {
  //   onChangeBloco({
  //     ...bloco,
  //     possuiCampoDeTextoInicial: true,
  //     campoDeTextoInicialHTML: texto
  //   })
  // }

  // const alterarCampoDeTextoFinal = (texto) => {
  //   onChangeBloco({
  //     ...bloco,
  //     possuiCampoDeTextoFinal: true,
  //     campoDeTextoFinal: texto
  //   })
  // }

  // const alterarCampoDeTextoFinalHTML = (texto) => {
  //   onChangeBloco({
  //     ...bloco,
  //     possuiCampoDeTextoFinal: true,
  //     campoDeTextoFinalHTML: texto
  //   })
  // }

  // useEffect(() => {
  //   // handleChangeCampoDeTextoInicial(campoDeTextoInicial, campoDeTextoInicialHTML);
  //   onChangeBloco({
  //     ...bloco,
  //     possuiCampoDeTextoInicial: true,
  //     campoDeTextoInicial: campoDeTextoInicial,
  //     campoDeTextoInicialHTML: campoDeTextoInicialHTML
  //   });
  // }, [campoDeTextoInicial, campoDeTextoInicialHTML, bloco, onChangeBloco])

  // useEffect(() => {
  //   // handleChangeCampoDeTextoFinal(campoDeTextoFinal, campoDeTextoFinalHTML);
  //   onChangeBloco({
  //     ...bloco,
  //     possuiCampoDeTextoFinal: true,
  //     campoDeTextoFinal: campoDeTextoFinal,
  //     campoDeTextoFinalHTML: campoDeTextoFinalHTML
  //   });
  // }, [campoDeTextoFinal, campoDeTextoFinalHTML, bloco, onChangeBloco])

  // const handleChangeCampoDeTextoInicial = (texto, html) => {
  //   onChangeBloco({
  //     ...bloco,
  //     possuiCampoDeTextoInicial: true,
  //     campoDeTextoInicial: texto,
  //     campoDeTextoInicialHTML: html
  //   });
  // }

  // const handleChangeCampoDeTextoFinal = (texto, html) => {
  //   onChangeBloco({
  //     ...bloco,
  //     possuiCampoDeTextoFinal: true,
  //     campoDeTextoFinal: texto,
  //     campoDeTextoFinalHTML: html
  //   });
  // }

  // const handleChangeCampoDeTexto = (texto, html, tipo) => {
  //   if(tipo === "inicial"){
  //     onChangeBloco({
  //       ...bloco,
  //       possuiCampoDeTextoInicial: true,
  //       campoDeTextoInicial: texto,
  //       campoDeTextoInicialHTML: html
  //     });
  //   }
  //   if(tipo === "final"){
  //     onChangeBloco({
  //       ...bloco,
  //       possuiCampoDeTextoFinal: true,
  //       campoDeTextoFinal: texto,
  //       campoDeTextoFinalHTML: html
  //     });
  //   }
  // }


  useEffect(() => {
    if(cdti.current !== campoDeTextoInicialEInicialHTML.texto || cdtih.current !== campoDeTextoInicialEInicialHTML.html){
      cdti.current = campoDeTextoInicialEInicialHTML.texto;
      cdtih.current = campoDeTextoInicialEInicialHTML.html;
      onChangeBloco({
        ...bloco,
        possuiCampoDeTextoInicial: true,
        campoDeTextoInicial: campoDeTextoInicialEInicialHTML.texto,
        campoDeTextoInicialHTML: campoDeTextoInicialEInicialHTML.html
      });
    }
  }, [campoDeTextoInicialEInicialHTML, bloco, onChangeBloco])

  useEffect(() => {
    if(cdtf.current !== campoDeTextoFinalEFinalHTML.texto || cdtfh.current !== campoDeTextoFinalEFinalHTML.html){
      cdtf.current = campoDeTextoFinalEFinalHTML.texto;
      cdtfh.current = campoDeTextoFinalEFinalHTML.html;
      onChangeBloco({
        ...bloco,
        possuiCampoDeTextoFinal: true,
        campoDeTextoFinal: campoDeTextoFinalEFinalHTML.texto,
        campoDeTextoFinalHTML: campoDeTextoFinalEFinalHTML.html
      });     
    }
  }, [campoDeTextoFinalEFinalHTML, bloco, onChangeBloco])


  const removerCampoTextoInicial = () => {
    setPossuiCampoDeTextoInicial(false);
    onChangeBloco({
      ...bloco,
      possuiCampoDeTextoInicial: false,
      campoDeTextoInicial: "",
      campoDeTextoInicialHTML: ""
    })
  }

  const removerCampoTextoFinal = () => {
    setPossuiCampoDeTextoFinal(false);
    onChangeBloco({
      ...bloco,
      possuiCampoDeTextoFinal: false,
      campoDeTextoFinal: "",
      campoDeTextoFinalHTML: "",
    })
  }

  const swapArrayElements = (arr, indexA, indexB) => {
    var temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
    return arr;
  };

  const moverFilhoParaCima = (f) => {
    if (filhos != null && filhos?.length > 0) {
      let kids = filhos;

      if (kids[0]?.ordem !== f?.ordem) {
        let index = null;
        for (let i = 0; i < kids.length; i++) {
          if (kids[i]?.ordem === f?.ordem) {
            index = i;
            break;
          }
        }
        if (index != null && index !== 0) {
          let temp = kids[index].ordem;
          kids[index].ordem = kids[index - 1].ordem;
          kids[index - 1].ordem = temp;
          kids = swapArrayElements(kids, index, index - 1);
          onChangeBloco({
            ...bloco,
            filhos: kids,
          });
        }
      }
    }
  }

  const moverFilhoParaBaixo = (f) => {
    if (filhos != null && filhos?.length > 0) {
      let kids = filhos;

      if (kids[kids.length - 1]?.ordem !== f?.ordem) {
        let index = null;
        for (let i = 0; i < kids.length; i++) {
          if (kids[i]?.ordem === f?.ordem) {
            index = i;
            break;
          }
        }
        if (index != null && index !== (kids.length - 1)) {
          let temp = kids[index].ordem;
          kids[index].ordem = kids[index + 1].ordem;
          kids[index + 1].ordem = temp;
          kids = swapArrayElements(kids, index, index + 1);
          onChangeBloco({
            ...bloco,
            filhos: kids,
          });
        }
      }
    }
  }

  return (
    <>
      <div className="card">
        <div className='col card-header'>
          <div className="d-flex flex-row">
            <input type="text" className="form-control" onChange={(e) => onChangeBloco({ ...bloco, titulo: e.target.value })} value={bloco.titulo} placeholder="Nome do bloco"></input>
            {
              (possuiCampoDeTextoInicial) ?
              <>
                <Tippy content='Remover campo de texto livre'>
                  <button className='btn col-1 btn-outline-danger mx-2' type='button' onClick={() => {
                      let id = `#textareaCampoDeTextoInicial-${bloco.ordem}`;
                      window.$(id).summernote('destroy');
                      removerCampoTextoInicial();
                    }}><i className='fa fa-trash'></i>&nbsp;Texto</button>
                </Tippy>
                
              </> :
              <>
                <Tippy content='Adicionar campo de texto livre ao bloco'>
                  <button className='btn btn-outline-secondary col-1 mx-2' type='button' onClick={() => setPossuiCampoDeTextoInicial(true)}><i className="fa fa-plus" />&nbsp;Texto</button>
                </Tippy>
              </>
            }
            <Tippy content="Deletar bloco">
              <button className="btn col-1 text-danger" onClick={() => {
                    let id = `#textareaCampoDeTextoInicial-${bloco.ordem}`;
                    window.$(id).summernote('destroy');
                    id = `#textareaCampoDeTextoFinal-${bloco.ordem}`;
                    window.$(id).summernote('destroy');
                    onDeleteBloco(bloco.ordem);
                  }
                } type='button'><span className='fa fa-trash'></span></button>
            </Tippy>
          </div>
          {
            (possuiCampoDeTextoInicial) ?
            <>
              <div className='my-2'>
                {/* <textarea className='form-control' rows={4} value={bloco?.campoDeTextoInicial ?? ""} placeholder='Digite o texto desejado...'
                  onChange={(e) => alterarCampoDeTextoInicial(e.target.value)}></textarea> */}
                  <SummernoteEditor id={`textareaCampoDeTextoInicial-${bloco.ordem}`}
                    placeholder='Digite o texto desejado...'
                    // textSetState={setCampoDeTextoInicial}
                    // codeSetState={setCampoDeTextoInicialHTML}
                    onChange={(contents, $editable) => {
                      // handleChangeCampoDeTextoInicial($editable[0]?.textContent, contents);
                      setCampoDeTextoInicialEInicialHTML({texto: $editable[0]?.textContent, html: contents});
                      // handleChangeCampoDeTexto($editable[0]?.textContent, contents, "inicial");
                    }}
                    initialContent={(bloco.campoDeTextoInicialHTML != null && bloco.campoDeTextoInicialHTML !== "") ? bloco.campoDeTextoInicialHTML : bloco.campoDeTextoInicial}
                    createOnFocus={true}
                    disableHelp={true}
                  />
              </div>
            </> :
            <>
            </>
          }
          
        </div>
        <div className="card-body">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className='text-center col-1'>ID</th>
                <th className='text-center'>Itens de Verificação</th>
                <th className='text-center' style={{width: "5%"}}>
                  <Tippy content="Adicionar novo campo filho">
                    <button type='button' className="btn text-info" onClick={() => adicionarCampoFilho()}>
                      {/* <button className="btn text-info" onClick={() => onChangeBloco({
                      ...bloco,
                      filhos: [
                        ...filhos, {
                          id: nextId++,
                          texto: ""
                        }
                      ]
                    })}> */}
                      <i className="fa fa-plus" />
                    </button>
                  </Tippy>
                </th>
              </tr>
            </thead>
            <tbody>
              {
                filhos.map((filho) => (
                  <CampoFilho key={filho.ordem} filho={filho} 
                    onDeleteFilho={deletarCampoFilho} onChangeFilho={alterarCampoFilho}
                    moveUp={moverFilhoParaCima} moveDown={moverFilhoParaBaixo} 
                  ></CampoFilho>
                ))
              }
            </tbody>
          </table>
          {
            (possuiCampoDeTextoFinal) ?
            <>
              <div className='my-2'>
                {/* <textarea className='form-control' rows={4} value={bloco?.campoDeTextoFinal ?? ""} placeholder='Digite o texto desejado...'
                  onChange={(e) => alterarCampoDeTextoFinal(e.target.value)}></textarea> */}
                  <SummernoteEditor id={`textareaCampoDeTextoFinal-${bloco.ordem}`}
                    placeholder='Digite o texto desejado...'
                    // textSetState={setCampoDeTextoFinal}
                    // codeSetState={setCampoDeTextoFinalHTML}
                    onChange={(contents, $editable) => {
                      // handleChangeCampoDeTextoFinal($editable[0]?.textContent, contents);
                      setCampoDeTextoFinalEFinalHTML({texto: $editable[0]?.textContent, html: contents});
                      // handleChangeCampoDeTexto($editable[0]?.textContent, contents, "final");
                    }}
                    initialContent={(bloco.campoDeTextoFinalHTML != null && bloco.campoDeTextoFinalHTML !== "") ? bloco.campoDeTextoFinalHTML : bloco.campoDeTextoFinal}
                    createOnFocus={true}
                    disableHelp={true}
                  />
                  <Tippy content='Remover campo de texto livre'>
                    <div className='text-center'>
                      <button className='btn btn-outline-danger my-2' type='button' onClick={() => {
                            let id = `#textareaCampoDeTextoFinal-${bloco.ordem}`;
                            window.$(id).summernote('destroy');
                            removerCampoTextoFinal();
                          }
                        }>Remover campo de texto</button>
                    </div>
                </Tippy>
              </div>
            </> :
            <>
              <div className='my-2 text-center'>
                <Tippy content='Adicionar campo de texto livre ao bloco'>
                  <button className='btn btn-outline-secondary my-2' type='button' onClick={() => setPossuiCampoDeTextoFinal(true)}>Adicionar campo de texto</button>
                </Tippy>
              </div>
            </>
          }
        </div>
      </div>
    </>
  )
}
