import Tippy from '@tippyjs/react';
import React from 'react'

export default function CampoNeto(props) {
  const neto = props.neto;
  const onDeleteNeto = props.onDeleteNeto;
  const onChangeNeto = props.onChangeNeto;
  const ordem = neto.ordem;
  const moveUp = props.moveUp;
  const moveDown = props.moveDown;

  const handleIdChange = (e) => {
    onChangeNeto({...neto, idCampo: e.target.value});
  }

  const handleTextoChange = (e) => {
    onChangeNeto({...neto, texto: e.target.value});
  }


  return (
    
      <div className='d-flex flex-row mt-1'>
          <input type="text" className="form-control mr-1 col-1" placeholder="ID" value={neto.idCampo} onChange={handleIdChange}/>
          <textarea rows={1} className="form-control" placeholder="Digite o item da manifestação quanto a análise." value={neto.texto} onChange={handleTextoChange}/>
          <Tippy content="Mover para cima">
            <button className="btn text-info" type='button' onClick={()=>{moveUp(neto)}}><span className='fas fa-chevron-up'></span></button>
          </Tippy>
          <Tippy content="Mover para baixo">
            <button className="btn text-info" type='button' onClick={()=>{moveDown(neto)}}><span className='fas fa-chevron-down'></span></button>
          </Tippy>
          <Tippy content="Deletar">
            <button className="btn text-danger" type='button' onClick={() => {
              if(window.confirm("Deseja excluir o campo?") === true){
                onDeleteNeto(ordem);
              }
            }}><span className='fa fa-trash'></span></button>
          </Tippy>
      </div>
    
  )
}
