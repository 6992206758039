import React, { useEffect } from 'react'

export default function NotFound() {
  useEffect(()=>{
    document.title = 'Página não encontrada - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
  }, [])
  
  return (
    <div className="content-wrapper">
      <div className="m-2 p-2">
        <h1>
            Página não encontrada.
        </h1>
      </div>
    </div>
  )
}
