import Tippy from '@tippyjs/react';
import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export default function NavbarSFC(props) {
  // const analista = false;
  const location = useLocation();
  const navigate = useNavigate();

  const usuario = props.usuario;

  const [searchNumProcesso, setSearchNumProcesso] = useState("");

  const [blocoDeNotasVisivel, setBlocodeNotasVisivel] = useState(false);

  const submitSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();

    navigate("/processo/pesquisar?numero=" + searchNumProcesso);
  }

  // useEffect(()=>{
  //   window.$('[data-toggle="control-sidebar"]').controlSidebar();
  // }, [])

  const blocoDeNotas = (
    <>
      <p>
        Em Construção
      </p>
    </>
  );

  return (
    <div>
        {/* Navbar */}
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          <ul className="navbar-nav d-xl-none">
            <li className="nav-item">
              <button className="nav-link btn border-0" data-widget="pushmenu"><i className="fas fa-bars" /></button>
            </li>
            <li className="nav-item">
              <Link to={"/"} className="nav-link btn border-0">Início</Link>
            </li>
          </ul>
          {/* Left navbar links */}
          <ul className="navbar-nav d-none d-xl-flex">
            <li className="nav-item">
              <button className="nav-link btn border-0" data-widget="pushmenu"><i className="fas fa-bars" /></button>
            </li>
            <li className="nav-item">
              <Link to={"/"} className="nav-link btn border-0">Início</Link>
            </li>

            <li className="nav-item dropdown">
              <button id="dropdownProcessos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle btn border-0">Processos</button>
              <ul aria-labelledby="dropdownProcessos" className="dropdown-menu border-0 shadow">
                <li><Link to={"/processo/novo"} className="dropdown-item">Cadastrar Processo</Link></li>
                <li>
                {
                  location?.pathname === "/processo/pesquisar" ?
                  <Link to={"/processo/pesquisar"} reloadDocument className="dropdown-item">Pesquisar Processo</Link> :
                  <Link to={"/processo/pesquisar"} className="dropdown-item">Pesquisar Processo</Link>
                }
                </li>
              </ul>
            </li>

            {
              ["ADMINISTRADOR", "SCF_ADMINISTRADOR", "AG_ADMINISTRADOR", "SUBGERAL_ADMINISTRADOR", "SCG_ADMINISTRADOR"].includes(usuario.tipoUsuario) ?
              <li className="nav-item dropdown">
                <button id="dropdownUnidades" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle btn border-0">Unidades</button>
                <ul aria-labelledby="dropdownUnidades" className="dropdown-menu border-0 shadow">
                  <li><Link to={"/unidade/cadastro"} className="dropdown-item">Cadastro Unidade</Link></li>
                  <li>
                  {
                    location?.pathname === "/unidade/pesquisar" ?
                    <Link to={"/unidade/pesquisar"} reloadDocument className="dropdown-item">Pesquisar Unidade</Link> :
                    <Link to={"/unidade/pesquisar"} className="dropdown-item">Pesquisar Unidade</Link>

                  }
                  </li>
                </ul>
              </li> :
              <></>
            }

            {
              ["ADMINISTRADOR", "SCF_ADMINISTRADOR", "AG_ADMINISTRADOR", "SUBGERAL_ADMINISTRADOR", "SCG_ADMINISTRADOR"].includes(usuario.tipoUsuario) ?
              <li className="nav-item dropdown">
                <button id="dropdownUsuarios" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle btn border-0">Usuários</button>
                <ul aria-labelledby="dropdownUsuarios" className="dropdown-menu border-0 shadow">
                  <li><Link to={"/usuario/cadastro"} className="dropdown-item">Cadastro Usuário</Link></li>

                  <li>
                  {
                    location?.pathname === "/usuario/pesquisar" ?
                    <Link to={"/usuario/pesquisar"} reloadDocument className="dropdown-item">Pesquisar Usuário</Link> :
                    <Link to={"/usuario/pesquisar"} className="dropdown-item">Pesquisar Usuário</Link>

                  }
                  </li>
                </ul>
              </li> :
              <></>
            }

            {
              usuario.tipoUsuario === "ADMINISTRADOR" || usuario.tipoUsuario === "SCF_ADMINISTRADOR" ?
              <li className="nav-item dropdown">
                <button id="dropdownModelos" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle btn border-0">Modelos de Análise</button>
                <ul aria-labelledby="dropdownModelos" className="dropdown-menu border-0 shadow">
                  <li><Link to={"/analise/modelo/novo"} className="dropdown-item">Cadastro Modelo de Análise</Link></li>
                  <li>
                    {
                      location?.pathname === "/analise/modelo/pesquisar" ?
                      <Link to={"/analise/modelo/pesquisar"} reloadDocument className="dropdown-item">Pesquisar Modelo de Análise</Link> :
                      <Link to={"/analise/modelo/pesquisar"} className="dropdown-item">Pesquisar Modelo de Análise</Link>

                    }
                  </li>
                </ul>
              </li> :
              <></>
            }

            <li className="nav-item">
              <Link to="/ajuda" className="nav-link">Ajuda</Link>
            </li>
            <li className="nav-item">
              <Link to="/contatos" className="nav-link">Contatos</Link>
            </li>
          </ul>
          {/* Right navbar links */}
          <ul className="navbar-nav ml-auto d-none d-xl-flex">
            <li className="nav-item dropdown">
              <button id="dropdownMenuUsuario" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle btn border-0">{usuario.login}</button>
              <ul aria-labelledby="dropdownMenuUsuario" className="dropdown-menu border-0 shadow">
                <li className="nav-item"><Link to="/usuario/alterarsenha" className="nav-link">Alterar Senha</Link></li>
                <li className="nav-item"><Link to={"/logout"} className="nav-link">Sair</Link></li>
              </ul>
            </li>
            {/* Navbar Search */}
            <li className="nav-item navbar-search">
              <form className="form-inline ml-0 ml-md-3" onSubmit={submitSearch}>
                <div className="input-group input-group-sm">
                  <input className="form-control form-control-navbar" type="search" placeholder="Número do Processo" aria-label="Search" value={searchNumProcesso} onChange={(e) => setSearchNumProcesso(e.target.value)}/>
                  <div className="input-group-append">
                    <button className="btn btn-navbar" type="submit">
                      <i className="fas fa-search" />
                    </button>
                  </div>
                </div>
              </form>
            </li>
            <li className="nav-item">
              <button className="nav-link btn border-0" data-widget="fullscreen" type="button">
                <i className="fas fa-expand-arrows-alt" />
              </button>
            </li>
            {/* <li className='nav-item'>
              <Tippy interactive={true} maxWidth={350} content={blocoDeNotas} >
                <button className="nav-link btn border-0" type="button">
                  <i className="fas fa-clipboard" />
                </button>
              </Tippy>
            </li> */}
            <li className="nav-item">
              <button className="nav-link btn border-0" data-widget="control-sidebar" data-slide="true" type="button">
                <i className="fas fa-th-large" />
              </button>
            </li>
          </ul>
          {/* Right navbar links */}
          <ul className="navbar-nav ml-auto d-xl-none">
            <li className="nav-item dropdown">
              <button id="dropdownMenuUsuario" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle btn border-0">{usuario.login}</button>
              <ul aria-labelledby="dropdownMenuUsuario" className="dropdown-menu border-0 shadow">
                <li className="nav-item"><Link to="/usuario/alterarsenha" className="nav-link">Alterar Senha</Link></li>
                <li className="nav-item"><Link to={"/logout"} className="nav-link">Sair</Link></li>
              </ul>
            </li>
            <li className="nav-item">
              <button className="nav-link btn border-0" data-widget="fullscreen" type="button">
                <i className="fas fa-expand-arrows-alt" />
              </button>
            </li>
            <li className='nav-item'>
              <button className="nav-link btn border-0" type="button">
                <i className="fas fa-clipboard" />
              </button>
            </li>
            <li className="nav-item">
              <button className="nav-link btn border-0" data-widget="control-sidebar" data-slide="true" type="button">
                <i className="fas fa-th-large" />
              </button>
            </li>
          </ul>
        </nav>
        {/* /.navbar */}
    </div>
  )
}
