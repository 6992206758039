import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Autenticar from "../../login/Autenticar";
import Requisicao from "../../helpers/Requisicao";
import EnumUtils from "../../helpers/EnumUtils";
import Utilidades from "../../helpers/Utilidades";
import Tippy from "@tippyjs/react";

export default function CadastroUsuario(props) {

  const usuario = props.usuario;

  const [unidadeLista, setUnidadeLista] = useState([]);
  const navigate = useNavigate();

  const [listaPermissoes, setListaPermissoes] = useState([]);
  const [permissao, setPermissao] = useState("");

  const [permissoesDoUsuario, setPermissoesDoUsuario] = useState([]);

  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [nome, setNome] = useState("");
  const [matricula, setMatricula] = useState("");
  const [cpf, setCPF] = useState("");
  const [email, setEmail] = useState("");
  const [isEstagiario, setIsEstagiario] = useState(false);
  const [tipoUsuario, setTipoUsuario] = useState("-1");
  const [statusUsuario, setStatusUsuario] = useState("ATIVO");
  const [unidadeId, setUnidadeId] = useState(null);
  const [justificativa, setJustificativa] = useState("");
  // const [triedSubmit, setTriedSubmit] = useState(false);
  const [elmtJustificativa, setElmtJustificativa] = useState(<></>);

  const [setores, setSetores] = useState([]);
  const [setorUsuario, setSetorUsuario] = useState("-1");

  const [listaTiposUsuario, setListaTiposUsuario] = useState([]);


  const carregarTiposUsuarios = () => {
    if(usuario != null && usuario.setor != null){
      if(usuario.tipoUsuario === "ADMINISTRADOR"){
        Requisicao.getListaTiposUsuario().then((data) => {
          if(data != null) {
            setListaTiposUsuario(data);
          }
        }).catch(erro => console.log('erro', erro));
      } else {
        Requisicao.getListaTiposUsuarioPorSetor(usuario.setor).then((data) => {
          if(data != null){
            setListaTiposUsuario(data);
          }
        }).catch(erro => console.log('erro', erro));
      }
    }
  }

  const carregarSetores = () => {
    Requisicao.getListaSetor().then((data) => {
      setSetores(Utilidades.sortEnumPorNome(data));
    }).catch(erro => console.log('erro', erro));
  }

  const carregarListaPermissoes = () => {
    Requisicao.getListaTipoPermissao()
      .then(data => {
        if(usuario.tipoUsuario === "ADMINISTRADOR"){
          setListaPermissoes(data);
        } else {
          let permissoesDoSetor = data.filter(a => a.setor === usuario?.setor);
          setListaPermissoes(permissoesDoSetor);
        }
      })
      .catch(erro => console.log('erro', erro));
  }

  const adicionarPermissao = () => {
    if(permissao != null && permissao !== "" && !permissoesDoUsuario.includes(permissao)){
      if(listaPermissoes != null && listaPermissoes.length > 0){
        for (let i = 0; i < listaPermissoes.length; i++) {
          if(permissao === listaPermissoes[i].value){
            // let arr = permissoesDoUsuario;
            // arr.push(permissao);
            // console.log('arr', arr);
            setPermissoesDoUsuario([
              ...permissoesDoUsuario,
              permissao
            ]);
            break;
          }
        }
      }
    }
  }

  const removerPermissao = (p) => {
    if(p != null && permissoesDoUsuario.includes(p)){
      setPermissoesDoUsuario(permissoesDoUsuario.filter((per) => per !== p));
    }
  }

  const handleLoginChange = (e) => {
    let maxLength = 150;
    if(e.target.value.length <= maxLength){
      setLogin(e.target.value);
    } else {
      setLogin(e.target.value.substr(0,maxLength));
    }

    if(e.target.value !== ""){
      e.target.classList.remove("is-invalid");
      e.target.classList.add("is-valid");
    } else {
      e.target.classList.remove("is-valid");
      e.target.classList.add("is-invalid");
    }
  }

  const handleSenhaChange = (e) => {
    let maxLength = 150;
    if(e.target.value.length <= maxLength){
      setSenha(e.target.value);
    } else {
      setSenha(e.target.value.substr(0,maxLength));
    }

    if(e.target.value !== ""){
      e.target.classList.remove("is-invalid");
      e.target.classList.add("is-valid");
    } else {
      e.target.classList.remove("is-valid");
      e.target.classList.add("is-invalid");
    }
  }

  const handleConfirmarSenhaChange = (e) => {
    let maxLength = 150;
    if(e.target.value.length <= maxLength){
      setConfirmarSenha(e.target.value);
    } else {
      setConfirmarSenha(e.target.value.substr(0,maxLength));
    }

    if(e.target.value !== "" && e.target.value === senha){
      e.target.classList.remove("is-invalid");
      e.target.classList.add("is-valid");
    } else {
      e.target.classList.remove("is-valid");
      e.target.classList.add("is-invalid");
    }
  }

  const handleNomeChange = (e) => {
    let maxLength = 150;
    if(e.target.value.length <= maxLength){
      setNome(e.target.value);
    } else {
      setNome(e.target.value.substr(0,maxLength));
    }

    if(e.target.value !== ""){
      e.target.classList.remove("is-invalid");
      e.target.classList.add("is-valid");
    } else {
      e.target.classList.remove("is-valid");
      e.target.classList.add("is-invalid");
    }
  }

  const handleMatriculaChange = (e) => {
    // onWheel={(e) => e.target.blur()}
    let maxLength = 7;
    let value = e.target.value.replace(/[^\d]/g,'');

    if(e.target.value.length <= maxLength){
      setMatricula(value);
    } else {
      setMatricula(value.substr(0,maxLength));
    }

    if(e.target.value !== ""){
      e.target.classList.remove("is-invalid");
      e.target.classList.add("is-valid");
    } else {
      e.target.classList.remove("is-valid");
      e.target.classList.add("is-invalid");
    }
  }

  const validateCPF = (cpf) => {	
    let rev;
    cpf = cpf.replace(/[^\d]+/g,'');	
    if(cpf === '') return false;	
    // Elimina CPFs invalidos conhecidos	
    if (cpf.length !== 11 || 
      cpf === "00000000000" || 
      cpf === "11111111111" || 
      cpf === "22222222222" || 
      cpf === "33333333333" || 
      cpf === "44444444444" || 
      cpf === "55555555555" || 
      cpf === "66666666666" || 
      cpf === "77777777777" || 
      cpf === "88888888888" || 
      cpf === "99999999999")
        return false;		
    // Valida 1o digito	
    let add = 0;	
    for (let i=0; i < 9; i ++)		
      add += parseInt(cpf.charAt(i)) * (10 - i);	
      rev = 11 - (add % 11);	
      if (rev === 10 || rev === 11)		
        rev = 0;	
      if (rev !== parseInt(cpf.charAt(9)))		
        return false;		
    // Valida 2o digito	
    add = 0;	
    for (let i = 0; i < 10; i ++)		
      add += parseInt(cpf.charAt(i)) * (11 - i);	
    rev = 11 - (add % 11);	
    if ( rev === 10 || rev === 11)	
      rev = 0;	
    if (rev !== parseInt(cpf.charAt(10)))
      return false;		
    return true;   
  }

  const handleCPFChange = (e) => {
    let maxLength = 11;
    let value = e.target.value.replace(/[^\d]/g,'');

    if(e.target.value.length <= maxLength){
      setCPF(value);
    } else {
      setCPF(value.substr(0,maxLength));
    }

    if(e.target.value !== "" && validateCPF(value.substr(0,maxLength))){
      e.target.classList.remove("is-invalid");
      e.target.classList.add("is-valid");
    } else {
      e.target.classList.remove("is-valid");
      e.target.classList.add("is-invalid");
    }
  }

  const validateEmail = (email) => {
    let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailChange = (e) => {
    let maxLength = 150;
    if(e.target.value.length <= maxLength){
      setEmail(e.target.value);
    } else {
      setEmail(e.target.value.substr(0,maxLength));
    }

    if((e.target.value !== "") && validateEmail(e.target.value.substr(0,maxLength))){
      e.target.classList.remove("is-invalid");
      e.target.classList.add("is-valid");
    } else {
      e.target.classList.remove("is-valid");
      e.target.classList.add("is-invalid");
    }
  }

  // handleJustificativaChange validation
  useEffect(()=>{
    if((statusUsuario !== "ATIVO") && (justificativa === "")){
      elmtJustificativa.classList?.remove("is-valid");
      elmtJustificativa.classList?.add("is-invalid");
    } else {
      elmtJustificativa.classList?.remove("is-invalid");
      elmtJustificativa.classList?.add("is-valid");
    }
  }, [justificativa, statusUsuario, elmtJustificativa])

  const handleJustificativaChange = (e) => {
    setJustificativa(e.target.value);
  }

  // handleUnidadeChange validation
  useEffect(()=>{
    let elmt = document.querySelector('#comboUnidades');
    if(unidadeId != null){
      if(unidadeId !== "-1"){
        elmt.classList.remove("is-invalid");
        elmt.classList.add("is-valid");
      } else {
        elmt.classList.remove("is-valid");
        elmt.classList.add("is-invalid");
      }
    }
  },[unidadeId]);

  // handleSetorChange validation
  useEffect(()=>{
    let elmt = document.querySelector('#comboboxSetores');
    if(setorUsuario != null){
      if(setorUsuario !== "-1"){
        elmt.classList.remove("is-invalid");
        elmt.classList.add("is-valid");
      } else {
        elmt.classList.remove("is-valid");
        elmt.classList.add("is-invalid");
      }
    }
  },[setorUsuario]);

  // componentDidMount
  useEffect(()=>{
    if (usuario == null) {
      navigate("/login", { replace:true });
    }
    if(!["ADMINISTRADOR", "SCF_ADMINISTRADOR", "AG_ADMINISTRADOR", "SUBGERAL_ADMINISTRADOR", "SCG_ADMINISTRADOR"].includes(usuario.tipoUsuario)){
      navigate("/acessonegado", { replace:true });
    }
  }, [usuario, navigate]);

  // componentDidMount
  useEffect(() => {
    // window.$('.select2').select2();
    window.$(".select2bs4").select2({
      theme: "bootstrap4",
    });
    window.$('[data-toggle="switch"]').bootstrapSwitch();

    window.$('#checkboxHabilitarUsuario').on('switchChange.bootstrapSwitch', function(event) {
      if(event.target.checked) {
        setStatusUsuario("ATIVO");
      } else{
        setStatusUsuario("INATIVO");
      }
    });

    window.$('#checkboxEstagiario').on('switchChange.bootstrapSwitch', function(event) {
        setIsEstagiario(event.target.checked);
    });
    window.$("#checkboxEstagiario").bootstrapSwitch("state", false);

    window.$('#comboboxTipoUsuario').on('change', function(e) {
      setTipoUsuario(e.target.value);
    });
    // window.$('#comboboxTipoUsuario').val("-1").trigger("change");

    window.$('#comboUnidades').on('change', function(e) {
      setUnidadeId(e.target.value);
    });
    // window.$('#comboUnidades').val(-1).trigger("change");

    setElmtJustificativa(document.querySelector("#inputJustificativa"));

    window.$("#comboboxPermissao").on('change.select2', function (e) {
      setPermissao(e.target.value);
    });

    window.$("#comboboxSetores").on('change', function(e) {
      setSetorUsuario(e.target.value);
      window.$('#comboUnidades').val("-1").trigger("change");
    });

    carregarListaPermissoes();
    carregarUnidadesLista();
    carregarSetores();
    carregarTiposUsuarios();

    document.title = 'Cadastrar Novo Usuário - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
    // eslint-disable-next-line
  }, []);

  const limparInterface = () => {
    setLogin("");
    setSenha("");
    setConfirmarSenha("");
    setNome("");
    setMatricula("");
    setCPF("");
    setEmail("");
    setTipoUsuario("ADMINISTRADOR");
    setStatusUsuario("ATIVO");
    setUnidadeId(null);
    setJustificativa("");
    window.$('#checkboxHabilitarUsuario').bootstrapSwitch('state' , true);
    window.$('#checkboxEstagiario').bootstrapSwitch('state' , false);

    window.$('#comboUnidades').val("-1").trigger("change");
    window.$('#comboboxPermissao').val("-1").trigger("change");
    window.$('#comboboxSetores').val("-1").trigger("change");
    window.$('#comboboxTipoUsuario').val("-1").trigger("change");

  }

  const carregarUnidadesLista = () => {
    if(usuario?.tipoUsuario === "ADMINISTRADOR"){
      Requisicao.getListaUnidades()
        .then((data) => {
          setUnidadeLista(data);
        })
        .catch((erro) => console.log(erro));
    } else {
      Requisicao.getListaUnidadesPorSetor(usuario?.setor)
      .then((data) => {
        setUnidadeLista(data);
      })
      .catch((erro) => console.log(erro));
    }
  };

  // preencherComboUnidades
  useEffect(()=>{
    window.$("#comboUnidades").empty();
    let combobox = document.querySelector("#comboUnidades").options;

    combobox.add(new Option("Selecione a Unidade", "-1"));
    unidadeLista.forEach((element) => {
      if(element.setor === setorUsuario){
        combobox.add(new Option(element.nome, element.id));
      }
    });

    combobox[0].disabled = true;
    combobox.selectedIndex = 0;
    // console.log(combobox);
  }, [unidadeLista, setorUsuario]);

  // preencher comboboxPermissao
  useEffect(() => {
    window.$("#comboboxPermissao").empty();
    let combobox = document.querySelector("#comboboxPermissao").options;

    combobox.add(new Option("Selecione a permissão desejada", "-1"));
    listaPermissoes?.forEach((element) => {
      if(element.setor === setorUsuario){
        combobox.add(new Option(element.nome, element.value));
      }
    });

    combobox[0].disabled = true;

    combobox.selectedIndex = 0;
  }, [listaPermissoes, setorUsuario]);

  // preencher comboboxSetores
  useEffect(() => {
    window.$("#comboboxSetores").empty();
    let combobox = document.querySelector("#comboboxSetores").options;

    combobox.add(new Option("Selecione o Setor do Usuário...", "-1"));
    combobox[0].disabled = true;
    combobox.selectedIndex = 0;

    if(usuario.tipoUsuario === "ADMINISTRADOR"){
      setores.forEach((element) => {
        combobox.add(new Option(element.nome, element.value));
      });
    } else {
      let tiposUsuarioPermitidos = ["ADMINISTRADOR", "SCF_ADMINISTRADOR", "AG_ADMINISTRADOR", "SUBGERAL_ADMINISTRADOR", "SCG_ADMINISTRADOR"];
      if(tiposUsuarioPermitidos.includes(usuario.tipoUsuario)){
        // combobox.selectedIndex = 0;
        setores.forEach((element, index) => {
          if(element.value === usuario.setor){
            combobox.add(new Option(element.nome, element.value));
            // console.log('index', index);
            // combobox.selectedIndex = index;
            window.$("#comboboxSetores").val(element.value).trigger("change");
          }
        });
      }
    }
  }, [setores, usuario.tipoUsuario, usuario.setor]);

  // // preencher comboboxTipoUsuario
  // useEffect(() => {
  //   window.$("#comboboxTipoUsuario").empty();
  //   let combobox = document.querySelector("#comboboxTipoUsuario").options;

  //   combobox.add(new Option("Selecione o Perfil do Usuário...", "-1"));
  //   listaTiposUsuario.forEach((element) => {
  //     combobox.add(new Option(element.nome, element.value));
  //   });

  //   combobox[0].disabled = true;
  //   combobox.selectedIndex = 0;

  // }, [listaTiposUsuario]);

  // preencher comboboxTipoUsuario
  useEffect(() => {
    window.$("#comboboxTipoUsuario").empty();
    let combobox = document.querySelector("#comboboxTipoUsuario").options;

    combobox.add(new Option("Selecione o Perfil do Usuário...", "-1"));
    combobox[0].disabled = true;
    combobox.selectedIndex = 0;

    listaTiposUsuario.forEach((element, index) => {
      if(element.setor === setorUsuario){
        combobox.add(new Option(element.nome, element.value));
      }
    });

    for (let i = 0; i < combobox.length; i++) {
      if(combobox[i].value === tipoUsuario){
        combobox.selectedIndex = i;
      }
    }

  }, [listaTiposUsuario, tipoUsuario, setorUsuario]);

  useEffect(() => {
    const tiposUsuarioListaSetoresLiberada = ["ADMINISTRADOR"];
    const comboboxSetores = document.getElementById("comboboxSetores");

    if(tiposUsuarioListaSetoresLiberada.includes(usuario?.tipoUsuario)){
      // console.log(true, props.usuario?.tipoUsuario);
      comboboxSetores.disabled = false;
    } else {
      // console.log(false, props.usuario?.tipoUsuario);
      comboboxSetores.disabled = true;
    }
  }, [usuario?.tipoUsuario]);


  const validateForm = () => {
    let valido = true;
    if(!(login.length <= 150 && login !== "")){
      document.querySelector("#inputLogin").classList.remove("is-valid");
      document.querySelector("#inputLogin").classList.add("is-invalid");
      valido = false;
    }
    if(!(senha.length <= 150 && senha !== "")){
      document.querySelector("#inputSenha").classList.remove("is-valid");
      document.querySelector("#inputSenha").classList.add("is-invalid");
      valido = false;
    }
    if(!(confirmarSenha.length <= 150 && confirmarSenha !== "" && confirmarSenha === senha)){
      document.querySelector("#inputConfirmarSenha").classList.remove("is-valid");
      document.querySelector("#inputConfirmarSenha").classList.add("is-invalid");
      valido = false;
    }
    if(!(nome.length <= 150 && nome !== "")){
      document.querySelector("#inputNome").classList.remove("is-valid");
      document.querySelector("#inputNome").classList.add("is-invalid");
      valido = false;
    }
    if(!(matricula.length <= 7 && matricula !== "")){
      document.querySelector("#inputMatricula").classList.remove("is-valid");
      document.querySelector("#inputMatricula").classList.add("is-invalid");
      valido = false;
    }
    if(!(validateCPF(cpf))){
      document.querySelector("#inputCPF").classList.remove("is-valid");
      document.querySelector("#inputCPF").classList.add("is-invalid");
      valido = false;
    }
    if(!(email !== "" && validateEmail(email))){
      document.querySelector("#inputEmail").classList.remove("is-valid");
      document.querySelector("#inputEmail").classList.add("is-invalid");
      valido = false;
    }
    if(!((statusUsuario === "ATIVO" || statusUsuario === "INATIVO"))){
      valido = false;
    }
    // if(!((
    //   tipoUsuario === "ADMINISTRADOR" ||
    //   tipoUsuario === "SCF_ADMINISTRADOR" ||
    //   tipoUsuario === "SCF_ANALISTA_UDCI" ||
    //   tipoUsuario === "SCF_REVISOR_UDCI" ||
    //   tipoUsuario === "SCF_ANALISTA_UC" ||
    //   tipoUsuario === "SCF_REVISOR_UC"
    // ))){
    //   valido = false;
    // }
    let arrTipos = [];
    listaTiposUsuario?.forEach(t => arrTipos.push(t.value));
    if(!(arrTipos.includes(tipoUsuario))){
      valido = false;
    }
    if(!(setorUsuario !== "-1" && setorUsuario != null)){
      document.querySelector("#comboboxSetores").classList.remove("is-valid");
      document.querySelector("#comboboxSetores").classList.add("is-invalid");
      valido = false;
    }
    let unidadeEncontrada = false;
    // console.log('unidadeLista.length', unidadeLista.length);
    unidadeLista.forEach((u) => {
      // console.log("unidade: " + unidade + ", u.id: " + u?.id + ", =? " + (unidade == u?.id.toString()));
      // console.log('typeof unidade', typeof unidade);
      // console.log('typeof u.id', typeof u.id);
      if(unidadeId === u?.id.toString()){
        unidadeEncontrada = true;
      }
    });
    if(!unidadeEncontrada){
      valido = unidadeEncontrada;
      document.querySelector("#comboUnidades").classList.remove("is-valid");
      document.querySelector("#comboUnidades").classList.add("is-invalid");
    }
    if(statusUsuario !== "ATIVO" && justificativa === ""){
      document.querySelector("#inputJustificativa").classList.remove("is-valid");
      document.querySelector("#inputJustificativa").classList.add("is-invalid");
      valido = false;
    }
    // console.log('valido', valido)
    return valido;
  }

  const gravar = () => {
    const dados = {
      "login": login,
      "senha": senha,
      "nome": nome,
      "matricula": matricula,
      "cpf": cpf,
      "email": email,
      "isEstagiario": isEstagiario,
      "tipoUsuario": tipoUsuario,
      "setor": setorUsuario,
      "statusUsuario": statusUsuario,
      "unidadeId": unidadeId,
      "justificativa": justificativa,
      "permissoes": permissoesDoUsuario,
    };
    
    const token = Autenticar.getLocalToken();
            
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(dados)
    };

    const url = window.servidor + "/v1/protegido/usuario/cadastrar"

    fetch(url, requestOptions)
        .then(response => {
          // console.log('response', response)
          // console.log('text', response.text());
          response.text().then(text => alert(text));
          limparInterface();
        })
        // .then(() =>{
        //     console.log('Gravado');
        //     alert("Usuario cadastrado com sucesso.");
        //     limparInterface();
        // })
        .catch(erro => console.log(erro));
  }

  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // setTriedSubmit(true);
    // console.log(validateForm());

    if(validateForm()){
      gravar();
    }

    // let forms = document.getElementsByClassName('needs-validation');
    // //   // Loop over them and prevent submission
    // let validation = Array.prototype.filter.call(forms, function(form) {
    //   // form.addEventListener('submit', function(event) {
    //   //   if (form.checkValidity() === false) {
    //   //     event.preventDefault();
    //   //     event.stopPropagation();
    //   //   }
    //   //   form.classList.add('was-validated');
    //   // }, false);
    //   // if (form.checkValidity() === true) {
    //   //   form.classList.add('was-validated');
    //   // }
    //   // if (form.checkValidity() === false) {
    //   //   e.preventDefault();
    //   //   e.stopPropagation();
    //   // }
    //   form.classList.add('was-validated');
    // });
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Cadastro de Usuário</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Cadastro de Usuário
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <form className="needs-validation" onSubmit={submitForm} noValidate>
            <div className="container-fluid">
              <div className="col">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Setor e Unidade do Usuário</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                        <label htmlFor="comboboxSetores">Setor</label>
                        <select id="comboboxSetores" className="select2bs4 precisa-validar" style={{ width: '100%' }} aria-describedby="comboboxSetoresFeedback">
                        </select>
                        <div
                            id="comboboxSetoresFeedback"
                            className="invalid-feedback"
                          >
                          Selecione o setor ao qual o usuário pertence.
                        </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="comboUnidades">Unidade do Usuário</label>
                      <select
                        id="comboUnidades"
                        className="select2bs4 precisa-validar"
                        style={{ width: "100%" }}
                        aria-describedby="comboUnidadesFeedback"
                      ></select>
                      <div
                        id="comboUnidadesFeedback"
                        className="invalid-feedback"
                      >
                        Selecione a unidade do usuário.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col d-flex">
                <div className="col-md-6 mx-0 pl-0">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">Cadastro Usuário</h3>
                    </div>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="inputNome">Nome do Usuário</label>
                        <input
                          type="text"
                          className="form-control precisa-validar"
                          id="inputNome"
                          aria-describedby="inputNomeFeedback"
                          value={nome}
                          onChange={handleNomeChange}
                          placeholder="Digite o Nome Completo do Usuário sem abreviações"
                          required
                        />
                        <div
                          id="inputNomeFeedback"
                          className="invalid-feedback"
                        >
                          Digite o nome do usuário.
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputMatricula">Matrícula do Usuário</label>
                        <input
                          type="text"
                          maxLength={7}
                          className="form-control precisa-validar"
                          id="inputMatricula"
                          aria-describedby="inputMatriculaFeedback"
                          value={matricula}
                          onChange={handleMatriculaChange}
                          placeholder="Digite a Matricula do Usuário sem pontos ou caracteres"
                          required
                        />
                        <div
                          id="inputMatriculaFeedback"
                          className="invalid-feedback"
                        >
                          Digite a matrícula do usuário. Apenas números.
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputEmail">E-mail</label>
                        <input
                          type="email"
                          className="form-control precisa-validar"
                          id="inputEmail"
                          aria-describedby="inputEmailFeedback"
                          value={email}
                          onChange={handleEmailChange}
                          placeholder="Digite o e-mail do Usuário"
                          required
                        />
                        <div
                          id="inputEmailFeedback"
                          className="invalid-feedback"
                        >
                          Digite o email do usuário.
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputCPF">CPF</label>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          className="form-control precisa-validar"
                          id="inputCPF"
                          aria-describedby="inputCPFFeedback"
                          value={cpf}
                          onChange={handleCPFChange}
                          placeholder="Digite o CPF do Usuário. Apenas números."
                          required
                        />
                        <div
                          id="inputCPFFeedback"
                          className="invalid-feedback"
                        >
                          Digite o CPF do usuário. Apenas números.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mx-0 pr-0">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">Login</h3>
                    </div>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="inputLogin">Login do Usuário</label>
                        <input
                          type="text"
                          className="form-control precisa-validar"
                          id="inputLogin"
                          aria-describedby="inputLoginFeedback"
                          value={login}
                          onChange={handleLoginChange}
                          placeholder="Digite o Login do Usuário"
                          required
                        />
                        <div
                          id="inputLoginFeedback"
                          className="invalid-feedback"
                        >
                          Digite o login do usuário.
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputSenha">Senha do Usuário</label>
                        <input
                          type="password"
                          className="form-control precisa-validar"
                          id="inputSenha"
                          aria-describedby="inputSenhaFeedback"
                          value={senha}
                          onChange={handleSenhaChange}
                          placeholder="Digite a senha do Usuário mínimo 4 caracteres"
                          required
                        />
                        <div
                          id="inputSenhaFeedback"
                          className="invalid-feedback"
                        >
                          Digite a senha do usuário.
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputConfirmarSenha">
                          Confirmar Senha do Usuário
                        </label>
                        <input
                          type="password"
                          className="form-control precisa-validar"
                          id="inputConfirmarSenha"
                          aria-describedby="inputConfirmarSenhaFeedback"
                          value={confirmarSenha}
                          onChange={handleConfirmarSenhaChange}
                          placeholder="Digite novamente a senha do Usuário"
                          required
                        />
                        <div
                          id="inputConfirmarSenhaFeedback"
                          className="invalid-feedback"
                        >
                          Repita a senha do usuário.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Configurações</h3>
                  </div>
                  <div className="card-body">
                    <div className="row py-0 mb-0">
                      <div className="form-group col-4 text-center">
                        <label>Perfil do Usuário:</label>
                        <select
                          id="comboboxTipoUsuario"
                          className="form-control select2bs4"
                          style={{ width: "100%" }}
                        >
                        </select>
                      </div>
                      <div className="form-group col-4 text-center">
                        <label>Estagiário:</label>
                        <div className="form-group">
                          <input
                          id="checkboxEstagiario"
                            type="checkbox"
                            data-toggle="switch"
                            data-size="small"
                            data-on-color="success"
                            data-on-text="SIM"
                            data-off-color="danger"
                            data-off-text="NÃO"
                            data-handle-width={10}
                            // defaultChecked
                          />
                        </div>
                      </div>
                      <div className="form-group col-4 text-center">
                        <label>Habilitar Usuário:</label>
                        <div className="form-group">
                          <input
                          id="checkboxHabilitarUsuario"
                            type="checkbox"
                            data-toggle="switch"
                            data-size="small"
                            data-on-color="success"
                            data-on-text="SIM"
                            data-off-color="danger"
                            data-off-text="NÃO"
                            data-handle-width={10}
                            defaultChecked
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row pt-2 pb-0 mb-0">
                      <div className="form-group col-6 text-center">
                        <label htmlFor="comboboxPermissao">Adicionar Permissões:</label>
                        <div className="row">
                          <div className="col-11">
                            <select
                              id="comboboxPermissao"
                              className="form-control select2bs4"
                              style={{ width: "100%" }}
                            >
                            </select>
                          </div>
                          <div className="col-1">
                            <Tippy content='Adicionar Permissão'>
                              <button type="button" className="btn btn-outline-primary" onClick={() => {adicionarPermissao()}}><i className="fas fa-chevron-right" /></button>
                            </Tippy>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-6 text-center">
                        <label>Permissões adicionadas:</label>
                        <div className="form-group">
                          {
                            permissoesDoUsuario && permissoesDoUsuario.map((p, index) => (
                              <div key={index} className="d-flex flex-row justify-content-center">
                                <div className="">
                                  <p className="text-bold m-0 pt-2">{EnumUtils.getNomeEnum(p, listaPermissoes)}</p>
                                </div>
                                <button type="button" className="btn col-1 text-danger" onClick={() => {removerPermissao(p)}}><i className='fa fa-trash' /></button>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Justificativa</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="inputJustificativa">Justificativa</label>
                      <textarea
                        id="inputJustificativa"
                        className="form-control precisa-validar"
                        rows={4}
                        aria-describedby="inputJustificativaFeedback"
                        placeholder=""
                        value={justificativa}
                        onChange={handleJustificativaChange}
                      />
                      <div
                        id="inputJustificativaFeedback"
                        className="invalid-feedback"
                      >
                        Insira a justificativa. Em caso de Desabilitar login, informar o motivo.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end px-3 pb-3">
              <button type="button" className="btn btn-primary mr-2" onClick={limparInterface}>
                Limpar
              </button>
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </form>
        </section>
      </div>
    </>
  );
}
