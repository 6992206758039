import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Requisicao from '../../helpers/Requisicao';
import Utilidades from '../../helpers/Utilidades';
import { isValid, parseISO } from 'date-fns';
import Tippy from '@tippyjs/react';
import PublicacaoAviso from './PublicacaoAviso';
import Autenticar from '../../login/Autenticar';

// let ordemPublicacao = 1;

export default function EditarAviso({ usuario, autenticado }) {
  const { avisoId } = useParams();

  const [loading, setLoading] = useState(false);

  const [salvando, setSalvando] = useState(false);

  const [listaProcessos, setListaProcessos] = useState(null);
  // const [processo, setProcesso] = useState(null);
  
  const [aviso, setAviso] = useState({});
  const [publicacoes, setPublicacoes] = useState([]);
  
  const [listaOrgaos, setListaOrgaos] = useState([]);
  const [listaTipoAvisoLicitacao, setListaAvisoLicitacao] = useState([]);
  const [listaLeiLicitacao, setListaLeiLicitacao] = useState([]);
  const [listaModalidadeLicitacao, setListaModalidadeLicitacao] = useState([]);
  const [listaCriterioJulgamento, setListaCriterioJulgamento] = useState([]);
  const [listaTipoAjudicacao, setListaTipoAjudicacao] = useState([]);
  const [listaRegimeExecucao, setListaRegimeExecucao] = useState([]);
  
  
  const [numeroProcesso, setNumeroProcesso] = useState("");
  const [orgaoSolicitante, setOrgaoSolicitante] = useState("-1");
  const [objetoProcesso, setObjetoProcesso] = useState("");
  const [valorProcesso, setValorProcesso] = useState(0);
  const [numeroLicitacao, setNumeroLicitacao] = useState("");
  const [valorPortalTransparencia, setValorPortalTransparencia] = useState(0);
  const [objetoPortalTransparencia, setObjetoPortalTransparencia] = useState("");
  const [observacoes, setObservacoes] = useState("");

  // const [ordemPublicacao, setOrdemPublicacao] = useState(1);
  
  // componentDidMount
  useEffect(() => {
    if (autenticado) {
      carregarListas();

      if (!isNaN(Number(avisoId))) {
        carregarAviso(avisoId);
      }

      window.$(".select2bs4").select2({
        theme: "bootstrap4",
      });
      window.$("#comboboxOrgaos").on('change', function (e) {
        setOrgaoSolicitante(e.target.value);
      });

    }

    document.title = 'Aviso de Licitação - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
    // eslint-disable-next-line
  }, [autenticado, avisoId]);

  useEffect(() => {
    document.getElementById('inputNumeroProcesso')?.classList.remove('is-valid');
    document.getElementById('inputNumeroProcesso')?.classList.remove('is-invalid');
    if (numeroProcesso != null && numeroProcesso !== "") {
      const timeOutId = setTimeout(() => fetchProcessoDebounced(numeroProcesso), 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [numeroProcesso]);

  const fetchProcessoDebounced = (numero) => {
    Requisicao.pesqusiarProcessoDadosBasicosPorNumero(numero).then((data) => setListaProcessos(data)).catch(erro => console.log('erro', erro));
  }

  // datalist processo
  useEffect(() => {
    let list = document.getElementById('list_processos');
    list.innerHTML = '';

    listaProcessos?.forEach((element) => {
      let option = document.createElement('option');
      option.value = element.numero;
      list.appendChild(option);
    })

  }, [listaProcessos]);

  const carregarProcesso = (numero) => {
    if (numero != null && numero !== "") {
      Requisicao.getProcessoDadosBasicosPorNumero(numero)
        .then((data) => {
          setupProcesso(data)
        })
        .catch(erro => {
          document.getElementById('inputNumeroProcesso')?.classList.remove('is-valid');
          document.getElementById('inputNumeroProcesso')?.classList.add('is-invalid');
          setOrgaoSolicitante("-1");
          setObjetoProcesso("");
          setValorProcesso(0);
          console.log('erro', erro);
        });
    }
  }

  const setupProcesso = (p) => {
    if (p != null) {
      let analises = p.analises;
      let maisRecente = null;
      if (analises != null && analises.length > 0) {
        for (let i = 0; i < analises.length; i++) {
          if (maisRecente == null) {
            maisRecente = analises[i];
          } else {
            if (parseISO(analises[i]?.dataCriacao) > maisRecente?.dataCriacao) {
              maisRecente = analises[i];
            }
          }
        }
      }
      let valorProcesso = 0;
      if (maisRecente != null) {
        valorProcesso = Number(maisRecente?.valorEstimado);
      }

      document.getElementById('inputNumeroProcesso')?.classList.remove('is-invalid');
      document.getElementById('inputNumeroProcesso')?.classList.add('is-valid');
      setOrgaoSolicitante(p.orgaoSolicitante);
      setObjetoProcesso(p.objeto);
      setValorProcesso(valorProcesso);
    }
  }

  const carregarListas = () => {
    Requisicao.getListaOrgaos().then((data) => setListaOrgaos(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaTipoAvisoLicitacao().then((data) => setListaAvisoLicitacao(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaLeisLicitacao().then((data) => setListaLeiLicitacao(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaModalidadesLicitacaoAtivas().then((data) => setListaModalidadeLicitacao(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaCriterioJulgamento().then((data) => setListaCriterioJulgamento(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaTipoAjudicacao().then((data) => setListaTipoAjudicacao(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaRegimeExecucao().then((data) => setListaRegimeExecucao(data)).catch(erro => console.log('erro', erro));
  }

  const carregarAviso = (id) => {
    setLoading(true);
    Requisicao.getAvisoLicitacao(id).then((data) => {
      setupAviso(data);
      setLoading(false);
    }).catch(erro => console.log('erro', erro));
  }

  const setupAviso = (a) => {

    setNumeroProcesso(a?.numeroProcesso ?? "");
    setOrgaoSolicitante(a?.orgaoSolicitante ?? "-1");
    setObjetoProcesso(a?.objetoProcesso ?? "");
    setValorProcesso(a?.valorProcesso ?? 0);
    setNumeroLicitacao(a?.numeroLicitacao);
    setValorPortalTransparencia(a?.valorPortalTransparencia ?? 0);
    setObjetoPortalTransparencia(a?.objetoPortalTransparencia ?? "");
    setObservacoes(a?.observacoes ?? "");

    if (a.publicacoes != null && a.publicacoes.length > 0) {
      a.publicacoes?.forEach((publicacao) => {
        publicacao.dataAviso = isValid(parseISO(publicacao?.dataAviso)) ? parseISO(publicacao?.dataAviso) : null;
        publicacao.dataPublicacaoDiarioOficial = isValid(parseISO(publicacao?.dataPublicacaoDiarioOficial)) ? parseISO(publicacao?.dataPublicacaoDiarioOficial) : null;
        publicacao.dataProcedimentoLicitatorio = isValid(parseISO(publicacao?.dataProcedimentoLicitatorio)) ? parseISO(publicacao?.dataProcedimentoLicitatorio) : null;
        publicacao.dataLimiteEnvioTCE = isValid(parseISO(publicacao?.dataLimiteEnvioTCE)) ? parseISO(publicacao?.dataLimiteEnvioTCE) : null;
        publicacao.dataEnvioTCE = isValid(parseISO(publicacao?.dataEnvioTCE)) ? parseISO(publicacao?.dataEnvioTCE) : null;
      })
      // setPublicacoes(a.publicacoes.sort(Utilidades.sortPorOrdem));
      // setPublicacoes(a.publicacoes);
      let arr = a.publicacoes.sort(Utilidades.sortPorOrdem)
      let newOrdem = 1;
      arr?.forEach((p) => (p.ordem = newOrdem++));
      setPublicacoes(arr);
    }

    setAviso(a);

  }

  // preencher comboboxOrgaos
  useEffect(() => {
    window.$("#comboboxOrgaos").empty();
    let combobox = document.querySelector("#comboboxOrgaos").options;

    combobox.add(new Option("Selecione o Órgão Solicitante...", "-1"));
    combobox[0].disabled = true;
    combobox.selectedIndex = 0;

    listaOrgaos.forEach((element, index) => {
      combobox.add(new Option(element.nome, element.value));
      if (orgaoSolicitante != null && orgaoSolicitante === element.value) {
        combobox.selectedIndex = index + 1;
      }
    });

  }, [listaOrgaos, orgaoSolicitante]);

  const adicionarNovaPublicação = () => {
    let arr = publicacoes ?? [];
    let newOrdem = 1;
    if (arr != null && arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        arr[i].ordem = i + 1;

      }
      newOrdem = arr.length + 1;
    }
    // arr?.forEach((p) => (p.ordem = newOrdem++))

    setPublicacoes([
      ...arr,
      {
        ordem: newOrdem++,
        id: null,
        tipoAvisoLicitacao: "",
        dataAviso: null,
        dataPublicacaoDiarioOficial: null,
        dataProcedimentoLicitatorio: null,
        dataLimiteEnvioTCE: null,
        dataEnvioTCE: null,
        leiLicitacao: "",
        modalidadeLicitacao: "",
        criterioJulgamento: "",
        tipoAjudicacao: "",
        regimeExecucao: "",
        me_epp: false,
        observacoes: "",
      }
    ])
    // ordemPublicacao = newOrdem;
    // setOrdemPublicacao(newOrdem);
  }


  const onChangePublicacao = (publicacao) => {
    let arr = publicacoes;
    arr = arr.map((p) => {
      if (p.ordem === publicacao.ordem) {
        return publicacao;
      } else {
        return p;
      }
    })
    setPublicacoes(arr);
  }

  const deletarPublicacao = (publicacao) => {

    let publicacoesFiltradas = publicacoes.filter((p) => parseInt(p.ordem) !== parseInt(publicacao.ordem))
    // publicacoesFiltradas?.forEach((a) => (a.ordem = newOrdem++));
    if (publicacoesFiltradas != null && publicacoesFiltradas.length > 0) {
      // publicacoesFiltradas?.sort(Utilidades.sortPorOrdem);
      for (let i = 0; i < publicacoesFiltradas.length; i++) {
        publicacoesFiltradas[i].ordem = i + 1;

      }
    }

    // ordemPublicacao = newOrdem;
    // setOrdemPublicacao(newOrdem);

    setPublicacoes(publicacoesFiltradas);

  }

  const moverPublicacaoParaCima = (p) => {
    if(publicacoes != null && publicacoes?.length > 0){
      let arr = [...publicacoes];
      if(arr[0]?.ordem !== p?.ordem){
        let index = null;
        for(let i = 0; i < arr.length; i++){
          if(arr[i]?.ordem === p?.ordem){
            index = i;
            break;
          }
        }
        if(index != null && index !== 0){
          let temp = arr[index]?.ordem;
          arr[index].ordem = arr[index - 1]?.ordem;
          arr[index - 1].ordem = temp;
          arr = Utilidades.swapArrayElements(arr, index, index - 1);
          setPublicacoes(arr);
        }
      }
    }
  }

  const moverPublicacaoParaBaixo = (p) => {
    if(publicacoes != null && publicacoes?.length > 0){
      let arr = [...publicacoes];
      if(arr[arr.length - 1]?.ordem !== p?.ordem){
        let index = null;
        for(let i = 0; i < arr.length; i++){
          if(arr[i]?.ordem === p?.ordem){
            index = i;
            break;
          }
        }
        if(index != null && index !== (arr.length - 1)){
          let temp = arr[index]?.ordem;
          arr[index].ordem = arr[index + 1]?.ordem;
          arr[index + 1].ordem = temp;
          arr = Utilidades.swapArrayElements(arr, index, index + 1);
          setPublicacoes(arr);
        }
      }
    }
  }

  const validateForm = () => {
    let valido = true;
    // if(!document.getElementById("inputNumeroProcesso").classList.contains("is-valid")){
    //   valido = false;
    // }
    return valido;
  }

  const gravar = () => {
    setSalvando(true);
    const dados = aviso;

    dados.numeroProcesso = numeroProcesso;
    dados.orgaoSolicitante = orgaoSolicitante;
    dados.objetoProcesso = objetoProcesso;
    dados.valorProcesso = valorProcesso;
    dados.numeroLicitacao = numeroLicitacao;
    dados.valorPortalTransparencia = valorPortalTransparencia;
    dados.objetoPortalTransparencia = objetoPortalTransparencia;
    dados.observacoes = observacoes;

    dados.publicacoes = publicacoes;

    if(dados.publicacoes != null && dados.publicacoes.length > 0){
      dados.publicacoes.forEach(p => {
        if(p.regimeExecucao === "" || p.regimeExecucao === "-1"){
          p.regimeExecucao = null;
        }
      })
    }

    let url = null;

    if(avisoId != null && !isNaN(Number(avisoId))){ //editar
      dados.id = avisoId;
      url = window.servidor + "/v1/protegido/avisolicitacao/editar"
      
    } else { //novo
      dados.id = null;
      url = window.servidor + "/v1/protegido/avisolicitacao/novo"
    }

    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(dados)
    };

    fetch(url, requestOptions)
        .then((response) => {
          if(response.status !== 200){
            setSalvando(false);
            throw new Error("Falha no cadastro/edição aviso.")
          }
          return response.text();
        })
        .then((data) => {
          // alert("Aviso salvo com sucesso.");
          alert(data);
          setSalvando(false);
          // limparInterface();
          // navigate("/analise/editar/" + data, { state:{referrer:location}, replace:true });
        })
        .catch(erro => console.log(erro));

  }

  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(validateForm()){
      gravar();
    }
  }


  





  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Aviso de Licitação</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Aviso de Licitação</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div>
                <h4>Informações Gerais</h4>
              </div>
              <hr />
              <div className='d-flex flex-column flex-md-row px-2'>
                <div className='form-group col col-md-6'>
                  <label htmlFor='inputNumeroProcesso'>Número do Processo</label>
                  <div className='input-group'>
                    <input type='text' id='inputNumeroProcesso' className='form-control' list='list_processos' placeholder='Digite o número do processo...'
                      onChange={(e) => setNumeroProcesso(e.target.value)} value={numeroProcesso}
                      autoComplete='off' aria-describedby='inputNumeroProcessoFeedback'></input>
                    <div className="input-group-append">
                      <Tippy content='Carregar dados do Processo'>
                        <button className="btn btn-outline-secondary rounded-right" type="button" id="button-addon-numero-processo"
                          onClick={() => { carregarProcesso(numeroProcesso) }}><i className="fas fa-check"></i></button>
                      </Tippy>
                    </div>
                    <div id="inputNumeroProcessoFeedback" className="invalid-feedback mb-2">Processo não encontrado</div>
                  </div>
                  <div className='d-inline' id='processos_suggest_container'>
                    <datalist id="list_processos">
                    </datalist>
                  </div>
                </div>
                <div className="form-group col col-md-6">
                  <label htmlFor="comboboxOrgaos">Órgão Solicitante</label>
                  <select id="comboboxOrgaos" className="select2bs4" style={{ width: "100%" }} aria-describedby="comboboxOrgaosFeedback">
                  </select>
                  <div
                    id="comboboxOrgaosFeedback"
                    className="invalid-feedback mb-4"
                  >
                    Selecione o Órgão Solicitante.
                  </div>
                </div>
              </div>
              <div className='col'>
                <label>Objeto do Processo</label>
                <textarea className='form-control' rows={3} placeholder='Digite o objeto do processo...'
                  value={objetoProcesso}
                  onChange={(e) => setObjetoProcesso(e.target.value)}
                ></textarea>
              </div>
              <div className="col-6">
                <label htmlFor="inputValorProcesso">
                  Valor&nbsp;
                  <Tippy content='Valor mais recente presente nas análises do processo.'>
                    <i className="fas fa-info-circle"></i>
                  </Tippy>
                </label>
                <input type="number" id="inputValorProcesso" className="form-control"
                  value={valorProcesso}
                  onChange={(e) => {
                    setValorProcesso(Utilidades.inputNumberFormatado(e.target.value));
                  }}
                  onWheel={(e) => e.target.blur()} required />
              </div>

              <hr />
              {/* <h6 className=''>Dados do Portal da Transparência</h6> */}
              <div className='row mt-3 px-2'>
                <div className='form-group col-6'>
                  <label htmlFor='inputNumeroLicitacao'>Número da Licitação</label>
                  <input type='text' id='inputNumeroLicitacao' className='form-control' placeholder='Digite o número da licitação...'
                    value={numeroLicitacao}
                    onChange={(e) => setNumeroLicitacao(e.target.value)}></input>
                </div>
                <div className="col-6">
                  <label htmlFor="inputValorPortalTransparencia">
                    Valor (Portal da Transparência)&nbsp;
                    <Tippy content='Valor encontrado no Portal da Transparência.'>
                      <i className="fas fa-info-circle"></i>
                    </Tippy>
                  </label>
                  <input type="number" id="inputValorPortalTransparencia" className="form-control"
                    value={valorPortalTransparencia}
                    onChange={(e) => {
                      setValorPortalTransparencia(Utilidades.inputNumberFormatado(e.target.value));
                    }}
                    onWheel={(e) => e.target.blur()} required />
                </div>
              </div>



              <div className='col'>
                <label>Objeto (Portal da Transparência)</label>
                <textarea className='form-control' rows={3} placeholder='Digite o objeto presente no portal da transparência...'
                  value={objetoPortalTransparencia}
                  onChange={(e) => setObjetoPortalTransparencia(e.target.value)}
                ></textarea>
              </div>
              <hr />
              <div className='col'>
                <label>Observações</label>
                <textarea className='form-control' rows={3} placeholder='Digite o objeto presente no portal da transparência...'
                  value={observacoes}
                  onChange={(e) => setObservacoes(e.target.value)}
                ></textarea>
              </div>

              <br />
              <br />
              <div>
                <h4>Publicações de Aviso de Licitação</h4>
              </div>
              <hr />

              {
                publicacoes && publicacoes.map((publicacao, index) => (
                  <PublicacaoAviso key={publicacao.ordem} 
                    publicacao={publicacao} collapsed={(publicacao?.id == null) ? false : true}
                    listaTipoAvisoLicitacao={listaTipoAvisoLicitacao}
                    listaLeiLicitacao={listaLeiLicitacao}
                    listaModalidadeLicitacao={listaModalidadeLicitacao}
                    listaCriterioJulgamento={listaCriterioJulgamento}
                    listaTipoAjudicacao={listaTipoAjudicacao}
                    listaRegimeExecucao={listaRegimeExecucao}
                    onChangePublicacao={onChangePublicacao}
                    deletarPublicacao={deletarPublicacao}
                    moveUp={moverPublicacaoParaCima}
                    moveDown={moverPublicacaoParaBaixo}
                  />
                  )
                )
              }

              <div className='text-center'>
                <button type='button' className='btn btn-primary' onClick={() => { adicionarNovaPublicação() }}>Adicionar Publicação de Aviso</button>
              </div>

              <br />
              <hr />
              { loading ? 
                <></> :
                <>
                  <div className='text-right'>
                    {salvando ?
                      <button type='button' className='btn btn-primary' onClick={submitForm} disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;Salvando...
                      </button>
                      : <button type='button' className='btn btn-primary' onClick={submitForm}>Salvar</button>
                    }
                  </div>
                </>
              }
            </div>
          </div>
        </form>
      </div>

    </>
  )
}
