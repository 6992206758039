import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import Utilidades from '../../helpers/Utilidades';
import Requisicao from '../../helpers/Requisicao';
import EnumUtils from '../../helpers/EnumUtils';
import Tippy from '@tippyjs/react';
import { isValid, parseISO } from 'date-fns';

export default function ExibirPublicacaoAviso({ publicacao, collapsed, listaTipoAvisoLicitacao,
  listaLeiLicitacao, listaModalidadeLicitacao, listaCriterioJulgamento, listaTipoAjudicacao, listaRegimeExecucao }) {

  const key = publicacao.ordem;

  const leiLicitacao = publicacao?.leiLicitacao;
  const modalidadeLicitacao = publicacao?.modalidadeLicitacao;
  const tipoAvisoLicitacao = publicacao?.tipoAvisoLicitacao;
  const criterioJulgamento = publicacao?.criterioJulgamento;
  const tipoAjudicacao = publicacao?.tipoAjudicacao;
  const regimeExecucao = publicacao?.regimeExecucao;

  return (
    <>
      <div className="col">
        <div id={`card-publicacao-${key}`} className={(collapsed) ? "card card-navy collapsed-card" : "card card-navy"}  >
          <div className="card-header" data-card-widget="collapse" style={{ cursor: "pointer" }}>
            <h3 className="card-title">
              {(tipoAvisoLicitacao != null) ? EnumUtils.getNomeEnum(tipoAvisoLicitacao, listaTipoAvisoLicitacao) + " - " + Utilidades.fromDateToLocalStringShort(publicacao?.dataAviso) : "Nova Publicação"}
            </h3>
            <div className="card-tools">
              <Tippy content={(collapsed) ? "Expandir" : "Minimizar"}>
                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className={(collapsed) ? "fas fa-plus" : "fas fa-minus"} />
                </button>
              </Tippy>
            </div>
          </div>
          <div className="card-body">
            <div className='d-flex flex-column flex-md-row my-2'>
              <div className="form-group col col-md-6">
                <label>Tipo do Aviso</label>
                <div>{EnumUtils.getNomeEnum(tipoAvisoLicitacao, listaTipoAvisoLicitacao)}</div>
              </div>
              <div className='form-group col col-lg-6'>
                <label>Data do Procedimento (Licitatório)</label>
                <div>{Utilidades.fromDateToLocalStringShort(publicacao?.dataProcedimentoLicitatorio)}</div>
              </div>
            </div>
            <div className='d-flex flex-column flex-md-row my-2'>
              <div className='form-group col col-lg-6'>
                <label>Data do Aviso</label>
                <div>{Utilidades.fromDateToLocalStringShort(publicacao?.dataAviso)}</div>
              </div>
              <div className='form-group col col-lg-6'>
                <label>Data da Publicação no Diário Oficial</label>
                <div>{Utilidades.fromDateToLocalStringShort(publicacao?.dataPublicacaoDiarioOficial)}</div>
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row my-2">
              <div className='col col-md-6'>
                <label>Data limite de envio ao TCE/RJ:</label>
                <div className='text-bold'>
                  {Utilidades.fromDateToLocalStringShort(publicacao?.dataLimiteEnvioTCE)}
                </div>
              </div>
              <div className='form-group col col-lg-6'>
                <label>Data de Envio ao TCE (SIGFIS)</label>
                <div>{Utilidades.fromDateToLocalStringShort(publicacao?.dataEnvioTCE)}</div>
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row my-2">
              <div className="col col-md-6">
                <label>Lei de Licitações/Serviços</label>
                <div>{EnumUtils.getNomeEnum(leiLicitacao, listaLeiLicitacao)}</div>
              </div>
              <div className="col col-md-6">
                <label>Modalidades de Licitações/Procedimentos</label>
                <div>{EnumUtils.getNomeEnum(modalidadeLicitacao, listaModalidadeLicitacao)}</div>
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row my-2">
              <div className="col col-md-6">
                <label>Critérios de Julgamento</label>
                <div>{EnumUtils.getNomeEnum(criterioJulgamento, listaCriterioJulgamento)}</div>
              </div>
              <div className="col col-md-6">
                <label>Tipo de Ajudicação</label>
                <div>{EnumUtils.getNomeEnum(tipoAjudicacao, listaTipoAjudicacao)}</div>
              </div>

            </div>
            <div className="d-flex flex-column flex-md-row my-2">
              <div className="col col-md-6">
                <label>Regime de Execução</label>
                <div>{EnumUtils.getNomeEnum(regimeExecucao, listaRegimeExecucao)}</div>
              </div>
              <div className='col col-md-6'>
                <label>Participação ME/EPP:</label>
                <div>{(publicacao?.me_epp) ? "SIM" : "NÃO"}</div>
              </div>
            </div>
            <div className='col mx-0 my-2 px-2'>
              <label>Observações</label>
              <div className='fix-linebreak'>{publicacao?.observacoes}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
