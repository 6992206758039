class TipoAditivo {
    constructor(){
        this.tipos = {
            CONTRATO_ORIGINAL: "Contrato Original",
            TERMO_ADITIVO: "Termo Aditivo",
            TERMO_APOSTILAMENTO: "Termo de Apostilamento",
            REAJUSTE: "Reajuste",
            ATA: "Ata de Registro de Preços"
        };
    }


    getNome(tipoAditivo) {
        return this.tipos[tipoAditivo];
    }
    
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new TipoAditivo();
