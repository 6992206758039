import React, { useState, useEffect } from 'react';
import Autenticar from '../../login/Autenticar';
import LoadingElement from '../../components/LoadingElement';
import Requisicao from '../../helpers/Requisicao';
import EnumUtils from '../../helpers/EnumUtils';

export default function AdicionarRecomendacaoBancoModal({ isOpen, onSelect, titulo, lei, tipo, usuario, customId }) {
  const [titulos, setTitulos] = useState([]);
  const [listaLeiLicitacao, setListaLeiLicitacao] = useState([]);
  const [listaTipoAnalise, setListaTipoAnalise] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [leiLicitacao, setLeiLicitacao] = useState(lei ?? '');
  const [tipoAnalise, setTipoAnalise] = useState(tipo ?? '');
  const [selectedTitulo, setSelectedTitulo] = useState(titulo ?? '');

  const [pageSize, setPageSize] = useState("5");
  const [page, setPage] = useState(0);

  const [resultado, setResultado] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [triggerCount, setTriggerCount] = useState(0);


  useEffect(() => {
    if (isOpen) {
      window.$(".select2bs4").select2({
        theme: "bootstrap4",
      });

      window.$(`#comboboxTitulos`).on('change', function (e) {
        setSelectedTitulo(e.target.value);
      });
      window.$(`#comboboxLeiLicitacao`).on('change', function (e) {
        setLeiLicitacao(e.target.value);
      });
      window.$(`#comboboxTipoAnalise`).on('change', function (e) {
        setTipoAnalise(e.target.value);
      });


      carregarLeis();
      carregarTipos();

      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      fetch(`${window.servidor}/v1/protegido/modelosanalise/dados/titulosmodelosblocos`, requestOptions)
        .then(response => response.json())
        .then(data => setTitulos(data))
        .catch(error => console.error('Erro carregando titulos:', error));

      pesquisar();
      // setIsLoading(true);
      // fetch(`${window.servidor}/v1/protegido/bancorecomendacao/pesquisa`, requestOptions)
      //   .then(response => response.json())
      //   .then(data => {
      //     setResultado(data);
      //     setIsLoading(false);
      //   })
      //   .catch(error => {
      //     console.error('Erro na pesquisa:', error);
      //     setIsLoading(false);
      //   });
    }

    return (() => {
      window.$(`#comboboxTitulos`).off('change');
      window.$(`#comboboxLeiLicitacao`).off('change');
      window.$(`#comboboxTipoAnalise`).off('change');
    })
    // eslint-disable-next-line
  }, [isOpen]);

  const pesquisar = () => {
    setIsLoading(true);
    setResultado(null);

    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };


    fetch(`${window.servidor}/v1/protegido/bancorecomendacao/pesquisa?p=${page}&s=${pageSize}&tituloBloco=${selectedTitulo}&texto=${searchText}&leiLicitacao=${leiLicitacao}&tipoAnalise=${tipoAnalise}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        setResultado(data);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Erro na pesquisa:', error);
        setIsLoading(false);
      });
  };

  const carregarLeis = () => {
    Requisicao.getListaLeisLicitacao()
      .then((data) => {
        setListaLeiLicitacao(data);
      })
      .catch((erro) => console.log(erro));
  };

  const carregarTipos = () => {
    Requisicao.getListaTiposAnaliseOrigem()
      .then((data) => {
        setListaTipoAnalise(data);
      })
      .catch((erro) => console.log(erro));
  };

  const getPagination = () => {
    let totalPaginas = parseInt(resultado.totalPaginas) ?? 0;
    let pag = []

    if (parseInt(resultado.numeroPagina) === 0) {
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Primeira</button></li>);
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Anterior</button></li>);
    } else {
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(0)}>Primeira</button></li>);
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado?.numeroPagina) - 1)}>Anterior</button></li>);
    }

    // for
    if (totalPaginas >= 9) {
      if (parseInt(resultado.numeroPagina) >= 5) {
        // pag.push(<li className="page-item"><button type="button" className="page-link" data-toggle="modal" data-target="#changePageModal">...</button></li>);
        pag.push(<li className="page-item"><button type="button" className="page-link" disabled>...</button></li>);
      }

      for (let i = Math.max(parseInt(resultado.numeroPagina) - 4, 0); i < parseInt(resultado.numeroPagina); i++) {
        pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
      }

      pag.push(<li className="page-item active"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado.numeroPagina))}>{parseInt(resultado.numeroPagina) + 1}</button></li>);

      for (let i = parseInt(resultado.numeroPagina) + 1; i <= parseInt(resultado.numeroPagina) + 4 && i < totalPaginas; i++) {
        pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
      }

      if ((totalPaginas - parseInt(resultado.numeroPagina)) >= 6) {
        // pag.push(<li className="page-item"><button type="button" className="page-link" data-toggle="modal" data-target="#changePageModal">...</button></li>);
        pag.push(<li className="page-item"><button type="button" className="page-link" disabled>...</button></li>);
      }
    } else {
      for (let i = 0; i < totalPaginas; i++) {
        if (i === parseInt(resultado.numeroPagina)) {
          pag.push(<li className="page-item active"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
        } else {
          pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
        }
      }
    }

    if (parseInt(resultado?.numeroPagina) + 1 === parseInt(resultado?.totalPaginas)) {
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Próxima</button></li>);
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Última</button></li>);
    } else {
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado?.numeroPagina) + 1)}>Próxima</button></li>);
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado?.totalPaginas) - 1)}>Última</button></li>);
    }

    return pag;
  }

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
    pesquisar();
  }

  const handleChangePage = (page) => {
    setPage(page);
    pesquisar();
  }

  // const handleTituloChange = (e) => {
  //   setSelectedTitulo(e.target.value);
  // };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearchClick = () => {
    pesquisar();
  };

  const containsOption = (options, value) => {
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].value === value) {
        return true;
      }
    }
    return false;
  }

  // preencher comboboxTitulos
  useEffect(() => {
    if (document.querySelector(`#comboboxTitulos`)) {
      window.$(`#comboboxTitulos`).empty();
      let combobox = document.querySelector(`#comboboxTitulos`).options;

      let lei = leiLicitacao != null && leiLicitacao !== "" & leiLicitacao !== "-1" ? leiLicitacao : null;
      let tipoA = tipoAnalise != null && tipoAnalise !== "" & tipoAnalise !== "-1" ? tipoAnalise : null;

      combobox.add(new Option("Todos", ""));
      titulos?.forEach((element) => {
        if (lei != null && tipoA != null) {
          if (element.leiLicitacao === lei && element.tipoAnalise === tipoA && !containsOption(combobox, element.titulo)) {
            combobox.add(new Option(element.titulo, element.titulo));
          }
        }
        if (lei != null && tipoA == null) {
          if (element.leiLicitacao === lei && !containsOption(combobox, element.titulo)) {
            combobox.add(new Option(element.titulo, element.titulo));
          }
        }
        if (lei == null && tipoA != null) {
          if (element.tipoAnalise === tipoA && !containsOption(combobox, element.titulo)) {
            combobox.add(new Option(element.titulo, element.titulo));
          }
        }
        if (lei == null && tipoA == null && !containsOption(combobox, element.titulo)) {
          combobox.add(new Option(element.titulo, element.titulo));
        }
      });

      // combobox[0].disabled = true;
      combobox.selectedIndex = 0;


      if (selectedTitulo != null) {
        // for (let i = 0; i < combobox.length; i++) {
        //   if (selectedTitulo === combobox[i].value) {
        //     window.$(`#comboboxTitulos`).val(combobox[i].value).trigger("change");
        //   }
        // }
        if (containsOption(combobox, selectedTitulo)) {
          window.$(`#comboboxTitulos`).val(selectedTitulo).trigger("change");
        } else {
          if(combobox.length > 1){
            if ((leiLicitacao != null && leiLicitacao !== "") || (tipoAnalise != null && tipoAnalise !== "")) {
              window.$(`#comboboxTitulos`).val("").trigger("change");
            }
          }
        }
      }

    }
  }, [titulos, leiLicitacao, tipoAnalise, selectedTitulo]);

  // useEffect(() => {
  //   console.log('selectedTitulo', selectedTitulo)
  //   console.log('leiLicitacao', leiLicitacao)
  //   console.log('tipoAnalise', tipoAnalise)
  //   console.log("-------------------------------------")
  // }, [selectedTitulo, leiLicitacao, tipoAnalise])

  // preencher comboboxLeiLicitacao
  useEffect(() => {
    if (document.querySelector(`#comboboxLeiLicitacao`)) {
      window.$(`#comboboxLeiLicitacao`).empty();
      let combobox = document.querySelector(`#comboboxLeiLicitacao`).options;

      combobox.add(new Option("Todos", ""));
      listaLeiLicitacao?.forEach((element) => {
        combobox.add(new Option(element.nome, element.value));
      });

      // combobox[0].disabled = true;
      combobox.selectedIndex = 0;

      if (leiLicitacao != null) {
        for (let i = 0; i < combobox.length; i++) {
          if (leiLicitacao === combobox[i].value) {
            window.$(`#comboboxLeiLicitacao`).val(combobox[i].value).trigger("change");
          }
        }
      }
    }
  }, [listaLeiLicitacao, leiLicitacao]);

  // preencher comboboxTipoAnalise
  useEffect(() => {
    if (document.querySelector(`#comboboxTipoAnalise`)) {

      window.$("#comboboxTipoAnalise").empty();
      let combobox = document.querySelector("#comboboxTipoAnalise").options;

      combobox.add(new Option("Todos", ""));
      listaTipoAnalise?.forEach((element) => {
        if (usuario?.tipoUsuario.toString() === "ADMINISTRADOR" ||
          usuario?.tipoUsuario.toString() === "SCF_ADMINISTRADOR" ||
          usuario?.tipoUsuario.toString() === "SCF_ANALISTA_UC" ||
          usuario?.tipoUsuario.toString() === "SCF_REVISOR_UC") {
          combobox.add(new Option(element.nome, element.value));
        } else {
          if (element.value !== "ANALISE_PREVIA") {
            combobox.add(new Option(element.nome, element.value));
          }
        }
      });

      // combobox[0].disabled = true;
      combobox.selectedIndex = 0;

      if (tipoAnalise != null) {
        for (let i = 0; i < combobox.length; i++) {
          if (tipoAnalise === combobox[i].value) {
            window.$(`#comboboxTipoAnalise`).val(combobox[i].value).trigger("change");
          }
        }
      }
    }
  }, [listaTipoAnalise, tipoAnalise, usuario.tipoUsuario]);


  const limparInterfaceModal = () => {
    // setLeiLicitacao("");
    // setTipoAnalise("");
    // setSelectedTitulo("");
    setSearchText("");
    window.$("#comboboxLeiLicitacao").val("").trigger("change");
    window.$("#comboboxTipoAnalise").val("").trigger("change");
    window.$("#comboboxTitulos").val("").trigger("change");
    triggerSearch();
  }

  const triggerSearch = () => {
    setTriggerCount(triggerCount + 1);
  }

  useEffect(() => {
    if(triggerCount > 0){
      pesquisar();
    }
  }, [triggerCount])


  return (
    isOpen && (
      <div className="modal-body">
        <div className='row p-2'>
          <div className='col col-lg-4'>
            <label htmlFor="comboboxLeiLicitacao">Lei de Licitações/Serviços</label>
            <select id="comboboxLeiLicitacao" className="select2bs4" style={{ width: '100%' }}></select>
          </div>
          <div className='col col-lg-4'>
            <label htmlFor="comboboxTipoAnalise">Tipo da Análise</label>
            <select id="comboboxTipoAnalise" className="select2bs4" style={{ width: '100%' }}></select>
          </div>
        </div>
        <div className='row p-2'>
          <div className='col col-lg-4'>
            <label htmlFor="comboboxTitulos">Bloco</label>
            <select id="comboboxTitulos" className="select2bs4" style={{ width: '100%' }}></select>
          </div>
          <div className='col col-lg-6'>
            <label htmlFor="inputSearchText">Texto</label>
            <input type="text" id="inputSearchText" className="form-control" value={searchText} onChange={handleSearchTextChange} />
          </div>
          <div className='row'>
            <div className='mx-1'>
              <label htmlFor='searchButton'>&nbsp;</label>
              <div>
                <button id='searchButton' type='button' disabled={isLoading} className="btn btn-primary" onClick={handleSearchClick}>
                  {isLoading ? (<><span className="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span></>) : <i className="fas fa-search"></i>}
                </button>
              </div>
            </div>
            <div className='mx-1'>
              <label htmlFor='limparButton'>&nbsp;</label>
              <div>
                <button type='button' id='limparButton' className="btn btn-primary" onClick={() => limparInterfaceModal()}>Limpar</button>
              </div>
            </div>
          </div>
        </div>
        {/* { resultado == null && isLoading ? <LoadingElement /> : <></>} */}
        {
          (resultado != null && resultado.elementos != null && resultado.elementos instanceof Array && resultado.elementos.length > 0) ?
            <div className='col'>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th className='text-sm'>Lei de Licitação</th>
                    <th className='text-sm'>Tipo de Análise</th>
                    <th className='text-sm'>Bloco</th>
                    <th className='text-sm' style={{width: "70%"}}>Recomendação</th>
                    <th className='text-sm' style={{width: "7%"}}></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    resultado.elementos && resultado.elementos.map((elemento) => (
                      <tr key={elemento.id}>
                        <td className='text-sm'>{EnumUtils.getNomeEnum(elemento.leiLicitacao, listaLeiLicitacao)}</td>
                        <td className='text-sm'>{EnumUtils.getNomeEnum(elemento.tipoAnalise, listaTipoAnalise)}</td>
                        <td className='text-sm'>{elemento.tituloBloco}</td>
                        <td className='text-sm'>{
                          (elemento.html != null && elemento.html !== "") ?
                            <div dangerouslySetInnerHTML={{ __html: elemento.html }}></div> :
                            elemento.texto
                        }</td>
                        <td className='text-sm'><button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => onSelect((elemento.html != null && elemento.html !== "") ? elemento.html : elemento.texto)}>Selecionar</button></td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              <div className='px-2 py-2 row justify-content-between'>
                {
                  resultado?.elementos != null && resultado?.elementos.length > 0 ?
                    <nav aria-label="Page navigation">
                      <ul className="pagination row px-2">
                        {
                          getPagination().map((pag, index) => {
                            return <React.Fragment key={index}>
                              {pag}
                            </React.Fragment>
                          })

                        }
                      </ul>
                    </nav>
                    : <div></div>
                }
                <select id="comboboxPageSize" className="form-control col-md-1 text-center" value={pageSize} onChange={handleChangePageSize}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div> :
            <>
              {
                isLoading ? <LoadingElement /> : <p className='pt-2 text-center'>Nenhuma recomendação encontrada</p>
              }
            </>


        }

      </div>
    )
  );
}
