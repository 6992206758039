import React, { useEffect } from 'react'
import LoadingElement from '../components/LoadingElement';

export default function Logout({logout}) {

    useEffect(() => {
        logout();
    }, [logout]);


  return (
    <div style={{margin: "42.5vh auto 42vh auto"}}>
      <LoadingElement />
    </div>
  )
}
