import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Autenticar from '../../login/Autenticar';
import "react-datepicker/dist/react-datepicker.css";
import LoadingElement from '../../components/LoadingElement';
import { isValid, parseISO } from 'date-fns';
import DatePicker from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import AvisoCollapse from './AvisoCollapse';
import Requisicao from '../../helpers/Requisicao';
import Utilidades from '../../helpers/Utilidades';


let nomesOrgaos = {};
let nomesTiposAviso = {};

export default function PesquisaAviso({usuario, autenticado}) {

  const [searchParams, setSearchParams] = useSearchParams();

  const [numeroProcesso, setNumeroProcesso] = useState(searchParams.get("numeroProcesso") ?? "");
  const [numeroLicitacao, setNumeroLicitacao] = useState(searchParams.get("numeroLicitacao") ?? "");
  const [orgao, setOrgao] = useState("");
  const [objeto, setObjeto] = useState(searchParams.get("objeto") ?? "");
  const [dataPublicacao, setDataPublicacao] = useState(null);
  const [dataProcedimento, setDataProcedimento] = useState(null);
  // const [pesquisarDataPublicacao, setPesquisarDataPublicacao] = useState(false);
  // const [pesquisarDataProcedimento, setPesquisarDataProcedimento] = useState(false);

  const [listaOrgaos, setListaOrgaos] = useState([]);

  const [resultado, setResultado] = useState({});


  // const [pageNumber, setPageNumber] = useState("0");
  const [pageSize, setPageSize] = useState("10");

  const [nenhumEncontrado, setNenhumEncontrado] = useState(false);

  const orgaoParam = searchParams.get("orgao") ?? "";
  const dataPublicacaoParam = searchParams.get("dataPublicacao") ?? "";
  const dataProcedimentoParam = searchParams.get("dataProcedimento") ?? "";

  const [pesquisando, setPesquisando] = useState(false);

  // const DataPublicacaoCustomInput = forwardRef(({ value, onClick }, ref) => (
  //   <div className="input-group mb-3">
  //     <div className="input-group-prepend">
  //       <div className="input-group-text">
  //         <input type="checkbox" checked={pesquisarDataPublicacao} onChange={(e) => setPesquisarDataPublicacao(e.target.checked)}></input>
  //       </div>
  //     </div>
  //     <input type="text" className="form-control" value={value} onClick={onClick} ref={ref} placeholder='Selecione a data da publicação...'></input>
  //   </div>

  // ));


      /* <button className="example-custom-input" onClick={onClick} ref={ref}>
      {value}
    </button> */
  useEffect(() => {
    if(dataPublicacaoParam != null && dataPublicacaoParam !== "" && isValid(parseISO(dataPublicacaoParam))){
      setDataPublicacao(parseISO(dataPublicacaoParam));
    }
  }, [dataPublicacaoParam]);

  useEffect(() => {
    if(dataProcedimentoParam != null && dataProcedimentoParam !== "" && isValid(parseISO(dataProcedimentoParam))){
      setDataProcedimento(parseISO(dataProcedimentoParam));
    }
  }, [dataProcedimentoParam]);

  useEffect(() => {
    if(searchParams != null && searchParams.toString() !== ""){
      if(searchParams.has("s") && searchParams.get("s") !== ""){
        setPageSize(searchParams.get("s"));
      }
      pesquisar();
    }
  // eslint-disable-next-line
  }, [searchParams]);

  const getPagination = () => {
    let totalPaginas = parseInt(resultado.totalPaginas) ?? 0;
    let pag = []

    if(parseInt(resultado.numeroPagina) === 0) {
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Primeira</button></li>);
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Anterior</button></li>);
    } else {
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(0)}>Primeira</button></li>);
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(parseInt(resultado?.numeroPagina)-1)}>Anterior</button></li>);
    }

    // for
    if(totalPaginas >= 9){
      if(parseInt(resultado.numeroPagina) >= 5){
        pag.push(<li className="page-item"><button type="button" className="page-link" data-toggle="modal" data-target="#changePageModal">...</button></li>);
      }

      for (let i = Math.max(parseInt(resultado.numeroPagina) - 4, 0); i < parseInt(resultado.numeroPagina); i++) {
        pag.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(i)}>{i+1}</button></li>);
      }

      pag.push(<li className="page-item active"><button type="button" className="page-link" onClick={()=>handleChangePage(parseInt(resultado.numeroPagina))}>{parseInt(resultado.numeroPagina)+1}</button></li>);

      for (let i = parseInt(resultado.numeroPagina) + 1; i <= parseInt(resultado.numeroPagina) + 4 && i < totalPaginas; i++) {
        pag.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(i)}>{i+1}</button></li>);
      }

      if((totalPaginas - parseInt(resultado.numeroPagina)) >= 6){
        pag.push(<li className="page-item"><button type="button" className="page-link" data-toggle="modal" data-target="#changePageModal">...</button></li>);
      }
    } else {
      for (let i = 0; i < totalPaginas; i++) {
        if(i === parseInt(resultado.numeroPagina)){
          pag.push(<li className="page-item active"><button type="button" className="page-link" onClick={()=>handleChangePage(i)}>{i+1}</button></li>);
        } else {
          pag.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(i)}>{i+1}</button></li>);
        }
      }
    }

    if(parseInt(resultado?.numeroPagina) + 1 === parseInt(resultado?.totalPaginas)){
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Próxima</button></li>);
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Última</button></li>);
    } else {
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(parseInt(resultado?.numeroPagina)+1)}>Próxima</button></li>);
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(parseInt(resultado?.totalPaginas)-1)}>Última</button></li>);
    }
   
    return pag;
  }

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
    let params = searchParams;
    params.set("p", "0");
    params.set("s", e.target.value);
    setSearchParams(params);
  }

  const handleChangePage = (page) => {
    let params = searchParams;
    params.set("p", page);
    setSearchParams(params);
  }

  const pesquisar = () => {
    setNenhumEncontrado(false);
    setPesquisando(true);
    setResultado({});

    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/avisolicitacao/pesquisa?" + searchParams.toString();

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if(data.totalElementos === 0 || data.totalElementos === null){
          setNenhumEncontrado(true);
        }
        setPesquisando(false);
        // console.log('data', data)
        if(data.elementos != null && data.elementos?.length > 0){
          data.elementos.forEach((aviso) => {
            // console.log('aviso', aviso);
            if(aviso.publicacoes != null && aviso.publicacoes?.length > 0){
              aviso.publicacoes?.sort(Utilidades.sortPorOrdem);
            }
          })
        }
          
        // console.log('data', data)
        setResultado(data);
      })
      .catch((erro) => console.log(erro));
  }

  const carregarListaOrgaos = () => {
    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/unidade/getorgaos";

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setListaOrgaos(data);
        nomesOrgaos = {};
        data.forEach((orgao)=>{
          nomesOrgaos[orgao.value] = orgao.nome;
        });
      })
      .catch((erro) => console.log(erro));
  };

  const carregarListaTiposAviso = () => {
    Requisicao.getListaTipoAvisoLicitacao()
      .then((data) => {
        nomesTiposAviso = {};
        data.forEach((tipo)=>{
          nomesTiposAviso[tipo.value] = tipo.nome;
        });
      })
      .catch((erro) => console.log(erro));
  }

  // preencher comboboxListaOrgaos
  useEffect(() => {
    window.$("#comboboxListaOrgaos").empty();
    let combobox = document.querySelector("#comboboxListaOrgaos").options;

    combobox.add(new Option("Selecione a Secretaria Solicitante", "-1"));
    listaOrgaos.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    if(listaOrgaos != null && listaOrgaos.length > 0){
      for (let i = 0; i < combobox.length; i++) {
        if(orgaoParam === combobox[i].value){
          combobox.selectedIndex = i;
          setOrgao(orgaoParam);
        }
      }
    } else {
      combobox.selectedIndex = 0;
    }

  }, [listaOrgaos, orgaoParam]);

  const handlePesquisar = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    let params = new URLSearchParams();

    if(numeroProcesso != null && numeroProcesso !== ""){
      params.set("numeroProcesso", numeroProcesso);
    }
    if(numeroLicitacao != null && numeroLicitacao !== ""){
      params.set("numeroLicitacao", numeroLicitacao);
    }
    if(orgao != null && orgao !== "" & orgao !== "-1"){
      params.set("orgao", orgao);
    }
    if(objeto != null && objeto !== ""){
      params.set("objeto", objeto);
    }
    if(dataPublicacao != null && dataPublicacao !== ""){
      params.set("dataPublicacao", dataPublicacao.toISOString().substring(0,10));
    }
    if(dataProcedimento != null && dataProcedimento !== ""){
      params.set("dataProcedimento", dataProcedimento.toISOString().substring(0,10));
    }
    
    params.set("p", "0");
    params.set("s", pageSize.toString());

    if(params.toString() !== searchParams.toString()){
      setSearchParams(params);
    } else {
      pesquisar();
    }
  }

  useEffect(()=>{
    window.$(".select2bs4").select2({
      theme: "bootstrap4",
    });
    window.$("#comboboxListaOrgaos").on('change', function (e) {
      setOrgao(e.target.value);
    });
    window.$('#changePageModal').on('hidden.bs.modal', function (e) {
      document.getElementById("changePageModalInput").value = "";
    })
    carregarListaOrgaos();
    carregarListaTiposAviso();

    document.title = 'Pesquisar Avisos de Licitação - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
  }, []);

  const limparInterface = () => {
    setNumeroProcesso("");
    setNumeroLicitacao("");
    setObjeto("");
    setDataPublicacao(null);
    setDataProcedimento(null);
    // setPesquisarDataPublicacao(null);
    // setPesquisarDataProcedimento(null);
    window.$('#comboboxListaOrgaos').val("-1").trigger("change");
    setResultado({}); 
  }


  return (
    <>
      <div className="modal fade" id="changePageModal" tabIndex={-1} aria-labelledby="changePageModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <form className="modal-content" onSubmit={(e)=>{
            e.preventDefault();
            let page = parseInt(document.getElementById("changePageModalInput").value);
            if(page > 0 && page <= parseInt(resultado?.totalPaginas)){
              handleChangePage(page-1);
              // document.getElementById("changePageModalInput").value = "";
              window.$("#changePageModal").modal("hide");
            }
          }}>
            <div className="modal-header">
              <h5 className="modal-title" id="changePageModalLabel">Insira o nº da página</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input className="form-control" id="changePageModalInput" type="number" onWheel={(e) => e.target.blur()} placeholder={`1 - ${resultado?.totalPaginas}`}/>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
              <button type="submit" className="btn btn-primary">Ir</button>
            </div>
          </form>
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Pesquisa de Avisos de Licitação</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Pesquisa de Avisos de Licitação</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12" onSubmit={handlePesquisar}>
          <div className="card">
            <div className="card-body">
              <h4 className='my-3'>Pesquisa de Avisos de Licitação</h4>
              <div className='my-3 row'>
                <div className='col'>
                  <label htmlFor='inputPesquisaNumProcesso'>Número do Processo</label>
                  <input id="inputPesquisaNumProcesso" type="text" className='form-control d-flex flex-column' value={numeroProcesso} onChange={(e)=>setNumeroProcesso(e.target.value)} placeholder={"Digite o número do processo..."} />
                </div>
                <div className='col'>
                  <label htmlFor='inputPesquisaNumProcesso'>Número da Licitação</label>
                  <input id="inputPesquisaNumProcesso" type="text" className='form-control d-flex flex-column' value={numeroLicitacao} onChange={(e)=>setNumeroLicitacao(e.target.value)} placeholder={"Digite o número da licitação..."} />
                </div>
                <div className='col'>
                  <label htmlFor='inputPesquisaObjeto'>Objeto</label>
                  <textarea id="inputPesquisaObjeto" rows={1} className='form-control d-flex flex-column' value={objeto} onChange={(e)=>setObjeto(e.target.value)} placeholder={"Digite o objeto..."} />
                </div>
              </div>
              <div className='my-3 row'>
                <div className='col'>
                  <label htmlFor='comboboxListaOrgaos'>Secretaria Solicitante</label>
                  <select id="comboboxListaOrgaos" className="form-control select2bs4" style={{ width: '100%' }}></select>
                </div>
              </div>
              <div className='my-3 row'>
                <div className='col'>
                  <label htmlFor="datepickerDataPublicacao">Data Publicação</label>
                  <div className='d-flex flex-column'>
                    <DatePicker id="datepickerDataPublicacao" className="form-control"
                      isClearable
                      placeholderText='Selecione a data da publicação...'
                      selected={dataPublicacao} onChange={(date) => {
                        setDataPublicacao(date);
                      }} 
                      strictParsing
                      locale={ptBR} dateFormat="dd/MM/yyyy"/>
                  </div>
                </div>
                <div className='col'>
                  <label htmlFor="datepickerDataProcedimento">Data do Procedimento (Licitatório)</label>
                  <div className='d-flex flex-column'>
                    <DatePicker id="datepickerDataProcedimento" className="form-control" 
                      isClearable
                      placeholderText='Selecione a data do procedimento licitatório...'
                      selected={dataProcedimento} onChange={(date) => {
                        setDataProcedimento(date);
                      }} 
                      strictParsing
                      locale={ptBR} dateFormat="dd/MM/yyyy"/>
                  </div>
                </div>
              </div>
              <div className='row'>
                <button type='submit' className='btn btn-primary mx-2'>Pesquisar</button>
                <button type='button' className='btn btn-outline-primary mx-2' onClick={limparInterface}>Limpar</button>
              </div>
              <hr />
              <div className='my-3'>
                  {
                    (pesquisando) ?
                    <div className='pt-5'>
                      <LoadingElement />
                    </div> :
                      <></>
                  }
                {
                  resultado.elementos && resultado.elementos.map((aviso, index) => (
                    <AvisoCollapse key={index} aviso={aviso} nomesTiposAviso={nomesTiposAviso} nomesOrgaos={nomesOrgaos} usuario={usuario}/>
                  ))
                }
                {
                  (nenhumEncontrado) ?
                  <p className='text-center'>Nenhum aviso encontrado</p> :
                  <></>
                }
              </div>
            </div>
            {
              (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.setor === "SUBCONTROLADORIA_GERAL" || usuario?.permissoes?.includes("PERM_SCF_VER_AVISO")) ?
                <div className='m-3 text-right'>
                  <Link className="btn btn-primary" to={"/avisolicitacao/novo"}>Novo Aviso</Link>
                </div> : <></>
            }
          </div>
          <div className='px-2 row justify-content-between'>
            {
              resultado?.elementos != null && resultado?.elementos.length > 0 ?
                <nav aria-label="Page navigation">
                  <ul className="pagination row px-2">
                    {

                      getPagination().map((pag, index) => {
                        return <React.Fragment key={index}>
                          {pag}
                        </React.Fragment>
                      })

                    }
                  </ul>
                </nav> 
                : <div></div>
            }
            <select id="comboboxPageSize" className="form-control col-md-1 text-center" value={pageSize} onChange={handleChangePageSize}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
          </select>
          </div>

        </form>
        
      </div>
    </>
  )
}
