import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import Autenticar from '../../login/Autenticar';
import { Link } from 'react-router-dom';

export default function CadastrarFeriado({usuario, autenticado}) {

  const [data, setData] = useState(new Date());
  const [descricao, setDescricao] = useState("");
  const [observacoes, setObservacoes] = useState("");


  useEffect(()=>{
    window.$(".select2bs4").select2({
      theme: "bootstrap4",
    });

    document.title = 'Cadastrar Feriado - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
  }, []);



  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(validateForm()){
      gravar();
    }
  }

  const validateForm = () => {
    return true;
  }

  const gravar = () => {
    const dados = {
      "id": null,
      "data": data.toJSON(),
      "descricao": descricao,
      "observacoes": observacoes
    }

    const token = Autenticar.getLocalToken();
            
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(dados)
    };

    const url = window.servidor + "/v1/protegido/feriado/novo";

    fetch(url, requestOptions)
        .then((response) => {
          if(response.status !== 200){
            throw new Error("Falha no cadastro do feriado.");
          } else {
            alert("Feriado cadastrado com sucesso.");
            limparInterface();
          }
          
        })
        .catch(erro => console.log(erro));
  }
  
  const limparInterface = () => {
    setData(new Date());
    setDescricao("");
    setObservacoes("");
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Cadastro de Feriado</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Cadastro de Feriado</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12" onSubmit={submitForm}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className='col-3 form-group'>
                  <label htmlFor="datepickerData">Data de Entrada</label>
                  <div className='d-flex flex-column'>
                    <DatePicker id="datepickerData" className="form-control" 
                      selected={data} onChange={(date) => {
                        setData(date);
                        // validateDate();
                      }} 
                      strictParsing
                      // onCalendarClose={() => validateDate()} 
                      aria-describedby="inputDataFeedback"
                      locale={ptBR} dateFormat="dd/MM/yyyy" required />
                  </div>
                  <div id="inputDataFeedback" className="text-sm text-danger d-none">
                    Verifique se a data de entrada está correta.
                  </div>
                </div>
                <div className="col-6">
                  <label htmlFor="inputDescricao">Descrição</label>
                  <input type="text" id="inputDescricao" className="form-control" onChange={(e)=>setDescricao(e.target.value)} value={descricao} placeholder='Insira a descrição/nome do feriado...' required/>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="inputObservacoes">Observações</label>
                  <textarea id="inputObservacoes" className="form-control" rows={3} onChange={(e)=>setObservacoes(e.target.value)} value={observacoes} placeholder='Insira aqui qualquer observação adicional...'/>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end px-3 pb-3">
                <button type="button" className="btn btn-primary mx-2" onClick={limparInterface}>Limpar</button>
                <button type="submit" className="btn btn-primary">Salvar</button>
            </div>
          </div>
        </form>
      </div>

    </>
  )
}
