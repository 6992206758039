import React, { useEffect, useState } from 'react'
import EnumUtils from '../../helpers/EnumUtils';
import Utilidades from '../../helpers/Utilidades';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Requisicao from '../../helpers/Requisicao';
import LoadingElement from '../../components/LoadingElement';
import Tippy from '@tippyjs/react';

export default function ExibirContrato({ usuario, autenticado }) {

  const navigate = useNavigate();
  const location = useLocation();

  const { contratoId } = useParams();

  const month = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];

  const [contrato, setContrato] = useState(null);

  const [listaOrgaos, setListaOrgaos] = useState([]);
  const [listaLeiLicitacao, setListaLeiLicitacao] = useState([]);
  const [listaModalidadeLicitacao, setListaModalidadeLicitacao] = useState([]);
  const [listaUnidadeGestora, setListaUnidadeGestora] = useState([]);
  const [listaTipoAditivoContrato, setListaTipoAditivoContrato] = useState([]);
  const [listaTipoAquisicao, setListaTipoAquisicao] = useState([]);
  const [listaSubtipoAquisicao, setListaSubtipoAquisicao] = useState([]);

  const [loading, setLoading] = useState(false);




  useEffect(() => {
    if (autenticado) {
      carregarListas();

      carregarContrato(contratoId);



    }

    document.title = 'Exibindo - Contratos e Aditivos - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
    // eslint-disable-next-line
  }, [autenticado, contratoId]);

  const carregarContrato = (id) => {
    setLoading(true);
    if (isNaN(Number(id))) {
      if (window.history.state && window.history.state?.idx > 1) {
        navigate(-1, { replace: true });
      } else {
        navigate('/', { state: { referrer: location }, replace: true });
      }
    } else {
      Requisicao.getContrato(id)
        .then(data => {
          // console.log('contrato', data)
          if (data != null) {
            setContrato(data);
            setLoading(false);
          } else {
            alert("Não foi possível encontrar este contrato.")
            if (window.history.state && window.history.state?.idx > 1) {
              navigate(-1);
            } else {
              navigate('/', { state: { referrer: location }, replace: true });
            }
          }
        })
        .catch((erro) => console.log(erro));
    }
  }

  const carregarListas = () => {
    Requisicao.getListaOrgaos().then((data) => setListaOrgaos(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaLeisLicitacao().then((data) => setListaLeiLicitacao(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaModalidadesLicitacao().then((data) => setListaModalidadeLicitacao(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaUnidadesGestoras().then((data) => setListaUnidadeGestora(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaTipoAditivoContrato().then((data) => setListaTipoAditivoContrato(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaTipoAquisicao().then((data) => setListaTipoAquisicao(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaSubtipoAquisicao().then((data) => setListaSubtipoAquisicao(data)).catch(erro => console.log('erro', erro));
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Exibindo Aditivo/Contrato</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Exibindo Aditivo/Contrato</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div>
                <h4>Informações Gerais</h4>
              </div>
              <hr />
              {
                loading ? <LoadingElement /> :
                  <>
                    {
                      contrato != null ?
                        <>
                          <div className='d-flex flex-column flex-md-row px-2'>
                            <div className='col col-md-6'>
                              <label>Número do Processo</label>
                              <p className=''>
                                {contrato.numeroProcesso}
                              </p>
                            </div>
                            <div className='col col-md-6'>
                              <div className='col px-0'>
                                <label>
                                  Número do Processo Mãe&nbsp;
                                  <Tippy content={`Número do(s) processo(s) anterior(es) referente(s) à este contrato/aditivo.`}>
                                    <i className='fas fa-info-circle text-info'></i>
                                  </Tippy>
                                </label>
                                <p>
                                  {contrato.numeroProcessoMae}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex flex-column flex-md-row my-2 px-2">
                            <div className="col col-md-6">
                              <label>Órgão Solicitante</label>
                              <p>
                                {EnumUtils.getNomeEnum(contrato.orgaoResponsavel, listaOrgaos)}
                              </p>
                            </div>
                            <div className="col col-md-6">
                              <label>Unidade Gestora</label>
                              <p>
                                {EnumUtils.getNomeEnum(contrato.unidadeGestora, listaUnidadeGestora)}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex flex-column flex-md-row my-2 px-2">
                            <div className="col col-md-6">
                              <label>Lei de Licitações/Serviços</label>
                              <p>
                                {EnumUtils.getNomeEnum(contrato.leiLicitacao, listaLeiLicitacao)}
                              </p>
                            </div>
                            <div className="col col-md-6">
                              <label>Modalidades de Licitações/Procedimentos</label>
                              <p>
                                {EnumUtils.getNomeEnum(contrato.modalidadeLicitacao, listaModalidadeLicitacao)}
                              </p>
                            </div>
                          </div>
                          <div className='d-flex flex-column flex-md-row my-2 px-2'>
                            <div className='col col-md-6'>
                              <label>Pessoa Jurídica/Física</label>
                              <p>
                                {contrato.empresa}
                              </p>
                            </div>
                            <div className='col col-md-6'>
                              <label>CNPJ/CPF</label>
                              <p>
                                {Utilidades.adicionarMascaraCPFOuCNPJ(contrato.cnpj)}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex flex-column flex-md-row my-2 px-2">
                            <div className="col col-md-6">
                              <label>Tipo de Aditivo/Contrato</label>
                              <p>
                                {
                                  (contrato.tipoAditivoContrato === 'TERMO_ADITIVO' || contrato.tipoAditivoContrato === 'TERMO_APOSTILAMENTO') &&
                                  (contrato.numAditivo != null && contrato.numAditivo !== "") ?
                                    `${contrato.numAditivo}º ` : ""
                                }
                                {EnumUtils.getNomeEnum(contrato.tipoAditivoContrato, listaTipoAditivoContrato)}
                              </p>
                            </div>
                          </div>
                          <div className='d-flex flex-column flex-md-row my-2 px-2'>
                            <div className='col col-md-4'>
                              <label>
                                {
                                  (contrato.tipoAditivoContrato != null && contrato.tipoAditivoContrato !== "-1" && contrato.tipoAditivoContrato !== "") ?
                                    `Número do(a) ${EnumUtils.getNomeEnum(contrato.tipoAditivoContrato, listaTipoAditivoContrato)}` :
                                    'Número do Contrato'
                                }
                              </label>
                              <p>
                                {contrato.numeroContrato}{(contrato.sufixoNumeroContrato != null && contrato.sufixoNumeroContrato !== "" ? `-${contrato.sufixoNumeroContrato}` : "")}
                              </p>
                            </div>
                            <div className='col col-md-4'>
                              <label>Número da Licitação</label>
                              <p>
                                {contrato.numeroLicitacao}
                              </p>
                            </div>
                            {/* <div className='col col-md-4'>
                              <label htmlFor='inputNumeroAta'>Número da Ata</label>
                              <input type='text' id='inputNumeroAta' className='form-control' placeholder='Digite o número da ata...'
                                value={numeroAta}
                                onChange={(e) => setNumeroAta(e.target.value)}></input>
                            </div> */}
                          </div>
                          <div className='d-flex flex-column flex-md-row my-2 px-2'>
                            <div className='col'>
                              <label>
                                {
                                  (contrato.tipoAditivoContrato != null && contrato.tipoAditivoContrato !== "-1" && contrato.tipoAditivoContrato !== "") ?
                                    `Objeto do(a) ${EnumUtils.getNomeEnum(contrato.tipoAditivoContrato, listaTipoAditivoContrato)}` :
                                    'Objeto'
                                }
                              </label>
                              <p>
                                {contrato.objetoContrato}
                              </p>
                            </div>
                          </div>

                          {
                            (contrato.tipoAditivoContrato === "TERMO_APOSTILAMENTO") ?
                              <div className='d-flex flex-column flex-md-row my-2 px-2'>
                                <div className='col col-md-4'>
                                  <label>Periodicidade Início</label>
                                  <p>
                                    {contrato.periodicidadeInicioMes}/{contrato.periodicidadeInicioAno}
                                  </p>
                                </div>
                                <div className='col col-md-4'>
                                  <label>Periodicidade Fim</label>
                                  <p>
                                    {contrato.periodicidadeFimMes}/{contrato.periodicidadeFimAno}
                                  </p>
                                </div>
                                <div className='col col-md-4'>
                                  <label>
                                    Valor
                                  </label>
                                  <p>
                                    {Utilidades.getValorFormatado(contrato.valor)}
                                  </p>
                                </div>
                              </div> :
                              <div className='d-flex flex-column flex-md-row my-2 px-2'>
                                <div className='col col-md-4'>
                                  <label>Data de Início</label>
                                  <p>
                                    {Utilidades.getDataFormatada(contrato.dataInicio)}
                                  </p>
                                </div>
                                <div className='col col-md-4'>
                                  <label>Data de Término</label>
                                  <p>
                                    {Utilidades.getDataFormatada(contrato.dataTermino)}
                                  </p>
                                </div>
                                <div className='col col-md-4'>
                                  <label>
                                    Valor
                                  </label>
                                  <p>
                                    {Utilidades.getValorFormatado(contrato.valor)}
                                  </p>
                                </div>
                              </div>
                          }
                          {
                            (contrato.tipoAditivoContrato === "CONTRATO_ORIGINAL") ?
                              <>
                                <div className='d-flex flex-column flex-md-row my-2 px-2'>
                                  <div className='col col-md-4'>
                                    <label>Valor Mensal</label>
                                    <p>
                                      {Utilidades.getValorFormatado(contrato.valorMensal)}
                                    </p>
                                  </div>
                                </div>
                              </> : <></>
                          }
                          {
                            (contrato.tipoAditivoContrato === "TERMO_ADITIVO") ?
                              <>
                                <div className='d-flex flex-column flex-md-row my-2 px-2'>
                                  <div className='col col-md-4'>
                                    <label>Valor Mensal</label>
                                    <p>
                                      {Utilidades.getValorFormatado(contrato.valorMensal)}
                                    </p>
                                  </div>
                                  <div className='col col-md-4'>
                                    <label>Valor Total</label>
                                    <p>
                                      {Utilidades.getValorFormatado(contrato.valorTotal)}
                                    </p>
                                  </div>
                                </div>
                                <div className='d-flex flex-column flex-md-row my-2 px-2'>
                                  <div className='col col-md-3 col-xl-4'>
                                    <label>Valor de Acréscimo</label>
                                    <p>
                                      {Utilidades.getValorFormatado(contrato.valorAcrescimo)}
                                    </p>
                                  </div>
                                  <div className='col col-md-3 col-xl-2'>
                                    <label>% de Acréscimo</label>
                                    <p>
                                      {contrato.percentualAcrescimo}%
                                    </p>
                                  </div>
                                  <div className='col col-md-3 col-xl-4'>
                                    <label>Valor de Supressão</label>
                                    <p>
                                      {Utilidades.getValorFormatado(contrato.valorSupressao)}
                                    </p>
                                  </div>
                                  <div className='col col-md-3 col-xl-2'>
                                    <label>% de Supressão</label>
                                    <p>
                                      {contrato.percentualSupressao}%
                                    </p>
                                  </div>
                                </div>
                                <div className='d-flex flex-column flex-md-row my-2 px-2'>
                                  <div className='col col-md-3'>
                                    <label>Reflexo Financeiro</label>
                                    <p>
                                      {contrato.percentualReflexoFinanceiro}%
                                    </p>
                                  </div>
                                </div>
                              </> : <></>
                          }
                          {
                            (contrato.tipoAditivoContrato === "TERMO_APOSTILAMENTO") ?
                              <>
                                <div className='d-flex flex-column flex-md-row my-2 px-2'>
                                  <div className='col col-md-4'>
                                    <label>Valor Total</label>
                                    <p>
                                      {Utilidades.getValorFormatado(contrato.valorTotal)}
                                    </p>
                                  </div>
                                  <div className='col col-md-3 col-xl-2'>
                                    <label>% Apostilamento</label>
                                    <p>
                                      {contrato.percentualApostilamento}%
                                    </p>
                                  </div>
                                  <div className='col col-md-4'>
                                    <label>Valor Atualizado do Contrato</label>
                                    <p>
                                      {Utilidades.getValorFormatado(contrato.valorAtualizadoContrato)}
                                    </p>
                                  </div>
                                </div>
                              </> : <></>
                          }
                          <div className='d-flex flex-column flex-md-row my-2 px-2'>
                            <div className='col'>
                              <label>Observações</label>
                              <p>
                                {contrato.observacoes}
                              </p>
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex flex-column flex-md-row my-2 px-2">
                            <div className="col col-md-6">
                              <label>Tipo de Aquisição</label>
                              <p>
                                {EnumUtils.getNomeEnum(contrato.tipoAquisicao, listaTipoAquisicao)}
                              </p>
                            </div>
                            <div className="col col-md-6">
                              <label>Subtipo Aquisição</label>
                              <p>
                                {EnumUtils.getNomeEnum(contrato.subtipoAquisicao, listaSubtipoAquisicao)}
                              </p>
                            </div>
                          </div>
                          {
                            contrato.subtipoAquisicao === "LOCACAO_VEICULO" ?
                              <>
                                <hr />
                                <div>
                                  <h4>Informações dos Veículos</h4>
                                </div>
                                {
                                  contrato.veiculos != null && contrato.veiculos.length > 0 ?
                                    <>
                                      <table className="table table-hover">
                                        <thead>
                                          <tr>
                                            <th scope="col" style={{ width: "5%" }}>Item</th>
                                            <th scope="col" style={{ width: "27%" }}>Tipo</th>
                                            <th scope="col" style={{ width: "12%" }}>Ano/Modelo</th>
                                            <th scope="col" style={{ width: "8%" }}>QTD. (Licitação)</th>
                                            <th scope="col" style={{ width: "8%" }}>QTD. (Recebida)</th>
                                            <th scope="col" style={{ width: "12%" }}>Tempo máx. uso</th>
                                            <th scope="col" style={{ width: "12%" }}>Tempo máx. reposição</th>
                                            <th scope="col" style={{ width: "8%" }}>Incluso Combustível</th>
                                            <th scope="col" style={{ width: "8%" }}>Incluso Motorista</th>
                                          </tr>
                                        </thead>
                                        <tbody className="align-middle">
                                          {/* ordenar veículos por ordem */}
                                        {
                                          contrato.veiculos && contrato.veiculos.map((veiculo) => {
                                            return <tr key={veiculo.ordem}>
                                              <td>
                                                {veiculo.ordem}
                                              </td>
                                              <td>
                                                {veiculo.tipo}
                                              </td>
                                              <td>
                                                {veiculo.anoModelo}
                                              </td>
                                              <td>
                                                {veiculo.qtdLicitacao}
                                              </td>
                                              <td>
                                                {veiculo.qtdRecebida}
                                              </td>
                                              <td>
                                                {veiculo.tempoUsoMax}
                                              </td>
                                              <td>
                                                {veiculo.tempoReposicaoMax}
                                              </td>
                                              <td>
                                                {veiculo?.combustivelIncluso === true ? "SIM" : "NÃO"}
                                              </td>
                                              <td>
                                                {veiculo?.motoristaIncluso === true ? "SIM" : "NÃO"}
                                              </td>
                                            </tr>
                                          })}
                                        </tbody>
                                      </table>
                                    </> : <></>
                                }
                              </> : <></>
                          }

                          {
                            contrato.subtipoAquisicao === "LOCACAO_IMOVEL" ?
                              <>
                                <hr />
                                <div>
                                  <h4>Informações dos Imóveis</h4>
                                </div>
                                {
                                  contrato.imoveis != null && contrato.imoveis.length > 0 ?
                                    <>
                                      <table className="table table-hover">
                                        <thead>
                                          <tr>
                                            <th scope="col" style={{ width: "5%" }}>Item</th>
                                            <th scope="col" style={{ width: "25%" }}>Endereço</th>
                                            <th scope="col" style={{ width: "17%" }}>Proprietário</th>
                                            <th scope="col" style={{ width: "25%" }}>Objeto / Destinação</th>
                                            <th scope="col" style={{ width: "8%" }}>Valor Mensal</th>
                                            <th scope="col" style={{ width: "20%" }}>Observações</th>
                                          </tr>
                                        </thead>
                                        <tbody className="align-middle">
                                        {
                                          contrato.imoveis && contrato.imoveis.map((imovel) => {
                                            return <tr key={imovel.ordem}>
                                              <td>
                                                  {imovel.ordem}
                                              </td>
                                              <td>
                                                {imovel.endereco}
                                              </td>
                                              <td>
                                                {imovel.proprietario}
                                              </td>
                                              <td>
                                                {imovel.objetoDestinacao}
                                              </td>
                                              <td>
                                                {EnumUtils.getValorFormatado(imovel.valorMensal)}
                                              </td>
                                              <td>
                                                {imovel.observacao}
                                              </td>
                                            </tr>
                                          })}
                                        </tbody>
                                      </table>
                                    </> : <></>
                                }
                              </> : <></>
                          }

                          <hr />
                          <div className='d-flex flex-column flex-md-row my-2 px-2'>
                            <div className='col col-md-6'>
                              <label>Data da Assinatura</label>
                              <p>
                                {Utilidades.getDataFormatada(contrato.dataAssinatura)}
                              </p>
                            </div>
                            <div className='col col-md-6'>
                              <label>Data de Publicação no Diário Oficial</label>
                              <p>
                                {Utilidades.getDataFormatada(contrato.dataPublicacaoDiarioOficial)}
                              </p>
                            </div>
                          </div>

                          <hr />
                          <div className='d-flex flex-column flex-md-row my-2 px-2'>
                            <div className='col col-md-6'>
                              <label>Data Limite de Envio ao TCE</label>
                              <p className='text-center text-bold'>
                                {Utilidades.getDataFormatada(contrato.dataLimiteEnvioTCE)}
                              </p>
                            </div>
                            <div className='col col-md-6'>
                              <label>Data de Lançamento no SIGFIS</label>
                              <p>
                                {Utilidades.getDataFormatada(contrato.dataPublicacaoDiarioOficial)}
                              </p>
                            </div>
                          </div>
                        </> : <></>
                    }
                  </>
              }
              <br />
            </div>
          </div>
        </form>
      </div>

    </>
  )
}
