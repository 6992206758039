import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import Utilidades from '../../helpers/Utilidades';
import EnumUtils from '../../helpers/EnumUtils';
import Tippy from '@tippyjs/react';
import { isValid, parseISO } from 'date-fns';

export default function PublicacaoAviso({ publicacao, collapsed, onChangePublicacao, deletarPublicacao, moveUp, moveDown,
  listaTipoAvisoLicitacao, listaLeiLicitacao, listaModalidadeLicitacao, listaCriterioJulgamento, listaTipoAjudicacao, listaRegimeExecucao}) {

  const key = publicacao.ordem;

  const [leiLicitacao, setLeiLicitacao] = useState("-1");
  const [modalidadeLicitacao, setModalidadeLicitacao] = useState("-1");
  const [tipoAvisoLicitacao, setTipoAvisoLicitacao] = useState("-1");
  const [criterioJulgamento, setCriterioJulgamento] = useState("-1");
  const [tipoAjudicacao, setTipoAjudicacao] = useState("-1");
  const [regimeExecucao, setRegimeExecucao] = useState("-1");

  useEffect(() => {
    window.$(".select2bs4").select2({
      theme: "bootstrap4",
    });
  }, []);

  useEffect(() => {
    window.$(`#comboboxLeiLicitacao-${key}`).on('change', function(e) {
      setLeiLicitacao(e.target.value);
    });

    window.$(`#comboboxModalidadeLicitacao-${key}`).on('change', function(e) {
      setModalidadeLicitacao(e.target.value);
    });

    window.$(`#comboboxTipoAviso-${key}`).on('change', function(e) {
      setTipoAvisoLicitacao(e.target.value);
    });

    window.$(`#comboboxCriterioJulgamento-${key}`).on('change', function(e) {
      setCriterioJulgamento(e.target.value);
    });
    
    window.$(`#comboboxTipoAjudicacao-${key}`).on('change', function(e) {
      setTipoAjudicacao(e.target.value);
    });

    window.$(`#comboboxRegimeExecucao-${key}`).on('change', function(e) {
      setRegimeExecucao(e.target.value);
    });

  }, [key])

  useEffect(() => {
    if(leiLicitacao != null && leiLicitacao !== "-1"){
      onChangePublicacao({
        ...publicacao,
        leiLicitacao: leiLicitacao
      })
      setModalidadeLicitacao("-1");
    } else {
      onChangePublicacao({
        ...publicacao,
        leiLicitacao: ""
      })
    }
    // eslint-disable-next-line
  }, [leiLicitacao])

  useEffect(() => {
    if(modalidadeLicitacao != null && modalidadeLicitacao !== "-1"){
      onChangePublicacao({
        ...publicacao,
        modalidadeLicitacao: modalidadeLicitacao
      })
    } else {
      onChangePublicacao({
        ...publicacao,
        modalidadeLicitacao: ""
      })
    }
    // eslint-disable-next-line
  }, [modalidadeLicitacao])

  useEffect(() => {
    if(tipoAvisoLicitacao != null && tipoAvisoLicitacao !== "-1"){
      onChangePublicacao({
        ...publicacao,
        tipoAvisoLicitacao: tipoAvisoLicitacao
      })
    } else {
      onChangePublicacao({
        ...publicacao,
        tipoAvisoLicitacao: ""
      })
    }
    // eslint-disable-next-line
  }, [tipoAvisoLicitacao])

  useEffect(() => {
    if(criterioJulgamento != null && criterioJulgamento !== "-1"){
      onChangePublicacao({
        ...publicacao,
        criterioJulgamento: criterioJulgamento
      })
    } else {
      onChangePublicacao({
        ...publicacao,
        criterioJulgamento: ""
      })
    }
    // eslint-disable-next-line
  }, [criterioJulgamento])

  useEffect(() => {
    if(tipoAjudicacao != null && tipoAjudicacao !== "-1"){
      onChangePublicacao({
        ...publicacao,
        tipoAjudicacao: tipoAjudicacao
      })
    } else {
      onChangePublicacao({
        ...publicacao,
        tipoAjudicacao: ""
      })
    }
    // eslint-disable-next-line
  }, [tipoAjudicacao])

  useEffect(() => {
    if(regimeExecucao != null && regimeExecucao !== "-1"){
      onChangePublicacao({
        ...publicacao,
        regimeExecucao: regimeExecucao
      })
    } else {
      onChangePublicacao({
        ...publicacao,
        regimeExecucao: ""
      })
    }
    // eslint-disable-next-line
  }, [regimeExecucao])

  // preencher comboboxLeiLicitacao
  useEffect(() => {
    window.$(`#comboboxLeiLicitacao-${key}`).empty();
    let combobox = document.querySelector(`#comboboxLeiLicitacao-${key}`).options;

    combobox.add(new Option("Selecione a Lei da Licitação...", "-1"));
    listaLeiLicitacao?.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    combobox[0].disabled = true;
    combobox.selectedIndex = 0;

    if(publicacao?.leiLicitacao != null){
      for (let i = 0; i < combobox.length; i++) {
        if(publicacao?.leiLicitacao === combobox[i].value){
          window.$(`#comboboxLeiLicitacao-${key}`).val(combobox[i].value).trigger("change");
        }
      }
    }
  }, [listaLeiLicitacao, key, publicacao?.leiLicitacao]);

  // Preencher e atualizar comboboxModalidadeLicitacao sempre que comboboxLeiLicitacao muda
  useEffect(() => {
    window.$(`#comboboxModalidadeLicitacao-${key}`).empty();
    let combobox = document.querySelector(`#comboboxModalidadeLicitacao-${key}`).options;

    combobox.add(new Option("Selecione a Modalidade da Licitação/Procedimento...", "-1"));
    listaModalidadeLicitacao?.forEach((element) => {
      if(leiLicitacao === element.leiValue){
        combobox.add(new Option(element.nome, element.value));
      }
    });

    combobox[0].disabled = true;
    combobox.selectedIndex = 0;

    if(publicacao?.modalidadeLicitacao != null){
      for (let i = 0; i < combobox.length; i++) {
        if(publicacao?.modalidadeLicitacao === combobox[i].value){
          window.$(`#comboboxModalidadeLicitacao-${key}`).val(combobox[i].value).trigger("change");
        }
      }
    }
  }, [leiLicitacao, listaModalidadeLicitacao, key, publicacao?.modalidadeLicitacao]);

  // preencher comboboxTipoAviso
  useEffect(() => {
    window.$(`#comboboxTipoAviso-${key}`).empty();
    let combobox = document.querySelector(`#comboboxTipoAviso-${key}`).options;

    combobox.add(new Option("Selecione o Tipo do Aviso...", "-1"));
    listaTipoAvisoLicitacao?.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    combobox[0].disabled = true;
    combobox.selectedIndex = 0;

    if(publicacao?.tipoAvisoLicitacao != null){
      for (let i = 0; i < combobox.length; i++) {
        if(publicacao?.tipoAvisoLicitacao === combobox[i].value){
          window.$(`#comboboxTipoAviso-${key}`).val(combobox[i].value).trigger("change");
        }
      }
    }

  }, [listaTipoAvisoLicitacao, key, publicacao?.tipoAvisoLicitacao]);

  // preencher comboboxCriterioJulgamento
  useEffect(() => {
    window.$(`#comboboxCriterioJulgamento-${key}`).empty();
    let combobox = document.querySelector(`#comboboxCriterioJulgamento-${key}`).options;

    combobox.add(new Option("Selecione os Critérios de Julgamento / Tipo de Licitação...", "-1"));
    listaCriterioJulgamento?.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    combobox[0].disabled = true;
    combobox.selectedIndex = 0;

    if(publicacao?.criterioJulgamento != null){
      for (let i = 0; i < combobox.length; i++) {
        if(publicacao?.criterioJulgamento === combobox[i].value){
          window.$(`#comboboxCriterioJulgamento-${key}`).val(combobox[i].value).trigger("change");
        }
      }
    }

  }, [listaCriterioJulgamento, key, publicacao?.criterioJulgamento]);

  // preencher comboboxTipoAjudicacao
  useEffect(() => {
    window.$(`#comboboxTipoAjudicacao-${key}`).empty();
    let combobox = document.querySelector(`#comboboxTipoAjudicacao-${key}`).options;

    combobox.add(new Option("Selecione o Tipo de Ajudicação...", "-1"));
    listaTipoAjudicacao?.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    combobox[0].disabled = true;
    combobox.selectedIndex = 0;

    if(publicacao?.tipoAjudicacao != null){
      for (let i = 0; i < combobox.length; i++) {
        if(publicacao?.tipoAjudicacao === combobox[i].value){
          window.$(`#comboboxTipoAjudicacao-${key}`).val(combobox[i].value).trigger("change");
        }
      }
    }

  }, [listaTipoAjudicacao, key, publicacao?.tipoAjudicacao]);

  // preencher comboboxRegimeExecucao
  useEffect(() => {
    window.$(`#comboboxRegimeExecucao-${key}`).empty();
    let combobox = document.querySelector(`#comboboxRegimeExecucao-${key}`).options;

    combobox.add(new Option("Selecione o Regime de Execução...", "-1"));
    combobox.add(new Option("(Em Branco)", "-1"));
    listaRegimeExecucao?.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    combobox[0].disabled = true;
    combobox.selectedIndex = 0;

    if(publicacao?.regimeExecucao != null){
      for (let i = 0; i < combobox.length; i++) {
        if(publicacao?.regimeExecucao === combobox[i].value){
          window.$(`#comboboxRegimeExecucao-${key}`).val(combobox[i].value).trigger("change");
        }
      }
    }

  }, [listaRegimeExecucao, key, publicacao?.regimeExecucao]);

  const addTwoWorkingDaysToDate = (date) => {

    let newDate = new Date(date);
    let addedWorkingDays = 0;
    let addedDays = 0;
    while(addedWorkingDays < 2){
      newDate.setDate(newDate.getDate() + 1);
      if([1,2,3,4,5].includes(newDate.getDay())){
        // let feriados = await Requisicao.getFeriadosPorData(newDate);
        // console.log('await feriados', await feriados)
        // if(!(feriados != null && feriados?.length > 0)){
          addedWorkingDays++;
        // }
        // console.log('newDate', newDate)
      }
      addedDays++;
      if(addedDays > 15){
        return date;
      }
    }
    // console.log('newDate.constructor', newDate.constructor)
    return newDate;
  }

  const adicionarDoisDiasUteis = (date) => {
    return new Promise((resolve, reject) => {
      Utilidades.adicionarDiasUteis(date, 2)
        .then(data => {
          if(isValid(parseISO(data))){
            resolve(parseISO(data));
          } else {
            resolve(addTwoWorkingDaysToDate(date));
          }
        })
        .catch(erro => {
          console.log('erro', erro);
          reject(addTwoWorkingDaysToDate(date));
        })
    })

  }


  const handleDeletarPublicacao = () => {
    if(window.confirm("Deseja excluir esta publicação?") === true){
      deletarPublicacao(publicacao);
    }
  }

  return (
    <>
      <div className="col">
        <div id={`card-publicacao-${key}`} className={(collapsed) ? "card card-navy collapsed-card" : "card card-navy"}  >
        {/* <div  className={"card card-navy"}  > */}
          {/* <div className="card-header" data-card-widget="collapse" style={{ cursor: "pointer" }}> */}
          <div className="card-header">
            <h3 className="card-title">
              {(tipoAvisoLicitacao != null && tipoAvisoLicitacao !== "-1" && tipoAvisoLicitacao !== "") ? EnumUtils.getNomeEnum(tipoAvisoLicitacao, listaTipoAvisoLicitacao)  + " - " + Utilidades.fromDateToLocalStringShort(publicacao?.dataAviso) : "Nova Publicação"}
            </h3>
            <div className="card-tools">
              <Tippy content={(collapsed) ? "Expandir" : "Minimizar"}>
                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                  <i className={(collapsed) ? "fas fa-plus" : "fas fa-minus"} />
                  {/* <i className={"fas fa-minus"} /> */}
                </button>
              </Tippy>
              <Tippy content="Mover para cima">
                <button className="btn btn-tool" type='button' onClick={() => moveUp(publicacao)}><i className='fas fa-chevron-up'></i></button>
              </Tippy>
              <Tippy content="Mover para baixo">
                <button className="btn btn-tool" type='button' onClick={() => moveDown(publicacao)}><i className='fas fa-chevron-down'></i></button>
              </Tippy>
              <Tippy content="Deletar">
                <button className="btn btn-tool" type='button' onClick={() => handleDeletarPublicacao()}><i className='fa fa-trash'></i></button>
              </Tippy>
            </div>
          </div>
          <div className="card-body">
            <div className='d-flex flex-column flex-md-row my-2'>
              <div className="form-group col col-md-6">
                <label htmlFor={`comboboxTipoAviso-${key}`}>Tipo do Aviso</label>
                <select id={`comboboxTipoAviso-${key}`} className="select2bs4" style={{ width: "100%" }} aria-describedby={`comboboxTipoAvisoFeedback-${key}`}>
                </select>
                <div id={`comboboxTipoAvisoFeedback-${key}`} className="invalid-feedback">Selecione o Tipo do Aviso.</div>
              </div>
              <div className='form-group col col-lg-6'>
                <label htmlFor={`datepickerDataProcedimentoLicitatorio-${key}`}>Data do Procedimento (Licitatório)</label>
                <div className='d-flex flex-column'>
                  <DatePicker id={`datepickerDataProcedimentoLicitatorio-${key}`} className="form-control col"
                    selected={publicacao?.dataProcedimentoLicitatorio}
                    placeholderText="Clique para selecionar..."
                    onChange={(date) => {
                      onChangePublicacao({
                        ...publicacao,
                        dataProcedimentoLicitatorio: date
                      })
                    }}
                    strictParsing
                    locale={ptBR} dateFormat="dd/MM/yyyy" required />
                </div>
              </div>
            </div>
            <div className='d-flex flex-column flex-md-row my-2'>
              <div className='form-group col col-lg-6'>
                <label htmlFor={`datepickerDataAviso-${key}`}>Data do Aviso</label>
                <div className='d-flex flex-column'>
                  <DatePicker id={`datepickerDataAviso-${key}`} className="form-control col"
                    selected={publicacao?.dataAviso}
                    placeholderText="Clique para selecionar..."
                    onChange={(date) => {
                      onChangePublicacao({
                        ...publicacao,
                        dataAviso: date
                      })
                    }}
                    strictParsing
                    locale={ptBR} dateFormat="dd/MM/yyyy" required />
                </div>
              </div>
              <div className='form-group col col-lg-6'>
                <label htmlFor={`datepickerDataPublicacaoDiarioOficial-${key}`}>Data da Publicação no Diário Oficial</label>
                <div className='d-flex flex-column'>
                  <DatePicker id={`datepickerDataPublicacaoDiarioOficial-${key}`} className="form-control col"
                    selected={publicacao?.dataPublicacaoDiarioOficial}
                    placeholderText="Clique para selecionar..."
                    onChange={async (date) => {

                      onChangePublicacao({
                        ...publicacao,
                        dataPublicacaoDiarioOficial: date,
                        dataLimiteEnvioTCE: await adicionarDoisDiasUteis(date)
                      })
                    }}
                    strictParsing
                    locale={ptBR} dateFormat="dd/MM/yyyy" required />
                </div>
              </div>
            </div>
            {/* <div className="row mx-0 my-2 px-2"> */}
            <div className="d-flex flex-column flex-md-row my-2">
              <div className='col col-md-6'>
                <label>Data limite de envio ao TCE/RJ:</label>
                <div className='text-center text-bold mt-2'>
                  {Utilidades.fromDateToLocalStringShort(publicacao?.dataLimiteEnvioTCE)}
                </div>
              </div>
              <div className='form-group col col-lg-6'>
                <label htmlFor={`datepickerDataEnvioTCE-${key}`}>Data de Envio ao TCE (SIGFIS)</label>
                <div className='d-flex flex-column'>
                  <DatePicker id={`datepickerDataEnvioTCE-${key}`} className="form-control col"
                    selected={publicacao?.dataEnvioTCE}
                    placeholderText="Clique para selecionar..."
                    onChange={(date) => {
                      onChangePublicacao({
                        ...publicacao,
                        dataEnvioTCE: date
                      })
                    }}
                    strictParsing
                    locale={ptBR} dateFormat="dd/MM/yyyy" />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row my-2">
              <div className="col col-md-6">
                <label htmlFor={`comboboxLeiLicitacao-${key}`}>Lei de Licitações/Serviços</label>
                <select id={`comboboxLeiLicitacao-${key}`} className="form-control select2bs4" style={{ width: '100%' }}>
                </select>
              </div>
              <div className="col col-md-6">
                <label htmlFor={`comboboxModalidadeLicitacao-${key}`}>Modalidades de Licitações/Procedimentos</label>
                <select id={`comboboxModalidadeLicitacao-${key}`} className="form-control select2bs4" defaultValue={"placeholder"} style={{ width: '100%' }}>
                </select>
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row">
              <div className="col col-md-4">
                <label htmlFor={`comboboxCriterioJulgamento-${key}`}>Critérios de Julgamento</label>
                <select id={`comboboxCriterioJulgamento-${key}`} className="form-control select2bs4" style={{ width: '100%' }}>
                </select>
              </div>
              <div className="col col-md-4">
                <label htmlFor={`comboboxTipoAjudicacao-${key}`}>Tipo de Ajudicação</label>
                <select id={`comboboxTipoAjudicacao-${key}`} className="form-control select2bs4" defaultValue={"placeholder"} style={{ width: '100%' }}>
                </select>
              </div>
              <div className="col col-md-4">
                <label htmlFor={`comboboxRegimeExecucao-${key}`}>Regime de Execução</label>
                <select id={`comboboxRegimeExecucao-${key}`} className="form-control select2bs4" defaultValue={"placeholder"} style={{ width: '100%' }}>
                </select>
              </div>
            </div>
            <div className='row mx-0 my-2'>
              <div className='col'>
                <span className='text-bold'>Participação: </span>
                <div className='form-group form-check'>
                  <input type='checkbox' id={`checkboxME_EPP-${key}`} className='form-check-input' 
                    checked={publicacao?.me_epp ?? false} onChange={(e) => {
                      onChangePublicacao({
                        ...publicacao,
                        me_epp: e.target.checked
                      })
                    }}></input>
                  <label htmlFor={`checkboxME_EPP-${key}`} className='form-check-label'>ME/EPP</label>
                </div>
              </div>
            </div>
            <div className='row mx-0 my-2 px-2'>
              <label htmlFor={`textareaObservacoes-${key}`}>Observações</label>
              <textarea id={`textareaObservacoes-${key}`} className='form-control' rows={3} placeholder='Relatar caso hajam observações sobre o Edital / Errata...'
              value={publicacao?.observacoes ?? ""} onChange={(e) => {
                onChangePublicacao({
                  ...publicacao,
                  observacoes: e.target.value,
                })
              }}></textarea>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
