import { Route, Routes} from 'react-router-dom';
import NavbarAuditoria from './NavbarAuditoria';
import SidebarAuditoria from './SidebarAuditoria';
import ControlBarAuditoria from './ControlBarAuditoria';
import FooterAuditoria from './FooterAuditoria';
import Home from '../../pages/Home';
import CadastroUnidade from '../../pages/unidade/CadastroUnidade';
import EditarUnidade from '../../pages/unidade/EditarUnidade';
import PesquisaUnidade from '../../pages/unidade/PesquisaUnidade';
import CadastroUsuario from '../../pages/usuario/CadastroUsuario';
import EditarUsuario from '../../pages/usuario/EditarUsuario';
import PesquisaUsuario from '../../pages/usuario/PesquisaUsuario';
import AlterarSenha from '../../pages/usuario/AlterarSenha';
import NotFound from '../../pages/NotFound';
import AcessoNegado from '../../pages/AcessoNegado';
import Contatos from '../../pages/ajuda/Contatos';


export default function RoutesAuditoria(props) {
  const autenticado = props.autenticado;
  const usuario = props.usuario;
  const version = props.version;

  if(autenticado && usuario?.setor === "AUDITORIA"){
    return (
        <>
          <NavbarAuditoria logout={props.logout} usuario={props.usuario}/>
          <SidebarAuditoria logout={props.logout} usuario={props.usuario}/>
  
          <Routes>
            <Route path="/" element={<Home usuario={props.usuario} autenticado={props.autenticado}/>} />
            <Route path="/unidade">
              <Route path="cadastro" element={<CadastroUnidade usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path="editar/:unidadeId" element={<EditarUnidade usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path="pesquisar" element={<PesquisaUnidade usuario={props.usuario} autenticado={props.autenticado}/>} />
            </Route>
            <Route path="/usuario">
              <Route path="cadastro" element={<CadastroUsuario usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path="editar/:usuarioId" element={<EditarUsuario usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path="pesquisar" element={<PesquisaUsuario usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path="alterarsenha" element={<AlterarSenha usuario={props.usuario} autenticado={props.autenticado}/>} />
            </Route>
            <Route path="/acessonegado" element={<AcessoNegado />}></Route>
            <Route path="/contatos" element={<Contatos logout={props.logout}/>}></Route>
            <Route path="*" element={<NotFound logout={props.logout} />}></Route>
          </Routes>
  
          <ControlBarAuditoria usuario={props.usuario} autenticado={props.autenticado}/>
          <FooterAuditoria version={version} />
        </>
    );
  } else {
    return (
      <>
        <Routes>
          <Route path="*" element={<NotFound logout={props.logout} />}></Route>
        </Routes>
      </>
    )
  }

}