import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// var arvoreiniciada = false;

export default function SidebarSFC(props) {
  const navigate = useNavigate();

  const [searchNumProcesso, setSearchNumProcesso] = useState("");
  const [irParaAnaliseId, setIrParaAnaliseId] = useState("");

  const location = useLocation();
  const usuario = props.usuario;

  // useEffect(() => {
  //   console.log("tree");
  //   const trees = window.$('[data-widget="treeview"]');
  //   if (trees.data('treeview-init') === undefined) {
  //     trees.Treeview('init');
  //     trees.data('treeview-init', true);
  //   }

  // });

  useEffect(() => {
    const trees = window.$('[data-widget="treeview"]');
    if(!window.arvoreiniciada){
      trees.Treeview('init');
      window.arvoreiniciada = true;
    }
  }, []);


  const submitSearch = (e) => {
    e.preventDefault();
    navigate("/processo/pesquisar?numero=" + searchNumProcesso);
  }

  const irParaAnalise = (e) => {
    e.preventDefault();
    navigate("/analise/" + irParaAnaliseId);
  }

  return (
    <>
      {/* Main Sidebar Container */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Início da Logo da Controladoria + nome - Brand Logo */}
        <Link to={"/"} className="brand-link">
          <img src="/logo_mun_mini.png" alt="Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.9' }} />
          <span className="brand-text font-weight-light" style={{ fontSize: "1.05rem" }}>Sistema Controle Interno</span>
        </Link>
        {/* Fim da Logo da Controladoria + nome - Brand Logo */}
        {/* Início do menu lateral da página - Sidebar */}
        <div className="sidebar">
          {/* Início da Barra de Pesquisa lateral - Acrescentei uma Div para separar melhor do nome do sistema - SidebarSearch Form */}
          <div className="d-flex">
            <form className="form-inline mt-3 pb-3 user-panel" onSubmit={submitSearch}>
              <div className="input-group">
                <input className="form-control form-control-sidebar" type="search" placeholder="Número do Processo" aria-label="Search" value={searchNumProcesso} onChange={(e) => setSearchNumProcesso(e.target.value)}/>
                <div className="input-group-append">
                  <button className="btn btn-sidebar" type='submit' >
                    <i className="fas fa-search fa-fw" style={{width: "20px"}}/>
                  </button>
                </div>
              </div>
            </form>
          </div>
          {
            (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR") ?
            <div className="d-flex">
              <form className="form-inline mt-3 pb-3 user-panel" onSubmit={irParaAnalise}>
                <div className="input-group">
                  <input className="form-control form-control-sidebar" type="search" placeholder="Insira o ID da análise" aria-label="Search" value={irParaAnaliseId} onChange={(e) => setIrParaAnaliseId(e.target.value)}/>
                  <div className="input-group-append">
                    <button className="btn btn-sidebar" type='submit'>
                      {/* <i className="fas fa-search fa-fw" /> */}
                      <i className="fas fa-chevron-right" style={{width: "20px"}}/>
                    </button>
                  </div>
                </div>
              </form>
            </div> :
            <></>
          }
          {/* Fim da Barra de Pesquisa lateral - SidebarSearch Form */}
          {/* Início dos Painéis - Sidebar Menu */}
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column nav-legacy nav-child-indent" data-widget="treeview" role="menu" data-accordion="false">
              {/* Add icons to the links using the .nav-icon class with font-awesome v5 or any other icon font library */}
              {/* Início do menu vertical identico ao menu horizontal*/}
              <li className="nav-item">
                <Link to={"/"} className="nav-link text-white">
                  <i className="nav-icon fas fa-th" />
                  <p>Início</p>
                </Link>
              </li>

              <li className="nav-item">
                <button className="nav-link btn text-white text-left" type="button">
                  <i className="nav-icon fas fa-copy" />
                  <p>
                    Processos
                    <i className="fas fa-angle-left right" />
                  </p>
                </button>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link to="/processo/novo" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Cadastrar Processo</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {
                      location?.pathname === "/processo/pesquisar" ?
                      <Link to={"/processo/pesquisar"} reloadDocument className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Pesquisar Processo</p>
                      </Link> :
                      <Link to={"/processo/pesquisar"} className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Pesquisar Processo</p>
                      </Link>
                    }
                  </li>
                </ul>
              </li>

              {
                ["ADMINISTRADOR", "SCF_ADMINISTRADOR", "AG_ADMINISTRADOR", "SUBGERAL_ADMINISTRADOR", "SCG_ADMINISTRADOR"].includes(usuario.tipoUsuario) ?
                <li className="nav-item">
                  <button className="nav-link btn text-white text-left" type="button">
                    <i className="nav-icon fas fa-copy" />
                    <p>
                      Unidades
                      <i className="fas fa-angle-left right" />
                    </p>
                  </button>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/unidade/cadastro" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Cadastrar Unidade</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {
                        location?.pathname === "/unidade/pesquisar" ?
                        <Link to={"/unidade/pesquisar"} reloadDocument className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Unidade</p>
                        </Link> :
                        <Link to={"/unidade/pesquisar"} className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Unidade</p>
                        </Link>
                      }
                    </li>
                  </ul>
                </li> :
                <></>
              }

              {
                ["ADMINISTRADOR", "SCF_ADMINISTRADOR", "AG_ADMINISTRADOR", "SUBGERAL_ADMINISTRADOR", "SCG_ADMINISTRADOR"].includes(usuario.tipoUsuario) ?
                <li className="nav-item">
                  <button className="nav-link btn text-white text-left" type="button">
                    <i className="nav-icon fas fa-copy" />
                    <p>
                      Usuários
                      <i className="fas fa-angle-left right" />
                    </p>
                  </button>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/usuario/cadastro" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Cadastrar Usuário</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {
                        location?.pathname === "/usuario/pesquisar" ?
                        <Link to={"/usuario/pesquisar"} reloadDocument className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Usuário</p>
                        </Link> :
                        <Link to={"/usuario/pesquisar"} className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Usuário</p>
                        </Link>
                      }
                    </li>
                  </ul>
                </li> :
                <></>
              }

              {
                usuario.tipoUsuario === "ADMINISTRADOR" || usuario.tipoUsuario === "SCF_ADMINISTRADOR" ?
                <li className="nav-item">
                  <button className="nav-link btn text-white text-left" type="button">
                    <i className="nav-icon fas fa-copy" />
                    <p>
                      Modelos de Análise
                      <i className="fas fa-angle-left right" />
                    </p>
                  </button>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/analise/modelo/novo" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Cadastrar Modelo</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {
                        location?.pathname === "/analise/modelo/pesquisar" ?
                        <Link to={"/analise/modelo/pesquisar"} reloadDocument className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Modelo</p>
                        </Link> :
                        <Link to={"/analise/modelo/pesquisar"} className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Modelo</p>
                        </Link>
                      }
                    </li>
                  </ul>
                </li> :
                <></>
              }

              {
                usuario.tipoUsuario === "ADMINISTRADOR" || usuario.tipoUsuario === "SCF_ADMINISTRADOR" ?
                <li className="nav-item">
                  <button className="nav-link btn text-white text-left" type="button">
                    <i className="nav-icon fas fa-copy" />
                    <p>
                      Feriados
                      <i className="fas fa-angle-left right" />
                    </p>
                  </button>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/feriados/novo" className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Cadastrar Feriado</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {
                        location?.pathname === "/analise/modelo/pesquisar" ?
                        <Link to={"/feriados/pesquisar"} reloadDocument className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Feriado</p>
                        </Link> :
                        <Link to={"/feriados/pesquisar"} className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Feriado</p>
                        </Link>
                      }
                    </li>
                  </ul>
                </li> :
                <></>
              }

              {
                (usuario?.permissoes?.includes("PERM_SCF_VER_AVISO")) ?
                <li className="nav-item">
                  <button className="nav-link btn text-white text-left" type="button">
                    <i className="nav-icon fas fa-copy" />
                    <p>
                      Avisos de Licitação
                      <i className="fas fa-angle-left right" />
                    </p>
                  </button>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      {
                        location?.pathname === "/avisolicitacao/pesquisar" ?
                        <Link to={"/avisolicitacao/pesquisar"} reloadDocument className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Avisos</p>
                        </Link> :
                        <Link to={"/avisolicitacao/pesquisar"} className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Avisos</p>
                        </Link>
                      }
                    </li>
                  </ul>
                </li> :
                <></>
              }
              {
                (usuario?.permissoes?.includes("PERM_SCF_VER_CONTRATO")) ?
                <li className="nav-item">
                  <button className="nav-link btn text-white text-left" type="button">
                    <i className="nav-icon fas fa-copy" />
                    <p>
                      Contratos
                      <i className="fas fa-angle-left right" />
                    </p>
                  </button>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      {
                        location?.pathname === "/contrato/pesquisar" ?
                        <Link to={"/contrato/pesquisar"} reloadDocument className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Contratos</p>
                        </Link> :
                        <Link to={"/contrato/pesquisar"} className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Contratos</p>
                        </Link>
                      }
                    </li>
                  </ul>
                </li> :
                <></>
              }

              {
                (usuario?.permissoes?.includes("PERM_SCF_CADASTRO_PENALIDADES") || usuario.tipoUsuario === "SCF_ADMINISTRADOR") ?
                <li className="nav-item">
                  <button className="nav-link btn text-white text-left" type="button">
                    <i className="nav-icon fas fa-copy" />
                    <p>
                      Penalidades
                      <i className="fas fa-angle-left right" />
                    </p>
                  </button>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link to="/penalidade/novo" reloadDocument className="nav-link">
                        <i className="far fa-circle nav-icon" />
                        <p>Nova Penalidade</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      {
                        location?.pathname === "/penalidade/pesquisar" ?
                        <Link to={"/penalidade/pesquisar"} reloadDocument className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Penalidades</p>
                        </Link> :
                        <Link to={"/penalidade/pesquisar"} className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Penalidades</p>
                        </Link>
                      }
                    </li>
                  </ul>
                </li> :
                <></>
              }
              <li className="nav-item">
                <Link to={"/bancoderecomendacoes"} className="nav-link text-white text-sm">
                  <i className="far fa-circle nav-icon" />
                  <p>Banco de Recomendações</p>
                </Link>
              </li>

              {/* <li className="nav-item">
                <a href="#" className="nav-link text-white">
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p>
                    Painéis de Controle
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <a href="../../index.html" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Controle de Processos</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="../../index2.html" className="nav-link">
                      <i className="fas fa-tv nav-icon"></i>
                      <p>Controle de Prazo</p>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="../../index3.html" className="nav-link">
                      <i className="far fa-circle nav-icon" />
                      <p>Análise de Riscos</p>
                    </a>
                  </li>
                </ul>
              </li> */}


              <li className="nav-item">
                <Link to={"/ajuda"} className="nav-link text-white">
                  <i className="far fa-circle nav-icon" />
                  <p>Ajuda</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/contatos"} className="nav-link text-white">
                  <i className="far fa-circle nav-icon" />
                  <p>Contatos</p>
                </Link>
              </li>
              <li className='nav-item d-lg-none'>
                <button className="nav-link btn border-0" data-widget="pushmenu"><i className="fas fa-angle-double-left"></i></button>
              </li>


              {/* <li className="nav-item">
                <Link to={"/login"} className="nav-link text-white">
                  <i className="far fa-circle nav-icon" />
                  <p>Login</p>
                </Link>
              </li>

              <li className="nav-item">
                <button className="nav-link btn text-white text-left" onClick={()=>{props.logout()}}>
                  <i className="far fa-circle nav-icon" />
                  <p>Logout</p>
                </button>
              </li> */}
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* <div className="sidebar-custom">
          <a href="#" className="btn btn-link"><i className="fas fa-cogs" /></a>
          <a href="#" className="btn btn-secondary hide-on-collapse pos-right">Help</a>
        </div> */}
        {/* /.sidebar-custom */}
        {/* /.sidebar */}
      </aside>
    </>
  )
}
