import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Autenticar from "../../login/Autenticar";
import Requisicao from "../../helpers/Requisicao";
import Utilidades from "../../helpers/Utilidades";

export default function EditarUnidade(props) {

  const usuario = props.usuario;

  const navigate = useNavigate();
  const { unidadeId } = useParams();

  const [orgaos, setOrgaos] = useState([]);

  const [setores, setSetores] = useState([]);
  const [setorUnidade, setSetorUnidade] = useState("-1");

  const [nome, setNome] = useState("");
  const [orgaoResponsavel, setOrgaoResponsavel] = useState("");
  // const [nomeOrgaoResponsavel, setNomeOrgaoResponsavel] = useState("");
  const [nomeOrdenadorDespesas, setNomeOrdenadorDespesas] = useState("");
  const [matriculaOrdenadorDespesas, setMatriculaOrdenadorDespesas] = useState("");
  const [nomeResponsavel, setNomeResponsavel] = useState("");
  const [matriculaResponsavel, setMatriculaResponsavel] = useState("");
  const [justificativa, setJustificativa] = useState("");
  const [unidadeAtiva, setUnidadeAtiva] = useState(true);
  const [decretoDesc, setDecretoDesc] = useState(false);
  const [ordenadorDespesasAtivo, setOrdenadorDespesasAtivo] = useState(true);
  const [logo, setLogo] = useState("");
  const [carimbo, setCarimbo] = useState("");

  const [unidade, setUnidade] = useState(null);


  const carregarUnidade = () => {
    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/unidade/get/" + unidadeId;

    fetch(url, requestOptions)
      .then((response) => {
        if(response.ok){
          return response.json();
        }
      })
      .then((data) => {
        // console.log('data', data);
        if(usuario.setor != null && usuario.tipoUsuario !== "ADMINISTRADOR" && usuario.setor !== data?.setor){
          navigate("/acessonegado", { replace:true });
          throw new Error("Acesso negado.");
        }
        setUnidade(data);

        setNome(data.nome ?? "");
        setSetorUnidade(data.setor ?? "-1");
        setOrgaoResponsavel(data.orgaoResponsavel ?? "-1");
        setNomeOrdenadorDespesas(data.nomeOrdenadorDespesas ?? "");
        setMatriculaOrdenadorDespesas(data.matriculaOrdenadorDespesas ?? "");
        setNomeResponsavel(data.nomeResponsavel ?? "");
        setMatriculaResponsavel(data.matriculaResponsavel ?? "");
        setUnidadeAtiva(data.unidadeAtiva ?? false);
        window.$("#checkboxHabilitarUnidade").bootstrapSwitch("state", data.unidadeAtiva ?? false);
        setDecretoDesc(data?.decretoDescentralizacao ?? false);
        window.$("#checkboxDecretoDescentralizacao").bootstrapSwitch("state", data?.decretoDescentralizacao ?? false);
        // window.$("#checkboxDecretoDescentralizacao").bootstrapSwitch("state", (data?.decretoDescentralizacao === true ? true : false));
        setOrdenadorDespesasAtivo(data.ordenadorDespesasAtivo ?? true);
        window.$("#checkboxHabilitarOrdenador").bootstrapSwitch("state", data.ordenadorDespesasAtivo ?? true);
        setJustificativa(data.justificativa ?? "");
        setLogo(data.fotoUnidade ?? "");
        setCarimbo(data.carimboPaginacao ?? "");

        // window.$('#comboboxTipoUsuario').val(data.tipoUsuario).trigger("change");
        // // setStatusUsuario(data.statusUsuario);
        // if(data.statusUsuario === "INATIVO") {
        //   window.$('#checkboxHabilitarUsuario').bootstrapSwitch('state' , false);
        // } else {
        //   if(data.statusUsuario === "ATIVO") {
        //     window.$('#checkboxHabilitarUsuario').bootstrapSwitch('state' , true);
        //   }
        // }
        // setUnidadeId(data.unidade.toString() ?? "-1");
      })
      .catch((erro) => console.log(erro));
  };

  // useEffect(()=> {
  //   if(orgaos != null && orgaos.length > 0 && nomeOrgaoResponsavel !== "" && nomeOrgaoResponsavel != null){
  //     for (let i = 0; i < orgaos.length; i++) {
  //       if(orgaos[i].nome === nomeOrgaoResponsavel){
  //         setOrgaoResponsavel(orgaos[i].value);
  //       }
  //     }
  //   }
  // }, [nomeOrgaoResponsavel, orgaos])

  const carregarOrgaos = () => {
    Requisicao.getListaOrgaos().then((data) => {
      setOrgaos(data);
    }).catch((erro) => console.log(erro));
  };

  const carregarSetores = () => {
    Requisicao.getListaSetor().then((data) => {
      setSetores(Utilidades.sortEnumPorNome(data));
    }).catch(erro => console.log('erro', erro));
  }

  const limparInterface = () => {
    setNome("");
    setNomeOrdenadorDespesas("");
    setMatriculaOrdenadorDespesas("");
    setNomeResponsavel("");
    setMatriculaResponsavel("");
    setOrdenadorDespesasAtivo(true);
    window.$("#checkboxHabilitarOrdenador").bootstrapSwitch("state", true);
    setUnidadeAtiva(true);
    window.$("#checkboxHabilitarUnidade").bootstrapSwitch("state", true);
    window.$("#checkboxDecretoDescentralizacao").bootstrapSwitch("state", false);
    setJustificativa("");
    limparLogo();
    limparCarimbo();
    setOrgaoResponsavel("");
    window.$("#comboboxOrgaos").val("-1").trigger("change");

  };

  const limparLogo = () => {
    let fileInput = document.querySelector("#inputLogoUnidade");
    fileInput.value = "";
    fileInput.dispatchEvent(new Event('change'));
    setLogo("");
  }

  const limparCarimbo = () => {
    let fileInput = document.querySelector("#inputCarimboPaginacao");
    fileInput.value = "";
    fileInput.dispatchEvent(new Event('change'));
    setCarimbo("");
  }

  const encodeFotoAsURL = (image) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = () => {
        reject();
      }
      reader.readAsDataURL(image);
    });
  }

  const encodeLogo = (event) => {
    let input = event.target;

    if (input.files[0] instanceof Blob) {
      encodeFotoAsURL(input.files[0]).then(result => {
        setLogo(result);
        // console.log('logo', result);
      });
    } else {
      setLogo("");
    }
  }

  const encodeCarimbo = (event) => {
    let input = event.target;

    if (input.files[0] instanceof Blob) {
      encodeFotoAsURL(input.files[0]).then(result => {
        setCarimbo(result);
        // console.log('carimbo', result);
      });
    } else {
      setCarimbo("");
    }
  }

  const validateForm = () => {
    let valido = true;
    document.querySelectorAll(".precisa-validar").forEach((element) => {
      if (!element.checkValidity()) {
        valido = false;
      }
    });
    return valido;
  };

  const gravar = () => {
    const dados = ordenadorDespesasAtivo
      ? {
        id: unidadeId,
        nome: nome,
        setor: setorUnidade,
        orgaoResponsavel: orgaoResponsavel,
        nomeOrdenadorDespesas: nomeOrdenadorDespesas,
        matriculaOrdenadorDespesas: matriculaOrdenadorDespesas,
        nomeResponsavel: nomeResponsavel,
        matriculaResponsavel: matriculaResponsavel,
        unidadeAtiva: unidadeAtiva,
        ordenadorDespesasAtivo: ordenadorDespesasAtivo,
        decretoDescentralizacao: decretoDesc,
        justificativa: justificativa,
        fotoUnidade: logo,
        carimboPaginacao: carimbo,
      }
      : {
        id: unidadeId,
        nome: nome,
        setor: setorUnidade,
        orgaoResponsavel: orgaoResponsavel,
        nomeOrdenadorDespesas: nomeResponsavel,
        matriculaOrdenadorDespesas: matriculaResponsavel,
        nomeResponsavel: nomeResponsavel,
        matriculaResponsavel: matriculaResponsavel,
        unidadeAtiva: unidadeAtiva,
        ordenadorDespesasAtivo: ordenadorDespesasAtivo,
        decretoDescentralizacao: decretoDesc,
        justificativa: justificativa,
        fotoUnidade: logo,
        carimboPaginacao: carimbo,
      };

    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(dados),
    };

    const url = window.servidor + "/v1/protegido/unidade/editar";

    fetch(url, requestOptions)
      .then((response) => {
        console.log('response', response)

        if (response.status === 200) {
          console.log('Gravado com sucesso.');
          alert("Unidade editada com sucesso.");
          // navigate(location.pathname);
        } else {
          alert("Erro ao editar a unidade. Verifique os dados inseridos e tente novamente.")
        }
      })
      .catch((erro) => console.log(erro));
  };

  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (validateForm()) {
      gravar();
    }

    let forms = document.getElementsByClassName("needs-validation");
    //   // Loop over them and prevent submission
    // let validation = Array.prototype.filter.call(forms, function (form) {
    // form.addEventListener('submit', function(event) {
    //   if (form.checkValidity() === false) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //   }
    //   form.classList.add('was-validated');
    // }, false);
    // if (form.checkValidity() === true) {
    //   form.classList.add('was-validated');
    // }
    // if (form.checkValidity() === false) {
    //   e.preventDefault();
    //   e.stopPropagation();
    // }
    //   form.classList.add("was-validated");
    // });
    Array.prototype.filter.call(forms, function (form) {
      form.classList.add("was-validated");
    });
  };

  useEffect(() => {
    if (usuario == null) {
      navigate("/login", { replace: true });
    } else {
      console.log('usuario.tipoUsuario', usuario.tipoUsuario)
      console.log('typeof usuario.tipoUsuario', typeof usuario.tipoUsuario)
      console.log('["ADMINISTRADOR", "SCF_ADMINISTRADOR", "AG_ADMINISTRADOR", "SUBGERAL_ADMINISTRADOR", "SCG_ADMINISTRADOR"].includes(usuario.tipoUsuario)', ["ADMINISTRADOR", "SCF_ADMINISTRADOR", "AG_ADMINISTRADOR", "SUBGERAL_ADMINISTRADOR", "SCG_ADMINISTRADOR"].includes(usuario.tipoUsuario))
      if(usuario.tipoUsuario !== "ADMINISTRADOR"){
        if (!(["SCF_ADMINISTRADOR", "AG_ADMINISTRADOR", "SUBGERAL_ADMINISTRADOR", "SCG_ADMINISTRADOR"].includes(usuario.tipoUsuario))) {
          navigate("/acessonegado", { replace: true });
          // TODO: verificar acesso por setor da unidade e setor do usuario
        } else {
          if(unidade != null && unidade?.setor !== usuario?.setor){
            navigate("/acessonegado", { replace: true });
          }
        }
      }
    }
  }, [usuario, navigate, unidade]);

  // preencher comboboxOrgaos
  useEffect(() => {
    window.$("#comboboxOrgaos").empty();
    if (orgaos != null && orgaos.length > 0) {
      let combobox = document.querySelector("#comboboxOrgaos").options;

      combobox.add(new Option("Selecione o Órgão Responsável", "-1"));
      orgaos.forEach((element) => {
        combobox.add(new Option(element.nome, element.value));
      });

      combobox[0].disabled = true;
      // combobox.selectedIndex = 0;
      if (unidade?.orgaoResponsavel != null) {
        for (let i = 0; i < combobox.length; i++) {
          if (unidade.orgaoResponsavel === combobox[i].value) {
            combobox.selectedIndex = i;
          }
        }
      } else {
        combobox.selectedIndex = 0;
      }
    }

  }, [orgaos, unidade?.orgaoResponsavel]);

  // preencher comboboxSetores
  useEffect(() => {
    window.$("#comboboxSetores").empty();
    let combobox = document.querySelector("#comboboxSetores").options;

    combobox.add(new Option("Selecione o Setor da Unidade...", "-1"));
    combobox[0].disabled = true;
    combobox.selectedIndex = 0;

    if (usuario.tipoUsuario === "ADMINISTRADOR") {
      setores.forEach((element, index) => {
        combobox.add(new Option(element.nome, element.value));
        if(element.value === unidade?.setor){
          window.$("#comboboxSetores").val(element.value).trigger("change");
        }
      });
    } else {
      let tiposUsuarioPermitidos = ["ADMINISTRADOR", "SCF_ADMINISTRADOR", "AG_ADMINISTRADOR", "SUBGERAL_ADMINISTRADOR", "SCG_ADMINISTRADOR"];
      if (tiposUsuarioPermitidos.includes(usuario.tipoUsuario)) {
        setores.forEach((element, index) => {
          if (element.value === usuario?.setor) {
            combobox.add(new Option(element.nome, element.value));
            if(element.value === unidade?.setor){
              window.$("#comboboxSetores").val(element.value).trigger("change");
            }
          }
        });
      }
    }

  }, [setores, usuario.tipoUsuario, usuario.setor, unidade?.setor]);

  useEffect(() => {
    const tiposUsuarioListaSetoresLiberada = ["ADMINISTRADOR"];
    const comboboxSetores = document.getElementById("comboboxSetores");

    if (tiposUsuarioListaSetoresLiberada.includes(usuario?.tipoUsuario)) {
      // console.log(true, props.usuario?.tipoUsuario);
      comboboxSetores.disabled = false;
    } else {
      // console.log(false, props.usuario?.tipoUsuario);
      comboboxSetores.disabled = true;
    }
  }, [usuario?.tipoUsuario]);

  useEffect(() => {
    window.$(".select2bs4").select2({
      theme: "bootstrap4",
    });
    window.$('[data-toggle="switch"]').bootstrapSwitch();
    // window.$(function () {
    //   window.bsCustomFileInput.init();
    // });
    window.bsCustomFileInput.init();

    window.$("#checkboxHabilitarOrdenador")
      .on("switchChange.bootstrapSwitch", function (event, state) {
        setOrdenadorDespesasAtivo(event.target.checked);
        if (!event.target.checked) {
          document.querySelector("#inputNomeOrdenadorDespesas").disabled = true;
          document.querySelector(
            "#inputMatriculaOrdenadorDespesas"
          ).disabled = true;
        } else {
          document.querySelector(
            "#inputNomeOrdenadorDespesas"
          ).disabled = false;
          document.querySelector(
            "#inputMatriculaOrdenadorDespesas"
          ).disabled = false;
        }
      });
    window.$("#checkboxHabilitarUnidade")
      .on("switchChange.bootstrapSwitch", function (event) {
        setUnidadeAtiva(event.target.checked);
      });

    window
      .$("#checkboxDecretoDescentralizacao")
      .on("switchChange.bootstrapSwitch", function (event) {
        setDecretoDesc(event.target.checked);
      });

    window.$("#comboboxOrgaos").on('change', function (e) {
      setOrgaoResponsavel(e.target.value);
    });

    window.$("#comboboxSetores").on('change', function (e) {
      setSetorUnidade(e.target.value);
    });

    carregarOrgaos();
    carregarUnidade();
    carregarSetores();

    document.title = 'Editar Unidade - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
    // eslint-disable-next-line
  }, []);

  const styles = {
    logo: {
      maxHeight: "100px",
      width: "auto"
    },
    carimbo: {
      maxHeight: "100px",
      width: "auto"
    }
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Edição de Unidade</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Edição de Unidade
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <form className="needs-validation" onSubmit={submitForm} noValidate>
            {/* <form onSubmit={submitForm}> */}
            {/* Nome da Unidade */}
            <div className="container-fluid">
              <div className="col">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Nome da Unidade</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group m-0">
                      <label htmlFor="inputNomeUnidade">Nome da Unidade</label>
                      <input
                        type="text"
                        id="inputNomeUnidade"
                        className="form-control precisa-validar"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        placeholder="Digite o Nome da Unidade Descentralizada"
                        aria-describedby="inputNomeUnidadeFeedback"
                        maxLength={150}
                        required
                      />
                      <div
                        id="inputNomeUnidadeFeedback"
                        className="invalid-feedback"
                      >
                        Insira o nome da Unidade.
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label htmlFor="comboboxSetores">Setor</label>
                        <select id="comboboxSetores" className="form-control select2bs4" style={{ width: '100%' }} aria-describedby="comboboxSetoresFeedback">
                        </select>
                        <div
                          id="comboboxSetoresFeedback"
                          className="invalid-feedback mb-4"
                        >
                          Selecione o setor ao qual a unidade pertence.
                        </div>
                      </div>
                      <div className="col">
                        <label htmlFor="comboboxOrgaos">Órgão Responsável</label>
                        <select id="comboboxOrgaos" className="form-control select2bs4" style={{ width: '100%' }}>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label className="my-2">Logo da Unidade:</label>
                        <div className="form-group custom-file col">
                          <input
                            className="custom-file-input"
                            type="file"
                            id="inputLogoUnidade"
                            accept="image/*"
                            onChange={(e) => encodeLogo(e)}
                          />
                          <div
                            id="inputLogoUnidadeFeedback"
                            className="invalid-feedback mb-4"
                          >
                            Selecione o logo da unidade.
                          </div>
                          <label htmlFor="inputLogoUnidade" className="custom-file-label">
                            Selecione o logo da Unidade...
                          </label>
                        </div>
                        <div className="col my-2 mx-2">
                          {(logo !== "") ? <img src={logo} style={styles.logo} alt="Logo da Unidade" /> : <></>}
                          {(logo !== "") ? <button className="btn close my-0 mx-3" onClick={limparLogo}><i className="fas fa-times"></i></button> : <></>}
                        </div>
                      </div>
                      <div className="col">
                        <label className="my-2">Carimbo de Paginação da unidade:</label>
                        <div className="form-group custom-file col">
                          <input
                            className="custom-file-input"
                            type="file"
                            id="inputCarimboPaginacao"
                            accept="image/*"
                            onChange={(e) => encodeCarimbo(e)}
                          />
                          <div
                            id="inputCarimboPaginacaoFeedback"
                            className="invalid-feedback"
                          >
                            Selecione o carimbo de paginação da unidade.
                          </div>
                          <label htmlFor="inputCarimboPaginacao" className="custom-file-label">
                            Selecione o carimbo de paginação da Unidade...
                          </label>
                        </div>
                        <div className="col my-2 mx-2">
                          {(carimbo !== "") ? <img src={carimbo} style={styles.carimbo} alt="Carimbo de Paginação da Unidade." /> : <></>}
                          {(carimbo !== "") ? <button className="btn close my-0 mx-3" onClick={limparCarimbo}><i className="fas fa-times"></i></button> : <></>}
                        </div>
                      </div>
                    </div>
                    <div className="row">


                    </div>
                  </div>
                </div>
              </div>
              {/* Ordenador de despesa e responsável */}
              <div className="col d-flex">
                {/* Ordenador de Despesas */}
                <div className="col-md-6 mx-0 pl-0">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">Ordenador de Despesas</h3>
                    </div>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="inputNomeOrdenadorDespesas">
                          Nome do Ordenador de Despesas
                        </label>
                        <input
                          type="text"
                          id="inputNomeOrdenadorDespesas"
                          className="form-control precisa-validar"
                          value={nomeOrdenadorDespesas}
                          onChange={(e) =>
                            setNomeOrdenadorDespesas(e.target.value)
                          }
                          placeholder="Digite o Nome do Ordenador de Despesas"
                          aria-describedby="inputNomeOrdenadorDespesasFeedback"
                          maxLength={150}
                          required
                        />
                        <div
                          id="inputNomeOrdenadorDespesasFeedback"
                          className="invalid-feedback"
                        >
                          Insira o nome do Ordenador de Despesas.
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputMatriculaOrdenadorDespesas">
                          Matricula do Ordenador de Despesas
                        </label>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          id="inputMatriculaOrdenadorDespesas"
                          className="form-control precisa-validar"
                          value={matriculaOrdenadorDespesas}
                          onChange={(e) =>
                            setMatriculaOrdenadorDespesas(e.target.value)
                          }
                          placeholder="Digite a Matricula do Ordenador de Despesas"
                          aria-describedby="inputMatriculaOrdenadorDespesasFeedback"
                          maxLength={20}
                          required
                        />
                        <div
                          id="inputMatriculaOrdenadorDespesasFeedback"
                          className="invalid-feedback"
                        >
                          Insira a matricula do Ordenador de Despesas.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Responsável */}
                <div className="col-md-6 mx-0 pr-0">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">Responsável</h3>
                    </div>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="inputNomeResponsavel">
                          Nome do Responsável
                        </label>
                        <input
                          type="text"
                          id="inputNomeResponsavel"
                          value={nomeResponsavel}
                          className="form-control precisa-validar"
                          onChange={(e) => setNomeResponsavel(e.target.value)}
                          placeholder="Digite o Nome do Ordenador de Despesas"
                          aria-describedby="inputNomeResponsavelFeedback"
                          maxLength={150}
                          required
                        />
                        <div
                          id="inputNomeResponsavelFeedback"
                          className="invalid-feedback"
                        >
                          Insira o Nome do Responsável.
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputMatriculaResponsavel">
                          Matricula do Responsável
                        </label>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          id="inputMatriculaResponsavel"
                          className="form-control precisa-validar"
                          value={matriculaResponsavel}
                          onChange={(e) =>
                            setMatriculaResponsavel(e.target.value)
                          }
                          placeholder="Digite a Matricula do Ordenador de Despesas"
                          aria-describedby="inputMatriculaResponsavelFeedback"
                          maxLength={20}
                          required
                        />
                        <div
                          id="inputMatriculaResponsavelFeedback"
                          className="invalid-feedback"
                        >
                          Insira a matrícula do Responsável.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Configurações */}
              <div className="col">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Configurações</h3>
                  </div>
                  <div className="card-body">
                    {/*Desabilita Ordenador de Despesas*/}
                    <div className="card-body row py-0 mb-0">
                      <div className="form-group col-4 text-center">
                        <label htmlFor="checkboxHabilitarOrdenador">
                          Habilitar Ordenador de Despesas:&nbsp;
                        </label>
                        <input
                          defaultChecked
                          id="checkboxHabilitarOrdenador"
                          type="checkbox"
                          data-toggle="switch"
                          data-size="small"
                          data-on-color="success"
                          data-on-text="SIM"
                          data-off-color="danger"
                          data-off-text="NÃO"
                          data-handle-width="10"
                        ></input>
                      </div>
                      {/*Desabilita Unidade(UDCI)*/}
                      <div className="form-group col-4 text-center">
                        <label htmlFor="checkboxHabilitarUnidade">
                          Habilitar Unidade:&nbsp;
                        </label>
                        <input
                          defaultChecked
                          id="checkboxHabilitarUnidade"
                          type="checkbox"
                          data-toggle="switch"
                          data-size="small"
                          data-on-color="success"
                          data-on-text="SIM"
                          data-off-color="danger"
                          data-off-text="NÃO"
                          data-handle-width="10"
                        ></input>
                      </div>
                      <div className="form-group col-4 text-center">
                        <label htmlFor="checkboxDecretoDescentralizacao">
                          Decreto de Descentralização:&nbsp;
                        </label>
                        <input
                          defaultChecked
                          id="checkboxDecretoDescentralizacao"
                          type="checkbox"
                          data-toggle="switch"
                          data-size="small"
                          data-on-color="success"
                          data-on-text="SIM"
                          data-off-color="danger"
                          data-off-text="NÃO"
                          data-handle-width="10"
                        ></input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Justificativa */}
              <div className="col">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Justificativa</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="inputJustificativa">Justificativa</label>
                      <textarea
                        id="inputJustificativa"
                        className="form-control precisa-validar"
                        onChange={(e) => setJustificativa(e.target.value)}
                        rows={4}
                        value={justificativa}
                        aria-describedby="inputJustificativaFeedback"
                        maxLength={1000}
                        required
                      />
                      <div
                        id="inputJustificativaFeedback"
                        className="invalid-feedback"
                      >
                        Insira a justificativa.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end px-3 pb-3">
              <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={limparInterface}
              >
                Limpar
              </button>
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </form>
        </section>
      </div>
    </>
  );
}
