import React, { useEffect, useState } from 'react'
import Autenticar from '../../login/Autenticar';
import Tippy from '@tippyjs/react';

export default function FavoritarProcesso({usuario, processo, collapsed}) {

  const [favorito, setFavorito] = useState(false);
  const [observacoes, setObservacoes] = useState("");

  const [visible, setVisible] = useState(false);

  // const [dados, setDados] = useState(null);

  // useEffect(() => {
  //   console.log('favorito', favorito);
  //   console.log('observacoes', observacoes)
  // }, [favorito, observacoes])

  useEffect(() => {
    if(usuario.tipoUsuario === "ADMINISTRADOR"){
      let processoId = processo?.id;
      const token = Autenticar.getLocalToken();
  
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/acompanhamento/processo/exists/" + processoId;
  
      fetch(url, requestOptions)
          .then((response) => {
            return response.json();
          })
          .then(data => {
            // console.log('isFavorito', data)
            setFavorito(data);
            // carregarObservacoes();
            const url2 = window.servidor + "/v1/protegido/acompanhamento/processo/getobservacoes/" + processoId;
            fetch(url2, requestOptions)
                .then((response) => {if(response.ok){return response.text();}})
                .then(data => setObservacoes(data)).catch((erro) => console.log('erro', erro));
                  })
          .catch((erro) => {
            console.log('erro', erro);
          });

    }

    return (()=> setVisible(false))

  }, [processo.id, usuario])

  // const carregarObservacoes = () => {
  //   let processoId = processo?.id;
  //   const token = Autenticar.getLocalToken();

  //   const requestOptions = {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + token,
  //     }
  //   };

  //   const url = window.servidor + "/v1/protegido/acompanhamento/processo/getobservacoes/" + processoId;
  //   fetch(url, requestOptions)
  //       .then((response) => {if(response.ok){return response.text();}})
  //       .then(data => setObservacoes(data)).catch((erro) => console.log('erro', erro));
  // }

  const adicionarFavorito = () => {
    if(!favorito && usuario.tipoUsuario === "ADMINISTRADOR"){
      const token = Autenticar.getLocalToken();

      const dados = {
        numeroProcesso: processo.numero,
        observacoes: observacoes
      }
      // console.log('dados', dados)

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(dados),
      };

      const url = window.servidor + "/v1/protegido/acompanhamento/processo/adicionar";
      
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            setFavorito(true);
            // setObservacoes("");
          } else {
            alert("Ocorreu um erro inesperado.");
          }
        })
        .catch(erro => console.log('erro', erro));

    }
  }

  const editarFavorito = () => {
    if(favorito && usuario.tipoUsuario === "ADMINISTRADOR"){
      const token = Autenticar.getLocalToken();

      const dados = {
        numeroProcesso: processo.numero,
        observacoes: observacoes
      }
      // console.log('dados', dados)

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(dados),
      };

      const url = window.servidor + "/v1/protegido/acompanhamento/processo/editar";
      
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            setFavorito(true);
            // setObservacoes("");
          } else {
            alert("Ocorreu um erro inesperado.");
          }
        })
        .catch(erro => console.log('erro', erro));

    }
  }

  const removerFavorito = () => {
    if(favorito && usuario.tipoUsuario === "ADMINISTRADOR"){
      if(window.confirm("Deseja parar de acompanhar o Processo Nº " + processo.numero + "?") === true){

        const token = Autenticar.getLocalToken();

        const dados = {
          numeroProcesso: processo.numero,
        }

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(dados),
        };

        const url = window.servidor + "/v1/protegido/acompanhamento/processo/remover";
        
        fetch(url, requestOptions)
          .then((response) => {
            if(response.ok){
              setFavorito(false);
            } else {
              alert("Ocorreu um erro inesperado.");
            }
          })
          .catch(erro => console.log('erro', erro));

        setObservacoes("");
      }
    }
  }

  // const handleChangeFavorito = (bool) => { ////////
  //   if(bool){
  //     setVisible(true);
  //   } else {
  //     if(window.confirm("Deseja parar de acompanhar o Processo Nº " + processo.numero + "?") === true){
  //       removerFavorito();
  //     }
  //   }
  // }

  useEffect(() => {
    if(collapsed){
      // setObservacoes("");
      setVisible(false);
    }
  }, [collapsed])


  const inputObservacoes = (
    <>
      <label>Observações</label>
      <textarea className="form-control my-2" rows={2} style={{ width: "320px" }} value={observacoes} onChange={(e) => setObservacoes(e.target.value)} />
      <div className="text-center my-2">
        <button type="button" className="btn btn-primary mx-2" 
          onClick={() => {
            setObservacoes("");
            setVisible(false);
          }}>Cancelar</button>
        <button type="button" className="btn btn-primary mx-2" 
          onClick={() => {
            adicionarFavorito();
            setVisible(false);
          }}>Adicionar</button>
      </div>
    </>
  );


  const inputEditObservacoes = (
    <>
      <label>Observações</label>
      <textarea className="form-control my-2" rows={2} style={{ width: "320px" }} value={observacoes} onChange={(e) => setObservacoes(e.target.value)} />
      <div className="text-center my-2">
        <button type="button" className="btn btn-danger mx-2" 
          onClick={() => {
            // setObservacoes("");
            removerFavorito();
            setVisible(false);
          }}>Excluir</button>
        <button type="button" className="btn btn-primary mx-2" 
          onClick={() => {
            // setObservacoes("");
            setVisible(false);
          }}>Cancelar</button>
        <button type="button" className="btn btn-primary mx-2" 
          onClick={() => {
            editarFavorito();
            setVisible(false);
          }}>Salvar</button>
      </div>
    </>
  );


  return (
    <>
      <div>
        {
          (favorito) ? 
          <>
            <Tippy content={(observacoes != null && observacoes !== "") ? observacoes : 'Parar de acompanhar o processo'}>
              <Tippy interactive={true} maxWidth={350} visible={visible} content={inputEditObservacoes} >
                <button className='btn text-warning' type='button' onClick={() => setVisible(true)}>
                  <i className="fas fa-star"></i>
                </button>
              </Tippy>
            </Tippy>
          </> :
          <>
            <Tippy content='Acompanhar o processo'>
              <Tippy interactive={true} maxWidth={350} visible={visible} content={inputObservacoes} >
                <button className='btn text-warning' type='button' onClick={() => setVisible(true)}>
                  <i className="far fa-star"></i>
                </button>
              </Tippy>
            </Tippy>
          </>
        }
      </div>
    </>
  )
}
