import React from 'react'

export default function ControlBarSubgeral({ usuario, autenticado }) {

  return (
    <div>
      <aside id="control-sidebar" className="control-sidebar control-sidebar-dark overflow-auto">
        <div className="p-4">
          <div className='row'>
            <h5 className='mt-2'>Em Construção</h5>
          </div>
          <br />
 
        </div>
      </aside>
    </div>
  )
}
