import React, { useEffect, useReducer, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Requisicao from '../../helpers/Requisicao';
import Utilidades from '../../helpers/Utilidades';
import { isValid, parseISO } from 'date-fns';
import Tippy from '@tippyjs/react';
import PublicacaoAviso from './PublicacaoAviso';
import Autenticar from '../../login/Autenticar';
import ExibirPublicacaoAviso from './ExibirPublicacaoAviso';
import EnumUtils from '../../helpers/EnumUtils';

let ordemPublicacao = 1;

export default function ExibirAviso({ usuario, autenticado }) {
  const { avisoId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);

  const [listaProcessos, setListaProcessos] = useState(null);
  // const [processo, setProcesso] = useState(null);
  
  const [aviso, setAviso] = useState({});
  const [publicacoes, setPublicacoes] = useState([]);
  
  const [listaOrgaos, setListaOrgaos] = useState([]);
  const [listaTipoAvisoLicitacao, setListaAvisoLicitacao] = useState([]);
  const [listaLeiLicitacao, setListaLeiLicitacao] = useState([]);
  const [listaModalidadeLicitacao, setListaModalidadeLicitacao] = useState([]);
  const [listaCriterioJulgamento, setListaCriterioJulgamento] = useState([]);
  const [listaTipoAjudicacao, setListaTipoAjudicacao] = useState([]);
  const [listaRegimeExecucao, setListaRegimeExecucao] = useState([]);
  
  
  const [numeroProcesso, setNumeroProcesso] = useState("");
  const [orgaoSolicitante, setOrgaoSolicitante] = useState("-1");
  const [objetoProcesso, setObjetoProcesso] = useState("");
  const [valorProcesso, setValorProcesso] = useState(0);
  const [numeroLicitacao, setNumeroLicitacao] = useState("");
  const [valorPortalTransparencia, setValorPortalTransparencia] = useState(0);
  const [objetoPortalTransparencia, setObjetoPortalTransparencia] = useState("");
  const [observacoes, setObservacoes] = useState("");

  
  // componentDidMount
  useEffect(() => {
    if (autenticado) {
      if (!isNaN(Number(avisoId))) {
        carregarListas();
        carregarAviso(avisoId);
      } else {
        navigate("/404", { replace:false });
      }

    }

    document.title = 'Aviso de Licitação - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
    // eslint-disable-next-line
  }, [autenticado, avisoId]);

  const carregarListas = () => {
    Requisicao.getListaOrgaos().then((data) => setListaOrgaos(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaTipoAvisoLicitacao().then((data) => setListaAvisoLicitacao(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaLeisLicitacao().then((data) => setListaLeiLicitacao(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaModalidadesLicitacao().then((data) => setListaModalidadeLicitacao(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaCriterioJulgamento().then((data) => setListaCriterioJulgamento(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaTipoAjudicacao().then((data) => setListaTipoAjudicacao(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaRegimeExecucao().then((data) => setListaRegimeExecucao(data)).catch(erro => console.log('erro', erro));
  }

  const carregarAviso = (id) => {
    setLoading(true);
    Requisicao.getAvisoLicitacao(id).then((data) => {
      setupAviso(data);
      setLoading(false);
    }).catch(erro => console.log('erro', erro));
  }

  const setupAviso = (a) => {
    
    if (a.publicacoes != null && a.publicacoes.length > 0) {
      a.publicacoes?.forEach((publicacao) => {
        publicacao.dataAviso = isValid(parseISO(publicacao?.dataAviso)) ? parseISO(publicacao?.dataAviso) : null;
        publicacao.dataPublicacaoDiarioOficial = isValid(parseISO(publicacao?.dataPublicacaoDiarioOficial)) ? parseISO(publicacao?.dataPublicacaoDiarioOficial) : null;
        publicacao.dataProcedimentoLicitatorio = isValid(parseISO(publicacao?.dataProcedimentoLicitatorio)) ? parseISO(publicacao?.dataProcedimentoLicitatorio) : null;
        publicacao.dataLimiteEnvioTCE = isValid(parseISO(publicacao?.dataLimiteEnvioTCE)) ? parseISO(publicacao?.dataLimiteEnvioTCE) : null;
        publicacao.dataEnvioTCE = isValid(parseISO(publicacao?.dataEnvioTCE)) ? parseISO(publicacao?.dataEnvioTCE) : null;
      })
      setPublicacoes(a.publicacoes.sort(Utilidades.sortPorOrdem));
    }
    setNumeroProcesso(a?.numeroProcesso ?? "");
    setOrgaoSolicitante(a?.orgaoSolicitante ?? "-1");
    setObjetoProcesso(a?.objetoProcesso ?? "");
    setValorProcesso(a?.valorProcesso ?? 0);
    setNumeroLicitacao(a?.numeroLicitacao);
    setValorPortalTransparencia(a?.valorPortalTransparencia ?? 0);
    setObjetoPortalTransparencia(a?.objetoPortalTransparencia ?? "");
    setObservacoes(a?.observacoes ?? "");

    setAviso(a);

  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Aviso de Licitação</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Aviso de Licitação</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div>
                <h4>Informações Gerais</h4>
              </div>
              <hr />
              <div className='d-flex flex-column flex-md-row px-2'>
                <div className='col col-md-6'>
                  <label>Número do Processo</label>
                  <div>{numeroProcesso}</div>
                </div>
                <div className='col-6'>
                  <label>Número da Licitação</label>
                  <div>{numeroLicitacao}</div>
                </div>
              </div>
              <div className='d-flex flex-column flex-md-row px-2 mt-2'>
                <div className="col">
                  <label>Órgão Solicitante</label>
                  <div>{EnumUtils.getNomeEnum(orgaoSolicitante, listaOrgaos)}</div>
                </div>
              </div>
              <div className='d-flex flex-column flex-md-row px-2 mt-2'>
                <div className="col-6">
                    <label>Valor (Análises do Processo)</label>
                    <div>{Utilidades.getValorFormatado(valorProcesso)}</div>
                </div>
                <div className="col-6">
                  <label>Valor (Portal da Transparência)</label>
                  <div>{Utilidades.getValorFormatado(valorPortalTransparencia)}</div>
                </div>
              </div>
              <div className='d-flex flex-column flex-md-row px-2 mt-2'>
                <div className='col'>
                    <label>Objeto do Processo</label>
                    <div>{objetoProcesso}</div>
                </div>
              </div>
              <div className='d-flex flex-column flex-md-row px-2 mt-2'>
                <div className='col'>
                    <label>Objeto (Portal da Transparência)</label>
                    <div>{objetoPortalTransparencia}</div>
                </div>
              </div>
              <hr />
              <div className='d-flex flex-column flex-md-row px-2 mt-2'>
                <div className='col'>
                    <label>Observações</label>
                    <div className='fix-linebreak'>{observacoes}</div>
                </div>
              </div>
              <br />
              <br />
              <div>
                <h4>Publicações de Aviso de Licitação</h4>
              </div>
              <hr />

              {
                publicacoes && publicacoes.map((publicacao) => (
                  <ExibirPublicacaoAviso key={publicacao.ordem} 
                    publicacao={publicacao} collapsed={(publicacao?.id == null) ? false : true}
                    listaTipoAvisoLicitacao={listaTipoAvisoLicitacao}
                    listaLeiLicitacao={listaLeiLicitacao}
                    listaModalidadeLicitacao={listaModalidadeLicitacao}
                    listaCriterioJulgamento={listaCriterioJulgamento}
                    listaTipoAjudicacao={listaTipoAjudicacao}
                    listaRegimeExecucao={listaRegimeExecucao}
                  />
                  )
                )
              }
              <hr />
            </div>
          </div>
        </form>
      </div>

    </>
  )
}
