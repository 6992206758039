import React, { useEffect, useState } from 'react'
import Tippy from '@tippyjs/react';
import BlocoDeNotas from './BlocoDeNotas';

export default function AbrirBlocoDeNotas({usuario, autenticado, }) {

  const [visible, setVisible] = useState(false);

  const blocoDeNotas = (
    <>
        <div className='d-flex flex-row justify-content-between'>
            <div className='text-bold'>Bloco de Notas</div>
            <button type="button" className="text-white my-0 py-0 btn btn-sm" 
            onClick={() => {
                setVisible(false);
            }}>&times;</button>
        </div>
        <BlocoDeNotas />
    </>
  );


  return (
    <>
      <div>
          <>
            <Tippy content={visible ? 'Fechar' : 'Bloco de Notas'} placement='bottom'>
              <Tippy interactive={true} width={350} maxWidth={350} visible={visible} content={blocoDeNotas} placement='bottom'>
                <button className="nav-link btn border-0" type="button" onClick={() => setVisible(!visible)}>
                  <i className="fas fa-clipboard" />
                </button>
              </Tippy>
            </Tippy>
          </>
      </div>
    </>
  )
}
