import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Autenticar from '../../login/Autenticar';
import "react-datepicker/dist/react-datepicker.css";
import LoadingElement from '../../components/LoadingElement';
import { isValid, parseISO } from 'date-fns';
import DatePicker from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import Requisicao from '../../helpers/Requisicao';
import Utilidades from '../../helpers/Utilidades';
import EnumUtils from '../../helpers/EnumUtils';
import Tippy from '@tippyjs/react';
import '../contratos/Contratos.css';


export default function PesquisaPenalidade({ usuario, autenticado }) {

  const [searchParams, setSearchParams] = useSearchParams();
  const orgaoResponsavelParam = searchParams.get("orgaoResponsavel") ?? "";
  const tipoPenalidadeParam = searchParams.get("tipoAquisicao") ?? "";

  const [razaoSocial, setRazaoSocial] = useState(searchParams.get("razaoSocial") ?? "");
  const [cnpj, setCnpj] = useState(searchParams.get("cnpj") ?? "");
  const [numeroProcesso, setNumeroProcesso] = useState(searchParams.get("numeroProcesso") ?? "");
  const [objeto, setObjeto] = useState(searchParams.get("objeto") ?? "");
  
  const [orgaoResponsavel, setOrgaoResponsavel] = useState("-1");
  const [tipoPenalidade, setTipoPenalidade] = useState("-1");


  const [dataTermino, setDataTermino] = useState(null);
  const dataTerminoParam = searchParams.get("dataTermino") ?? "";
  const [comparadorDataTermino, setComparadorDataTermino] = useState(searchParams.get("comparadorDataTermino") ?? "eq");


  const [listaOrgaos, setListaOrgaos] = useState([]);
  const [listaTipoPenalidade, setListaTipoPenalidade] = useState([]);


  const [resultado, setResultado] = useState({});

  const [pageSize, setPageSize] = useState("10");

  const [nenhumEncontrado, setNenhumEncontrado] = useState(false);

  const [pesquisando, setPesquisando] = useState(false);

  useEffect(() => {
    window.$(".select2bs4").select2({
      theme: "bootstrap4",
    });
    window.$("#comboboxListaOrgaos").on('change', function (e) {
      setOrgaoResponsavel(e.target.value);
    });
    window.$('#changePageModal').on('hidden.bs.modal', function (e) {
      document.getElementById("changePageModalInput").value = "";
    })
    window.$("#comboboxTipoPenalidade").on('change', function (e) {
      setTipoPenalidade(e.target.value);
    });

    carregarListaOrgaos();
    carregarListaTipoPenalidade();

    document.title = 'Pesquisar Penalidades - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
  }, []);

  useEffect(() => {
    if (searchParams != null && searchParams.toString() !== "") {
      if (searchParams.has("s") && searchParams.get("s") !== "") {
        setPageSize(searchParams.get("s"));
      }
      pesquisar();
    }
    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    if (dataTerminoParam != null && dataTerminoParam !== "" && isValid(parseISO(dataTerminoParam))) {
      setDataTermino(parseISO(dataTerminoParam));
    }
  }, [dataTerminoParam]);

  const getPagination = () => {
    let totalPaginas = parseInt(resultado.totalPaginas) ?? 0;
    let pag = []

    if (parseInt(resultado.numeroPagina) === 0) {
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Primeira</button></li>);
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Anterior</button></li>);
    } else {
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(0)}>Primeira</button></li>);
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado?.numeroPagina) - 1)}>Anterior</button></li>);
    }

    // for
    if (totalPaginas >= 9) {
      if (parseInt(resultado.numeroPagina) >= 5) {
        pag.push(<li className="page-item"><button type="button" className="page-link" data-toggle="modal" data-target="#changePageModal">...</button></li>);
      }

      for (let i = Math.max(parseInt(resultado.numeroPagina) - 4, 0); i < parseInt(resultado.numeroPagina); i++) {
        pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
      }

      pag.push(<li className="page-item active"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado.numeroPagina))}>{parseInt(resultado.numeroPagina) + 1}</button></li>);

      for (let i = parseInt(resultado.numeroPagina) + 1; i <= parseInt(resultado.numeroPagina) + 4 && i < totalPaginas; i++) {
        pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
      }

      if ((totalPaginas - parseInt(resultado.numeroPagina)) >= 6) {
        pag.push(<li className="page-item"><button type="button" className="page-link" data-toggle="modal" data-target="#changePageModal">...</button></li>);
      }
    } else {
      for (let i = 0; i < totalPaginas; i++) {
        if (i === parseInt(resultado.numeroPagina)) {
          pag.push(<li className="page-item active"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
        } else {
          pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(i)}>{i + 1}</button></li>);
        }
      }
    }

    if (parseInt(resultado?.numeroPagina) + 1 === parseInt(resultado?.totalPaginas)) {
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Próxima</button></li>);
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Última</button></li>);
    } else {
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado?.numeroPagina) + 1)}>Próxima</button></li>);
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={() => handleChangePage(parseInt(resultado?.totalPaginas) - 1)}>Última</button></li>);
    }

    return pag;
  }

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
    let params = searchParams;
    params.set("p", "0");
    params.set("s", e.target.value);
    setSearchParams(params);
  }

  const handleChangePage = (page) => {
    let params = searchParams;
    params.set("p", page);
    setSearchParams(params);
  }

  const getAllPesquisa = () => {
    let params = new URLSearchParams();

    if (razaoSocial != null && razaoSocial !== "") {
      params.set("razaoSocial", razaoSocial);
    }
    if (cnpj != null && cnpj !== "") {
      params.set("cnpj", cnpj);
    }
    if (tipoPenalidade != null && tipoPenalidade !== "" & tipoPenalidade !== "-1") {
      params.set("tipoPenalidade", tipoPenalidade);
    }
    if (orgaoResponsavel != null && orgaoResponsavel !== "" & orgaoResponsavel !== "-1") {
      params.set("orgaoResponsavel", orgaoResponsavel);
    }
    if (numeroProcesso != null && numeroProcesso !== "") {
      params.set("numeroProcesso", numeroProcesso);
    }
    if (objeto != null && objeto !== "") {
      params.set("objeto", objeto);
    }

    if (dataTermino != null && dataTermino !== "") {
      params.set("dataTermino", dataTermino.toISOString().substring(0, 10));

      if (comparadorDataTermino != null && comparadorDataTermino !== "") {
        params.set("comparadorDataTermino", comparadorDataTermino);
      }
    }

    // console.log('params.toString()', params.toString())

    if(params?.toString() !== "" || (resultado?.elementos != null && resultado?.elementos instanceof Array && resultado?.elementos?.length > 0)){
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/penalidade/pesquisa/all?" + params.toString();

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          // console.log('data', data)
          donwloadCSV(data);
        })
        .catch((erro) => console.log(erro));
    }
  }

  const pesquisar = () => {
    setNenhumEncontrado(false);
    setPesquisando(true);
    setResultado({});

    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/penalidade/pesquisa?" + searchParams.toString();

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.totalElementos === 0 || data.totalElementos === null) {
          setNenhumEncontrado(true);
        }
        setPesquisando(false);
        console.log('data', data);
        setResultado(data);
      })
      .catch((erro) => console.log(erro));
  }

  const carregarListaOrgaos = () => {
    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/unidade/getorgaos";

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setListaOrgaos(data);
      })
      .catch((erro) => console.log(erro));
  };

  const carregarListaTipoPenalidade = () => {
    Requisicao.getListaTipoPenalidade().then((data) => setListaTipoPenalidade(data)).catch(erro => console.log('erro', erro));
  }
  

  // preencher comboboxListaOrgaos
  useEffect(() => {
    window.$("#comboboxListaOrgaos").empty();
    let combobox = document.querySelector("#comboboxListaOrgaos").options;

    combobox.add(new Option("Selecione a Secretaria Solicitante", "-1"));
    listaOrgaos.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    if (listaOrgaos != null && listaOrgaos.length > 0) {
      for (let i = 0; i < combobox.length; i++) {
        if (orgaoResponsavelParam === combobox[i].value) {
          combobox.selectedIndex = i;
          setOrgaoResponsavel(orgaoResponsavelParam);
        }
      }
    } else {
      combobox.selectedIndex = 0;
    }

  }, [listaOrgaos, orgaoResponsavelParam]);

  // preencher comboboxTipoPenalidade
  useEffect(() => {
    window.$(`#comboboxTipoPenalidade`).empty();
    let combobox = document.querySelector(`#comboboxTipoPenalidade`).options;

    combobox.add(new Option("Selecione o tipo da penalidade...", "-1"));
    listaTipoPenalidade?.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    combobox[0].disabled = true;

    if (listaTipoPenalidade != null && listaTipoPenalidade.length > 0) {
      for (let i = 0; i < combobox.length; i++) {
        if (tipoPenalidadeParam === combobox[i].value) {
          combobox.selectedIndex = i;
          setTipoPenalidade(tipoPenalidadeParam);
        }
      }
    } else {
      combobox.selectedIndex = 0;
    }
  }, [listaTipoPenalidade, tipoPenalidadeParam]);

    const handlePesquisar = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let params = new URLSearchParams();

    if (razaoSocial != null && razaoSocial !== "") {
      params.set("razaoSocial", razaoSocial);
    }
    if (cnpj != null && cnpj !== "") {
      params.set("cnpj", cnpj);
    }
    if (tipoPenalidade != null && tipoPenalidade !== "" & tipoPenalidade !== "-1") {
      params.set("tipoPenalidade", tipoPenalidade);
    }
    if (orgaoResponsavel != null && orgaoResponsavel !== "" & orgaoResponsavel !== "-1") {
      params.set("orgaoResponsavel", orgaoResponsavel);
    }
    if (numeroProcesso != null && numeroProcesso !== "") {
      params.set("numeroProcesso", numeroProcesso);
    }
    if (objeto != null && objeto !== "") {
      params.set("objeto", objeto);
    }

    if (dataTermino != null && dataTermino !== "") {
      params.set("dataTerminoPunicao", dataTermino.toISOString().substring(0, 10));

      if (comparadorDataTermino != null && comparadorDataTermino !== "") {
        params.set("comparadorDataTerminoPunicao", comparadorDataTermino);
      }
    }

    params.set("p", "0");
    params.set("s", pageSize.toString());

    if (params.toString() !== searchParams.toString()) {
      setSearchParams(params);
    } else {
      pesquisar();
    }
  }



  const limparInterface = () => {
    setRazaoSocial("");
    setCnpj("");
    setNumeroProcesso("");
    setObjeto("");
    setDataTermino(null);
    setComparadorDataTermino("eq");
    window.$('#comboboxListaOrgaos').val("-1").trigger("change");
    window.$('#comboboxTipoPenalidade').val("-1").trigger("change");
    setResultado({});
    setSearchParams('');
  }

  const donwloadCSV = (data) => {

    // let csv = Utilidades.JSONToCSVConverter(resultado.elementos);
    let csv = Utilidades.JSONToCSVConverter(data);

    if(csv != null && csv !== ""){
      let link = document.createElement("a");
      link.id = "lnkDwnldLnk";

      //this part will append the anchor tag and remove it after automatic click
      document.body.appendChild(link);

      // let blob = new Blob(["\ufeff", csv], { type: 'text/csv' });
      let blob = new Blob(["\ufeff", csv]);
      // var csvUrl = window.webkitURL.createObjectURL(blob);
      var csvUrl = window.URL.createObjectURL(blob);
      // var filename =  (ReportTitle || 'UserExport') + '.csv';
      var filename =  'Penalidades.csv';
      window.$("#lnkDwnldLnk")
          .attr({
              'download': filename,
              'href': csvUrl
          });

      window.$('#lnkDwnldLnk')[0].click();
      document.body.removeChild(link);
    }
  }


  return (
    <>
      <div className="modal fade" id="changePageModal" tabIndex={-1} aria-labelledby="changePageModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <form className="modal-content" onSubmit={(e) => {
            e.preventDefault();
            let page = parseInt(document.getElementById("changePageModalInput").value);
            if (page > 0 && page <= parseInt(resultado?.totalPaginas)) {
              handleChangePage(page - 1);
              // document.getElementById("changePageModalInput").value = "";
              window.$("#changePageModal").modal("hide");
            }
          }}>
            <div className="modal-header">
              <h5 className="modal-title" id="changePageModalLabel">Insira o nº da página</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input className="form-control" id="changePageModalInput" type="number" onWheel={(e) => e.target.blur()} placeholder={`1 - ${resultado?.totalPaginas}`} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
              <button type="submit" className="btn btn-primary">Ir</button>
            </div>
          </form>
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Pesquisar Penalidades</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Pesquisar Penalidades</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12" onSubmit={handlePesquisar}>
          <div className="card">
            <div className="card-body">
              <h4 className='my-3'>Pesquisar Penalidades</h4>
              <div className='d-flex flex-column flex-md-row my-3'>
                <div className='col col-md-3'>
                  <label htmlFor='inputPesquisaNumeroProcesso'>Número do Processo</label>
                  <input id="inputPesquisaNumeroProcesso" type="text" className='form-control form-control-sm' value={numeroProcesso} onChange={(e) => setNumeroProcesso(e.target.value)} placeholder={"Digite o número do processo..."} />
                </div>
                <div className='col col-md-3'>
                  <label htmlFor='inputPesquisaRazaoSocial'>Razão Social</label>
                  <input id="inputPesquisaRazaoSocial" type="text" className='form-control form-control-sm' value={razaoSocial} onChange={(e) => setRazaoSocial(e.target.value)} placeholder={"Digite a razão social..."} />
                </div>
                <div className='col col-md-3'>
                  <label htmlFor='inputPesquisaCNPJ'>CPF / CNPJ</label>
                  <input id="inputPesquisaCNPJ" type="number" className='form-control form-control-sm' 
                    onKeyDown={(e) => {
                      if(e.key === '.' || e.key === ',' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                        e.preventDefault();
                      }
                    }}
                    onWheel={(e) => e.target.blur()}
                    value={cnpj} onChange={(e) => setCnpj(e.target.value)} placeholder={"Digite o CPF/CNPJ (apenas números)..."} />
                </div>
                <div className='col col-md-3'>
                  <label htmlFor='inputPesquisaObjeto'>Objeto</label>
                  <textarea id="inputPesquisaObjeto" rows={1} className='form-control form-control-sm d-flex flex-column' value={objeto} onChange={(e) => setObjeto(e.target.value)} placeholder={"Digite o objeto..."} />
                </div>
              </div>
              <div className='d-flex flex-column flex-md-row my-3'>
                <div className='col col-md-4 select2-sm'>
                  <label htmlFor='comboboxListaOrgaos'>Secretaria Solicitante</label>
                  <select id="comboboxListaOrgaos" className="form-control form-control-sm select2bs4" style={{ width: '100%' }}></select>
                </div>
                <div className='col col-md-4 select2-sm'>
                  <label htmlFor='comboboxTipoPenalidade'>Tipo da Penalidade</label>
                  <select id="comboboxTipoPenalidade" className="form-control form-control-sm select2bs4" style={{ width: '100%' }}></select>
                </div>
                <div className='col col-md-4'>
                  <label htmlFor='divPesquisaDataTermino'>Data Término</label>
                  <div id='divPesquisaDataTermino' className='input-group'>
                    <select id='comboboxComparadorDataTermino' className='form-control form-control-sm col-3 col-md-2 rounded-left'
                      style={{ minWidth: "70px" }}
                      value={comparadorDataTermino} onChange={(e) => setComparadorDataTermino(e.target.value)}>
                      {/* <option value=''></option> */}
                      <option value='eq'>{'='}</option>
                      <option value='lte'>{'<='}</option>
                      <option value='gte'>{'>='}</option>
                    </select>
                    <div className='col px-0'>
                      <div className='d-flex flex-column flex-grow-1'>
                        <DatePicker id="datepickerDataTermino" className="form-control form-control-sm dataTerminoInput"
                          isClearable
                          autoComplete='off'
                          placeholderText='Selecione a data de término...'
                          selected={dataTermino}
                          onChange={(date) => {
                            setDataTermino(date);
                          }}
                          strictParsing
                          showYearDropdown
                          yearDropdownItemNumber={8}
                          scrollableYearDropdown
                          dateFormatCalendar="MMMM"
                          locale={ptBR} dateFormat="dd/MM/yyyy" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-row'>
                <div className=''>
                  <button type='submit' className='btn btn-primary mx-2'>Pesquisar</button>
                  <button type='button' className='btn btn-outline-primary mx-2' onClick={limparInterface}>Limpar</button>
                </div>
                <div className="btn-group mx-4" role="group">
                  <button type="button" className="btn btn-secondary dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                    Download
                  </button>
                  <div className="dropdown-menu">
                    <button type='button' className="dropdown-item btn btn-secondary" onClick={() => getAllPesquisa()}>CSV</button>
                    {/* <Tippy content='Em construção'>
                      <button type='button' className="dropdown-item btn btn-secondary" disabled>CSV</button>
                    </Tippy> */}
                  </div>
                </div>
              </div>
              <hr />
              <div className='my-3'>
                {
                  (pesquisando) ?
                    <div className='pt-5'>
                      <LoadingElement />
                    </div> :
                    <></>
                }
                {
                  (resultado.elementos != null && resultado.elementos instanceof Array && resultado.elementos.length > 0) ?
                    <>
                      <div className='col'>
                        <table className="table table-striped table-bordered">
                          <thead>
                            <tr>
                              {/* <th>ID</th> */}
                              <th>Empresa</th>
                              <th>Processo Adm.</th>
                              <th>Processo Autuação</th>
                              <th>Tipo Penalidade</th>
                              <th>Órgão Solicitante</th>
                              <th>Data Término</th>
                              <th>Observações</th>
                              <th>Botões</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              resultado.elementos && resultado.elementos.map((penalidade) => (
                                <tr key={penalidade.id}>
                                  {/* <td>{contrato.id}</td> */}
                                  <td>{penalidade.razaoSocial}</td>
                                  <td><Link to={`/processo/pesquisar?numero=${penalidade.numeroProcessoAdministrativo}`} className="text-info">{penalidade.numeroProcessoAdministrativo}</Link></td>
                                  <td><Link to={`/processo/pesquisar?numero=${penalidade.numeroProcessoAutuacao}`} className="text-info">{penalidade.numeroProcessoAutuacao}</Link></td>
                                  <td>{EnumUtils.getNomeEnum(penalidade?.tipoPenalidade, listaTipoPenalidade)}</td>
                                  <td>{EnumUtils.getNomeEnum(penalidade?.orgaoResponsavel, listaOrgaos)}</td>
                                  <td>{isValid(parseISO(penalidade?.dataTerminoPunicao)) ? parseISO(penalidade?.dataTerminoPunicao).toLocaleDateString('pt-BR') : ""}</td>
                                  <td>{penalidade.observacoes}</td>
                                  <td className="project-actions text-right">
                                    <Link className="btn btn-warning btn-sm mr-2" to={`/penalidade/editar/${penalidade.id}`}>
                                      <i className="far fa-file-alt"></i>
                                      &nbsp;Editar
                                    </Link>
                                    <Link className="btn btn-info btn-sm mr-2" to={`/penalidade/exibir/${penalidade.id}`}>
                                      <i className="far fa-file-alt"></i>
                                      &nbsp;Exibir
                                    </Link>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </> : <></>
                }
                {
                  (nenhumEncontrado) ?
                    <p className='text-center'>Nenhum contrato encontrado</p> :
                    <></>
                }
              </div>
            </div>
            <div className='m-3 text-right'>
              <Link className="btn btn-primary" to={"/penalidade/novo"}>Nova Penalidade</Link>
            </div>
          </div>
          <div className='px-2 row justify-content-between'>
            {
              resultado?.elementos != null && resultado?.elementos.length > 0 ?
                <nav aria-label="Page navigation">
                  <ul className="pagination row px-2">
                    {

                      getPagination().map((pag, index) => {
                        return <React.Fragment key={index}>
                          {pag}
                        </React.Fragment>
                      })

                    }
                  </ul>
                </nav>
                : <div></div>
            }
            <select id="comboboxPageSize" className="form-control col-md-1 text-center" value={pageSize} onChange={handleChangePageSize}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>

        </form>

      </div>
    </>
  )
}


