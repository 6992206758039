import Tippy from '@tippyjs/react';
import { isValid, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Utilidades from '../../helpers/Utilidades';
import FavoritarProcesso from './FavoritarProcesso';
import ObservarProcesso from './ObservarProcesso';

export default function ProcessoCollapse(props) {
  const processo = props.processo;
  const nomesTipos = props.nomesTipos;
  const nomesOrgaos = props.nomesOrgaos;
  const usuario = props.usuario;

  const [collapsed, setCollapsed] = useState(true);

  // useEffect(() => {
  //   let id = "#accordion-" + processo.id;
  //   window.$(id).on('hidden.bs.collapse', function () {
  //     setCollapsed(true);
  //   })
  //   window.$(id).on('shown.bs.collapse', function () {
  //     setCollapsed(false);
  //   })
  // }, [processo])

  const isCollapsed = useCallback(() => {
    let id = "accordion-" + processo.id;
    return document.getElementById(id).classList.contains("collapsed-card");
  }, [processo.id])

  useEffect(() => {
    if (isCollapsed) {
      setCollapsed(true);
    }
  }, [isCollapsed])

  // const nomesStatus = {
  //   "EM_EDICAO": "Edição",
  //   "EM_EDICAO_REVISAO": "Edição (Verificação)",
  //   "FINALIZADA": "Finalizada"
  // }

  // console.log('nomesTipos', nomesTipos);


  return (
    <>
      <div id={`accordion-${processo.id}`} className="card collapsed-card card-secondary">
        <div className="card-header" data-card-widget="collapse" onClick={() => setCollapsed(!isCollapsed())} style={{ cursor: "pointer" }}>
          <h3 className="card-title text-bold">Processo Nº {processo.numero}</h3>
          <div className="card-tools">
            <Tippy content="Mostar análises">
              <button type="button" className="btn btn-tool" data-card-widget="collapse" title="">
                <i className="fas fa-plus" />
              </button>
            </Tippy>
          </div>
        </div>
        <div className="card-body p-0">
          <div className='d-flex flex-row justify-content-between'>
            <div className='my-3 mx-3 row flex-grow-1'>
              <div className='col'>
                <div className='my-3 col'>
                  <label>Unidade Responsável</label>
                  <p>{processo.unidade.nome}</p>
                </div>
                <div className='my-3 col'>
                  <label>Órgão Solicitante</label>
                  <p>{nomesOrgaos[processo.orgaoSolicitante]}</p>
                </div>
                <div className='my-3 col'>
                  <label>Volumes e Apensos</label>
                  <p>{processo.volumeseApensos}</p>
                </div>
              </div>
              <div className='my-3 col'>
                <label>Objeto do Processo</label>
                <p className='fix-linebreak'>{processo.objeto}</p>
              </div>
            </div>
            {
              (usuario.tipoUsuario === "ADMINISTRADOR") ?
                <div className='col flex-grow-0'>
                  <FavoritarProcesso usuario={usuario} processo={processo} collapsed={collapsed} />
                </div> :
                <></>
            }
            {
              (usuario.unidadeId === 1) ?
                <div className='col flex-grow-0'>
                  <ObservarProcesso usuario={usuario} processo={processo} collapsed={collapsed} />
                </div> :
                <></>
            }
          </div>
          <div className='d-flex flex-row justify-content-center'>
            {
              (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR" || usuario?.permissoes?.includes("PERM_SCF_EDITAR_PROCESSO")) ?
                <div className='m-2'>
                  <Link to={`/processo/editar/${processo.id}`} type='button' className='btn btn-primary'>Editar Processo</Link>
                </div> : <></>
            }
            <div className='m-2'>
              <Link to={`/processo/exibir/${processo.id}`} type='button' className='btn btn-primary'>Detalhes Processo</Link>
            </div>
            {
              (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR" || usuario?.setor === "SUBCONTROLADORIA_GERAL" || usuario?.setor === "CONSULTA") ?
                <div className='m-2'>
                  <Link to={`/avisolicitacao/pesquisar?numeroProcesso=${processo.numero}`} type='button' className='btn btn-primary'>Avisos de Licitação</Link>
                </div> : <></>
            }
            {
              (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR" || usuario?.setor === "SUBCONTROLADORIA_GERAL" || usuario?.setor === "CONSULTA") ?
                <div className='m-2'>
                  <Link to={`/contrato/pesquisar?numeroProcesso=${processo.numero}`} type='button' className='btn btn-primary'>Contratos</Link>
                </div> : <></>
            }
            {
              (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.setor === "SUBCONTROLADORIA_GERAL") ?
                <div className='m-2'>
                  <Link to={`/contrato/novo?numeroProcesso=${processo.numero}`} type='button' className='btn btn-primary'>Adicionar Contrato/Aditivo</Link>
                </div> : <></>
            }
            {
              (usuario?.setor === "FISCALIZACAO_E_CONTROLE" || usuario?.tipoUsuario === "ADMINISTRADOR") ?
                <div className='text-right m-2'>
                  <Link to={`/processo/${processo.id}/novaanalise`} type='button' className='btn btn-primary'>Nova Análise</Link>
                </div> : <></>
            }
          </div>
          {
            (usuario?.setor === "FISCALIZACAO_E_CONTROLE" || usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.setor === "CONSULTA") ?
              <>
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th colSpan={8} className="text-center">Análises</th>
                    </tr>
                    <tr>
                      <th>ID</th>
                      <th>Nome</th>
                      <th>Tipo de Análise</th>
                      <th>Data de Entrada</th>
                      <th>Data de Saída</th>
                      <th>Valor</th>
                      <th>Última Modificação</th>
                      {
                        (usuario?.setor === "FISCALIZACAO_E_CONTROLE" || usuario?.tipoUsuario === "ADMINISTRADOR") ?
                          <th>Botões</th> :
                          <></>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      processo.analises && processo.analises.map((analise) => (
                        <tr key={analise.id}>
                          <td>{analise.id}</td>
                          <td>{analise?.nome ?? "Sem informações"}</td>
                          <td>{nomesTipos[analise.tipoAnalise]}</td>
                          <td>{isValid(parseISO(analise?.dataEntrada)) ? parseISO(analise?.dataEntrada).toLocaleDateString('pt-BR') : ""}</td>
                          <td>{isValid(parseISO(analise?.dataSaida)) ? parseISO(analise?.dataSaida).toLocaleDateString('pt-BR') : ""}</td>
                          <td>{Utilidades.getValorFormatado(analise?.valorEstimado)}</td>
                          {/* <td>R$&nbsp;{analise.valorEstimado?.replaceAll(".", ",")}</td> */}
                          <td>{analise.usuarioUltimaEdicao}{isValid(parseISO(analise?.dataModificacao)) ? " - " + parseISO(analise?.dataModificacao).toLocaleString('pt-BR') : ""}</td>
                          {/* <td>{nomesStatus[analise?.statusAnalise]}</td> */}
                          {
                            (usuario?.setor === "FISCALIZACAO_E_CONTROLE" || usuario?.tipoUsuario === "ADMINISTRADOR") ?
                              <td className="project-actions text-right">
                                <Link className="btn btn-warning btn-sm mr-2" to={`/analise/${analise.id}`}>
                                  <i className="far fa-file-alt"></i>
                                  &nbsp;Abrir
                                </Link>
                              </td> :
                              <></>
                          }
                          {
                            (usuario?.setor === "CONSULTA") ?
                              <td className="project-actions text-right">
                                <Link className="btn btn-warning btn-sm mr-2" to={`/analise/visualizar/${analise.id}`}>
                                  <i className="far fa-file-alt"></i>
                                  &nbsp;Abrir
                                </Link>
                              </td> :
                              <></>
                          }
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </> : <></>
          }

          {/* {
            (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR" || usuario?.permissoes?.includes("PERM_SCF_EDITAR_PROCESSO")) ?
              <div className='d-flex flex-row justify-content-between'>
                <div className='m-2'>
                  <Link to={`/processo/editar/${processo.id}`} type='button' className='btn btn-primary'>Editar Processo</Link>
                </div>
                <div className='m-2'>
                  <Link to={`/processo/${processo.id}/novaanalise`} type='button' className='btn btn-primary'>Nova Análise</Link>
                </div>
              </div>
              :
              <>
              {
                (usuario?.setor === "FISCALIZACAO_E_CONTROLE" || usuario?.tipoUsuario === "ADMINISTRADOR") ?
                  <div className='text-right m-2'>
                    <Link to={`/processo/${processo.id}/novaanalise`} type='button' className='btn btn-primary'>Nova Análise</Link>
                  </div> :
                  <></>
              }
              </>
          } */}
        </div>
      </div>

    </>
  )
}
