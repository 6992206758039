import { Route, Routes} from 'react-router-dom';
import NavbarSFC from './NavbarSFC';
import SidebarSFC from './SidebarSFC';
import ControlBarSFC from './ControlBarSFC';
import FooterSFC from './FooterSFC';
import Home from '../../pages/Home';
import CadastroUnidade from '../../pages/unidade/CadastroUnidade';
import EditarUnidade from '../../pages/unidade/EditarUnidade';
import PesquisaUnidade from '../../pages/unidade/PesquisaUnidade';
import CadastroUsuario from '../../pages/usuario/CadastroUsuario';
import EditarUsuario from '../../pages/usuario/EditarUsuario';
import PesquisaUsuario from '../../pages/usuario/PesquisaUsuario';
import AlterarSenha from '../../pages/usuario/AlterarSenha';
import NovoProcesso from '../../pages/processo/NovoProcesso';
import EditarDadosProcesso from '../../pages/processo/EditarDadosProcesso';
import NovaAnalise from '../../pages/analise/NovaAnalise';
import PesquisaProcesso from '../../pages/processo/PesquisaProcesso';
import CadastrarFeriado from '../../pages/feriados/CadastrarFeriado';
import EditarFeriado from '../../pages/feriados/EditarFeriado';
import PesquisaFeriado from '../../pages/feriados/PesquisaFeriado';
import AnaliseInfo from '../../pages/analise/AnaliseInfo';
import NovaAnaliseVinculada from '../../pages/analise/NovaAnaliseVinculada';
import Analise from '../../pages/analise/Analise';
import AnaliseEditarDadosBasicos from '../../pages/analise/AnaliseEditarDadosBasicos';
import VisualizarAnalise from '../../pages/analise/VisualizarAnalise';
import ModeloAnalise from '../../pages/modelos/ModeloAnalise';
import EditarModeloAnalise from '../../pages/modelos/EditarModeloAnalise';
import PesquisaModelo from '../../pages/modelos/PesquisaModelos';
import FilaIndividual from '../../pages/filas/FilaIndividual';
import Fila from '../../pages/filas/Fila';
import NotFound from '../../pages/NotFound';
import AcessoNegado from '../../pages/AcessoNegado';
import Contatos from '../../pages/ajuda/Contatos';
import ExibirAviso from '../../pages/avisoslicitacao/ExibirAviso';
import PesquisaAviso from '../../pages/avisoslicitacao/PesquisaAviso';
import ExibirProcesso from '../../pages/processo/ExibirProcesso';
import PesquisaDePrecos from '../../pages/pesquisadeprecos/PesquisaDePrecos';
import ExibirContrato from '../../pages/contratos/ExibirContrato';
import PesquisaContrato from '../../pages/contratos/PesquisaContrato';
import AlertasElement from '../../navbar/AlertasElement';
import AnalisesAbertasIndividual from '../../pages/filas/AnalisesAbertasIndividual';
import EditarPenalidade from '../../pages/penalidades/EditarPenalidade';
import ExibirPenalidade from '../../pages/penalidades/ExibirPenalidade';
import PesquisaPenalidade from '../../pages/penalidades/PesquisaPenalidade';
import ProcessosObservados from '../../pages/processo/ProcessosObservados';
import BancoDeRecomendacoes from '../../pages/analise/BancoDeRecomendacoes';
import BancoDeRecomendacoesEditar from '../../pages/analise/BancoDeRecomendacoesEditar';


export default function RoutesSFC(props) {
  const autenticado = props.autenticado;
  const usuario = props.usuario;
  const version = props.version;

  if(autenticado && usuario?.setor === "FISCALIZACAO_E_CONTROLE"){
    return (
        <>
          <NavbarSFC logout={props.logout} usuario={props.usuario}/>
          <SidebarSFC logout={props.logout} usuario={props.usuario}/>
          <AlertasElement logout={props.logout} usuario={props.usuario}/>
  
          <Routes>
            <Route path="/" element={<Home usuario={props.usuario} autenticado={props.autenticado}/>} />
            <Route path="/unidade">
              <Route path="cadastro" element={<CadastroUnidade usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path="editar/:unidadeId" element={<EditarUnidade usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path="pesquisar" element={<PesquisaUnidade usuario={props.usuario} autenticado={props.autenticado}/>} />
            </Route>
            <Route path="/usuario">
              <Route path="cadastro" element={<CadastroUsuario usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path="editar/:usuarioId" element={<EditarUsuario usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path="pesquisar" element={<PesquisaUsuario usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path="alterarsenha" element={<AlterarSenha usuario={props.usuario} autenticado={props.autenticado}/>} />
            </Route>
            <Route path="/processo">
              <Route path="novo" element={<NovoProcesso usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path="editar/:processoId" element={<EditarDadosProcesso usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path="exibir/:processoId" element={<ExibirProcesso usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path=':processoId/novaanalise' element={<NovaAnalise usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path='pesquisar' element={<PesquisaProcesso usuario={props.usuario} autenticado={props.autenticado}/>} />
              {
                (usuario.unidadeId === 1) ?
                  <Route path="emobservacao" element={<ProcessosObservados usuario={props.usuario} autenticado={props.autenticado}/>} />
                  : <></>
              }
            </Route>
            <Route path='/feriados'>
              <Route path='novo' element={<CadastrarFeriado usuario={props.usuario} autenticado={props.autenticado} />} />
              <Route path='editar/:feriadoId' element={<EditarFeriado usuario={props.usuario} autenticado={props.autenticado} />} />
              <Route path='pesquisar' element={<PesquisaFeriado usuario={props.usuario} autenticado={props.autenticado} />} />
            </Route>
            <Route path='/analise'>
              <Route path=':analiseId' element={<AnaliseInfo usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path=':analiseId/novaanalisevinculada' element={<NovaAnaliseVinculada usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path='editar/:analiseId' element={<Analise usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path='editar/dadosbasicos/:analiseId' element={<AnaliseEditarDadosBasicos usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path='visualizar/:analiseId' element={<VisualizarAnalise usuario={props.usuario} autenticado={props.autenticado}/>} />
            </Route>
            <Route path='/analise/modelo'>
              <Route path='novo' element={<ModeloAnalise usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path='editar/:modeloId' element={<EditarModeloAnalise usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path='pesquisar' element={<PesquisaModelo usuario={props.usuario} autenticado={props.autenticado}/>} />
            </Route>
            <Route path='/analise/filas'>
              <Route path='individual' element={<FilaIndividual usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path='individual/abertas' element={<AnalisesAbertasIndividual usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route index element={<Fila usuario={props.usuario} autenticado={props.autenticado}/>} />
            </Route>
            <Route path='/bancoderecomendacoes'>
              <Route index element={<BancoDeRecomendacoes usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path='novo' element={<BancoDeRecomendacoesEditar usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path='editar/:recBancoId' element={<BancoDeRecomendacoesEditar usuario={props.usuario} autenticado={props.autenticado}/>} />
            </Route>
            {
              (usuario?.permissoes?.includes("PERM_SCF_VER_AVISO")) ?
              <Route path='/avisolicitacao'>
                <Route path='exibir/:avisoId' element={<ExibirAviso usuario={props.usuario} autenticado={props.autenticado} />} />
                <Route path='pesquisar' element={<PesquisaAviso usuario={props.usuario} autenticado={props.autenticado} />} />
              </Route> :
              <></>
            }
            {
              (usuario?.permissoes?.includes("PERM_SCF_VER_CONTRATO")) ?
              <Route path='/contrato'>
                <Route path='exibir/:contratoId' element={<ExibirContrato usuario={props.usuario} autenticado={props.autenticado} />} />
                <Route path='pesquisar' element={<PesquisaContrato usuario={props.usuario} autenticado={props.autenticado} />} />
              </Route> :
              <></>
            }
            {
              (usuario?.permissoes?.includes("PERM_SCF_CADASTRO_PENALIDADES") || usuario.tipoUsuario === "SCF_ADMINISTRADOR") ?
              <Route path='/penalidade'>
                <Route path='novo' element={<EditarPenalidade usuario={props.usuario} autenticado={props.autenticado} />} />
                <Route path='editar/:penalidadeId' element={<EditarPenalidade usuario={props.usuario} autenticado={props.autenticado} />} />
                <Route path='exibir/:penalidadeId' element={<ExibirPenalidade usuario={props.usuario} autenticado={props.autenticado} />} />
                <Route path='pesquisar' element={<PesquisaPenalidade usuario={props.usuario} autenticado={props.autenticado} />} />
              </Route> :
              <></>
            }
            <Route path='/pesquisadeprecos'>
              <Route path=":pesquisaId?" element={<PesquisaDePrecos usuario={props.usuario} autenticado={props.autenticado} />} />
            </Route>

            <Route path="/acessonegado" element={<AcessoNegado />}></Route>
            <Route path="/contatos" element={<Contatos logout={props.logout}/>}></Route>
            <Route path="*" element={<NotFound logout={props.logout} />}></Route>
            
          </Routes>
  
          <ControlBarSFC usuario={props.usuario} autenticado={props.autenticado}/>
          <FooterSFC version={version} />
        </>
    );
  } else {
    return (
      <>
        <Routes>
          <Route path="*" element={<NotFound logout={props.logout} />}></Route>
        </Routes>
      </>
    )
  }

}