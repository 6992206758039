import React, { useEffect, useState } from 'react'
import Tippy from '@tippyjs/react';
import SummernoteEditor from '../../../components/SummernoteEditor';


export default function Recomendacao({ recomendacao, emRevisao, onDeleteRecomendacao, onChangeRecomendacao, exibindo, isConformidade, moveUp, moveDown, idBloco, index }) {

  // const observacao = recomendacao?.observacao ?? "";

  const [comentario, setComentario] = useState("");

  const [visible, setVisible] = useState(false);

  const [editando, setEditando] = useState(false);

  const [elmtHeight, setElmtHeight] = useState(null);

  // const [textoRecomendacao, setTextoRecomendacao] = useState("");
  // const [htmlRecomendacao, setHtmlRecomendacao] = useState("");

  const show = () => {
    // setComentario(observacao);
    setVisible(true);
  }

  const hide = () => {
    handleObservacaoChange(comentario);
    setVisible(false);
  }

  const handleObservacaoChange = (obs) => {
    onChangeRecomendacao({ ...recomendacao, observacao: obs });
  }

  useEffect(() => {
    if (recomendacao.observacao != null) {
      setComentario(recomendacao.observacao)
    } else {
      setComentario("");
    }
  }, [recomendacao.observacao])

  // const handleIdCampoChange = (novoId) => {
  //   onChangeRecomendacao({...recomendacao, idCampo: novoId});
  // }

  // const handleTextoChange = (novoTexto) => {
  //   onChangeRecomendacao({...recomendacao, texto: novoTexto});
  // }

  // const handleHTMLChange = (novoHTML) => {
  //   onChangeRecomendacao({...recomendacao, html: novoHTML});
  // }

  // useEffect(()=>{
  //   onChangeRecomendacao({...recomendacao, texto: novoTexto});
  // }, [recomendacao, novoTexto, onChangeRecomendacao])

  // useEffect(()=>{
  //   onChangeRecomendacao({...recomendacao, html: novoHTML});
  // }, [recomendacao, novoHTML, onChangeRecomendacao])

  const handleEditandoChange = (bool) => {
    if (bool) {
      let arrElmts = document.querySelectorAll(`.btn-concluir-edicao-bloco-${idBloco}`);
      if (arrElmts != null && arrElmts.length > 0) {
        arrElmts.forEach(b => b?.click())
      }
      setEditando(bool);
    } else {

      let id = `textareaRecomendacao-${idBloco}-${recomendacao.idCampo}-${index}`;
      id = id.replaceAll('.', '_').replaceAll(' ', '').replace(/[!"#$%&'()*+,/:;<=>?@[\\\]^`{|}~]/g, '');
      // id = id.replaceAll('.', '_').replaceAll(' ', '').replace(/[!"#\$%&'\(\)\*\+,/:;<=>\?@\[\\\]\^`\{\|\}~]/g,'');
      // id = id.replaceAll('.', '_').replaceAll(' ', '');
      let html = window.$('#' + id).summernote('code');
      let text = null;
      if (typeof html === 'string') {
        const tempDiv = document.createElement(`div`);
        tempDiv.innerHTML = html;
        text = tempDiv.textContent;
        // console.log('text', text)
        // console.log('html', html)
      }

      if (html != null && html !== "" && text != null && text !== "") {
        onChangeRecomendacao({ ...recomendacao, texto: text, html: html });
        setEditando(bool);
      }
      // if(recomendacao?.texto != null && recomendacao?.texto !== ""){
      //   setEditando(bool);
      // }
    }
  }

  useEffect(() => {
    let h = document.getElementById(`divRecomendacao-${idBloco}-${recomendacao.idCampo}-${index}`)?.offsetHeight ?? null;
    // console.log('h', h)
    if (h != null) {
      setElmtHeight(h + 20);
    }
  }, [idBloco, recomendacao.idCampo, index])


  useEffect(() => {
    if (editando) {
      if (recomendacao?.texto == null || recomendacao?.texto === "") {
        document.getElementById(`textareaRecomendacao-${idBloco}-${recomendacao.idCampo}-${index}`)?.classList.add("is-invalid");
      } else {
        document.getElementById(`textareaRecomendacao-${idBloco}-${recomendacao.idCampo}-${index}`)?.classList.remove("is-invalid");
      }
    }
  }, [recomendacao.texto, idBloco, recomendacao.idCampo, editando, index])

  const handleChangeRecomendacaoEditor = (texto, html) => {
    onChangeRecomendacao({ ...recomendacao, texto: texto, html: html });
  }






  const inputComment = (
    <>
      <label>Adicionar Comentário</label>
      <textarea className="form-control my-2" rows={4} style={{ width: "320px" }} value={comentario} onChange={(e) => setComentario(e.target.value)} />
      <div className="text-center my-2">
        <button type="button" className="btn btn-primary mx-2" onClick={() => { setComentario(""); }}>Limpar</button>
        <button type="button" className="btn btn-primary mx-2" onClick={() => { hide(); }}>Ok</button>
      </div>
    </>
  );

  // const buttonAdicionarComentario = (
  //   emRevisao && !exibindo ?
  //     <Tippy content={(comentario != null && comentario !== "") ? "Editar Comentário" : "Adicionar Comentário"} disabled={visible}>
  //       <Tippy interactive={true} maxWidth={350} visible={visible} content={inputComment} >
  //         <button className={(comentario != null && comentario !== "") ? 'btn text-danger' : 'btn text-warning'} type="button" onClick={visible ? hide : show}>
  //         {recomendacao.texto}-{recomendacao.ordem}<i className="fas fa-comment-alt"></i>
  //         </button>
  //       </Tippy>
  //     </Tippy> :
  //     <></>
  // );

  // const buttonExibirComentario = (
  //   !emRevisao && !exibindo && comentario != null && comentario !== "" ?
  //     <Tippy interactive={true} maxWidth={350} visible={visible} content={comentario} >
  //       <button className='btn text-danger' type="button" onClick={visible ? hide : show}>
  //       {recomendacao.texto}-{recomendacao.ordem}<i className="fas fa-comment-alt"></i>
  //       </button>
  //     </Tippy> :
  //     <></>
  // );

  // useEffect(() => {
  //   console.log('id', `textareaRecomendacao-${idBloco}-${recomendacao.idCampo}-${index}`)
  //   console.log('recomendacao.texto', recomendacao.texto)
  //   console.log('recomendacao.html', recomendacao.html)
  // }, [recomendacao.texto, recomendacao.html])

  return (
    <tr className='border-0' style={{ border: "0px" }}>
      {
        exibindo ?
          <>
            <td className='text-center' style={{ width: "5%" }}>
              <button type='button' className='btn text-bold'
                onClick={() => {
                  document.getElementById(`campoFilhoNeto-${idBloco}-${recomendacao.idCampo}`)?.scrollIntoView({ behavior: "smooth" });
                }}>
                <u>{recomendacao.idCampo}</u>
              </button></td>
            <td colSpan={(isConformidade) ? 3 : 2}><div className='py-0 my-0' dangerouslySetInnerHTML={{ __html: (recomendacao?.html != null && recomendacao?.html !== "") ? recomendacao?.html : recomendacao?.texto }}></div></td>
          </> :
          <>
            {
              (editando) ?
                <>
                  <td className='text-center' style={{ width: "5%" }}>
                    {/* <input className='form-control' type='text' value={recomendacao.idCampo} onChange={(e) => handleIdCampoChange(e.target.value)}></input> */}
                    <button type='button' className='btn text-bold'
                      onClick={() => {
                        document.getElementById(`campoFilhoNeto-${idBloco}-${recomendacao.idCampo}`)?.scrollIntoView({ behavior: "smooth" });
                      }}>
                      <u>{recomendacao.idCampo}</u>
                    </button>
                  </td>
                  <td colSpan={(isConformidade) ? 2 : 1}>
                    <div className='form-group'>
                      <div>
                        <SummernoteEditor id={`textareaRecomendacao-${idBloco}-${recomendacao.idCampo}-${index}`}
                          placeholder='Insira o texto da recomendação...'
                          // classNameEditable='textarea-recomendacao'
                          // classNameEditable='textarea-recomendacao resize-vertical'
                          classNameEditor=''
                          height={elmtHeight != null && elmtHeight > 120 ? elmtHeight : 120}
                          // initialContent={recomendacao.html}
                          onChange={(contents, $editable) => {
                            // onChangeRecomendacao({...recomendacao, texto: $editable[0]?.textContent, html: contents});
                            handleChangeRecomendacaoEditor($editable[0]?.textContent, contents);
                          }}
                          createOnFocus={false}
                          disableHelp={true}
                          // airMode={true}
                          initialContent={(recomendacao?.html != null && recomendacao?.html !== "") ? recomendacao?.html : recomendacao?.texto}
                        />
                      </div>
                      {/* <textarea 
                        id={`textareaRecomendacao-${idBloco}-${recomendacao.idCampo}-${index}`} 
                        className='form-control' rows={2} value={recomendacao.texto} 
                        onChange={(e) => handleTextoChange(e.target.value)}></textarea> */}
                      <div id={`textareaRecomendacaoFeedback-${idBloco}-${recomendacao.idCampo}`} className="invalid-feedback"
                      >
                        Insira o texto da recomendação
                      </div>
                    </div>
                  </td>
                  <td style={{ width: "15%" }}>
                    <Tippy content="Concluir Edição">
                      <button className={`btn col-1 text-secondary mx-2 mt-3 btn-concluir-edicao-bloco-${idBloco}`} type='button' onClick={() => handleEditandoChange(false)}><span className='fas fa-check'></span></button>
                    </Tippy>
                    <Tippy content="Deletar recomendação">
                      <button className="btn col-1 text-danger mx-2 mt-3" type='button' onClick={() => {
                        if (window.confirm("Deseja excluir a recomendação?") === true) {
                          onDeleteRecomendacao(recomendacao)
                        }
                      }
                      }
                      ><span className='fa fa-trash'></span></button>
                    </Tippy>
                  </td>
                </> :
                <>
                  <td className='text-center' style={{ width: "5%" }}>
                    <button type='button' className='btn text-bold'
                      onClick={() => {
                        document.getElementById(`campoFilhoNeto-${idBloco}-${recomendacao.idCampo}`)?.scrollIntoView({ behavior: "smooth" });
                      }}>
                      <u>{recomendacao.idCampo}</u>
                    </button>
                  </td>
                  <td colSpan={(isConformidade) ? 2 : 1}>
                    <div className='d-flex flex-row'>
                      <div id={`divRecomendacao-${idBloco}-${recomendacao.idCampo}-${index}`} className='py-0 my-0' dangerouslySetInnerHTML={{ __html: (recomendacao?.html != null && recomendacao?.html !== "") ? recomendacao?.html : recomendacao?.texto }}></div>
                      <div className='my-0 py-0'>
                        {/* &nbsp;{buttonExibirComentario}{buttonAdicionarComentario} */}
                        {
                          (emRevisao) ?
                            <Tippy content={(comentario != null && comentario !== "") ? "Editar Comentário" : "Adicionar Comentário"} disabled={visible}>
                              <Tippy interactive={true} maxWidth={350} visible={visible} content={inputComment} >
                                <button className={(comentario != null && comentario !== "") ? 'btn text-danger' : 'btn text-warning'} type="button" onClick={visible ? hide : show}>
                                  &nbsp;<i className="fas fa-comment-alt"></i>
                                </button>
                              </Tippy>
                            </Tippy> :
                            <>
                              {
                                (comentario != null && comentario !== "") ?
                                  <Tippy interactive={true} maxWidth={350} visible={visible} content={comentario} >
                                    <button className='btn text-danger' type="button" onClick={visible ? hide : show}>
                                      &nbsp;<i className="fas fa-comment-alt"></i>
                                    </button>
                                  </Tippy>
                                  : <></>
                              }
                            </>
                        }
                      </div>
                    </div>
                  </td>
                  {/* <td colSpan={(isConformidade) ? 2 : 1}><p className='text-bold text-justify py-0 my-0'>{recomendacao.texto}&nbsp;{buttonExibirComentario}{buttonAdicionarComentario}</p></td> */}
                  <td className="" style={{ width: "15%" }}>
                    <div className='d-flex flex-row'>
                      <div className='col mx-0 px-0'>
                        <Tippy content="Mover para cima">
                          <button className="btn col-1 text-info mx-2" type='button' onClick={() => { moveUp(recomendacao) }}><span className='fas fa-chevron-up'></span></button>
                        </Tippy>
                        <Tippy content="Mover para baixo">
                          <button className="btn col-1 text-info mx-2" type='button' onClick={() => { moveDown(recomendacao) }}><span className='fas fa-chevron-down'></span></button>
                        </Tippy>
                      </div>
                      <div className='col mx-0 px-0'>
                        <Tippy content="Editar recomendação">
                          <button className="btn col-1 text-secondary mx-2" type='button' onClick={() => handleEditandoChange(true)}><span className='fas fa-edit'></span></button>
                        </Tippy>
                        <Tippy content="Deletar recomendação">
                          <button className="btn col-1 text-danger mx-2" type='button' onClick={() => {
                            if(emRevisao || (comentario == null || comentario === "")){
                              if (window.confirm("Deseja excluir a recomendação?") === true) {
                                onDeleteRecomendacao(recomendacao)
                              }
                            } else {
                              window.toastr["warning"]("Não é possível excluir uma recomendação que possua observações adicionadas por um revisor.");

                            }
                          }
                          }
                          ><span className='fa fa-trash'></span></button>
                        </Tippy>
                      </div>

                    </div>
                  </td>
                </>
            }

          </>
      }
    </tr>
  )
}
