import React from 'react'

export default function Nota({usuario, autenticado, nota, onChange}) {

  return (
    <div className='col'>
        <textarea rows={8} value={nota?.texto ?? ""} onChange={(e) => onChange({
          ...nota,
          texto: e.target.value
        })}
        style={{width: '300px'}}/>
    </div>
  )
}
