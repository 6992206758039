import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Autenticar from '../../login/Autenticar';
import Requisicao from '../../helpers/Requisicao';
import Tippy from '@tippyjs/react';
import ModeloPDF from '../../reports/modelos/ModeloPDF';

export default function PesquisaModelo({ usuario, autenticado }) {

  const [searchParams, setSearchParams] = useSearchParams();

  const [nome, setNome] = useState(searchParams.get("nome") ?? "");
  const [tipo, setTipo] = useState("-1");
  const [tiposAnalise, setTiposAnalise] = useState([]);
  const [lei, setLei] = useState("-1");
  const [leisLicitacao, setLeisLicitacao] = useState([]);
  const [resultado, setResultado] = useState({});

  const tipoParam = searchParams.get("tipo") ?? "";
  const leiParam = searchParams.get("lei") ?? "";


  const [pageNumber, setPageNumber] = useState("0");
  const [pageSize, setPageSize] = useState("10");

  const [nenhumEncontrado, setNenhumEncontrado] = useState(false);

  const [pesquisando, setPesquisando] = useState(false);

  const [lockAtivar, setLockAtivar] = useState(false);


  const carregarTipos = () => {
    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/analise/tiposanalise";

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setTiposAnalise(data);
      })
      .catch((erro) => console.log(erro));
  };

  const carregarLeis = () => {
    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/analise/leislicitacao";

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLeisLicitacao(data);
      })
      .catch((erro) => console.log(erro));
  };

  useEffect(()=> {
    window.$(".select2bs4").select2({
      theme: "bootstrap4",
    });
    window.$("#comboboxTipoAnalise").on('change', function (e) {
      setTipo(e.target.value);
    });
    window.$("#comboboxLeiLicitacao").on('change', function (e) {
      setLei(e.target.value);
    });
    carregarTipos();
    carregarLeis();

    document.title = 'Pesquisar Modelos de Análise - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
  }, []);

  // preencher comboboxTipoAnalise
  useEffect(() => {
    window.$("#comboboxTipoAnalise").empty();
    let combobox = document.querySelector("#comboboxTipoAnalise").options;

    combobox.add(new Option("Selecione o Tipo da Análise", "-1"));
    tiposAnalise.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    if(tiposAnalise != null && tiposAnalise.length > 0){
      for (let i = 0; i < combobox.length; i++) {
        if(tipoParam === combobox[i].value){
          combobox.selectedIndex = i;
          setTipo(tipoParam);
        }
      }
    } else {
      combobox.selectedIndex = 0;
    }

  }, [tiposAnalise, tipoParam]);

  // preencher comboboxLeiLicitacao
  useEffect(() => {
    window.$("#comboboxLeiLicitacao").empty();
    let combobox = document.querySelector("#comboboxLeiLicitacao").options;

    combobox.add(new Option("Selecione a Lei da Licitação", "-1"));
    leisLicitacao.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    if(leisLicitacao != null && leisLicitacao.length > 0){
      for (let i = 0; i < combobox.length; i++) {
        if(leiParam === combobox[i].value){
          combobox.selectedIndex = i;
          setLei(leiParam);
        }
      }
    } else {
      combobox.selectedIndex = 0;
    }

  }, [leisLicitacao, leiParam]);

  const limparInterface = () => {
    setNome("");
    window.$('#comboboxTipoAnalise').val("-1").trigger("change");    
    window.$('#comboboxLeiLicitacao').val("-1").trigger("change");    
  }
  

  useEffect(() => {
    if(searchParams != null && searchParams.toString() !== ""){
      if(searchParams.has("p") && searchParams.get("p") === ""){
        setPageNumber(searchParams.get("p"));
      }
      if(!searchParams.has("s") || searchParams.get("s") === ""){
        setPageSize(searchParams.get("s"));
      }
      pesquisar();

    }
    // eslint-disable-next-line
  }, [searchParams]);

  const getPagination = () => {
    let totalPaginas = parseInt(resultado.totalPaginas) ?? 0;
    let pag = []
    for (let i = 0; i < totalPaginas; i++) {
      if(i === parseInt(resultado.numeroPagina)){
        pag.push(<li className="page-item active"><button type="button" className="page-link" onClick={()=>handleChangePage(i)}>{i+1}</button></li>);
      } else {
        pag.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(i)}>{i+1}</button></li>);
      }
    }
    return pag;
  }

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
    let params = searchParams;
    params.set("p", "0");
    params.set("s", e.target.value);
    setSearchParams(params);
  }

  const handleChangePage = (page) => {
    let params = searchParams;
    params.set("p", page);
    setSearchParams(params);
  }

  const pesquisar = () => {
    setNenhumEncontrado(false);
    setPesquisando(true);

    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/modelosanalise/pesquisa?" + searchParams.toString();

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if(data.totalElementos === 0 || data.totalElementos === null){
          // alert("Nenhum modelo encontrado.");
          setNenhumEncontrado(true);
        }
        setPesquisando(false);
        setResultado(data);
        // setProcessos(data?.elementos);
      })
      .catch((erro) => console.log(erro));
  }

  const handlePesquisar = (e, force) => {
    e.preventDefault();
    e.stopPropagation();

    let params = new URLSearchParams();

    if(nome != null && nome !== ""){
      params.set("nome", nome);
    }

    if(tipo != null && tipo !== "" & tipo !== "-1"){
      params.set("tipo", tipo);
    }

    if(lei != null && lei !== "" & lei !== "-1"){
      params.set("lei", lei);
    }

    params.set("p", pageNumber.toString());
    params.set("s", pageSize.toString());

    if(params.toString() !== searchParams.toString){
      setSearchParams(params);
    } else {
      pesquisar();
    }
  }


  const ativarModeloAnalise = (id) => {
    setLockAtivar(true);
    Requisicao.setAtivarModeloAnalise(id)
      .then(data => {
        console.log('Ativar modelo análise: ', data);
        setLockAtivar(false);
        pesquisar();
      })
      .catch(erro => {
        console.log('erro', erro);
        setLockAtivar(false);
      })
  }

  const desativarModeloAnalise = (id) => {
    setLockAtivar(true);
    Requisicao.setDesativarModeloAnalise(id)
      .then(data => {
        console.log('Desativar modelo análise: ', data);
        setLockAtivar(false);
        pesquisar();
      })
      .catch(erro => {
        console.log('erro', erro);
        setLockAtivar(false);

      })
  }

  const isAdministradorOuAdministradorSCF = () => {
    if (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR") {
      return true;
    }
    return false;
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Pesquisa de Modelos de Análise</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Pesquisa de Modelos de Análise</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12" onSubmit={handlePesquisar}>
          <div className="card">
            <div className="card-body">
              <h4 className='my-3'>Pesquisa de Modelos de Análise</h4>
              <div className='my-3 row'>
                <div className='col'>
                  <label htmlFor='inputPesquisaNome'>Nome</label>
                  <input id="inputPesquisaNome" type="text" className='form-control d-flex flex-column' value={nome} onChange={(e)=>setNome(e.target.value)} placeholder={"Digite o nome do Modelo"} />
                </div>
                <div className='col'>
                  <label htmlFor='comboboxTipoAnalise'>Tipo da Análise</label>
                  <select id="comboboxTipoAnalise" className="form-control select2bs4" style={{ width: '100%' }}>
                  </select>
                </div>
              </div>
              <div className='my-3 row'>
                <div className='col-6'>
                  <label htmlFor='comboboxLeiLicitacao'>Lei da Licitação</label>
                  <select id="comboboxLeiLicitacao" className="form-control select2bs4" style={{ width: '100%' }}>
                  </select>
                </div>
              </div>
              <div className='row'>
                <button type='submit' className='btn btn-primary mx-2'>Pesquisar</button>
                <button type='button' className='btn btn-outline-primary mx-2' onClick={limparInterface}>Limpar</button>
              </div>
              <hr />
              <div className='my-3'>
                  {
                    (pesquisando) ?
                    <div className="text-center mt-3 col px-0 mx-0">
                      <div className="spinner-border" role="status">
                      </div>
                    </div> :
                    <></>
                  }
                  {
                    (nenhumEncontrado) ?
                    <p className='text-center'>Nenhum modelo de analise encontrado</p> :
                    <></>
                  }
                {
                  resultado?.elementos != null && resultado?.elementos.length > 0 ?
                  <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Tipo da Análise</th>
                            {
                              isAdministradorOuAdministradorSCF() ?
                              <th scope="col" className="text-center">Ativado</th> : <></>
                            }
                            <th scope="col" className="text-center">Editar</th>
                        </tr>
                    </thead>
                    <tbody className="align-middle">
                        {resultado.elementos && resultado.elementos.map((elemento) => {
                            return <tr key={elemento.id}>
                                <th scope="row">{elemento.id}</th>
                                <td>{elemento.nome}</td>
                                <td>{elemento.tipoAnalise}</td>
                                {
                                  isAdministradorOuAdministradorSCF() ?
                                    <td className="text-center">
                                    {
                                      (elemento.desativado) ?
                                      <Tippy content='Ativar Modelo'>
                                        <button type="button" className="btn btn-danger btn mr-2 my-2" onClick={() => {
                                          if(!lockAtivar){
                                            ativarModeloAnalise(elemento.id);
                                          }
                                        }}>
                                          <i className="far fa-square"></i>
                                        </button>
                                      </Tippy> :
                                      <Tippy content='Desativar Modelo'>
                                        <button type="button" className="btn btn-success btn mr-2 my-2" onClick={() => {
                                          if(!lockAtivar){
                                            desativarModeloAnalise(elemento.id)
                                          }
                                        }}>
                                          <i className="far fa-check-square"></i>
                                        </button>
                                      </Tippy>
                                    }
                                    </td> : <></>
                                }
                                <td className="text-center">
                                  <Tippy content='Imprimir Modelo'>
                                    <button className="btn btn-danger mr-2" type='button' onClick={() => ModeloPDF(elemento.id, usuario)}>
                                      <i className="far fa-file-pdf"></i>
                                    </button>
                                  </Tippy>
                                  <Tippy content='Editar'>
                                    <Link to={`/analise/modelo/editar/${elemento.id}`} className="btn btn-success"><i className="fas fa-edit"></i></Link>
                                  </Tippy>
                                </td>
                            </tr>
                        })}
                    </tbody>
                  </table> :
                  <></>
                }
                
              </div>
            </div>
            <div className='m-3 text-right'>
              <Link className="btn btn-primary" to={"/analise/modelo/novo"}>Novo Modelo de Análise</Link>
            </div>
          </div>
          <div className='px-2 row justify-content-between'>
            {
              resultado?.elementos != null && resultado?.elementos.length > 0 ?
                <nav aria-label="Page navigation example">
                  <ul className="pagination">
                    {
                      parseInt(resultado.numeroPagina) === 0 ?
                      <li className="page-item disabled"><button type="button" className="page-link">Anterior</button></li> :
                      <li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(parseInt(resultado.numeroPagina)-1)}>Anterior</button></li>
                    }
                    {

                      getPagination().map((pag, index) => {
                        return <React.Fragment key={index}>
                          {pag}
                        </React.Fragment>
                      })

                    }
                    {
                      parseInt(resultado?.numeroPagina) + 1 === parseInt(resultado?.totalPaginas) ?
                      <li className="page-item disabled"><button type="button" className="page-link">Próxima</button></li> :
                      <li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(parseInt(resultado.numeroPagina)+1)}>Próxima</button></li>

                    }
                  </ul>
                </nav>
                
                : <div></div>
            }
            <select id="comboboxPageSize" className="form-control col-1 text-center" value={pageSize} onChange={handleChangePageSize}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
          </select>
          </div>

        </form>
        
      </div>
    </>
  )
}
