import React, { useState, useEffect } from 'react';
import Autenticar from '../../login/Autenticar';
import LoadingElement from '../../components/LoadingElement';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import SummernoteEditor from '../../components/SummernoteEditor';
import EnumUtils from '../../helpers/EnumUtils';
import Requisicao from '../../helpers/Requisicao';

export default function BancoDeRecomendacoesEditar({ usuario, autenticado }) {

  const { recBancoId } = useParams();
  const editando = isNaN(Number(recBancoId)) ? false : true;

  const navigate = useNavigate();
  const location = useLocation();

  const [recBanco, setRecBanco] = useState(null);

  const [titulos, setTitulos] = useState([]);
  const [listaLeiLicitacao, setListaLeiLicitacao] = useState([]);
  const [listaTipoAnalise, setListaTipoAnalise] = useState([]);


  const [selectedTitulo, setSelectedTitulo] = useState('');
  const [leiLicitacao, setLeiLicitacao] = useState('');
  const [tipoAnalise, setTipoAnalise] = useState('');

  const [textoRec, setTextoRec] = useState("");
  const [textoRecHTML, setTextoRecHTML] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [salvando, setSalvando] = useState(false);

  const [modo, setModo] = useState('individual')


  useEffect(() => {
    if (autenticado) {
      if (editando) {
        const token = Autenticar.getLocalToken();

        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        };

        fetch(`${window.servidor}/v1/protegido/bancorecomendacao/get/${recBancoId}`, requestOptions)
          .then(response => response.json())
          .then(data => {
            checarAcesso(data);
            setupRecBanco(data);
            setIsLoading(false);
          })
          .catch(error => {
            console.error('Erro carregando modelo de recomendação do banco:', error);
            setIsLoading(false);
          });
      } else{
        setIsLoading(false);
      }
    }

    document.title = 'Modelo de Recomendação - Sistema de Controle Interno';
    document.title = `${editando ? 'Editar - ' : 'Novo - '}Modelo de Recomendação - Sistema de Controle Interno`;
    return (() => document.title = 'Sistema de Controle Interno')
    // eslint-disable-next-line
  }, [autenticado, editando, recBancoId]);

  useEffect(() => {
    if(!isLoading){
        carregarTitulos();
        carregarLeis();
        carregarTipos();
  
        window.$(".select2bs4").select2({
          theme: "bootstrap4",
        });
        
        window.$(`#comboboxLeiLicitacao`).on('change', function (e) {
          setLeiLicitacao(e.target.value);
          // setSelectedTitulo("");
        });
        window.$(`#comboboxTipoAnalise`).on('change', function (e) {
          setTipoAnalise(e.target.value);
          // setSelectedTitulo("");
        });
        window.$(`#comboboxTitulos`).on('change', function (e) {
          setSelectedTitulo(e.target.value);
        });

    }
  }, [isLoading])

  const checarAcesso = (e) => {
    if(e?.usuarioLogin !== usuario?.login && e?.unidadeId !== usuario.unidadeId){
      alert("Acesso negado.");
      navigate("/bancoderecomendacoes", { state: { referrer: location }, replace: true });
    } else {
      if(e?.usuarioLogin !== usuario?.login && !(isAdministradorOuAdministradorSCF() || isRevisor())){
        alert("Acesso negado.");
        navigate("/bancoderecomendacoes", { state: { referrer: location }, replace: true });
      }
    }

  }

  const setupRecBanco = (r) => {
    setTextoRec(r?.texto ?? "");
    setTextoRecHTML(r?.html ?? "");
    setModo(r?.usuarioLogin != null && r?.usuarioLogin !== "" && r?.usuarioLogin === usuario.login ? 'individual' : 'unidade');
    setLeiLicitacao(r?.leiLicitacao ?? "");
    setTipoAnalise(r?.tipoAnalise ?? "");
    setSelectedTitulo(r?.tituloBloco ?? "");
    setRecBanco(r);
  }

  const carregarLeis = () => {
    Requisicao.getListaLeisLicitacao()
      .then((data) => {
        setListaLeiLicitacao(data);
      })
      .catch((erro) => console.log(erro));
  };

  const carregarTipos = () => {
    Requisicao.getListaTiposAnaliseOrigem()
      .then((data) => {
        setListaTipoAnalise(data);
      })
      .catch((erro) => console.log(erro));
  };

  const carregarTitulos = () => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

    fetch(`${window.servidor}/v1/protegido/modelosanalise/dados/titulosmodelosblocos`, requestOptions)
        .then(response => response.json())
        .then(data => {
          setTitulos(data);
        })
        .catch(error => {
          console.error('Erro carregando titulos:', error);
        });
  }

  const handleChangeModo = (modo) => {
    setModo(modo);
  }

  const isAdministradorOuAdministradorSCF = () => {
    if (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR") {
      return true;
    }
    return false;
  }
  const isRevisor = () => {
    if (usuario?.tipoUsuario === "SCF_REVISOR_UC" || usuario?.tipoUsuario === "SCF_REVISOR_UDCI") {
      return true;
    }
    return false;
  }

  // const handleTituloChange = (e) => {
  //   setSelectedTitulo(e.target.value);
  // };

  // useEffect(() => {
  //   console.log('textoRec', textoRec);
  //   console.log('textoRecHTML', textoRecHTML);
  // }, [textoRec, textoRecHTML]);

  const salvar = () => {
    setSalvando(true);
    window.toastr["info"]("Salvando...", '', { timeOut: 0, extendedTimeout: 0 });

    const dados = {
      id: editando ? recBancoId : null,
      unidadeId: modo === 'unidade' ? usuario.unidadeId : null,
      usuarioLogin: modo === 'individual' ? usuario.login : null,
      texto: textoRec,
      html: textoRecHTML,
      tituloBloco: selectedTitulo,
      leiLicitacao: EnumUtils.exists(listaLeiLicitacao, leiLicitacao) ? leiLicitacao : null,
      tipoAnalise: EnumUtils.exists(listaTipoAnalise, tipoAnalise) ? tipoAnalise : null,
    }

    console.log('dados', dados);

    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(dados)
    };

    const url = editando ? `${window.servidor}/v1/protegido/bancorecomendacao/editar` : `${window.servidor}/v1/protegido/bancorecomendacao/novo`;

    fetch(url, requestOptions)
        .then((response) => {
          if(response.status !== 200){
            setSalvando(false);
            window.toastr.clear();
            window.toastr["error"]("Falha ao salvar. Verifique sua conexão com a internet.");
            throw new Error("Falha no cadastro/edição da recomendação.")
          }
          return response.json();
        })
        .then((data) => {
          setSalvando(false);
          window.toastr.clear();
          if(data != null){
            window.toastr["success"]("Salvo com sucesso!");
          } else {
            window.toastr["error"]("Falha ao salvar. Verifique sua conexão com a internet.");
          }
          if(!editando){
            navigate("/bancoderecomendacoes/editar/" + data.id, { state:{referrer:location}, replace:true });
          }
        })
        .catch(erro => {
          setSalvando(false);
          window.toastr.clear();
          window.toastr["error"]("Falha ao salvar. Verifique sua conexão com a internet.");
          console.log(erro);
        });
  }

  const containsOption = (options, value) => {
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].value === value) {
        return true;
      }
    }
    return false;
  }


  // preencher comboboxTitulos
  useEffect(() => {
    if (document.querySelector(`#comboboxTitulos`)) {
      window.$(`#comboboxTitulos`).empty();
      let combobox = document.querySelector(`#comboboxTitulos`).options;

      let lei = leiLicitacao != null && leiLicitacao !== "" & leiLicitacao !== "-1" ? leiLicitacao : null;
      let tipoA = tipoAnalise != null && tipoAnalise !== "" & tipoAnalise !== "-1" ? tipoAnalise : null;

      combobox.add(new Option("Todos", ""));
      titulos?.forEach((element) => {
        if (lei != null && tipoA != null) {
          if (element.leiLicitacao === lei && element.tipoAnalise === tipoA && !containsOption(combobox, element.titulo)) {
            combobox.add(new Option(element.titulo, element.titulo));
          }
        }
        if (lei != null && tipoA == null) {
          if (element.leiLicitacao === lei && !containsOption(combobox, element.titulo)) {
            combobox.add(new Option(element.titulo, element.titulo));
          }
        }
        if (lei == null && tipoA != null) {
          if (element.tipoAnalise === tipoA && !containsOption(combobox, element.titulo)) {
            combobox.add(new Option(element.titulo, element.titulo));
          }
        }
        if (lei == null && tipoA == null && !containsOption(combobox, element.titulo)) {
          combobox.add(new Option(element.titulo, element.titulo));
        }
      });

      // console.log('combobox', combobox)

      // combobox[0].disabled = true;
      combobox.selectedIndex = 0;
      // window.$(`#comboboxTitulos`).val("").trigger("change");

      // console.log('combobox', combobox)


      if (selectedTitulo != null) {
        // for (let i = 0; i < combobox.length; i++) {
        //   if (selectedTitulo === combobox[i].value) {
        //     window.$(`#comboboxTitulos`).val(combobox[i].value).trigger("change");
        //   }
        // }
        if(containsOption(combobox, selectedTitulo)){
          window.$(`#comboboxTitulos`).val(selectedTitulo).trigger("change");
        } 
        else {
          if(combobox.length > 1){
            if((leiLicitacao != null && leiLicitacao !== "") || (tipoAnalise != null && tipoAnalise !== "")){
              window.$(`#comboboxTitulos`).val("").trigger("change");
            }
          }
        }
      }
      
    }
  }, [titulos, leiLicitacao, tipoAnalise, selectedTitulo]);

  // useEffect(() => {
  //   console.log('selectedTitulo', selectedTitulo)
  //   console.log('leiLicitacao', leiLicitacao)
  //   console.log('tipoAnalise', tipoAnalise)
  //   console.log("-------------------------------------")
  // }, [selectedTitulo, leiLicitacao, tipoAnalise])

  // preencher comboboxLeiLicitacao
  useEffect(() => {
    if (document.querySelector(`#comboboxLeiLicitacao`)) {
      window.$(`#comboboxLeiLicitacao`).empty();
      let combobox = document.querySelector(`#comboboxLeiLicitacao`).options;

      combobox.add(new Option("Todos", ""));
      listaLeiLicitacao?.forEach((element) => {
        combobox.add(new Option(element.nome, element.value));
      });

      // combobox[0].disabled = true;
      combobox.selectedIndex = 0;

      if (leiLicitacao != null) {
        for (let i = 0; i < combobox.length; i++) {
          if (leiLicitacao === combobox[i].value) {
            window.$(`#comboboxLeiLicitacao`).val(combobox[i].value).trigger("change");
          }
        }
      }
    }
  }, [listaLeiLicitacao, leiLicitacao]);

  // preencher comboboxTipoAnalise
  useEffect(() => {
    if (document.querySelector(`#comboboxTipoAnalise`)) {

      window.$("#comboboxTipoAnalise").empty();
      let combobox = document.querySelector("#comboboxTipoAnalise").options;

      combobox.add(new Option("Todos", ""));
      listaTipoAnalise?.forEach((element) => {
        if (usuario?.tipoUsuario.toString() === "ADMINISTRADOR" ||
          usuario?.tipoUsuario.toString() === "SCF_ADMINISTRADOR" ||
          usuario?.tipoUsuario.toString() === "SCF_ANALISTA_UC" ||
          usuario?.tipoUsuario.toString() === "SCF_REVISOR_UC") {
          combobox.add(new Option(element.nome, element.value));
        } else {
          if (element.value !== "ANALISE_PREVIA") {
            combobox.add(new Option(element.nome, element.value));
          }
        }
      });

      // combobox[0].disabled = true;
      combobox.selectedIndex = 0;

      if (tipoAnalise != null) {
        for (let i = 0; i < combobox.length; i++) {
          if (tipoAnalise === combobox[i].value) {
            window.$(`#comboboxTipoAnalise`).val(combobox[i].value).trigger("change");
          }
        }
      }      
    }
  }, [listaTipoAnalise, tipoAnalise, usuario.tipoUsuario]);

  // const limparInterface = () => {
  //   console.log("Limpando Interface.")
  //   setLeiLicitacao("");
  //   setTipoAnalise("");
  //   setSelectedTitulo("");
  //   window.$("#comboboxLeiLicitacao").val("").trigger("change");
  //   window.$("#comboboxTipoAnalise").val("").trigger("change");
  //   window.$("#comboboxTitulos").val("").trigger("change");
  // }


  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">{`${editando ? 'Editar - ' : 'Novo - '}Modelo de Recomendação`}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/bancoderecomendacoes"}>Banco de Recomendações</Link>
                  </li>
                  <li className="breadcrumb-item active">{`${editando ? 'Editar' : 'Novo'}`}</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              {
                (isLoading) ?
                  <LoadingElement /> :
                  <>
                    <div className='col mx-2'>
                      <p className='text-bold' htmlFor='btnGroupModo'>Adicionar ao banco de recomendações:</p>
                      <div id='btnGroupModo' className="btn-group" role="group">
                        <button type="button" className={modo === 'individual' ? 'btn btn-primary' : 'btn btn-secondary'} onClick={() => handleChangeModo('individual')}>Individual</button>
                        {
                          (isAdministradorOuAdministradorSCF() || isRevisor() || modo === 'unidade') ? 
                          <button type="button" className={modo === 'unidade' ? 'btn btn-primary' : 'btn btn-secondary'} onClick={() => handleChangeModo('unidade')}>Unidade</button> :
                          <button type="button" className={modo === 'unidade' ? 'btn btn-primary' : 'btn btn-secondary'} disabled>Unidade</button>
                        }
                      </div>
                    </div>
                    <div className='d-flex flex-column flex-md-row my-3 mx-2'>
                      <div className='col col-lg-4'>
                        <label htmlFor="comboboxLeiLicitacao">Lei de Licitações/Serviços</label>
                        <select id="comboboxLeiLicitacao" className="select2bs4" style={{ width: '100%' }}></select>
                      </div>
                    </div>
                    <div className='d-flex flex-column flex-md-row my-3 mx-2'>
                      <div className='col col-lg-4'>
                        <label htmlFor="comboboxTipoAnalise">Tipo da Análise</label>
                        <select id="comboboxTipoAnalise" className="select2bs4" style={{ width: '100%' }}></select>
                      </div>
                    </div>
                    <div className='d-flex flex-column flex-md-row my-3 mx-2'>
                      <div className='col col-lg-4'>
                        <label htmlFor="comboboxTitulos">Bloco</label>
                        <select id="comboboxTitulos" className="select2bs4" style={{ width: '100%' }}></select>
                      </div>
                    </div>
                    <div className='col my-3 mx-2'>
                      <SummernoteEditor id={`textareaAdicionarRecomendacaoBanco`}
                        placeholder='Insira o texto da recomendação...'
                        classNameEditable='textarea-recomendacao'
                        classNameEditor=''
                        height={250}
                        textSetState={setTextoRec}
                        codeSetState={setTextoRecHTML}
                        createOnFocus={false}
                        disableHelp={true}
                        initialContent={textoRecHTML ?? ""}
                      />
                    </div>
                    <div className="d-flex flex-row justify-content-end px-3 pb-3">
                    {
                      salvando ?
                        <>
                          <button type="button" className="btn btn-secondary mx-2" disabled>Voltar</button>
                          <button type='button' className='btn btn-primary' disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;Salvando...
                          </button>
                        </> :
                        <>
                          <Link className="btn btn-secondary mx-2" to={'/bancoderecomendacoes'}>Voltar</Link>
                          <button type="button" className="btn btn-primary" onClick={() => salvar()}>Salvar</button>
                        </>
                    }
                  </div>
                  </>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
