import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { isValid, parseISO } from 'date-fns';
import Utilidades from '../../helpers/Utilidades';
import FavoritarProcesso from './FavoritarProcesso';
import Requisicao from '../../helpers/Requisicao';
import EnumUtils from '../../helpers/EnumUtils';
import LoadingElement from '../../components/LoadingElement';
import ObservarProcesso from './ObservarProcesso';

export default function ExibirProcesso({ usuario, autenticado }) {

  const { processoId } = useParams();


  const [loading, setLoading] = useState(true);

  const [processo, setProcesso] = useState(null);
  const [listaOrgaos, setListaOrgaos] = useState([]);
  const [listaTiposAnalise, setListaTiposAnalise] = useState([]);

  const [contratos, setContratos] = useState([]);
  const [listaTipoAditivoContrato, setListaTipoAditivoContrato] = useState([]);

  useEffect(() => {

    carregarListaOrgaos();
    carregarListaTiposAnalise();
    carregarListaTipoAditivoContrato();
    carregarProcessoPorId(processoId);
    // eslint-disable-next-line
  }, [processoId])


  const carregarProcessoPorId = (id) => {
    setLoading(true);
    if (usuario.setor === "FISCALIZACAO_E_CONTROLE" || usuario.tipoUsuario === "ADMINISTRADOR" || usuario?.setor === "CONSULTA") {
      Requisicao.getProcessoPorId(id)
        .then(data => {
          setProcesso(data);
          carregarContratos(data.numero);
          setLoading(false);
        })
        .catch(erro => console.log('erro', erro));
    } else {
      Requisicao.getProcessoDadosBasicosPorId(id)
        .then(data => {
          setProcesso(data);
          carregarContratos(data.numero);
          setLoading(false);
        })
        .catch(erro => console.log('erro', erro));
    }
  }

  const carregarListaOrgaos = () => {
    Requisicao.getListaOrgaos().then(data => setListaOrgaos(data)).catch(erro => console.log('erro', erro));
  }

  const carregarListaTiposAnalise = () => {
    Requisicao.getListaTiposAnalise().then(data => setListaTiposAnalise(data)).catch(erro => console.log('erro', erro));
  }

  const carregarListaTipoAditivoContrato = () => {
    Requisicao.getListaTipoAditivoContrato().then(data => setListaTipoAditivoContrato(data)).catch(erro => console.log('erro', erro));
  }

  const carregarContratos = (numero) => {
    Requisicao.getAllContratosByNumeroProcesso(numero)
      .then(data => {
        // console.log('contratos', data)
        if (data != null) {
          setContratos(data);
        }
      })
      .catch(erro => console.log('erro', erro));
  }


  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h3 className="m-0">Informações do Processo</h3>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Informações do Processo</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {
          (processo != null) ?
            <>

              <div className="card card-secondary mx-2">
                <div className="card-header">
                  <h3 className="card-title text-bold">Processo Nº {processo.numero}</h3>
                </div>
                <div className="card-body">
                  <div className='d-flex flex-row justify-content-between'>
                    <div className='col'>
                      <div className='row flex-grow-1'>
                        <div className='col'>
                          <label>Número do Processo</label>
                          <p>{processo.numero}</p>
                        </div>
                        <div className='col'>
                          <label>Volumes e Apensos</label>
                          <p>{processo.volumeseApensos}</p>
                        </div>
                      </div>
                      <div className='row flex-grow-1'>
                        <div className='col'>
                          <label>Unidade Responsável</label>
                          <p>{processo.unidade.nome}</p>
                        </div>
                        <div className='col'>
                          <label>Órgão Solicitante</label>
                          <p>{EnumUtils.getNomeEnum(processo.orgaoSolicitante, listaOrgaos)}</p>
                        </div>
                      </div>
                      <div className='row flex-grow-1'>
                        <div className='col'>
                          <label>Objeto do Processo</label>
                          <p className='fix-linebreak'>{processo.objeto}</p>
                        </div>
                      </div>
                    </div>
                    {
                      (usuario.tipoUsuario === "ADMINISTRADOR") ?
                        <div className='col flex-grow-0'>
                          <FavoritarProcesso usuario={usuario} processo={processo} collapsed={false} />
                        </div> :
                        <></>
                    }
                    {
                      (usuario.unidadeId === 1) ?
                        <div className='col flex-grow-0'>
                          <ObservarProcesso usuario={usuario} processo={processo} collapsed={false} />
                        </div> :
                        <></>
                    }
                  </div>
                  <div className='d-flex flex-row justify-content-center'>
                    {
                      (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR" || usuario?.permissoes?.includes("PERM_SCF_EDITAR_PROCESSO")) ?
                        <div className='mt-2'>
                          <Link to={`/processo/editar/${processo.id}`} type='button' className='btn btn-primary m-2'>Editar Processo</Link>
                        </div> : <></>
                    }
                    {
                      (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR" || usuario?.setor === "SUBCONTROLADORIA_GERAL" || usuario?.setor === "CONSULTA") ?
                        <div className='mt-2'>
                          <Link to={`/avisolicitacao/pesquisar?numeroProcesso=${processo.numero}`} type='button' className='btn btn-primary m-2'>Avisos de Licitação</Link>
                        </div> : <></>
                    }
                    {
                      (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR" || usuario?.setor === "SUBCONTROLADORIA_GERAL" || usuario?.setor === "CONSULTA") ?
                        <div className='mt-2'>
                          <Link to={`/contrato/pesquisar?numeroProcesso=${processo.numero}`} type='button' className='btn btn-primary m-2'>Contratos</Link>
                        </div> : <></>
                    }
                    {
                      (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR" || usuario?.setor === "SUBCONTROLADORIA_GERAL") ?
                        <div className='mt-2'>
                          <Link to={`/contrato/novo?numeroProcesso=${processo.numero}`} type='button' className='btn btn-primary m-2'>Adicionar Contrato/Aditivo</Link>
                        </div> : <></>
                    }
                  </div>
                  <br />
                  {
                    (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.setor === "FISCALIZACAO_E_CONTROLE" || usuario?.setor === "CONSULTA") ?
                      <>
                        <div className="card card-navy collapsed-card px-2">
                          <div className="card-header row" data-card-widget="collapse" style={{ cursor: "pointer" }}>
                            <div className='d-flex flex-row justify-content-center flex-grow-1'>
                              <h3 className="card-title">Análises do Processo</h3>
                            </div>
                            <div className="card-tools">
                              <button type="button" className="btn btn-tool" data-card-widget="collapse" title="">
                                <i className="fas fa-plus" />
                              </button>
                            </div>
                          </div>
                          <div className="card-body px-0">
                            <div className='col'>
                              <table className="table table-striped table-bordered">
                                <thead>
                                  <tr>
                                    <th>ID</th>
                                    <th>Nome</th>
                                    <th>Tipo de Análise</th>
                                    <th>Data de Entrada</th>
                                    <th>Data de Saída</th>
                                    <th>Valor</th>
                                    <th>Última Modificação</th>
                                    {
                                      (usuario?.setor === "FISCALIZACAO_E_CONTROLE" || usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.setor === "CONSULTA") ?
                                        <th>Botões</th> :
                                        <></>
                                    }
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    processo.analises && processo.analises.map((analise) => (
                                      <tr key={analise.id}>
                                        <td>{analise.id}</td>
                                        <td>{analise?.nome ?? "Sem informações"}</td>
                                        <td>{EnumUtils.getNomeEnum(analise.tipoAnalise, listaTiposAnalise)}</td>
                                        <td>{isValid(parseISO(analise?.dataEntrada)) ? parseISO(analise?.dataEntrada).toLocaleDateString('pt-BR') : ""}</td>
                                        <td>{isValid(parseISO(analise?.dataSaida)) ? parseISO(analise?.dataSaida).toLocaleDateString('pt-BR') : ""}</td>
                                        <td>{Utilidades.getValorFormatado(analise?.valorEstimado)}</td>
                                        {/* <td>R$&nbsp;{analise.valorEstimado?.replaceAll(".", ",")}</td> */}
                                        <td>{analise.usuarioUltimaEdicao}{isValid(parseISO(analise?.dataModificacao)) ? " - " + parseISO(analise?.dataModificacao).toLocaleString('pt-BR') : ""}</td>
                                        {/* <td>{nomesStatus[analise?.statusAnalise]}</td> */}
                                        {
                                          (usuario?.setor === "FISCALIZACAO_E_CONTROLE" || usuario?.tipoUsuario === "ADMINISTRADOR") ?
                                            <td className="project-actions text-right">
                                              <Link className="btn btn-warning btn-sm mr-2" to={`/analise/${analise.id}`}>
                                                <i className="far fa-file-alt"></i>
                                                &nbsp;Abrir
                                              </Link>
                                            </td> :
                                            <></>
                                        }
                                        {
                                          (usuario?.setor === "CONSULTA") ?
                                            <td className="project-actions text-right">
                                              <Link className="btn btn-warning btn-sm mr-2" to={`/analise/visualizar/${analise.id}`}>
                                                <i className="far fa-file-alt"></i>
                                                &nbsp;Abrir
                                              </Link>
                                            </td> :
                                            <></>
                                        }
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                              {
                                (usuario?.setor === "FISCALIZACAO_E_CONTROLE" || usuario?.tipoUsuario === "ADMINISTRADOR") ?
                                  <div className='text-right mt-2'>
                                    <Link to={`/processo/${processo.id}/novaanalise`} type='button' className='btn btn-primary'>Nova Análise</Link>
                                  </div> :
                                  <></>
                              }
                            </div>
                          </div>
                        </div>
                      </> : <></>
                  }

                  {
                    (contratos != null && contratos instanceof Array && contratos.length > 0) ?
                      <>
                        <div className="card card-olive collapsed-card px-2">
                          <div className="card-header row" data-card-widget="collapse" style={{ cursor: "pointer" }}>
                            <div className='d-flex flex-row justify-content-center flex-grow-1'>
                              <h3 className="card-title">Contratos</h3>
                            </div>
                            <div className="card-tools">
                              <button type="button" className="btn btn-tool" data-card-widget="collapse" title="">
                                <i className="fas fa-plus" />
                              </button>
                            </div>
                          </div>
                          <div className="card-body px-0">
                            <div className='col'>
                              <table className="table table-striped table-bordered">
                                <thead>
                                  <tr>
                                    <th>ID</th>
                                    <th>Número</th>
                                    <th>Aditivo</th>
                                    <th>Órgão Solicitante</th>
                                    <th>Empresa</th>
                                    <th>Valor</th>
                                    <th>Data Término</th>
                                    {
                                      (usuario?.setor === "SUBCONTROLADORIA_GERAL" || usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.setor === "CONSULTA") ?
                                        <th>Botões</th> :
                                        <></>
                                    }
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    contratos && contratos.map((contrato) => (
                                      <tr key={contrato.id}>
                                        <td>{contrato.id}</td>
                                        <td>{contrato.numeroContrato}{(contrato.sufixoNumeroContrato != null && contrato.sufixoNumeroContrato !== "" ? `-${contrato.sufixoNumeroContrato}` : "")}</td>
                                        <td>{
                                          (contrato?.tipoAditivoContrato === "TERMO_ADITIVO" || contrato?.tipoAditivoContrato === "TERMO_APOSTILAMENTO") ?
                                            `${contrato?.numAditivo}º ${EnumUtils.getNomeEnum(contrato?.tipoAditivoContrato, listaTipoAditivoContrato)}` :
                                            EnumUtils.getNomeEnum(contrato?.tipoAditivoContrato, listaTipoAditivoContrato)
                                        }</td>
                                        <td>{EnumUtils.getNomeEnum(contrato?.orgaoResponsavel, listaOrgaos)}</td>
                                        <td>{contrato.empresa}</td>
                                        <td>{Utilidades.getValorFormatado(contrato?.valor)}</td>
                                        <td>{isValid(parseISO(contrato?.dataTermino)) ? parseISO(contrato?.dataTermino).toLocaleDateString('pt-BR') : ""}</td>
                                        {
                                          (usuario?.setor === "SUBCONTROLADORIA_GERAL" || usuario?.tipoUsuario === "ADMINISTRADOR") ?
                                            <td className="project-actions text-right">
                                              <Link className="btn btn-warning btn-sm mr-2" to={`/contrato/editar/${contrato.id}`}>
                                                <i className="far fa-file-alt"></i>
                                                &nbsp;Editar
                                              </Link>
                                              <Link className="btn btn-info btn-sm mr-2" to={`/contrato/exibir/${contrato.id}`}>
                                                <i className="far fa-file-alt"></i>
                                                &nbsp;Exibir
                                              </Link>
                                            </td> :
                                            <>
                                              {
                                                (usuario?.permissoes?.includes("PERM_SCF_VER_CONTRATO") || usuario?.setor === "CONSULTA") ?
                                                  <td className="project-actions text-right">
                                                    <Link className="btn btn-info btn-sm mr-2" to={`/contrato/exibir/${contrato.id}`}>
                                                      <i className="far fa-file-alt"></i>
                                                      &nbsp;Exibir
                                                    </Link>
                                                  </td> :
                                                  <></>
                                              }
                                            </>
                                        }
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                              {
                                (usuario?.setor === "FISCALIZACAO_E_CONTROLE" || usuario?.tipoUsuario === "ADMINISTRADOR") ?
                                  <div className='text-right mt-2'>
                                    <Link to={`/processo/${processo.id}/novaanalise`} type='button' className='btn btn-primary'>Nova Análise</Link>
                                  </div> :
                                  <></>
                              }
                            </div>
                          </div>
                        </div>
                      </> : <></>
                  }
                </div>
              </div>
            </> :
            <></>
        }
        {(loading) ? <><div style={{ marginTop: "40vh" }}><LoadingElement /></div></> : <></>}
      </div>

    </>
  )
}
