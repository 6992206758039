import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Requisicao from '../../helpers/Requisicao';
import Utilidades from '../../helpers/Utilidades';
import { isValid, parseISO } from 'date-fns';
import Autenticar from '../../login/Autenticar';
import LoadingElement from '../../components/LoadingElement';
import EnumUtils from '../../helpers/EnumUtils';
import DatePicker from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";

export default function ExibirPenalidade({usuario, autenticado}) {
  const { penalidadeId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const [penalidade, setPenalidade] = useState(null);

  const [listaOrgaos, setListaOrgaos] = useState([]);
  const [listaTipoPenalidade, setListaTipoPenalidade] = useState([]);

  // componentDidMount
  useEffect(() => {
    if (autenticado) {
      carregarListas();

      if (penalidadeId != null && penalidadeId !== "" && !isNaN(Number(penalidadeId))) {
        carregarPenalidade(penalidadeId);
      }

    }

    document.title = `Exibindo Cadastro de Penalidade - Sistema de Controle Interno`;
    return (() => document.title = 'Sistema de Controle Interno')
    // eslint-disable-next-line
  }, [autenticado, penalidadeId]);

  const carregarPenalidade = (id) => {
    if(isNaN(Number(id))){
      if (window.history.state && window.history.state?.idx > 1) {
        navigate(-1, { replace: true });
      } else {
        navigate('/', { state: { referrer: location }, replace: true });
      }
    } else {
      setLoading(true);
      Requisicao.getPenalidade(id)
        .then(data => {
          if (data != null) {
            console.log('data', data)
            setupPenalidade(data);
            setLoading(false);
          } else {
            setLoading(false);
            alert("Não foi possível encontrar o cadastro de penalidade desejado.");
            if (window.history.state && window.history.state?.idx > 1) {
              navigate(-1);
            } else {
              navigate('/', { state: { referrer: location }, replace: true });
            }
          }
        })
        .catch((erro) => {
          console.log(erro);
          setLoading(false);
        });
    }
  }

  const setupPenalidade = (p) => {

    let newPenalidade = {
      id: p.id,
      orgaoResponsavel: p.orgaoResponsavel,
      numeroProcessoAdministrativo: p.numeroProcessoAdministrativo,
      numeroProcessoAutuacao: p.numeroProcessoAutuacao,
      objeto: p.objeto,
      razaoSocial: p.razaoSocial,
      cnpj: p.cnpj,
      tipoPenalidade: p.tipoPenalidade,
      numeroDiarioOficial: p.numeroDiarioOficial,
      dataPublicacaoDiarioOficial: isValid(parseISO(p.dataPublicacaoDiarioOficial)) ? parseISO(p.dataPublicacaoDiarioOficial) : null,
      folhaDiarioOficial: p.folhaDiarioOficial,
      dataInicioPunicao: isValid(parseISO(p.dataInicioPunicao)) ? parseISO(p.dataInicioPunicao) : null,
      dataTerminoPunicao: isValid(parseISO(p.dataTerminoPunicao)) ? parseISO(p.dataTerminoPunicao) : null,
      observacoes: p.observacoes,
    }
    
    setPenalidade(newPenalidade);
  }

  const carregarListas = () => {
    Requisicao.getListaOrgaos().then((data) => setListaOrgaos(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaTipoPenalidade().then((data) => setListaTipoPenalidade(data)).catch(erro => console.log('erro', erro));
  }


  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Cadastro de Penalidade</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Cadastro de Penalidade</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12">
          <div className="card">
            <div className="card-body">
              {
                loading ? <LoadingElement /> :
                  <>
                    <div className='d-flex flex-column px-2 my-2'>
                      <div className='col'>
                        <label>Razão Social</label>
                        <input id='inputRazaoSocial' type='text' className='form-control'
                          value={penalidade?.razaoSocial ?? ""} disabled/>
                      </div>
                      <div className='col'>
                        <label>CNPJ / CPF</label>
                        <input id='inputCNPJ' type='text' className='form-control'
                          value={Utilidades.adicionarMascaraCPFOuCNPJ(penalidade?.cnpj) ?? ""} disabled/>
                      </div>
                      <div className='col'>
                        <label>Tipo de Penalidade</label>
                        <input id={`comboboxTipoPenalidade`} type='text' className="form-control" value={EnumUtils.getNomeEnum(penalidade?.tipoPenalidade, listaTipoPenalidade)} disabled/>
                      </div>
                      <div className="col">
                        <label htmlFor="comboboxOrgaos">Órgão Solicitante</label>
                        <input id={`comboboxOrgaos`} type='text' className="form-control" value={EnumUtils.getNomeEnum(penalidade?.orgaoResponsavel, listaOrgaos)} disabled/>
                      </div>
                      <div className='col'>
                        <label>Número Processo Administrativo</label>
                        <input id='inputNumeroProcessoAdministrativo' type='text' className='form-control'
                          value={penalidade?.numeroProcessoAdministrativo ?? ""} disabled/>
                      </div>
                      <div className='col'>
                        <label>Número Processo de Autuação</label>
                        <input id='inputNumeroProcessoAutuacao' type='text' className='form-control'
                          value={penalidade?.numeroProcessoAutuacao ?? ""} disabled/>
                      </div>
                      <div className='col'>
                        <label>Objeto</label>
                        <textarea id='inputNumeroObjeto' rows={4} className='form-control' value={penalidade?.objeto ?? ""} disabled/>
                      </div>
                      <div className='col'>
                        <label>Data de Início da Punição</label>
                        <input id='datepickerDataInicioPunicao' type='text' className='form-control' value={Utilidades.getDataFormatada(penalidade?.dataInicioPunicao)} disabled />
                      </div>
                      <div className='col'>
                        <label>Data de Término da Punição</label>
                        <input id='datepickerDataTerminoPunicao' type='text' className='form-control' value={Utilidades.getDataFormatada(penalidade?.dataTerminoPunicao)} disabled />
                      </div>
                      <div className='col'>
                        <label>Nº do Diário Oficial</label>
                        <input id='inputNumeroDiarioOficial' type='text' className='form-control'
                          value={penalidade?.numeroDiarioOficial ?? ""} disabled />
                      </div>
                      <div className='col'>
                        <label>Folha do Diário Oficial</label>
                        <input id='inputFolhaDiarioOficial' type='text' className='form-control'
                          value={penalidade?.folhaDiarioOficial ?? ""} disabled />
                      </div>
                      <div className='col'>
                        <label>Data de Publicação no Diário Oficial</label>
                        <input id='datepickerDataPublicacaoDiarioOficial' type='text' className='form-control' value={Utilidades.getDataFormatada(penalidade?.dataPublicacaoDiarioOficial)} disabled />
                      </div>
                      <div className='col'>
                        <label>Observações</label>
                        <textarea id='inputNumeroObservacoes' rows={4} className='form-control'
                          value={penalidade?.observacoes ?? ""} disabled/>
                      </div>
                    </div>

                  </>
              }
              <br />
            </div>
          </div>
        </form>
      </div>

    </>
  )
}
