import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Requisicao from '../../helpers/Requisicao';
import Utilidades from '../../helpers/Utilidades';
import { addMonths, endOfMonth, isValid, parseISO } from 'date-fns';
import Tippy from '@tippyjs/react';
import Autenticar from '../../login/Autenticar';
import LoadingElement from '../../components/LoadingElement';
import EnumUtils from '../../helpers/EnumUtils';
import DatePicker from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";

export default function EditarContrato({ usuario, autenticado }) {
  const { contratoId } = useParams();
  const editando = isNaN(Number(contratoId)) ? false : true;

  const navigate = useNavigate();
  const location = useLocation();

  const month = ["janeiro","fevereiro","março","abril","maio","junho","julho","agosto","setembro","outubro","novembro","dezembro"];

  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [salvando, setSalvando] = useState(false);

  const [numeroProcesso, setNumeroProcesso] = useState("");
  const [listaProcessos, setListaProcessos] = useState(null);
  const [processo, setProcesso] = useState(null);

  const [listaOrgaos, setListaOrgaos] = useState([]);
  const [listaLeiLicitacao, setListaLeiLicitacao] = useState([]);
  const [listaModalidadeLicitacao, setListaModalidadeLicitacao] = useState([]);
  const [listaUnidadeGestora, setListaUnidadeGestora] = useState([]);
  const [listaTipoAditivoContrato, setListaTipoAditivoContrato] = useState([]);
  const [listaTipoAquisicao, setListaTipoAquisicao] = useState([]);
  const [listaSubtipoAquisicao, setListaSubtipoAquisicao] = useState([]);

  const [leiLicitacao, setLeiLicitacao] = useState("-1");
  const [modalidadeLicitacao, setModalidadeLicitacao] = useState("-1");
  const [orgaoSolicitante, setOrgaoSolicitante] = useState("-1");
  const [unidadeGestora, setUnidadeGestora] = useState("-1");
  const [tipoAditivoContrato, setTipoAditivoContrato] = useState("-1");

  const [numeroContrato, setNumeroContrato] = useState("");
  const [sufixoNumeroContrato, setSufixoNumeroContrato] = useState("");
  const [numAditivo, setNumAditivo] = useState("");
  const [numeroLicitacao, setNumeroLicitacao] = useState("");
  // const [numeroAta, setNumeroAta] = useState("");
  const [numeroProcessoMae, setNumeroProcessoMae] = useState("");
  const [objetoContrato, setObjetoContrato] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [empresa, setEmpresa] = useState("");
  const [dataInicio, setDataInicio] = useState(null);
  const [dataTermino, setDataTermino] = useState(null);
  const [valor, setValor] = useState("");

  const [valorMensal, setValorMensal] = useState("");
  const [valorTotal, setValorTotal] = useState("");
  const [valorAcrescimo, setValorAcrescimo] = useState("");
  const [valorSupressao, setValorSupressao] = useState("");
  const [percentualAcrescimo, setPercentualAcrescimo] = useState("");
  const [percentualSupressao, setPercentualSupressao] = useState("");
  const [percentualReflexoFinanceiro, setPercentualReflexoFinanceiro] = useState("");
  const [percentualApostilamento, setPercentualApostilamento] = useState("");
  const [valorAtualizadoContrato, setValorAtualizadoContrato] = useState("");
  const [observacoes, setObservacoes] = useState("");

  const [tipoAquisicao, setTipoAquisicao] = useState("-1");
  const [subtipoAquisicao, setSubtipoAquisicao] = useState("-1");

  const [veiculos, setVeiculos] = useState(null);
  const [imoveis, setImoveis] = useState(null);

  const [dataAssinatura, setDataAssinatura] = useState(null);
  const [dataPublicacaoDiarioOficial, setDataPublicacaoDiarioOficial] = useState(null);
  const [dataLimiteEnvioTCE, setDataLimiteEnvioTCE] = useState(null);
  const [dataAtoSIGFIS, setDataAtoSIGFIS] = useState(null);


  // const [ordemItem, setOrdemItem] = useState(1);





  //   const [contrato, setContrato] = useState({});

  // componentDidMount
  useEffect(() => {
    if (autenticado) {
      carregarListas();

      if (editando) {
        carregarContrato(contratoId);
      }



    }

    document.title = 'Contratos e Aditivos - Sistema de Controle Interno';
    document.title = `${editando ? 'Editar - ' : 'Novo - '}Contratos e Aditivos - Sistema de Controle Interno`;
    return (() => document.title = 'Sistema de Controle Interno')
    // eslint-disable-next-line
  }, [autenticado, contratoId, editando]);

  const carregarContrato = (id) => {
    setLoading (true);
    if(isNaN(Number(id))){
      if (window.history.state && window.history.state?.idx > 1) {
        navigate(-1, { replace: true });
      } else {
        navigate('/', { state: { referrer: location }, replace: true });
      }
    } else {
      Requisicao.getContrato(id)
        .then(data => {
          // console.log('contrato', data)
          setLoading(false);
          if (data != null) {
            setupContrato(data);
          } else {
            alert("Não foi possível encontrar este contrato.")
            if (window.history.state && window.history.state?.idx > 1) {
              navigate(-1);
            } else {
              navigate('/', { state: { referrer: location }, replace: true });
            }
          }
        })
        .catch((erro) => {
          console.log(erro);
          setLoading(false)
        });
    }
  }

  const setupContrato = (c) => {
    setNumeroProcesso(c.numeroProcesso);
    // carregarProcesso(c.numeroProcesso);
    if (c.numeroProcesso != null && c.numeroProcesso !== "") {
      Requisicao.getProcessoDadosBasicosPorNumero(c.numeroProcesso)
        .then((data) => {
          if (data != null) {
            document.getElementById('inputNumeroProcesso')?.classList.remove('is-invalid');
            document.getElementById('inputNumeroProcesso')?.classList.add('is-valid');
            setProcesso(data);
          } else {
            document.getElementById('inputNumeroProcesso')?.classList.remove('is-valid');
            document.getElementById('inputNumeroProcesso')?.classList.add('is-invalid');
          }
        })
        .catch(erro => {
          document.getElementById('inputNumeroProcesso')?.classList.remove('is-valid');
          document.getElementById('inputNumeroProcesso')?.classList.add('is-invalid');
          console.log('erro', erro);
        });
    }
    setLeiLicitacao(c.leiLicitacao);
    setModalidadeLicitacao(c.modalidadeLicitacao);
    setOrgaoSolicitante(c.orgaoResponsavel);
    setUnidadeGestora(c.unidadeGestora);
    setTipoAditivoContrato(c.tipoAditivoContrato);
    setNumeroContrato(c.numeroContrato ?? "");
    setSufixoNumeroContrato(c.sufixoNumeroContrato ?? "");
    setNumAditivo(c.numAditivo ?? "");
    setNumeroLicitacao(c.numeroLicitacao ?? "");
    setNumeroProcessoMae(c.numeroProcessoMae ?? "");
    setObjetoContrato(c.objetoContrato ?? "");
    setCnpj(c.cnpj ?? "");
    setEmpresa(c.empresa ?? "");
    setDataInicio(isValid(parseISO(c.dataInicio)) ? parseISO(c.dataInicio) : null);
    setDataTermino(isValid(parseISO(c.dataTermino)) ? parseISO(c.dataTermino) : null);
    setValor(c.valor ?? "");
    setValorMensal(c.valorMensal ?? "");
    setValorTotal(c.valorTotal ?? "");
    setValorAcrescimo(c.valorAcrescimo ?? "");
    setValorSupressao(c.valorSupressao ?? "");
    setPercentualAcrescimo(c.percentualAcrescimo ?? "");
    setPercentualSupressao(c.percentualSupressao ?? "");
    setPercentualReflexoFinanceiro(c.percentualReflexoFinanceiro ?? "");
    setPercentualApostilamento(c.percentualApostilamento ?? "");
    setValorAtualizadoContrato(c.valorAtualizadoContrato ?? "");
    setObservacoes(c.observacoes ?? "");
    setTipoAquisicao(c.tipoAquisicao);
    setSubtipoAquisicao(c.subtipoAquisicao);
    setVeiculos(c.veiculos);
    setImoveis(c.imoveis);
    setDataAssinatura(isValid(parseISO(c.dataAssinatura)) ? parseISO(c.dataAssinatura) : null);
    setDataPublicacaoDiarioOficial(isValid(parseISO(c.dataPublicacaoDiarioOficial)) ? parseISO(c.dataPublicacaoDiarioOficial) : null);
    setDataLimiteEnvioTCE(isValid(parseISO(c.dataLimiteEnvioTCE)) ? parseISO(c.dataLimiteEnvioTCE) : null);
    setDataAtoSIGFIS(isValid(parseISO(c.dataAtoSIGFIS)) ? parseISO(c.dataAtoSIGFIS) : null);
  }

  useEffect(() => {
    if (processo != null) {
      window.$(".select2bs4").select2({
        theme: "bootstrap4",
      });

      window.$(`#comboboxLeiLicitacao`).on('change', function (e) {
        e.target?.classList.remove("is-invalid");
        setLeiLicitacao(e.target.value);
        setModalidadeLicitacao("-1");
      });
      window.$(`#comboboxModalidadeLicitacao`).on('change', function (e) {
        e.target?.classList.remove("is-invalid");
        setModalidadeLicitacao(e.target.value);
      });
      window.$("#comboboxOrgaos").on('change', function (e) {
        e.target?.classList.remove("is-invalid");
        setOrgaoSolicitante(e.target.value);
      });
      window.$("#comboboxUnidadeGestora").on('change', function (e) {
        e.target?.classList.remove("is-invalid");
        setUnidadeGestora(e.target.value);
      });
      window.$("#comboboxTipoAditivoContrato").on('change', function (e) {
        e.target?.classList.remove("is-invalid");
        setTipoAditivoContrato(e.target.value);
      });
      window.$("#comboboxTipoAquisicao").on('change', function (e) {
        e.target?.classList.remove("is-invalid");
        setTipoAquisicao(e.target.value);
        setSubtipoAquisicao("-1");
      });
      window.$("#comboboxSubtipoAquisicao").on('change', function (e) {
        setSubtipoAquisicao(e.target.value);
      });

    }
  }, [processo])

  useEffect(() => {
    if (numeroProcesso != null && numeroProcesso !== "") {
      const timeOutId = setTimeout(() => fetchProcessoDebounced(numeroProcesso), 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [numeroProcesso]);

  useEffect(() => {
    if (numeroProcessoMae != null && numeroProcessoMae !== "") {
      const timeOutId = setTimeout(() => fetchProcessoMaeDebounced(numeroProcessoMae), 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [numeroProcessoMae]);

  const fetchProcessoDebounced = (numero) => {
    Requisicao.pesqusiarProcessoDadosBasicosPorNumero(numero).then((data) => setListaProcessos(data)).catch(erro => console.log('erro', erro));
  }

  const fetchProcessoMaeDebounced = (numero) => {
    Requisicao.pesqusiarProcessoDadosBasicosPorNumero(numero).then((data) => {
      // setListaProcessos(data);
      let list = document.getElementById('list_processos_mae');
      if (list != null) {
        list.innerHTML = '';

        data?.forEach((element) => {
          let option = document.createElement('option');
          option.value = element.numero;
          list.appendChild(option);
        })
      }
    }).catch(erro => console.log('erro', erro));
  }

  // datalist processo
  useEffect(() => {
    let list = document.getElementById('list_processos');
    list.innerHTML = '';

    listaProcessos?.forEach((element) => {
      let option = document.createElement('option');
      option.value = element.numero;
      list.appendChild(option);
    })
  }, [listaProcessos]);

  const carregarProcesso = (numero) => {
    if (numero != null && numero !== "") {
      setLoading(true)
      Requisicao.getProcessoDadosBasicosPorNumero(numero)
        .then((data) => {
          setupProcesso(data);
          setLoading(false);
        })
        .catch(erro => {
          document.getElementById('inputNumeroProcesso')?.classList.remove('is-valid');
          document.getElementById('inputNumeroProcesso')?.classList.add('is-invalid');
          console.log('erro', erro);
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    if (searchParams.has("numeroProcesso")) {
      let numero = searchParams.get("numeroProcesso");
      setNumeroProcesso(numero);
      carregarProcesso(numero);
    }
    // eslint-disable-next-line
  }, [searchParams])

  const handleChangeNumeroProccesso = (value) => {
    let elm = document.getElementById('inputNumeroProcesso');
    elm?.classList.remove('is-valid');
    elm?.classList.remove('is-invalid');
    setNumeroProcesso(value);
  }

  const setupProcesso = (p) => {
    // console.log('p', p)
    if (p != null) {
      let analises = p.analises;
      let maisRecente = null;
      if (analises != null && analises.length > 0) {
        for (let i = 0; i < analises.length; i++) {
          if (maisRecente == null) {
            maisRecente = analises[i];
          } else {
            if (parseISO(analises[i]?.dataCriacao) > maisRecente?.dataCriacao) {
              maisRecente = analises[i];
            }
          }
        }
      }
      if (maisRecente != null) {
        setLeiLicitacao(maisRecente.leiLicitacao);
        setModalidadeLicitacao(maisRecente.modalidadeLicitacao);
      }

      // let valorProcesso = 0;
      // if (maisRecente != null) {
      //   valorProcesso = Number(maisRecente?.valorEstimado);
      // }

      setOrgaoSolicitante(p?.orgaoSolicitante);

      document.getElementById('inputNumeroProcesso')?.classList.remove('is-invalid');
      document.getElementById('inputNumeroProcesso')?.classList.add('is-valid');
      setProcesso(p);
    } else {
      document.getElementById('inputNumeroProcesso')?.classList.remove('is-valid');
      document.getElementById('inputNumeroProcesso')?.classList.add('is-invalid');
    }
  }

  const carregarListas = () => {
    Requisicao.getListaOrgaos().then((data) => setListaOrgaos(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaLeisLicitacao().then((data) => setListaLeiLicitacao(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaModalidadesLicitacaoAtivas().then((data) => setListaModalidadeLicitacao(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaUnidadesGestoras().then((data) => setListaUnidadeGestora(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaTipoAditivoContrato().then((data) => setListaTipoAditivoContrato(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaTipoAquisicao().then((data) => setListaTipoAquisicao(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaSubtipoAquisicao().then((data) => setListaSubtipoAquisicao(data)).catch(erro => console.log('erro', erro));
  }

  const limparInterface = () => {
    setNumeroProcesso("");
    setProcesso(null);
    let elm = document.getElementById('inputNumeroProcesso');
    elm?.classList.remove('is-valid');
    elm?.classList.remove('is-invalid');

  }

  // preencher comboboxLeiLicitacao
  useEffect(() => {
    if (processo != null && document.querySelector(`#comboboxLeiLicitacao`)) {
      window.$(`#comboboxLeiLicitacao`).empty();
      let combobox = document.querySelector(`#comboboxLeiLicitacao`).options;

      combobox.add(new Option("Selecione a Lei da Licitação...", "-1"));
      listaLeiLicitacao?.forEach((element) => {
        combobox.add(new Option(element.nome, element.value));
      });

      // combobox[0].disabled = true;
      combobox.selectedIndex = 0;

      if (leiLicitacao != null) {
        for (let i = 0; i < combobox.length; i++) {
          if (leiLicitacao === combobox[i].value) {
            window.$(`#comboboxLeiLicitacao`).val(combobox[i].value).trigger("change");
          }
        }
      }
    }
  }, [listaLeiLicitacao, leiLicitacao, processo]);

  // Preencher e atualizar comboboxModalidadeLicitacao sempre que comboboxLeiLicitacao muda
  useEffect(() => {
    if (processo != null && document.querySelector(`#comboboxModalidadeLicitacao`)) {
      window.$(`#comboboxModalidadeLicitacao`).empty();
      let combobox = document.querySelector(`#comboboxModalidadeLicitacao`).options;

      combobox.add(new Option("Selecione a Modalidade da Licitação/Procedimento...", "-1"));
      listaModalidadeLicitacao?.forEach((element) => {
        if (leiLicitacao === element.leiValue) {
          combobox.add(new Option(element.nome, element.value));
        }
      });

      // combobox[0].disabled = true;
      combobox.selectedIndex = 0;

      if (modalidadeLicitacao != null) {
        for (let i = 0; i < combobox.length; i++) {
          if (modalidadeLicitacao === combobox[i].value) {
            window.$(`#comboboxModalidadeLicitacao`).val(combobox[i].value).trigger("change");
          }
        }
      }
    }
  }, [leiLicitacao, listaModalidadeLicitacao, modalidadeLicitacao, processo]);

  // preencher comboboxOrgaos
  useEffect(() => {
    if (processo != null && document.querySelector("#comboboxOrgaos")) {
      window.$("#comboboxOrgaos").empty();
      let combobox = document.querySelector("#comboboxOrgaos").options;

      combobox.add(new Option("Selecione o Órgão Solicitante...", "-1"));
      combobox[0].disabled = true;
      combobox.selectedIndex = 0;

      listaOrgaos.forEach((element, index) => {
        combobox.add(new Option(element.nome, element.value));
        if (orgaoSolicitante != null && orgaoSolicitante === element.value) {
          combobox.selectedIndex = index + 1;
        }
      });
    }
  }, [listaOrgaos, orgaoSolicitante, processo]);

  // preencher comboboxUnidadeGestora
  useEffect(() => {
    if (processo != null && document.querySelector("#comboboxUnidadeGestora")) {
      window.$("#comboboxUnidadeGestora").empty();
      let combobox = document.querySelector("#comboboxUnidadeGestora").options;

      combobox.add(new Option("Selecione a Unidade Gestora...", "-1"));
      combobox[0].disabled = true;
      combobox.selectedIndex = 0;

      listaUnidadeGestora.forEach((element, index) => {
        combobox.add(new Option(element.nome, element.value));
        if (unidadeGestora != null && unidadeGestora === element.value) {
          combobox.selectedIndex = index + 1;
        }
      });
    }
  }, [listaUnidadeGestora, unidadeGestora, processo]);

  // preencher comboboxTipoAditivoContrato
  useEffect(() => {
    if (processo != null && document.querySelector("#comboboxTipoAditivoContrato")) {
      window.$("#comboboxTipoAditivoContrato").empty();
      let combobox = document.querySelector("#comboboxTipoAditivoContrato").options;

      combobox.add(new Option("Selecione o tipo...", "-1"));
      combobox[0].disabled = true;
      combobox.selectedIndex = 0;

      listaTipoAditivoContrato.forEach((element, index) => {
        combobox.add(new Option(element.nome, element.value));
        if (tipoAditivoContrato != null && tipoAditivoContrato === element.value) {
          combobox.selectedIndex = index + 1;
        }
      });
    }
  }, [listaTipoAditivoContrato, tipoAditivoContrato, processo]);


  // preencher comboboxTipoAquisicao
  useEffect(() => {
    if (processo != null && document.querySelector(`#comboboxTipoAquisicao`)) {
      window.$(`#comboboxTipoAquisicao`).empty();
      let combobox = document.querySelector(`#comboboxTipoAquisicao`).options;

      combobox.add(new Option("Selecione o tipo de aquisição...", "-1"));
      listaTipoAquisicao?.forEach((element) => {
        combobox.add(new Option(element.nome, element.value));
      });

      combobox[0].disabled = true;
      combobox.selectedIndex = 0;

      if (tipoAquisicao != null) {
        for (let i = 0; i < combobox.length; i++) {
          if (tipoAquisicao === combobox[i].value) {
            window.$(`#comboboxTipoAquisicao`).val(combobox[i].value).trigger("change");
          }
        }
      }
    }
  }, [listaTipoAquisicao, tipoAquisicao, processo]);

  // Preencher e atualizar comboboxSubtipoAquisicao sempre que comboboxTipoAquisicao muda
  useEffect(() => {
    if (processo != null && document.querySelector(`#comboboxSubtipoAquisicao`)) {
      window.$(`#comboboxSubtipoAquisicao`).empty();
      let combobox = document.querySelector(`#comboboxSubtipoAquisicao`).options;

      combobox.add(new Option("Selecione o subtipo da aquisição...", "-1"));
      // combobox.add(new Option("(Em Branco)", "-1"));
      listaSubtipoAquisicao?.forEach((element) => {
        if (tipoAquisicao === element.aquisicaoValue) {
          combobox.add(new Option(element.nome, element.value));
        }
      });

      // combobox[0].disabled = true;
      combobox.selectedIndex = 0;

      if (subtipoAquisicao != null) {
        for (let i = 0; i < combobox.length; i++) {
          if (subtipoAquisicao === combobox[i].value) {
            window.$(`#comboboxSubtipoAquisicao`).val(combobox[i].value).trigger("change");
          }
        }
      }
    }
  }, [tipoAquisicao, listaSubtipoAquisicao, subtipoAquisicao, processo]);


  const adicionarVeiculo = () => {
    let arr = veiculos ?? [];
    let newOrdem = 1;
    if (arr != null && arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        arr[i].ordem = i + 1;

      }
      newOrdem = arr.length + 1;
    }

    setVeiculos([
      ...arr,
      {
        id: null,
        ordem: newOrdem,
        tipo: "",
        anoModelo: "",
        qtdLicitacao: 0,
        qtdRecebida: 0,
        tempoUsoMax: "",
        tempoReposicaoMax: "",
        combustivelIncluso: false,
        motoristaIncluso: false,
      }
    ]);
  }

  const onChangeVeiculo = (veiculo) => {
    let arr = veiculos;
    arr = arr.map((v) => {
      if (v.ordem === veiculo.ordem) {
        return veiculo;
      } else {
        return v;
      }
    })
    setVeiculos(arr);
  }

  const handleDeleteVeiculo = (veiculo) => {
    let newVeiculos = veiculos?.filter((i) => i.ordem !== veiculo.ordem);
    let ordem = 1;
    newVeiculos?.forEach((i) => i.ordem = ordem++);
    setVeiculos(newVeiculos);
  }


  const adicionarImovel = () => {
    let arr = imoveis ?? [];
    let newOrdem = 1;
    if (arr != null && arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        arr[i].ordem = i + 1;

      }
      newOrdem = arr.length + 1;
    }

    setImoveis([
      ...arr,
      {
        id: null,
        ordem: newOrdem,
        endereco: "",
        proprietario: "",
        objetoDestinacao: "",
        valorMensal: 0,
      }
    ]);
  }

  const onChangeImovel = (imovel) => {
    let arr = imoveis;
    arr = arr.map((i) => {
      if (i.ordem === imovel.ordem) {
        return imovel;
      } else {
        return i;
      }
    })
    setImoveis(arr);
  }

  const handleDeleteImovel = (imovel) => {
    let newImoveis = imoveis?.filter((i) => i.ordem !== imovel.ordem);
    let ordem = 1;
    newImoveis?.forEach((i) => i.ordem = ordem++);
    setImoveis(newImoveis);
  }

  const numeroProcessoValido = (input) => {
    let regex = /^[0-9]+\/[0-9]+$/i;
    return regex.test(input);
  }

  const submitForm = (e) => {
    // e.preventDefault();
    // e.stopPropagation();

    if(validateForm()){
      gravar();
      // console.log('dados', getDados());
    }
  }

  const validateForm = () => {
    
    let valido = true;
    if(orgaoSolicitante == null || orgaoSolicitante === "" || orgaoSolicitante === "-1" || !listaOrgaos.some(e => e.value === orgaoSolicitante)){
      valido = false;
      document.getElementById("comboboxOrgaos")?.classList.add("is-invalid");
    }
    if(unidadeGestora == null || unidadeGestora === "" || unidadeGestora === "-1" || !listaUnidadeGestora.some(e => e.value === unidadeGestora)){
      valido = false;
      document.getElementById("comboboxUnidadeGestora")?.classList.add("is-invalid");
    }
    // validar numero contrato
    if(!numeroProcessoValido(numeroContrato)){ // Mesmo padrão que o número do processo
      valido = false;
      document.getElementById("inputNumeroContrato")?.classList.add("is-invalid");
    }
    // se for aditivo ou apostilamento verificar se o numero é válido
    if(tipoAditivoContrato == null || tipoAditivoContrato === "" || tipoAditivoContrato === "-1" || !listaTipoAditivoContrato.some(e => e.value === tipoAditivoContrato)){
      valido = false;
      document.getElementById("comboboxTipoAditivoContrato")?.classList.add("is-invalid");
    }
    if((tipoAditivoContrato === "TERMO_ADITIVO" || tipoAditivoContrato === "TERMO_APOSTILAMENTO") && (numAditivo == null || numAditivo === "")){
      valido = false;
      document.getElementById("inputNumAditivo")?.classList.add("is-invalid");
    }
    if(tipoAquisicao == null || tipoAquisicao === "" || tipoAquisicao === "-1" || !listaTipoAquisicao.some(e => e.value === tipoAquisicao)){
      valido = false;
      document.getElementById("comboboxTipoAquisicao")?.classList.add("is-invalid");
    }
    // o processo pode ser nulo?
    if(numeroProcesso == null || numeroProcesso === "" || !numeroProcessoValido(numeroProcesso)){
      valido = false;
    }

    // if(leiLicitacao == null || leiLicitacao === "" || leiLicitacao === "-1" || !listaLeiLicitacao.some(e => e.value === leiLicitacao)){
    //   valido = false;
    //   document.getElementById("comboboxLeiLicitacao")?.classList.add("is-invalid");
    // }
    // if(modalidadeLicitacao == null || modalidadeLicitacao === "" || modalidadeLicitacao === "-1" || !listaModalidadeLicitacao.some(e => e.value === modalidadeLicitacao)){
    //   valido = false;
    //   document.getElementById("comboboxModalidadeLicitacao")?.classList.add("is-invalid");
    // }

    // console.log('valido', valido);
    if(!valido){
      alert("Preencha os campos corretamente.");
    }

    return valido;
  }

  const getDados = () => {
    let dados = {
      id: editando ? contratoId : null,
      orgaoResponsavel: orgaoSolicitante,
      unidadeGestora: unidadeGestora,
      numeroContrato: numeroContrato,
      sufixoNumeroContrato: sufixoNumeroContrato,
      tipoAditivoContrato: tipoAditivoContrato,
      numAditivo: numAditivo,
      tipoAquisicao: tipoAquisicao,
      subtipoAquisicao: subtipoAquisicao != null && subtipoAquisicao !== "" && subtipoAquisicao !== "-1" ? subtipoAquisicao : null,
      numeroProcesso: numeroProcesso,
      numeroProcessoMae: numeroProcessoMae,
      numeroLicitacao: numeroLicitacao,
      leiLicitacao: (listaLeiLicitacao.some(e => e.value === leiLicitacao) ? leiLicitacao : null),
      modalidadeLicitacao: (listaModalidadeLicitacao.some(e => e.value === modalidadeLicitacao) ? modalidadeLicitacao : null),
      cnpj: cnpj?.replace(/[^\d]+/g,''),
      empresa: empresa,
      objetoContrato: objetoContrato,
      dataInicio: dataInicio,
      dataTermino: dataTermino,
      valor: valor,
      valorMensal: valorMensal,
      valorTotal: valorTotal,
      valorAcrescimo: valorAcrescimo,
      valorSupressao: valorSupressao,
      percentualAcrescimo: percentualAcrescimo,
      percentualSupressao: percentualSupressao,
      percentualReflexoFinanceiro: percentualReflexoFinanceiro,
      periodicidadeInicioMes: tipoAditivoContrato === 'TERMO_APOSTILAMENTO' && dataInicio != null ? month[dataInicio?.getMonth()] : null,
      periodicidadeInicioAno: tipoAditivoContrato === 'TERMO_APOSTILAMENTO' && dataInicio != null ? dataInicio?.getFullYear() : null,
      periodicidadeFimMes: tipoAditivoContrato === 'TERMO_APOSTILAMENTO' && dataTermino != null ? month[dataTermino?.getMonth()] : null,
      periodicidadeFimAno: tipoAditivoContrato === 'TERMO_APOSTILAMENTO' && dataTermino != null ? dataTermino?.getFullYear() : null,
      percentualApostilamento: percentualApostilamento,
      valorAtualizadoContrato: valorAtualizadoContrato,
      observacoes: observacoes,
      
      dataLimiteEnvioTCE: dataLimiteEnvioTCE,
      dataAtoSIGFIS: dataAtoSIGFIS,
      dataAssinatura: dataAssinatura,
      dataPublicacaoDiarioOficial: dataPublicacaoDiarioOficial,
      
      veiculos: subtipoAquisicao === "LOCACAO_VEICULO" ? veiculos : null,
      imoveis: tipoAquisicao === "LOCACAO_IMOVEL" ? imoveis : null,

    }
    
    // console.log('dados', dados)

    return dados;
  }

  const gravar = () => {
    setSalvando(true);
    window.toastr["info"]("Salvando...", '', { timeOut: 0, extendedTimeout: 0 });

    const dados = getDados();

    let url = null;

    if(editando){ //editar
      dados.id = contratoId;
      url = window.servidor + "/v1/protegido/contrato/editar"
      
    } else { //novo
      dados.id = null;
      url = window.servidor + "/v1/protegido/contrato/novo"
    }

    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(dados)
    };

    fetch(url, requestOptions)
        .then((response) => {
          if(response.status !== 200){
            setSalvando(false);
            throw new Error("Falha no cadastro/edição do contrato.")
          }
          return response.json();
        })
        .then((data) => {
          // alert("Aviso salvo com sucesso.");
          setSalvando(false);
          window.toastr.clear();
          if(data != null){
            window.toastr["success"]("Salvo com sucesso!");
          } else {
            window.toastr["error"]("Falha ao salvar. Verifique sua conexão com a internet.");
          }
          // console.log('salvo', data);
          if(!editando){
            navigate("/contrato/editar/" + data.id, { state:{referrer:location}, replace:true });
          }
        })
        .catch(erro => {
          setSalvando(false);
          window.toastr.clear();
          window.toastr["error"]("Falha ao salvar. Verifique sua conexão com a internet.");
          console.log(erro);
        });

  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">{editando ? 'Editar ' : 'Novo '}Aditivo/Contrato</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">{editando ? 'Editar ' : 'Novo '}Aditivo/Contrato</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div>
                <h4>Informações Gerais</h4>
              </div>
              <hr />
              {
                editando && loading ? <LoadingElement /> :
                  <>
                    <div className='col'>
                      <p>Primeiramente informe o Número do Processo:</p>
                    </div>
                    <div className='d-flex flex-column flex-md-row px-2'>
                      <div className='form-group col col-md-6'>
                        <label htmlFor='inputNumeroProcesso'>Número do Processo</label>
                        <div className='input-group'>
                          {
                            processo != null ?
                              <>
                                <input type='text' id='inputNumeroProcesso' className='form-control' list='list_processos' placeholder='Digite o número do processo...' pattern='^[0-9]+/[0-9]+$'
                                  onChange={(e) => handleChangeNumeroProccesso(e.target.value)} value={numeroProcesso}
                                  autoComplete='off' aria-describedby='inputNumeroProcessoFeedback' disabled
                                  onKeyDown={(e) => {
                                    if(e.key === 'Enter'){
                                      e.preventDefault();
                                      document.getElementById("button-addon-numero-processo")?.click();
                                    }
                                  }}></input>
                                <div className="input-group-append">
                                  <Tippy content='Limpar número do processo'>
                                    <button className="btn btn-outline-secondary rounded-right" type="button" id="button-addon-numero-processo"
                                      onClick={() => { limparInterface() }}><i className="fas fa-times"></i></button>
                                  </Tippy>
                                </div>
                              </> :
                              <>
                                <input type='text' id='inputNumeroProcesso' className='form-control' list='list_processos' placeholder='Digite o número do processo...' pattern='^[0-9]+/[0-9]+$'
                                  onChange={(e) => handleChangeNumeroProccesso(e.target.value)} value={numeroProcesso}
                                  autoComplete='off' aria-describedby='inputNumeroProcessoFeedback'
                                  onKeyDown={(e) => {
                                    if(e.key === 'Enter'){
                                      e.preventDefault();
                                      document.getElementById("button-addon-numero-processo")?.click();
                                    }
                                  }}></input>
                                <div className="input-group-append">
                                  <Tippy content='Carregar dados do processo'>
                                    <button className="btn btn-outline-secondary rounded-right" type="button" id="button-addon-numero-processo"
                                      onClick={() => { carregarProcesso(numeroProcesso) }} disabled={loading}>
                                        {
                                          loading ? 
                                          <span className="spinner-border spinner-border-sm" role="status"  aria-hidden="true"></span>
                                          : 
                                          <i className="fas fa-check"></i>
                                        }
                                      </button>
                                  </Tippy>
                                </div>
                              </>
                          }
                          <div id="inputNumeroProcessoFeedback" className="invalid-feedback mb-2">Processo não encontrado</div>
                        </div>
                        <div className='d-inline' id='processos_suggest_container'>
                          <datalist id="list_processos">
                          </datalist>
                        </div>
                      </div>
                      {
                        processo != null ?
                          <>
                            <div className='input-group form-group col col-md-6'>
                              <div className='col px-0'>
                                <label htmlFor='inputNumeroProcessoMae'>
                                  Número do Processo Mãe&nbsp;
                                  <Tippy content={`Número do(s) processo(s) anterior(es) referente(s) à este contrato/aditivo.`}>
                                    <i className='fas fa-info-circle text-info'></i>
                                  </Tippy>
                                </label>
                                <input type='text' id='inputNumeroProcessoMae' className='form-control' placeholder='Digite o número do processo mãe...' list='list_processos_mae' pattern='^[0-9]+/[0-9]+$'
                                  value={numeroProcessoMae}
                                  onChange={(e) => setNumeroProcessoMae(e.target.value)}></input>
                              </div>
                            </div>
                            <div className='d-inline' id='processos_mae_suggest_container'>
                              <datalist id="list_processos_mae">
                              </datalist>
                            </div>
                          </> : <></>
                      }
                    </div>
                    {
                      processo != null ?
                        <>
                          <div className="d-flex flex-column flex-md-row my-2 px-2">
                            <div className="col col-md-6">
                              <label htmlFor="comboboxOrgaos">Órgão Solicitante</label>
                              <select id="comboboxOrgaos" className="select2bs4" style={{ width: "100%" }} aria-describedby="comboboxOrgaosFeedback">
                              </select>
                            </div>
                            <div className="col col-md-6">
                              <label htmlFor={`comboboxUnidadeGestora`}>Unidade Gestora</label>
                              <select id={`comboboxUnidadeGestora`} className="form-control select2bs4" style={{ width: '100%' }}>
                              </select>
                            </div>
                          </div>
                          <div className="d-flex flex-column flex-md-row my-2 px-2">
                            <div className="col col-md-6">
                              <label htmlFor={`comboboxLeiLicitacao`}>Lei de Licitações/Serviços</label>
                              <select id={`comboboxLeiLicitacao`} className="form-control select2bs4" style={{ width: '100%' }}>
                              </select>
                            </div>
                            <div className="col col-md-6">
                              <label htmlFor={`comboboxModalidadeLicitacao`}>Modalidades de Licitações/Procedimentos</label>
                              <select id={`comboboxModalidadeLicitacao`} className="form-control select2bs4" style={{ width: '100%' }}>
                              </select>
                            </div>
                          </div>
                          <div className='d-flex flex-column flex-md-row my-2 px-2'>
                            <div className='form-group col col-md-6'>
                              <label htmlFor='inputEmpresa'>Pessoa Jurídica/Física</label>
                              <input type='text' id='inputEmpresa' className='form-control' placeholder='Digite o nome da pessoa jurídica/física...'
                                value={empresa}
                                onChange={(e) => setEmpresa(e.target.value)}></input>
                            </div>
                            <div className='form-group col col-md-6'>
                              <label htmlFor='inputCNPJ'>CNPJ/CPF</label>
                              <input type='text' id='inputCNPJ' className='form-control' placeholder='Digite o CNPJ ou CPF da pessoa jurídica/física...'
                                // onWheel={(e) => e.target.blur()}
                                value={cnpj}
                                onChange={(e) => setCnpj(e.target.value)}></input>
                            </div>
                          </div>
                          <div className="d-flex flex-column flex-md-row my-2 px-2">
                            <div className="col col-md-6">
                              <label htmlFor={`comboboxTipoAditivoContrato`}>Tipo de Aditivo/Contrato</label>
                              <select id={`comboboxTipoAditivoContrato`} className="form-control select2bs4 col" style={{ width: '100%' }}>
                              </select>
                            </div>
                            {
                              (tipoAditivoContrato === 'TERMO_ADITIVO' || tipoAditivoContrato === 'TERMO_APOSTILAMENTO') ?
                              <>
                                <div className="col col-md-4">
                                  <label>Ordem (número) do {EnumUtils.getNomeEnum(tipoAditivoContrato, listaTipoAditivoContrato)}&nbsp;
                                    <Tippy content={`Número cardinal referente à ordem do aditivo. Exemplo: No caso do 2º ${EnumUtils.getNomeEnum(tipoAditivoContrato, listaTipoAditivoContrato)} deverá ser inserido apenas o número 2.`}>
                                      <i className='fas fa-info-circle text-info'></i>
                                    </Tippy>
                                  </label>
                                  <input id="inputNumAditivo" type='number' onWheel={(e) => e.target.blur()} className='form-control' placeholder='Ex.: 1, 2, 3...'
                                    value={numAditivo} onChange={(e) => {
                                      if(e.target.value < 0){
                                        e.preventDefault();
                                      } else {
                                        e.target?.classList.remove("is-invalid");
                                        setNumAditivo(e.target.value);
                                      }
                                    }} 
                                    onKeyDown={(e) => {
                                      if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === ','){
                                        e.preventDefault();
                                      }
                                    }}/>
                                </div>
                                <div className="col col-md-2">
                                  <br className='d-none d-md-block'/>
                                  <p className='text-bold mt-3 mb-0'>
                                    {(numAditivo != null && numAditivo !== "") ? `→ ${numAditivo}º ${EnumUtils.getNomeEnum(tipoAditivoContrato, listaTipoAditivoContrato)}` : ""}
                                  </p>
                                </div>
                                </>
                                : <></>
                            }
                          </div>

                          <div className='d-flex flex-column flex-md-row my-2 px-2'>
                            <div className='form-group col col-md-4'>
                              <label htmlFor='inputNumeroContrato'>
                                {
                                  (tipoAditivoContrato != null && tipoAditivoContrato !== "-1" && tipoAditivoContrato !== "") ?
                                    `Número do(a) ${EnumUtils.getNomeEnum(tipoAditivoContrato, listaTipoAditivoContrato)}` :
                                    'Número do Contrato'
                                }
                              </label>
                              <div className='input-group'>
                                <input type='text' id='inputNumeroContrato' className='form-control' placeholder='Digite o número do contrato...' pattern='^[0-9]+/[0-9]+$'
                                  value={numeroContrato}
                                  onChange={(e) => setNumeroContrato(e.target.value)}></input>
                                <select id='comboboxSufixoNumeroContrato' className='form-control col-4'
                                  value={sufixoNumeroContrato} onChange={(e) => setSufixoNumeroContrato(e.target.value)}>
                                  <option value=''></option>
                                  <option value='FMS'>FMS</option>
                                  <option value='SEMINF'>SEMINF</option>
                                  <option value='SEMED'>SEMED</option>
                                </select>
                              </div>
                            </div>
                            <div className='form-group col col-md-4'>
                              <label htmlFor='inputNumeroLicitacao'>Número da Licitação</label>
                              <input type='text' id='inputNumeroLicitacao' className='form-control' placeholder='Digite o número da licitação...'
                                value={numeroLicitacao}
                                onChange={(e) => setNumeroLicitacao(e.target.value)}></input>
                            </div>
                            {/* <div className='form-group col col-md-4'>
                              <label htmlFor='inputNumeroAta'>Número da Ata</label>
                              <input type='text' id='inputNumeroAta' className='form-control' placeholder='Digite o número da ata...'
                                value={numeroAta}
                                onChange={(e) => setNumeroAta(e.target.value)}></input>
                            </div> */}
                          </div>
                          <div className='d-flex flex-column flex-md-row my-2 px-2'>
                            <div className='form-group col'>
                              <label htmlFor='textareaObjetoContrato'>
                                {
                                  (tipoAditivoContrato != null && tipoAditivoContrato !== "-1" && tipoAditivoContrato !== "") ?
                                    `Objeto do(a) ${EnumUtils.getNomeEnum(tipoAditivoContrato, listaTipoAditivoContrato)}` :
                                    'Objeto'
                                }
                              </label>
                              <textarea id='textareaObjetoContrato' className='form-control' rows={3} placeholder='Insira o objeto conforme o portal da transparência...'
                                value={objetoContrato} onChange={(e) => setObjetoContrato(e.target.value)} />
                            </div>
                          </div>

                          {
                            (tipoAditivoContrato === "TERMO_APOSTILAMENTO") ? // Apenas para data
                              <div className='d-flex flex-column flex-md-row my-2 px-2'>
                                <div className='form-group col col-md-4'>
                                  <label htmlFor={`datepickerDataInicio`}>Periodicidade Início&nbsp;
                                    <Tippy content='O usuário deverá inserir a periodicidade inicial (mês/ano), considerada no cálculo, que deverá constar no Termo de Referência/Nota de Solicitação de Despesas.'>
                                      <i className="fas fa-info-circle text-info"></i>
                                    </Tippy>
                                  </label>
                                  <div className='d-flex flex-column'>
                                    <DatePicker id={`datepickerDataInicio`} className="form-control col"
                                      selected={dataInicio}
                                      placeholderText="Selecione o mês e ano..."
                                      onChange={(date) => {
                                        setDataInicio(date);
                                      }}
                                      autoComplete='off'
                                      showMonthYearPicker
                                      showTwoColumnMonthYearPicker
                                      locale={ptBR} dateFormat="MMMM/yyyy" required />
                                  </div>
                                </div>
                                <div className='form-group col col-md-4'>
                                  <label htmlFor={`datepickerDataTermino`}>Periodicidade Fim&nbsp;
                                    <Tippy content='O usuário deverá inserir a periodicidade final (mês/ano), considerada no cálculo, que deverá constar no Termo de Referência/Nota de Solicitação de Despesas.'>
                                      <i className="fas fa-info-circle text-info"></i>
                                    </Tippy>
                                  </label>
                                  <div className='d-flex flex-column'>
                                    <DatePicker id={`datepickerDataTermino`} className="form-control col"
                                      selected={dataTermino}
                                      placeholderText="Selecione o mês e ano..."
                                      onChange={(date) => {
                                        setDataTermino(date);
                                      }}
                                      autoComplete='off'
                                      showMonthYearPicker
                                      showTwoColumnMonthYearPicker
                                      locale={ptBR} dateFormat="MMMM/yyyy" required />
                                  </div>
                                </div>
                                <div className='form-group col col-md-4'>
                                  <label htmlFor='inputValor'>
                                    Valor
                                  </label>
                                  <input type='number' id='inputValor' className='form-control' placeholder='Digite o valor do contrato...'
                                    onWheel={(e) => e.target.blur()}
                                    value={valor}
                                    onChange={(e) => {
                                      if(e.target.value < 0){
                                        e.preventDefault();
                                      } else {
                                        setValor(Utilidades.inputNumberFormatado(e.target.value))   
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                                        e.preventDefault();
                                      }
                                    }}></input>
                                </div>
                              </div> :
                              <div className='d-flex flex-column flex-md-row my-2 px-2'>
                                <div className='form-group col col-md-4'>
                                  <label htmlFor={`datepickerDataInicio`}>Data de Início</label>
                                  <div className='d-flex flex-column'>
                                    <DatePicker id={`datepickerDataInicio`} className="form-control col"
                                      selected={dataInicio}
                                      autoComplete='off'
                                      placeholderText="Clique para selecionar..."
                                      onChange={(date) => {
                                        setDataInicio(date);
                                      }}
                                      strictParsing
                                      showYearDropdown
                                      yearDropdownItemNumber={8}
                                      scrollableYearDropdown
                                      dateFormatCalendar="MMMM"
                                      locale={ptBR} dateFormat="dd/MM/yyyy" required />
                                  </div>
                                </div>
                                <div className='form-group col col-md-4'>
                                  <label htmlFor={`datepickerDataTermino`}>Data de Término</label>
                                  <div className='d-flex flex-column'>
                                    <DatePicker id={`datepickerDataTermino`} className="form-control col"
                                      selected={dataTermino}
                                      autoComplete='off'
                                      placeholderText="Clique para selecionar..."
                                      onChange={(date) => {
                                        setDataTermino(date);
                                      }}
                                      strictParsing
                                      showYearDropdown
                                      yearDropdownItemNumber={8}
                                      scrollableYearDropdown
                                      dateFormatCalendar="MMMM"
                                      locale={ptBR} dateFormat="dd/MM/yyyy" required />
                                  </div>
                                </div>
                                <div className='form-group col col-md-4'>
                                  <label htmlFor='inputValor'>
                                    Valor
                                  </label>
                                  <input type='number' id='inputValor' className='form-control' placeholder='Digite o valor do contrato...'
                                    onWheel={(e) => e.target.blur()}
                                    value={valor}
                                    onChange={(e) => {
                                      if(e.target.value < 0){
                                        e.preventDefault();
                                      } else {
                                        setValor(Utilidades.inputNumberFormatado(e.target.value))
                                      }
                                    }}
                                    onKeyDown={(e) => {
                                      if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                                        e.preventDefault();
                                      }
                                    }}></input>
                                </div>
                              </div>

                          }
                          {
                            (tipoAditivoContrato === "CONTRATO_ORIGINAL") ?
                              <>
                                {/* <div>
                                  <h4>Dados Adicionais do {EnumUtils.getNomeEnum(tipoAditivoContrato, listaTipoAditivoContrato)}</h4>
                                </div>
                                <hr /> */}
                                <div className='d-flex flex-column flex-md-row my-2 px-2'>
                                  <div className='form-group col col-md-4'>
                                    <label htmlFor='inputValorMensal'>
                                      Valor Mensal&nbsp;
                                      <Tippy content='O usuário deverá inserir o valor mensal do contrato, que deverá constar no Termo de Referência/Projeto Básico.'>
                                        <i className="fas fa-info-circle text-info"></i>
                                      </Tippy>
                                    </label>
                                    <input type='number' id='inputValorMensal' className='form-control' placeholder='Digite o valor do contrato...'
                                      onWheel={(e) => e.target.blur()}
                                      value={valorMensal}
                                      onChange={(e) => {
                                        if(e.target.value < 0){
                                          e.preventDefault();
                                        } else {
                                          setValorMensal(Utilidades.inputNumberFormatado(e.target.value))
                                        }
                                      }}
                                      onKeyDown={(e) => {
                                        if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                                          e.preventDefault();
                                        }
                                      }}></input>
                                  </div>
                                </div>

                              </> : <></>
                          }
                          {
                            (tipoAditivoContrato === "TERMO_ADITIVO") ?
                              <>
                                {/* <div>
                                  <h4>Dados Adicionais do {(numAditivo != null && numAditivo !== "") ? `${numAditivo}º ` : " "}{EnumUtils.getNomeEnum(tipoAditivoContrato, listaTipoAditivoContrato)}</h4>
                                </div>
                                <hr /> */}
                                <div className='d-flex flex-column flex-md-row my-2 px-2'>
                                  <div className='form-group col col-md-4'>
                                    <label htmlFor='inputValorMensal'>
                                      Valor Mensal&nbsp;
                                      <Tippy content='O usuário deverá inserir o valor mensal do contrato, que deverá constar no Termo de Referência/Projeto Básico.'>
                                        <i className="fas fa-info-circle text-info"></i>
                                      </Tippy>
                                    </label>
                                    <input type='number' id='inputValorMensal' className='form-control' placeholder='Digite o valor mensal do contrato...'
                                      onWheel={(e) => e.target.blur()}
                                      value={valorMensal}
                                      onChange={(e) => {
                                        if(e.target.value < 0){
                                          e.preventDefault();
                                        } else {
                                          setValorMensal(Utilidades.inputNumberFormatado(e.target.value))
                                        }
                                      }
                                      }
                                      onKeyDown={(e) => {
                                        if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                                          e.preventDefault();
                                        }
                                      }}></input>
                                  </div>
                                  <div className='form-group col col-md-4'>
                                    <label htmlFor='inputValorTotal'>
                                      Valor Total&nbsp;
                                      <Tippy content={`Valor total acumulado do contrato.`}>
                                        <i className='fas fa-info-circle text-info'></i>
                                      </Tippy>
                                    </label>
                                    <input type='number' id='inputValorTotal' className='form-control' placeholder='Digite o valor total do contrato...'
                                      onWheel={(e) => e.target.blur()}
                                      value={valorTotal}
                                      onChange={(e) => {
                                        if(e.target.value < 0){
                                          e.preventDefault();
                                        } else {
                                          setValorTotal(Utilidades.inputNumberFormatado(e.target.value))
                                        }
                                      }}
                                      onKeyDown={(e) => {
                                        if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                                          e.preventDefault();
                                        }
                                      }}></input>
                                  </div>
                                </div>
                                <div className='d-flex flex-column flex-md-row my-2 px-2'>
                                  <div className='form-group col col-md-3 col-xl-4'>
                                    <label>
                                      Valor de Acréscimo&nbsp;
                                      <Tippy content='O usuário deverá inserir o valor do acréscimo solicitado que deverá constar no Termo de Referência/Projeto Básico.'>
                                        <i className="fas fa-info-circle text-info"></i>
                                      </Tippy>
                                    </label>
                                    <input type='number' id={`inputValorAcrescimo`} className="form-control"
                                      onChange={(e) => {
                                        if(e.target.value < 0){
                                          e.preventDefault();
                                        } else {
                                          setValorAcrescimo(Utilidades.inputNumberFormatado(e.target.value))
                                        }
                                      }}
                                      value={valorAcrescimo}
                                      onWheel={(e) => e.target.blur()}
                                      placeholder={"Digite o valor de acréscimo..."} 
                                      onKeyDown={(e) => {
                                        if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                                          e.preventDefault();
                                        }
                                      }}/>
                                  </div>
                                  <div className='form-group col col-md-3 col-xl-2'>
                                    <label>
                                      % de Acréscimo&nbsp;
                                      <Tippy content='O usuário deverá inserir o percentual referente ao valor do acréscimo sobre o valor do contrato.'>
                                        <i className="fas fa-info-circle text-info"></i>
                                      </Tippy>
                                    </label>
                                    <div className='input-group'>
                                      <input type='number' id={`inputPercentualAcrescimo`} className="form-control"
                                        onChange={(e) => {
                                          if(e.target.value < 0){
                                            e.preventDefault();
                                          } else {
                                            setPercentualAcrescimo(Utilidades.inputNumberFormatado(e.target.value))
                                          }
                                        }}
                                        value={percentualAcrescimo}
                                        onWheel={(e) => e.target.blur()}
                                        placeholder={"Digite a % de acréscimo..."} 
                                        onKeyDown={(e) => {
                                          if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                                            e.preventDefault();
                                          }
                                        }}/>
                                      <div className="input-group-append">
                                        <span className="input-group-text">%</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='form-group col col-md-3 col-xl-4'>
                                    <label>
                                      Valor de Supressão&nbsp;
                                      <Tippy content='O usuário deverá inserir o valor da supressão solicitada que deverá constar no Termo de Referência/Projeto Básico.'>
                                        <i className="fas fa-info-circle text-info"></i>
                                      </Tippy>
                                    </label>
                                    <input type='number' id={`inputValorSupressao`} className="form-control"
                                      onChange={(e) => {
                                        if(e.target.value < 0){
                                          e.preventDefault();
                                        } else {
                                          setValorSupressao(Utilidades.inputNumberFormatado(e.target.value))
                                        }
                                      }}
                                      value={valorSupressao}
                                      onWheel={(e) => e.target.blur()}
                                      placeholder={"Digite o valor de supressão..."} 
                                      onKeyDown={(e) => {
                                        if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                                          e.preventDefault();
                                        }
                                      }}/>
                                  </div>
                                  <div className='form-group col col-md-3 col-xl-2'>
                                    <label>
                                      % de Supressão&nbsp;
                                      <Tippy content='O usuário deverá inserir o percentual referente ao valor da supressão sobre o valor do contrato.'>
                                        <i className="fas fa-info-circle text-info"></i>
                                      </Tippy>
                                    </label>
                                    <div className='input-group'>
                                      <input type='number' id={`inputPercentualSupressao`} className="form-control"
                                        onChange={(e) => {
                                          if(e.target.value < 0){
                                            e.preventDefault();
                                          } else {
                                            setPercentualSupressao(Utilidades.inputNumberFormatado(e.target.value))
                                          }
                                        }}
                                        value={percentualSupressao}
                                        onWheel={(e) => e.target.blur()}
                                        placeholder={"Digite a % de supressão..."} 
                                        onKeyDown={(e) => {
                                          if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                                            e.preventDefault();
                                          }
                                        }}/>
                                      <div className="input-group-append">
                                        <span className="input-group-text">%</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex flex-column flex-md-row my-2 px-2'>
                                  <div className='form-group col col-md-3'>
                                    <label>
                                      Reflexo Financeiro&nbsp;
                                      <Tippy content='O usuário deverá inserir o percentual referente ao reflexo financeiro da supressão e acréscimo realizados sobre o valor do contrato.'>
                                        <i className="fas fa-info-circle text-info"></i>
                                      </Tippy>
                                    </label>
                                    <div className='input-group'>
                                      <input type='number' id={`inputReflexoFinanceiro`} className="form-control"
                                        onChange={(e) => { setPercentualReflexoFinanceiro(Utilidades.inputNumberFormatado(e.target.value)) }}
                                        value={percentualReflexoFinanceiro}
                                        onWheel={(e) => e.target.blur()}
                                        placeholder={"Insira o reflexo financeiro..."} 
                                        onKeyDown={(e) => {
                                          if(e.key === '.' || e.key === 'e' || e.key === 'E'){
                                            e.preventDefault();
                                          }
                                        }}/>
                                      <div className="input-group-append">
                                        <span className="input-group-text">%</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </> : <></>
                          }
                          {
                            (tipoAditivoContrato === "TERMO_APOSTILAMENTO") ?
                              <>
                                {/* <div>
                                  <h4>Dados Adicionais do {(numAditivo != null && numAditivo !== "") ? `${numAditivo}º ` : " "}{EnumUtils.getNomeEnum(tipoAditivoContrato, listaTipoAditivoContrato)}</h4>
                                </div>
                                <hr /> */}
                                <div className='d-flex flex-column flex-md-row my-2 px-2'>
                                  <div className='form-group col col-md-4'>
                                    <label htmlFor='inputValorTotal'>
                                      Valor Total&nbsp;
                                      <Tippy content={`Valor total acumulado do contrato.`}>
                                        <i className='fas fa-info-circle text-info'></i>
                                      </Tippy>
                                    </label>
                                    <input type='number' id='inputValorTotal' className='form-control' placeholder='Digite o valor total do contrato...'
                                      onWheel={(e) => e.target.blur()}
                                      value={valorTotal}
                                      onChange={(e) => {
                                        if(e.target.value < 0){
                                          e.preventDefault();
                                        } else {
                                          setValorTotal(Utilidades.inputNumberFormatado(e.target.value));
                                        }
                                      }}
                                      onKeyDown={(e) => {
                                        if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                                          e.preventDefault();
                                        }
                                      }}></input>
                                  </div>
                                  <div className='form-group col col-md-3 col-xl-2'>
                                    <label htmlFor='inputPercentualApostilamento'>
                                      % Apostilamento&nbsp;
                                      <Tippy content='O usuário deverá inserir o percentual referente ao valor do reajuste/repactuação sobre o valor do contrato.'>
                                        <i className="fas fa-info-circle text-info"></i>
                                      </Tippy>
                                    </label>
                                    <div className='input-group'>
                                      <input type='number' id={`inputPercentualApostilamento`} className="form-control"
                                        onChange={(e) => {
                                          if(e.target.value < 0){
                                            e.preventDefault();
                                          } else {
                                            setPercentualApostilamento(Utilidades.inputNumberFormatado(e.target.value));
                                          }
                                        }}
                                        value={percentualApostilamento}
                                        onWheel={(e) => e.target.blur()}
                                        placeholder={"Digite a % do apostilamento..."} 
                                        onKeyDown={(e) => {
                                          if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                                            e.preventDefault();
                                          }
                                        }}/>
                                      <div className="input-group-append">
                                        <span className="input-group-text">%</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='form-group col col-md-4'>
                                    <label>Valor Atualizado do Contrato&nbsp;
                                      <Tippy content='O usuário deverá inserir o valor atualizado do contrato, ou seja, o valor originário somado ao valor do reajuste/repactuação, conforme informado no Termo de Referência/Nota de Solicitação de Despesas.'>
                                        <i className="fas fa-info-circle text-info"></i>
                                      </Tippy>
                                    </label>
                                    <input type='number' id='inputValorAtualizadoContrato' className='form-control' placeholder='Digite o valor atualizado do contrato...'
                                      onWheel={(e) => e.target.blur()}
                                      value={valorAtualizadoContrato}
                                      onChange={(e) => {
                                        if(e.target.value < 0){
                                          e.preventDefault();
                                        } else {
                                          setValorAtualizadoContrato(Utilidades.inputNumberFormatado(e.target.value))
                                        }
                                      }}
                                      onKeyDown={(e) => {
                                        if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                                          e.preventDefault();
                                        }
                                      }}></input>
                                  </div>
                                </div>
                              </> : <></>
                          }
                          {/* Problema com a Ata de Registro de Preços, quando o tipo for ATA em qual campo deve ser colocado o número da Ata? O campo numeroContrato deve sumir? */}
                          {/* {
                            (tipoAditivoContrato === "ATA") ?
                              <>
                              
                              </> : <></>
                          } */}



                          {/* <div>
                            <h4>Dados Adicionais</h4>
                          </div>
                          <hr /> */}
                          <div className='d-flex flex-column flex-md-row my-2 px-2'>
                            <div className='form-group col'>
                              <label htmlFor='textareaObservacoes'>
                                Observações
                              </label>
                              <textarea rows={3} className='form-control' id='textareaObservacoes' placeholder='Insira quaisquer observações referentes ao contrato...' value={observacoes} onChange={(e) => setObservacoes(e.target.value)} />
                            </div>
                          </div>
                          <hr />
                          <div className="d-flex flex-column flex-md-row my-2 px-2">
                            <div className="col col-md-6">
                              <label htmlFor={`comboboxTipoAquisicao`}>Tipo de Aquisição</label>
                              <select id={`comboboxTipoAquisicao`} className="form-control select2bs4" style={{ width: '100%' }}>
                              </select>
                            </div>
                            <div className="col col-md-6">
                              <label htmlFor={`comboboxSubtipoAquisicao`}>Subtipo</label>
                              <select id={`comboboxSubtipoAquisicao`} className="form-control select2bs4" style={{ width: '100%' }}>
                              </select>
                            </div>
                          </div>
                          {
                            subtipoAquisicao === "LOCACAO_VEICULO" ? 
                            <>
                              <hr />
                              <div>
                                <h4>Informações dos Veículos</h4>
                              </div>
                              {
                                veiculos != null && veiculos.length > 0 ? 
                                <>
                                  <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{width: "5%"}}>Item</th>
                                            <th scope="col" style={{width: "27%"}}>Tipo</th>
                                            <th scope="col" style={{width: "12%"}}>Ano/Modelo</th>
                                            <th scope="col" style={{width: "8%"}}>QTD. (Licitação)</th>
                                            <th scope="col" style={{width: "8%"}}>QTD. (Recebida)</th>
                                            <th scope="col" style={{width: "12%"}}>Tempo máx. uso</th>
                                            <th scope="col" style={{width: "12%"}}>Tempo máx. reposição</th>
                                            <th scope="col" style={{width: "8%"}}>Incluso Combustível</th>
                                            <th scope="col" style={{width: "8%"}}>Incluso Motorista</th>
                                        </tr>
                                    </thead>
                                    <tbody className="align-middle">
                                        {veiculos && veiculos.map((veiculo) => {
                                            return <tr key={veiculo.ordem}>
                                                <td>
                                                  <div className='d-flex flex-row'>
                                                    <div>
                                                      {veiculo.ordem}
                                                    </div>
                                                    <Tippy content={<>Excluir o veículo {veiculo.ordem}.<br />Esta ação é irreversível.</>}>
                                                      <button className='btn btn-sm text-danger mx-0' type="button" tabIndex={-1}
                                                        onClick={() => {
                                                          if(window.confirm("Deseja excluir o veículo " + veiculo.ordem + "?\n\nATENÇÃO: Esta ação é irreverssível.\n") === true){
                                                            handleDeleteVeiculo(veiculo);
                                                          }
                                                        }}>
                                                        <i className="fas fa-times"></i>
                                                      </button>
                                                    </Tippy>
                                                  </div>
                                                </td>
                                                <td>
                                                  <input type='text' className='form-control' placeholder=''
                                                    value={veiculo.tipo ?? ""} onChange={(e) => onChangeVeiculo({...veiculo, tipo: e.target.value})}/> 
                                                </td>
                                                <td>
                                                  <input type='text' className='form-control' placeholder=''
                                                    value={veiculo.anoModelo ?? ""} onChange={(e) => onChangeVeiculo({...veiculo, anoModelo: e.target.value})}/> 
                                                </td>
                                                <td>
                                                  <input type='number' className='form-control' placeholder='' onWheel={(e) => e.target.blur()}
                                                    value={veiculo.qtdLicitacao ?? 0} onChange={(e) => {
                                                      if(e.target.value < 0){
                                                        e.preventDefault();
                                                      } else {
                                                        onChangeVeiculo({...veiculo, qtdLicitacao: Utilidades.inputNumberFormatado(e.target.value)})
                                                      }
                                                    }}
                                                    onKeyDown={(e) => {
                                                      if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                                                        e.preventDefault();
                                                      }
                                                    }}/> 
                                                </td>
                                                <td>
                                                  <input type='number' className='form-control' placeholder='' onWheel={(e) => e.target.blur()}
                                                    value={veiculo.qtdRecebida ?? 0} onChange={(e) => {
                                                      if(e.target.value < 0){
                                                        e.preventDefault();
                                                      } else {
                                                        onChangeVeiculo({...veiculo, qtdRecebida: Utilidades.inputNumberFormatado(e.target.value)});
                                                      }
                                                    }}
                                                    onKeyDown={(e) => {
                                                      if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                                                        e.preventDefault();
                                                      }
                                                    }}/> 
                                                </td>
                                                <td>
                                                  <input type='text' className='form-control' placeholder=''
                                                    value={veiculo.tempoUsoMax ?? ""} onChange={(e) => onChangeVeiculo({...veiculo, tempoUsoMax: e.target.value})}/> 
                                                </td>
                                                <td>
                                                  <input type='text' className='form-control' placeholder=''
                                                    value={veiculo.tempoReposicaoMax ?? ""} onChange={(e) => onChangeVeiculo({...veiculo, tempoReposicaoMax: e.target.value})}/> 
                                                </td>
                                                <td>
                                                  <input type='checkbox' className='form-control text-center reduzir-scale'
                                                    checked={veiculo?.combustivelIncluso} onChange={(e) => onChangeVeiculo({...veiculo, combustivelIncluso: e.target.checked})} />
                                                </td>
                                                <td>
                                                  <input type='checkbox' className='form-control text-center reduzir-scale' 
                                                    checked={veiculo?.motoristaIncluso} onChange={(e) => onChangeVeiculo({...veiculo, motoristaIncluso: e.target.checked})} />
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                  </table>
                                </> : <></>
                              }

                              {

                              }

                              <div className='text-center'>
                                <button type='button' className='btn btn-outline-secondary' onClick={() => adicionarVeiculo()}>Adicionar Veículo</button>
                              </div>
                            </> : <></>
                          }

                          {
                            tipoAquisicao === "LOCACAO_IMOVEL" ? 
                            <>
                              <hr />
                              <div>
                                <h4>Informações dos Imóveis</h4>
                              </div>
                              {
                                imoveis != null && imoveis.length > 0 ? 
                                <>
                                  <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col" style={{width: "5%"}}>Item</th>
                                            <th scope="col" style={{width: "25%"}}>Endereço</th>
                                            <th scope="col" style={{width: "17%"}}>Proprietário</th>
                                            <th scope="col" style={{width: "25%"}}>Objeto / Destinação</th>
                                            <th scope="col" style={{width: "8%"}}>Valor Mensal</th>
                                            <th scope="col" style={{width: "20%"}}>Observações</th>
                                        </tr>
                                    </thead>
                                    <tbody className="align-middle">
                                        {imoveis && imoveis.map((imovel) => {
                                            return <tr key={imovel.ordem}>
                                                <td>
                                                  <div className='d-flex flex-row'>
                                                    <div>
                                                      {imovel.ordem}
                                                    </div>
                                                    <Tippy content={<>Excluir o imóvel {imovel.ordem}.<br />Esta ação é irreversível.</>}>
                                                      <button className='btn btn-sm text-danger mx-0' type="button" tabIndex={-1}
                                                        onClick={() => {
                                                          if(window.confirm("Deseja excluir o imóvel " + imovel.ordem + "?\n\nATENÇÃO: Esta ação é irreverssível.\n") === true){
                                                            handleDeleteImovel(imovel);
                                                          }
                                                        }}>
                                                        <i className="fas fa-times"></i>
                                                      </button>
                                                    </Tippy>
                                                  </div>
                                                </td>
                                                <td>
                                                  <input type='text' className='form-control' placeholder=''
                                                    value={imovel.endereco ?? ""} onChange={(e) => onChangeImovel({...imovel, endereco: e.target.value})}/> 
                                                </td>
                                                <td>
                                                  <input type='text' className='form-control' placeholder=''
                                                    value={imovel.proprietario ?? ""} onChange={(e) => onChangeImovel({...imovel, proprietario: e.target.value})}/> 
                                                </td>
                                                <td>
                                                  <input type='text' className='form-control' placeholder=''
                                                    value={imovel.objetoDestinacao ?? ""} onChange={(e) => onChangeImovel({...imovel, objetoDestinacao: e.target.value})}/> 
                                                </td>
                                                <td>
                                                  <input type='number' className='form-control' placeholder='' onWheel={(e) => e.target.blur()}
                                                    value={imovel.valorMensal ?? 0} onChange={(e) => {
                                                      if(e.target.value < 0){
                                                        e.preventDefault();
                                                      } else {
                                                        onChangeImovel({...imovel, valorMensal: Utilidades.inputNumberFormatado(e.target.value)});
                                                      }
                                                    }}
                                                    onKeyDown={(e) => {
                                                      if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                                                        e.preventDefault();
                                                      }
                                                    }}/> 
                                                </td>
                                                <td>
                                                  <input type='text' className='form-control' placeholder=''
                                                    value={imovel.observacao ?? ""} onChange={(e) => onChangeImovel({...imovel, observacao: e.target.value})}/> 
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                  </table>
                                </> : <></>
                              }

                              <div className='text-center'>
                                <button type='button' className='btn btn-outline-secondary' onClick={() => adicionarImovel()}>Adicionar Imóvel</button>
                              </div>
                            </> : <></>
                          }

                          <hr />
                          <div className='d-flex flex-column flex-md-row my-2 px-2'>
                            <div className='form-group col col-md-6'>
                              <label htmlFor={`datepickerDataAssinatura`}>Data da Assinatura</label>
                              <div className='d-flex flex-column'>
                                <DatePicker id={`datepickerDataAssinatura`} className="form-control col"
                                  selected={dataAssinatura}
                                  autoComplete='off'
                                  placeholderText="Clique para selecionar..."
                                  onChange={(date) => {
                                    setDataAssinatura(date);
                                    setDataLimiteEnvioTCE(endOfMonth(addMonths(date, 1)));
                                  }}
                                  strictParsing
                                  showYearDropdown
                                  yearDropdownItemNumber={8}
                                  scrollableYearDropdown
                                  dateFormatCalendar="MMMM"
                                  locale={ptBR} dateFormat="dd/MM/yyyy" required />
                              </div>
                            </div>
                            <div className='form-group col col-md-6'>
                              <label htmlFor={`datepickerDataPublicacaoDiarioOficial`}>Data de Publicação no Diário Oficial</label>
                              <div className='d-flex flex-column'>
                                <DatePicker id={`datepickerDataPublicacaoDiarioOficial`} className="form-control col"
                                  selected={dataPublicacaoDiarioOficial}
                                  autoComplete='off'
                                  placeholderText="Clique para selecionar..."
                                  onChange={(date) => {
                                    setDataPublicacaoDiarioOficial(date);
                                  }}
                                  strictParsing
                                  showYearDropdown
                                  yearDropdownItemNumber={8}
                                  scrollableYearDropdown
                                  dateFormatCalendar="MMMM"
                                  locale={ptBR} dateFormat="dd/MM/yyyy" required />
                              </div>
                            </div>
                          </div>

                          <hr />
                          <div className='d-flex flex-column flex-md-row my-2 px-2'>
                            <div className='form-group col col-md-6'>
                              <label htmlFor={`datepickerDataLimiteEnvioTCE`}>Data Limite de Envio ao TCE</label>
                              {/* <div className='d-flex flex-column'>
                                <DatePicker id={`datepickerDataLimiteEnvioTCE`} className="form-control col"
                                  selected={dataLimiteEnvioTCE}
                                  autoComplete='off'
                                  placeholderText="Clique para selecionar..."
                                  onChange={(date) => {
                                    setDataLimiteEnvioTCE(date);
                                  }}
                                  strictParsing
                                  showYearDropdown
                                  yearDropdownItemNumber={8}
                                  scrollableYearDropdown
                                  dateFormatCalendar="MMMM"
                                  locale={ptBR} dateFormat="dd/MM/yyyy" required />
                              </div> */}
                              <div className='text-center text-bold pt-2'>
                                {Utilidades.getDataFormatada(dataLimiteEnvioTCE)}
                              </div>
                            </div>
                            <div className='form-group col col-md-6'>
                              <label htmlFor={`datepickerDataAtoSIGFIS`}>Data de Lançamento no SIGFIS</label>
                              <div className='d-flex flex-column'>
                                <DatePicker id={`datepickerDataAtoSIGFIS`} className="form-control col"
                                  selected={dataAtoSIGFIS}
                                  autoComplete='off'
                                  placeholderText="Clique para selecionar..."
                                  onChange={(date) => {
                                    setDataAtoSIGFIS(date);
                                  }}
                                  strictParsing
                                  showYearDropdown
                                  yearDropdownItemNumber={8}
                                  scrollableYearDropdown
                                  dateFormatCalendar="MMMM"
                                  locale={ptBR} dateFormat="dd/MM/yyyy" required />
                              </div>
                            </div>
                          </div>






                        </> : <></>
                    }
                  </>
              }
              <br />
              <hr />
              {loading || processo == null ? <></> :
                <>
                  <div className='text-right'>
                    {salvando ?
                      <button type='button' className='btn btn-primary' onClick={void (0)} disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;Salvando...
                      </button>
                      : <button type='button' className='btn btn-primary' onClick={() => submitForm()}>Salvar</button>
                    }
                  </div>
                </>
              }
            </div>
          </div>
        </form>
      </div>

    </>
  )
}
