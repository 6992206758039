export const modelosCamposComplementares = [
    {
        id: 1,
        nome: "Quantidades de Lotes/Itens",
        tipo: "OBJETO",
        infoAdicional: ""
    }, 
    {
        id: 2,
        nome: "Pessoa Física/Pessoa Jurídica",
        tipo: "OUTROS",
        infoAdicional: ""
    }, 
    {
        id: 3,
        nome: "C.P.F./C.N.P.J.",
        tipo: "OUTROS",
        infoAdicional: ""
    }, 
    {
        id: 4,
        nome: "Nº da Ata",
        tipo: "OUTROS",
        infoAdicional: ""
    }, 
    {
        id: 5,
        nome: "Órgão Gerenciador da Ata",
        tipo: "OUTROS",
        infoAdicional: ""
    }, 
    {
        id: 6,
        nome: "Data base / Data de apresentação da proposta",
        tipo: "OUTROS",
        infoAdicional: ""
    }, 
    {
        id: 7,
        nome: "Objeto do Reajuste",
        tipo: "OBJETO",
        infoAdicional: ""
    }, 
    {
        id: 8,
        nome: "Objeto Contratado",
        tipo: "OBJETO",
        infoAdicional: ""
    }, 
    // {
    //     id: 9,
    //     nome: "Campo de Período de Tempo",
    //     tipo: "PERIODO",
    //     infoAdicional: ""
    // },
    {
        id: 10,
        nome: "Valor Estimado",
        tipo: "VALOR",
        infoAdicional: ""
    }, 
    {
        id: 11,
        nome: "Valor Solicitado",
        tipo: "VALOR",
        infoAdicional: ""
    }, 
    {
        id: 12,
        nome: "Valor Registrado",
        tipo: "VALOR",
        infoAdicional: ""
    }, 
    {
        id: 13,
        nome: "Campos de Valor e Período de Contrato, Aditivo e Termo",
        tipo: "ADITIVO",
        infoAdicional: ""
    },
    {
        id: 14,
        nome: "Número do Processo Originário",
        tipo: "OUTROS",
        infoAdicional: ""
    },
    {
        id: 15,
        nome: "Número do Contrato",
        tipo: "OUTROS",
        infoAdicional: ""
    },
    {
        id: 16,
        nome: "Pessoa Física/Jurídica + CPF/CNPJ + Campo de Texto",
        tipo: "CAMPOCONJUNTO_PFPJ_CPFCPNJ_TEXTO",
        infoAdicional: ""
    },
    {
        id: 17,
        nome: "Valor Mensal",
        tipo: "VALOR",
        infoAdicional: ""
    }, 
    {
        id: 18,
        nome: "Período da Locação",
        tipo: "OUTROS",
        infoAdicional: ""
    },
    {
        id: 19,
        nome: "Adotado Sistema de Registro de Preços",
        tipo: "BOOLEAN",
        infoAdicional: "SISTEMA_REGISTRO_PRECO"
    },
    {
        id: 20,
        nome: "Valor do Repasse",
        tipo: "VALOR",
        infoAdicional: ""
    },
    {
        id: 21,
        nome: "Período",
        tipo: "OUTROS",
        infoAdicional: ""
    },
    // {
    //     id: 22,
    //     nome: "Campo livre",
    //     tipo: "LIVRE",
    //     infoAdicional: ""
    // },
    {
        id: 23,
        nome: "Campo livre",
        tipo: "OUTROS",
        infoAdicional: "LIVRE"
    },
    {
        id: 24,
        nome: "Objeto Complementar",
        tipo: "OBJETO",
        infoAdicional: "OBJETO_COMPLEMENTAR"
    },
    {
        id: 25,
        nome: "Artigo e Inciso do Enquadramento da Contratação Direta",
        tipo: "OUTROS",
        infoAdicional: ""
    },
    {
        id: 26,
        nome: "Resultado do Processo de Contratação Direta",
        tipo: "CAMPO_COMPLEMENTAR_CONFORMIDADE",
        infoAdicional: ""
    },
    {
        id: 27,
        nome: "Resultado do Processo Licitatório",
        tipo: "CAMPO_COMPLEMENTAR_CONFORMIDADE",
        infoAdicional: ""
    },

]