import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// var arvoreiniciada = false;

export default function SidebarConsulta(props) {
  const navigate = useNavigate();

  const [searchNumProcesso, setSearchNumProcesso] = useState("");

  const location = useLocation();
  const usuario = props.usuario;

  useEffect(() => {
    const trees = window.$('[data-widget="treeview"]');
    if (!window.arvoreiniciada) {
      trees.Treeview('init');
      window.arvoreiniciada = true;
    }
  }, []);


  const submitSearch = (e) => {
    e.preventDefault();
    navigate("/processo/pesquisar?numero=" + searchNumProcesso);
  }


  return (
    <>
      {/* Main Sidebar Container */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Início da Logo da Controladoria + nome - Brand Logo */}
        <Link to={"/"} className="brand-link">
          <img src="/logo_mun_mini.png" alt="Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.9' }} />
          <span className="brand-text font-weight-light" style={{ fontSize: "1.05rem" }}>Sistema Controle Interno</span>
        </Link>
        {/* Fim da Logo da Controladoria + nome - Brand Logo */}
        {/* Início do menu lateral da página - Sidebar */}
        <div className="sidebar">
          {/* Início da Barra de Pesquisa lateral - Acrescentei uma Div para separar melhor do nome do sistema - SidebarSearch Form */}
          <div className="d-flex">
            <form className="form-inline mt-3 pb-3 user-panel" onSubmit={submitSearch}>
              <div className="input-group">
                <input className="form-control form-control-sidebar" type="search" placeholder="Número do Processo" aria-label="Search" value={searchNumProcesso} onChange={(e) => setSearchNumProcesso(e.target.value)} />
                <div className="input-group-append">
                  <button className="btn btn-sidebar" type='submit' >
                    <i className="fas fa-search fa-fw" style={{ width: "20px" }} />
                  </button>
                </div>
              </div>
            </form>
          </div>
          {/* Fim da Barra de Pesquisa lateral - SidebarSearch Form */}
          {/* Início dos Painéis - Sidebar Menu */}
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column nav-legacy nav-child-indent" data-widget="treeview" role="menu" data-accordion="false">
              {/* Add icons to the links using the .nav-icon class with font-awesome v5 or any other icon font library */}
              {/* Início do menu vertical identico ao menu horizontal*/}
              <li className="nav-item">
                <Link to={"/"} className="nav-link text-white">
                  <i className="nav-icon fas fa-th" />
                  <p>Início</p>
                </Link>
              </li>

              <li className="nav-item">
                <button className="nav-link btn text-white text-left" type="button">
                  <i className="nav-icon fas fa-copy" />
                  <p>
                    Processos
                    <i className="fas fa-angle-left right" />
                  </p>
                </button>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    {
                      location?.pathname === "/processo/pesquisar" ?
                        <Link to={"/processo/pesquisar"} reloadDocument className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Processo</p>
                        </Link> :
                        <Link to={"/processo/pesquisar"} className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Processo</p>
                        </Link>
                    }
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <button className="nav-link btn text-white text-left" type="button">
                  <i className="nav-icon fas fa-copy" />
                  <p>
                    Avisos de Licitação
                    <i className="fas fa-angle-left right" />
                  </p>
                </button>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    {
                      location?.pathname === "/avisolicitacao/pesquisar" ?
                        <Link to={"/avisolicitacao/pesquisar"} reloadDocument className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Avisos</p>
                        </Link> :
                        <Link to={"/avisolicitacao/pesquisar"} className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Avisos</p>
                        </Link>
                    }
                  </li>
                </ul>
              </li>

              <li className="nav-item">
                <button className="nav-link btn text-white text-left" type="button">
                  <i className="nav-icon fas fa-copy" />
                  <p>
                    Contratos
                    <i className="fas fa-angle-left right" />
                  </p>
                </button>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    {
                      location?.pathname === "/contrato/pesquisar" ?
                        <Link to={"/contrato/pesquisar"} reloadDocument className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Contratos</p>
                        </Link> :
                        <Link to={"/contrato/pesquisar"} className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Pesquisar Contratos</p>
                        </Link>
                    }
                  </li>
                </ul>
              </li>





              <li className="nav-item">
                <Link to={"/ajuda"} className="nav-link text-white">
                  <i className="far fa-circle nav-icon" />
                  <p>Ajuda</p>
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/contatos"} className="nav-link text-white">
                  <i className="far fa-circle nav-icon" />
                  <p>Contatos</p>
                </Link>
              </li>
              <li className='nav-item d-lg-none'>
                <button className="nav-link btn border-0" data-widget="pushmenu"><i className="fas fa-angle-double-left"></i></button>
              </li>

            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
      </aside>
    </>
  )
}
