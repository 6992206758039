import React, { useEffect, useReducer, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Autenticar from '../../login/Autenticar';
import Tippy from '@tippyjs/react';
import CampoPai from './CampoPai';
import { modelosCamposComplementares } from './ModelosCamposComplementares';

const blocosIniciais = [{
  ordem: 1,
  titulo: "",
  filhos: [{
    ordem: 1,
    idCampo: "",
    texto: ""
  }], 
  possuiCampoDeTextoInicial: false,
  possuiCampoDeTextoFinal: false,
  campoDeTextoInicial: "",
  campoDeTextoFinal: "",
  campoDeTextoInicialHTML: "",
  campoDeTextoFinalHTML: ""
}];

let nextId = 2;

let campoId = 1;

function blocosReducer(blocos, action) {
  switch (action.type) {
    case 'set_blocos_iniciais': {
      return action.blocos;
    }
    case 'add_bloco': {
      return [
        ...blocos,
        {
          ordem: action.ordem,
          titulo: action.titulo,
          filhos: [{
            ordem: 1,
            idCampo: "",
            texto: "", 
            possuiCampoDeTextoInicial: false,
            possuiCampoDeTextoFinal: false,
            campoDeTextoInicial: "",
            campoDeTextoFinal: "",
            campoDeTextoInicialHTML: "",
            campoDeTextoFinalHTML: ""
          }]
        }
      ]
    }
    case 'delete_bloco': {
      return blocos.filter((b) => parseInt(b.ordem) !== parseInt(action.ordem));
    }
    case 'change_bloco': {
      return blocos.map((b) => {
        if (b.ordem === action.bloco.ordem) {
          return action.bloco;
        } else {
          return b;
        }
      });
    }
    case 'limpar_blocos': {
      nextId = 2;
      return blocosIniciais;
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}

export default function EditarModeloAnalise() {

  const { modeloId } = useParams();

  const [blocos, dispatch] = useReducer(blocosReducer, blocosIniciais);
  const [camposComplementares, setCamposComplementares] = useState([]);

  const [nomeModelo, setNomeModelo] = useState("");

  const [leisLicitacao, setLeisLicitacao] = useState([]);
  const [tiposAnalise, setTiposAnalise] = useState([]);

  const [desativado, setDesativado] = useState(null);


  const [leiLicitacao, setLeiLicitacao] = useState("");
  const [leiInicial, setLeiInicial] = useState("");
  const [tipoDaAnalise, setTipoDaAnalise] = useState("");
  const [tipoInicial, setTipoInicial] = useState("");

  const [textoInicial, setTextoInicial] = useState("");
  const [textoFinal, setTextoFinal] = useState("");



  const limparInterface = () => {
    dispatch({
      type: 'limpar_blocos',
    });

    setCamposComplementares([]);
    campoId = 1;
    setNomeModelo("");
    setTipoDaAnalise("");
    setLeiLicitacao("");
    setTextoInicial("");
    setTextoFinal("");

    window.$("#comboboxTipoAnalise").val("-1").trigger("change");
    window.$("#comboboxLeiLicitacao").val("-1").trigger("change");

  }

  const carregarTipos = () => {
    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/analise/tiposanalise";

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setTiposAnalise(data);
      })
      .catch((erro) => console.log(erro));
  };

  const carregarModelo = () => {
    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/modelosanalise/get/" + modeloId;

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log('modelo', data);
        setupModelo(data);
      })
      .catch((erro) => console.log(erro));
  };

  const setupModelo = (data) => {
    data = ordenarBlocosCampos(data);
    dispatch({
      type: "set_blocos_iniciais",
      blocos: data.modelosBlocos,
    });
    setCamposComplementares(data.camposComplementares);

    setNomeModelo(data.nome);
    setTipoInicial(data.tipoAnalise);
    setLeiInicial(data.leiLicitacao ?? "");
    setTextoInicial(data.textoInicial);
    setTextoFinal(data.textoFinal);

    setDesativado(data?.desativado ?? null);
    
    for (let i = 0; i < data?.camposComplementares?.length; i++) {
      if(data.camposComplementares[i].ordem >= campoId){
        campoId = data.camposComplementares[i].ordem + 1;
      }
    }
    for (let i = 0; i < data?.modelosBlocos?.length; i++) {
      if(data.modelosBlocos[i].ordem >= nextId){
        nextId = data.modelosBlocos[i].ordem + 1;
      }
      
    }
  }

  const sortPorOrdem = (a, b) => {
    if(a.ordem < b.ordem) {
      return -1;
    }
    if(a.ordem > b.ordem) {
      return 1;
    }

    return 0;
  }

  const ordenarBlocosCampos = (data) => {
    if(data != null) {
      data.modelosBlocos.sort(sortPorOrdem);
      
      data.modelosBlocos.forEach((bloco) => {
        bloco.filhos.sort(sortPorOrdem);
        bloco.filhos.forEach((filho) => {
          filho.netos.sort(sortPorOrdem);
        });
        // bloco.recomendacoes.sort(sortPorOrdem);
      });

      data.camposComplementares.sort(sortPorOrdem);
    }

    return data;
  }

  const carregarLeis = () => {
    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/analise/leislicitacao";

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLeisLicitacao(data);
      })
      .catch((erro) => console.log(erro));
  };

  useEffect(() => {
    carregarTipos();
    carregarLeis();
    carregarModelo();

    window.$(document).on("DOMNodeInserted", '.note-editable', function (e) {
      if (e.target.tagName === "SPAN" && e.target.style?.getPropertyValue('background-color') === "") {
        window.$(e.target).replaceWith(window.$(e.target).contents());
      }
    });

    document.title = 'Editar Modelo da Análise - Sistema de Controle Interno';

    return (() => {
      window.$(document).off("DOMNodeInserted", '.note-editable');
      document.title = 'Sistema de Controle Interno';
    })
    // eslint-disable-next-line
  }, []);

  // preencher comboboxTipoAnalise
  useEffect(() => {
    window.$("#comboboxTipoAnalise").empty();
    let combobox = document.querySelector("#comboboxTipoAnalise").options;

    combobox.add(new Option("Selecione o Tipo da Análise", "-1"));
    tiposAnalise.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    combobox[0].disabled = true;

    if(tipoInicial != null && tipoInicial !== "-1"){
      let combobox = document.querySelector("#comboboxTipoAnalise").options;

      for (let i = 0; i < combobox.length; i++) {
        if(combobox[i].value === tipoInicial) {
          combobox.selectedIndex = i;
          setTipoDaAnalise(tipoInicial);
        }

      }
    } else {
      combobox.selectedIndex = 0;
    }

  }, [tiposAnalise, tipoInicial]);

  // preencher comboboxLeiLicitacao
  useEffect(() => {
    window.$("#comboboxLeiLicitacao").empty();
    let combobox = document.querySelector("#comboboxLeiLicitacao").options;

    combobox.add(new Option("Selecione a Lei da Licitação", "-1"));
    leisLicitacao.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    combobox[0].disabled = true;
    if(leiInicial != null && leiInicial !== "-1"){
      let combobox = document.querySelector("#comboboxLeiLicitacao").options;

      for (let i = 0; i < combobox.length; i++) {
        if(combobox[i].value === leiInicial) {
          combobox.selectedIndex = i;
          setLeiLicitacao(leiInicial);
        }
      }
    } else {
      combobox.selectedIndex = 0;
    }

  }, [leisLicitacao, leiInicial]);

  // iniciar combobox lei, modalidade e tipo
  useEffect(() => {
    window.$("#comboboxTipoAnalise").on('change', function (e) {
      setTipoDaAnalise(e.target.value);
    });
    window.$("#comboboxLeiLicitacao").on('change', function (e) {
      setLeiLicitacao(e.target.value);
    });
  }, []);

  // iniciar combobox dos campos complementares
  useEffect(() => {
    window.$(".select2bs4").select2({
      theme: "bootstrap4",
    });
    window.$("#comboboxCamposComplementares").empty();
    let comboboxOptions = document.querySelector("#comboboxCamposComplementares").options;


    comboboxOptions.add(new Option("Selecione o modelo do campo", "-1"));
    modelosCamposComplementares.forEach((element) => {
      comboboxOptions.add(new Option(element.nome, element.id));
    });

    comboboxOptions[0].disabled = true;
    comboboxOptions.selectedIndex = 0;
    // console.log(comboboxOptions);
    // window.$('#comboboxCamposComplementares').on('change', function(e) {
    //   setCampoSelecionado(e.target.value);
    // });
  }, []);


  function handleAddBloco() {
    dispatch({
      type: 'add_bloco',
      ordem: nextId++,
      titulo: "",
    })
  }

  function handleDeleteBloco(blocoOrdem) {
    dispatch({
      type: 'delete_bloco',
      ordem: blocoOrdem,
    });
  }

  function handleChangeBloco(bloco) {
    dispatch({
      type: 'change_bloco',
      bloco: bloco,
    });
  }

  function handleAddCampoComplementar() {
    // let campo = window.$("#comboboxCamposComplementares").select2("data");
    let id = document.getElementById("comboboxCamposComplementares").value;
    let campo = null;
    for (let i = 0; i < modelosCamposComplementares.length; i++) {
      if (parseInt(modelosCamposComplementares[i].id) === parseInt(id)) {
        campo = modelosCamposComplementares[i];
        break;
      }
    }
    // console.log('campo', campo)
    if (campo != null) {
      setCamposComplementares([
        ...camposComplementares, {
          ...campo,
          ordem: campoId++,

        }
      ])
    }
  }

  function handleDeleteCampoComplementar(campo) {
    let campos = camposComplementares.filter((c) => parseInt(campo.ordem) !== parseInt(c.ordem));

    campoId = 0;

    for (let i = 0; i < campos.length; i++) {
      campos[i].ordem = i + 1;
      campoId = i + 1;
    }

    campoId++;

    setCamposComplementares(campos);

  }

  const handleChangeNomeModelo = (e) => {
    setNomeModelo(e.target.value);
  }

  const handleChangeTextoInicial = (e) => {
    setTextoInicial(e.target.value);
  }

  const handleChangeTextoFinal = (e) => {
    setTextoFinal(e.target.value);
  }



  const validateForm = () => {
    let valido = true;
    console.log('tiposAnalise', tiposAnalise)
    console.log('tipoDaAnalise', tipoDaAnalise)
    // if(!tiposAnalise?.includes(tipoDaAnalise)){
    //   valido = false;
    // }
    if(!tiposAnalise.some(e => e.value === tipoDaAnalise)){
      valido = false;
    }

    // criar função para validar o formulário
    


    return valido;
  }

  const gravar = () => {
    let campos = camposComplementares;
    for (let i = 0; i < campos.length; i++) {
      campos[i].id = null;
    }

    const dados = {
      "id": modeloId,
      "nome": nomeModelo,
      "leiLicitacao": leiLicitacao,
      // "modalidadeLicitacao": modalidade,
      "tipoAnalise": tipoDaAnalise,
      "camposComplementares": campos,
      "textoInicial": textoInicial,
      "modelosBlocos": blocos,
      "textoFinal": textoFinal,
      "desativado": desativado
    }

    console.log('dados', dados)
    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(dados),
    };

    const url = window.servidor + "/v1/protegido/modelosanalise/editar";

    fetch(url, requestOptions)
      .then((response) => {
        // console.log('response', response);
        if(response.status !== 200){
          throw new Error("Falha na edição do Modelo de Análise.")
        } else {
          alert("Modelo editado com sucesso.");
        }
        // return response.json();
      })
      .catch((erro) => console.log(erro));

    // console.log('dados', JSON.stringify(dados));
    // console.log('dados', dados);
  }

  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (validateForm()) {
      gravar();
    } else {
      alert("Por favor preencha os campos corretamente.");
    }
  }


  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Edição de Modelo de Análise</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Edição de Modelo de Análise</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12" onSubmit={submitForm}>
          <div className="card">
            <div className="card-body">
              <div className="col">
                <div className='my-3'>
                  <p>Esta página é destinada à edição dos modelos de análises realizadas pela Subcontroladoria de Fiscalização e Controle.</p>
                </div>
                <div className='my-3'>
                  <label htmlFor='inputNomeModelo'>Nome do Modelo de Análise</label>
                  <textarea id='inputNomeModelo' rows={1} className='form-control' placeholder='Digite o nome do modelo de análise.' value={nomeModelo} onChange={handleChangeNomeModelo} required/>
                </div>
                <div className='my-3'>
                  <h4>Informações sobre o modelo</h4>
                  <p>
                    Este modelo é referente as seguintes categorias:
                  </p>
                </div>
                <div className='my-3 row'>
                  <div className="col-6">
                    <label htmlFor="comboboxLeiLicitacao">Lei de Licitações/Serviços</label>
                    <select id="comboboxLeiLicitacao" className="form-control select2bs4" style={{ width: '100%' }}>
                    </select>
                  </div>
                  {/* <div className="col-6">
                    <label htmlFor="comboboxModalidadeLicitacao">Modalidades de Licitações/Procedimentos</label>
                    <select id="comboboxModalidadeLicitacao" className="form-control select2bs4" defaultValue={"placeholder"} style={{ width: '100%' }}>
                    </select>
                  </div> */}
                </div>
                <div className="my-3 row">
                  <div className="col-6">
                    <label htmlFor="comboboxTipoAnalise">Tipo de Análise&nbsp;
                    <Tippy content={"Os campos: Número do Processo, Volumes do Processo, Órgão de Origem e Descrição do Objeto" +
                    "fazem parte dos dados básicos de um processo e sendo assim já são cadastrados no sistema na hora" +
                    "do cadastro do processo. No momento do preenchimento de uma análise relativa a determinado processo" +
                    "esses dados serão buscados e preenchidos automaticamente pelo sistema."}>
                      <i className="fas fa-question-circle" />
                    </Tippy>
                    </label>
                    <select id="comboboxTipoAnalise" className="form-control select2bs4" style={{ width: '100%' }}>
                    </select>
                  </div>
                </div>
                {/* <div className='my-3'>
                  <p>
                    <strong>Informação: </strong>Os campos: Número do Processo, Volumes do Processo, Órgão de Origem e Descrição do Objeto
                    fazem parte dos dados básicos de um processo e sendo assim já são cadastrados no sistema na hora
                    do cadastro do processo. <br />No momento do preenchimento de uma análise relativa a determinado processo
                    esses dados serão buscados e preenchidos automaticamente pelo sistema.
                  </p>
                </div> */}
                <div className='my-3'>
                  <h4>Corpo do modelo da análise</h4>
                  <label>Campos Complementares&nbsp;
                  <Tippy content={"Os campos complementares descritos abaixo variam de acordo com cada tipo de" +
                    "modelo de análise. Selecione na caixa de seleção os tipos de campos que o modelo desejado contém." +
                    "A ordem da lista denota a ordem que os campos apareceram para preenchimento na análise."}>
                      <i className="fas fa-question-circle" />
                    </Tippy>
                  </label>
                  {/* <p>
                    <strong>Atenção: </strong> Os campos complementares descritos abaixo variam de acordo com cada tipo de
                    modelo de análise. Selecione na caixa de seleção os tipos de campos que o modelo desejado contém.
                    A ordem da lista denota a ordem que os campos apareceram para preenchimento na análise.
                  </p> */}
                </div>
                <div className='my-3'>
                  <div className='card'>
                    <div className='card-header row'>
                      <select id="comboboxCamposComplementares" className='select2bs4 form-control col'>
                      </select>
                      <Tippy content="Adicionar Campo">
                        <button className="btn col-1 text-primary" type='button' onClick={() => handleAddCampoComplementar()}><i className="fa fa-plus" /></button>
                      </Tippy>
                    </div>
                    <div className='card-body'>
                      {
                        camposComplementares && camposComplementares.map((campo) => (
                          <div key={campo.ordem}>
                            <label>{campo.ordem}. {campo.nome}</label>&nbsp;
                            <Tippy content="Deletar campo">
                              <button className="btn col-1 text-danger" type='button' onClick={() => handleDeleteCampoComplementar(campo)}><span className='fa fa-trash'></span></button>
                            </Tippy>
                            <hr className='mt-0 pt-0'/>
                          </div>
                        ))
                      }
                    </div>


                  </div>
                </div>
                <div className='my-3'>
                  <label htmlFor='textareaTextoInicial'>Texto Inicial&nbsp;
                  <Tippy content={"O texto inicial estará presente logo após o cabeçalho da análise." +
                    "No momento do preenchimento de uma análise, outro campo de texto estará presente logo abaixo deste campo texto" +
                    "para que possam ser inseridas qualquer informação complementar."}>
                      <i className="fas fa-question-circle" />
                    </Tippy>
                  </label>
                  {/* <p>
                    O texto inicial estará presente logo após o cabeçalho da análise.
                    No momento do preenchimento de uma análise, outro campo de texto estará presente logo abaixo deste campo texto
                    para que possam ser inseridas qualquer informação complementar.
                  </p> */}
                  <textarea id='textareaTextoInicial' className='form-control' rows={4} placeholder={"Digite o texto inicial do modelo da análise."} value={textoInicial} onChange={handleChangeTextoInicial}></textarea>
                </div>
                <div className='my-3'>
                  <label>Blocos de Itens de Verificação</label>
                  <p>
                    Os blocos conterão os itens de verificação referentes à análise. Diversos blocos podem ser criados e cada um deles poderá ter um texto anterior e um posterior. 
                  </p>
                  <p>
                    Obs.: No caso de análises de manifestação com digitação livre e independente dos itens de verificação, excluir o primeiro campo filho que já vem adicionado quando se cria um bloco.
                  </p>
                  {
                    blocos.map((bloco) => (
                      <CampoPai key={bloco.ordem} bloco={bloco} onDeleteBloco={handleDeleteBloco} onChangeBloco={handleChangeBloco}></CampoPai>
                    ))
                  }
                </div>
              </div>
              <div className='text-center my-3'>
                <button type='button' className="btn btn-primary" onClick={handleAddBloco}>Adicionar Bloco de Itens de Verificação</button>
              </div>
              <div className='my-3'>
                <label htmlFor='textareaTextoFinal'>Texto Final&nbsp;
                <Tippy content={"O texto final estará presente logo após os blocos de itens de verificação."}>
                  <i className="fas fa-question-circle" />
                </Tippy></label>
                <textarea id='textareaTextoFinal' className='form-control' rows={4} placeholder={"Digite o texto final do modelo da análise."} value={textoFinal} onChange={handleChangeTextoFinal}></textarea>
              </div>
              
            </div>
            <div className="d-flex flex-row justify-content-end px-3 pb-3">
              <button type="button" className="btn btn-primary mx-2" onClick={limparInterface}>Limpar</button>
              <button type="submit" className="btn btn-primary">Salvar</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
