import React from 'react'

export default function LoadingElement() {
  return (
    <div className="text-center my-3 col px-0 mx-0">
        <div className="spinner-border" role="status">
        </div>
    </div>
  )
}
