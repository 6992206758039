import { isValid, parseISO } from "date-fns";
import Autenticar from "../login/Autenticar";

class Utilidades {

  aplicarMascaraValor(valor) {
    let countVirgula = 0;
    let countPonto = 0;

    let position = valor.indexOf(",");
    while (position !== -1) {
      countVirgula++;
      position = valor.indexOf(",", position + 1);
    }

    position = valor.indexOf(".");
    while (position !== -1) {
      countPonto++;
      position = valor.indexOf(".", position + 1);
    }

    if (countPonto === 1 && countVirgula === 0) {
      return valor.replaceAll(".", ",");
    }
    if (countPonto === 0 && countVirgula === 1) {
      return valor;
    }
    if (countPonto === 0 && countVirgula === 0) {
      return valor + ',00';
    }
  }

  getDataFormatada(data) {
    if(isValid(data)){
      return data.toLocaleDateString('pt-BR');
    }
    if(isValid(parseISO(data))){
      return parseISO(data).toLocaleDateString('pt-BR');
    }
    return "";
  }



  fromDateToLocalStringShort(date) {
    if(date != null && isValid(date)){
      return date.toLocaleDateString("pt-BR").substring(0, 10);
    } else {
      if(typeof date === 'string' || typeof date === 'number'){
        return date;
      }
      return '';
    }
  }

  removerIdsBlocosModelo(modelo) {
    modelo?.modelosBlocos.forEach((bloco) => {
      bloco.id = null;
      bloco.filhos.forEach((filho) => {
        filho.id = null;
        filho.netos.forEach((neto) => {
          neto.id = null;
        })
      })
    });

    return modelo;
  }

  sortPorOrdem(a, b) {
    if (a.ordem < b.ordem) {
      return -1;
    }
    if (a.ordem > b.ordem) {
      return 1;
    }
    return 0;
  }

  sortEnumPorNome(arr) {
    if(arr != null && arr instanceof Array){
      return arr.sort((a, b) => a.nome.localeCompare(b.nome));
    }
    return arr;
  }

  ordenarBlocosCamposModelo(modelo) {
    if (modelo != null) {

      modelo.modelosBlocos.sort(this.sortPorOrdem);

      modelo.modelosBlocos.forEach((bloco) => {
        bloco.filhos.sort(this.sortPorOrdem);
        bloco.filhos.forEach((filho) => {
          filho.netos.sort(this.sortPorOrdem);
        });
        // bloco.recomendacoes.sort(sortPorOrdem);
      });

      modelo.camposComplementares.sort(this.sortPorOrdem);
    }

    return modelo;
  }

  paraFloatPontoDecimal(valor) {
    if (valor != null) {

      valor = valor.toString();

      let countVirgula = 0;
      let countPonto = 0;

      let position = valor.indexOf(",");
      while (position !== -1) {
        countVirgula++;
        position = valor.indexOf(",", position + 1);
      }

      position = valor.indexOf(".");
      while (position !== -1) {
        countPonto++;
        position = valor.indexOf(".", position + 1);
      }

      if (countPonto === 0 && countVirgula === 1) {
        return valor.replaceAll(",", ".");
      }
      if (countPonto === 1 && countVirgula === 0) {
        return valor;
      }
      if (countPonto === 0 && countVirgula === 0) {
        return valor + '.00';
      }
      if (countPonto === 1 && countVirgula === 1) {
        if (valor.indexOf(",") < valor.indexOf(".")) {
          return valor.replaceAll(",", "");
        } else {
          return valor.replaceAll(".", "").replaceAll(",", ".");
        }
      }
      if (countPonto > 1 && countVirgula === 1) {
        return valor.replaceAll(".", "").replaceAll(",", ".");
      }
      if (countPonto === 1 && countVirgula > 1) {
        return valor.replaceAll(",", "");
      }
    } else {
      return null;
    }

  }

  getValorFormatado(valor) {
    if (Number.isNaN(Number(this.paraFloatPontoDecimal(valor)))) {
      return valor;
    }
    return Number(this.paraFloatPontoDecimal(valor)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  }

  swapArrayElements(arr, indexA, indexB) {
    var temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
    return arr;
  };

  inputNumberFormatado(numberString) {
    // let valor = valor.replace(/\b(0(?!\b))+/g, "");
    // if(valor === ""){
    //   valor = '0';
    // }
    let valor = numberString.replace(/^0+/, '');
    if (valor.length === 0) {
      valor = '0';
    }
    if (valor.charAt(0) === '.' || valor.charAt(0) === ',') {
      valor = '0' + valor;
    }
    return valor;
  }


  adicionarDiasUteis(dataInicial, diasUteis) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();
  
      const dados = {
        dataInicial: dataInicial.toISOString().substring(0,10),
        diasUteis: diasUteis
      }
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(dados),
      };
  
      const url = window.servidor + "/v1/protegido/feriado/adicionardiasuteis";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro)
        });
    })
  }

  /**
   * 
   * @param {Date} dataInicial 
   * @param {Date} dataFinal 
   * @returns {Promise<string>} A quantidade de dias úteis entre dataInicial incluso e DataFinal incluso
   */
  calcularDiasUteis(dataInicial, dataFinal){
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();
  
      const dados = {
        dataInicial: dataInicial.toISOString().substring(0,10),
        dataFinal: dataFinal.toISOString().substring(0,10)
      }
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(dados),
      };
  
      const url = window.servidor + "/v1/protegido/feriado/calculardiasuteis";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.text();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro)
        });
    })
  }

  validateCPF(cpf){	
    let rev;
    cpf = cpf?.replace(/[^\d]+/g,'');	
    if(cpf === '' || cpf == null) return false;	
    // Elimina CPFs invalidos conhecidos	
    if (cpf.length !== 11 || 
      cpf === "00000000000" || 
      cpf === "11111111111" || 
      cpf === "22222222222" || 
      cpf === "33333333333" || 
      cpf === "44444444444" || 
      cpf === "55555555555" || 
      cpf === "66666666666" || 
      cpf === "77777777777" || 
      cpf === "88888888888" || 
      cpf === "99999999999")
        return false;		
    // Valida 1o digito	
    let add = 0;	
    for (let i=0; i < 9; i ++)		
      add += parseInt(cpf.charAt(i)) * (10 - i);	
      rev = 11 - (add % 11);	
      if (rev === 10 || rev === 11)		
        rev = 0;	
      if (rev !== parseInt(cpf.charAt(9)))		
        return false;		
    // Valida 2o digito	
    add = 0;	
    for (let i = 0; i < 10; i ++)		
      add += parseInt(cpf.charAt(i)) * (11 - i);	
    rev = 11 - (add % 11);	
    if ( rev === 10 || rev === 11)	
      rev = 0;	
    if (rev !== parseInt(cpf.charAt(10)))
      return false;		
    return true;   
  }

  validateCNPJ(cnpj) {
 
    cnpj = cnpj?.replace(/[^\d]+/g,'');
    
    if(cnpj === '' || cnpj == null) return false;
     
    if (cnpj.length !== 14)
        return false;
 
    // Elimina CNPJs invalidos conhecidos
    if (cnpj === "00000000000000" || 
        cnpj === "11111111111111" || 
        cnpj === "22222222222222" || 
        cnpj === "33333333333333" || 
        cnpj === "44444444444444" || 
        cnpj === "55555555555555" || 
        cnpj === "66666666666666" || 
        cnpj === "77777777777777" || 
        cnpj === "88888888888888" || 
        cnpj === "99999999999999")
        return false;
         
    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2)
            pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado !== parseInt(digitos.charAt(0)))
        return false;
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0,tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado !== parseInt(digitos.charAt(1)))
          return false;
           
    return true;
    
  }

  adicionarMascaraCPF(cpf){
    if(!this.validateCPF(cpf)){
      return cpf;
    }
    return(cpf.substring(0, 3) + "." + cpf.substring(3, 6) + "." + cpf.substring(6, 9) + "-" + cpf.substring(9, 11));
  }

  adicionarMascaraCNPJ(cnpj){
    if(!this.validateCNPJ(cnpj)){
      return cnpj;
    }
    return (cnpj.substring(0, 2) + "." + cnpj.substring(2, 5) + "." + cnpj.substring(5, 8) + "/" + cnpj.substring(8, 12) + "-" + cnpj.substring(12, 14));
  }

  adicionarMascaraCPFOuCNPJ(str){
    if(this.validateCPF(str)){
      return(str.substring(0, 3) + "." + str.substring(3, 6) + "." + str.substring(6, 9) + "-" + str.substring(9, 11));
    }

    if(this.validateCNPJ(str)){
      return (str.substring(0, 2) + "." + str.substring(2, 5) + "." + str.substring(5, 8) + "/" + str.substring(8, 12) + "-" + str.substring(12, 14));
    }

    if(str?.length === 11) {
      return(str.substring(0, 3) + "." + str.substring(3, 6) + "." + str.substring(6, 9) + "-" + str.substring(9, 11));
    }
    if(str?.length === 14) {
      return (str.substring(0, 2) + "." + str.substring(2, 5) + "." + str.substring(5, 8) + "/" + str.substring(8, 12) + "-" + str.substring(12, 14));
    }

    return str;
  }


  JSONToCSVConverter(data){

    if(data == null || data?.length === 0){
      return '';
    }

    // const items = [...data]
    const items = data;
    const header = Object.keys(items[0])
    const replacer = (key, value) => value == null || value instanceof Array ? '' : value // specify how you want to handle null values here
    const csv = [
      header.join(','), // header row first
      ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer).replace(/\\"/g, '""')).join(','))
    ].join('\r\n')

    // console.log('csv', csv)

    return csv;
    
  }

  isValidJSONString(str){
    if(typeof str !== 'string' || str == null){
      return false;
    }

    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }

  }


}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new Utilidades();