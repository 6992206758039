import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Autenticar from "../../login/Autenticar";

export default function AlterarSenha(props) {
  // const navigate = useNavigate();
  // const location = useLocation();

  // const [login, setLogin] = useState("");
  const [senhaAtual, setSenhaAtual] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");

  const login = props.usuario.login;
  
  // const carregarUsuário = () => {
  //   const token = Autenticar.getLocalToken();

  //   const requestOptions = {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + token,
  //     }
  //   };

  //   const url = window.servidor + "/v1/protegido/usuario/get/" + usuarioId;

  //   fetch(url, requestOptions)
  //     .then((response) =>{
  //       if(response.ok){
  //         return response.json();
  //       } else {
  //         alert("Usuário não encontrado.");
  //         navigate("/", { replace: true });
  //       }
  //     }) 
  //     .then((data) => {
  //       console.log('data', data);
  //       setLogin(data.login);
  //     })
  //     .catch((erro) => console.log(erro));
  // };

  useEffect(()=>{
    document.title = 'Alterar Senha - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
  }, [])

  const handleSenhaAtualChange = (e) => {
    let maxLength = 150;
    if(e.target.value.length <= maxLength){
      setSenhaAtual(e.target.value);
    } else {
      setSenhaAtual(e.target.value.substr(0,maxLength));
    }
  }


  const handleSenhaChange = (e) => {
    let maxLength = 150;
    if(e.target.value.length <= maxLength){
      setSenha(e.target.value);
    } else {
      setSenha(e.target.value.substr(0,maxLength));
    }

    // if(e.target.value !== ""){
    //   e.target.classList.remove("is-invalid");
    //   e.target.classList.add("is-valid");
    // } else {
    //   e.target.classList.remove("is-valid");
    //   e.target.classList.add("is-invalid");
    // }
  }

  const handleConfirmarSenhaChange = (e) => {
    let maxLength = 150;
    if(e.target.value.length <= maxLength){
      setConfirmarSenha(e.target.value);
    } else {
      setConfirmarSenha(e.target.value.substr(0,maxLength));
    }

    if(e.target.value !== "" && e.target.value === senha){
      e.target.classList.remove("is-invalid");
      e.target.classList.add("is-valid");
    } else {
      e.target.classList.remove("is-valid");
      e.target.classList.add("is-invalid");
    }
  }

  // componentDidMount
  // useEffect(() => {
    // window.$('.select2').select2();
    // window.$(".select2bs4").select2({
    //   theme: "bootstrap4",
    // });
    // window.$('[data-toggle="switch"]').bootstrapSwitch();


    // carregarUsuário();
  // }, []);

  const limparInterface = () => {
    // setLogin("");
    setSenhaAtual("");
    setSenha("");
    setConfirmarSenha("");
  }



  const validateForm = () => {
    let valido = true;
    // if(!(login.length <= 150 && login !== "")){
    //   document.querySelector("#inputLogin").classList.remove("is-valid");
    //   document.querySelector("#inputLogin").classList.add("is-invalid");
    //   valido = false;
    // }
    // if(!(senha.length <= 150 && senha !== "")){
    //   document.querySelector("#inputSenha").classList.remove("is-valid");
    //   document.querySelector("#inputSenha").classList.add("is-invalid");
    //   valido = false;
    // }
    if(!(senhaAtual.length <= 150)){
      document.querySelector("#inputSenha").classList.remove("is-valid");
      document.querySelector("#inputSenha").classList.add("is-invalid");
      valido = false;
    }
    if(!(senha.length <= 150)){
      document.querySelector("#inputSenha").classList.remove("is-valid");
      document.querySelector("#inputSenha").classList.add("is-invalid");
      valido = false;
    }
    if(!(confirmarSenha.length <= 150 && confirmarSenha === senha)){
      document.querySelector("#inputConfirmarSenha").classList.remove("is-valid");
      document.querySelector("#inputConfirmarSenha").classList.add("is-invalid");
      valido = false;
    }
    
    return valido;
  }

  const gravar = () => {
    const dados = {
      "login": login,
      "senha": senha
    };
    // console.log('dados', dados);
    const token = Autenticar.getLocalToken();
            
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(dados)
    };

    const url = window.servidor + "/v1/protegido/usuario/alterarsenha"

    Autenticar.getToken(props.usuario.login, senhaAtual)
      .then(() => {
        fetch(url, requestOptions)
          .then((response) =>{
            console.log('response', response)
            if(response.status === 200){
              console.log('Senha alterada com sucesso.');
              alert("Senha alterada com sucesso.");
              // navigate(location.pathname);
              limparInterface();
            } else {
              alert("Erro ao alterar senha. Verifique os dados inseridos e tente novamente.")
            }
            // return response.text();
              // limparInterface();
          })
          // .then(data => console.log('data', data))
          .catch(erro => console.log(erro));
      })
      .catch(erro => {
        console.log('erro', erro);
        alert("Senha atual incorreta.");
      })

    
  }

  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // setTriedSubmit(true);
    // console.log(validateForm());

    if(validateForm()){
      gravar();
    }
  }

  
  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Cadastro de Usuário</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Cadastro de Usuário
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <form className="needs-validation" onSubmit={submitForm} noValidate>
            <div className="container-fluid">
              <div className="col d-flex">
                <div className="col">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">Alterar Senha</h3>
                    </div>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="inputSenha">Senha Atual</label>
                        <input
                          type="password"
                          className="form-control precisa-validar"
                          id="inputSenhaAtual"
                          aria-describedby="inputSenhaAtualFeedback"
                          value={senhaAtual}
                          onChange={handleSenhaAtualChange}
                          placeholder="Digite a sua senha atual"
                        />
                        <div
                          id="inputSenhaAtualFeedback"
                          className="invalid-feedback"
                        >
                          Digite sua nova senha.
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputSenha">Nova Senha</label>
                        <input
                          type="password"
                          className="form-control precisa-validar"
                          id="inputSenha"
                          aria-describedby="inputSenhaFeedback"
                          value={senha}
                          onChange={handleSenhaChange}
                          placeholder="Digite a senha desejada. Mínimo de 4 caracteres."
                        />
                        <div
                          id="inputSenhaFeedback"
                          className="invalid-feedback"
                        >
                          Digite sua nova senha.
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputConfirmarSenha">
                          Repita a Nova Senha
                        </label>
                        <input
                          type="password"
                          className="form-control precisa-validar"
                          id="inputConfirmarSenha"
                          aria-describedby="inputConfirmarSenhaFeedback"
                          value={confirmarSenha}
                          onChange={handleConfirmarSenhaChange}
                          placeholder="Digite novamente a senha desejada. Mínimo de 4 caracteres."
                        />
                        <div
                          id="inputConfirmarSenhaFeedback"
                          className="invalid-feedback"
                        >
                          Repita a nova senha corretamente.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end px-3 pb-3">
              <button type="button" className="btn btn-primary mr-2" onClick={limparInterface}>
                Limpar
              </button>
              <button type="submit" className="btn btn-primary">
                Salvar
              </button>
            </div>
          </form>
        </section>
      </div>
    </>
  );
}
