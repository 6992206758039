import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Utilidades from '../../helpers/Utilidades';
import Tippy from '@tippyjs/react';
import Autenticar from '../../login/Autenticar';
import Requisicao from '../../helpers/Requisicao';
// import { useInterval } from '../../helpers/useInterval';
import './PesquisaDePrecos.css'

export default function PesquisaDePrecos({ usuario, autenticado }) {

  const navigate = useNavigate();
  const location = useLocation();

  const [itens, setItens] = useState([]);
  const [empresas, setEmpresas] = useState([]);

  const [analise, setAnalise] = useState(null);

  const [media, setMedia] = useState([]);
  // const [mediaPonderada, setMediaPonderada] = useState([]);
  const [mediaSaneada, setMediaSaneada] = useState([]);
  const [mediana, setMediana] = useState([]);
  const [menorValor, setMenorValor] = useState([]);

  const [coefDesvioPadrao, setCoefDesvioPadrao] = useState([]);
  const [coefVariacaoSaneada, setCoefVariacaoSaneada] = useState([]);

  const [resultados, setResultados] = useState([]);

  // const cols = useRef(5);
  const [cols, setCols] = useState(5);

  const { pesquisaId } = useParams();
  const editando = isNaN(Number(pesquisaId)) ? false : true;
  
  const [analiseId, setAnaliseId] = useState(null);
  const [observacoes, setObservacoes] = useState("");

  const [salvando, setSalvando] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [adicionadoEmpresaManualmente, setAdicionadoEmpresaManualmente] = useState(false);
  const [adicionadoItemManualmente, setAdicionadoItemManualmente] = useState(false);

  const [exibindoCoeficientes, setExibindoCoeficientes] = useState(false);


  // useInterval(() => {
  //   console.log('Date.now()', Date.now())
  //   if(analiseId != null){
  //     let id = editando ? `pesquisaDePrecos-${pesquisaId}-${analiseId}` : `pesquisaDePrecos-0-${analiseId}`
  //     localStorage.setItem(id, JSON.stringify(getDados()));
  //   }
  // }, 20 * 1000);

  useEffect(() => {
    if (searchParams.has("analiseId")) {
      let id = searchParams.get("analiseId");
      if(!editando){
        carregarAnalise(id);
        setAnaliseId(id);
      }
    }
    console.log('analiseId', analiseId)
    // eslint-disable-next-line
  }, [searchParams, analiseId])

  const carregarAnalise = (id) => {
    if(isNaN(Number(id))){
      if (window.history.state && window.history.state?.idx > 1) {
        navigate(-1, { replace: true });
      } else {
        navigate('/', { state: { referrer: location }, replace: true });
      }
    } else {
      Requisicao.getAnaliseDadosBasicosPorId(id)
        .then(data => {
          if (data != null && isMesmaUnidadeOuAdministrador(data)) {
            setAnalise(data);
          } else {
            alert("Você não tem permissão para adicionar uma pesquisa de preços à esta análise ou não foi possível encontrar a análise.")
            if (window.history.state && window.history.state?.idx > 1) {
              navigate(-1);
            } else {
              navigate('/', { state: { referrer: location }, replace: true });
            }
          }
        })
        .catch((erro) => console.log(erro));
    }
  }

  useEffect(() => {
    if(!editando && analiseId == null && searchParams.get("analiseId") == null){
      // navigate(-1, { state: { referrer: location }, replace: true });
      if (window.history.state && window.history.state?.idx > 1) {
        navigate(-1);
      } else {
        navigate('/', { state: { referrer: location }, replace: true });
      }
    }
  }, [editando, analiseId, location, navigate, searchParams])

  // atalhos de teclado
  useEffect(() => {
    const atalhosTeclado = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 's') {
        e.preventDefault();
        if (!salvando) {
          document.getElementById('btnSalvarPesquisa').click();
        }
      }
      if(e.altKey && e.key === 'q'){
        document.getElementById('btnAdicionarItem').click();
      }
      if(e.altKey && e.key === 'w'){
        document.getElementById('btnAdicionarEmpresa').click();
      }
    }


    document.addEventListener('keydown', atalhosTeclado);

    return (() => {
      document.removeEventListener('keydown', atalhosTeclado);
    })
  }, [salvando])

  useEffect(() => {
    
    if (autenticado) {

      if (editando) {
        carregarPesquisaDePreco(pesquisaId);

      } else {
        setItens([
          {
            id: null,
            ordem: 1,
            codigo: "",
            descricao: "",
            unidade: "",
            quantidade: ""
          },
          {
            id: null,
            ordem: 2,
            codigo: "",
            descricao: "",
            unidade: "",
            quantidade: ""
          },
        ]);
        setEmpresas([
          {
            id: null,
            ordem: 1,
            nome: "",
            cnpj: "",
            orcamento: "",
            valorUnitario: [],
            valorTotal: []
          },
          {
            id: null,
            ordem: 2,
            nome: "",
            cnpj: "",
            orcamento: "",
            valorUnitario: [],
            valorTotal: []
          },
        ]);
      }
    }

    document.title = 'Pesquisa de Preços - Sistema de Controle Interno';

    return (() => {
      document.title = 'Sistema de Controle Interno';
    }
  );
    // eslint-disable-next-line
  }, [autenticado, editando])

  const isMesmaUnidadeOuAdministrador = (a) => {
    if (
      (parseInt(usuario?.unidadeId) === parseInt(a?.unidadeResponsavelId) || usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR" 
      // || (usuario?.permissoes?.includes("PERM_SUBGERAL_PESQUISA_DE_PRECOS"))
      )
      && a?.desativada !== true
      ) {
      return true;
    }
    return false;
  }

  const carregarPesquisaDePreco = (id) => {
    Requisicao.getPesquisaDePrecos(id)
      .then(data => {
        if(data != null){
          setupPesquisa(data);
        } else {
          alert("Pesquisa de preços não encontrada.");
          if (window.history.state && window.history.state?.idx > 1) {
            navigate(-1);
          } else {
            navigate('/', { replace: true });
          }
        }
      })
      .catch(erro => {
        console.log('erro', erro);
        alert("Pesquisa de preços não encontrada.");
        // console.log('window.history.state', window.history.state);
        if (window.history.state && window.history.state?.idx > 1) {
          navigate(-1);
        } else {
          navigate('/', { replace: true });
        }
      });
  }

  const setupPesquisa = (p) => {
    console.log('pesquisa', p)
    let newEmpresas = [];
    let newItens = [];

    p?.empresas?.forEach(e => {
      e?.itens?.forEach(item => {
        let itemAux = newItens.find(i => i.ordem === item.ordem);
        if(itemAux == null || itemAux === undefined){
          newItens.push(item);
        }
      })
    })
    newItens.sort((a, b) => a.ordem - b.ordem);

    p?.empresas?.forEach(e => {
      let newE = {
        id: e.id,
        ordem: e.ordem,
        nome: e.nome,
        cnpj: e.cnpj,
        orcamento: e.orcamento,
        valorUnitario: [],
        valorTotal: []
      }
      e?.itens?.forEach(item => {
        let index = newItens.findIndex(i => i.ordem === item.ordem);
        if(index !== -1){
          newE.valorUnitario[index] = item.valorUnitario;
          newE.valorTotal[index] = item.valorTotal;
        }
        // newE.valorUnitario.push(i.valorUnitario); // não funciona, e se o valor estiver vazio???
      })
      newEmpresas.push(newE);
    })
    newEmpresas.sort((a, b) => a.ordem - b.ordem);
    setItens(newItens);
    setEmpresas(newEmpresas);
    setAnaliseId(p.analiseId);
    setObservacoes(p.observacoes);
    // setResultados(p.resultados);
    
    let mean = [];
    let median = [];
    let minimum = [];
    // let weightedMean = [];
    let sanMean = [];

    p.resultados?.sort((a, b) => a.ordem - b.ordem);

    p.resultados?.forEach((r, index) => {
      mean.push(r.media);
      median.push(r.mediana);
      minimum.push(r.menorValor);
      sanMean.push(r.mediaSaneada);
    })
    if(p.resultados != null && p.resultados?.length > 0){
      setCols(13);
      setResultados(p.resultados);
      setMedia(mean);
      setMediana(median);
      setMenorValor(minimum);
      setMediaSaneada(sanMean);
    }

  }

  const adicionarItem = () => {
    setAdicionadoItemManualmente(true);
    if (itens != null && itens instanceof Array && itens.length > 0) {
      let ordem = 1;
      itens.forEach((item) => {
        item.ordem = ordem++;
      })
      setItens([
        ...itens,
        {
          id: null,
          ordem: ordem,
          codigo: "",
          descricao: "",
          unidade: "",
          quantidade: ""
        }
      ])
    } else {
      setItens([
        {
          id: null,
          ordem: 1,
          codigo: "",
          descricao: "",
          unidade: "",
          quantidade: ""
        }
      ])
    }
  }

  useEffect(() => {
    if(adicionadoItemManualmente){
      document.getElementById("tableContainer").scrollTop = document.getElementById("tableContainer").scrollHeight;
    }
  }, [itens.length, adicionadoItemManualmente])

  const adicionarEmpresa = () => {
    setAdicionadoEmpresaManualmente(true);
    if (empresas != null && empresas instanceof Array && empresas.length > 0) {
      let ordem = 1;
      empresas.forEach((empresa) => {
        empresa.ordem = ordem++;
      })
      setEmpresas([
        ...empresas,
        {
          id: null,
          ordem: ordem,
          nome: "",
          cnpj: "",
          orcamento: "",
          valorUnitario: [],
          valorTotal: []
        }
      ])
    } else {
      setEmpresas([
        {
          id: null,
          ordem: 1,
          nome: "",
          cnpj: "",
          orcamento: "",
          valorUnitario: [],
          valorTotal: []
        }
      ])
    }
  }

  useEffect(() => {
    if(adicionadoEmpresaManualmente){
      document.getElementById("tableContainer").scrollLeft = document.getElementById("tableContainer").scrollWidth;
    }
  }, [empresas.length, adicionadoEmpresaManualmente])

  const handleChangeItem = (item) => {
    let newItens = itens?.map((i) => {
      if (i.ordem === item.ordem) {
        return item;
      } else {
        return i;
      }
    })
    setItens(newItens);
  }

  const handleChangeValorTotal = (v, i) => {
    let newEmpresas = empresas;
    let qtd = v ?? 0;
    newEmpresas?.forEach(e => {
      let vu = e.valorUnitario[i] ?? 0
      e.valorTotal[i] = Number.parseFloat(vu*qtd).toFixed(2);
    })
    setEmpresas(newEmpresas);
  }

  const handleChangeEmpresa = (empresa) => {
    let newEmpresas = empresas?.map((e) => {
      if (e.ordem === empresa.ordem) {
        return empresa;
      } else {
        return e;
      }
    })
    setEmpresas(newEmpresas);
  }

  // const logDados = () => { //baseado nos itens, boa para exibição em json
  //   let dados = [];
  //   itens?.forEach((item, index) => {
  //     let newItem = {
  //       ordem: item.ordem,
  //       codigo: item.ordem,
  //       descricao: item.descricao,
  //       unidade: item.unidade,
  //       quantidade: item.quantidade,
  //       precos: []
  //     }
  //     empresas?.forEach(empresa => {
  //       if(empresa?.valorUnitario[index] != null || empresa?.valorTotal[index] != null){
  //         newItem.precos.push({
  //           ordem: empresa.ordem,
  //           nome: empresa.nome,
  //           cnpj: empresa.cnpj,
  //           orcamento: empresa.orcamento,
  //           data: new Date(),
  //           valorUnitario: empresa.valorUnitario[index],
  //           valorTotal: empresa.valorTotal[index]
  //         })
  //       }
  //     })
  //     dados.push(newItem);
  //   })

  //   let pesquisaDePreco = {
  //     id: isNaN(Number(pesquisaId)) ? pesquisaId : null,
  //     data: new Date(),
  //     analiseId: analiseId,
  //     itens: dados
  //   }

  //   console.log('dados', pesquisaDePreco);
  //   console.log('json', JSON.stringify(pesquisaDePreco, null, 2));
  // }


  const getDados = () => { // baseado nas empresas, boa para modelagem e trabalhar com os dados
    let dados = [];
    empresas?.forEach(empresa => {
      let newEmpresa = {
        ordem: empresa.ordem,
        nome: empresa.nome,
        cnpj: empresa.cnpj,
        orcamento: empresa.orcamento,
        itens: []
      };
      itens?.forEach((item, index) => [
        newEmpresa.itens.push({
          ordem: item.ordem,
          codigo: item.codigo,
          descricao: item.descricao,
          unidade: item.unidade,
          quantidade: item.quantidade,
          valorUnitario: empresa.valorUnitario[index],
          valorTotal: empresa.valorTotal[index]
        })
      ])
      dados.push(newEmpresa);
    })

    let pesquisaDePreco = {
      id: isNaN(Number(pesquisaId)) ? null : pesquisaId,
      // dataPesquisa: new Date(),
      dataPesquisa: new Date(Date.now() - (new Date().getTimezoneOffset() * 60*1000)).toJSON(),
      analiseId: analiseId,
      empresas: dados,
      observacoes: observacoes,
      resultados: resultados,
      nomeAnalista: usuario.nome,
      totalMenorValor: Number.parseFloat(calcularTotal(menorValor, itens)).toFixed(2),
      totalMedia: Number.parseFloat(calcularTotal(media, itens)).toFixed(2),
      totalMediana: Number.parseFloat(calcularTotal(mediana, itens)).toFixed(2),
      totalMediaSaneada: Number.parseFloat(calcularTotal(mediaSaneada, itens)).toFixed(2),
    }

    console.log('dados', pesquisaDePreco);
    // console.log('json', JSON.stringify(pesquisaDePreco, null, 2));
    return pesquisaDePreco;

  }

  const desvioPadrao = (arr) => {
    if(arr != null && arr instanceof Array && arr.length > 0){
      let media = getMedia(arr);
      // console.log('arr', arr)
  
      let sum = 0;
  
      if(media > 0){
        arr?.forEach(e => {
          sum = sum + ((e - media) ** 2);
        })
      }
      let dp = 0;
      if(arr.length > 1){
        dp = (Math.sqrt(sum/(arr.length - 1)));
      } else{
        dp = (Math.sqrt(sum/arr.length));
      }
      // console.log('desvioPadrão', dp)
      return dp;
    }
    return null;


  }

  const coeficienteVariacao = (arr) => {
    let dp = desvioPadrao(arr);
    let m = getMedia(arr);

    if(dp != null && m != null && m !== 0 && !isNaN(dp) && !isNaN(m)){
      return (dp * 100 / m);
    }

    return null;
  }

  const limiteSuperior = (arr) => {
    let dp = desvioPadrao(arr);
    let m = getMedia(arr);

    if(dp != null && m != null && m !== 0 && !isNaN(dp) && !isNaN(m)){
      return m + dp;
    }
    return null;
  }

  const limiteInferior = (arr) => {
    let dp = desvioPadrao(arr);
    let m = getMedia(arr);

    if(dp != null && m != null && m !== 0 && !isNaN(dp) && !isNaN(m)){
      return m - dp;
    }
    return null;
  }

  // const getMediaPonderada = (arr) => {
  //   let values = [];
  //   let weights = [];

  //   arr?.forEach((elm) => {
  //     if(values.indexOf(elm) !== -1){
  //       weights[values.indexOf(elm)]++;
  //     } else {
  //       values.push(elm);
  //       weights.push(1);
  //     }
  //   })
    
  //   if(values.length > 0 && values.length === weights.length){
  //     let numerador = 0;
  //     let denominador = 0;

  //     values?.forEach((elm, index) => {
  //       numerador = numerador + (Number(elm)*weights[index]);
  //       denominador = denominador + weights[index];
  //     })

  //     return numerador / denominador;
  //   }
  //   return 0;
  // }

  const getMedia = (arr) => {
    let sum = 0;
    let count = 0;

    arr?.forEach(e => {
      if(Number(e) > 0){
        sum = sum + Number(e);
        count++;
      }
    })

    if(count > 0){
      return sum / count;
    }

    return 0;
  }

  const getMediana = (arr) => {
    if(arr != null && arr instanceof Array && arr.length > 0){
      arr.sort((a,b) => a-b);

      if(arr.length % 2 === 0){
        return ((arr[(arr.length / 2) - 1] + arr[arr.length / 2]) / 2);
      } else{
        return arr[Math.floor(arr.length / 2)];
      }
    } else {
      return 0;
    }
  }

  const getMenorValor = (arr) => {
    if(arr != null && arr instanceof Array && arr.length > 0){
      arr.sort((a,b) => a-b);
      return arr[0];
    } else {
      return 0;
    }
  }

  const getMediaSaneada = (arr) => {
    if(arr != null && arr instanceof Array && arr.length > 0){
      let limit = 100;

      let newArr = [...arr];
      
      
      // console.log('newArr Entrada', newArr);
      while(newArr.length > 0 && coeficienteVariacao(newArr) > 25 && limit-- > 0){
        let li = limiteInferior(newArr);
        // console.log('limite inferior', li)
        let ls = limiteSuperior(newArr);
        // console.log('limite superior', ls)
        newArr = newArr.filter((e) => e >= li && e <= ls);
        // console.log('newArr', newArr);
        // console.log('coeficienteVariacao', coeficienteVariacao(newArr));
        
      }

      return getMedia(newArr);

    } else {
      return 0;
    }
  }

  const getArraySaneado = (arr) => {
    if(arr != null && arr instanceof Array && arr.length > 0){
      let limit = 100;

      let newArr = [...arr];
      
      
      // console.log('newArr Entrada', newArr);
      while(newArr.length > 0 && coeficienteVariacao(newArr) > 25 && limit-- > 0){
        let li = limiteInferior(newArr);
        // console.log('limite inferior', li)
        let ls = limiteSuperior(newArr);
        // console.log('limite superior', ls)
        newArr = newArr.filter((e) => e >= li && e <= ls);
        // console.log('newArr', newArr);
        // console.log('coeficienteVariacao', coeficienteVariacao(newArr));
        
      }

      return newArr

    } else {
      return arr;
    }
  }

  const calcular = () => {
    let mean = [];
    let median = [];
    let minimum = [];
    // let weightedMean = [];
    let sanMean = [];

    let cdp = [];
    let cvs = [];

    let valores = [];

    let result = [];

    itens?.forEach((item, index) => {
      valores = [];

      empresas?.forEach(empresa => {
        if(!isNaN(Number(empresa.valorUnitario[index])) && Number(empresa.valorUnitario[index]) > 0){
          let n = Number(empresa.valorUnitario[index]);
          valores.push(n);
        }
        if(valores.length > 0){
          valores.sort((a,b) => a-b);
        }
      })

      mean[index] = Number.parseFloat(getMedia(valores)).toFixed(2);
      median[index] = Number.parseFloat(getMediana(valores)).toFixed(2);
      minimum[index] = Number.parseFloat(getMenorValor(valores)).toFixed(2);
      // weightedMean[index] = Number.parseFloat(getMediaPonderada(valores)).toFixed(2);
      sanMean[index] = Number.parseFloat(getMediaSaneada(valores)).toFixed(2);

      result[index] = {
        id: resultados[index]?.id != null ? resultados[index]?.id : null,
        ordem: item.ordem,
        codigo: item.codigo,
        descricao: item.descricao,
        unidade: item.unidade,
        quantidade: item.quantidade,
        menorValor: minimum[index],
        media: mean[index],
        mediana: median[index],
        mediaSaneada: sanMean[index],
        // desvioPadrao: desvioPadrao(valores),
        // coeficienteVariacao: coeficienteVariacao(getArraySaneado(valores))

      }

      cdp[index] = Number.parseFloat(desvioPadrao(valores)).toFixed(2);
      cvs[index] = Number.parseFloat(coeficienteVariacao(getArraySaneado(valores))).toFixed(2);
      
    })

    // cols.current = 13;
    setCols(13);

    // console.log('mean', mean);
    // console.log('median', median);
    // console.log('minimum', minimum);
    // // console.log('weightedMean', weightedMean);
    // console.log('sanMean', sanMean);

    setMedia(mean);
    // setMediaPonderada(weightedMean);
    setMediana(median);
    setMenorValor(minimum);
    setMediaSaneada(sanMean);

    setCoefDesvioPadrao(cdp);
    setCoefVariacaoSaneada(cvs);

    setResultados(result);

    // document.getElementById("menorValorCol")?.scrollIntoView({inline: "end"});
    document.getElementById('tableContainer').scrollLeft = 0;
  }

  const exibirCoeficientes = () =>{
    calcular();
    setExibindoCoeficientes(true);

    setCols(15);
  }

  /**
   * 
   * @param {Array} arr 
   * @param {Array} arrItens 
   * @returns O valor da soma de todos os valores do primeiro array multiplicados por suas respectivas quantidades presentes no array de itens.
   */
  const calcularTotal = (arr, arrItens) => {
    let total = 0;
    arr?.forEach((e, index) => {
      if(!isNaN(Number(e)) && !isNaN(Number(arrItens[index]?.quantidade))){
        total += Number(e) * Number(arrItens[index]?.quantidade);
      }
    })

    return total;
  }

  const limparCalculos = () => {
    // cols.current = 5;
    setCols(5);
    setMedia([]);
    setMediana([]);
    setMenorValor([]);
    setMediaSaneada([]);
    setResultados([]);
    setExibindoCoeficientes(false);
  }

  const limparTabela = () => {
    setCols(5);
    setMedia([]);
    setMediana([]);
    setMenorValor([]);
    setMediaSaneada([]);

    setItens([
      {
        ordem: 1,
        codigo: "",
        descricao: "",
        unidade: "",
        quantidade: ""
      }
    ]);
    setEmpresas([
      {
        ordem: 1,
        nome: "",
        cnpj: "",
        orcamento: "",
        valorUnitario: [],
        valorTotal: []
      }
    ]);
    // adicionarEmpresa();
    // adicionarItem();
  }

  const handleDeleteEmpresa = (empresa) => {
    let newEmpresas = empresas?.filter((e) => e.ordem !== empresa.ordem);
    let ordem = 1;
    newEmpresas?.forEach((e) => e.ordem = ordem++);
    setEmpresas(newEmpresas);
  }

  const handleDeleteItem = (item) => {
    let newItens = itens?.filter((i) => i.ordem !== item.ordem);
    let ordem = 1;
    newItens?.forEach((i) => i.ordem = ordem++);
    setItens(newItens);
  }

  const submitForm = (e) => {
    // e.preventDefault();
    // e.stopPropagation();

    if(validateForm()){
      gravar();
    }
  }

  const validateForm = () => {
    let valido = true;

    return valido;
  }

  const gravar = () => {
    setSalvando(true);
    window.toastr["info"]("Salvando...", '', { timeOut: 0, extendedTimeout: 0 });

    const dados = getDados();

    let url = null;

    if(editando){ //editar
      dados.id = pesquisaId;
      url = window.servidor + "/v1/protegido/pesquisadeprecos/editar"
      
    } else { //novo
      dados.id = null;
      url = window.servidor + "/v1/protegido/pesquisadeprecos/novo"
    }

    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(dados)
    };

    fetch(url, requestOptions)
        .then((response) => {
          if(response.status !== 200){
            setSalvando(false);
            throw new Error("Falha no cadastro/edição da pesquisa de preços.")
          }
          return response.json();
        })
        .then((data) => {
          // alert("Aviso salvo com sucesso.");
          setSalvando(false);
          window.toastr.clear();
          if(data != null){
            window.toastr["success"]("Salvo com sucesso!");
          } else {
            window.toastr["error"]("Falha ao salvar. Verifique sua conexão com a internet.");
          }
          // console.log('salvo', data);
          if(!editando){
            navigate("/pesquisadeprecos/" + data.id, { state:{referrer:location}, replace:true });
          }
        })
        .catch(erro => {
          setSalvando(false);
          window.toastr.clear();
          window.toastr["error"]("Falha ao salvar. Verifique sua conexão com a internet.");
          console.log(erro);
        });

  }



  const moverItemParaCima = (p) => {
    if(itens != null && itens?.length > 0){
      let arr = [...itens];
      if(arr[0]?.ordem !== p?.ordem){
        let index = null;
        for(let i = 0; i < arr.length; i++){
          if(arr[i]?.ordem === p?.ordem){
            index = i;
            break;
          }
        }
        if(index != null && index !== 0){
          let temp = arr[index]?.ordem;
          arr[index].ordem = arr[index - 1]?.ordem;
          arr[index - 1].ordem = temp;
          arr = Utilidades.swapArrayElements(arr, index, index - 1);
          setItens(arr);
        }
      }
    }
  }

  const moverItensParaBaixo = (p) => {
    if(itens != null && itens?.length > 0){
      let arr = [...itens];
      if(arr[arr.length - 1]?.ordem !== p?.ordem){
        let index = null;
        for(let i = 0; i < arr.length; i++){
          if(arr[i]?.ordem === p?.ordem){
            index = i;
            break;
          }
        }
        if(index != null && index !== (arr.length - 1)){
          let temp = arr[index]?.ordem;
          arr[index].ordem = arr[index + 1]?.ordem;
          arr[index + 1].ordem = temp;
          arr = Utilidades.swapArrayElements(arr, index, index + 1);
          setItens(arr);
        }
      }
    }
  }
  

  return (
    <>
      <div className="modal fade" id="modalObservacoes" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Observações</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Insira aqui informações e observações referentes à pesquisa de preços.
              </p>
              <div className="my-3 row">
                {/* <label>Observações</label> */}
                <textarea rows={5} className='form-control mx-2' placeholder='Observações...' value={observacoes} onChange={(e) => setObservacoes(e.target.value)} />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => setObservacoes("")}>Limpar</button>
              <button type="button" className="btn btn-primary" data-dismiss="modal">Fechar</button>
            </div>
          </div>
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Pesquisa de Preços</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={`/analise/${analiseId}`}>Informações Análise</Link>
                  </li>
                  <li className="breadcrumb-item active">Pesquisa de Preços</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12">
          <div className="card">
            <div className="card-body">

              {/* <table className="table table-responsive table-head-fixed text-nowrap"> */}
              <div id="tableContainer" className='overflow-auto my-3 border div-pesquisa-imprimir' style={{ height: "60vh", resize: 'vertical' }}>
                <table className="table table-sm table-hover table-bordered tabela-pesquisa-preco tabela-pesquisa-imprimir" style={{ maxWidth: "100%", maxHeight: "500px" }}>
                  <colgroup>
                    <col span={5} />
                    {
                      (cols - 7 >= 0) ?
                        <col span={2} style={{backgroundColor: "#fdffc4"}}/> : <></>
                    }
                    {
                      (cols - 9 >= 0) ?
                        <col span={2} style={{backgroundColor: "#caffb5"}}/> : <></>
                    }
                    {
                      (cols - 11 >= 0) ?
                        <col span={2} style={{backgroundColor: "#fdffc4"}}/> : <></>
                    }
                    {
                      (cols - 13 >= 0) ?
                        <col span={2} style={{backgroundColor: "#caffb5"}}/> : <></>
                    }
                    {
                      (cols - 14 >= 0) ?
                        <col span={1} style={{backgroundColor: "#aad3e3"}}/> : <></>
                    }
                    {
                      (cols - 15 >= 0) ?
                        <col span={1} style={{backgroundColor: "#aad3e3"}}/> : <></>
                    }
                    {
                      empresas && empresas.map((empresa) => (
                        <React.Fragment key={empresa.ordem}>
                          <col span={2} style={empresa.ordem % 2 === 1 ? { backgroundColor: "#cccccc" } : {}} />
                        </React.Fragment>
                      ))
                    }
                  </colgroup>
                  <thead className="align-middle">
                    <tr>
                      <th scope="col" rowSpan={4} style={{ width: "120px" }} className='sticky-col first-col'>ITEM</th>
                      <th scope="col" rowSpan={4} style={{ width: "80px" }} className='sticky-col second-col text-wrap'>CÓD. CATMAT CATSER</th>
                      <th scope="col" rowSpan={4} style={{ width: "200px" }} className='sticky-col third-col'>DESCRIÇÃO</th>
                      <th scope="col" rowSpan={4} style={{ width: "80px" }} className='sticky-col fourth-col'>UND.</th>
                      <th scope="col" rowSpan={4} style={{ width: "80px" }} className='sticky-col fifth-col'>QTD.</th>
                      {
                        menorValor != null && menorValor.length > 0 ?
                          <>
                            <th id="menorValorCol" scope='col' rowSpan={4} style={{ width: "110px" }}>Menor Valor</th>
                            <th scope='col' rowSpan={4} style={{ width: "135px" }}>Total Menor Valor</th>
                          </>
                          : <></>
                      }
                      {
                        media != null && media.length > 0 ?
                          <>
                            <th scope='col' rowSpan={4} style={{ width: "110px" }}>Média</th>
                            <th scope='col' rowSpan={4} style={{ width: "135px" }}>Total Média</th>
                          </>
                          : <></>
                      }
                      {
                        mediana != null && mediana.length > 0 ?
                          <>
                            <th scope='col' rowSpan={4} style={{ width: "110px" }}>Mediana</th>
                            <th scope='col' rowSpan={4} style={{ width: "135px" }}>Total Mediana</th>
                          </>
                          : <></>
                      }
                      {/* {
                        mediaPonderada != null && mediaPonderada.length > 0 ?
                          <th scope='col' rowSpan={4} style={{ width: "100px" }}>Média Ponderada</th> : <></>
                      } */}
                      {
                        mediaSaneada != null && mediaSaneada.length > 0 ?
                          <>
                            <th scope='col' rowSpan={4} style={{ width: "110px" }}>Média Saneada</th>
                            <th scope='col' rowSpan={4} style={{ width: "135px" }}>Total Média Saneada</th>
                          </>
                          : <></>
                      }
                      {
                        exibindoCoeficientes ? 
                        <>
                          <th scope='col' rowSpan={4} style={{ width: "110px" }}>Desvio Padrão</th>
                          <th scope='col' rowSpan={4} style={{ width: "135px" }}>Variação Final (Saneada)</th>
                        </>
                        : <></>
                      }
                      {
                        empresas && empresas.map((empresa) => (
                          <React.Fragment key={empresa.ordem}>
                            <th scope="col" colSpan={2} style={{ width: "265px" }}>
                              <div className='d-flex flex-row'>
                                <input type='text' className='form-control form-control-sm ipnut-empresa-pesquisa-preco text-bold' placeholder='Nome da empresa...'
                                  value={empresa.nome ?? ""}
                                  onChange={(e) => handleChangeEmpresa({
                                    ...empresa,
                                    nome: e.target.value
                                  })} />
                                <Tippy content={<>Excluir a empresa{empresa.nome != null && empresa.nome !== "" ? ` ${empresa.nome}` : ""}.<br />Esta ação é irreversível.</>}>
                                  <button className='btn btn-sm text-danger mx-0 hide-print' type="button" tabIndex={-1}
                                    onClick={() => {
                                      if(window.confirm("Deseja excluir a empresa '" + empresa.nome + "'?\n\nATENÇÃO: Esta ação é irreverssível.\n") === true){
                                        handleDeleteEmpresa(empresa);
                                      }
                                    }}>
                                    <i className="fas fa-times"></i>
                                  </button>
                                </Tippy>
                              </div>
                            </th>
                          </React.Fragment>
                        ))
                      }
                    </tr>
                    <tr>
                      {
                        empresas && empresas.map((empresa) => (
                          <React.Fragment key={empresa.ordem}>
                            <th scope="col" colSpan={2}>
                              <input type='text' className='form-control form-control-sm ipnut-empresa-pesquisa-preco text-bold' placeholder='CPF/CNPJ...'
                                value={empresa.cnpj ?? ""}
                                onChange={(e) => handleChangeEmpresa({
                                  ...empresa,
                                  cnpj: e.target.value
                                })} />
                            </th>
                          </React.Fragment>
                        ))
                      }
                    </tr>
                    <tr>
                      {
                        empresas && empresas.map((empresa) => (
                          <React.Fragment key={empresa.ordem}>
                            <th scope="col" colSpan={2}>
                              <input type='text' className='form-control form-control-sm ipnut-empresa-pesquisa-preco text-bold' placeholder='Informações do orçamento...'
                                value={empresa.orcamento ?? ""}
                                onChange={(e) => handleChangeEmpresa({
                                  ...empresa,
                                  orcamento: e.target.value
                                })} />
                            </th>
                          </React.Fragment>
                        ))
                      }
                    </tr>
                    <tr>
                      {
                        empresas && empresas.map((empresa) => (
                          <React.Fragment key={empresa.ordem}>
                            <th scope="col" style={{ width: "50%" }}>Valor Unitário</th>
                            <th scope="col" style={{ width: "50%" }}>Valor Total</th>
                          </React.Fragment>
                        ))
                      }
                    </tr>
                  </thead>
                  <tbody className="align-middle">
                    {
                      itens && itens.map((item, index) => (
                        <tr key={item.ordem}>
                          <td className='sticky-col first-col'>
                            <div className='d-flex flex-row'>
                              <div className='btn-group btn-group-sm' role="group">
                                <Tippy content="Mover para cima">
                                  <button className="btn btn-sm btn-tool btn-outline-secondary my-0 text-info col" type='button' onClick={() => moverItemParaCima(item)}><i className='fas fa-chevron-up'></i></button>
                                </Tippy>
                                <Tippy content="Mover para baixo">
                                  <button className="btn btn-sm btn-tool btn-outline-secondary my-0 text-info col" type='button' onClick={() => moverItensParaBaixo(item)}><i className='fas fa-chevron-down'></i></button>
                                </Tippy>
                              </div>
                              <div className='ml-1 pt-1'>
                                {item.ordem}
                              </div>
                              <Tippy content={<>Excluir o item {item.ordem}.<br />Esta ação é irreversível.</>}>
                                  <button className='btn btn-sm text-danger mx-0 hide-print' type="button" tabIndex={-1}
                                    onClick={() => {
                                      if(window.confirm("Deseja excluir o item " + item.ordem + "?\n\nATENÇÃO: Esta ação é irreverssível.\n") === true){
                                        handleDeleteItem(item);
                                      }
                                    }}>
                                    <i className="fas fa-times"></i>
                                  </button>
                                </Tippy>
                            </div>
                          </td>
                          <td className='sticky-col second-col'>
                            <input type="text" id={`inputCodigo_${item.ordem}`} className='form-control form-control-sm' value={item.codigo ?? ""} 
                              onChange={(e) => { handleChangeItem({ ...item, codigo: e.target.value }) }}
                              // onWheel={(e) => e.target.blur()}
                              onKeyDown={(e) => {
                                if(e.key === 'ArrowUp'){
                                  e.preventDefault();
                                  document.getElementById(`inputCodigo_${item.ordem-1}`)?.focus();
                                }
                                if(e.key === 'ArrowDown' || e.key === 'Enter'){
                                  e.preventDefault();
                                  document.getElementById(`inputCodigo_${item.ordem+1}`)?.focus();
                                }
                              }} />
                          </td>
                          <td className='sticky-col third-col'>
                            <input type="text" id={`inputDescricao_${item.ordem}`} className='form-control form-control-sm' value={item.descricao ?? ""} 
                              onChange={(e) => { handleChangeItem({ ...item, descricao: e.target.value }) }} 
                              onKeyDown={(e) => {
                                if(e.key === 'ArrowUp'){
                                  e.preventDefault();
                                  document.getElementById(`inputDescricao_${item.ordem-1}`)?.focus();
                                }
                                if(e.key === 'ArrowDown' || e.key === 'Enter'){
                                  e.preventDefault();
                                  document.getElementById(`inputDescricao_${item.ordem+1}`)?.focus();
                                }
                              }} />
                          </td>
                          <td className='sticky-col fourth-col'>
                            <input type="text" id={`inputUnidade_${item.ordem}`}  className='form-control form-control-sm' value={item.unidade ?? ""} 
                              onChange={(e) => { handleChangeItem({ ...item, unidade: e.target.value }) }} 
                              onKeyDown={(e) => {
                                if(e.key === 'ArrowUp'){
                                  e.preventDefault();
                                  document.getElementById(`inputUnidade_${item.ordem-1}`)?.focus();
                                }
                                if(e.key === 'ArrowDown' || e.key === 'Enter'){
                                  e.preventDefault();
                                  document.getElementById(`inputUnidade_${item.ordem+1}`)?.focus();
                                }
                              }} />
                          </td>
                          <td className='sticky-col fifth-col'>
                            <input type="number" id={`inputQuantidade_${item.ordem}`} className='form-control form-control-sm' value={item.quantidade ?? ""} 
                              onChange={(e) => {
                                handleChangeValorTotal(e.target.value, index);
                                handleChangeItem({ ...item, quantidade: e.target.value });
                              }} 
                              onWheel={(e) => e.target.blur()}
                              onKeyDown={(e) => {
                                if(e.key === 'e' || e.key === 'E' || e.key === '-'){
                                  e.preventDefault();
                                }
                                if(e.key === 'ArrowUp'){
                                  e.preventDefault();
                                  document.getElementById(`inputQuantidade_${item.ordem-1}`)?.focus();
                                }
                                if(e.key === 'ArrowDown' || e.key === 'Enter'){
                                  e.preventDefault();
                                  document.getElementById(`inputQuantidade_${item.ordem+1}`)?.focus();
                                }
                              }}/>
                          </td>
                          {
                            menorValor != null && menorValor.length > 0 ?
                              <>
                                <td>
                                  {Utilidades.getValorFormatado(menorValor[index])}
                                </td>
                                <td>
                                  {Utilidades.getValorFormatado(Number.parseFloat((menorValor[index] ? menorValor[index] : 0)*item.quantidade).toFixed(2))}
                                </td>
                              </>
                              : <></>
                          }
                          {
                            media != null && media.length > 0 ?
                              <>
                                <td>
                                  {Utilidades.getValorFormatado(media[index])}
                                </td>
                                <td>
                                  {Utilidades.getValorFormatado(Number.parseFloat((media[index] ? media[index] : 0)*item.quantidade).toFixed(2))}
                                </td>
                              </>
                              : <></>
                          }
                          {
                            mediana != null && mediana.length > 0 ?
                              <>
                                <td>
                                  {Utilidades.getValorFormatado(mediana[index])}
                                </td> 
                                <td>
                                  {Utilidades.getValorFormatado(Number.parseFloat((mediana[index] ? mediana[index] : 0)*item.quantidade).toFixed(2))}
                                </td> 
                              </>
                              : <></>
                          }
                          {/* {
                            mediaPonderada != null && mediaPonderada.length > 0 ?
                              <td>
                                {mediaPonderada[index]}
                              </td> : <></>
                          } */}
                          {
                            mediaSaneada != null && mediaSaneada.length > 0 ?
                              <>
                                <td>
                                  {Utilidades.getValorFormatado(mediaSaneada[index])}
                                </td> 
                                <td>
                                  {Utilidades.getValorFormatado(Number.parseFloat((mediaSaneada[index] ? mediaSaneada[index] : 0)*item.quantidade).toFixed(2))}
                                </td> 
                              </>
                              : <></>
                          }
                          {
                            exibindoCoeficientes ?
                            <>
                              <td>{isNaN(coefDesvioPadrao[index]) ? "" : coefDesvioPadrao[index]?.toString().replace(".", ",")}</td>
                              <td>{isNaN(coefVariacaoSaneada[index]) ? "" : coefVariacaoSaneada[index] + " %"}</td>
                            </>
                            : <></>
                          }
                          {
                            empresas && empresas.map((empresa) => (
                              <React.Fragment key={empresa.ordem}>
                                <td>
                                  <input type="number" id={`inputValorUnitario_${item.ordem}_${empresa.ordem}`}  className='form-control form-control-sm' value={empresa.valorUnitario[index] ?? ""}
                                    onChange={(e) => {
                                      let valoresUni = empresa.valorUnitario;
                                      let valoresTotais = empresa.valorTotal;
                                      valoresUni[index] = e.target.value;
                                      let qtd = item.quantidade ?? 0;
                                      valoresTotais[index] = Number.parseFloat(e.target.value * qtd).toFixed(2);
                                      handleChangeEmpresa({ ...empresa, valorUnitario: valoresUni, valorTotal: valoresTotais });
                                    }} 
                                    onWheel={(e) => e.target.blur()}
                                    onKeyDown={(e) => {
                                      if(e.key === 'e' || e.key === 'E' || e.key === '-'){
                                        e.preventDefault();
                                      }
                                      if(e.key === 'ArrowUp'){
                                        e.preventDefault();
                                        document.getElementById(`inputValorUnitario_${item.ordem-1}_${empresa.ordem}`)?.focus();
                                      }
                                      if(e.key === 'ArrowDown' || e.key === 'Enter'){
                                        e.preventDefault();
                                        document.getElementById(`inputValorUnitario_${item.ordem+1}_${empresa.ordem}`)?.focus();
                                      }
                                    }}
                                  onFocus={(e) => {
                                    if(empresa.ordem === 1 && document.getElementById('tableContainer').clientWidth >= 1130) {
                                      e.target.scrollIntoView({block: 'nearest', inline: 'center'});
                                    }
                                    // console.log('focus e.target', e.target);
                                  }}
                                  />
                                </td>
                                <td>
                                  {/* <input type="text" className='form-control form-control-sm' value={empresa.valorTotal[index] ?? ""}
                                    onChange={(e) => {
                                      let valoresTotais = empresa.valorTotal;
                                      valoresTotais[index] = e.target.value;
                                      handleChangeEmpresa({ ...empresa, valorTotal: valoresTotais });
                                    }} /> */}
                                    {Utilidades.getValorFormatado(empresa.valorTotal[index])}
                                </td>
                              </React.Fragment>
                            ))
                          }
                        </tr>
                      ))
                    }
                    {
                      resultados != null && resultados instanceof Array && resultados.length > 0 ?
                      <>
                        <tr>
                          <td className='text-center sticky-col first-col'>-</td>
                          <td className='text-center sticky-col second-col'>-</td>
                          <td className='text-bold sticky-col total-col' colSpan={3}>Total</td>
                          {/* <td></td> */}
                          {/* <td className='text-center'>{itens != null && itens?.length > 0 ? itens.reduce((n, {quantidade}) => n + quantidade, 0) : ""}</td> */}
                          {
                            menorValor != null && menorValor.length > 0 ?
                              <>
                                <td className='text-right text-bold' colSpan={2}>
                                  {Utilidades.getValorFormatado(Number.parseFloat(calcularTotal(menorValor, itens)).toFixed(2))}
                                </td>
                              </>
                              : <></>
                          }
                          {
                            media != null && media.length > 0 ?
                              <>
                                <td className='text-right text-bold' colSpan={2}>
                                  {Utilidades.getValorFormatado(Number.parseFloat(calcularTotal(media, itens)).toFixed(2))}
                                </td>
                              </>
                              : <></>
                          }
                          {
                            mediana != null && mediana.length > 0 ?
                              <>
                                <td className='text-right text-bold' colSpan={2}>
                                  {Utilidades.getValorFormatado(Number.parseFloat(calcularTotal(mediana, itens)).toFixed(2))}

                                </td>
                              </>
                              : <></>
                          }
                          {
                            mediaSaneada != null && mediaSaneada.length > 0 ?
                              <>
                                <td className='text-right text-bold' colSpan={2}>
                                  {Utilidades.getValorFormatado(Number.parseFloat(calcularTotal(mediaSaneada, itens)).toFixed(2))}
                                </td>
                              </>
                              : <></>
                          }
                        </tr>
                      </>
                      :
                      <></>
                    }
                  </tbody>
                </table>
              </div>
              <div className='mx-2 d-flex flex-row justify-content-between hide-print'>
                <div className=''>
                  <Tippy content='Alt + q'>
                    <button type='button' id="btnAdicionarItem" className='btn btn-sm btn-info mx-2 mb-2' onClick={() => adicionarItem()}>Novo Item</button>
                  </Tippy>
                  <Tippy content='Alt + w'>
                    <button type='button' id="btnAdicionarEmpresa" className='btn btn-sm btn-warning mx-2 mb-2' onClick={() => adicionarEmpresa()}>Nova Empresa</button>
                  </Tippy>
                  <button type='button' className='btn btn-sm btn-success mx-2 mb-2' onClick={() => calcular()}>Calcular</button>
                  <button type='button' className='btn btn-sm btn-secondary mx-2 mb-2' onClick={() => exibirCoeficientes()}>Coeficientes</button>
                  {/* <button type='button' className='btn btn-sm btn-outline-secondary mx-2' onClick={() => getDados()}>Log Dados</button> */}
                  <button type='button' className='btn btn-sm btn-danger mx-2 mb-2' onClick={() => limparCalculos()}>Apagar (Cálculos)</button>
                  {/* <button type='button' className='btn btn-sm btn-outline-secondary mx-2' onClick={() => limparTabela()}>Limpar (Tabela)</button> */}
                  <button type='button' className='btn btn-sm btn-info mx-2 mb-2' data-toggle="modal" data-target="#modalObservacoes">Observações</button>
                </div>
                {/* <div className=''> */}
                  {/* <button type='button' className='btn btn-sm btn-info mx-2 mb-2' 
                  onClick={() => {
                    let data = new Date();
                    let w = Date.now() - (new Date().getTimezoneOffset() * 60*1000);
                    let ww = new Date(Date.now() - (new Date().getTimezoneOffset() * 60*1000)).toJSON();
                    
                    console.log('data', data)
                    console.log('ww', ww)
                  }}>data</button> */}
                {/* </div> */}
                <div className=''>
                  {
                    salvando ?
                    <button type="button" className="btn btn-sm btn-primary mx-2 mb-2" disabled>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;Salvando...
                    </button>
                    :
                    <Tippy content='Ctrl + s'>
                      <button id="btnSalvarPesquisa" type='button' className='btn btn-sm btn-primary mx-2 mb-2' onClick={() => submitForm()} disabled={salvando}>Salvar</button>
                    </Tippy>

                  }
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
