class Alerts {

    /**
     * @param {String} msg
     * @param {String} elementId The element that will be replaced by the alert.
     * @param {Function} fun 
     */
    erro = (msg, elementId, fun) => {
      let element = document.getElementById(elementId);
      element.innerHTML = '';
    
      let wrapper = document.createElement('div');
      wrapper.innerHTML = '<div class="alert alert-danger alert-dismissible py-1" role="alert">'
                            + msg
                            + '<button type="button" class="close py-0" data-dismiss="alert" aria-label="Close"><span aria-hidden="true" style="color:white;">&times;</span></button>'
                            + '</div>'
    
      element.append(wrapper);

      wrapper.querySelector('#alert-close-button')?.addEventListener('click', function (e) {
        if(fun != null && typeof fun === "function"){
          fun();
        }
      });
      
      // window.$('.alert').alert()
    }

    /**
     * @param {String} msg
     * @param {String} elementId The element that will be replaced by the alert.
     * @param {Function} fun 
     */
    warning = (msg, elementId, fun) => {
      let element = document.getElementById(elementId);
      element.innerHTML = '';

    
      let wrapper = document.createElement('div');
      wrapper.innerHTML = '<div class="alert alert-warning alert-dismissible py-1" role="alert">'
                            + msg
                            + '<button type="button" id="alert-close-button" class="close py-0" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
                            + '</div>';
      
      element.append(wrapper);

      wrapper.querySelector('#alert-close-button')?.addEventListener('click', function (e) {
        if(fun != null && typeof fun === "function"){
          fun();
        }
      });
      
      // window.$('.alert').alert()
    }

    /**
     * @param {String} msg
     * @param {String} elementId The element that will be replaced by the alert.
     * @param {Function} fun 
     */
    sucesso = (msg, elementId, fun) => {
      let element = document.getElementById(elementId);
      element.innerHTML = '';
    
      let wrapper = document.createElement('div');
      wrapper.innerHTML = '<div class="alert alert-success alert-dismissible py-1" role="alert">'
                            + msg
                            + '<button type="button" class="close py-0" data-dismiss="alert" aria-label="Close"><span aria-hidden="true" style="color:white;">&times;</span></button>'
                            + '</div>'
    
      element.append(wrapper);

      wrapper.querySelector('#alert-close-button')?.addEventListener('click', function (e) {
        if(fun != null && typeof fun === "function"){
          fun();
        }
      });

      // window.$('.alert').alert()
    }

    /**
     * @param {String} msg
     * @param {String} elementId The element that will be replaced by the alert.
     * @param {Function} fun 
     */
    info = (msg, elementId, fun) => {
      let element = document.getElementById(elementId);
      element.innerHTML = '';
    
      let wrapper = document.createElement('div');
      wrapper.innerHTML = '<div class="alert alert-info alert-dismissible py-1" role="alert">'
                            + msg
                            + '<button type="button" class="close py-0" data-dismiss="alert" aria-label="Close"><span aria-hidden="true" style="color:white;">&times;</span></button>'
                            + '</div>'
    
      element.append(wrapper);

      wrapper.querySelector('#alert-close-button')?.addEventListener('click', function (e) {
        if(fun != null && typeof fun === "function"){
          fun();
        }
      });

      // window.$('.alert').alert()
    }

}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new Alerts();