import Requisicao from "./Requisicao";

class EnumUtils {

    /**
     * Recebe o valor do enum e retorna o nome da mesma.
     * Este método supõe que cada elemento da lista contenha os atributos:
     *      value   - indicando o valor do enum no backend
     *      nome    - indicando o nome do enum no backend
     */
    getNomeEnum(value, lista) {
        if(lista != null && lista instanceof Array && lista.length > 0){
            for (let i = 0; i < lista.length; i++) {
                if(lista[i].value === value){
                    if(lista[i].nome != null){
                        return lista[i].nome;
                    }
                }
            }
        }
        return value;
    }

    getNomeLeiLicitacao(value) {
        return new Promise((resolve, reject) => {
            Requisicao.getLeiLicitacao(value)
                .then(data => {
                    resolve(data.nome ?? value);
                })
                .catch(erro => {
                    reject(erro);
                })
        })
    }

    getNomeModalidadeLicitacao(value) {
        return new Promise((resolve, reject) => {
            Requisicao.getModalidadeLicitacao(value)
                .then(data => {
                    resolve(data.nome ?? value);
                })
                .catch(erro => {
                    reject(erro);
                })
        })
    }

    getNomeTipoAnalise(value) {
        return new Promise((resolve, reject) => {
            Requisicao.getTipoAnalise(value)
                .then(data => {
                    resolve(data.nome ?? value);
                })
                .catch(erro => {
                    reject(erro);
                })
        })
    }

    getNomeOrgao(value) {
        return new Promise((resolve, reject) => {
            Requisicao.getOrgao(value)
                .then(data => {
                    resolve(data.nome ?? value);
                })
                .catch(erro => {
                    reject(erro);
                })
        })
    }

    getNomeFilaAnalise(value) {
        return new Promise((resolve, reject) => {
            Requisicao.getFilaAnalise(value)
                .then(data => {
                    resolve(data.nome ?? value);
                })
                .catch(erro => {
                    reject(erro);
                })
        })
    }

    getNomeStatusAnalise(value) {
        return new Promise((resolve, reject) => {
            Requisicao.getStatusAnalise(value)
                .then(data => {
                    resolve(data.nome ?? value);
                })
                .catch(erro => {
                    reject(erro);
                })
        })
    }

    getNomeTipoUsuario(value) {
        return new Promise((resolve, reject) => {
            Requisicao.getTipoUsuario(value)
                .then(data => {
                    resolve(data.nome ?? value);
                })
                .catch(erro => {
                    reject(erro);
                })
        })
    }

    exists(arr, value) {
        let exists = false;
        if(arr != null && arr.length > 0){
            arr.forEach(element => {
                if(element.value === value){
                    exists = true;
                }
            });
        }

        return exists;
    }

}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new EnumUtils();