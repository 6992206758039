import { Route, Routes} from 'react-router-dom';
import Home from '../../pages/Home';
import CadastroUnidade from '../../pages/unidade/CadastroUnidade';
import EditarUnidade from '../../pages/unidade/EditarUnidade';
import PesquisaUnidade from '../../pages/unidade/PesquisaUnidade';
import CadastroUsuario from '../../pages/usuario/CadastroUsuario';
import EditarUsuario from '../../pages/usuario/EditarUsuario';
import PesquisaUsuario from '../../pages/usuario/PesquisaUsuario';
import AlterarSenha from '../../pages/usuario/AlterarSenha';
import NotFound from '../../pages/NotFound';
import AcessoNegado from '../../pages/AcessoNegado';
import Contatos from '../../pages/ajuda/Contatos';
import EditarAviso from '../../pages/avisoslicitacao/EditarAviso';
import PesquisaAviso from '../../pages/avisoslicitacao/PesquisaAviso';
import NavbarSubgeral from './NavbarSubgeral';
import SidebarSubgeral from './SidebarSubgeral';
import ControlBarSubgeral from './ControlBarSubgeral';
import FooterSubgeral from './FooterSubgeral';
import PesquisaProcesso from '../../pages/processo/PesquisaProcesso';
import ExibirAviso from '../../pages/avisoslicitacao/ExibirAviso';
import ExibirProcesso from '../../pages/processo/ExibirProcesso';
import EditarContrato from '../../pages/contratos/EditarContrato';
import ExibirContrato from '../../pages/contratos/ExibirContrato';
import PesquisaContrato from '../../pages/contratos/PesquisaContrato';


export default function RoutesSubgeral(props) {
  const autenticado = props.autenticado;
  const usuario = props.usuario;
  const version = props.version;

  if(autenticado && usuario?.setor === "SUBCONTROLADORIA_GERAL"){
    return (
        <>
          <NavbarSubgeral logout={props.logout} usuario={props.usuario}/>
          <SidebarSubgeral logout={props.logout} usuario={props.usuario}/>
  
          <Routes>
            <Route path="/" element={<Home usuario={props.usuario} autenticado={props.autenticado}/>} />
            <Route path="/unidade">
              <Route path="cadastro" element={<CadastroUnidade usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path="editar/:unidadeId" element={<EditarUnidade usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path="pesquisar" element={<PesquisaUnidade usuario={props.usuario} autenticado={props.autenticado}/>} />
            </Route>
            <Route path="/usuario">
              <Route path="cadastro" element={<CadastroUsuario usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path="editar/:usuarioId" element={<EditarUsuario usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path="pesquisar" element={<PesquisaUsuario usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path="alterarsenha" element={<AlterarSenha usuario={props.usuario} autenticado={props.autenticado}/>} />
            </Route>
            <Route path='/avisolicitacao'>
              <Route path='novo' element={<EditarAviso usuario={props.usuario} autenticado={props.autenticado} />} />
              <Route path='editar/:avisoId' element={<EditarAviso usuario={props.usuario} autenticado={props.autenticado} />} />
              <Route path='exibir/:avisoId' element={<ExibirAviso usuario={props.usuario} autenticado={props.autenticado} />} />
              <Route path='pesquisar' element={<PesquisaAviso usuario={props.usuario} autenticado={props.autenticado} />} />
            </Route>
            <Route path="/processo">
              <Route path='pesquisar' element={<PesquisaProcesso usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path="exibir/:processoId" element={<ExibirProcesso usuario={props.usuario} autenticado={props.autenticado}/>} />
            </Route>
            <Route path='/contrato'>
              <Route path='novo' element={<EditarContrato usuario={props.usuario} autenticado={props.autenticado} />} />
              <Route path='editar/:contratoId' element={<EditarContrato usuario={props.usuario} autenticado={props.autenticado} />} />
              <Route path='exibir/:contratoId' element={<ExibirContrato usuario={props.usuario} autenticado={props.autenticado} />} />
              <Route path='pesquisar' element={<PesquisaContrato usuario={props.usuario} autenticado={props.autenticado} />} />
            </Route>


            <Route path="/acessonegado" element={<AcessoNegado />}></Route>
            <Route path="/contatos" element={<Contatos logout={props.logout}/>}></Route>
            <Route path="*" element={<NotFound logout={props.logout} />}></Route>
          </Routes>
  
          <ControlBarSubgeral usuario={props.usuario} autenticado={props.autenticado}/>
          <FooterSubgeral version={version} />
        </>
    );
  } else {
    return (
      <>
        <Routes>
          <Route path="*" element={<NotFound logout={props.logout} />}></Route>
        </Routes>
      </>
    )
  }

}