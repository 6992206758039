import React, { useEffect } from 'react'

export default function AcessoNegado() {
  useEffect(()=>{
    document.title = 'Acesso Negado - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
  }, [])
  
  return (
    <div className="content-wrapper">
      <div className="m-2 p-2">
        <h1>
            Acesso Negado.
        </h1>
      </div>
    </div>
  )
}
