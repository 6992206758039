import { Route, Routes} from 'react-router-dom';
import NavbarConsulta from './NavbarConsulta';
import SidebarConsulta from './SidebarConsulta';
import ControlBarConsulta from './ControlBarConsulta';
import FooterConsulta from './FooterConsulta';
import Home from '../../pages/Home';
import NotFound from '../../pages/NotFound';
import AcessoNegado from '../../pages/AcessoNegado';
import Contatos from '../../pages/ajuda/Contatos';
import ExibirProcesso from '../../pages/processo/ExibirProcesso';
import PesquisaProcesso from '../../pages/processo/PesquisaProcesso';
import VisualizarAnalise from '../../pages/analise/VisualizarAnalise';
import ExibirAviso from '../../pages/avisoslicitacao/ExibirAviso';
import PesquisaAviso from '../../pages/avisoslicitacao/PesquisaAviso';
import ExibirContrato from '../../pages/contratos/ExibirContrato';
import PesquisaContrato from '../../pages/contratos/PesquisaContrato';


export default function RoutesConsulta(props) {
  const autenticado = props.autenticado;
  const usuario = props.usuario;
  const version = props.version;

  if(autenticado && usuario?.setor === "CONSULTA"){
    return (
        <>
          <NavbarConsulta logout={props.logout} usuario={props.usuario}/>
          <SidebarConsulta logout={props.logout} usuario={props.usuario}/>
  
          <Routes>
            <Route path="/" element={<Home usuario={props.usuario} autenticado={props.autenticado}/>} />

            <Route path="/processo">
              <Route path="exibir/:processoId" element={<ExibirProcesso usuario={props.usuario} autenticado={props.autenticado}/>} />
              <Route path='pesquisar' element={<PesquisaProcesso usuario={props.usuario} autenticado={props.autenticado}/>} />
            </Route>
            <Route path='/analise'>
              <Route path='visualizar/:analiseId' element={<VisualizarAnalise usuario={props.usuario} autenticado={props.autenticado}/>} />
            </Route>
            <Route path='/avisolicitacao'>
              <Route path='exibir/:avisoId' element={<ExibirAviso usuario={props.usuario} autenticado={props.autenticado} />} />
              <Route path='pesquisar' element={<PesquisaAviso usuario={props.usuario} autenticado={props.autenticado} />} />
            </Route>
            <Route path='/contrato'>
              <Route path='exibir/:contratoId' element={<ExibirContrato usuario={props.usuario} autenticado={props.autenticado} />} />
              <Route path='pesquisar' element={<PesquisaContrato usuario={props.usuario} autenticado={props.autenticado} />} />
            </Route>

            <Route path="/acessonegado" element={<AcessoNegado />}></Route>
            <Route path="/contatos" element={<Contatos logout={props.logout}/>}></Route>
            <Route path="*" element={<NotFound logout={props.logout} />}></Route>
          </Routes>
  
          <ControlBarConsulta usuario={props.usuario} autenticado={props.autenticado}/>
          <FooterConsulta version={version} />
        </>
    );
  } else {
    return (
      <>
        <Routes>
          <Route path="*" element={<NotFound logout={props.logout} />}></Route>
        </Routes>
      </>
    )
  }

}