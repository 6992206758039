import React, { useEffect, useState } from 'react'
import Tippy from '@tippyjs/react';
import Utilidades from '../../../helpers/Utilidades';
import AditivoElement from './AditivoElement';
import CampoConformidadeElement from './CampoConformidadeElement';
// import { parseISO } from 'date-fns';

let ordem = 1;

export default function CampoComplementar(props) {

  const emRevisao = props.emRevisao ?? false;
  const exibindo = props.exibindo ?? false;
  const campo = props.campo;
  const onChangeCampo = props.onChangeCampo;
  const onDeleteCampo = props.onDeleteCampo;
  const aditivos = campo?.campoCompAditivo ?? [];
  const conjuntosNomeDocTexto = campo?.campoCompConjuntoNomeDocumentoTextos ?? [];
  const camposComplementaresConformidade = campo?.campoCompConformidade ?? [];

  // const dataInicial = (typeof aditivo?.dataInicial === 'string' || aditivo?.dataInicial instanceof String) ? parseISO(aditivo?.dataInicial) : aditivo?.dataInicial;
  // const dataFinal = (typeof aditivo?.dataFinal === 'string' || aditivo?.dataFinal instanceof String) ? parseISO(aditivo?.dataFinal) : aditivo?.dataFinal;

  const textoOUTROS = campo?.campoCompOutros?.texto ?? "";
  const textoOBJETO = campo?.campoCompObjeto?.objeto ?? "";
  const textoVALOR = campo?.campoCompValor?.valor ?? "";
  const textoBOOLEAN = (campo?.campoCompBoolean?.valor === true) ? "SIM" : "NÃO";
  const checkedBOOLEAN = campo?.campoCompBoolean?.valor;

  const nomeCampo = campo?.nome ?? "";

  const [exibirTextoVALOR, setExibirTextoVALOR] = useState("");

  const observacao = campo?.observacao ?? "";

  const [comentario, setComentario] = useState(observacao);

  const [visible, setVisible] = useState(false);

  const [number, setNumber] = useState("");
  const [tipoAditivo, setTipoAditivo] = useState("-1");
  const [tipoObjetoComplementar, setTipoObjetoComplementar] = useState(
                                                                ["Objeto do Contrato",
                                                                "Objeto do Termo Aditivo",
                                                                "Objeto do Termo de Apostilamento",
                                                                "Objeto da Ata de Registro de Preços",
                                                                "Objeto do Convênio/Acordo de Cooperação Técnica"].includes(campo?.nome) ? campo?.nome : "-1");

  const [tipoCampoComplementarConformidade, setTipoCampoComplementarConformidade] = useState("-1");



  // const [nomeCampoLivre, setNomeCampoLivre] = useState("");

  const show = () => {
    setComentario(observacao);
    setVisible(true);
  }

  const hide = () => {
    handleObservacaoChange(comentario);
    setVisible(false);
  }

  useEffect(() => {
    let valor = textoVALOR;
    valor = valor.replaceAll(".", ",");
    if (!valor.includes(",")) {
      valor = valor + ',00'
    }
    setExibirTextoVALOR(valor);
  }, [textoVALOR])


  useEffect(() => {
    window.$(function () {
      window.$('[data-toggle="popover"]').popover()
    });
  })

  const adicionarCampoComplementarConformidade = () => {
    if(["CONTRATO", "ARP", "NOTA_DE_EMPENHO"].includes(tipoCampoComplementarConformidade)){
      document.getElementById(`comboboxCampoComplementarConformidade-${campo.ordem}`)?.classList.remove("is-invalid");
      if(campo.campoCompConformidade != null && campo.campoCompConformidade.length > 0) {
        campo.campoCompConformidade.forEach((c) => {
          if (c.ordem >= ordem) {
            ordem = c.ordem;
            ordem++;
          }
        })
        onChangeCampo({
          ...campo,
          campoCompConformidade: [
            ...campo.campoCompConformidade,
            {
              id: null,
              ordem: ordem++,
              tipoComplementarConformidade: tipoCampoComplementarConformidade,
              objeto: '',
              numeroContrato: '',
              numeroAta: '',
              numeroNotaEmpenho: '',
              valor: '',
              razaoSocial: '',
              cnpj: '',
              dataInicial: null,
              dataFinal: null,
            }
          ]
        })
      } else {
        onChangeCampo({
          ...campo,
          campoCompConformidade: [
            {
              id: null,
              ordem: ordem++,
              tipoComplementarConformidade: tipoCampoComplementarConformidade,
              objeto: '',
              numeroContrato: '',
              numeroAta: '',
              numeroNotaEmpenho: '',
              valor: '',
              razaoSocial: '',
              cnpj: '',
              dataInicial: null,
              dataFinal: null,
            }
          ]
        })
      }
    } else {
      document.getElementById(`comboboxCampoComplementarConformidade-${campo.ordem}`)?.classList.add("is-invalid");
    }
  }


  const adicionarAditivo = () => {
    if (tipoAditivo !== "-1" && (number !== "" || tipoAditivo === "CONTRATO_ORIGINAL" || tipoAditivo === "ATA")) {
      document.getElementById(`inputAditivoNumero-${campo.ordem}`)?.classList.remove("is-invalid");
      document.getElementById(`comboboxAditivo-${campo.ordem}`)?.classList.remove("is-invalid");
      if (campo.campoCompAditivo != null && campo.campoCompAditivo.length > 0) {
        campo.campoCompAditivo.forEach((ca) => {
          if (ca.ordem >= ordem) {
            ordem = ca.ordem;
            ordem++;
          }
        })
        onChangeCampo({
          ...campo,
          campoCompAditivo: [
            ...campo.campoCompAditivo,
            {
              id: null,
              ordem: ordem++,
              tipoAditivo: tipoAditivo,
              numAditivo: (tipoAditivo === "CONTRATO_ORIGINAL" || tipoAditivo === "ATA") ? "" : number,
              valor: '',
              valorMensal: '',
              valorAcrescimo: '',
              valorSupressao: '',
              percentualAcrescimo: '',
              percentualSupressao: '',
              percentualReflexoFinanceiro: '',
              periodicidadeInicioMes: '',
              periodicidadeInicioAno: '',
              periodicidadeFimMes: '',
              periodicidadeFimAno: '',
              percentualApostilamento: '',
              valorAtualizadoContrato: '',
              numeroContrato: '',
              numeroAta: '',

            }
          ]
        })
        setNumber("");
      } else {
        onChangeCampo({
          ...campo,
          campoCompAditivo: [
            {
              id: null,
              ordem: ordem++,
              tipoAditivo: tipoAditivo,
              numAditivo: (tipoAditivo === "CONTRATO_ORIGINAL" || tipoAditivo === "ATA") ? "" : number,
              valor: '',
              valorMensal: '',
              valorAcrescimo: '',
              valorSupressao: '',
              percentualAcrescimo: '',
              percentualSupressao: '',
              percentualReflexoFinanceiro: '',
              periodicidadeInicioMes: '',
              periodicidadeInicioAno: '',
              periodicidadeFimMes: '',
              periodicidadeFimAno: '',
              percentualApostilamento: '',
              valorAtualizadoContrato: '',
              numeroContrato: '',
              numeroAta: '',
            }
          ]
        })
        setNumber("");
      }
    } else {
      if(((tipoAditivo === "TERMO_ADITIVO" || tipoAditivo === "TERMO_APOSTILAMENTO") && number === "")){
        // let elmt = document.getElementById(`inputAditivoNumero-${campo.ordem}`);
        // elmt.classList.remove('is-valid');
        // elmt.classList.add('is-invalid');
        
        document.getElementById(`inputAditivoNumero-${campo.ordem}`)?.classList.add('is-invalid');
      }
      if(tipoAditivo === "-1"){
        document.getElementById(`comboboxAditivo-${campo.ordem}`)?.classList.add("is-invalid");
      } else {
        document.getElementById(`comboboxAditivo-${campo.ordem}`)?.classList.remove("is-invalid");
      }
    }

  }

  const moverCampoConformidadeParaCima = (c) => {
    if(camposComplementaresConformidade != null && camposComplementaresConformidade?.length > 0){
      let arr = camposComplementaresConformidade;
      if(arr[0]?.ordem !== c?.ordem){
        let index = null;
        for(let i = 0; i < arr.length; i++){
          if(arr[i]?.ordem === c?.ordem){
            index = i;
            break;
          }
        }
        if(index != null && index !== 0){
          let temp = arr[index]?.ordem;
          arr[index].ordem = arr[index - 1]?.ordem;
          arr[index - 1].ordem = temp;
          arr = Utilidades.swapArrayElements(arr, index, index - 1);
          onChangeCampo({
            ...campo,
            campoCompConformidade: arr
          })
        }
      }
    }
  }

  const moverAditivoParaCima = (a) => {
    if(aditivos != null && aditivos?.length > 0){
      let arr = aditivos;
      if(arr[0]?.ordem !== a?.ordem){
        let index = null;
        for(let i = 0; i < arr.length; i++){
          if(arr[i]?.ordem === a?.ordem){
            index = i;
            break;
          }
        }
        if(index != null && index !== 0){
          let temp = arr[index]?.ordem;
          arr[index].ordem = arr[index - 1]?.ordem;
          arr[index - 1].ordem = temp;
          arr = Utilidades.swapArrayElements(arr, index, index - 1);
          onChangeCampo({
            ...campo,
            campoCompAditivo: arr
          })
        }
      }
    }
  }

  const moverCampoConformidadeParaBaixo = (c) => {
    if(camposComplementaresConformidade != null && camposComplementaresConformidade?.length > 0){
      let arr = camposComplementaresConformidade;
      if(arr[arr.length - 1]?.ordem !== c?.ordem){
        let index = null;
        for(let i = 0; i < arr.length; i++){
          if(arr[i]?.ordem === c?.ordem){
            index = i;
            break;
          }
        }
        if(index != null && index !== (arr.length - 1)){
          let temp = arr[index]?.ordem;
          arr[index].ordem = arr[index + 1]?.ordem;
          arr[index + 1].ordem = temp;
          arr = Utilidades.swapArrayElements(arr, index, index + 1);
          onChangeCampo({
            ...campo,
            campoCompConformidade: arr
          })
        }
      }
    }
  }

  const moverAditivoParaBaixo = (a) => {
    if(aditivos != null && aditivos?.length > 0){
      let arr = aditivos;
      if(arr[arr.length - 1]?.ordem !== a?.ordem){
        let index = null;
        for(let i = 0; i < arr.length; i++){
          if(arr[i]?.ordem === a?.ordem){
            index = i;
            break;
          }
        }
        if(index != null && index !== (arr.length - 1)){
          let temp = arr[index]?.ordem;
          arr[index].ordem = arr[index + 1]?.ordem;
          arr[index + 1].ordem = temp;
          arr = Utilidades.swapArrayElements(arr, index, index + 1);
          onChangeCampo({
            ...campo,
            campoCompAditivo: arr
          })
        }
      }
    }
  }

  const adicionarConjuntoNomeDocTexto = () => {
    if(campo.campoCompConjuntoNomeDocumentoTextos != null && 
        campo.campoCompConjuntoNomeDocumentoTextos.length > 0){
          campo.campoCompConjuntoNomeDocumentoTextos.forEach((c) => {
            if (c.ordem >= ordem) {
              ordem = c.ordem;
              ordem++;
            }
          })
          onChangeCampo({
            ...campo,
            campoCompConjuntoNomeDocumentoTextos: [
              ...campo.campoCompConjuntoNomeDocumentoTextos,
              {
                id: null,
                ordem: ordem++,
                nome: "",
                documento: "",
                texto: "",
                infoAdicional: "",
                descricao: ""
              }
            ]
          })
        } else {
          ordem = 1;
          onChangeCampo({
            ...campo,
            campoCompConjuntoNomeDocumentoTextos: [
              {
                id: null,
                ordem: ordem++,
                nome: "",
                documento: "",
                texto: "",
                infoAdicional: "",
                descricao: ""
              },
            ]
          })
        }
  }

  const changeCampoConformidade = (campoConformidade) => {
    onChangeCampo({
      ...campo,
      campoCompConformidade: campo.campoCompConformidade.map((c) => {
        if (c.ordem === campoConformidade.ordem) {
          return campoConformidade;
        } else {
          return c;
        }
      })
    })
  }

  const changeAditivo = (aditivo) => {
    onChangeCampo({
      ...campo,
      campoCompAditivo: campo.campoCompAditivo.map((a) => {
        if (a.ordem === aditivo.ordem) {
          return aditivo;
        } else {
          return a;
        }
      })
    })
  }

  const changeConjuntoNomeDocTexto = (conjunto) => {
    onChangeCampo({
      ...campo,
      campoCompConjuntoNomeDocumentoTextos: campo.campoCompConjuntoNomeDocumentoTextos.map((c) => {
        if (c.ordem === conjunto.ordem) {
          return conjunto;
        } else {
          return c;
        }
      })
    })
  }

  const deletarCampoConformidade = (campoConformidade) => {
    let camposFiltrados = campo.campoCompConformidade.filter((c) => parseInt(c.ordem) !== parseInt(campoConformidade.ordem))
    ordem = 1;
    camposFiltrados && camposFiltrados.forEach((c) => (c.ordem = ordem++))

    onChangeCampo({
      ...campo,
      campoCompConformidade: camposFiltrados
    })
  }

  const deletarAditivo = (aditivo) => {

    let camposFiltrados = campo.campoCompAditivo.filter((a) => parseInt(a.ordem) !== parseInt(aditivo.ordem))
    ordem = 1;
    camposFiltrados && camposFiltrados.forEach((a) => (a.ordem = ordem++))

    onChangeCampo({
      ...campo,
      campoCompAditivo: camposFiltrados
    })

  }

  const deletarConjuntoNomeDocTexto = (conjunto) => {

    let camposFiltrados = campo.campoCompConjuntoNomeDocumentoTextos.filter((c) => parseInt(c.ordem) !== parseInt(conjunto.ordem));
    ordem = 1;
    camposFiltrados && camposFiltrados.forEach((c) => (c.ordem = ordem++))
    onChangeCampo({
      ...campo,
      campoCompConjuntoNomeDocumentoTextos: camposFiltrados
    })

  }

  const handleChangeOUTROS = (e) => {
    onChangeCampo({
      ...campo,
      campoCompOutros: {
        ...campo?.campoCompOutros,
        texto: e.target.value
      }
    })
  }

  const handleChangeNomeCampo = (value) => {
    onChangeCampo({
      ...campo,
      nome: value
    })
  }

  const handleChangeOBJETO = (e) => {
    onChangeCampo({
      ...campo,
      campoCompObjeto: {
        ...campo?.campoCompObjeto,
        objeto: e.target.value
      }
    })
  }

  const handleChangeVALOR = (e) => {
    let valor = e.target.value.replace(/^0+/, '');
    if(valor.length === 0){
      valor = '0';
    }
    if(valor.charAt(0) === '.' || valor.charAt(0) === ','){
      valor = '0' + valor;
    }
    onChangeCampo({
      ...campo,
      campoCompValor: {
        ...campo?.campoCompValor,
        valor: valor
      }
    })
  }

  const handleBooleanChange = (e) => {
    onChangeCampo({
      ...campo,
      campoCompBoolean: {
        ...campo?.campoCompBoolean,
        valor: e.target.checked,
        tipoCampoCompBoolean: campo?.infoAdicional
      }
    })
  }

  const handleObservacaoChange = (obs) => {
    onChangeCampo({
      ...campo,
      observacao: obs,
    })
  }

  const inputComment = (
    <>
      <label>Adicionar Comentário</label>
      <textarea className="form-control my-2" rows={4} style={{ width: "320px" }} value={comentario} onChange={(e) => setComentario(e.target.value)} />
      <div className="text-center my-2">
        <button type="button" className="btn btn-primary mx-2" onClick={() => { setComentario(""); }}>Limpar</button>
        <button type="button" className="btn btn-primary mx-2" onClick={() => { hide(); }}>Ok</button>
      </div>
    </>
  );

  const buttonAdicionarComentario = (
    emRevisao && !exibindo ?
      <Tippy content={(comentario != null && comentario !== "") ? "Editar Comentário" : "Adicionar Comentário"} disabled={visible}>
        <Tippy interactive={true} maxWidth={350} visible={visible} content={inputComment} appendTo={document.body}>
          <button className={(comentario != null && comentario !== "") ? 'btn text-danger' : 'btn text-warning'} type="button" onClick={visible ? hide : show}>
            <i className="fas fa-comment-alt"></i>
          </button>
        </Tippy>
      </Tippy> :
      <></>
  );

  const buttonExibirComentario = (
    !emRevisao && !exibindo && comentario != null && comentario !== "" ?
      <Tippy interactive={true} maxWidth={350} visible={visible} content={comentario} appendTo={document.body}>
        <button className='btn text-danger' type="button" onClick={visible ? hide : show}>
          <i className="fas fa-comment-alt"></i>
        </button>
      </Tippy> :
      <></>
  );

  const buttonDeletarCampo = ( exibindo ? <></> : 
    // <Tippy content={['Excluir o campo.', <br />, 'Esta ação é irreversível.']}>
    <Tippy content={<>Excluir o campo.<br />Esta ação é irreversível.</>}>
    {/* <Tippy content={'Excluir o campo. Esta ação é irreversível.'}> */}
      <button className='btn btn-sm text-danger mx-2' type="button" 
        onClick={() => {
          if(window.confirm("Deseja excluir o campo '" + campo.nome + "'?\n\nATENÇÃO: Esta ação é irreverssível.\nPara recuperar o campo, será necessário reaplicar o modelo desejado, apagando os dados previamente inseridos na análise.") === true){
            onDeleteCampo(campo);
          }
        }}>
        <i className="fas fa-times"></i>
      </button>
    </Tippy>
  )

  return (

    <div className='mt-2'>
      {
        campo.tipo === "OUTROS" ?
          <>
            {
              exibindo ?
                <>
                  <label htmlFor={`inputCampoComplementar-${campo.ordem}`}>
                    {campo.nome}
                    {buttonExibirComentario}
                    {buttonAdicionarComentario}
                  </label>
                  <p className='text-justify fix-linebreak'>
                    {textoOUTROS}
                  </p>
                </>
                : 
                <>
                  {
                    (campo?.infoAdicional === "LIVRE") ? 
                    <>
                      <div className='row my-2 mx-1'>
                        <label htmlFor={`inputNomeCampoComplementar-${campo.ordem}`}>Campo livre</label>
                        {buttonExibirComentario}
                        {buttonAdicionarComentario}
                        {buttonDeletarCampo}
                        <input id={`inputNomeCampoComplementar-${campo.ordem}`} type="text" className='form-control' placeholder={`Insira o nome do campo...`}
                          onChange={(e) => handleChangeNomeCampo(e.target.value)} value={nomeCampo} />
                        
                      </div>
                      <div className='row my-2 mx-1'>
                        <textarea id={`inputCampoComplementar-${campo.ordem}`} rows={2} className='form-control' placeholder={`Digite o(a) ${nomeCampo === "" ? "texto do campo..." : nomeCampo}`}
                          onChange={handleChangeOUTROS} value={textoOUTROS} ></textarea>
                      </div>
                    </> :
                    <>
                      <label htmlFor={`inputCampoComplementar-${campo.ordem}`}>
                        {campo.nome}
                        {buttonExibirComentario}
                        {buttonAdicionarComentario}
                        {buttonDeletarCampo}
                      </label>
                      <input id={`inputCampoComplementar-${campo.ordem}`} type="text" className='form-control' placeholder={`Digite o(a) ${campo.nome}`}
                        onChange={handleChangeOUTROS} value={textoOUTROS} />
                    </>
                  }
                </>
            }
          </> :
          <></>
      }
      {
        campo.tipo === "OBJETO" ?
          <>
            {
              (campo?.infoAdicional === "OBJETO_COMPLEMENTAR") ? 
              <>
                <label htmlFor={`inputCampoComplementar-${campo.ordem}`}>
                  {campo.nome}
                  {buttonExibirComentario}
                  {buttonAdicionarComentario}
                  {buttonDeletarCampo}
                </label>
                {
                  exibindo ?
                    <p className='text-justify fix-linebreak'>
                      {textoOBJETO}
                    </p>
                    : 
                    <>
                      <select id={`comboboxObjetoComplementar-${campo.ordem}`} className='form-control col-6 my-2' value={tipoObjetoComplementar} 
                      onChange={(e) => {
                        setTipoObjetoComplementar(e.target.value);
                        if(e.target.value !== "-1"){
                          handleChangeNomeCampo(e.target.value);
                        } else{
                          handleChangeNomeCampo("Objeto Complementar");
                        }
                        }}>
                        <option value="-1">Selecione o tipo...</option>
                        <option value="Objeto do Contrato">Contrato Original</option>
                        <option value="Objeto do Termo Aditivo">Termo Aditivo</option>
                        <option value="Objeto do Termo de Apostilamento">Termo de Apostilamento</option>
                        <option value="Objeto da Ata de Registro de Preços">Ata de Registro de Preços</option>
                        <option value="Objeto do Convênio/Acordo de Cooperação Técnica">Convênio/Acordo de Cooperação Técnica</option>
                      </select>
                      <textarea id={`inputCampoComplementar-${campo.ordem}`} type="text" className='form-control my-2' placeholder={`Digite o(a) ${campo.nome}`} rows={3}
                        onChange={handleChangeOBJETO} value={textoOBJETO}></textarea>
                    </>
                    
                }
              </> :
              <>
                <label htmlFor={`inputCampoComplementar-${campo.ordem}`}>
                  {campo.nome}
                  {buttonExibirComentario}
                  {buttonAdicionarComentario}
                  {buttonDeletarCampo}
                </label>
                {
                  exibindo ?
                    <p className='text-justify fix-linebreak'>
                      {textoOBJETO}
                    </p>
                    : <textarea id={`inputCampoComplementar-${campo.ordem}`} type="text" className='form-control' placeholder={`Digite o(a) ${campo.nome}`} rows={3}
                      onChange={handleChangeOBJETO} value={textoOBJETO}></textarea>
                }
              </>
            }

            
          </> :
          <></>
      }
      {
        campo.tipo === "VALOR" ?
          <>
            <label htmlFor={`inputCampoComplementar-${campo.ordem}`}>
              {campo.nome}
              {buttonExibirComentario}
              {buttonAdicionarComentario}
              {buttonDeletarCampo}
            </label>
            {
              exibindo ?
                <p>
                  R$ {exibirTextoVALOR}
                </p>
                : <input id={`inputCampoComplementar-${campo.ordem}`} type="number" className='form-control' placeholder={`Digite o(a) ${campo.nome}`}
                  onChange={handleChangeVALOR} value={textoVALOR}
                  onWheel={(e) => e.target.blur()} />
            }
          </> :
          <></>
      }
      {
        campo.tipo === "BOOLEAN" ?
          <>

            {
              exibindo ?
              <>
                <label htmlFor={`inputCampoComplementar-${campo.ordem}`}>
                  {campo.nome}
                  {buttonExibirComentario}
                  {buttonAdicionarComentario}
                  {buttonDeletarCampo}
                </label>
                <p>
                  {textoBOOLEAN}
                </p>
              </> : 
              <> 
                <label htmlFor={`inputCampoComplementar-${campo.ordem}`}>
                  {campo.nome}
                  {buttonExibirComentario}
                  {buttonAdicionarComentario}
                  {buttonDeletarCampo}
                </label>
                <div className='row'>
                  <input type='checkbox' id={`inputCampoComplementar-${campo.ordem}`} className='form-control reduzir-scale col-1' 
                    checked={checkedBOOLEAN ?? false} onChange={handleBooleanChange}></input>
                  <p className='mt-2'>
                    {textoBOOLEAN}
                  </p>
                </div>
              </>
            }
          </> :
          <></>
      }
      {
        campo.tipo === "ADITIVO" ?
          <>
            <label className='mx-1'>Contrato Original, Aditivos, Termos e Ata.</label>
            {buttonExibirComentario}
            {buttonAdicionarComentario}
            {buttonDeletarCampo}
            {
              exibindo ?
                <></> :
                <div>
                  <div className='d-flex flex-column flex-md-row mx-1'>
                    <div className='col-sm-6 col-xl-4 col my-2'>
                      <label>Tipo</label>
                      <select id={`comboboxAditivo-${campo.ordem}`} className='form-control col' value={tipoAditivo} onChange={(e) => setTipoAditivo(e.target.value)}>
                        <option value="-1" disabled>Selecione o tipo...</option>
                        <option value="ATA">Ata de Registro de Preços</option>
                        <option value="CONTRATO_ORIGINAL">Contrato</option>
                        <option value="TERMO_ADITIVO">Termo Aditivo</option>
                        <option value="TERMO_APOSTILAMENTO">Termo de Apostilamento</option>
                        {/* <option value="REAJUSTE">Reajuste</option> */}
                      </select>
                    </div>
                    {
                      (tipoAditivo === "TERMO_ADITIVO" || tipoAditivo === "TERMO_APOSTILAMENTO") ?
                        <div className='col-sm-4 col-xl-2 col my-2'>
                          <label>Número do Termo</label>
                          <input type="number" id={`inputAditivoNumero-${campo.ordem}`} className='form-control' onChange={(e) => { setNumber(e.target.value) }} value={number} placeholder="nº do termo" onWheel={(e) => e.target.blur()}></input>
                        </div> :
                        <></>
                    }
                  </div>
                  <div className='col-4 my-2 mx-1'>
                    {/* <Tippy content="Adicionar Campo"> */}
                      <button className="btn btn-outline-primary" type='button' onClick={adicionarAditivo}>Adicionar Campo</button>
                    {/* </Tippy> */}
                  </div>
                </div>
            }
            <div className='col mx-2'>
              {
                aditivos && aditivos.map((aditivo) => (
                  <React.Fragment key={aditivo.ordem}>
                    <hr />
                    <div className='row align-items-center'>
                      <AditivoElement exibindo={exibindo} aditivo={aditivo} campo={campo} 
                      changeAditivo={changeAditivo} deletarAditivo={deletarAditivo}
                      moveUp={moverAditivoParaCima} moveDown={moverAditivoParaBaixo}/>



                    {/* 
                      <div className='col'>
                        <label>Data de Início</label>
                        {
                          exibindo ?
                            <p>
                              {aditivo?.dataInicial?.toLocaleString('pt-BR').substr(0, 10)}
                            </p> :
                            <DatePicker id={`datepickerDataInicial-${campo.ordem}-${aditivo.ordem}`} className="form-control col"
                              selected={aditivo?.dataInicial}
                              onChange={(date) => {
                                changeAditivo({
                                  ...aditivo,
                                  dataInicial: date
                                })
                              }}
                              strictParsing
                              locale={ptBR} dateFormat="dd/MM/yyyy" required />
                        }
                      </div>
                      <div className='col'>
                        <label>Data de Término</label>
                        {
                          exibindo ?
                            <p>
                              {aditivo?.dataFinal?.toLocaleString('pt-BR').substr(0, 10)}
                            </p> :
                            <DatePicker id={`datepickerDataFinal-${campo.ordem}-${aditivo.ordem}`} className="form-control col"
                              selected={aditivo?.dataFinal}
                              onChange={(date) => {
                                changeAditivo({
                                  ...aditivo,
                                  dataFinal: date
                                })
                              }}
                              strictParsing
                              locale={ptBR} dateFormat="dd/MM/yyyy" required />
                        }
                      </div> */}
                      {/* <div className='col'>
                        <label>Valor</label>
                        {
                          exibindo ?
                            <p>
                              R$&nbsp;{Utilidades.aplicarMascaraValor(aditivo.valor)}
                            </p> :
                            <input type="number" id={`inputValor-${campo.ordem}-${aditivo.ordem}`} className="form-control"
                              onChange={
                                (e) => {
                                  let valor = e.target.value.replace(/^0+/, '');
                                  if(valor.length === 0){
                                    valor = '0';
                                  }
                                  if(valor.charAt(0) === '.' || valor.charAt(0) === ','){
                                    valor = '0' + valor;
                                  }
                                  changeAditivo({
                                    ...aditivo,
                                    valor: valor
                                  })
                                }
                              }
                              value={aditivo.valor}
                              onWheel={(e) => e.target.blur()}
                              placeholder={"Digite o valor"} />
                        }
                      </div> */}



                      {/* {
                        exibindo ?
                          <></> :
                          <div className='col'>
                            <br />
                            <Tippy content="Deletar campo">
                              <button className="btn col-1 text-danger" type='button' onClick={() => deletarAditivo(aditivo)}><span className='fa fa-trash'></span></button>
                            </Tippy>
                          </div>
                      } */}
                    </div>
                  </React.Fragment>
                ))
              }
            </div>
          </> :
          <></>
      }
      {
        campo.tipo === "CAMPO_COMPLEMENTAR_CONFORMIDADE" ?
        <>
            <label className='mx-1'>`{campo.nome != null && campo.nome !== "" ? campo.nome : 'Campo Complementar Conformidade'}</label>
            {buttonExibirComentario}
            {buttonAdicionarComentario}
            {buttonDeletarCampo}
            {
              exibindo ?
                <></> :
                <>
                  <div>
                    <div className='d-flex flex-column flex-md-row mx-1'>
                      <div className='col-sm-6 col-xl-4 col my-2'>
                        <label>Tipo</label>
                        <select id={`comboboxCampoComplementarConformidade-${campo.ordem}`} className='form-control col' value={tipoCampoComplementarConformidade} onChange={(e) => setTipoCampoComplementarConformidade(e.target.value)}>
                          <option value="-1" disabled>Selecione...</option>
                          <option value="CONTRATO">Contrato</option>
                          <option value="ARP">Ata de Registro de Preços</option>
                          <option value="NOTA_DE_EMPENHO">Nota de Empenho</option>
                        </select>
                      </div>
                    </div>
                    <div className='col-4 my-2 mx-1'>
                        <button className="btn btn-outline-primary" type='button' onClick={adicionarCampoComplementarConformidade}>Adicionar Campo</button>
                    </div>
                  </div>
                
                </>
            }
            <div className='col mx-2'>
              {
                camposComplementaresConformidade && camposComplementaresConformidade.map((campoConformidade) => (
                  <React.Fragment key={campoConformidade.ordem}>
                    <hr />
                    <div className='row align-items-center'>
                      <CampoConformidadeElement exibindo={exibindo} campoConformidade={campoConformidade} campo={campo} 
                        changeCampoConformidade={changeCampoConformidade} deletarCampoConformidade={deletarCampoConformidade}
                        moveUp={moverCampoConformidadeParaCima} moveDown={moverCampoConformidadeParaBaixo}/>

                    </div>
                  </React.Fragment>
                ))
              }
            </div>
        </> :
        <></>
      }
      {
        campo.tipo === "CAMPOCONJUNTO_PFPJ_CPFCPNJ_TEXTO" ?
          <>
            {
              exibindo ?
                <>
                  <label>Resultado do Processo Licitatório</label>
                  {/* <label>Nomes, Documentos e Texto</label> */}
                </> :
                <>
                  <div className='row mt-3 mx-2'>
                    {/* <label className='pt-1 mx-2'>Nomes, Documentos e Texto</label> */}
                    <label>
                      Resultado do Processo Licitatório&nbsp;
                      <Tippy content={"Informar a licitação/contratação direta com número e ano; tipo de licitação; instrumento celebrado com número e ano; " +
                                      "itens/lotes contratados/adquiridos; valor total; Nota de Empenho com número e ano; dentre outras."}>
                        <i className="fas fa-question-circle text-info" />
                      </Tippy>
                    </label>
                    <div>
                      {/* <Tippy content="Adicionar Campo">
                        <button className="btn col-1 text-primary" type='button' onClick={adicionarAditivo}><i className="fa fa-plus" /></button>
                      </Tippy> */}
                      {buttonExibirComentario}
                      {buttonAdicionarComentario}
                      {buttonDeletarCampo}
                    </div>
                  </div>
                  {/* <div className='row'>
                    <input type="text" className='form-control col-4' placeholder='Digite o nome da Pessoa Física/Jurídica...'></input>
                    <input type="text" className='form-control col-4' placeholder='Digite o CPF/CNPJ...'></input>
                  </div>
                  <div>
                    <textarea className='form-control' rows={3} placeholder='Digite as informações necessárias...'></textarea>
                  </div>
                  <div className='text-center'>
                    <button type='button' className='btn btn-primary'>Adicionar</button>
                  </div> */}
                </>
            }
            <div className='col'>
              {
                conjuntosNomeDocTexto && conjuntosNomeDocTexto.map((conjunto) => (
                  <React.Fragment key={conjunto.ordem}>
                    <div className='row'>
                      <div className='my-2 mx-2 pr-2'>
                        <br />
                        <p className='text-bold'>
                          {conjunto?.ordem} -
                        </p>
                      </div>
                      <div className='col'>
                        <label>Pessoa Física/Jurídica</label>
                        {
                          exibindo ?
                            <p>
                              {conjunto?.nome}
                            </p> :
                            <input type="text" className='form-control col' placeholder='Digite o nome da Pessoa Física/Jurídica...' 
                            value={conjunto?.nome}
                            onChange={(e) => {
                              changeConjuntoNomeDocTexto({
                                ...conjunto,
                                nome: e.target.value
                              })
                            }}></input>
                        }
                      </div>
                      <div className='col'>
                        <label>CPF/CNPJ</label>
                        {
                          exibindo ?
                            <p>
                              {conjunto?.documento}
                            </p> :
                            <input type="text" className='form-control col' placeholder='Digite o CPF/CNPJ...' 
                            value={conjunto?.documento} 
                            onChange={(e) => {
                              changeConjuntoNomeDocTexto({
                                ...conjunto,
                                documento: e.target.value
                              })
                            }}></input>
                        }
                      </div>
                    </div>
                    {
                      exibindo ?
                      <p>{conjunto?.texto}</p> :
                      <div>
                        <textarea className='form-control' rows={3} placeholder='Digite as informações necessárias...' 
                        value={conjunto?.texto} 
                        onChange={(e) => {
                          changeConjuntoNomeDocTexto({
                            ...conjunto,
                            texto: e.target.value
                          })
                        }}></textarea>
                      </div>
                    }
                    {
                      exibindo ? 
                      <></> :
                      <div className='text-center'>
                        <Tippy content="Deletar campo">
                          <button type='button' className='btn btn-outline-danger my-2' onClick={()=>deletarConjuntoNomeDocTexto(conjunto)}>
                            <span className='fa fa-trash'></span>&nbsp;Excluir
                          </button>
                        </Tippy>
                      </div>
                    }
                  </React.Fragment>
                ))
              }
              {
                exibindo ?
                <></> :
                <div className=''>
                  <button type='button' className='btn btn-outline-primary' 
                  onClick={adicionarConjuntoNomeDocTexto}>Adicionar Novo</button>
                </div>
              }
            </div>
          </> :
          <></>
      }
    </div>
  )
}
