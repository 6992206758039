import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import LoadingElement from '../../components/LoadingElement';
import Requisicao from '../../helpers/Requisicao';
import EnumUtils from '../../helpers/EnumUtils';
import Utilidades from '../../helpers/Utilidades';
import { isValid, parseISO } from 'date-fns';
// import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import Tippy from '@tippyjs/react';
import Autenticar from '../../login/Autenticar';
import analisePDF from '../../reports/analises/AnalisePDF';

let nomesTipos = {};
// let nomesFilas = {};

export default function AnaliseInfo({ usuario, autenticado }) {
  const { analiseId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();


  const [loadingAnalise, setLoadingAnalise] = useState(true);
  const [loadingProcesso, setLoadingProcesso] = useState(true);
  const [loadingVinculadas, setLoadingVinculadas] = useState(true);
  const [loadingAlterarStatus, setLoadingAlterarStatus] = useState(false);
  const [loadingAlterarFila, setLoadingAlterarFila] = useState(false);

  // const [unidadeResponsavel, setUnidadeResponsavel] = useState(null);

  const [pesquisasDePrecos, setPesquisasDePrecos] = useState([]);


  const [analise, setAnalise] = useState(null);
  const [analisesVinculadas, setAnalisesVinculadas] = useState(null);
  const [processo, setProcesso] = useState(null);

  const nomesStatus = {
    "EM_EDICAO": "Edição",
    "EM_VERIFICACAO": "Verificação",
    "ENCAMINHADA": "Encaminhada",
    "FINALIZADA": "Finalizada"
  }

  // const [fila, setFila] = useState("EM_ANALISE");


  // componentDidMount
  useEffect(() => {
    if (autenticado) {
      carregarAnalise(analiseId);
      // carregarTiposAnalise();
      document.getElementById("comboboxSelecionarNovaFilaAnalisesVinculadas").options.selectedIndex = 0;
      document.getElementById("comboboxSelecionarNovoStatusAnalise").options.selectedIndex = 0;
    }
    document.title = 'Informações da Análise - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
    // eslint-disable-next-line
  }, [autenticado, analiseId])

  const imprimirAnalisePDF = async () => {

    const analiseImprimir = await Requisicao.getAnalisePorId(analiseId);
    const unidadeImprimir = await Requisicao.getUnidadeById(analise.unidadeOrigemId);
    // Requisicao.getUnidadeById(analise.unidadeOrigemId)
    //   .then(data => {
    //     if(data != null){
    //       analisePDF(analise, processo, data);
    //     }
    //   })
    //   .catch(erro => console.log('erroGetUnidade', erro));
    // console.log('analiseImprimir', analiseImprimir);

    if (analiseImprimir.blocos != null && analiseImprimir.blocos.length > 0) {
      analiseImprimir.blocos.sort(Utilidades.sortPorOrdem);

      analiseImprimir.blocos.forEach((bloco) => {
        if (bloco.filhos != null && bloco.filhos.length > 0) {
          bloco.filhos.sort(Utilidades.sortPorOrdem);
          bloco.filhos.forEach((filho) => {
            if (filho.netos != null && filho.netos.length > 0) {
              filho.netos.sort(Utilidades.sortPorOrdem);
            }
          });
        }
        if (bloco.recomendacoes != null && bloco.recomendacoes.length > 0) {
          bloco.recomendacoes.sort(Utilidades.sortPorOrdem);
        }
      });

    }

    analiseImprimir.camposComplementares.sort(Utilidades.sortPorOrdem);

    console.log('analiseImprimir', analiseImprimir);
    analisePDF(analiseImprimir, processo, unidadeImprimir, usuario);
  }

  const carregarAnalise = (id) => {
    setLoadingAnalise(true);
    Requisicao.getAnaliseDadosBasicosPorId(id)
      .then(data => {
        // console.log('analise', data);
        if (data != null) {
          setLoadingAnalise(false);
          setupAnalise(data);
          carregarPesquisasDePreco(id);
          if (data.analiseOrigemId != null) {
            carregarAnalisesVinculdadas(data.analiseOrigemId);
          }
          

        } else {
          alert("Análise não econtrada ou você não tem permissão para acessá-la.");
          navigate("/processo/pesquisar", { state: { referrer: location }, replace: true });
        }
      })
      .catch((erro) => console.log(erro));
  }

  const carregarPesquisasDePreco = (id) => {
    Requisicao.getPesquisaDePrecosPorAnalise(id)
      .then(data => {
        if(data != null){
          setPesquisasDePrecos(data);
          console.log('pesquisas', data);

        }
      }).catch(erro => console.log('erro', erro));
  }

  const carregarAnalisesVinculdadas = (id) => {
    setLoadingVinculadas(true);
    Requisicao.getListaAnalisesVinculadasPorAnaliseId(id)
      .then(data => {
        setAnalisesVinculadas(data);
        setLoadingVinculadas(false);

        Requisicao.getListaTiposAnalise()
          .then((data) => {
            nomesTipos = {};
            data.forEach((tipo) => {
              nomesTipos[tipo.value] = tipo.nome;
            });
          })
          .catch((erro) => console.log(erro));

        // Requisicao.getListaFilasAnalise()
        //   .then((data) => {
        //     nomesFilas = {};
        //     data.forEach((fila)=>{
        //       nomesFilas[fila.value] = fila.nome;
        //     });
        //   })
        // .catch((erro) => console.log(erro));
      })
      .catch((erro) => console.log(erro));
  }

  const carregarProcessoBasico = (id) => {
    setLoadingProcesso(true);
    Requisicao.getProcessoDadosBasicosPorId(id)
      .then(data => {
        if (data != null) {
          setLoadingProcesso(false);
          setupProcesso(data);
        } else {
          console.log("Processo vinculado à analise não encontrado.\n" +
            "Informe este erro ao administrador do sistema.\n" +
            "ID da análise: " + analiseId)
          navigate("/processo/pesquisar", { state: { referrer: location }, replace: true });
        }
      })
      .catch((erro) => console.log(erro));
  }

  // const carregarTiposAnalise = () => {
  //   const token = Autenticar.getLocalToken();

  //   const requestOptions = {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + token,
  //     }
  //   };

  //   const url = window.servidor + "/v1/protegido/analise/tiposanalise";

  //   fetch(url, requestOptions)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // setTiposAnalise(data);
  //       nomesTipos = {};
  //       data.forEach((tipo)=>{
  //         nomesTipos[tipo.value] = tipo.nome;
  //       });
  //     })
  //     .catch((erro) => console.log(erro));
  // };

  const setupAnalise = async (a) => {
    if (a.processoId != null) {
      carregarProcessoBasico(a.processoId);
    } else {
      alert("Processo vinculado à analise não encontrado.\n" +
        "Informe este erro ao administrador do sistema.\n" +
        "ID da análise: " + analiseId)
      navigate("/processo/pesquisar", { state: { referrer: location }, replace: true });
    }
    if (!isAdministradorOuAdministradorSCF() && a?.desativada === true) {
      navigate("/processo/pesquisar", { state: { referrer: location }, replace: true });
    }

    // Setar o nome dos enums lei, modalidade e tipo
    a.leiLicitacaoNome = await EnumUtils.getNomeLeiLicitacao(a.leiLicitacao)
      .catch(erro => {
        console.log('erro', erro);
        a.leiLicitacaoNome = a.leiLicitacao;
      });

    a.modalidadeLicitacaoNome = await EnumUtils.getNomeModalidadeLicitacao(a.modalidadeLicitacao)
      .catch(erro => {
        console.log('erro', erro);
        a.modalidadeLicitacaoNome = a.modalidadeLicitacao;
      });

    a.tipoAnaliseNome = await EnumUtils.getNomeTipoAnalise(a.tipoAnalise)
      .catch(erro => {
        console.log('erro', erro);
        a.tipoAnaliseNome = a.tipoAnalise;
      });

    a.filaAnaliseNome = await EnumUtils.getNomeFilaAnalise(a.filaAnalise)
      .catch(erro => {
        console.log('erro', erro);
        a.filaAnaliseNome = a.filaAnalise;
      });

    a.valorEstimadoExibicao = Utilidades.getValorFormatado(a.valorEstimado);
    a.dataEntradaExibicao = parseISO(a.dataEntrada).toLocaleString('pt-BR').substring(0, 10);

    // let uniResp = await Requisicao.getUnidadeById(a?.unidadeResponsavelId)
    // a.unidadeResponsavelNome = await uniResp?.nome;
    a.unidadeResponsavelNome = await Requisicao.getNomeUnidadeById(a?.unidadeResponsavelId);

    a.statusAnaliseNome = await EnumUtils.getNomeStatusAnalise(a.statusAnalise);

    // setUnidadeResponsavel(uniResp);
    setAnalise(a);
    // console.log('a', a);
  }

  const setupProcesso = async (p) => {
    p.orgaoNome = await EnumUtils.getNomeOrgao(p.orgaoSolicitante)
      .catch(erro => {
        console.log('erro', erro);
        p.orgaoNome = p.orgaoSolicitante;
      })

    setProcesso(p);
  }

  const isAnaliseStatusFinalizada = () => {
    if (analise?.statusAnalise === "FINALIZADA") {
      return true;
    }
    return false;
  }

  const isAdministradorOuAdministradorSCF = () => {
    if (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR") {
      return true;
    }
    return false;
  }

  const isMesmaUnidadeOuAdministrador = () => {
    if (parseInt(usuario?.unidadeId) === parseInt(analise?.unidadeResponsavelId) || isAdministradorOuAdministradorSCF()) {
      return true;
    }
    return false;
  }

  const isMesmaUnidade = () => {
    if (parseInt(usuario?.unidadeId) === parseInt(analise?.unidadeResponsavelId)) {
      // if (parseInt(usuario?.unidadeId) === parseInt(analise?.unidadeOrigemId)) {
      return true;
    }
    return false;
  }

  const isPerteceUnidadeDestino = () => {
    if (parseInt(usuario?.unidadeId) === parseInt(analise?.unidadeDestinoId)) {
      return true;
    }
    return false;
  }

  const isAnalistaOuRevisorUC = () => {
    if (usuario?.tipoUsuario === "SCF_ANALISTA_UC" || usuario?.tipoUsuario === "SCF_REVISOR_UC") {
      return true;
    }
    return false;
  }
  const isRevisorUC = () => {
    if (usuario?.tipoUsuario === "SCF_REVISOR_UC") {
      return true;
    }
    return false;
  }

  const isAnalistaUC = () => {
    if (usuario?.tipoUsuario === "SCF_ANALISTA_UC") {
      return true;
    }
    return false;
  }

  const isAnalistaOuResponsavelUDCI = () => {
    if (usuario?.tipoUsuario === "SCF_ANALISTA_UDCI" || usuario?.tipoUsuario === "SCF_REVISOR_UDCI") {
      return true;
    }
    return false;
  }
  const isAnalistaUDCI = () => {
    if (usuario?.tipoUsuario === "SCF_ANALISTA_UDCI") {
      return true;
    }
    return false;
  }
  const isResponsavelUDCI = () => {
    if (usuario?.tipoUsuario === "SCF_REVISOR_UDCI") {
      return true;
    }
    return false;
  }

  const ativarAnalise = () => {
    setLoadingAnalise(true);
    Requisicao.setAtivarAnalise(analiseId)
      .then(data => {
        console.log('Ativar análise: ', data);
        carregarAnalise(analiseId);
      })
      .catch(erro => {
        console.log('erro', erro);
        setLoadingAnalise(false);
      })
  }

  const desativarAnalise = () => {
    setLoadingAnalise(true);
    Requisicao.setDesativarAnalise(analiseId)
      .then(data => {
        console.log('Desativar análise: ', data);
        carregarAnalise(analiseId);
      })
      .catch(erro => {
        console.log('erro', erro);
        setLoadingAnalise(false);
      })
  }

  const alterarStatus = (novoStatus) => {
    if (["EM_EDICAO", "EM_VERIFICACAO", "ENCAMINHADA", "FINALIZADA"].includes(novoStatus)) {
      setLoadingAlterarStatus(true);
      Requisicao.updateStatusAnalise(novoStatus, analiseId)
        .then(data => {
          setLoadingAlterarStatus(false);
          window.$("#modalAlterarStatus").modal("hide");
          // if (analise?.analiseOrigemId != null) {
          //   carregarAnalisesVinculdadas(analise.analiseOrigemId);
          // }
          carregarAnalise(analiseId);

        })
        .catch(erro => console.log('erro', erro));
    }

  }

  const alterarFila = (novaFila) => {
    if (["EM_ANALISE", "EM_REVISAO", "EM_MANIFESTACAO", "FINALIZADO"].includes(novaFila)) {
      setLoadingAlterarFila(true);
      Requisicao.updateFilaAnalise(novaFila, analise?.analiseOrigemId ?? analiseId)
        .then(data => {
          setLoadingAlterarFila(false);
          window.$("#modalAlterarFila").modal("hide");
          carregarAnalise(analiseId);
        })
        .catch(erro => console.log('erro', erro));
    }
  }



  // const getNomeUnidadeResponsavel = (id) => {
  //   const send = (nome) => {return nome;}
  //   Requisicao.getNomeUnidadeById(id).then(nome => send(nome)).catch(erro => console.log('erro', erro));

  // }

  return (
    <>
      {/* Modal Alterar Fila */}
      <div className="modal fade" id="modalAlterarFila" tabIndex={-1} aria-labelledby="modalAlterarFilaLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalAlterarFilaLabel">Alterar Fila</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Esta ação irá alterar em qual fila se econtra todas as analises vinculadas listadas abaixo.</p>
              <p></p>
              <p>Selecione a opção desejada:</p>
              <select id="comboboxSelecionarNovaFilaAnalisesVinculadas" className='form-control col'>
                <option value="-1" disabled>Selecione a file desejada...</option>
                <option value="EM_ANALISE">Em Análise</option>
                <option value="EM_REVISAO">Em Revisão</option>
                <option value="EM_MANIFESTACAO">Em Cumprimento das Recomendações</option>
                <option value="FINALIZADO">Finalizado</option>
              </select>
            </div>
            <div className="modal-footer">
              {
                (loadingAlterarFila) ?
                  <>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" disabled>Cancelar</button>
                    <button type="button" className="btn btn-primary" disabled>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;Alterar
                    </button>
                  </> :
                  <>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="button" className="btn btn-primary" onClick={() => {
                      let value = document.getElementById("comboboxSelecionarNovaFilaAnalisesVinculadas").value;
                      alterarFila(value);
                    }}>Alterar</button>
                  </>
              }

            </div>
          </div>
        </div>
      </div>
      {/* Modal Alterar Status*/}
      <div className="modal fade" id="modalAlterarStatus" tabIndex={-1} aria-labelledby="modalAlterarStatusLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="modalAlterarStatusLabel">Alterar Estado</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Esta ação irá alterar o estado da análise selecionada.</p>
              <p></p>
              <p>Selecione a opção desejada:</p>
              <select id="comboboxSelecionarNovoStatusAnalise" className='form-control col'>
                <option value="-1" disabled>Selecione o estado desejado...</option>
                <option value="EM_EDICAO">{nomesStatus["EM_EDICAO"]}</option>
                <option value="EM_VERIFICACAO">{nomesStatus["EM_VERIFICACAO"]}</option>
                <option value="ENCAMINHADA">{nomesStatus["ENCAMINHADA"]}</option>
                <option value="FINALIZADA">{nomesStatus["FINALIZADA"]}</option>
              </select>
            </div>
            <div className="modal-footer">
              {
                (loadingAlterarStatus) ?
                  <>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal" disabled>Cancelar</button>
                    <button type="button" className="btn btn-primary" disabled>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;Alterar
                    </button>
                  </> :
                  <>
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                    <button type="button" className="btn btn-primary" onClick={() => {
                      let value = document.getElementById("comboboxSelecionarNovoStatusAnalise").value;
                      alterarStatus(value);
                    }}>Alterar</button>
                  </>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Informações Análise</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Informações Análise</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12">
          <div className="card">
            <div className="card-body">
              {
                (analise?.nome != null) ?
                  <h2 className='text-center'>{analise?.nome}</h2> :
                  <h2 className='text-center'>{analise?.tipoAnaliseNome}</h2>
              }
              {(loadingProcesso === true) ? <LoadingElement /> : <></>}
              {
                (processo != null) ?
                  <div className="mt-3 col px-0 mx-0">
                    <br />
                    <div className='row'>
                      <div className='col-6'>
                        <label>Número do Processo</label>
                        <p>
                          <Link to={`/processo/exibir/${processo.id}`} className="text-info">{processo.numero}</Link>
                          {/* <Link to={`/processo/pesquisar?numero=${processo.numero}`} className="text-info">{processo.numero}</Link> */}
                        </p>
                      </div>
                      <div className='col-6'>
                        <label>Volumes e Apensos</label>
                        <p>
                          {processo?.volumeseApensos}
                        </p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col'>
                        <label>Objeto do Processo</label>
                        <p>
                          {processo?.objeto}
                        </p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <label>Unidade de Origem (Processo)</label>
                        <p>
                          {processo?.unidade?.nome}
                        </p>
                      </div>
                      <div className='col-6'>
                        <label>Órgão Solicitante</label>
                        <p>
                          {processo?.orgaoNome}
                        </p>
                      </div>
                    </div>
                  </div> :
                  <></>
              }
              {
                (analise != null) ?
                  <div className="col px-0 mx-0">
                    <div className='row'>
                      <div className='col-6'>
                        <label>Unidade Responsável (Análise)</label>
                        <p>
                          {analise?.unidadeResponsavelNome}
                        </p>
                      </div>
                      <div className='col-6'>
                        <label>Tipo da Análise</label>
                        <p>
                          {analise?.tipoAnaliseNome}
                        </p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <label>Lei da Licitação</label>
                        <p>
                          {analise?.leiLicitacaoNome}
                        </p>
                      </div>
                      <div className='col-6'>
                        <label>Modalidade da Licitação</label>
                        <p>
                          {analise?.modalidadeLicitacaoNome}
                        </p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <label>Data de Entrada</label>
                        <p>
                          {analise?.dataEntradaExibicao}
                        </p>
                      </div>
                      <div className='col-6'>
                        <label>Valor</label>
                        <p>
                          {analise?.valorEstimadoExibicao}
                        </p>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-6'>
                        <label>Fila</label>
                        <p>
                          {analise?.filaAnaliseNome}
                        </p>
                      </div>
                      <div className='col-6'>
                        <label>Estado</label>
                        <p>
                          {analise?.statusAnaliseNome}
                        </p>
                      </div>
                    </div>
                    <div className='p-2 text-center d-flex flex-md-row flex-column justify-content-center'>
                      {/* {
                        (!isAdministradorOuAdministradorSCF() || loadingAnalise) ?
                          <>
                            {
                              (analise.desativada) ?
                                <button type="button" className="btn btn-danger btn-sm mr-2 my-2" disabled>
                                  <i className="far fa-square"></i>&nbsp;Desativada
                                </button> :
                                <button type="button" className="btn btn-success btn-sm mr-2 my-2" disabled>
                                  <i className="far fa-check-square"></i>&nbsp;Ativada
                                </button>
                            }
                          </> :
                          <>
                            {
                              (analise?.desativada) ?
                                <Tippy content="Ativar análise">
                                  <button type="button" className="btn btn-danger btn-sm mr-2 my-2" onClick={() => ativarAnalise()}>
                                    <i className="far fa-square"></i>&nbsp;Desativada
                                  </button>
                                </Tippy> :
                                <Tippy content="Desativar análise">
                                  <button type="button" className="btn btn-success btn-sm mr-2 my-2" onClick={() => desativarAnalise()}>
                                    <i className="far fa-check-square"></i>&nbsp;Ativada
                                  </button>
                                </Tippy>
                            }
                          </>
                      } */}
                      {
                        (isAdministradorOuAdministradorSCF()) ?
                          <>
                            {
                              (loadingAnalise) ?
                                <>
                                  {
                                    (analise.desativada) ?
                                      <button type="button" className="btn btn-danger btn-sm mr-2 my-2" disabled>
                                        <i className="far fa-square"></i>&nbsp;Desativada
                                      </button> :
                                      <button type="button" className="btn btn-success btn-sm mr-2 my-2" disabled>
                                        <i className="far fa-check-square"></i>&nbsp;Ativada
                                      </button>
                                  }
                                </> :
                                <>
                                  {
                                    (analise?.desativada) ?
                                      <Tippy content="Ativar análise">
                                        <button type="button" className="btn btn-danger btn-sm mr-2 my-2" onClick={() => ativarAnalise()}>
                                          <i className="far fa-square"></i>&nbsp;Desativada
                                        </button>
                                      </Tippy> :
                                      <Tippy content="Desativar análise">
                                        <button type="button" className="btn btn-success btn-sm mr-2 my-2" onClick={() => desativarAnalise()}>
                                          <i className="far fa-check-square"></i>&nbsp;Ativada
                                        </button>
                                      </Tippy>
                                  }
                                </>
                            }
                          </> :
                          <></>
                      }
                      {
                        (analise?.desativada !== true && (isAdministradorOuAdministradorSCF() || (usuario?.permissoes?.includes("PERM_SCF_ALTERAR_FILA_ANALISE") && isMesmaUnidade()))) ?
                          <>
                            <button type='button' className='btn btn-info btn-sm mr-2 my-2' data-toggle="modal" data-target="#modalAlterarFila"><i className="fas fa-pencil-alt"></i>&nbsp;Alterar Fila</button>
                            <button type='button' className='btn btn-info btn-sm mr-2 my-2' data-toggle="modal" data-target="#modalAlterarStatus"><i className="fas fa-pencil-alt"></i>&nbsp;Alterar Estado</button>
                          </> :
                          <></>
                      }
                      {
                        (analise?.desativada && !isAdministradorOuAdministradorSCF()) ? <></> :
                          <>
                            {/* {
                              (isAdministradorOuAdministradorSCF()) ?
                                <>
                                  <button type='button' className='btn btn-info btn-sm mr-2 my-2' data-toggle="modal" data-target="#modalAlterarFila"><i className="fas fa-pencil-alt"></i>&nbsp;Alterar Fila</button>
                                  <button type='button' className='btn btn-info btn-sm mr-2 my-2' data-toggle="modal" data-target="#modalAlterarStatus"><i className="fas fa-pencil-alt"></i>&nbsp;Alterar Estado</button>
                                </> :
                                <></>
                            } */}
                            {
                              (isAdministradorOuAdministradorSCF()
                                || (isMesmaUnidade() && analise?.filaAnalise === "EM_MANIFESTACAO")
                                || (isAnalistaOuRevisorUC() && analise?.filaAnalise === "EM_REVISAO")
                                || (isAnalistaOuRevisorUC() && isMesmaUnidade())
                                || (analise?.filaAnalise === "EM_MANIFESTACAO" && analise?.statusAnalise === "ENCAMINHADA")
                              ) ?
                                <Link className="btn btn-primary btn-sm mr-2 my-2" to={`/analise/${analise.id}/novaanalisevinculada`}>
                                  <i className="far fa-file-alt"></i>
                                  &nbsp;Criar Nova Análise Vinculada
                                </Link> :
                                <button className="btn btn-primary btn-sm mr-2 my-2" type='button' disabled>
                                  <i className="far fa-file-alt"></i>
                                  &nbsp;Criar Nova Análise Vinculada
                                </button>
                            }
                            {
                              ((!isAnaliseStatusFinalizada() && isMesmaUnidade()) || isAdministradorOuAdministradorSCF()
                              //  || (usuario?.permissoes?.includes("PERM_SUBGERAL_PESQUISA_DE_PRECOS"))
                               ) ?
                                <Link className="btn btn-dark btn-sm mr-2 my-2" to={`/pesquisadeprecos?analiseId=${analise.id}`}>
                                  <i className="fas fa-search-dollar"></i>
                                  &nbsp;Nova Pesquisa de Preços
                                </Link> :
                                <button className="btn btn-dark btn-sm mr-2 my-2" type='button' disabled>
                                  <i className="fas fa-search-dollar"></i>
                                  &nbsp;Nova Pesquisa de Preços
                                </button>
                            }
                            {
                              // (isAdministradorOuAdministradorSCF() || (isMesmaUnidade() && isResponsavelUDCI()) || (isMesmaUnidade() && isRevisorUC())) ?
                              (isAdministradorOuAdministradorSCF() || (isMesmaUnidade() && analise?.statusAnalise === "EM_EDICAO") 
                                || (isMesmaUnidade() && (isRevisorUC() || isResponsavelUDCI()) && analise?.statusAnalise === "EM_VERIFICACAO")) ?
                                <Link className='btn btn-secondary btn-sm mr-2 my-2' to={`/analise/editar/dadosbasicos/${analiseId}`}>
                                  <i className="fas fa-pencil-alt"></i>
                                  &nbsp;Alterar Dados Básicos
                                </Link> :
                                <button className='btn btn-secondary btn-sm mr-2 my-2' type='button'
                                  onClick={() => alert("Você não possui permissão para alterar estes dados.\n" +
                                    "Caso seja ncessário alterar os dados básicos da análise, entre em contato com o Órgão Central de Controle Interno e informe o ID da análise ou o número do processo.\n" +
                                    "ID da análise: " + analiseId)}>
                                  <i className="fas fa-pencil-alt"></i>
                                  &nbsp;Alterar Dados Básicos
                                </button>
                              // <button className='btn btn-secondary btn-sm mr-2 my-2' type='button' disabled
                              //   onClick={() => alert("Não foi possível realizar esta ação.")}>
                              //   <i className="fas fa-pencil-alt"></i>
                              //   &nbsp;Alterar Dados Básicos
                              // </button>
                            }
                            {
                              ((!isAnaliseStatusFinalizada() && isMesmaUnidade()) || isAdministradorOuAdministradorSCF()) ?
                                <Link className="btn btn-success btn-sm mr-2 my-2" to={`/analise/editar/${analise.id}`}>
                                  <i className="far fa-edit"></i>
                                  &nbsp;Editar
                                </Link> :
                                <button className="btn btn-success btn-sm mr-2 my-2" type='button' disabled>
                                  <i className="far fa-edit"></i>
                                  &nbsp;Editar
                                </button>
                            }
                            <Link className="btn btn-warning btn-sm mr-2 my-2" to={`/analise/visualizar/${analise.id}`}>
                              <i className="far fa-file-alt"></i>
                              &nbsp;Visualizar
                            </Link>
                            {
                              (analise?.dataSaida != null && analise?.dataSaida !== "") || isAdministradorOuAdministradorSCF() ? 
                                <button className="btn btn-danger btn-sm mr-2 my-2" type='button' onClick={() => imprimirAnalisePDF()}>
                                  <i className="far fa-file-pdf"></i>
                                  &nbsp;Imprimir
                                </button>
                                :
                                <Tippy content={"Impressão desativada pois a análise ainda não foi finalizada. Finalize e encaminhe primeiro a análise para depois imprimí-la."}>
                                  <div>
                                    <button className="btn btn-danger btn-sm mr-2 my-2" type='button' disabled>
                                      <i className="far fa-file-pdf"></i>
                                      &nbsp;Imprimir
                                    </button>
                                  </div>
                                </Tippy>
                            }

                          </>
                      }
                    </div>

                  </div> :
                  <></>
              }

              {
                loadingVinculadas ?
                  <LoadingElement /> :
                  <>
                    {
                      (analisesVinculadas != null && analisesVinculadas instanceof Array && analisesVinculadas?.length > 0 && analise != null) ?
                        <>
                          <table className="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th colSpan={9} className="text-center">Análises Vinculdads</th>
                              </tr>
                              <tr>
                                <th>ID</th>
                                <th>Nome</th>
                                <th>Tipo de Análise</th>
                                <th>Data de Entrada</th>
                                <th>Data de Saída</th>
                                <th>Valor</th>
                                <th>Última Modificação</th>
                                <th>Estado</th>
                                <th>Botões</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                analisesVinculadas && analisesVinculadas.map((analise) => (
                                  <tr key={analise.id}>
                                    <td>{analise.id}</td>
                                    <td>{analise?.nome ?? "Sem informações"}</td>
                                    <td>{nomesTipos[analise.tipoAnalise]}</td>
                                    <td>{isValid(parseISO(analise?.dataEntrada)) ? parseISO(analise?.dataEntrada).toLocaleDateString('pt-BR') : ""}</td>
                                    <td>{isValid(parseISO(analise?.dataSaida)) ? parseISO(analise?.dataSaida).toLocaleDateString('pt-BR') : ""}</td>
                                    <td>{Utilidades.getValorFormatado(analise?.valorEstimado)}</td>
                                    <td>{analise.usuarioUltimaEdicao}{isValid(parseISO(analise?.dataModificacao)) ? " - " + parseISO(analise?.dataModificacao).toLocaleString('pt-BR') : ""}</td>
                                    <td>{nomesStatus[analise?.statusAnalise]}</td>
                                    <td className="project-actions text-right">
                                      {
                                        (parseInt(analise.id) === parseInt(analiseId)) ?
                                          <div className='text-center'>Selecionada</div> :
                                          <Link className="btn btn-warning btn-sm mr-2" to={`/analise/${analise.id}`}>
                                            <i className="far fa-file-alt"></i>
                                            &nbsp;Abrir
                                          </Link>
                                      }
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </> :
                        <></>
                    }
                  </>
              }
              {
                (pesquisasDePrecos != null && pesquisasDePrecos instanceof Array && pesquisasDePrecos?.length > 0 && analise != null) ?
                  <>
                    <br />
                    <br />
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th colSpan={9} className="text-center">Pesquisas de Preço</th>
                        </tr>
                        <tr>
                          <th>ID</th>
                          <th>Observações</th>
                          <th>Data Modificação</th>
                          <th>Analista</th>
                          <th>Botões</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          pesquisasDePrecos && pesquisasDePrecos.map((pesquisa) => (
                            <tr key={pesquisa.id}>
                              <td>{pesquisa.id}</td>
                              <td>{pesquisa.observacoes}</td>
                              <td>{isValid(parseISO(pesquisa?.dataPesquisa)) ? parseISO(pesquisa?.dataPesquisa).toLocaleString('pt-BR') : ""}</td>
                              <td>{pesquisa.nomeAnalista}</td>
                              <td className="project-actions text-right">
                                    <Link className="btn btn-warning btn-sm mr-2" to={`/pesquisadeprecos/${pesquisa.id}`}>
                                      <i className="far fa-file-alt"></i>
                                      &nbsp;Abrir
                                    </Link>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </> :
                  <></>
              }

            </div>
          </div>
        </form>
      </div>
    </>
  )
}
