import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { logoPrefeituraRodape } from './images';
import EnumUtils from '../../helpers/EnumUtils';
import TipoAditivo from '../../pages/analise/campos/TipoAditivo';
import { isValid, parseISO } from 'date-fns';
import Utilidades from '../../helpers/Utilidades';
import htmlToPdfmake from 'html-to-pdfmake';
import TipoCampoConformidade from '../../pages/analise/campos/TipoCampoConformidade';

async function analisePDF(analise, processo, unidade, usuario) {

  let nextId = 1;

  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const win = window.open('', '_blank');

  function getValorCampoBoolean(tipoCampoBoolean) {
    if (analise?.camposComplementares != null && analise?.camposComplementares?.length > 0) {
      let campos = analise?.camposComplementares;
      for (let i = 0; i < campos.length; i++) {
        if (campos[i]?.tipo === "BOOLEAN" && campos[i]?.campoCompBoolean?.tipoCampoCompBoolean === tipoCampoBoolean) {
          if (campos[i]?.campoCompBoolean?.valor === true) {
            return true;
          } else {
            return false;
          }
        }
      }
      return false;
    } else {
      return false;
    }
  }

  // function existsCampo(tipo, infoAdicional) {
  //   if (analise?.camposComplementares != null && analise?.camposComplementares?.length > 0) {
  //     let campos = analise?.camposComplementares;
  //     for (let i = 0; i < campos.length; i++) {
  //       if (campos[i]?.tipo === tipo && (infoAdicional == null || campos[i]?.infoAdicional === infoAdicional)) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   } else {
  //     return false;
  //   }
  // }

  const orgaoNome = await EnumUtils.getNomeOrgao(processo.orgaoSolicitante);
  const modalidadeNome = (analise.modalidadeLicitacao != null && analise.modalidadeLicitacao !== "") ? (await EnumUtils.getNomeModalidadeLicitacao(analise.modalidadeLicitacao) + (getValorCampoBoolean("SISTEMA_REGISTRO_PRECO") ? " - Sistema de Registro de Preços" : "")) : "";

  const isConformidade = (analise?.tipoAnalise === "ANALISE_DE_CONFORMIDADE" || analise?.tipoAnalise === "REVISAO_ANALISE_DE_CONFORMIDADE") ? true : false;
  const isManifestacao = (analise?.tipoAnalise === "MANIFESTACAO") ? true : false;
  // const isPrevia = (analise?.tipoAnalise === "ANALISE_PREVIA") ? true : false;

  function marginInCm(cm) {
    // console.log(cm + ": " + Math.floor(Number(cm) / 2.54 * 72));
    return Math.floor(Number(cm) / 2.54 * 72);
  }

  function getTextoFormatado(texto) {
    if (texto != null && texto !== "") {
      return "\t\t\t" + texto?.toString().replaceAll("\t", "").replaceAll("\n", "\n\t\t\t");
    }
    return '';
  }

  function getTextoSublinhadoNegritado(texto) {
    let text = [];

    let paragrafos = texto?.split('\n');
    paragrafos?.forEach(p => {
      p.replaceAll('\n', '');
      text.push({
        text: '\t\t\t',
        preserveLeadingSpaces: true
      })
      text.push(
        {
          text: p + '\n',
          alignment: 'justify',
          bold: true,
          decoration: 'underline',
          preserveLeadingSpaces: true
        }
      )
    })

    if (text.length > 0) {
      console.log('text', text)
      return text;
    }

    return '';

  }

  function inserirTabulação(html) {
    if (html != null) {

      // let tab = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
      // let inicio = html.indexOf("<p>");
      // let fim = html.indexOf("</p>");

      // let arr = [];

      // if (inicio === -1) {
      //   arr.push('<p>' + html + '</p>');
      // } else {
      //   if(inicio !== 0){
      //     arr.push(html.substring(0, inicio));
      //   }
      //   while (inicio !== -1) {
      //     arr.push(html.substring(inicio, fim + 4));
      //     inicio = html.indexOf("<p>", inicio + 3);
      //     if(inicio !== fim + 4 && inicio > fim + 4 && inicio !== -1){
      //       arr.push(html.substring(fim + 4, inicio));
      //     }
      //     fim = html.indexOf("</p>", fim + 4);
      //   }
      // }
      // arr?.forEach(function (p, index) {
      //   let i = p.indexOf("<p>");
      //   if (i !== -1) {
      //     this[index] = p.substring(i, i + 3) + tab + p.substring(i + 3);
      //   }
      // }, arr)
      // return arr.join('');

      // Disable?
      if(html.indexOf("<p>") === -1){
        html = '<p>' + html + '</p>';
      }

      html = html.replaceAll('<p>', '<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');
      html = html.replaceAll('<br>', '<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');
      return html;
    } else {
      return '';
    }
  }

  function fromHtmlToText(texto, lh) {
    let lineHeight = lh ?? 1.25;
    // console.log('texto', texto);
    // while(texto?.indexOf("\n") === 0){
    //   texto = texto.replace("\n", "");
    // }
    texto = texto.replaceAll("\n", "");
    // console.log('textoAntes', texto)
    texto = texto.replaceAll('<div></div>', '');
    texto = texto.replaceAll('<div> </div>', '');
    // console.log('textoDepois', texto)
    // while(texto?.indexOf("\n") === texto?.length - 3){
    //   texto = texto.substring(0, texto?.length - 3);
    // }
    if (texto?.indexOf("<div") !== 0 && texto?.indexOf("<span") !== 0 && texto?.indexOf("<p ") !== 0 && texto?.indexOf("<p>") !== 0) {
      texto = '<p>' + texto + '</p>'
    }
    let html = htmlToPdfmake(texto, {
      ignoreStyles: ['font-family', 'font-size', 'text-indent', 'line-height', 'text-align'],
      tableAutoSize: true,
      defaultStyles: {
        p: {
          // margin: [0, 5, 0, 10],
          margin: [0, 0, 0, 0],
          preserveLeadingSpaces: true,
          alignment: 'justify',
          lineHeight: lineHeight
        },
        span: {
          // margin: [0, 5, 0, 10],
          margin: [0, 0, 0, 0],
          preserveLeadingSpaces: true,
          alignment: 'justify',
          lineHeight: lineHeight
        },
        div: {
          // margin: [0, 5, 0, 10],
          margin: [0, 0, 0, 0],
          preserveLeadingSpaces: true,
          alignment: 'justify',
          lineHeight: lineHeight
        }
      }
    });
    // console.log('pdfmake', html);
    return html;

  }

  function getValorFormatado(valor) {
    if (valor != null) {
      if (Number.isNaN(Number(Utilidades.paraFloatPontoDecimal(valor)))) {
        return valor;
      }
      return Number(Utilidades.paraFloatPontoDecimal(valor)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
    return '';
  }

  function getDataAnalisePorExtenso(dataAnalise) {
    let date = parseISO(dataAnalise);
    let options = { month: "long" };
    let mes = date.toLocaleDateString("pt-BR", options);
    let dateString = "Macaé, " + date.getDate() + " de " + mes + " de " + date.getFullYear() + ".";
    if (isValid(date)) {
      return dateString;
    }
    return 'Macaé, ____ de ____________ de _____'
  }

  function getTextCampo(campo) {
    if (campo.tipo === "VALOR") {
      if (campo.campoCompValor != null && campo.campoCompValor.valor !== "") {
        return getValorFormatado(campo.campoCompValor.valor);
        // return Number(campo.campoCompValor.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
      }
      return '';
    }
    if (campo.tipo === "OBJETO") {
      if (campo.campoCompObjeto != null && campo.campoCompObjeto.objeto !== "") {
        return campo.campoCompObjeto.objeto;
      }
      return '';
    }
    if (campo.tipo === "OUTROS") {
      if (campo.campoCompOutros != null && campo.campoCompOutros.texto !== "") {
        return campo.campoCompOutros.texto;
      }
      return '';
    }
    if (campo.tipo === "PERIODO") {
      if (campo.campoCompPeriodo != null && campo.campoCompPeriodo.dataInicial != null && campo.campoCompPeriodo.dataInicial !== "" && campo.campoCompPeriodo.dataFinal != null && campo.campoCompPeriodo.dataFinal !== "") {
        return parseISO(campo.campoCompPeriodo.dataInicial).toLocaleString('pt-BR').substring(0, 10) + ' até ' + parseISO(campo.campoCompPeriodo.dataFinal).toLocaleString('pt-BR').substring(0, 10);
      }
      return '';
    }
    return '';
  }

  function getCampoCompConjuntoNomeDocTextoBody(campos) {

    let body = []
    campos && campos.forEach(conjunto => {
      let rowNome = [
        {
          text: 'Pessoa Física/Jurídica',
          bold: true,
          fillColor: '#bbbbbb',
          border: [false, false, true, true],
          fontSize: 9,
          alignment: 'center'
        },
        {
          text: conjunto.nome,
          bold: false,
          border: [false, false, true, true],
          fontSize: 10,
          alignment: 'center'
        },
      ];
      body.push(rowNome);

      let rowDocumento = [
        {
          text: 'CPF/CNPJ',
          bold: true,
          fillColor: '#bbbbbb',
          border: [false, false, true, true],
          fontSize: 9,
          alignment: 'center',
        },
        {
          text: conjunto.documento,
          bold: false,
          border: [false, false, false, true],
          fontSize: 10,
          alignment: 'center'
        }
      ]
      body.push(rowDocumento);

      let rowTexto = [
        {
          text: conjunto.texto,
          border: [true, true, true, true],
          fontSize: 10,
          alignment: 'justify',
          colSpan: 2
        },
        {}
      ];
      body.push(rowTexto)
    })
    return body;
  }

  function getCamposComplementares() {

    let campos = analise.camposComplementares;
    if (campos != null && campos?.length > 0) {
      let camposMapped =
      {
        table: {
          widths: [120, '*', '*', '*', '*'],
          body: []
        },
        layout: {
          paddingLeft: function (i, node) {
            return -1;
          },
          paddingTop: function (i, node) {
            return -1;
          },
          paddingRight: function (i, node) {
            return -1;
          },
          paddingBottom: function (i, node) {
            return -1;
          },
        }
      };
      campos?.forEach(campo => {
        // console.log('campo.campoCompAditivo.length', campo.campoCompAditivo.length)
        let processado = false;
        if (campo.tipo === "ADITIVO" && processado === false) {
          processado = true;
        }
        if (campo.tipo === "CAMPO_COMPLEMENTAR_CONFORMIDADE" && processado === false) {
          processado = true;
        }
        if (campo.tipo === "CAMPOCONJUNTO_PFPJ_CPFCPNJ_TEXTO" && processado === false) {
          camposMapped.table.body.push(
            [
              {
                // text: campo.nome,
                text: 'Resultado do Processo',
                // text: 'Resultado do Processo Licitatório',
                bold: true,
                fillColor: '#bbbbbb',
                border: [true, false, true, true],
                margin: [5, 5, 5, 5],
                fontSize: 10,
                id: 'campoCompConjuntoNomeDocTexto' + nextId++
              },
              {
                table: {
                  widths: [100, '*'],
                  body: (campo?.campoCompConjuntoNomeDocumentoTextos != null && campo?.campoCompConjuntoNomeDocumentoTextos?.length > 0) ? getCampoCompConjuntoNomeDocTextoBody(campo?.campoCompConjuntoNomeDocumentoTextos) : [{}, {}]
                },
                border: [true, false, true, true],
                colSpan: 4,
                id: 'campoCompConjuntoNomeDocTexto' + nextId++
              },
              {},
              {},
              {}
            ]
          )
          processado = true;
        }
        if (campo.tipo === "BOOLEAN" && processado === false) {
          processado = true;
        }
        if (campo.tipo === "OBJETO" && campo.infoAdicional === "OBJETO_COMPLEMENTAR" && (getTextCampo(campo) === '') && processado === false) {
          processado = true;
        }
        if (processado === false) {
          camposMapped.table.body.push([
            {
              text: campo.nome,
              bold: true,
              fillColor: '#bbbbbb',
              border: [true, false, true, true],
              margin: [5, 5, 5, 5],
              alignment: 'left'
            },
            {
              text: getTextCampo(campo),
              border: [true, false, true, true],
              margin: [5, 5, 5, 5],
              colSpan: 4
            },
            {},
            {},
            {}
          ]);
          processado = true;
        }
      })

      if (camposMapped?.table?.body != null && camposMapped?.table?.body?.length > 0) {
        // console.log('camposMapped', camposMapped)
        return camposMapped;
      }
    }

    return '';
  }

  function getCamposCompAditivos() {
    let campos = analise.camposComplementares;
    if (campos != null && campos?.length > 0) {
      let camposAditivos = [];

      campos?.forEach(campo => {
        if (campo.tipo === "ADITIVO") {
          camposAditivos.push(campo);
        }
      })
      if (camposAditivos != null && camposAditivos.length > 0) {
        let camposAditivosMapped =
        {
          table: {
            widths: ['100%'],
            body: []
          },
          layout: {
            paddingLeft: function (i, node) {
              return -1;
            },
            paddingTop: function (i, node) {
              return -1;
            },
            paddingRight: function (i, node) {
              return -1;
            },
            paddingBottom: function (i, node) {
              return -1;
            },
          }
        };

        // camposAditivosMapped.table.body.push([
        //   {
        //     text: 'Informações Contratuais',
        //     bold: true,
        //     fillColor: '#bbbbbb',
        //     border: [true, false, true, true],
        //     margin: [5, 5, 5, 5],
        //     // fontSize: 10,
        //     id: 'campoCompAditivo' + nextId++,
        //     alignment: 'center',
        //   },
        // ]);

        camposAditivos?.forEach(campoAditivo => {
          if (campoAditivo?.campoCompAditivo != null && campoAditivo?.campoCompAditivo?.length > 0) {

            camposAditivosMapped.table.body.push([
              {
                text: 'Informações Contratuais',
                bold: true,
                fillColor: '#bbbbbb',
                border: [true, false, true, true],
                margin: [5, 5, 5, 5],
                // fontSize: 10,
                id: 'campoCompAditivo' + nextId++,
                alignment: 'center',
              },
            ]);
            
            campoAditivo?.campoCompAditivo?.sort(Utilidades.sortPorOrdem);

            campoAditivo?.campoCompAditivo?.forEach(aditivo => {
              let processado = false;
              if (aditivo?.tipoAditivo === "CONTRATO_ORIGINAL" && processado === false) {
                let grupo = {
                  stack: [],
                  unbreakable: true
                }

                let linhas = {
                  table: {
                    widths: ['18%', '18%', '18%', '23%', '23%'],
                    body: []
                  },
                  border: [true, true, true, true],
                  // id: 'campoCompAditivo' + nextId++
                };


                linhas.table.body.push([
                  {
                    text: TipoAditivo.getNome(aditivo.tipoAditivo),
                    bold: true,
                    fillColor: '#bbbbbb',
                    border: [true, true, true, true],
                    margin: [1, 1, 1, 1],
                    alignment: 'center',
                    fontSize: 10,
                    colSpan: 5,
                  },
                  {},
                  {},
                  {},
                  {},
                ])

                linhas.table.body.push([
                  {
                    text: 'Número',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: 'Data de Início',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: 'Data de Término',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: 'Valor Mensal',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, false, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: 'Valor Total',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, false, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  }
                ])

                linhas.table.body.push([
                  {
                    text: aditivo?.numeroContrato,
                    border: [true, true, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: parseISO(aditivo?.dataInicial).toLocaleDateString('pt-BR'),
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: parseISO(aditivo?.dataFinal).toLocaleDateString('pt-BR'),
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: getValorFormatado(aditivo.valorMensal),
                    border: [true, true, true, true],
                    fontSize: (aditivo.valorMensal?.length > 10) ? 9 : 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: getValorFormatado(aditivo.valor),
                    border: [true, true, true, true],
                    fontSize: (aditivo.valor?.length > 10) ? 9 : 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  }
                ])

                grupo.stack.push(linhas);
                camposAditivosMapped.table.body.push([grupo]);
                processado = true;
              }
              if (aditivo?.tipoAditivo === "ATA" && processado === false) {
                let grupo = {
                  stack: [],
                  unbreakable: true
                }

                let linhas = {
                  table: {
                    widths: ['17.6%', '17.6%', '17.6%', '47.2%'],
                    body: []
                  },
                  border: [true, true, true, true],
                  // id: 'campoCompAditivo' + nextId++
                };


                linhas.table.body.push([
                  {
                    text: TipoAditivo.getNome(aditivo.tipoAditivo),
                    bold: true,
                    fillColor: '#bbbbbb',
                    border: [true, true, true, true],
                    margin: [1, 1, 1, 1],
                    alignment: 'center',
                    fontSize: 10,
                    colSpan: 4,
                  },
                  {},
                  {},
                  {},
                ])

                linhas.table.body.push([
                  {
                    text: 'Número',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: 'Data de Início',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: 'Data de Término',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: 'Valor Total',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, false, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  }
                ])

                linhas.table.body.push([
                  {
                    text: aditivo?.numeroAta,
                    border: [true, true, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: parseISO(aditivo?.dataInicial).toLocaleDateString('pt-BR'),
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: parseISO(aditivo?.dataFinal).toLocaleDateString('pt-BR'),
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: getValorFormatado(aditivo.valor),
                    border: [true, true, true, true],
                    fontSize: (aditivo.valor?.length > 12) ? 9 : 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  }
                ])

                grupo.stack.push(linhas);
                camposAditivosMapped.table.body.push([grupo]);
                processado = true;
              }
              if (aditivo?.tipoAditivo === "TERMO_ADITIVO" && processado === false) {
                let grupo = {
                  stack: [],
                  unbreakable: true
                }

                let linhas = {
                  table: {
                    widths: ['23.5%', '23.5%', '26.5%', '26.5%'],
                    body: []
                  },
                  border: [true, true, true, true],
                  // id: 'campoCompAditivo' + nextId++
                };

                linhas.table.body.push([
                  {
                    text: (aditivo?.numAditivo != null && aditivo?.numAditivo > 0) ? aditivo.numAditivo + 'º ' + TipoAditivo.getNome(aditivo.tipoAditivo) : TipoAditivo.getNome(aditivo.tipoAditivo),
                    bold: true,
                    fillColor: '#bbbbbb',
                    border: [true, true, true, true],
                    margin: [1, 1, 1, 1],
                    alignment: 'center',
                    fontSize: 10,
                    colSpan: 4,
                  },
                  {},
                  {},
                  {},
                ])

                linhas.table.body.push([
                  {
                    text: 'Data de Início',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: 'Data de Término',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: 'Valor Mensal',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, false, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: 'Valor Total',
                    bold: true,
                    fillColor: '#dddddd',
                    border: [true, true, false, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  }
                ])

                linhas.table.body.push([
                  {
                    text: parseISO(aditivo?.dataInicial).toLocaleDateString('pt-BR'),
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: parseISO(aditivo?.dataFinal).toLocaleDateString('pt-BR'),
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: getValorFormatado(aditivo.valorMensal),
                    border: [true, true, true, true],
                    fontSize: (aditivo.valorMensal?.length > 10) ? 9 : 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: getValorFormatado(aditivo.valor),
                    border: [true, true, false, true],
                    fontSize: (aditivo.valor?.length > 10) ? 9 : 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  }
                ])

                grupo.stack.push(linhas);

                linhas = {
                  table: {
                    widths: ['24%', '14%', '24%', '14%', '24%'],
                    body: []
                  },
                  border: [true, true, true, true],
                  // id: 'campoCompAditivo' + nextId++
                };

                linhas.table.body.push([
                  {
                    text: 'Valor Acréscimo',
                    bold: true,
                    fillColor: '#dddddd',
                    border: [true, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: '% Acréscimo',
                    bold: true,
                    fillColor: '#dddddd',
                    border: [true, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: 'Valor Supressão',
                    bold: true,
                    fillColor: '#dddddd',
                    border: [true, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: '% Supressão',
                    bold: true,
                    fillColor: '#dddddd',
                    border: [true, false, false, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: 'Reflexo Financeiro',
                    bold: true,
                    fillColor: '#dddddd',
                    border: [true, false, false, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  }
                ])

                linhas.table.body.push([
                  {
                    text: getValorFormatado(aditivo.valorAcrescimo),
                    border: [true, true, true, true],
                    fontSize: (aditivo.valorAcrescimo?.length > 10) ? 9 : 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: aditivo.percentualAcrescimo != null ? aditivo.percentualAcrescimo + '%' : '',
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: getValorFormatado(aditivo.valorSupressao),
                    border: [true, true, true, true],
                    fontSize: (aditivo.valorSupressao?.length > 10) ? 9 : 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: aditivo.percentualSupressao != null ? aditivo.percentualSupressao + '%' : '',
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: aditivo.percentualReflexoFinanceiro != null ? aditivo.percentualReflexoFinanceiro + '%' : '',
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  }
                ])

                grupo.stack.push(linhas);
                camposAditivosMapped.table.body.push([grupo]);
                processado = true;
              }
              if (aditivo?.tipoAditivo === "TERMO_APOSTILAMENTO" && processado === false) {
                let grupo = {
                  stack: [],
                  unbreakable: true
                }

                let linhas = {
                  table: {
                    widths: ['20%', '20%', '22%', '16%', '22%'],
                    body: []
                  },
                  border: [true, true, true, true],
                  // id: 'campoCompAditivo' + nextId++
                };

                linhas.table.body.push([
                  {
                    text: (aditivo?.numAditivo != null && aditivo?.numAditivo > 0) ? aditivo.numAditivo + 'º ' + TipoAditivo.getNome(aditivo.tipoAditivo) : TipoAditivo.getNome(aditivo.tipoAditivo),
                    bold: true,
                    fillColor: '#bbbbbb',
                    border: [true, true, true, true],
                    margin: [1, 1, 1, 1],
                    alignment: 'center',
                    fontSize: 10,
                    colSpan: 5,
                  },
                  {},
                  {},
                  {},
                  {},
                ])

                linhas.table.body.push([
                  {
                    text: 'Periodicidade Início',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [true, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: 'Periodicidade Fim',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [true, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: 'Valor Total',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [true, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: '% Apostilamento',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [true, false, false, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: 'Valor Atualizado do Contrato',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [true, false, false, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  }
                ])

                linhas.table.body.push([
                  {
                    text: aditivo?.periodicidadeInicioMes + '/' + aditivo?.periodicidadeInicioAno,
                    border: [true, true, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: aditivo?.periodicidadeFimMes + '/' + aditivo?.periodicidadeFimAno,
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: getValorFormatado(aditivo.valor),
                    border: [true, true, true, true],
                    fontSize: (aditivo.valor?.length > 10) ? 9 : 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: aditivo.percentualApostilamento != null ? aditivo.percentualApostilamento + '%' : '',
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  },
                  {
                    text: getValorFormatado(aditivo.valorAtualizadoContrato),
                    border: [true, true, true, true],
                    fontSize: (aditivo.valorAtualizadoContrato?.length > 10) ? 9 : 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                  }
                ])

                grupo.stack.push(linhas);
                camposAditivosMapped.table.body.push([grupo]);
                processado = true;
              }

            })
          }
        })


        if (camposAditivosMapped?.table?.body != null && camposAditivosMapped?.table?.body?.length > 0) {
          // console.log('camposAditivosMapped', camposAditivosMapped)
          return camposAditivosMapped;
        }
      }
    }
    return '';
  }

  function getCamposCompConformidade() {
    let campos = analise.camposComplementares;
    if (campos != null && campos?.length > 0) {
      let camposConformidade = [];

      campos?.forEach(campo => {
        if (campo.tipo === "CAMPO_COMPLEMENTAR_CONFORMIDADE") {
          camposConformidade.push(campo);
        }
      })
      if (camposConformidade != null && camposConformidade.length > 0) {
        let camposConformidadeMapped =
        {
          table: {
            widths: ['100%'],
            body: []
          },
          layout: {
            paddingLeft: function (i, node) {
              return -1;
            },
            paddingTop: function (i, node) {
              return -1;
            },
            paddingRight: function (i, node) {
              return -1;
            },
            paddingBottom: function (i, node) {
              return -1;
            },
          }
        };

        camposConformidade?.forEach(campoConformidade => {
          if (campoConformidade?.campoCompConformidade != null && campoConformidade?.campoCompConformidade?.length > 0) {

            camposConformidadeMapped.table.body.push([
              {
                // text: campoConformidade?.nome != null && campoConformidade?.nome !== "" ? campoConformidade?.nome : 'Informações Complementares',
                text: 'Informações Complementares',
                bold: true,
                fillColor: '#bbbbbb',
                border: [true, false, true, true],
                margin: [5, 5, 5, 5],
                // fontSize: 10,
                id: 'campoCompConformidade' + nextId++,
                alignment: 'center',
              },
            ]);
            
            campoConformidade?.campoCompConformidade?.sort(Utilidades.sortPorOrdem);

            campoConformidade?.campoCompConformidade?.forEach(cc => {
              let processado = false;
              if (cc?.tipoComplementarConformidade === "CONTRATO" && processado === false) {
                let grupo = {
                  stack: [],
                  unbreakable: true
                }

                let linhas = {
                  table: {
                    widths: ['16.6%', '16.7%', '16.6%', '16.7%', '16.6%', '16.8%'],
                    body: []
                  },
                  border: [true, true, true, true],
                  // id: 'campoCompConformidade' + nextId++
                };


                linhas.table.body.push([
                  {
                    text: [TipoCampoConformidade.getNome(cc.tipoComplementarConformidade), (cc?.numeroContrato != null && cc?.numeroContrato !== '' ? ' - ' + cc?.numeroContrato : '')],
                    bold: true,
                    fillColor: '#bbbbbb',
                    border: [true, true, true, true],
                    margin: [1, 1, 1, 1],
                    alignment: 'center',
                    fontSize: 10,
                    colSpan: 6,
                  },
                  {},
                  {},
                  {},
                  {},
                  {},
                ])

                if(cc?.objeto != null && cc?.objeto !== ""){
                  linhas.table.body.push([
                    {
                        text: cc?.objeto,
                        bold: false,
                        // fillColor: '#bbbbbb',
                        border: [true, true, true, true],
                        margin: [1, 1, 1, 1],
                        alignment: 'justify',
                        fontSize: 10,
                        colSpan: 6,
                    },
                    {},
                    {},
                    {},
                    {},
                    {},
                  ])
                }

                linhas.table.body.push([
                  {
                    text: 'Nº Contrato',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 2,
                  },
                  {},
                  {
                    text: 'Nº Nota Empenho',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 2,
                  },
                  {},
                  {
                    text: 'Valor',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, false, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 2,
                  },
                  {},
                ])

                linhas.table.body.push([
                  {
                    text: (cc?.numeroContrato != null && cc?.numeroContrato !== "") ? cc?.numeroContrato : ' ',
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 2,
                  },
                  {},
                  {
                    text: (cc?.numeroNotaEmpenho != null && cc?.numeroNotaEmpenho !== "") ? cc?.numeroNotaEmpenho : ' ',
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 2,
                  },
                  {},
                  {
                    text: (cc?.valor != null && cc?.valor !== "") ? getValorFormatado(cc?.valor) : ' ',
                    border: [true, true, true, true],
                    fontSize: (cc.valor?.length > 10) ? 9 : 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 2,
                  },
                  {},
                ])

                linhas.table.body.push([
                  {
                    text: 'Pessoa Física / Pessoa Jurídica',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 4,
                  },
                  {},
                  {},
                  {},
                  {
                    text: 'CPF / CNPJ',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 2,
                  },
                  {},
                ])

                linhas.table.body.push([
                  {
                    text: (cc?.razaoSocial != null && cc?.razaoSocial !== "") ? cc?.razaoSocial : ' ',
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 4,
                  },
                  {},
                  {},
                  {},
                  {
                    text: (cc?.cnpj != null && cc?.cnpj !== "") ? Utilidades.adicionarMascaraCPFOuCNPJ(cc?.cnpj) : ' ',
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 2,
                  },
                  {},
                ])

                linhas.table.body.push([
                  {
                    text: 'Período de Vigência',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 6,
                  },
                  {},
                  {},
                  {},
                  {},
                  {},
                ])

                linhas.table.body.push([
                  {
                    text: (cc?.periodoVigencia != null && cc?.periodoVigencia !== "") ? cc?.periodoVigencia : ' ',
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 6,
                  },
                  {},
                  {},
                  {},
                  {},
                  {},
                ])

                grupo.stack.push(linhas);
                camposConformidadeMapped.table.body.push([grupo]);
                processado = true;
              }

              if (cc?.tipoComplementarConformidade === "ARP" && processado === false) {
                let grupo = {
                  stack: [],
                  unbreakable: true
                }

                let linhas = {
                  table: {
                    widths: ['16.6%', '16.7%', '16.6%', '16.7%', '16.6%', '16.8%'],
                    body: []
                  },
                  border: [true, true, true, true],
                  // id: 'campoCompConformidade' + nextId++
                };


                linhas.table.body.push([
                  {
                    text: [TipoCampoConformidade.getNome(cc.tipoComplementarConformidade), (cc.numeroAta != null && cc.numeroAta ? ' - ' + cc.numeroAta : '')],
                    bold: true,
                    fillColor: '#bbbbbb',
                    border: [true, true, true, true],
                    margin: [1, 1, 1, 1],
                    alignment: 'center',
                    fontSize: 10,
                    colSpan: 6,
                  },
                  {},
                  {},
                  {},
                  {},
                  {},
                ])

                if(cc?.objeto != null && cc?.objeto !== ""){
                  linhas.table.body.push([
                    {
                        text: cc?.objeto,
                        bold: false,
                        // fillColor: '#bbbbbb',
                        border: [true, true, true, true],
                        margin: [1, 1, 1, 1],
                        alignment: 'justify',
                        fontSize: 10,
                        colSpan: 6,
                    },
                    {},
                    {},
                    {},
                    {},
                    {},
                  ])
                }

                linhas.table.body.push([
                  {
                    text: 'Nº Ata de Registro de Preços',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 3,
                  },
                  {},
                  {},
                  {
                    text: 'Valor',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, false, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 3,
                  },
                  {},
                  {},
                ])

                linhas.table.body.push([
                  {
                    text: (cc?.numeroAta != null && cc?.numeroAta !== '') ? cc?.numeroAta : ' ',
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 3,
                  },
                  {},
                  {},
                  {
                    text: (cc?.valor != null && cc?.valor !== '') ?  getValorFormatado(cc?.valor) : ' ',
                    border: [true, true, true, true],
                    fontSize: (cc?.valor?.length > 10) ? 9 : 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 3,
                  },
                  {},
                  {},
                ])

                linhas.table.body.push([
                  {
                    text: 'Pessoa Física / Pessoa Jurídica',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 4,
                  },
                  {},
                  {},
                  {},
                  {
                    text: 'CPF / CNPJ',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 2,
                  },
                  {},
                ])

                linhas.table.body.push([
                  {
                    text: (cc?.razaoSocial != null && cc?.razaoSocial !== '') ? cc?.razaoSocial : ' ',
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 4,
                  },
                  {},
                  {},
                  {},
                  {
                    text: (cc?.cnpj != null && cc?.cnpj !== "") ? Utilidades.adicionarMascaraCPFOuCNPJ(cc?.cnpj) : ' ',
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 2,
                  },
                  {},
                ])

                linhas.table.body.push([
                  {
                    text: 'Período de Vigência',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 6,
                  },
                  {},
                  {},
                  {},
                  {},
                  {},
                ])

                linhas.table.body.push([
                  {
                    text: (cc?.periodoVigencia != null && cc?.periodoVigencia !== '') ? cc?.periodoVigencia : ' ',
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 6,
                  },
                  {},
                  {},
                  {},
                  {},
                  {},
                ])

                grupo.stack.push(linhas);
                camposConformidadeMapped.table.body.push([grupo]);
                processado = true;
              }

              if (cc?.tipoComplementarConformidade === "NOTA_DE_EMPENHO" && processado === false) {
                let grupo = {
                  stack: [],
                  unbreakable: true
                }

                let linhas = {
                  table: {
                    widths: ['16.6%', '16.7%', '16.6%', '16.7%', '16.6%', '16.8%'],
                    body: []
                  },
                  border: [true, true, true, true],
                  // id: 'campoCompConformidade' + nextId++
                };


                linhas.table.body.push([
                  {
                    text: [TipoCampoConformidade.getNome(cc.tipoComplementarConformidade), (cc.numeroNotaEmpenho != null && cc.numeroNotaEmpenho ? ' - ' + cc.numeroNotaEmpenho : '')],
                    bold: true,
                    fillColor: '#bbbbbb',
                    border: [true, true, true, true],
                    margin: [1, 1, 1, 1],
                    alignment: 'center',
                    fontSize: 10,
                    colSpan: 6,
                  },
                  {},
                  {},
                  {},
                  {},
                  {},
                ])

                linhas.table.body.push([
                  {
                    text: 'Nº Nota de Empenho',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 3,
                  },
                  {},
                  {},
                  {
                    text: 'Valor',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, false, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 3,
                  },
                  {},
                  {},
                ])

                linhas.table.body.push([
                  {
                    text: (cc?.numeroNotaEmpenho != null && cc?.numeroNotaEmpenho !== "") ? cc?.numeroNotaEmpenho : ' ',
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 3,
                  },
                  {},
                  {},
                  {
                    text: (cc?.valor != null && cc?.valor !== "") ? getValorFormatado(cc?.valor) : ' ',
                    border: [true, true, true, true],
                    fontSize: (cc.valor?.length > 10) ? 9 : 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 3,
                  },
                  {},
                  {},
                ])

                linhas.table.body.push([
                  {
                    text: 'Pessoa Física / Pessoa Jurídica',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 4,
                  },
                  {},
                  {},
                  {},
                  {
                    text: 'CPF / CNPJ',
                    bold: true,
                    fillColor: '#dddddd',
                    // border: [false, false, true, true],
                    fontSize: 9,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 2,
                  },
                  {},
                ])

                linhas.table.body.push([
                  {
                    text: (cc?.razaoSocial != null && cc?.razaoSocial !== "") ? cc?.razaoSocial : ' ',
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 4,
                  },
                  {},
                  {},
                  {},
                  {
                    text: (cc?.cnpj != null && cc?.cnpj !== "") ? Utilidades.adicionarMascaraCPFOuCNPJ(cc?.cnpj) : ' ',
                    border: [true, true, true, true],
                    fontSize: 10,
                    alignment: 'center',
                    // margin: [0, 0, 0, 0],
                    colSpan: 2,
                  },
                  {},
                ])

                grupo.stack.push(linhas);
                camposConformidadeMapped.table.body.push([grupo]);
                processado = true;
              }

            })
          }
        })


        if (camposConformidadeMapped?.table?.body != null && camposConformidadeMapped?.table?.body?.length > 0) {
          // console.log('camposConformidadeMapped', camposConformidadeMapped)
          return camposConformidadeMapped;
        }
      }
    }
    return '';
  }
  
  
  function getBlocos() {
    let blocos = analise.blocos;
    if (blocos != null && blocos.length > 0) {
      let blocosMapped = []

      blocos?.forEach(bloco => {
        blocosMapped.push(getBlocoMapped(bloco));
      });
      return blocosMapped;
    }
    return '';
  }

  function getBlocoMapped(bloco) {

    if (isConformidade) {
      let blocoMapped = {
        table: {
          widths: [35, '*', 35, 50],
          body: []
        },
        margin: [0, 20, 0, 20]
      };

      blocoMapped.table.body.push([
        {
          text: bloco.titulo,
          alignment: 'center',
          fillColor: '#bbbbbb',
          colSpan: 4,
          bold: true,
          border: [true, true, true, true],
        },
        {},
        {},
        {}
      ]);

      if (bloco.possuiCampoDeTextoInicial) {
        blocoMapped.table.body.push([
          (bloco.campoDeTextoInicialHTML != null && bloco.campoDeTextoInicialHTML !== "") ? {
            stack: fromHtmlToText(bloco.campoDeTextoInicialHTML),
            colSpan: 4,
            border: [true, true, true, true],
          } :
          {
            text: bloco.campoDeTextoInicial,
            alignment: 'justify',
            colSpan: 4,
            bold: false,
            border: [true, true, true, true],
          },
          {},
          {},
          {}
        ]);
      }

      if (bloco.filhos != null && bloco.filhos.length > 0) {
        blocoMapped.table.body.push([
          {
            text: 'ID',
            alignment: 'center',
            bold: true,
            border: [true, true, true, true],
          },
          {
            text: 'Itens de Verificação',
            alignment: 'center',
            bold: true,
            border: [true, true, true, true],
          },
          {
            text: 'Consta',
            alignment: 'center',
            bold: true,
            border: [true, true, true, true],
          },
          {
            text: 'Folhas',
            alignment: 'center',
            bold: true,
            border: [true, true, true, true],
          },
        ]);
      }

      if (bloco.filhos != null && bloco.filhos.length > 0) {
        bloco.filhos.forEach(filho => {
          blocoMapped.table.body.push([
            {
              text: filho.idCampo,
              alignment: 'center',
              bold: true,
              border: [true, true, true, true],
            },
            {
              text: filho.texto,
              alignment: 'justify',
              bold: false,
              border: [true, true, true, true],
            },
            {
              text: (filho.consta) ? "SIM" : "NÃO",
              alignment: 'center',
              bold: false,
              border: [true, true, true, true],
            },
            {
              text: filho.valor,
              alignment: 'center',
              bold: false,
              border: [true, true, true, true],
            },
          ]);

          if (filho.netos != null && filho.netos.length > 0) {
            filho.netos.forEach(neto => {
              blocoMapped.table.body.push([
                {
                  text: neto.idCampo,
                  alignment: 'center',
                  bold: true,
                  border: [true, true, true, true],
                },
                {
                  text: neto.texto,
                  alignment: 'justify',
                  bold: false,
                  border: [true, true, true, true],
                },
                {
                  text: (neto.consta) ? "SIM" : "NÃO",
                  alignment: 'center',
                  bold: false,
                  border: [true, true, true, true],
                },
                {
                  text: neto.valor,
                  alignment: 'center',
                  bold: false,
                  border: [true, true, true, true],
                },
              ]);
            })
          }


        });
      }

      if (bloco.recomendacoes != null && bloco.recomendacoes.length > 0) {
        blocoMapped.table.body.push([
          {
            text: (isConformidade) ? 'Informações Adicionais e Recomendações' : 'Recomendações',
            alignment: 'center',
            colSpan: 4,
            bold: true,
            fillColor: '#bbbbbb',
            border: [true, true, true, true],
          },
          {},
          {},
          {}
        ]);

        bloco.recomendacoes.forEach(recomendacao => {
          blocoMapped.table.body.push([
            {
              text: recomendacao.idCampo,
              alignment: 'center',
              bold: true,
              border: [true, true, true, true],
              fontSize: (recomendacao.idCampo === "OUTROS") ? 9 : 11,
            },
            (recomendacao.html != null && recomendacao.html !== "") ? {
              stack: fromHtmlToText(recomendacao.html),
              colSpan: 3,
              border: [true, true, true, true],
              // unbreakable: true,
            } : {
              text: recomendacao.texto,
              alignment: 'justify',
              colSpan: 3,
              bold: true,
              border: [true, true, true, true],
            },
            // {
            //   text: recomendacao.texto,
            //   alignment: 'justify',
            //   colSpan: 3,
            //   bold: true,
            //   border: [true, true, true, true],
            // },
            {},
            {}
          ]);
        })

      }

      if (bloco.possuiCampoDeTextoComplementar) {
        blocoMapped.table.body.push([
          {
            text: bloco.campoDeTextoComplementar,
            alignment: 'justify',
            colSpan: 4,
            bold: false,
            border: [true, true, true, true],
          },
          {},
          {},
          {}
        ]);
      }

      if (bloco.possuiCampoDeTextoFinal) {
        blocoMapped.table.body.push([
          (bloco.campoDeTextoFinalHTML != null && bloco.campoDeTextoFinalHTML !== "") ? {
            stack: fromHtmlToText(bloco.campoDeTextoFinalHTML),
            colSpan: 4,
            border: [true, true, true, true],
          } :
          {
            text: bloco.campoDeTextoFinal,
            alignment: 'justify',
            colSpan: 4,
            bold: false,
            border: [true, true, true, true],
          },
          {},
          {},
          {}
        ]);
      }
      return blocoMapped;

    } else {
      let blocoMapped = {
        table: {
          widths: [35, '*', 55],
          body: []
        },
        margin: [0, 20, 0, 20]
      };

      blocoMapped.table.body.push([
        {
          text: bloco.titulo,
          alignment: 'center',
          fillColor: '#bbbbbb',
          colSpan: 3,
          bold: true,
          border: [true, true, true, true],
        },
        {},
        {}
      ]);

      if (bloco.possuiCampoDeTextoInicial) {
        blocoMapped.table.body.push([
          (bloco.campoDeTextoInicialHTML != null && bloco.campoDeTextoInicialHTML !== "") ? {
            stack: fromHtmlToText(bloco.campoDeTextoInicialHTML),
            colSpan: 3,
            border: [true, true, true, true],
          } :
          {
            text: bloco.campoDeTextoInicial,
            alignment: 'justify',
            colSpan: 3,
            bold: false,
            border: [true, true, true, true],
          },
          {},
          {}
        ]);
      }

      if (bloco.filhos != null && bloco.filhos.length > 0) {
        blocoMapped.table.body.push([
          {
            text: 'ID',
            alignment: 'center',
            bold: true,
            border: [true, true, true, true],
          },
          {
            text: 'Itens de Verificação',
            alignment: 'center',
            bold: true,
            border: [true, true, true, true],
          },
          {
            text: 'Folhas',
            alignment: 'center',
            bold: true,
            border: [true, true, true, true],
          },
        ]);
      }

      if (bloco.filhos != null && bloco.filhos.length > 0) {
        bloco.filhos.forEach(filho => {
          blocoMapped.table.body.push([
            {
              text: filho.idCampo,
              alignment: 'center',
              bold: true,
              border: [true, true, true, true],
            },
            {
              text: filho.texto,
              alignment: 'justify',
              bold: false,
              border: [true, true, true, true],
            },
            {
              text: filho.valor,
              alignment: 'center',
              bold: false,
              border: [true, true, true, true],
            },
          ]);

          if (filho.netos != null && filho.netos.length > 0) {
            filho.netos.forEach(neto => {
              blocoMapped.table.body.push([
                {
                  text: neto.idCampo,
                  alignment: 'center',
                  bold: true,
                  border: [true, true, true, true],
                },
                {
                  text: neto.texto,
                  alignment: 'justify',
                  bold: false,
                  border: [true, true, true, true],
                },
                {
                  text: neto.valor,
                  alignment: 'center',
                  bold: false,
                  border: [true, true, true, true],
                },
              ]);
            })
          }


        });
      }

      if (bloco.recomendacoes != null && bloco.recomendacoes.length > 0) {
        blocoMapped.table.body.push([
          {
            text: (isConformidade) ? 'Informações Adicionais e Recomendações' : 'Recomendações',
            alignment: 'center',
            colSpan: 3,
            bold: true,
            fillColor: '#bbbbbb',
            border: [true, true, true, true],
          },
          {},
          {}
        ]);

        if (isManifestacao) {
          bloco.recomendacoes.forEach(recomendacao => {
            blocoMapped.table.body.push([
              // {
              //   text: recomendacao.texto,
              //   alignment: 'justify',
              //   colSpan: 3,
              //   bold: true,
              //   border: [true, true, true, true],
              // },
              (recomendacao.html != null && recomendacao.html !== "") ? {
                stack: fromHtmlToText(recomendacao.html),
                colSpan: 3,
                border: [true, true, true, true],
                // unbreakable: true,
              } : {
                text: recomendacao.texto,
                alignment: 'justify',
                colSpan: 3,
                bold: true,
                border: [true, true, true, true],
              },
              {},
              {}
            ]);
          })
        } else {
          bloco.recomendacoes.forEach(recomendacao => {
            blocoMapped.table.body.push([
              {
                text: recomendacao.idCampo,
                alignment: 'center',
                bold: true,
                border: [true, true, true, true],
                fontSize: (recomendacao.idCampo === "OUTROS") ? 9 : 11,
              },
              (recomendacao.html != null && recomendacao.html !== "") ? {
                stack: fromHtmlToText(recomendacao.html),
                colSpan: 2,
                border: [true, true, true, true],
                // unbreakable: true,
              } : {
                text: recomendacao.texto,
                alignment: 'justify',
                colSpan: 2,
                bold: true,
                border: [true, true, true, true],
              },
              // {
              //   text: recomendacao.texto,
              //   alignment: 'justify',
              //   colSpan: 2,
              //   bold: true,
              //   border: [true, true, true, true],
              // },
              {}
            ]);
          })
        }

      }

      if (bloco.possuiCampoDeTextoComplementar) {
        blocoMapped.table.body.push([
          {
            text: bloco.campoDeTextoComplementar,
            alignment: 'justify',
            colSpan: 3,
            bold: false,
            border: [true, true, true, true],
          },
          {},
          {}
        ]);
      }

      if (bloco.possuiCampoDeTextoFinal) {
        blocoMapped.table.body.push([
          (bloco.campoDeTextoFinalHTML != null && bloco.campoDeTextoFinalHTML !== "") ? {
            stack: fromHtmlToText(bloco.campoDeTextoFinalHTML),
            colSpan: 3,
            border: [true, true, true, true],
          } :
          {
            text: bloco.campoDeTextoFinal,
            alignment: 'justify',
            colSpan: 3,
            bold: false,
            border: [true, true, true, true],
          },
          {},
          {}
        ]);
      }

      return blocoMapped;
    }

  }

  function cabecalho() {
    return [
      {
        columns: [
          {
            image: unidade.fotoUnidade,
            width: 150,
            alignment: 'left',
            // margin: [0, 0, 0, 0] // left, top, right, bottom
          },
          {
            width: '*',
            text: ''
          },
          {
            image: unidade.carimboPaginacao,
            width: 60,
            alignment: 'right',
            // margin: [0, 0, 0, 0] // left, top, right, bottom
          }
        ],
        margin: [marginInCm(3), 15, marginInCm(2.8), 0] // left, top, right, bottom
      },
      {
        table: {
          widths: ['*'],
          body: [
            [
              {
                border: [false, false, false, true],
                text: ''
              }
            ]
          ]
        },
        layout: {
          vLineColor: function (i, node) {
            return '#0098da';
          },
          hLineColor: function (i, node) {
            return '#0098da';
          }
        },
        margin: [marginInCm(3), 0, marginInCm(2.8), 0] // left, top, right, bottom
      }
    ];
  }

  function getDadosBasicos() {
    let dados = [
      '\n',
      {
        text: analise.nome,
        alignment: 'center',
        style: 'header'
      },
      '\n\n',
      {
        table: {
          widths: [110, '*'],
          body: [
            [
              {
                text: 'Nº do Processo',
                bold: true,
                fillColor: '#bbbbbb',
                border: [true, true, true, true],
              },
              {
                text: processo.numero,
                border: [true, true, true, true],
              }
            ],
            [
              {
                text: 'Volumes e Apensos',
                bold: true,
                fillColor: '#bbbbbb',
                border: [true, true, true, true],
              },
              {
                text: processo.volumeseApensos,
                border: [true, true, true, true],
              }
            ],
            [
              {
                text: 'Órgão Solicitante',
                bold: true,
                fillColor: '#bbbbbb',
                border: [true, true, true, true],
              },
              {
                text: orgaoNome,
                border: [true, true, true, true],
              }
            ],
            [
              {
                text: 'Unidade Responsável',
                bold: true,
                fillColor: '#bbbbbb',
                border: [true, true, true, true],
              },
              {
                text: unidade.nome,
                border: [true, true, true, true],
              }
            ],
            [
              {
                text: 'Procedimento',
                // text: 'Modalidade de Licitação',
                bold: true,
                fillColor: '#bbbbbb',
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: modalidadeNome,
                border: [true, true, true, true],
              }
            ],
          ]
        }
      },
    ]

    // if(!existsCampo("OBJETO", "OBJETO_COMPLEMENTAR")){
    dados[3].table.body.push([
      {
        text: 'Objeto do Processo',
        bold: true,
        fillColor: '#bbbbbb',
        border: [true, true, true, true],
      },
      {
        text: processo.objeto,
        border: [true, true, true, true],
      }
    ]);
    // }

    dados[3].table.body.push([
      {
        // text: 'Valor Estimado',
        text: 'Valor',
        bold: true,
        fillColor: '#bbbbbb',
        border: [true, true, true, true],
      },
      {
        text: getValorFormatado(analise.valorEstimado),
        border: [true, true, true, true],
      }
    ]);

    return dados;
  }

  const dadosBasicos = getDadosBasicos();

  const camposComplementares = getCamposComplementares();

  const camposCompAditivos = getCamposCompAditivos();

  const camposCompConformidade = getCamposCompConformidade();

  const textosIniciais = [
    '\n\n',
    // '\n',
    // {
    //   text: getTextoFormatado(analise.textoInicial),
    //   alignment: 'justify',
    //   preserveLeadingSpaces: true,
    //   lineHeight: 1.25
    // },
    (analise.textoInicialHTML != null && analise.textoInicialHTML !== "") ? fromHtmlToText(inserirTabulação(analise.textoInicialHTML)) : {
      text: getTextoFormatado(analise.textoInicial),
      alignment: 'justify',
      preserveLeadingSpaces: true,
      lineHeight: 1.25
    },
    '\n',
    // {
    //   text: getTextoFormatado(analise.textoInicialExtra),
    //   alignment: 'justify',
    //   bold: true,
    //   decoration: 'underline',
    //   preserveLeadingSpaces: true
    // },
    // {
    //   text: (analise.textoInicialExtraHTML != null && analise.textoInicialExtraHTML !== "") ? getTextoSublinhadoNegritado(analise.textoInicialExtraHTML) : "",
    //   // alignment: 'justify',
    //   // preserveLeadingSpaces: true,
    //   lineHeight: 1.25
    // },
    // '\n',
    (analise.textoInicialExtraHTML != null && analise.textoInicialExtraHTML !== "") ? fromHtmlToText(inserirTabulação(analise.textoInicialExtraHTML)) : {
      text: getTextoSublinhadoNegritado(analise.textoInicialExtra),
      alignment: 'justify',
      preserveLeadingSpaces: true,
      lineHeight: 1.25
    },
  ];

  const blocos = getBlocos();


  const textosFinais = [
    // '\n\n',
    '\n',
    {
      table: {
        widths: ['*'],
        headerRows: 1,
        body: [
          [{
            text: (isConformidade) ? 'CONCLUSÃO' : '',
            alignment: 'center',
            bold: true,
            fillColor: (isConformidade) ? '#bbbbbb' : '',
            border: (isConformidade) ? [true, true, true, true] : [false],
          }],
        ]
      },
    },
    {
      text: (isConformidade) ? '\n' : '',
    },
    // {
    //   text: (analise.textoFinalExtraHTML != null && analise.textoFinalExtraHTML !== "") ? getTextoSublinhadoNegritado(analise.textoFinalExtraHTML) : "",
    //   // alignment: 'justify',
    //   // preserveLeadingSpaces: true,
    //   lineHeight: 1.25
    // },
    // {
    //   text: getTextoFormatado(analise.textoFinal),
    //   alignment: 'justify',
    //   preserveLeadingSpaces: true,
    //   lineHeight: 1.25
    // },
    (analise.textoFinalHTML != null && analise.textoFinalHTML !== "") ? fromHtmlToText(inserirTabulação(analise.textoFinalHTML)) : {
      text: getTextoFormatado(analise.textoFinal),
      alignment: 'justify',
      preserveLeadingSpaces: true,
      lineHeight: 1.25
    },
    // '\n',
    ((analise.textoFinalHTML != null && analise.textoFinalHTML !== "") || (analise.textoFinal != null && analise.textoFinal !== "")) ? '\n' : '',
    (analise.textoFinalExtraHTML != null && analise.textoFinalExtraHTML !== "") ? fromHtmlToText(inserirTabulação(analise.textoFinalExtraHTML)) : {
      text: getTextoSublinhadoNegritado(analise.textoFinalExtra),
      alignment: 'justify',
      preserveLeadingSpaces: true,
      lineHeight: 1.25
    },
    ((analise.textoFinalExtraHTML != null && analise.textoFinalExtraHTML !== "") || (analise.textoFinalExtra != null && analise.textoFinalExtra !== "")) ? '\n\n' : '',

  ];

  const dadosFinais = [
    {
      stack: [
        (analise.textoEncaminhamentoHTML != null && analise.textoEncaminhamentoHTML !== "") ? {
          stack: fromHtmlToText(inserirTabulação(analise.textoEncaminhamentoHTML))
        } : {
          text: getTextoFormatado(analise.textoEncaminhamento),
          alignment: 'justify',
          preserveLeadingSpaces: true,
          lineHeight: 1.25
        },
        '\n\n',
        // {
        //   text: getDataAnalisePorExtenso(analise.dataModificacao),
        //   alignment: 'center',
        //   bold: true,
        //   id: 'dataAnaliseExtenso'
        // },
        // '\n\n',
        {
          stack: [
            {
              columns: [
                {
                  table: {
                    widths: ['*'],
                    headerRows: 1,
                    body: [
                      [{
                        text: (usuario?.isEstagiario) ? `Sob Supervisão em: ${Utilidades.getDataFormatada(analise?.dataAnalise)}` : `Analisado em: ${Utilidades.getDataFormatada(analise?.dataAnalise)}`,
                        alignment: 'center',
                        bold: true,
                        border: [true, true, true, true],
                      }],
                      [{
                        text: '\n\n\n',
                        border: [true, false, true, false],
                      }],
                      [{
                        text: (analise.nomeAnalista != null && analise.nomeAnalista !== "") ? analise.nomeAnalista : '\n',
                        alignment: 'center',
                        bold: false,
                        border: [true, false, true, false],
                      }],
                      [{
                        text: (analise.matriculaAnalista != null && analise.matriculaAnalista !== "") ? 'Mat. ' + analise.matriculaAnalista : '\n',
                        alignment: 'center',
                        bold: false,
                        border: [true, false, true, true],
                      }]
                    ]
                  },
                  margin: [15, 0, 10, 0]
                },
                {
                  table: {
                    widths: ['*'],
                    headerRows: 1,
                    body: [
                      [{
                        text: `Revisado em: ${Utilidades.getDataFormatada((analise?.dataRevisao != null ? analise?.dataRevisao : analise?.dataAnalise))}`,
                        alignment: 'center',
                        bold: true,
                        border: [true, true, true, true],
                      }],
                      [{
                        text: '\n\n\n',
                        border: [true, false, true, false],
                      }],
                      [{
                        // text: (analise.nomeRevisor != null && analise.nomeRevisor !== "") ? analise.nomeRevisor : (unidade.nomeResponsavel != null && unidade.nomeResponsavel !== '') ? unidade.nomeResponsavel : '\n',
                        text: (analise.nomeRevisor != null && analise.nomeRevisor !== "") ? analise.nomeRevisor : '\n',
                        alignment: 'center',
                        bold: false,
                        border: [true, false, true, false],
                      }],
                      [{
                        // text: (analise.matriculaRevisor != null && analise.matriculaRevisor !== "") ? 'Mat. ' + analise.matriculaRevisor : (unidade.matriculaResponsavel != null && unidade.matriculaResponsavel !== '') ? 'Mat. ' + unidade.matriculaResponsavel : '\n',
                        text: (analise.matriculaRevisor != null && analise.matriculaRevisor !== "") ? 'Mat. ' + analise.matriculaRevisor : '\n',
                        alignment: 'center',
                        bold: false,
                        border: [true, false, true, true],
                      }]
                    ]
                  },
                  margin: [10, 0, 15, 0]
                },
              ]
            }
          ],
          unbreakable: true,
          id: 'assinaturasAnalistaRevisor'
        },
        '\n\n',
        {
          stack: [
            {
              table: {
                widths: ['*'],
                headerRows: 1,
                body: [
                  [{
                    text: 'Ciente em:',
                    alignment: 'center',
                    bold: true,
                    border: [true, true, true, true],
                    // margin: [90,0,0,0]
                  }],
                  [{
                    text: '\n\n\n',
                    border: [true, false, true, false],
                  }],
                  [{
                    text: (unidade.nomeOrdenadorDespesas != null && unidade.nomeOrdenadorDespesas !== "") ? unidade.nomeOrdenadorDespesas : '\n',
                    alignment: 'center',
                    bold: false,
                    border: [true, false, true, false],
                  }],
                  [{
                    text: (Number(analise.unidadeResponsavelId) === 1) ? 'Controlador Geral do Município' : '',
                    alignment: 'center',
                    bold: false,
                    border: [true, false, true, false],
                  }],
                  [{
                    text: (unidade.matriculaOrdenadorDespesas != null && unidade.matriculaOrdenadorDespesas !== "") ? 'Mat. ' + unidade.matriculaOrdenadorDespesas : '\n',
                    alignment: 'center',
                    bold: false,
                    border: [true, false, true, true],
                  }]
                ]
              },
              margin: [15, 0, 15, 0]
            }
          ],
          unbreakable: true
        }
      ],
      unbreakable: true,
    }
  ];



  function rodape(currentPage, pageCount) {
    return [
      {
        table: {
          widths: ['*'],
          body: [
            [
              {
                border: [false, true, false, false],
                text: ''
              }
            ]
          ]
        },
        layout: {
          vLineColor: function (i, node) {
            return '#0098da';
          },
          hLineColor: function (i, node) {
            return '#0098da';
          }
        },
        margin: [marginInCm(3), 0, marginInCm(2.8), 0] // left, top, right, bottom
      },
      {
        columns: [
          {
            text: "Processo nº " + processo?.numero + " - " + processo?.volumeseApensos,
            alignment: 'left',
            fontSize: 9,
            margin: [0, 10, 0, 0] // left, top, right, bottom
          },
          {
            image: logoPrefeituraRodape,
            width: 30,
            alignment: 'right',
            // margin: [0, 0, 56, 0] // left, top, right, bottom
          }
        ],
        margin: [marginInCm(3), 0, marginInCm(2.8), 0] // left, top, right, bottom
      },
      {
        // text: currentPage + ' / ' + pageCount,
        text: currentPage.toString(),
        alignment: 'right',
        fontSize: 9,
        margin: [0, -15, marginInCm(1), 0] // left, top, right, bottom
      }
    ]
  }



  const docDefinition = {
    pageSize: 'A4',

    // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    pageMargins: [marginInCm(3), marginInCm(3), marginInCm(3), marginInCm(2)],

    header: cabecalho,
    content: [dadosBasicos, camposComplementares, camposCompAditivos, camposCompConformidade, textosIniciais, blocos, textosFinais, dadosFinais],
    footer: rodape,

    defaultStyle: {
      fontSize: 11,
      bold: false,
      alignment: 'justify',
    },
    styles: {
      header: {
        fontSize: 16,
        bold: true
      },
      subheader: {
        fontSize: 14,
        bold: false
      }
    },
    pageBreakBefore: function (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
      if (currentNode.id != null && currentNode.id.includes('campoCompAditivo')
        && (currentNode.pageNumbers.length !== 1
          || (
            followingNodesOnPage[0].id != null && followingNodesOnPage[0].id.includes('campoCompAditivo') && followingNodesOnPage[0].pageNumbers.length !== 1)
        )
      ) {
        return true;
      }
      if (currentNode.id != null && currentNode.id === 'dataAnaliseExtenso' && nodesOnNextPage.findIndex((node) => node?.id === 'assinaturasAnalistaRevisor') !== -1
      ) {
        return true;
      }
      return false;
    }
  };
  console.log('docDefinition', docDefinition);
  pdfMake.createPdf(docDefinition).open({}, win);

}


export default analisePDF;