import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Requisicao from '../../helpers/Requisicao';
import Utilidades from '../../helpers/Utilidades';
import { isValid, parseISO } from 'date-fns';
import Autenticar from '../../login/Autenticar';
import LoadingElement from '../../components/LoadingElement';
import DatePicker from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";

export default function EditarPenalidade({ usuario, autenticado }) {
  const { penalidadeId } = useParams();
  const editando = isNaN(Number(penalidadeId)) ? false : true;

  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(editando ? true : false);
  const [salvando, setSalvando] = useState(false);

  const [penalidade, setPenalidade] = useState(null);


  const [listaOrgaos, setListaOrgaos] = useState([]);
  const [listaTipoPenalidade, setListaTipoPenalidade] = useState([]);

  const [orgao, setOrgao] = useState("-1");
  const [tipoPenalidade, setTipoPenalidade] = useState("-1");

  const [numeroProcessoAdministrativo, setNumeroProcessoAdministrativo] = useState("");
  const [numeroProcessoAutuacao, setNumeroProcessoAutuacao] = useState("");




  // componentDidMount
  useEffect(() => {
    if (autenticado) {
      carregarListas();

      if (editando) {
        carregarPenalidade(penalidadeId);
      }

    }

    document.title = `${editando ? 'Editar - ' : 'Novo - '}Cadastro de Penalidade - Sistema de Controle Interno`;
    return (() => document.title = 'Sistema de Controle Interno')
    // eslint-disable-next-line
  }, [autenticado, penalidadeId, editando]);

  const carregarPenalidade = (id) => {
    if(isNaN(Number(id))){
      if (window.history.state && window.history.state?.idx > 1) {
        navigate(-1, { replace: true });
      } else {
        navigate('/', { state: { referrer: location }, replace: true });
      }
    } else {
      setLoading(true);
      Requisicao.getPenalidade(id)
        .then(data => {
          console.log('penalidade', data);
          if (data != null) {
            setupPenalidade(data);
            setLoading(false);
          } else {
            setLoading(false);
            alert("Não foi possível encontrar o cadastro de penalidade desejado.");
            if (window.history.state && window.history.state?.idx > 1) {
              navigate(-1);
            } else {
              navigate('/', { state: { referrer: location }, replace: true });
            }
          }
        })
        .catch((erro) => {
          console.log(erro);
          setLoading(false);
        });
    }
  }

  const setupPenalidade = (p) => {

    let newPenalidade = {
      id: p.id,
      orgaoResponsavel: p.orgaoResponsavel,
      numeroProcessoAdministrativo: p.numeroProcessoAdministrativo,
      numeroProcessoAutuacao: p.numeroProcessoAutuacao,
      objeto: p.objeto,
      razaoSocial: p.razaoSocial,
      cnpj: p.cnpj,
      tipoPenalidade: p.tipoPenalidade,
      numeroDiarioOficial: p.numeroDiarioOficial,
      dataPublicacaoDiarioOficial: isValid(parseISO(p.dataPublicacaoDiarioOficial)) ? parseISO(p.dataPublicacaoDiarioOficial) : null,
      folhaDiarioOficial: p.folhaDiarioOficial,
      dataInicioPunicao: isValid(parseISO(p.dataInicioPunicao)) ? parseISO(p.dataInicioPunicao) : null,
      dataTerminoPunicao: isValid(parseISO(p.dataTerminoPunicao)) ? parseISO(p.dataTerminoPunicao) : null,
      observacoes: p.observacoes,
    }

    if(p.numeroProcessoAdministrativo != null){
      setNumeroProcessoAdministrativo(p.numeroProcessoAdministrativo);
    }

    if(p.numeroProcessoAutuacao != null){
      setNumeroProcessoAutuacao(p.numeroProcessoAutuacao);
    }

    if(p.orgaoResponsavel != null){
      setOrgao(p.orgaoResponsavel);
    }

    if(p.tipoPenalidade != null){
      setTipoPenalidade(p.tipoPenalidade);
    }
    
    window.$("#comboboxOrgaos")?.val(p.orgaoResponsavel).trigger("change");
    window.$("#comboboxTipoPenalidade")?.val(p.tipoPenalidade).trigger("change");

    setPenalidade(newPenalidade);
  }

  useEffect(() => {
    if (!loading){
      window.$(".select2bs4").select2({
        theme: "bootstrap4",
      });

      window.$("#comboboxOrgaos").on('change', function (e) {
        e.target?.classList.remove("is-invalid");
        setOrgao(e.target.value);
      });
      window.$("#comboboxTipoPenalidade").on('change', function (e) {
        e.target?.classList.remove("is-invalid");
        setTipoPenalidade(e.target.value);
      });


    }
  }, [loading])

  useEffect(() => {
    if (numeroProcessoAdministrativo != null && numeroProcessoAdministrativo !== "") {
      const timeOutId = setTimeout(() => fetchProcessoDebounced(numeroProcessoAdministrativo, 'list_processos_administrativos'), 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [numeroProcessoAdministrativo]);

  useEffect(() => {
    if (numeroProcessoAutuacao != null && numeroProcessoAutuacao !== "") {
      const timeOutId = setTimeout(() => fetchProcessoDebounced(numeroProcessoAutuacao, 'list_processos_autuacao'), 1000);
      return () => clearTimeout(timeOutId);
    }
  }, [numeroProcessoAutuacao]);

  const fetchProcessoDebounced = (numero, idList) => {
    Requisicao.pesqusiarProcessoDadosBasicosPorNumero(numero).then((data) => {
      // setListaProcessos(data);
      let list = document.getElementById(idList);
      if (list != null) {
        list.innerHTML = '';

        data?.forEach((element) => {
          let option = document.createElement('option');
          option.value = element.numero;
          list.appendChild(option);
        })
      }
    }).catch(erro => console.log('erro', erro));
  }

  const carregarListas = () => {
    Requisicao.getListaOrgaos().then((data) => setListaOrgaos(data)).catch(erro => console.log('erro', erro));
    Requisicao.getListaTipoPenalidade().then((data) => setListaTipoPenalidade(data)).catch(erro => console.log('erro', erro));
  }

  const limparInterface = () => {
    setNumeroProcessoAdministrativo("");
    let elm = document.getElementById('inputNumeroProcesso');
    elm?.classList.remove('is-valid');
    elm?.classList.remove('is-invalid');

  }

  // preencher comboboxOrgaos
  useEffect(() => {
    if (!loading) {
      window.$("#comboboxOrgaos").empty();
      let combobox = document.querySelector("#comboboxOrgaos").options;

      combobox.add(new Option("Selecione o Órgão Solicitante...", "-1"));
      combobox[0].disabled = true;
      combobox.selectedIndex = 0;

      listaOrgaos.forEach((element, index) => {
        combobox.add(new Option(element.nome, element.value));
        if (orgao != null && orgao === element.value) {
          combobox.selectedIndex = index + 1;
        }
      });
    }
  }, [listaOrgaos, orgao, loading]);

  // preencher comboboxTipoPenalidade
  useEffect(() => {
    if (!loading) {
      window.$("#comboboxTipoPenalidade").empty();
      let combobox = document.querySelector("#comboboxTipoPenalidade").options;

      combobox.add(new Option("Selecione o tipo...", "-1"));
      combobox[0].disabled = true;
      combobox.selectedIndex = 0;

      Utilidades.sortEnumPorNome(listaTipoPenalidade)?.forEach((element, index) => {
        combobox.add(new Option(element.nome, element.value));
        if (tipoPenalidade != null && tipoPenalidade === element.value) {
          combobox.selectedIndex = index + 1;
        }
      });
    }
  }, [listaTipoPenalidade, tipoPenalidade, loading]);

  const numeroProcessoValido = (input) => {
    let regex = /^[0-9]+\/[0-9]+$/i;
    return regex.test(input);
  }

  const submitForm = (e) => {
    // e.preventDefault();
    // e.stopPropagation();

    if(validateForm()){
      gravar();
    }
  }

  const validateForm = () => {
    
    let valido = true;
    if(orgao == null || orgao === "" || orgao === "-1" || !listaOrgaos.some(e => e.value === orgao)){
      valido = false;
      document.getElementById("comboboxOrgaos")?.classList.add("is-invalid");
    }
    
    if(tipoPenalidade == null || tipoPenalidade === "" || tipoPenalidade === "-1" || !listaTipoPenalidade.some(e => e.value === tipoPenalidade)){
      valido = false;
      document.getElementById("comboboxTipoPenalidade")?.classList.add("is-invalid");
    }

    // validar numeros processo
    if(numeroProcessoAdministrativo !== "" && !numeroProcessoValido(numeroProcessoAdministrativo)){
      valido = false;
      document.getElementById("inputNumeroProcessoAdministrativo")?.classList.add("is-invalid");
    }
    if(numeroProcessoAutuacao !== "" && !numeroProcessoValido(numeroProcessoAutuacao)){
      valido = false;
      document.getElementById("inputNumeroProcessoAutuacao")?.classList.add("is-invalid");
    }

    if(penalidade?.cnpj != null && penalidade?.cnpj !== "" && !Utilidades.validateCNPJ(penalidade?.cnpj) && !Utilidades.validateCPF(penalidade?.cnpj)){
      valido = false;
      document.getElementById("inputCNPJ")?.classList.add("is-invalid");

    }

    // console.log('valido', valido);
    if(!valido){
      alert("Preencha os campos corretamente.");
    }
    console.log('valido', valido)
    return valido;
  }

  const getDados = () => {
    let dados = penalidade;
    dados.id = editando ? penalidadeId : null;
    dados.orgaoResponsavel = orgao;
    dados.tipoPenalidade = tipoPenalidade;
    dados.numeroProcessoAdministrativo = numeroProcessoAdministrativo;
    dados.numeroProcessoAutuacao = numeroProcessoAutuacao;
    dados.cnpj = dados.cnpj?.replace(/[^\d]+/g,'');
    
    console.log('dados', dados)

    return dados;
  }

  const gravar = () => {
    setSalvando(true);
    window.toastr["info"]("Salvando...", '', { timeOut: 0, extendedTimeout: 0 });

    const dados = getDados();

    let url = null;

    if(editando){ //editar
      dados.id = penalidadeId;
      url = window.servidor + "/v1/protegido/penalidade/editar"
      
    } else { //novo
      dados.id = null;
      url = window.servidor + "/v1/protegido/penalidade/novo"
    }

    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(dados)
    };

    fetch(url, requestOptions)
        .then((response) => {
          if(response.status !== 200){
            setSalvando(false);
            throw new Error(`Falha ${editando ? "na edição" : "no cadastro"} da penalidade.`);
          }
          return response.json();
        })
        .then((data) => {
          // alert("Aviso salvo com sucesso.");
          setSalvando(false);
          window.toastr.clear();
          if(data != null){
            window.toastr["success"]("Salvo com sucesso!");
          } else {
            window.toastr["error"]("Falha ao salvar. Verifique sua conexão com a internet.");
          }
          // console.log('salvo', data);
          if(!editando){
            navigate("/penalidade/editar/" + data.id, { state:{referrer:location}, replace:true });
          }
        })
        .catch(erro => {
          setSalvando(false);
          window.toastr.clear();
          window.toastr["error"]("Falha ao salvar. Verifique sua conexão com a internet.");
          console.log(erro);
        });

  }

  const handleChangeNumeroProccessoAdministrativo = (e) => {
    // let elm = document.getElementById('inputNumeroProcessoAdministrativo');
    e.target?.classList.remove('is-valid');
    e.target?.classList.remove('is-invalid');
    setNumeroProcessoAdministrativo(e.target.value);
  }

  const handleChangeNumeroProccessoAutuacao = (e) => {
    // let elm = document.getElementById('inputNumeroProcessoAutuacao');
    e.target?.classList.remove('is-valid');
    e.target?.classList.remove('is-invalid');
    setNumeroProcessoAutuacao(e.target.value);
  }

  

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">{editando ? 'Editar ' : 'Novo '}Cadastro de Penalidade</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">{editando ? 'Editar ' : 'Novo '}Cadastro de Penalidade</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12">
          <div className="card">
            <div className="card-body">
              {
                loading ? <LoadingElement /> :
                  <>
                    <div className='d-flex flex-column flex-md-row px-2 my-2'>
                      <div className='col col-md-4'>
                        <label>Razão Social</label>
                        <input id='inputRazaoSocial' type='text' className='form-control' placeholder='Insira a Razão Social...' 
                          value={penalidade?.razaoSocial ?? ""} onChange={(e) => setPenalidade({...penalidade, razaoSocial: e.target.value})}/>
                      </div>
                      <div className='col col-md-4'>
                        <label>CNPJ / CPF</label>
                        <input id='inputCNPJ' type='text' className='form-control' placeholder='Insira o CNPJ / CPF...' autoComplete='off' 
                          value={penalidade?.cnpj ?? ""} onChange={(e) => {
                            setPenalidade({...penalidade, cnpj: e.target.value});
                            e.target.classList.remove("is-invalid");
                          }}
                          aria-describedby='inputCNPJFeedback'/>
                        <div id="inputCNPJFeedback" className="invalid-feedback mb-2">CNPJ/CPF inválido!</div>
                      </div>
                      <div className='col col-md-4'>
                        <label>Tipo de Penalidade</label>
                        <select id={`comboboxTipoPenalidade`} className="form-control select2bs4" style={{ width: '100%' }}
                          aria-describedby='comboboxTipoPenalidadeFeedback'></select>
                        <div id="comboboxTipoPenalidadeFeedback" className="invalid-feedback mb-2">Campo obrigatório</div>
                      </div>
                    </div>
                    <div className='d-flex flex-column flex-md-row px-2 my-2'>
                      <div className="col col-md-4">
                        <label htmlFor="comboboxOrgaos">Órgão Solicitante</label>
                        <select id="comboboxOrgaos" className="select2bs4" style={{ width: "100%" }} aria-describedby="comboboxOrgaosFeedback"></select>
                        <div id="comboboxOrgaosFeedback" className="invalid-feedback mb-2">Campo obrigatório</div>
                      </div>
                      <div className='col col-md-4'>
                        <label>Número Processo Administrativo</label>
                        <input id='inputNumeroProcessoAdministrativo' type='text' className='form-control' placeholder='Insira o número do processo administrativo...' pattern='^[0-9]+/[0-9]+$'
                          list='list_processos_administrativos' onChange={(e) => handleChangeNumeroProccessoAdministrativo(e)} value={numeroProcessoAdministrativo}
                          autoComplete='off' aria-describedby='inputNumeroProcessoAdministrativoFeedback'
                        />
                        <div id="inputNumeroProcessoAdministrativoFeedback" className="invalid-feedback mb-2">Processo inválido</div>
                        <div className='d-inline' id='processos_suggest_container'>
                          <datalist id="list_processos_administrativos">
                          </datalist>
                        </div>
                      </div>
                      <div className='col col-md-4'>
                        <label>Número Processo de Autuação</label>
                        <input id='inputNumeroProcessoAutuacao' type='text' className='form-control' placeholder='Insira o número do processo de autuação...' pattern='^[0-9]+/[0-9]+$'
                          list='list_processos_autuacao' onChange={(e) => handleChangeNumeroProccessoAutuacao(e)} value={numeroProcessoAutuacao}
                          autoComplete='off' aria-describedby='inputNumeroProcessoAutuacaoFeedback'
                        />
                        <div id="inputNumeroProcessoAutuacaoFeedback" className="invalid-feedback mb-2">Processo inválido</div>
                        <div className='d-inline' id='processos_autuacao_suggest_container'>
                          <datalist id="list_processos_autuacao">
                          </datalist>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex flex-column flex-md-row px-2'>
                      <div className='col col-md-4'>
                        <label>Objeto</label>
                        <textarea id='inputNumeroObjeto' rows={2} className='form-control' placeholder='Insira o objeto do processo...'
                          value={penalidade?.objeto ?? ""} onChange={(e) => setPenalidade({...penalidade, objeto: e.target.value})}/>
                      </div>
                      <div className='col col-md-4'>
                        <label>Data de Início da Punição</label>
                        <div className='d-flex flex-column'>
                          <DatePicker id={`datepickerDataInicioPunicao`} className="form-control col"
                            selected={penalidade?.dataInicioPunicao ?? null}
                            autoComplete='off'
                            placeholderText="Clique para selecionar..."
                            onChange={(date) => {
                              setPenalidade({...penalidade, dataInicioPunicao: date});
                            }}
                            strictParsing
                            showYearDropdown
                            yearDropdownItemNumber={8}
                            scrollableYearDropdown
                            dateFormatCalendar="MMMM"
                            locale={ptBR} dateFormat="dd/MM/yyyy" required />
                        </div>
                      </div>
                      <div className='col col-md-4'>
                        <label>Data de Término da Punição</label>
                        <div className='d-flex flex-column'>
                          <DatePicker id={`datepickerDataTerminoPunicao`} className="form-control col"
                            selected={penalidade?.dataTerminoPunicao ?? null}
                            autoComplete='off'
                            placeholderText="Clique para selecionar..."
                            onChange={(date) => {
                              setPenalidade({...penalidade, dataTerminoPunicao: date});
                            }}
                            strictParsing
                            showYearDropdown
                            yearDropdownItemNumber={8}
                            scrollableYearDropdown
                            dateFormatCalendar="MMMM"
                            locale={ptBR} dateFormat="dd/MM/yyyy" required />
                        </div>
                      </div>
                    </div>
                    <div className='d-flex flex-column flex-md-row px-2'>
                      <div className='col col-md-4'>
                        <label>Nº do Diário Oficial</label>
                        <input id='inputNumeroDiarioOficial' type='text' className='form-control' placeholder='Insira o nº do diário oficial...' autoComplete='off' 
                          value={penalidade?.numeroDiarioOficial ?? ""} onChange={(e) => setPenalidade({...penalidade, numeroDiarioOficial: e.target.value})}/>
                      </div>
                      <div className='col col-md-4'>
                        <label>Folha do Diário Oficial</label>
                        <input id='inputFolhaDiarioOficial' type='text' className='form-control' placeholder='Insira a folha do diário oficial...' autoComplete='off' 
                          value={penalidade?.folhaDiarioOficial ?? ""} onChange={(e) => setPenalidade({...penalidade, folhaDiarioOficial: e.target.value})}/>
                      </div>
                      <div className='col col-md-4'>
                        <label>Data de Publicação no Diário Oficial</label>
                        <div className='d-flex flex-column'>
                          <DatePicker id={`datepickerDataPublicacaoDiarioOficial`} className="form-control col"
                            selected={penalidade?.dataPublicacaoDiarioOficial ?? null}
                            autoComplete='off'
                            placeholderText="Clique para selecionar..."
                            onChange={(date) => {
                              setPenalidade({...penalidade, dataPublicacaoDiarioOficial: date});
                            }}
                            strictParsing
                            showYearDropdown
                            yearDropdownItemNumber={8}
                            scrollableYearDropdown
                            dateFormatCalendar="MMMM"
                            locale={ptBR} dateFormat="dd/MM/yyyy" required />
                        </div>
                      </div>
                    </div>
                    <div className='d-flex flex-column flex-md-row px-2'>
                      <div className='col col-md-4'>
                        <label>Observações</label>
                        <textarea id='inputNumeroObservacoes' rows={2} className='form-control' placeholder='Insira o objeto do processo...'
                          value={penalidade?.observacoes ?? ""} onChange={(e) => setPenalidade({...penalidade, observacoes: e.target.value})}/>
                      </div>
                    </div>

                  </>
              }
              <br />
              <hr />
              {loading ? <></> :
                <>
                  <div className='text-right'>
                    {salvando ?
                      <button type='button' className='btn btn-primary' onClick={void (0)} disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;Salvando...
                      </button>
                      : <button type='button' className='btn btn-primary' onClick={() => submitForm()}>Salvar</button>
                    }
                  </div>
                </>
              }
            </div>
          </div>
        </form>
      </div>

    </>
  )
}
