import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Autenticar from '../../login/Autenticar';
import Requisicao from '../../helpers/Requisicao';

let nomesTiposUsuario = {};

export default function PesquisaUsuario({ usuario }) {
  
  const [searchParams, setSearchParams] = useSearchParams();

  const [nome, setNome] = useState(searchParams.get("nome") ?? "");
  const [login, setLogin] = useState(searchParams.get("login") ?? "");
  const [matricula, setMatricula] = useState(searchParams.get("matricula") ?? "");
  const [cpf, setCpf] = useState(searchParams.get("cpf") ?? "");
  const [resultado, setResultado] = useState({});

  const [pageNumber, setPageNumber] = useState("0");
  const [pageSize, setPageSize] = useState("10");

  const [nenhumEncontrado, setNenhumEncontrado] = useState(false);
  const [pesquisando, setPesquisando] = useState(false);

  const [listaTiposUsuario, setListaTiposUsuario] = useState([]);
  const [tipoUsuario, setTipoUsuario] = useState("-1");

  const [listaUnidades, setListaUnidades] = useState([]);
  const [unidadeId, setUnidade] = useState("-1");

  const tipoParam = searchParams.get("tipo") ?? "";
  const unidadeParam = searchParams.get("unidadeId") ?? "";


  useEffect(() => {
    if(searchParams != null && searchParams.toString() !== ""){
      if(searchParams.has("p") && searchParams.get("p") === ""){
        setPageNumber(searchParams.get("p"));
      }
      if(!searchParams.has("s") || searchParams.get("s") === ""){
        setPageSize(searchParams.get("s"));
      }
      pesquisar();

    }
  // eslint-disable-next-line
  }, [searchParams]);

  const carregarTiposUsuario = () => {
    Requisicao.getListaTiposUsuario()
      .then(data => {
        if(data != null && data.length > 0){
          data.forEach(tipo => {
            nomesTiposUsuario[tipo?.value] = tipo?.nome;
          })
        }
        setListaTiposUsuario(data);
      })
      .catch(erro => console.log('erro', erro));
  }

  const carregarUnidades = () => {
    Requisicao.getListaUnidades()
      .then(data => setListaUnidades(data))
      .catch(erro => console.log('erro', erro));
  }

  useEffect(()=> {
    window.$(".select2bs4").select2({
      theme: "bootstrap4",
    });
    window.$("#comboTiposUsuarios").on('change', function (e) {
      setTipoUsuario(e.target.value);
    });
    window.$("#comboUnidades").on('change', function (e) {
      setUnidade(e.target.value);
    });
    carregarUnidades();
    carregarTiposUsuario();

    document.title = 'Pesquisar Usuário - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
  }, []);

  // preencherComboTipos
  useEffect(()=>{
    window.$("#comboTiposUsuarios").empty();
    let combobox = document.querySelector("#comboTiposUsuarios").options;


    combobox.add(new Option("Selecione o Tipo do Usuário", "-1"));
    if(usuario.tipoUsuario === "ADMINISTRADOR"){
      listaTiposUsuario?.forEach((element) => {
        combobox.add(new Option(element.nome, element.value));
      });
    } else {
      listaTiposUsuario?.forEach((element) => {
        if(element.setor === usuario.setor){
          combobox.add(new Option(element.nome, element.value));
        }
      });
    }

    // combobox[0].disabled = true;

    if(listaTiposUsuario != null && listaTiposUsuario.length > 0){
      for (let i = 0; i < combobox.length; i++) {
        if(tipoParam === combobox[i].value){
          combobox.selectedIndex = i;
          setTipoUsuario(tipoParam);
        }
      }
    } else {
      combobox.selectedIndex = 0;
    }
  }, [listaTiposUsuario, tipoParam, usuario.setor, usuario.tipoUsuario]);

  // preencherComboUnidades
  useEffect(()=>{
    window.$("#comboUnidades").empty();
    let combobox = document.querySelector("#comboUnidades").options;


    combobox.add(new Option("Selecione a Unidade do Usuário", "-1"));
    if(usuario.tipoUsuario === "ADMINISTRADOR"){
      listaUnidades?.forEach((element) => {
        combobox.add(new Option(element.nome, element.id));
      });
    } else {
      listaUnidades?.forEach((element) => {
        if(element.setor === usuario.setor){
          combobox.add(new Option(element.nome, element.id));
        }
      });
    }

    // combobox[0].disabled = true;

    if(listaUnidades != null && listaUnidades.length > 0){
      for (let i = 0; i < combobox.length; i++) {
        if(unidadeParam === combobox[i].value){
          combobox.selectedIndex = i;
          setUnidade(unidadeParam);
        }
      }
    } else {
      combobox.selectedIndex = 0;
    }
  }, [listaUnidades, unidadeParam, usuario.setor, usuario.tipoUsuario]);

  const getPagination = () => {
    let totalPaginas = parseInt(resultado.totalPaginas) ?? 0;
    let pag = []
    for (let i = 0; i < totalPaginas; i++) {
      if(i === parseInt(resultado.numeroPagina)){
        pag.push(<li className="page-item active"><button type="button" className="page-link" onClick={()=>handleChangePage(i)}>{i+1}</button></li>);
      } else {
        pag.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(i)}>{i+1}</button></li>);
      }
    }

    return pag;
    
  }

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
    let params = searchParams;
    params.set("p", "0");
    params.set("s", e.target.value);
    setSearchParams(params);
  }

  const handleChangePage = (page) => {
    let params = searchParams;
    params.set("p", page);
    setSearchParams(params);
  }

  const pesquisar = () => {
    setNenhumEncontrado(false);
    setPesquisando(true);

    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/usuario/pesquisa?" + searchParams.toString();

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if(data.totalElementos === 0 || data.totalElementos === null){
          setNenhumEncontrado(true);
        }
        setPesquisando(false);
        setResultado(data);
      })
      .catch((erro) => console.log(erro));
  }

  const handlePesquisar = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let params = new URLSearchParams();

    if(nome != null && nome !== ""){
      params.set("nome", nome);
    }
    if(login != null && login !== ""){
      params.set("login", login);
    }
    if(matricula != null && matricula !== ""){
      params.set("matricula", matricula);
    }
    if(cpf != null && cpf !== ""){
      params.set("cpf", cpf);
    }

    if(tipoUsuario != null && tipoUsuario !== "" & tipoUsuario !== "-1"){
      params.set("tipo", tipoUsuario);
    }

    if(unidadeId != null && unidadeId !== "" & unidadeId !== "-1"){
      params.set("unidadeId", unidadeId);
    }

    params.set("p", pageNumber.toString());
    params.set("s", pageSize.toString());

    if(params.toString() !== searchParams.toString){
      setSearchParams(params);
    } else {
      pesquisar();
    }
  }

  const limparInterface = () => {
    setNome("");
    setLogin("");
    setMatricula("");
    setCpf("");
    window.$('#comboTiposUsuarios').val("-1").trigger("change");
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Pesquisa de Usuários</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Pesquisa de Usuários</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12" onSubmit={handlePesquisar}>
          <div className="card">
            <div className="card-body">
              <h4 className='my-3'>Pesquisa de Usuários</h4>
              <div className='my-3 row'>
                <div className='col'>
                  <label htmlFor='inputPesquisaNome'>Nome</label>
                  <input id="inputPesquisaNome" type="text" className='form-control d-flex flex-column' value={nome} onChange={(e)=>setNome(e.target.value)} placeholder={"Digite o nome do usuário"} />
                </div>
                <div className='col'>
                  <label htmlFor='inputPesquisaLogin'>Login</label>
                  <input id="inputPesquisaLogin" type="text" className='form-control d-flex flex-column' value={login} onChange={(e)=>setLogin(e.target.value)} placeholder={"Digite o login do usuário"} />
                </div>
              </div>
              <div className='my-3 row'>
                <div className='col'>
                  <label htmlFor='inputPesquisaMatricula'>Matrícula</label>
                  <input id="inputPesquisaMatricula" type="number" className='form-control d-flex flex-column' value={matricula} onChange={(e)=>setMatricula(e.target.value)} onWheel={(e) => e.target.blur()} placeholder={"Digite a matrícula do usuário"} />
                </div>
                <div className='col'>
                  <label htmlFor='inputPesquisaCpf'>CPF</label>
                  <input id="inputPesquisaCpf" type="number" className='form-control d-flex flex-column' value={cpf} onChange={(e)=>setCpf(e.target.value)} onWheel={(e) => e.target.blur()} placeholder={"Digite o CPF do usuário"} />
                </div>
              </div>
              <div className='my-3 row'>
                <div className="col-6">
                  <label>Tipo do Usuário</label>
                  <select
                    id="comboTiposUsuarios"
                    className="form-control select2bs4"
                    style={{ width: "100%" }}
                  >
                  </select>
                </div>
                <div className="col-6">
                  <label>Unidade do Usuário</label>
                  <select
                    id="comboUnidades"
                    className="form-control select2bs4"
                    style={{ width: "100%" }}
                  >
                  </select>
                </div>
              </div>
              <div className='row'>
                <button type='submit' className='btn btn-primary mx-2'>Pesquisar</button>
                <button type='button' className='btn btn-outline-primary mx-2' onClick={limparInterface}>Limpar</button>
              </div>
              <hr />
              <div className='my-3'>
                {
                  (pesquisando) ?
                  <div className="text-center mt-3 col px-0 mx-0">
                    <div className="spinner-border" role="status">
                    </div>
                  </div> :
                  <></>
                }
                {
                  resultado?.elementos != null && resultado?.elementos.length > 0 ?
                  <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Nome</th>
                            <th scope="col">Login</th>
                            <th scope="col">E-mail</th>
                            <th scope="col">Matrícula</th>
                            <th scope="col">Setor</th>
                            <th scope="col">Tipo de Usuário</th>
                            <th scope="col" className="text-center">Editar</th>
                        </tr>
                    </thead>
                    <tbody className="align-middle">
                        {resultado.elementos && resultado.elementos.map((elemento) => {
                            return <tr key={elemento.id}>
                                <th scope="row">{elemento.id}</th>
                                <td>{elemento.nome}</td>
                                <td>{elemento.login}</td>
                                <td>{elemento.email}</td>
                                <td>{elemento.matricula}</td>
                                <td>{elemento.setor}</td>
                                <td>{nomesTiposUsuario[elemento.tipoUsuario]}</td>
                                <td className="text-center"><Link to={`/usuario/editar/${elemento.id}`} className="btn btn-success"><i className="fas fa-edit"></i></Link></td>
                            </tr>
                        })}
                    </tbody>
                  </table> :
                  <></>
                }
                {
                  (nenhumEncontrado) ?
                  <p className='text-center'>Nenhum usuário encontrado</p> :
                  <></>
                }
              </div>
            </div>
            <div className='m-3 text-right'>
              <Link className="btn btn-primary" to={"/usuario/cadastro"}>Novo Usuário</Link>
            </div>
          </div>
          <div className='row justify-content-between px-2'>
          {
            resultado?.elementos != null && resultado?.elementos.length > 0 ?
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  {
                    parseInt(resultado.numeroPagina) === 0 ?
                    <li className="page-item disabled"><button type="button" className="page-link">Anterior</button></li> :
                    <li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(parseInt(resultado.numeroPagina)-1)}>Anterior</button></li>
                  }
                  {

                    getPagination().map((pag, index) => {
                      return <React.Fragment key={index}>
                        {pag}
                      </React.Fragment>
                    })

                  }
                  {
                    parseInt(resultado?.numeroPagina) + 1 === parseInt(resultado?.totalPaginas) ?
                    <li className="page-item disabled"><button type="button" className="page-link">Próxima</button></li> :
                    <li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(parseInt(resultado.numeroPagina)+1)}>Próxima</button></li>

                  }
                </ul>
              </nav>
              
              : <div></div>
          }
          <select id="comboboxPageSize" className="form-control col-1 text-center" value={pageSize} onChange={handleChangePageSize}>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          </div>

        </form>
        
      </div>
    </>
  )
}
