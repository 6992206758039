import { isValid, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react'
import Utilidades from '../../helpers/Utilidades';

export default function DiasUteisComponent({dataEntrada, dataSaida}) {
  
  const [diasUteis, setDiasUteis] = useState("");

  useEffect(() => {

    if(dataEntrada != null){
      let de = parseISO(dataEntrada);
      let ds = (dataSaida != null) ? parseISO(dataSaida) : new Date();
  
      if(isValid(de) && isValid(ds)){
        Utilidades.calcularDiasUteis(de, ds)
          .then(data => setDiasUteis(data))
      }
    }

  }, [dataEntrada, dataSaida])

  return (
    <div>{diasUteis}</div>
  )
}
