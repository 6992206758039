import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Autenticar from '../../login/Autenticar';
// import Tippy from '@tippyjs/react';
import DatePicker from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import LoadingElement from '../../components/LoadingElement';
import { isValid, parseISO } from 'date-fns';

export default function PesquisaFeriado({ usuario, autenticado }) {

  const [searchParams, setSearchParams] = useSearchParams();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [descricao, setDescricao] = useState("");
  const [observacoes, setObservacoes] = useState("");

  const [resultado, setResultado] = useState({});

  const [pageSize, setPageSize] = useState("10");

  const [nenhumEncontrado, setNenhumEncontrado] = useState(false);

  const [pesquisando, setPesquisando] = useState(false);

  useEffect(() => {
    if(searchParams != null && searchParams.toString() !== ""){
      if(searchParams.has("s") && searchParams.get("s") !== ""){
        setPageSize(searchParams.get("s"));
      }
      pesquisar();
    }
  // eslint-disable-next-line
  }, [searchParams]);

  const getPagination = () => {
    let totalPaginas = parseInt(resultado.totalPaginas) ?? 0;
    let pag = []

    if(parseInt(resultado.numeroPagina) === 0) {
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Primeira</button></li>);
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Anterior</button></li>);
    } else {
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(0)}>Primeira</button></li>);
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(parseInt(resultado?.numeroPagina)-1)}>Anterior</button></li>);
    }

    // for
    if(totalPaginas >= 9){
      if(parseInt(resultado.numeroPagina) >= 5){
        pag.push(<li className="page-item"><button type="button" className="page-link" data-toggle="modal" data-target="#changePageModal">...</button></li>);
      }

      for (let i = Math.max(parseInt(resultado.numeroPagina) - 4, 0); i < parseInt(resultado.numeroPagina); i++) {
        pag.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(i)}>{i+1}</button></li>);
      }

      pag.push(<li className="page-item active"><button type="button" className="page-link" onClick={()=>handleChangePage(parseInt(resultado.numeroPagina))}>{parseInt(resultado.numeroPagina)+1}</button></li>);

      for (let i = parseInt(resultado.numeroPagina) + 1; i <= parseInt(resultado.numeroPagina) + 4 && i < totalPaginas; i++) {
        pag.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(i)}>{i+1}</button></li>);
      }

      if((totalPaginas - parseInt(resultado.numeroPagina)) >= 6){
        pag.push(<li className="page-item"><button type="button" className="page-link" data-toggle="modal" data-target="#changePageModal">...</button></li>);
      }
    } else {
      for (let i = 0; i < totalPaginas; i++) {
        if(i === parseInt(resultado.numeroPagina)){
          pag.push(<li className="page-item active"><button type="button" className="page-link" onClick={()=>handleChangePage(i)}>{i+1}</button></li>);
        } else {
          pag.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(i)}>{i+1}</button></li>);
        }
      }
    }

    if(parseInt(resultado?.numeroPagina) + 1 === parseInt(resultado?.totalPaginas)){
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Próxima</button></li>);
      pag.push(<li className="page-item disabled"><button type="button" className="page-link">Última</button></li>);
    } else {
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(parseInt(resultado?.numeroPagina)+1)}>Próxima</button></li>);
      pag.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>handleChangePage(parseInt(resultado?.totalPaginas)-1)}>Última</button></li>);
    }
   
    return pag;
  }

  const handleChangePageSize = (e) => {
    setPageSize(e.target.value);
    let params = searchParams;
    params.set("p", "0");
    params.set("s", e.target.value);
    setSearchParams(params);
  }

  const handleChangePage = (page) => {
    let params = searchParams;
    params.set("p", page);
    setSearchParams(params);
  }

  const pesquisar = () => {
    setNenhumEncontrado(false);
    setPesquisando(true);
    setResultado({});

    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/feriado/pesquisa?" + searchParams.toString();

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if(data.totalElementos === 0 || data.totalElementos === null){
          // alert("Nenhum processo encontrado.");
          setNenhumEncontrado(true);
        }
        setPesquisando(false);
        setResultado(data);
        // setProcessos(data?.elementos);
      })
      .catch((erro) => console.log(erro));
  }

  const handlePesquisar = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    let params = new URLSearchParams();

    if(startDate != null && startDate !== ""){
      // params.set("startDate", startDate.toLocaleString('pt-BR').substring(0, 10));
      params.set("startDate", startDate.toJSON().substring(0, 10));
    }
    if(endDate != null && endDate !== ""){
      // params.set("endDate", endDate.toLocaleString('pt-BR').substring(0, 10));
      params.set("endDate", endDate.toJSON().substring(0, 10));
    }

    if(descricao != null && descricao !== ""){
      params.set("descricao", descricao);
    }
    if(observacoes != null && observacoes !== ""){
      params.set("observacoes", observacoes);
    }

    params.set("p", "0");
    params.set("s", pageSize.toString());

    if(params.toString() !== searchParams.toString){
      setSearchParams(params);
    } else {
      pesquisar();
    }
  }

  useEffect(()=>{
    window.$(".select2bs4").select2({
      theme: "bootstrap4",
    });

    document.title = 'Pesquisar Feriados - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')
  }, []);

  const limparInterface = () => {
    setStartDate(null);
    setEndDate(null);
    setDescricao("");
    setObservacoes("");
    setResultado({}); 
  }

  const excluirFeriado = (id) => {
    if(window.confirm("Deseja excluir o feriado?") === true){
      const token = Autenticar.getLocalToken();
            
      const requestOptions = {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + token
          },
      };

      const url = window.servidor + "/v1/protegido/feriado/excluir/" + id;

      fetch(url, requestOptions)
        .then((response) => {
          // console.log('response', response)
          if(response.status !== 200){
            throw new Error("Falha ao excluir feriado.");
          } else {
            alert("Feriado excluído com sucesso.");
            // limparInterface();
            pesquisar();
          }
          
        })
        .catch(erro => console.log(erro));
    }
  }


  return (
    <>
      <div className="modal fade" id="changePageModal" tabIndex={-1} aria-labelledby="changePageModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <form className="modal-content" onSubmit={(e)=>{
            e.preventDefault();
            let page = parseInt(document.getElementById("changePageModalInput").value);
            if(page > 0 && page <= parseInt(resultado?.totalPaginas)){
              handleChangePage(page-1);
              // document.getElementById("changePageModalInput").value = "";
              window.$("#changePageModal").modal("hide");
            }
          }}>
            <div className="modal-header">
              <h5 className="modal-title" id="changePageModalLabel">Insira o nº da página</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <input className="form-control" id="changePageModalInput" type="number" onWheel={(e) => e.target.blur()} placeholder={`1 - ${resultado?.totalPaginas}`}/>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
              <button type="submit" className="btn btn-primary">Ir</button>
            </div>
          </form>
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Pesquisa de Feriados</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Pesquisa de Feriados</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12" onSubmit={handlePesquisar}>
          <div className="card">
            <div className="card-body">
              <h4 className='my-3'>Pesquisa de Feriados</h4>
              <div className='my-3 row'>
                <div className='col'>
                  <label htmlFor="datepickerStartDate">Data Inicial</label>
                  <div className='d-flex flex-column'>
                    <DatePicker id="datepickerStartDate" className="form-control" 
                      placeholderText='Selecione a data inicial da pesquisa...'
                      selected={startDate} onChange={(date) => {
                        setStartDate(date);
                      }} 
                      strictParsing
                      locale={ptBR} dateFormat="dd/MM/yyyy" />
                  </div>
                </div>
                <div className='col'>
                  <label htmlFor="datepickerEndDate">Data Final</label>
                  <div className='d-flex flex-column'>
                    <DatePicker id="datepickerEndDate" className="form-control" 
                      placeholderText='Selecione a data final da pesquisa...'
                      selected={endDate} onChange={(date) => {
                        setEndDate(date);
                      }} 
                      strictParsing
                      locale={ptBR} dateFormat="dd/MM/yyyy" />
                  </div>
                </div>
              </div>
              <div className='my-3 row'>
                <div className='col'>
                  <label htmlFor='inputPesquisaDescricao'>Descrição/Nome</label>
                  <input id="inputPesquisaDescricao" type="text" className='form-control d-flex flex-column' value={descricao} onChange={(e)=>setDescricao(e.target.value)} placeholder={"Digite a descrição/nome do feriado..."} />
                </div>
                <div className='col'>
                  <label htmlFor='inputPesquisaObservacoes'>Observações</label>
                  <input id="inputPesquisaObservacoes" type="text" className='form-control d-flex flex-column' value={observacoes} onChange={(e)=>setObservacoes(e.target.value)} placeholder={"Digite as observações a pesquisar..."} />
                </div>
              </div>
              <div className='row'>
                <button type='submit' className='btn btn-primary mx-2'>Pesquisar</button>
                <button type='button' className='btn btn-outline-primary mx-2' onClick={limparInterface}>Limpar</button>
              </div>
              <hr />
              <div className='my-3'>
                {
                  (pesquisando) ?
                  <div className='pt-5'>
                    <LoadingElement />
                  </div> :
                    <></>
                }
                {
                  resultado?.elementos != null && resultado?.elementos.length > 0 ?
                  <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Nome/Descrição</th>
                            <th scope="col">Data</th>
                            <th scope="col">Observações</th>
                            <th scope="col" className="text-center">Editar</th>
                            <th scope="col" className="text-center">Excluir</th>
                        </tr>
                    </thead>
                    <tbody className="align-middle">
                        {resultado.elementos && resultado.elementos.map((elemento) => {
                            return <tr key={elemento.id}>
                                <th scope="row">{elemento.id}</th>
                                <td>{elemento.descricao}</td>
                                <td>{isValid(parseISO(elemento?.data)) ? parseISO(elemento?.data).toLocaleDateString('pt-BR') : ""}</td>
                                <td>{elemento.observacoes}</td>
                                <td className="text-center"><Link to={`/feriados/editar/${elemento.id}`} className="btn btn-success"><i className="fas fa-edit"></i></Link></td>
                                <td className="text-center"><button type="button" className="btn btn-danger" onClick={()=>{excluirFeriado(elemento.id)}}><i className="fas fa-trash"></i></button></td>
                            </tr>
                        })}
                    </tbody>
                  </table> :
                  <></>
                }
                {
                  (nenhumEncontrado) ?
                  <p className='text-center'>Nenhum feriado encontrado</p> :
                  <></>
                }
              </div>
            </div>
            <div className='m-3 text-right'>
              <Link className="btn btn-primary" to={"/feriados/novo"}>Novo Feriado</Link>
            </div>
          </div>
          <div className='px-2 row justify-content-between'>
            {
              resultado?.elementos != null && resultado?.elementos.length > 0 ?
                <nav aria-label="Page navigation">
                  <ul className="pagination row px-2">
                    {

                      getPagination().map((pag, index) => {
                        return <React.Fragment key={index}>
                          {pag}
                        </React.Fragment>
                      })

                    }
                  </ul>
                </nav> 
                : <div></div>
            }
            <select id="comboboxPageSize" className="form-control col-md-1 text-center" value={pageSize} onChange={handleChangePageSize}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
          </select>
          </div>

        </form>
        
      </div>
    
    </>
  )
}
