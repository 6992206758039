import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Autenticar from '../../login/Autenticar';
import Requisicao from '../../helpers/Requisicao';
import { addDays } from 'date-fns';
import Utilidades from '../../helpers/Utilidades';

export default function NovoProcesso(props) {
  
  const navigate = useNavigate();
  const location = useLocation();

  const usuario = props.usuario;
  // const autenticado = props.autenticado;

  const [unidadeLista, setUnidadeLista] = useState([]);
  const [unidadeId, setUnidadeId] = useState(null);
  const [unidadeProcesso, setUnidadeProcesso] = useState({});
  const [leisLicitacao, setLeisLicitacao] = useState([]);
  const [modalidadesLicitacao, setModalidadesLicitacao] = useState([]);
  const [tiposAnalise, setTiposAnalise] = useState([]);

  const [leiLicitacao, setLeiLicitacao] = useState("");
  const [modalidade, setModalidade] = useState("");
  const [tipoDaAnalise, setTipoDaAnalise] = useState("");

  const [numeroProcesso, setNumeroProcesso] = useState("");
  const [volumesProcesso, setVolumesProcesso] = useState("");
  const [objeto, setObjeto] = useState("");
  const [valorEstimado, setValorEstimado] = useState(0);

  const [orgaosLista, setOrgaosLista] = useState([]);
  const [orgao, setOrgao] = useState(null);

  const [dataEntradaProcesso, setDataEntradaProcesso] = useState(new Date());

  // const [processoSalvo, setProcessoSalvo] = useState({});


  const carregarOrgaosLista = () => {
    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/unidade/getorgaos";

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setOrgaosLista(data);

      })
      .catch((erro) => console.log(erro));
  };

  const carregarUnidadesLista = () => {
    // Requisicao.getListaUnidades()
    Requisicao.getListaUnidadesPorSetor("FISCALIZACAO_E_CONTROLE")
      .then((data) => {
        setUnidadeLista(data);
      })
      .catch((erro) => console.log(erro));

  };

  const carregarLeis = () => {
    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/analise/leislicitacao";

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLeisLicitacao(data);
      })
      .catch((erro) => console.log(erro));
  };

  const carregarModalidades = () => {
    Requisicao.getListaModalidadesLicitacaoAtivas()
      .then((data) => {
        setModalidadesLicitacao(data);
      })
      .catch((erro) => console.log(erro));
  };

  const carregarTipos = () => {
    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      }
    };

    const url = window.servidor + "/v1/protegido/analise/tiposanalise/origem";

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setTiposAnalise(data);
      })
      .catch((erro) => console.log(erro));
  };

  // useEffect(() => {
  //   carregarUnidadesLista();
  // }, []);

  // carregar dados complementares da analise
  useEffect(() => {
    carregarOrgaosLista();
    carregarUnidadesLista();
    carregarLeis();
    carregarModalidades();
    carregarTipos();
  }, []);



  // preencher comboboxUnidades
  useEffect(() => {
    window.$("#comboboxUnidades").empty();
    let combobox = document.querySelector("#comboboxUnidades").options;


    combobox.add(new Option("Selecione a Unidade", "-1"));
    unidadeLista.forEach((element) => {
      combobox.add(new Option(element.nome, element.id));
    });

    combobox[0].disabled = true;
    if(props.usuario?.unidadeId != null){
      for (let index = 0; index < combobox.length; index++) {
        if(parseInt(combobox[index].value) === parseInt(props.usuario?.unidadeId)){
          combobox.selectedIndex = index;
        }      
      }
      setUnidadeId(props.usuario?.unidadeId);
    } else{
      combobox.selectedIndex = 0;
    }
    // console.log(combobox);
  }, [unidadeLista, props.usuario?.unidadeId]);

  // preencher comboboxLeiLicitacao
  useEffect(() => {
    window.$("#comboboxLeiLicitacao").empty();
    let combobox = document.querySelector("#comboboxLeiLicitacao").options;

    combobox.add(new Option("Selecione a Lei da Licitação", "-1"));
    leisLicitacao.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    combobox[0].disabled = true;
    combobox.selectedIndex = 0;

  }, [leisLicitacao]);

  // preencher comboboxTipoAnalise
  useEffect(() => {
    window.$("#comboboxTipoAnalise").empty();
    let combobox = document.querySelector("#comboboxTipoAnalise").options;

    combobox.add(new Option("Selecione o Tipo da Análise", "-1"));
    tiposAnalise.forEach((element) => {
      // if(usuario.tipoUsuario.toString() === "SCF_ANALISTA_UDCI" || usuario.tipoUsuario.toString() === "SCF_REVISOR_UDCI"){
      //   if(element.value !== "REVISAO_ANALISE_DE_REQUISITO_MINIMO" && 
      //      element.value !== "REVISAO_ANALISE_DE_CONFORMIDADE" &&
      //      element.value !== "ANALISE_PREVIA"){
      //         combobox.add(new Option(element.nome, element.value));
      //   }
      // } else {
      //   combobox.add(new Option(element.nome, element.value));
      // }
      if(usuario?.tipoUsuario.toString() === "ADMINISTRADOR" || 
        usuario?.tipoUsuario.toString() === "SCF_ADMINISTRADOR" || 
        usuario?.tipoUsuario.toString() === "SCF_ANALISTA_UC" || 
        usuario?.tipoUsuario.toString() === "SCF_REVISOR_UC"){
          combobox.add(new Option(element.nome, element.value));
      } else {
        if(element.value !== "ANALISE_PREVIA"){
          combobox.add(new Option(element.nome, element.value));
        }
      }
    });

    combobox[0].disabled = true;
    combobox.selectedIndex = 0;

  }, [tiposAnalise, usuario.tipoUsuario]);

  // preencher comboboxOrgaoSolicitante
  useEffect(() => {
    window.$("#comboboxOrgaoSolicitante").empty();
    let combobox = document.querySelector("#comboboxOrgaoSolicitante").options;

    combobox.add(new Option("Selecione o Órgão Solicitante", "-1"));
    orgaosLista.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    combobox[0].disabled = true;
    combobox.selectedIndex = 0;

  }, [orgaosLista]);


  // Atualizar comboboxModalidadeLicitacao sempre que comboboxLeiLicitacao muda
  useEffect(() => {
    window.$("#comboboxModalidadeLicitacao").empty();
    let combobox = document.querySelector("#comboboxModalidadeLicitacao").options;

    combobox.add(new Option("Selecione a Modalidade da Licitação/Procedimento", "-1"));
    modalidadesLicitacao.forEach((element) => {
      if(leiLicitacao === element.leiValue){
        combobox.add(new Option(element.nome, element.value));
      }
    });

    combobox[0].disabled = true;
    combobox.selectedIndex = 0;
  }, [leiLicitacao, modalidadesLicitacao]);

  // Atualizar unidadeProcesso sempre que unidadeId muda
  useEffect(()=>{
    unidadeLista.forEach((u) => {
      if(parseInt(u.id) === parseInt(unidadeId)){
        setUnidadeProcesso(u);
      }
    });
  }, [unidadeId, unidadeLista]);

  // DOM setup
  useEffect(() => {
    window.$(".select2bs4").select2({
      theme: "bootstrap4",
    });
    window.$('#comboboxUnidades').on('change', function(e) {
      setUnidadeId(e.target.value);
    });
    window.$("#comboboxLeiLicitacao").on('change', function(e) {
      setLeiLicitacao(e.target.value);
    });
    window.$("#comboboxModalidadeLicitacao").on('change', function(e) {
      setModalidade(e.target.value);
    });
    window.$("#comboboxTipoAnalise").on('change', function(e) {
      setTipoDaAnalise(e.target.value);
    });
    window.$("#comboboxOrgaoSolicitante").on('change', function(e) {
      setOrgao(e.target.value);
    });

    document.title = 'Cadastrar Processo - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')

  }, []);

  // desabilitar e habilitar a combobox de Unidades baseado no tipo de usuario
  useEffect(() => {
    const tiposUsuarioListaLiberada = ["ADMINISTRADOR", "SCF_ADMINISTRADOR", "SCF_ANALISTA_UC", "SCF_REVISOR_UC"];
    const comboboxUnidades = document.getElementById("comboboxUnidades");

    if(tiposUsuarioListaLiberada.includes(props.usuario?.tipoUsuario)){
      // console.log(true, props.usuario?.tipoUsuario);
      comboboxUnidades.disabled = false;
    } else {
      // console.log(false, props.usuario?.tipoUsuario);
      comboboxUnidades.disabled = true;
    }
  }, [props.usuario?.tipoUsuario]);

  const handleNumeroProcessoChange = (e) => {
    setNumeroProcesso(e.target.value);
  }

  const handleVolumesProcessoChange = (e) => {
    setVolumesProcesso(e.target.value);
  }

  const handleObjetoChange = (e) => {
    setObjeto(e.target.value);
  }
  
  // const handleValorEstimadoChange = (e) => {
  //   // let valor = valor.replace(/\b(0(?!\b))+/g, "");
  //   // if(valor === ""){
  //   //   valor = '0';
  //   // }
  //   let valor = e.target.value.replace(/^0+/, '');
  //   if(valor.length === 0){
  //     valor = '0';
  //   }
  //   if(valor.charAt(0) === '.' || valor.charAt(0) === ','){
  //     valor = '0' + valor;
  //   }
  //   setValorEstimado(valor);
  // }

  const validateDate = () => {
    if(dataEntradaProcesso?.getFullYear() === new Date().getFullYear()){
      document.getElementById("datepickerDataEntradaProcesso").classList.remove("is-invalid");
      document.getElementById("inputDataEntradaFeedback").classList.remove("d-block");
      document.getElementById("inputDataEntradaFeedback").classList.add("d-none");
    } else {
      document.getElementById("datepickerDataEntradaProcesso").classList.add("is-invalid");
      document.getElementById("inputDataEntradaFeedback").classList.add("d-block");
      document.getElementById("inputDataEntradaFeedback").classList.remove("d-none");
    }
  }

  // teste
  // useEffect(()=>{
  //   console.log("unidadeProcesso", unidadeProcesso);
  //   console.log("leiLicitacao", leiLicitacao);
  //   console.log("modalidade", modalidade);
  //   console.log("tipoDaAnalise", tipoDaAnalise);
  //   console.log("numeroProcesso", numeroProcesso);
  //   console.log("dataEntradaProcesso", dataEntradaProcessoString);
  //   console.log("volumesProcesso", volumesProcesso);
  //   console.log("objeto", objeto);
  //   console.log("valorEstimado", valorEstimado);
  // }, [unidadeProcesso, leiLicitacao, modalidade, tipoDaAnalise, numeroProcesso, dataEntradaProcesso, volumesProcesso, objeto, valorEstimado]);

  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(validateForm()){
      gravar();
    }
  }

  const numeroProcessoValido = (input) => {
    let regex = /^[0-9]+\/[0-9]+$/i;
    return regex.test(input);
  }

  const validateForm = () => {
    // implementar validação dos formulários
    let valido = true;
    let alerta = "Preencha os campos abaixo corretamente:\n";
    if(unidadeProcesso == null || unidadeProcesso?.id == null){
      valido = false;
      alerta += "Selecione a unidade responsável.\n";
      document.getElementById('comboboxUnidades').classList.add("is-invalid");
    } else {
      document.getElementById('comboboxUnidades').classList.remove("is-invalid");
    }

    if(orgao == null){
      valido = false;
      alerta += "Selecione o Órgão Solicitante.\n"
      document.getElementById('comboboxOrgaoSolicitante').classList.add("is-invalid");
    } else {
      document.getElementById('comboboxOrgaoSolicitante').classList.remove("is-invalid");
    }

    if(!numeroProcessoValido(numeroProcesso)){
      valido = false;
      alerta += "Insira corretamente o Número do Processo. Deve conter apenas números e uma barra. Ex: 123456/2023"
      document.getElementById('inputNumeroProcesso').classList.add("is-invalid");
    } else {
      document.getElementById('inputNumeroProcesso').classList.remove("is-invalid");
    }

    if(leiLicitacao === ""){
      valido = false;
      alerta += "Selecione a Lei de Licitação.\n";
      document.getElementById('comboboxLeiLicitacao').classList.add("is-invalid");
    } else {
      document.getElementById('comboboxLeiLicitacao').classList.remove("is-invalid");
    }

    if(modalidade === ""){
      valido = false;
      alerta += "Selecione a Modalidade da Licitação/Procedimento.\n";
      document.getElementById('comboboxModalidadeLicitacao').classList.add("is-invalid");
    } else {
      document.getElementById('comboboxModalidadeLicitacao').classList.remove("is-invalid");
    }

    if(tipoDaAnalise === ""){
      valido = false;
      alerta += "Selecione o tipo da Análise.\n";
      document.getElementById('comboboxTipoAnalise').classList.add("is-invalid");
    } else {
      document.getElementById('comboboxTipoAnalise').classList.remove("is-invalid");
    }

    if(!valido){
      alert(alerta);
    }

    return valido;
  }

  const limparInterface = () => {
    console.log("Limpando Interface.")
    window.$("#comboboxLeiLicitacao").val("-1").trigger("change");
    window.$("#comboboxTipoAnalise").val("-1").trigger("change");
    window.$("#comboboxOrgaoSolicitante").val("-1").trigger("change");
    setLeiLicitacao("");
    setModalidade("");
    setTipoDaAnalise("");
    setNumeroProcesso("");
    setVolumesProcesso("");
    setObjeto("");
    setOrgao("");
    setValorEstimado(0);
    setDataEntradaProcesso(new Date());
  }

  // useEffect(()=>{
  //   console.log('orgao', orgao)
  // }, [orgao])

  const gravar = () => {
    const dados = {
      "numero": numeroProcesso,
      "volumeseApensos": volumesProcesso,
      "objeto": objeto,
      "favorecido": null,
      "cnpjFavorecido": null,
      "unidadeId": unidadeProcesso.id,
      "orgaoSolicitante": orgao,
      "analise": {
        "dataEntrada": dataEntradaProcesso.toJSON(),
        "unidadeOrigemId": unidadeProcesso.id,
        "dataAnalise": null,
        "leiLicitacao": leiLicitacao,
        "modalidadeLicitacao": modalidade,
        "tipoAnalise": tipoDaAnalise,
        "valorEstimado": valorEstimado,
        "filaAnalise": (tipoDaAnalise === "MANIFESTACAO") ? "EM_MANIFESTACAO" : "EM_ANALISE",
        "usuarioUltimaEdicao": usuario.nome,
        "statusAnalise": "EM_EDICAO",
        "desativada": false,
        "isModeloSet": false,
        "unidadeResponsavelId": unidadeProcesso.id
      }
    };
    
    const token = Autenticar.getLocalToken();
            
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(dados)
    };

    const url = window.servidor + "/v1/protegido/processo/novo"

    fetch(url, requestOptions)
        .then((response) => {
          console.log('response', response);
          // if(response.status === 200){
          //   alert("Processo cadastrado com sucesso.");
          // } else {
          //   throw new Error("Falha no cadastro do Processo.")
          // }
          if(response.status !== 200){
            throw new Error("Falha no cadastro do Processo.")
          }
          return response.json();
        })
        // .then((response) => response.json())
        .then((data) => {
          console.log('data', data);
          // setProcessoSalvo(data);
          alert("Processo cadastrado com sucesso.");
          limparInterface();
          navigate("/analise/editar/" + data, { state:{referrer:location}, replace:true });
        })
        .catch(erro => console.log(erro));
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Cadastro de Processo</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Cadastro de Processo</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12" onSubmit={submitForm}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor='comboboxUnidades'>Unidade de Controle Responsável</label>
                    <select id="comboboxUnidades" className="form-control select2bs4" style={{ width: '100%' }}>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor='comboboxOrgaoSolicitante'>Órgão Solicitante</label>
                    <select id="comboboxOrgaoSolicitante" className="form-control select2bs4" style={{ width: '100%' }}>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label htmlFor="inputNumeroProcesso">Número do Processo</label>
                  <input type="text" className="form-control" id="inputNumeroProcesso" pattern='^[0-9]+/[0-9]+$' placeholder="Digite o número do processo" autoComplete='off'
                    onChange={handleNumeroProcessoChange} value={numeroProcesso} required/>
                </div>
                <div className="col-6">
                  <label htmlFor="inputVolumesProcesso">Volumes e Apensos do Processo</label>
                  <input type="text" className="form-control" id="inputVolumesProcesso" placeholder="Digite os volumes do processo" onChange={handleVolumesProcessoChange} value={volumesProcesso} required />
                </div>
              </div>
              <div className="form-group my-2">
                <label htmlFor="inputDescricao">Descrição do Objeto</label>
                <textarea id="inputDescricao" className="form-control" rows={4} placeholder="Descreva o objeto conforme descrito no item 1 do Termo de Referência." onChange={handleObjetoChange} value={objeto} required />
              </div>
              <div className="row">
                <div className="col-6">
                  <label htmlFor="comboboxLeiLicitacao">Lei de Licitações/Serviços</label>
                  <select id="comboboxLeiLicitacao" className="form-control select2bs4" style={{ width: '100%' }}>
                  </select>
                </div>
                <div className="col-6">
                  <label htmlFor="comboboxModalidadeLicitacao">Modalidades de Licitações/Procedimentos</label>
                  <select id="comboboxModalidadeLicitacao" className="form-control select2bs4" defaultValue={"placeholder"} style={{ width: '100%' }}>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <label htmlFor="comboboxTipoAnalise">Tipo de Análise</label>
                  <select id="comboboxTipoAnalise" className="form-control select2bs4" style={{ width: '100%' }}>
                  </select>
                </div>
                <div className='col-3 form-group'>
                  <label htmlFor="datepickerDataEntradaProcesso">Data de Entrada</label>
                  <div className='d-flex flex-column'>
                    <DatePicker id="datepickerDataEntradaProcesso" className="form-control" 
                      selected={dataEntradaProcesso} 
                      onChange={(date) => {
                        setDataEntradaProcesso(date);
                        validateDate();
                      }} 
                      autoComplete='off'
                      placeholderText="Clique para selecionar..."
                      strictParsing
                      maxDate={addDays(new Date(), 1)}
                      onCalendarClose={() => validateDate()} 
                      aria-describedby="inputDataEntradaFeedback"
                      locale={ptBR} dateFormat="dd/MM/yyyy" required />
                  </div>
                  <div id="inputDataEntradaFeedback" className="text-sm text-danger d-none">
                    Verifique se a data de entrada está correta.
                  </div>
                </div>
                <div className="col-4">
                  <label htmlFor="inputValorEstimado">Valor</label>
                  <input type="number" id="inputValorEstimado" className="form-control" 
                    // onChange={handleValorEstimadoChange} 
                    onChange={(e) => {
                      if(e.target.value < 0){
                        e.preventDefault();
                      } else {
                        setValorEstimado(Utilidades.inputNumberFormatado(e.target.value))   
                      }
                    }}
                    onKeyDown={(e) => {
                      if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                        e.preventDefault();
                      }
                    }}
                    value={valorEstimado} onWheel={(e) => e.target.blur()} required/>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end px-3 pb-3">
                <button type="button" className="btn btn-primary mx-2" onClick={limparInterface}>Limpar</button>
                <button type="submit" className="btn btn-primary">Salvar</button>
            </div>
          </div>
        </form>
      </div>

    </>
  )
}
