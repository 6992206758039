import React, { useCallback, useEffect } from 'react'
import Alerts from '../helpers/Alerts';
import Utilidades from '../helpers/Utilidades';
import Requisicao from '../helpers/Requisicao';

export default function AlertasElement({ usuario }) {

  const deveMostrarAlertaAnalisesNaoFinalizadas = useCallback( () => {
    let aanf = localStorage.getItem('alertaAnalisesNaoFinalizadas');

    let obj = Utilidades.isValidJSONString(aanf) ? JSON.parse(aanf) : null;

    if(obj == null){
      return true;
    }

    if(usuario?.login !== obj?.usuario){
      return true;
    }

    if(usuario?.login === obj?.usuario && Date.now() > (obj?.ultimaVerificacao + 1000*60*60*24)){
      return true;
    }

    return false;
  }, [usuario.login]);

  useEffect(() => {
    if(deveMostrarAlertaAnalisesNaoFinalizadas()){
      Requisicao.getQtdAnalisesAbertasIndividual(21)
      .then(data => {
        if(data != null && typeof data === "number" && data > 0){
          Alerts.warning("Você possui análises que estão em aberto há mais de três semanas. Clique <a href='/analise/filas/individual/abertas?dias=21' style='color: black; font-weight: bold;'>aqui</a> para listá-las.", "alertPlaceholder");
        }
        localStorage.setItem('alertaAnalisesNaoFinalizadas', JSON.stringify({usuario: usuario.login, ultimaVerificacao: Date.now()}));
      })
    }
  }, [deveMostrarAlertaAnalisesNaoFinalizadas, usuario.login])

  return (
    // <div className='content-wrapper p-1' id="alertPlaceholder" style={{minHeight: '0px !important'}}>
    <div className='content-wrapper p-1' id="alertPlaceholder">
    </div>
  )
}
