import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import LoadingElement from '../../components/LoadingElement';
import Requisicao from '../../helpers/Requisicao';
import EnumUtils from '../../helpers/EnumUtils';
import { addDays, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import Autenticar from '../../login/Autenticar';
import Utilidades from '../../helpers/Utilidades';

export default function AnaliseEditarDadosBasicos({ usuario, autenticado }) {
  const { analiseId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [salvando, setSalvando] = useState(false);

  const [analise, setAnalise] = useState(null);
  const [processo, setProcesso] = useState(null);

  const [leisLicitacao, setLeisLicitacao] = useState([]);
  const [modalidadesLicitacao, setModalidadesLicitacao] = useState([]);
  const [tiposAnalise, setTiposAnalise] = useState([]);

  const [leiLicitacao, setLeiLicitacao] = useState("");
  const [modalidade, setModalidade] = useState("");
  const [tipoDaAnalise, setTipoDaAnalise] = useState("");

  const [valorEstimado, setValorEstimado] = useState(0);

  const [dataEntrada, setDataEntrada] = useState(new Date());


  const carregarAnalise = (id) => {
    setLoading(true);
    Requisicao.getAnalisePorId(id)
      .then(async (data) => {
        if (data != null) {
          if (data?.processoId !== null) {
            carregarProcessoDadosBasicos(data.processoId);
          }
          setDataEntrada(parseISO(data?.dataEntrada));
          let valor = data?.valorEstimado.trim().replaceAll(",", ".");
          setValorEstimado(Number(valor));
          setAnalise(data);
          setLeiLicitacao(data?.leiLicitacao ?? "");
          setModalidade(data?.modalidadeLicitacao ?? "");
          setTipoDaAnalise(data?.tipoAnalise ?? "");
        } else {
          alert("Análise de Origem não econtrada ou você não tem permissão para acessá-la.");
          navigate("/processo/pesquisar", { state: { referrer: location }, replace: true });
        }
      })
      .catch((erro) => console.log(erro));
  }

  const carregarProcessoDadosBasicos = (id) => {
    setLoading(true);
    Requisicao.getProcessoDadosBasicosPorId(id)
      .then(async (data) => {
        data.orgaoNome = await EnumUtils.getNomeOrgao(data.orgaoSolicitante)
          .catch(erro => {
            console.log('erro', erro);
            data.orgaoNome = data.orgaoSolicitante;
          });
        setProcesso(data);
        setLoading(false);
      })
      .catch((erro) => console.log(erro));
  };

  const carregarLeis = () => {
    Requisicao.getListaLeisLicitacao()
      .then((data) => {
        setLeisLicitacao(data);
      })
      .catch((erro) => console.log(erro));
  };

  const carregarModalidades = () => {
    Requisicao.getListaModalidadesLicitacao()
      .then((data) => {
        setModalidadesLicitacao(data);
      })
      .catch((erro) => console.log(erro));
  };

  const carregarTipos = () => {
    Requisicao.getListaTiposAnalise()
      .then((data) => {
        setTiposAnalise(data);
      })
      .catch((erro) => console.log(erro));
  };

  const isAdministrador = () => {
    if (usuario?.tipoUsuario === "ADMINISTRADOR") {
      return true;
    }
    return false;
  }

  const isAdministradorOuAdministradorSCF = () => {
    if (usuario?.tipoUsuario === "ADMINISTRADOR" || usuario?.tipoUsuario === "SCF_ADMINISTRADOR") {
      return true;
    }
    return false;
  }

  const isAdministradorSetorResponsavel = () => {
    if (usuario?.tipoUsuario === "SCF_ADMINISTRADOR") {
      // if (isMesmaUnidade() && isResponsavelUDCI()) {
      return true;
    }
    return false;
  }

  const isRevisorUC = () => {
    if (usuario?.tipoUsuario === "SCF_REVISOR_UC") {
      return true;
    }
    return false;
  }

  const isResponsavelUDCI = () => {
    if (usuario?.tipoUsuario === "SCF_REVISOR_UDCI") {
      return true;
    }
    return false;
  }

  const isMesmaUnidade = () => {
    if (parseInt(usuario?.unidadeId) === parseInt(analise?.unidadeResponsavelId)) {
      // if (parseInt(usuario?.unidadeId) === parseInt(analise?.unidadeOrigemId)) {
      return true;
    }
    return false;
  }

  const possuiPermissao = () => {
    if((usuario?.permissoes?.includes("PERM_SCF_EDITAR_DADOSBASICOS_ANALISE") && isMesmaUnidade())){
      return true;
    }
    return false;
  }



  // carregar dados complementares da analise
  useEffect(() => {
    if (autenticado) {
      carregarAnalise(analiseId);
      carregarLeis();
      carregarModalidades();
      carregarTipos();
    }

    document.title = 'Alterar Dados Básicos da Análise - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')

    // eslint-disable-next-line
  }, [analiseId, usuario?.tipoUsuario]);

  // preencher comboboxLeiLicitacao
  useEffect(() => {
    if (!loading) {
      window.$("#comboboxLeiLicitacao").empty();
      let combobox = document.querySelector("#comboboxLeiLicitacao").options;

      combobox.add(new Option("Selecione a Lei da Licitação", "-1"));
      leisLicitacao.forEach((element) => {
        combobox.add(new Option(element.nome, element.value));
      });

      combobox[0].disabled = true;
      if (leiLicitacao != null) {
        for (let i = 0; i < combobox.length; i++) {
          if (leiLicitacao === combobox[i].value) {
            // combobox.selectedIndex = i;
            window.$("#comboboxLeiLicitacao").val(combobox[i].value).trigger("change");

          }
        }
      } else {
        combobox.selectedIndex = 0;
      }
    }

  }, [loading, leisLicitacao, leiLicitacao]);

  // Atualizar comboboxModalidadeLicitacao sempre que comboboxLeiLicitacao muda
  useEffect(() => {
    if (!loading) {

      window.$("#comboboxModalidadeLicitacao").empty();
      let combobox = document.querySelector("#comboboxModalidadeLicitacao").options;

      combobox.add(new Option("Selecione a Modalidade da Licitação/Procedimento", "-1"));
      modalidadesLicitacao.forEach((element) => {
        if (leiLicitacao === element.leiValue) {
          combobox.add(new Option(element.nome, element.value));
        }
      });

      combobox[0].disabled = true;

      if (modalidade != null) {
        for (let i = 0; i < combobox.length; i++) {
          if (modalidade === combobox[i].value) {
            // combobox.selectedIndex = i;
            window.$("#comboboxModalidadeLicitacao").val(combobox[i].value).trigger("change");

          }
        }
      } else {
        combobox.selectedIndex = 0;
      }
    }

  }, [loading, leiLicitacao, modalidadesLicitacao, modalidade]);

  // preencher comboboxTipoAnalise
  useEffect(() => {
    if (!loading) {

      window.$("#comboboxTipoAnalise").empty();
      let combobox = document.querySelector("#comboboxTipoAnalise").options;

      combobox.add(new Option("Selecione o Tipo da Análise", "-1"));

      // if(analise?.analiseOrigemId != null) {
      //   if(parseInt(analiseId) === parseInt(analise?.analiseOrigemId)){
      //     tiposAnalise.forEach((element) => {
      //       if(usuario?.tipoUsuario.toString() === "ADMINISTRADOR" || 
      //         usuario?.tipoUsuario.toString() === "SCF_ADMINISTRADOR" || 
      //         usuario?.tipoUsuario.toString() === "SCF_ANALISTA_UC" || 
      //         usuario?.tipoUsuario.toString() === "SCF_REVISOR_UC"){
      //           combobox.add(new Option(element.nome, element.value));
      //       } else {
      //         if(element.value !== "ANALISE_PREVIA"){
      //           combobox.add(new Option(element.nome, element.value));
      //         }
      //       }
      //     });
      //   } else {
      //     tiposAnalise.forEach((element) => {
      //       if (usuario?.tipoUsuario.toString() === "ADMINISTRADOR" ||
      //         usuario?.tipoUsuario.toString() === "SCF_ADMINISTRADOR" ||
      //         usuario?.tipoUsuario.toString() === "SCF_ANALISTA_UC" ||
      //         usuario?.tipoUsuario.toString() === "SCF_REVISOR_UC") {
      //         combobox.add(new Option(element.nome, element.value));
      //       } else {
      //         if (element.value === "MANIFESTACAO") {
      //           combobox.add(new Option(element.nome, element.value));
      //         }
      //       }
      //     });
      //   }
      // }

      tiposAnalise.forEach((element) => {
        combobox.add(new Option(element.nome, element.value));
      });

      combobox[0].disabled = true;

      if (tipoDaAnalise != null) {
        for (let i = 0; i < combobox.length; i++) {
          if (tipoDaAnalise === combobox[i].value) {
            // combobox.selectedIndex = i;
            window.$("#comboboxTipoAnalise").val(combobox[i].value).trigger("change");
          }
        }
      } else {
        combobox.selectedIndex = 0;
      }
    }


  }, [loading, tiposAnalise, usuario.tipoUsuario, tipoDaAnalise, analiseId, analise?.analiseOrigemId]);

  useEffect(() => {
    if (analise != null) {
      // if(!(isAdministrador() || isAdministradorSetorResponsavel() || (isMesmaUnidade() && isResponsavelUDCI()) || (isMesmaUnidade() && isRevisorUC()))){
      if (!(isAdministradorOuAdministradorSCF() || (isMesmaUnidade() && (analise?.statusAnalise === "EM_EDICAO" || analise?.statusAnalise === "EM_VERIFICACAO")))) {
        alert("Acesso negado.");
        limparInterface();
        navigate("/analise/" + analiseId, { state: { referrer: location }, replace: true });
      }
    }
    // eslint-disable-next-line
  }, [analise, analiseId, location, navigate])


  // DOM setup
  useEffect(() => {
    if (!loading) {
      window.$(".select2bs4").select2({
        theme: "bootstrap4",
      });
      window.$("#comboboxLeiLicitacao").on('change', function (e) {
        setLeiLicitacao(e.target.value);
        setModalidade("");
      });
      window.$("#comboboxModalidadeLicitacao").on('change', function (e) {
        setModalidade(e.target.value);
      });
      window.$("#comboboxTipoAnalise").on('change', function (e) {
        setTipoDaAnalise(e.target.value);
      });
    }

  }, [loading]);


  // const handleValorEstimadoChange = (e) => {
  //   setValorEstimado(e.target.value);
  // }

  // useEffect(() => {
  //   console.log('leiLicitacao', leiLicitacao)
  // }, [leiLicitacao])

  // useEffect(() => {
  //   console.log('modalidade', modalidade)
  // }, [modalidade])

  // useEffect(() => {
  //   console.log('tipoDaAnalise', tipoDaAnalise)
  // }, [tipoDaAnalise])



  const submitForm = (e) => {
    e.preventDefault();

    if (analise?.leiLicitacao !== leiLicitacao || analise?.modalidadeLicitacao !== modalidade || analise?.tipoAnalise !== tipoDaAnalise) {
      if (window.confirm("Alterar a Lei da Licitação, a Modalidade da Licitação ou o Tipo da Análise apagará os dados da análise preenchidos anteriormente.\n" +
        "Você está certo disso?") === true) {
        let dados = analise;
        dados.filaAnalise = "EM_ANALISE";
        dados.dataAnalise = null;

        dados.textoInicial = null;
        dados.textoInicialHTML = null;
        dados.textoFinal = null;
        dados.textoFinalHTML = null;
        dados.textoInicialExtra = null;
        dados.textoInicialExtraHTML = null;
        dados.observacaoTextoInicialExtra = null;
        dados.textoFinalExtra = null;
        dados.textoFinalExtraHTML = null;
        dados.textoEncaminhamento = null;
        dados.textoEncaminhamentoHTML = null;
        dados.observacaoTextoFinalExtra = null;
        dados.blocos = null;
        dados.camposComplementares = null;

        dados.dataCriacao = new Date().toJSON();
        dados.dataSaida = null;

        dados.matriculaAnalista = null;
        dados.matriculaOrdenadorDespesas = null;
        dados.matriculaResponsavel = null;
        dados.matriculaRevisor = null;
        dados.modeloAnalise = null;
        dados.modeloAnaliseId = null;
        dados.nome = null;
        dados.nomeAnalista = null;
        dados.nomeOrdenadorDespesas = null;
        dados.nomeResponsavel = null;
        dados.nomeRevisor = null;
        dados.statusAnalise = "EM_EDICAO";
        dados.unidadeDestino = null;
        dados.unidadeDestinoId = null;

        dados.desativada = false;
        dados.isModeloSet = false;

        if (validateForm()) {
          gravar(dados);
        }

      }
    } else {
      if (validateForm()) {
        gravar(analise);
      }
    }


  }

  const validateForm = () => {
    // implementar validação dos formulários
    if (leiLicitacao !== "" && modalidade !== "" && tipoDaAnalise !== "") {
      return true;
    }
    // if(
    //   leisLicitacao.includes(leiLicitacao) &&
    //   modalidadesLicitacao.includes(modalidade) &&
    //   tiposAnalise.includes(tipoDaAnalise)
    // ) {
    //   return true;
    // }
    alert("Selecione a Lei, a Modalidade e o tipo da Análise.");
    return false;
  }

  const limparInterface = () => {
    // console.log("Limpando Interface.")
    setLeiLicitacao("");
    setModalidade("");
    setTipoDaAnalise("");
    setValorEstimado(0);
    setDataEntrada(new Date());
    window.$("#comboboxLeiLicitacao").val("-1").trigger("change");
    window.$("#comboboxTipoAnalise").val("-1").trigger("change");
  }

  const getDados = (a) => {

    let dados = {
      ...a,
      processoId: processo.id,
      dataEntrada: dataEntrada.toJSON(),
      unidadeOrigemId: processo.unidade.id,
      dataAnalise: a?.dataAnalise,
      leiLicitacao: (isAdministradorOuAdministradorSCF() || (isMesmaUnidade() && isResponsavelUDCI()) || possuiPermissao()) && leisLicitacao?.some(e => e.value === leiLicitacao) ? leiLicitacao : analise?.leiLicitacao,
      modalidadeLicitacao: (isAdministradorOuAdministradorSCF() || (isMesmaUnidade() && isResponsavelUDCI()) || possuiPermissao()) && modalidadesLicitacao.some(e => e.value === modalidade) ? modalidade : analise?.modalidadeLicitacao,
      tipoAnalise: (isAdministradorOuAdministradorSCF() || (isMesmaUnidade() && isResponsavelUDCI()) || possuiPermissao()) && tiposAnalise.some(e => e.value === tipoDaAnalise) ? tipoDaAnalise : analise?.tipoDaAnalise,
      valorEstimado: valorEstimado,
      filaAnalise: analise?.filaAnalise,
      statusAnalise: analise?.statusAnalise,
      usuarioUltimaEdicao: usuario.nome,
      analiseOrigemId: analise?.analiseOrigemId
    };

    // console.log('leisLicitacao', leisLicitacao)
    // console.log('modalidadesLicitacao', modalidadesLicitacao)
    // console.log('tiposAnalise', tiposAnalise)

    // console.log('leiLicitacao', leiLicitacao)
    // console.log('modalidade', modalidade)
    // console.log('tipoDaAnalise', tipoDaAnalise)

    // console.log('leisLicitacao?.some(e => e.value === leiLicitacao)', leisLicitacao?.some(e => e.value === leiLicitacao))
    // console.log('modalidadesLicitacao.some(e => e.value === modalidade)', modalidadesLicitacao.some(e => e.value === modalidade))
    // console.log('tiposAnalise.some(e => e.value === tipoDaAnalise)', tiposAnalise.some(e => e.value === tipoDaAnalise))

    return dados;
  }

  const gravar = (analiseParaSalvar) => {
    setSalvando(true);
    window.toastr["info"]("Salvando...", '', { timeOut: 0, extendedTimeout: 0 });

    const dados = getDados(analiseParaSalvar);
    console.log('dados', dados)

    const token = Autenticar.getLocalToken();

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      body: JSON.stringify(dados)
    };

    const url = window.servidor + "/v1/protegido/analise/editar/dadosbasicos"

    fetch(url, requestOptions)
      .then((response) => {
        // console.log('response', response);
        if (response.status !== 200) {
          setSalvando(false);
          throw new Error("Falha na alteração dos dados da análise da análise.")
        }
        return response.json();
      })
      // .then((response) => response.json())
      .then((data) => {
        // console.log('analise editada', data);
        // setProcessoSalvo(data);
        setSalvando(false);
        window.toastr.clear();
        if (data != null) {
          // alert("Analise salva com sucesso.");
          window.toastr["success"]("Salvo com sucesso!");
          // limparInterface();
          navigate("/analise/" + analiseId, { state: { referrer: location }, replace: false });
        } else {
          window.toastr["error"]("Falha ao salvar. Verifique sua conexão com a internet.");
        }
      })
      .catch(erro => {
        setSalvando(false);
        window.toastr.clear();
        window.toastr["error"]("Falha ao salvar. Verifique sua conexão com a internet.");
        console.log(erro);
      });
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Alterar Dados Básicos da Análise</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Alterar Dados Básicos da Análise</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12">
          {
            loading ?
              <div className="card">
                <div className="card-body">
                  <LoadingElement />
                </div>
              </div> :
              <>
                <div className="card">
                  <div className="card-body">
                    {
                      processo != null ?
                        <>
                          <div className="col px-0 mx-0">
                            <h3 className='text-center'>Processo Nº {processo.numero}</h3>
                            <br />
                            <div className='row my-2'>
                              <div className='col-4'>
                                <label>Volumes do Processo</label>
                                <p>
                                  {processo.volumeseApensos}
                                </p>
                              </div>
                              <div className='col-4'>
                                <label>Unidade Responsável</label>
                                <p>
                                  {processo.unidade.nome}
                                </p>
                              </div>
                              <div className='col-4'>
                                <label>Órgão Solicitante</label>
                                <p>
                                  {processo?.orgaoNome}
                                </p>
                              </div>
                            </div>
                            <div className='row my-2'>
                              <div className='col'>
                                <label>Descrição do Objeto</label>
                                <p>
                                  {processo.objeto}
                                </p>
                              </div>
                            </div>
                          </div>
                        </> :
                        <></>
                    }
                    <hr />
                    <h4 className='text-center'>Dados Básicos da Análise</h4>
                    <div className="row">
                      <div className="col-6">
                        <label htmlFor="comboboxLeiLicitacao">Lei de Licitações/Serviços</label>
                        <select id="comboboxLeiLicitacao" className="form-control select2bs4" style={{ width: '100%' }}
                          disabled={(isAdministradorOuAdministradorSCF() || (isMesmaUnidade() && isResponsavelUDCI()) || possuiPermissao() ? false : true)}>
                        </select>
                      </div>
                      <div className="col-6">
                        <label htmlFor="comboboxModalidadeLicitacao">Modalidades de Licitações/Procedimentos</label>
                        <select id="comboboxModalidadeLicitacao" className="form-control select2bs4" defaultValue={"placeholder"} style={{ width: '100%' }}
                          disabled={(isAdministradorOuAdministradorSCF() || (isMesmaUnidade() && isResponsavelUDCI()) || possuiPermissao() ? false : true)}>
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <label htmlFor="comboboxTipoAnalise">Tipo de Análise</label>
                        <select id="comboboxTipoAnalise" className="form-control select2bs4" style={{ width: '100%' }}
                          disabled={(isAdministradorOuAdministradorSCF() || (isMesmaUnidade() && isResponsavelUDCI()) || possuiPermissao() ? false : true)}>
                        </select>
                      </div>
                      <div className='col-3'>
                        <label htmlFor="datepickerDataEntrada">Data de Entrada</label>
                        <div className='d-flex flex-column'>
                          <DatePicker id="datepickerDataEntrada" className="form-control" selected={dataEntrada} onChange={(date) => setDataEntrada(date)} maxDate={addDays(new Date(), 1)} locale={ptBR} dateFormat="dd/MM/yyyy" required />
                        </div>
                      </div>
                      <div className="col-4">
                        <label htmlFor="inputValorEstimado">Valor</label>
                        <input type="number" id="inputValorEstimado" className="form-control" value={valorEstimado} 
                          // onChange={handleValorEstimadoChange} 
                          onChange={(e) => {
                            if(e.target.value < 0){
                              e.preventDefault();
                            } else {
                              setValorEstimado(Utilidades.inputNumberFormatado(e.target.value))   
                            }
                          }}
                          onKeyDown={(e) => {
                            if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                              e.preventDefault();
                            }
                          }}
                          onWheel={(e) => e.target.blur()} required />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-row justify-content-end px-3 pb-3">
                    {
                      salvando ?
                        <>
                          <button type="button" className="btn btn-primary mx-2" disabled>Limpar</button>
                          <button type='button' className='btn btn-primary' disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;Salvando...
                          </button>
                        </> :
                        <>
                          <button type="button" className="btn btn-primary mx-2" onClick={limparInterface}>Limpar</button>
                          <button type="button" className="btn btn-primary" onClick={submitForm}>Salvar</button>
                        </>
                    }
                  </div>
                </div>
              </>
          }
        </form>
      </div>
    </>
  )
}
