import React, { useEffect } from 'react'
import './App.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import RoutesSistema from './modules/setores/sistema/RoutesSistema';
import Logout from './modules/login/Logout';
import RoutesSFC from './modules/setores/fiscalizacaoecontrole/RoutesSFC';
import RoutesAuditoria from './modules/setores/auditoria/RoutesAuditoria';
import RoutesSubgeral from './modules/setores/subgeral/RoutesSubgeral';
import RoutesConsulta from './modules/setores/consulta/RoutesConsulta';

export default function Rotas(props) {
  const autenticado = props.autenticado;
  const usuario = props.usuario;
  const navigate = useNavigate();
  const location = useLocation();

  const version = 'v1.3.0'; // 
  useEffect(() => {
    if(!autenticado){
      navigate("/login", {state: {referrer: location}, replace: false});
    }
  }, [autenticado, location, navigate]);


  return (
    <div className="wrapper container-fluid px-0">

      <Routes>
        <Route path="/logout" element={<Logout logout={props.logout}/>}></Route>
      </Routes>
      
      { autenticado && usuario?.setor === "SISTEMA" ? <RoutesSistema usuario={usuario} autenticado={autenticado} version={version} /> : <></>}
      { autenticado && usuario?.setor === "AUDITORIA" ? <RoutesAuditoria usuario={usuario} autenticado={autenticado} version={version} /> : <></>}
      { autenticado && usuario?.setor === "FISCALIZACAO_E_CONTROLE" ? <RoutesSFC usuario={usuario} autenticado={autenticado} version={version} /> : <></>}
      { autenticado && usuario?.setor === "SUBCONTROLADORIA_GERAL" ? <RoutesSubgeral usuario={usuario} autenticado={autenticado} version={version} /> : <></>}
      { autenticado && usuario?.setor === "CONSULTA" ? <RoutesConsulta usuario={usuario} autenticado={autenticado} version={version} /> : <></>}

    </div>
  )
}
