import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import ptBR from 'date-fns/locale/pt-BR';
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Autenticar from '../../login/Autenticar';
import Requisicao from '../../helpers/Requisicao';
import EnumUtils from '../../helpers/EnumUtils';
import { addDays } from 'date-fns';
import Utilidades from '../../helpers/Utilidades';

export default function NovaAnalise(props) {
  
  const navigate = useNavigate();
  const location = useLocation();

  const { processoId } = useParams();

  const usuario = props.usuario;

  const [processo, setProcesso] = useState(null);  

  const [leisLicitacao, setLeisLicitacao] = useState([]);
  const [modalidadesLicitacao, setModalidadesLicitacao] = useState([]);
  const [tiposAnalise, setTiposAnalise] = useState([]);

  const [leiLicitacao, setLeiLicitacao] = useState("");
  const [modalidade, setModalidade] = useState("");
  const [tipoDaAnalise, setTipoDaAnalise] = useState("");

  const [valorEstimado, setValorEstimado] = useState(0);

  const [dataEntradaProcesso, setDataEntradaProcesso] = useState(new Date());

  // const [processoSalvo, setProcessoSalvo] = useState({});

  const carregarProcessoDadosBasicos = (id) => {
    Requisicao.getProcessoDadosBasicosPorId(id)
      .then(async (data) => {
          data.orgaoNome = await EnumUtils.getNomeOrgao(data.orgaoSolicitante)
          .catch(erro => {
            console.log('erro', erro);
            data.orgaoNome = data.orgaoSolicitante;
          });
        setProcesso(data);
      })
      .catch((erro) => console.log(erro));
  };

  const carregarLeis = () => {
    Requisicao.getListaLeisLicitacao()
      .then((data) => {
        setLeisLicitacao(data);
      })
      .catch((erro) => console.log(erro));
  };

  const carregarModalidades = () => {
    Requisicao.getListaModalidadesLicitacaoAtivas()
      .then((data) => {
        setModalidadesLicitacao(data);
      })
      .catch((erro) => console.log(erro));
  };

  const carregarTipos = () => {
    Requisicao.getListaTiposAnaliseOrigem()
      .then((data) => {
        setTiposAnalise(data);
      })
      .catch((erro) => console.log(erro));
  };

  // carregar dados complementares da analise
  useEffect(() => {
    carregarProcessoDadosBasicos(processoId);
    carregarLeis();
    carregarModalidades();
    carregarTipos();
    // eslint-disable-next-line
  }, [processoId]);

  // preencher comboboxLeiLicitacao
  useEffect(() => {
    window.$("#comboboxLeiLicitacao").empty();
    let combobox = document.querySelector("#comboboxLeiLicitacao").options;

    combobox.add(new Option("Selecione a Lei da Licitação", "-1"));
    leisLicitacao?.forEach((element) => {
      combobox.add(new Option(element.nome, element.value));
    });

    combobox[0].disabled = true;
    combobox.selectedIndex = 0;

  }, [leisLicitacao]);

  // Atualizar comboboxModalidadeLicitacao sempre que comboboxLeiLicitacao muda
  useEffect(() => {
    window.$("#comboboxModalidadeLicitacao").empty();
    let combobox = document.querySelector("#comboboxModalidadeLicitacao").options;

    combobox.add(new Option("Selecione a Modalidade da Licitação/Procedimento", "-1"));
    modalidadesLicitacao?.forEach((element) => {
      if(leiLicitacao === element.leiValue){
        combobox.add(new Option(element.nome, element.value));
      }
    });

    combobox[0].disabled = true;
    combobox.selectedIndex = 0;
  }, [leiLicitacao, modalidadesLicitacao]);

  // preencher comboboxTipoAnalise
  useEffect(() => {
    window.$("#comboboxTipoAnalise").empty();
    let combobox = document.querySelector("#comboboxTipoAnalise").options;

    combobox.add(new Option("Selecione o Tipo da Análise", "-1"));
    tiposAnalise?.forEach((element) => {
      if(usuario?.tipoUsuario.toString() === "ADMINISTRADOR" || 
        usuario?.tipoUsuario.toString() === "SCF_ADMINISTRADOR" || 
        usuario?.tipoUsuario.toString() === "SCF_ANALISTA_UC" || 
        usuario?.tipoUsuario.toString() === "SCF_REVISOR_UC"){
          combobox.add(new Option(element.nome, element.value));
      } else {
        if(element.value !== "ANALISE_PREVIA"){
          combobox.add(new Option(element.nome, element.value));
        }
      }
    });

    combobox[0].disabled = true;
    combobox.selectedIndex = 0;

  }, [tiposAnalise, usuario.tipoUsuario]);


  // DOM setup
  useEffect(() => {
    window.$(".select2bs4").select2({
      theme: "bootstrap4",
    });
    window.$("#comboboxLeiLicitacao").on('change', function(e) {
      setLeiLicitacao(e.target.value);
    });
    window.$("#comboboxModalidadeLicitacao").on('change', function(e) {
      setModalidade(e.target.value);
    });
    window.$("#comboboxTipoAnalise").on('change', function(e) {
      setTipoDaAnalise(e.target.value);
    });

    document.title = 'Nova Análise - Sistema de Controle Interno';
    return (() => document.title = 'Sistema de Controle Interno')

  }, []);

  
  // const handleValorEstimadoChange = (e) => {
  //   // setValorEstimado(e.target.value);
  //   let valor = e.target.value.replace(/^0+/, '');
  //   if(valor.length === 0){
  //     valor = '0';
  //   }
  //   if(valor.charAt(0) === '.' || valor.charAt(0) === ','){
  //     valor = '0' + valor;
  //   }
  //   setValorEstimado(valor);
  // }

  const validateDate = () => {
    if(dataEntradaProcesso?.getFullYear() === new Date().getFullYear()){
      document.getElementById("datepickerDataEntradaProcesso").classList.remove("is-invalid");
      document.getElementById("inputDataEntradaFeedback").classList.remove("d-block");
      document.getElementById("inputDataEntradaFeedback").classList.add("d-none");
    } else {
      document.getElementById("datepickerDataEntradaProcesso").classList.add("is-invalid");
      document.getElementById("inputDataEntradaFeedback").classList.add("d-block");
      document.getElementById("inputDataEntradaFeedback").classList.remove("d-none");
    }
  }


  const submitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(validateForm()){
      gravar();
    }
  }

  const validateForm = () => {
    // implementar validação dos formulários
    let valido = true;
    let alerta = "Preencha os campos corretamente.\n";
    if(leiLicitacao === ""){
      valido = false;
      alerta += "Selecione a Lei de Licitação.\n";
      document.getElementById('comboboxLeiLicitacao').classList.add("is-invalid");
    } else {
      document.getElementById('comboboxLeiLicitacao').classList.remove("is-invalid");
    }

    if(modalidade === ""){
      valido = false;
      alerta += "Selecione a Modalidade da Licitação/Procedimento.\n";
      document.getElementById('comboboxModalidadeLicitacao').classList.add("is-invalid");
    } else {
      document.getElementById('comboboxModalidadeLicitacao').classList.remove("is-invalid");
    }

    if(tipoDaAnalise === ""){
      valido = false;
      alerta += "Selecione o tipo da Análise.\n";
      document.getElementById('comboboxTipoAnalise').classList.add("is-invalid");
    } else {
      document.getElementById('comboboxTipoAnalise').classList.remove("is-invalid");
    }

    if(!valido){
      alert(alerta);
    }

    return valido;
  }

  const limparInterface = () => {
    console.log("Limpando Interface.")
    setLeiLicitacao("");
    setModalidade("");
    setTipoDaAnalise("");
    setValorEstimado(0);
    setDataEntradaProcesso(new Date());
    window.$("#comboboxLeiLicitacao").val("-1").trigger("change");
    window.$("#comboboxTipoAnalise").val("-1").trigger("change");
  }

  const gravar = () => {
    const dados = {
      "processoId": processo.id,
      "dataEntrada": dataEntradaProcesso.toJSON(),
      "unidadeOrigemId": usuario?.unidadeId ?? processo.unidade.id,
      "dataAnalise": null,
      "leiLicitacao": leiLicitacao,
      "modalidadeLicitacao": modalidade,
      "tipoAnalise": tipoDaAnalise,
      "valorEstimado": valorEstimado,
      // "filaAnalise": (tipoDaAnalise === "MANIFESTACAO") ? "EM_MANIFESTACAO" : "EM_ANALISE",
      "filaAnalise": "EM_ANALISE",
      "usuarioUltimaEdicao": usuario.nome,
      "statusAnalise": "EM_EDICAO",
      "desativada": false,
      "isModeloSet": false,
      "unidadeResponsavelId": usuario?.unidadeId ?? processo.unidade.id
    };
    
    const token = Autenticar.getLocalToken();
            
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        },
        body: JSON.stringify(dados)
    };

    const url = window.servidor + "/v1/protegido/analise/nova"

    fetch(url, requestOptions)
        .then((response) => {
          console.log('response', response);
          // if(response.status === 200){
          //   alert("Processo cadastrado com sucesso.");
          // } else {
          //   throw new Error("Falha no cadastro do Processo.")
          // }
          if(response.status !== 200){
            throw new Error("Falha no cadastro da análise.")
          }
          return response.json();
        })
        // .then((response) => response.json())
        .then((data) => {
          console.log('data', data);
          // setProcessoSalvo(data);
          alert("Analise cadastrado com sucesso.");
          limparInterface();
          navigate("/analise/editar/" + data, { state:{referrer:location}, replace:true });
        })
        .catch(erro => console.log(erro));
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Cadastro de Nova Análise</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to={"/"}>Página Inicial</Link>
                  </li>
                  <li className="breadcrumb-item active">Cadastro de Nova Análise</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <form className="col-md-12" onSubmit={submitForm}>
          <div className="card">
            <div className="card-body">
              {
                processo != null ? 
                <div className="col">
                  <div className='my-2'>
                    <h4>Processo Nº {processo.numero}</h4>
                  </div>
                  <div className='row my-2'>
                    <div className='col-6'>
                      <label>Volumes do Processo</label>
                      <p>
                        {processo.volumeseApensos}
                      </p>
                    </div>
                    <div className='col-6'>
                      <label>Unidade Responsável</label>
                      <p>
                        {processo.unidade.nome}
                      </p>
                    </div>
                  </div>
                  <div className='row my-2'>
                    <div className='col-6'>
                      <label>Órgão Solicitante</label>
                      <p>
                        {processo?.orgaoNome}
                      </p>
                    </div>
                    <div className='col-6'>
                      <label>Descrição do Objeto</label>
                      <p>
                        {processo.objeto}
                      </p>
                    </div>
                  </div>
                </div> :
                <></>
              }
              <div className="row">
                <div className="col-6">
                  <label htmlFor="comboboxLeiLicitacao">Lei de Licitações/Serviços</label>
                  <select id="comboboxLeiLicitacao" className="form-control select2bs4" style={{ width: '100%' }}>
                  </select>
                </div>
                <div className="col-6">
                  <label htmlFor="comboboxModalidadeLicitacao">Modalidades de Licitações/Procedimentos</label>
                  <select id="comboboxModalidadeLicitacao" className="form-control select2bs4" defaultValue={"placeholder"} style={{ width: '100%' }}>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <label htmlFor="comboboxTipoAnalise">Tipo de Análise</label>
                  <select id="comboboxTipoAnalise" className="form-control select2bs4" style={{ width: '100%' }}>
                  </select>
                </div>
                <div className='col-3 form-group'>
                  <label htmlFor="datepickerDataEntradaProcesso">Data de Entrada</label>
                  <div className='d-flex flex-column'>
                    <DatePicker id="datepickerDataEntradaProcesso" className="form-control" 
                      selected={dataEntradaProcesso} onChange={(date) => {
                        setDataEntradaProcesso(date);
                        validateDate();
                      }} 
                      strictParsing
                      onCalendarClose={() => validateDate()} 
                      maxDate={addDays(new Date(), 1)}
                      aria-describedby="inputDataEntradaFeedback"
                      locale={ptBR} dateFormat="dd/MM/yyyy" required />
                    <div id="inputDataEntradaFeedback" className="text-sm text-danger d-none">
                      Verifique se a data de entrada está correta.
                    </div>
                  </div>
                  {/* <div id="inputDataEntradaFeedback" className="invalid-feedback"> */}
                </div>
                <div className="col-4">
                  <label htmlFor="inputValorEstimado">Valor</label>
                  <input type="number" id="inputValorEstimado" className="form-control" 
                  // onChange={handleValorEstimadoChange} 
                  onChange={(e) => {
                    if(e.target.value < 0){
                      e.preventDefault();
                    } else {
                      setValorEstimado(Utilidades.inputNumberFormatado(e.target.value))   
                    }
                  }}
                  onKeyDown={(e) => {
                    if(e.key === '.' || e.key === 'e' || e.key === 'E' || e.key === '-'){
                      e.preventDefault();
                    }
                  }}
                  value={valorEstimado} onWheel={(e) => e.target.blur()} required/>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-end px-3 pb-3">
                <button type="button" className="btn btn-primary mx-2" onClick={limparInterface}>Limpar</button>
                <button type="submit" className="btn btn-primary">Salvar</button>
            </div>
          </div>
        </form>
      </div>

    </>
  )
}
