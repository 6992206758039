import React, { useEffect, useRef, useState } from 'react'

function getSelectionHtml() {
  var html = "";
  if (typeof window.getSelection != "undefined") {
      var sel = window.getSelection();
      if (sel.rangeCount) {
          var container = document.createElement("div");
          for (var i = 0, len = sel.rangeCount; i < len; ++i) {
              container.appendChild(sel.getRangeAt(i).cloneContents());
          }
          html = container.innerHTML;
      }
  } else if (typeof document.selection != "undefined") {
      if (document.selection.type === "Text") {
          html = document.selection.createRange().htmlText;
      }
  }
  return html;
}

function allCaps(html) {
  let upper = '';
  let j = 0;
  for (let i = 0; i < html.length; i++) {
    if(html[i] === '<'){
      j = html.indexOf('>', i);
      if(j !== -1){
        upper = upper + html.substring(i, j + 1);
        i = j;
      } else {
        if(html.indexOf("&nbsp;", i) !== i){
          upper = upper + html[i].toUpperCase();
        } else {
          upper = upper + html.substring(i, i + 5);
          i = i + 5;
        }
      }
    } else {
      if(html.indexOf("&nbsp;", i) !== i){
        upper = upper + html[i].toUpperCase();
      } else {
        upper = upper + html.substring(i, i + 5);
        i = i + 5;
      }
    }
  }
  // console.log('upper', upper)
  return upper;
}

const TabButton = function (context) {
  let ui = window.$.summernote.ui;

  // create button
  let button = ui.button({
    contents: '<i class="fas fa-indent"></i>',
    tooltip: 'Inserir tabulação',
    click: function () {
      context.invoke('editor.pasteHTML', '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;');
    }
  });

  return button.render();
}

const Uppercase = function (context) {
  let ui = window.$.summernote.ui;

  let button = ui.button({
    // contents: '<i class="fas fa-spell-check"></i>',
    contents: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-alphabet-uppercase" viewBox="0 0 16 16">
                <path d="M1.226 10.88H0l2.056-6.26h1.42l2.047 6.26h-1.29l-.48-1.61H1.707l-.48 1.61ZM2.76 5.818h-.054l-.75 2.532H3.51l-.75-2.532Zm3.217 5.062V4.62h2.56c1.09 0 1.808.582 1.808 1.54 0 .762-.444 1.22-1.05 1.372v.055c.736.074 1.365.587 1.365 1.528 0 1.119-.89 1.766-2.133 1.766h-2.55ZM7.18 5.55v1.675h.8c.812 0 1.171-.308 1.171-.853 0-.51-.328-.822-.898-.822H7.18Zm0 2.537V9.95h.903c.951 0 1.342-.312 1.342-.909 0-.591-.382-.954-1.095-.954H7.18Zm5.089-.711v.775c0 1.156.49 1.803 1.347 1.803.705 0 1.163-.454 1.212-1.096H16v.12C15.942 10.173 14.95 11 13.607 11c-1.648 0-2.573-1.073-2.573-2.849v-.78c0-1.775.934-2.871 2.573-2.871 1.347 0 2.34.849 2.393 2.087v.115h-1.172c-.05-.665-.516-1.156-1.212-1.156-.849 0-1.347.67-1.347 1.83Z"/>
              </svg>`,
    tooltip: 'Caixa alta',
    click: function () {
      // console.log('context', context);
      // console.log('window.getSelection()', window.getSelection());

      // let html = getSelectionHtml();
      // console.log('html', html);
      // console.log('allCaps', allCaps(html));

      let editable = context.modules.editor.$editable[0];
      // console.log('editable', editable);

      // let ut = window.getSelection().toString().toUpperCase();
      let ut = allCaps(getSelectionHtml());

      let range = window.getSelection().getRangeAt(0);
      // console.log('range', range)
      if(range.commonAncestorContainer.isSameNode(editable) ||
         editable.contains(range.commonAncestorContainer)){
           range.deleteContents();
          //  range.insertNode(document.createTextNode(ut));
          //  let el = document.createElement('div');
          //  el.innerHTML = ut;
          //  console.log('el', el)
          //  if(el.hasChildNodes()){

          //    let children = el.childNodes;
          //    children?.forEach(n => {
          //     console.log('n', n)
          //     range.insertNode(n);
          //    })
          //  }
          // let nodes =  new DOMParser().parseFromString('<div>' + ut + '</div>', 'text/html').body.getElementsByTagName("*");
          let nodes =  new DOMParser().parseFromString(ut, 'text/html').body.childNodes;
          // console.log('nodes', nodes)
          for (let i = 0; i < nodes.length; i++) {
          // let i = 0;
          // while(nodes[i] != null){
            let n = nodes[i].cloneNode(true);
            // console.log('n', n);
            range.insertNode(n);
            range.setStartAfter(n);
            
          }


          //  window.$(editable).trigger('input');
          editable.dispatchEvent(new Event('input', { 'bubbles': true }));
         }

      // console.log('range', range)

      // // let ut = context.$note.summernote('createRange').toString().toUpperCase();

      // // // let range = context.$note.summernote('createRange').getRangeAt(0);
      // // // range.deleteContents();
      // // // range.insertNode(document.createTextNode(ut));
      // let range2 = context.$note.summernote('createRange');
      // console.log('range2', range2)
      // let ut2 = range2.toString().toUpperCase();
      // // range2.deleteContents();
      // range2.insertNode(document.createTextNode(ut2));
      // // context.$note.summernote('insertNode', document.createTextNode(ut));



    }
  });

  return button.render();


}


export default function SummernoteEditor(props) {

  // const tempId = (props.id != null && props.id !== "") ? props?.id?.replaceAll('.', '_').replaceAll(' ', '') : undefined;

  // const id = tempId ?? Date.now().toString() + '-' + Math.floor(Math.random() * 100000000).toString();
  // console.log('id', id)

  // const [id, setId] = useState(
  //   (props.id != null && props.id !== "") ? 
  //     // props?.id?.replaceAll('.', '_').replaceAll(' ', '')
  //     props?.id?.replaceAll('.', '_').replaceAll(' ', '').replace(/[!"#\$%&'\(\)\*\+,/:;<=>\?@\[\\\]\^`\{\|\}~]/g,'')
  //     :
  //     Date.now().toString() + '-' + Math.floor(Math.random() * 100000000).toString()
  // )

  const id = (props.id != null && props.id !== "") ? 
                // props?.id?.replaceAll('.', '_').replaceAll(' ', '').replace(/[!"#\$%&'\(\)\*\+,/:;<=>\?@\[\\\]\^`\{\|\}~]/g,'')
                props?.id?.replaceAll('.', '_').replaceAll(' ', '').replace(/[!"#$%&'()*+,/:;<=>?@[\\\]^`{|}~]/g,'')
                :
                Date.now().toString() + '-' + Math.floor(Math.random() * 100000000).toString();

  const airMode = props.airMode ?? false;

  const saveOnLocalStorage = props.saveOnLocalStorage ?? false;

  const lang = props.lang ?? 'pt-BR';
  // const height = props.height ?? 170;
  const [height, setHeight] = useState(props.height ?? 170);
  const minHeight = props.minHeight ?? null;
  const maxHeight = props.maxHeight ?? null;
  const placeholder = props.placeholder ?? '';
  const codeSetState = props.codeSetState;
  const textSetState = props.textSetState;

  const onFocus = props.onFocus ?? null;
  const onBlur = props.onBlur ?? null;

  // const enableFontSizes = props.enableFontSizes ?? false;
  const disableHelp = props.disableHelp ?? false;


  const disableDragAndDrop = props.disableDragAndDrop ?? true;
  const shortcuts = props.shortcuts ?? true;

  const initialContent = props.initialContent != null ? props.initialContent :  '';
  // const value = props.value ?? initialContent;
  const createOnFocus = props.createOnFocus ?? false;

  const focus = props.focus ?? (createOnFocus ? true : false);

  const [created, setCreated] = useState(false);
  const [focused, setFocused] = useState(createOnFocus ? false : true);

  // const [code, setCode] = useState(initialContent);

  const classNameEditable = props.classNameEditable;
  const classNameEditor = props.classNameEditor;

  let editableElmt = useRef(null);
  // const editorElmt = null;



    // useEffect(() => {
    //   console.log('id', id)
    // }, [id])

  const style ={
    height: height ?? '170px',
    overflowY: 'auto',
    resize: 'vertical'
  }

  // const destroy = function() {
  //   window.$('#' + id).summernote('destroy');
  //   setFocused(false);
  //   setCreated(false);
  // }

  useEffect(() => {
    if (!created && focused) {
      // console.log('initialContent', initialContent)

      const onChange = saveOnLocalStorage ? 
        props.onChange != null ?
          function(contents, $editable){
            let fun = props.onChange;
            fun(contents, $editable);
            localStorage.setItem(id, contents);
          }
          : function(contents, $editable) {
            if(codeSetState != null) {
              codeSetState(contents);
              localStorage.setItem(id, contents);
            }
            if(textSetState != null) {
              textSetState($editable[0]?.textContent);
            }
          }
        : props.onChange ?? function(contents, $editable) {
          if(codeSetState != null) {
            // setCode(contents);
            codeSetState(contents);
          }
          if(textSetState != null) {
            textSetState($editable[0]?.textContent);
          }
          // console.log('$editable', $editable[0])
        }

      const onFocus = props.onFocus ?? function() {
        if(airMode){
          // console.log('editableElmt Focus', editableElmt.current)
          if(editableElmt.current != null){
            editableElmt.current?.addClass('p-2 border form-control border-primary');
          } else{
            window.$('#' + id)?.nextAll('div.note-editor:first')?.find('div.note-editing-area')?.find('[contenteditable]')?.addClass('p-2 border form-control border-primary');
          }
        }
      }
      const onBlur = props.onBlur ?? function() {
        if(airMode){
          // console.log('editableElmt Blur', editableElmt.current)
          if(editableElmt.current != null){
            editableElmt.current?.removeClass('border-primary');
          } else{
            window.$('#' + id)?.nextAll('div.note-editor:first')?.find('div.note-editing-area')?.find('[contenteditable]')?.removeClass('border-primary');
          }
        }
      }

      // const destroy = function() {
      //   window.$('#' + id).summernote('destroy');
      //   setFocused(false);
      //   setCreated(false);
      // }
      // console.log('airMode', airMode)
      const toolbar = props.toolbar ?? [
        ['edit', ['undo', 'redo']],
        ['myButtons', ['tab']],
        ['style', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
        // ['fontsize', ['fontsize']],
        ['transform', ['uppercase']],
        ['color', ['forecolor', 'backcolor']],
        ['paragraph', ['ul', 'ol']],
        ['tools', ['fullscreen', shortcuts && !disableHelp ? 'help' : null]]
      ];

      const popover = props.popover ?? {
        air: [
          ['style', ['bold', 'italic', 'underline', 'strikethrough', 'clear']],
          ['color', ['forecolor', 'backcolor']],
        ]
      }

      // const fontSizes = props.fontSizes ?? ['8', '10', '12', '14', '16', '18', '20', '24', '28', '32', '48'];
      const lineHeights = props.lineHeights ?? ['1.0', '1.25', '1.5', '1.75', '2.0'];

      window.$('#' + id).summernote({
        lang: lang,
        airMode: airMode,
        height: height,
        minHeight: minHeight,
        maxHeight: maxHeight,
        placeholder: placeholder,
        focus: focus,
        callbacks: {
          onChange: onChange,
          onFocus: (onFocus ? onFocus : null),
          onBlur: (onBlur ? onBlur : null),
          onPaste: function (e) {
            var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
            e.preventDefault();
            document.execCommand('insertText', false, bufferText);
          }
        },
        toolbar: toolbar,
        popover: popover,
        // fontSizes: fontSizes,
        lineHeights: lineHeights,
        disableDragAndDrop: disableDragAndDrop,
        shortcuts: shortcuts,
        tabsize: 5,
        tabDisable: true,
        buttons: {
          tab: TabButton,
          uppercase: Uppercase,
        },
        // spellCheck: false
      });

      editableElmt.current = window.$('#' + id)?.nextAll('div.note-editor:first')?.find('div.note-editing-area')?.find('[contenteditable]');

      // window.$('#' + id)?.nextAll('div.note-editor:first')?.find('div.note-editing-area')?.find('[contenteditable]').css('font-size','14pt');
      // window.$('#' + id).summernote('foreColor', 'black');
      window.$('#' + id).summernote('code', initialContent);
      // window.$('#' + id)?.nextAll('div.note-editor:first')?.find('div.note-editing-area')?.find('[contenteditable]')?.addClass('border form-control')
      if(classNameEditable != null && typeof classNameEditable === "string"){
        if(editableElmt.current != null){
          editableElmt.current?.addClass(classNameEditable);
        } else{
          window.$('#' + id)?.nextAll('div.note-editor:first')?.find('div.note-editing-area')?.find('[contenteditable]')?.addClass(classNameEditable);
        }
      }
      if(classNameEditor != null && typeof classNameEditor === "string"){
        window.$('#' + id)?.nextAll('div.note-editor:first')?.addClass(classNameEditor);
        // window.$('#' + id)?.nextAll('div.note-editor:first')?.find('div.note-editing-area')?.find('[contenteditable]')?.css(style);
        // window.$('#' + id)?.nextAll('div.note-editor:first')?.find('div.note-editing-area')?.css(style);
      }
      if(airMode){
        if(editableElmt.current != null){
          editableElmt.current?.addClass('resize-vertical');
          editableElmt.current.css('height', height);
        } else{
          window.$('#' + id)?.nextAll('div.note-editor:first')?.find('div.note-editing-area')?.find('[contenteditable]')?.addClass('resize-vertical');
          window.$('#' + id)?.nextAll('div.note-editor:first')?.find('div.note-editing-area')?.find('[contenteditable]').css('height', height);
        }
      }

      // console.log('elmt', window.$('#' + id));

      // window.$('#' + id).summernote('foreColor', 'black');


      setCreated(true);
      setFocused(true);
    }
  }, [
    id,
    lang,
    height,
    minHeight,
    maxHeight,
    placeholder,
    props.onChange,
    props.onFocus,
    props.onBlur,
    props.toolbar,
    props.fontSizes,
    props.lineHeights,
    props.popover,
    disableDragAndDrop,
    shortcuts,
    initialContent,
    codeSetState,
    textSetState,
    created,
    focused,
    onFocus,
    onBlur,
    focus,
    airMode,
    disableHelp,
    classNameEditable,
    classNameEditor,
    saveOnLocalStorage
  ])

  useEffect(() => {
    if(!created){
      document.getElementById(id).innerHTML = initialContent;
    }
  }, [created, initialContent, id])

  useEffect(() => {
    if(created){
      return (() => {
        window.$('#' + id).summernote('destroy');
        setFocused(false);
        setCreated(false);
      })
    }
  }, [created, id])


  return (
    <>
      <div id={id} className={"border form-control"} placeholder={placeholder}
        onFocus={() => {
          let elHeight = document.getElementById(id).offsetHeight;
          if(elHeight !== height && typeof elHeight === 'number'){
            setHeight(elHeight);
          }
          document.getElementById(id).innerHTML = '';
          setFocused(true);
          // console.log('focusDiv');
          // document.getElementById(id)?.classList.add("border-primary");
        }} 
        // onBlur={() => {
          // console.log('blurDiv');
          // document.getElementById(id)?.classList.remove("border-primary")
        // }} 
        contentEditable={true} 
        style={style}
        // dangerouslySetInnerHTML={{__html: editorCodeContent}}
        ></div>

    </>
  )
}
