import React from 'react'
import Tippy from '@tippyjs/react';
import TipoCampoConformidade from './TipoCampoConformidade';
import Utilidades from '../../../helpers/Utilidades';

export default function CampoConformidadeElement({ exibindo, campoConformidade, campo, changeCampoConformidade, deletarCampoConformidade, moveUp, moveDown }) {

  const tipo = campoConformidade?.tipoComplementarConformidade ?? '';

  // const month = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];

  const botoes = (
    <>
      <Tippy content="Mover para cima">
        <button className="btn col-1 text-info mx-2" type='button' onClick={() => moveUp(campoConformidade)}><span className='fas fa-chevron-up'></span></button>
      </Tippy>
      <Tippy content="Mover para baixo">
        <button className="btn col-1 text-info mx-2" type='button' onClick={() => moveDown(campoConformidade)}><span className='fas fa-chevron-down'></span></button>
      </Tippy>
      <Tippy content="Deletar">
        <button className="btn col-1 text-danger mx-2" type='button' onClick={() => deletarCampoConformidade(campoConformidade)}><span className='fa fa-trash'></span></button>
      </Tippy>
    </>
  )

  return (
    <>
      {
        (exibindo) ?
          <>
            <div className='col'>
              <div className='row px-0'>
                <div className='col'>
                  <label>
                    {TipoCampoConformidade.getNome(tipo)}
                  </label>
                </div>
              </div>
              {
                (tipo === "CONTRATO" || tipo === "ARP") ?
                  <div className='d-flex flex-column flex-md-row mb-2'>
                    <div className='col'>
                      <label>Objeto</label>
                      <p>{campoConformidade?.objeto ?? ''}</p>
                      </div>
                  </div> : <></>
              }
              <div className='d-flex flex-column flex-md-row mb-2'>
                {
                  (tipo === "CONTRATO") ?
                    <div className='col col-lg-4'>
                      <label>Nº Contrato</label>
                      <p>{campoConformidade?.numeroContrato ?? ''}</p>
                    </div> : <></>
                }
                {
                  (tipo === "ARP") ?
                    <div className='col col-lg-4'>
                      <label>Nº Ata de Registro de Preços</label>
                      <p>{campoConformidade?.numeroAta ?? ''}</p>
                    </div> : <></>
                }
                {
                  (tipo === "NOTA_DE_EMPENHO" || tipo === "CONTRATO") ?
                    <div className='col col-lg-4'>
                      <label>Nº Nota de Empenho</label>
                      <p>{campoConformidade?.numeroNotaEmpenho ?? ''}</p>
                    </div> : <></>
                }
                <div className='col col-lg-4'>
                  <label>Valor</label>
                  <p>{Utilidades.getValorFormatado(campoConformidade?.valor)}</p>
                </div>
              </div>
              <div className='d-flex flex-column flex-md-row mb-2'>
                <div className='col col-lg-4'>
                  <label>Razão Social</label>
                  <p>{campoConformidade?.razaoSocial ?? ''}</p>
                </div>
                <div className='col col-lg-4'>
                  <label>CPF / CNPJ</label>
                  <p>{Utilidades.adicionarMascaraCPFOuCNPJ(campoConformidade?.cnpj)}</p>
                </div>
              </div>
              {
                (tipo === "CONTRATO" || tipo === "ARP") ?
                  <>
                    <div className='d-flex flex-column flex-md-row mb-2'>
                      <div className='col col-lg-4'>
                        <label>Período de Vigência</label>
                        <p>{campoConformidade?.periodoVigencia ?? ''}</p>
                      </div>
                    </div>
                  </> : <></>
              }
            </div>
          </> :
          <>
            <div className='col'>
              <div className='row px-0'>
                <div className='col'>
                  <label>
                    {TipoCampoConformidade.getNome(tipo)}
                  </label>
                </div>
                <div className='col text-right'>
                  {botoes}
                </div>
              </div>
              {
                (tipo === "CONTRATO" || tipo === "ARP") ?
                  <div className='d-flex flex-column flex-md-row mb-2'>
                    <div className='col'>
                      <label>Objeto</label>
                      <textarea id={`textareaObjetoCampoConformidade_${campo.ordem}_${campoConformidade.ordem}`} className='form-control' rows={2} placeholder='Descrever o objeto...'
                        value={campoConformidade?.objeto ?? ''}
                        onChange={(e) => {
                          changeCampoConformidade({
                            ...campoConformidade,
                            objeto: e.target.value
                          })
                        }}
                      />
                    </div>
                  </div> : <></>
              }
              <div className='d-flex flex-column flex-md-row mb-2'>
                {
                  (tipo === "CONTRATO") ?
                    <div className='col col-lg-4'>
                      <label>Nº Contrato</label>
                      <input id={`inputNumeroContratoCampoConformidade_${campo.ordem}_${campoConformidade.ordem}`} type='text' className='form-control'
                        placeholder='Inserir o nº do Contrato...' autoComplete='off'
                        value={campoConformidade?.numeroContrato ?? ''}
                        onChange={(e) => {
                          changeCampoConformidade({
                            ...campoConformidade,
                            numeroContrato: e.target.value
                          })
                        }}
                      />
                    </div> : <></>
                }
                {
                  (tipo === "ARP") ?
                    <div className='col col-lg-4'>
                      <label>Nº Ata de Registro de Preços</label>
                      <input id={`inputNumeroAtaCampoConformidade_${campo.ordem}_${campoConformidade.ordem}`} type='text' className='form-control'
                        placeholder='Inserir o nº da Ata de Registro de Preços...' autoComplete='off'
                        value={campoConformidade?.numeroAta ?? ''}
                        onChange={(e) => {
                          changeCampoConformidade({
                            ...campoConformidade,
                            numeroAta: e.target.value
                          })
                        }}
                      />
                    </div> : <></>
                }
                {
                  (tipo === "NOTA_DE_EMPENHO" || tipo === "CONTRATO") ?
                    <div className='col col-lg-4'>
                      <label>Nº Nota de Empenho</label>
                      <input id={`inputNumeroNotaEmpenhoCampoConformidade_${campo.ordem}_${campoConformidade.ordem}`} type='text' className='form-control'
                        placeholder='Inserir o nº da Nota de Empenho...' autoComplete='off'
                        value={campoConformidade?.numeroNotaEmpenho ?? ''}
                        onChange={(e) => {
                          changeCampoConformidade({
                            ...campoConformidade,
                            numeroNotaEmpenho: e.target.value
                          })
                        }}
                      />
                    </div> : <></>
                }
                <div className='col col-lg-4'>
                  <label>Valor</label>
                  <input type='number' id={`inputValorCampoConformidade_${campo.ordem}_${campoConformidade.ordem}`} className='form-control'
                    placeholder='Insira o valor...'
                    onWheel={(e) => e.target.blur()}
                    value={campoConformidade?.valor ?? '0'}
                    onChange={(e) => {
                      if (e.target.value < 0) {
                        e.preventDefault();
                      } else {
                        changeCampoConformidade({
                          ...campoConformidade,
                          valor: Utilidades.inputNumberFormatado(e.target.value)
                        })
                      }
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                        e.preventDefault();
                      }
                    }} />
                </div>
              </div>
              <div className='d-flex flex-column flex-md-row mb-2'>
                <div className='col col-lg-4'>
                  <label>Razão Social</label>
                  <input id={`inputRazaoSocialCampoConformidade_${campo.ordem}_${campoConformidade.ordem}`} type='text' className='form-control'
                    placeholder='Inserir a Razão Social da Pessoa Física/Jurídica...' autoComplete='off'
                    value={campoConformidade?.razaoSocial ?? ''}
                    onChange={(e) => {
                      changeCampoConformidade({
                        ...campoConformidade,
                        razaoSocial: e.target.value
                      })
                    }}
                  />
                </div>
                <div className='col col-lg-4'>
                  <label>CPF / CNPJ</label>
                  <input id={`inputCnpjCampoConformidade_${campo.ordem}_${campoConformidade.ordem}`} type='text' className='form-control'
                    placeholder='Inserir o CPF ou CNPJ...' autoComplete='off'
                    value={campoConformidade?.cnpj ?? ''}
                    onChange={(e) => {
                      let cnpj = e.target.value?.replace(/[^\d]+/g, '');
                      changeCampoConformidade({
                        ...campoConformidade,
                        cnpj: cnpj
                      })
                    }}
                  />
                </div>
              </div>
              {
                (tipo === "CONTRATO" || tipo === "ARP") ?
                  <>
                    <div className='d-flex flex-column flex-md-row mb-2'>
                      <div className='col col-lg-4'>
                        <label>Período de Vigência</label>
                        <input id={`inputPeriodoVigencia_${campo.ordem}_${campoConformidade.ordem}`} type='text' className='form-control'
                          placeholder='Inserir o período de vigência...' autoComplete='off'
                          value={campoConformidade?.periodoVigencia ?? ''}
                          onChange={(e) => {
                            changeCampoConformidade({
                              ...campoConformidade,
                              periodoVigencia: e.target.value
                            })
                          }}
                        />

                      </div>
                    </div>
                  </> : <></>
              }
            </div>
          </>
      }
    </>
  )
}
