import { isValid } from "date-fns";
import Autenticar from "../login/Autenticar"

class Requisicao {

  // verificarAutenticacao(token){

  // }


  // getResource(url) { // criar um pop up/modal/nova janela pedindo para o usuário logar novamente dizendo que o login expirou
  //   const token = Autenticar.getLocalToken();

  // }

  getProcessoPorId(processoId) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();
  
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/processo/get/" + processoId;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
          // else {
          //   throw Error(response.status + " - " + response.statusText);
          // }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        })
    })
  }

  getProcessoDadosBasicosPorId(processoId) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();
  
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/processo/dadosbasicos/get/" + processoId;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        })
    })
  }

  getProcessoDadosBasicosPorNumero(numero) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const dados = {
        numero: numero
      }
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(dados),
      };
  
      const url = window.servidor + "/v1/protegido/processo/dadosbasicos/getpornumero";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
          if(response.status === 404) {
            reject(new Error("Processo não encontrado."));
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        })
    })
  }

  getAnaliseMaisRecentePorProcessoId(processoId) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();
  
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/processo/getanalisemaisrecente/" + processoId;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        })
    })
  }

  pesqusiarProcessoDadosBasicosPorNumero(numero) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();
  
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/processo/pesquisa/pornumero?numero=" + numero;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        })
    })
  }

  getAnalisePorId(analiseId) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();
  
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/analise/get/" + analiseId;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        })
    })
  }

  getAnaliseDadosBasicosPorId(analiseId) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();
  
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/analise/dadosbasicos/get/" + analiseId;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        })
    })
  }

  getListaAnalisesVinculadasPorAnaliseId(analiseId) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();
  
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/analise/vinculadas/" + analiseId;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro)
        });
    })
  }

  getFeriadoPorId(feriadoId) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();
  
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/feriado/get/" + feriadoId;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        })
    })
  }

  getFeriadosPorData(date) {
    
    return new Promise((resolve, reject) => {

      if(!isValid(date)){
        reject(new Error("Data inválida."));
      } else {
        const token = Autenticar.getLocalToken();
    
  
        const dados = {
          data: date.toISOString().substring(0,10)
        }
    
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          body: JSON.stringify(dados),
        };
    
        const url = window.servidor + "/v1/protegido/feriado/getpordata";
    
        fetch(url, requestOptions)
          .then((response) => {
            if(response.ok){
              return response.json();
            }
          })
          .then((data) => {
            resolve(data);
          })
          .catch((erro) => {
            reject(erro);
          })

      }
    })
  }

  getListaUsuarioPorUnidadeId(unidadeId){
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/usuario/listartodos/unidade/" + unidadeId;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro)
        });
    })
  }

  // getListaModelosLicitacao(){
  //   return new Promise((resolve, reject) => {
  //     const token = Autenticar.getLocalToken();
  
  //     const requestOptions = {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + token,
  //       }
  //     };
  
  //     const url = window.servidor + "/v1/protegido/modelosanalise/getall";
  
  //     fetch(url, requestOptions)
  //       .then((response) => {
  //         if(response.ok){
  //           return response.json();
  //         }
  //       })
  //       .then((data) => {
  //         resolve(data);
  //       })
  //       .catch((erro) => {
  //         reject(erro)
  //       });
  //   })
  // }

  getListaModelosLicitacaoPorTipo(tipo) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();
  
      const dados = {
        tipoAnalise: tipo
      }
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(dados),
      };
  
      const url = window.servidor + "/v1/protegido/modelosanalise/getlistbytipo";
      // const url = window.servidor + "/v1/protegido/modelosanalise/getlistaportipo";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro)
        });
    })
  }

  getListaModelosLicitacaoPorTipoELei(tipo, lei) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();
  
      const dados = {
        tipoAnalise: tipo,
        leiLicitacao: lei
      }
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(dados),
      };
  
      const url = window.servidor + "/v1/protegido/modelosanalise/getlistby";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro)
        });
    })
  }

  getModeloPorId(modeloId) {
      return new Promise((resolve, reject) => {
        const token = Autenticar.getLocalToken();
  
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        };
    
        const url = window.servidor + "/v1/protegido/modelosanalise/get/" + modeloId;
        fetch(url, requestOptions)
          .then((response) => {
            if(response.ok){
              return response.json();
            }
          })
          .then(data => {
            resolve(data);
          })
          .catch((erro) => {
            reject(erro);
          });
      });
  }

  getListaOrgaos() {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/unidade/getorgaos";

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getOrgao(value) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/unidade/getorgaos/" + value;

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaUnidades() {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/unidade/listarunidadesativas";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaUnidadesPorSetor(value) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/unidade/listarunidadesativas/setor/" + value;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getUnidadeById(id) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/unidade/get/" + id;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getNomeUnidadeById(id) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/unidade/get/nome/" + id;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.text();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaLeisLicitacao() {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/analise/leislicitacao";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getLeiLicitacao(value) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/analise/leislicitacao/get/" + value;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaModalidadesLicitacao() {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/analise/modalidadeslicitacao";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaModalidadesLicitacaoAtivas() {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/analise/modalidadeslicitacaoativas";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getModalidadeLicitacao(value) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/analise/modalidadeslicitacao/get/" + value;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaTiposAnalise() {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/analise/tiposanalise";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaTiposAnaliseOrigem() {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/analise/tiposanalise/origem";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaTiposAnaliseVinculada() {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/analise/tiposanalise/vinculada";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getTipoAnalise(value) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/analise/tiposanalise/get/" + value;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getTipoUsuario(value) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/usuario/tiposusuario/get/" + value;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaTiposUsuario() {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/usuario/tiposusuario";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaTiposUsuarioPorSetor(value) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/usuario/tiposusuario/porsetor/" + value;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaFilasAnalise() {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/analise/filasanalise";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getFilaAnalise(value) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/analise/filasanalise/get/" + value;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaStatusAnalise() {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/analise/statusanalise";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getStatusAnalise(value) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/analise/statusanalise/get/" + value;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaTiposEncaminhamento() {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/encaminhamento/gettipos";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getTipoEncaminhamento(value) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/encaminhamento/gettipos/get/" + value;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaOrgaosOrcamentarios() {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/unidade/getorgaosorcamentarios";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getOrgaoOrcamentario(value) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/unidade/getorgaosorcamentarios/get/" + value;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaConsultoriasJuridicas() {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/unidade/getconsultoriasjuridicas";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getConsultoriaJuridica(value) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/unidade/getconsultoriasjuridicas/get/" + value;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  updateStatusAnalise(statusAnalise, analiseId) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();
  
      const dados = {
        statusAnalise: statusAnalise,
        analiseId: analiseId
      }
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(dados),
      };
  
      const url = window.servidor + "/v1/protegido/analise/alterarstatus";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            // try {
            //   response.json()
            //     .then(json => {return json});
            // } catch (error) {
            //   response.text()
            //     .then(text => {return text})
            //     .catch(()=>{return response.status})
              
            // }
            return response.text();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro)
        });
    })
  }

  updateFilaAnalise(filaAnalise, analiseOrigemId) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();
  
      const dados = {
        filaAnalise: filaAnalise,
        analiseOrigemId: analiseOrigemId
      }
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(dados),
      };
  
      const url = window.servidor + "/v1/protegido/analise/alterarfila";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            // response.json()
            //   .then(json => {return json})
            //   .catch(response.text().then(text => {return text}).catch(()=>{return response.status}))
            return response.text();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro)
        });
    })
  }


  // getQtdByFilaAnaliseAndTipoAnalise(filaAnalise, tipoAnalise) {
  //   return new Promise((resolve, reject) => {
  //     const token = Autenticar.getLocalToken();
  
  //     const dados = {
  //       filaAnalise: filaAnalise,
  //       tipoAnalise: tipoAnalise
  //     }
  
  //     const requestOptions = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + token,
  //       },
  //       body: JSON.stringify(dados),
  //     };
  
  //     const url = window.servidor + "/v1/protegido/filas/quantidades";
  
  //     fetch(url, requestOptions)
  //       .then((response) => {
  //         if(response.ok){
  //           return response.json();
  //         }
  //       })
  //       .then((data) => {
  //         resolve(data);
  //       })
  //       .catch((erro) => {
  //         reject(erro);
  //       });
  //   })
  // }

  // getQtdByFilaAnalise(filaAnalise) {
  //   return new Promise((resolve, reject) => {
  //     const token = Autenticar.getLocalToken();
  
  //     const dados = {
  //       filaAnalise: filaAnalise
  //     }
  
  //     const requestOptions = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + token,
  //       },
  //       body: JSON.stringify(dados),
  //     };
  
  //     const url = window.servidor + "/v1/protegido/filas/quantidades";
  
  //     fetch(url, requestOptions)
  //       .then((response) => {
  //         if(response.ok){
  //           return response.json();
  //         }
  //       })
  //       .then((data) => {
  //         resolve(data);
  //       })
  //       .catch((erro) => {
  //         reject(erro);
  //       });
  //   })
  // }

  // getQtdByTipoAnalise(tipoAnalise) {
  //   return new Promise((resolve, reject) => {
  //     const token = Autenticar.getLocalToken();
  
  //     const dados = {
  //       tipoAnalise: tipoAnalise
  //     }
  
  //     const requestOptions = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + token,
  //       },
  //       body: JSON.stringify(dados),
  //     };
  
  //     const url = window.servidor + "/v1/protegido/filas/quantidades";
  
  //     fetch(url, requestOptions)
  //       .then((response) => {
  //         if(response.ok){
  //           return response.json();
  //         }
  //       })
  //       .then((data) => {
  //         resolve(data);
  //       })
  //       .catch((erro) => {
  //         reject(erro);
  //       });
  //   })
  // }

  getAllQtdByUnidadeUsuario() {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/filas/quantidades/getall";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getAllQtdByUnidadeUsuarioAndUsuario() {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      const url = window.servidor + "/v1/protegido/filas/quantidades/porusuario/getall";
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  setAtivarAnalise(id) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
  
      const url = window.servidor + "/v1/protegido/analise/ativar/" + id;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.text();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  setDesativarAnalise(id) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
  
      const url = window.servidor + "/v1/protegido/analise/desativar/" + id;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.text();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  setAtivarModeloAnalise(id) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
  
      const url = window.servidor + "/v1/protegido/modelosanalise/ativar/" + id;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.text();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  setDesativarModeloAnalise(id) {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();
  
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      };
  
      const url = window.servidor + "/v1/protegido/modelosanalise/desativar/" + id;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.text();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getFilas = (qparams) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      let searchParams = (qparams != null && qparams !== "") ? `?${qparams.toString()}` : "";
      const url = window.servidor + "/v1/protegido/filas/get" + searchParams;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getFilasIndividual = (qparams) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      let searchParams = (qparams != null && qparams !== "") ? `?${qparams.toString()}` : "";
      const url = window.servidor + "/v1/protegido/filas/porusuario/get" + searchParams;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaEmAnalise = (qparams) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      let searchParams = (qparams != null && qparams !== "") ? `?${qparams.toString()}` : "";
      const url = window.servidor + "/v1/protegido/filas/emanalise/getall" + searchParams;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaEmVerificacao = (qparams) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      let searchParams = (qparams != null && qparams !== "") ? `?${qparams.toString()}` : "";
      const url = window.servidor + "/v1/protegido/filas/emverificacao/getall" + searchParams;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaEmRevisao = (qparams) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      let searchParams = (qparams != null && qparams !== "") ? `?${qparams.toString()}` : "";
      const url = window.servidor + "/v1/protegido/filas/emrevisao/getall" + searchParams;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaEmVerificacaoRevisao = (qparams) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      let searchParams = (qparams != null && qparams !== "") ? `?${qparams.toString()}` : "";
      const url = window.servidor + "/v1/protegido/filas/emverificacaorevisao/getall" + searchParams;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaEmManifestacao = (qparams) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      let searchParams = (qparams != null && qparams !== "") ? `?${qparams.toString()}` : "";
      const url = window.servidor + "/v1/protegido/filas/emmanifestacao/getall" + searchParams;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaEmManifestacaoEdicao = (qparams) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      let searchParams = (qparams != null && qparams !== "") ? `?${qparams.toString()}` : "";
      const url = window.servidor + "/v1/protegido/filas/emmanifestacao/edicao/getall" + searchParams;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaEmManifestacaoVerificacao = (qparams) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      let searchParams = (qparams != null && qparams !== "") ? `?${qparams.toString()}` : "";
      const url = window.servidor + "/v1/protegido/filas/emmanifestacao/verificacao/getall" + searchParams;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaEmFinalizado = (qparams) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      let searchParams = (qparams != null && qparams !== "") ? `?${qparams.toString()}` : "";
      const url = window.servidor + "/v1/protegido/filas/emfinalizado/getall" + searchParams;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaTipoPermissao = () => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/usuario/tipospermissao";

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getTipoPermissao = (value) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/usuario/tipospermissao/get/" + value;

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaSetor = () => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/usuario/setores";

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getSetor = (value) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/usuario/setores/get/" + value;

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }


  getListaTipoAvisoLicitacao = () => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/avisolicitacao/tiposaviso";

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getTipoAvisoLicitacao = (value) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/avisolicitacao/tiposaviso/get/" + value;

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaCriterioJulgamento = () => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/avisolicitacao/criteriosjulgamento";

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getCriterioJulgamento = (value) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/avisolicitacao/criteriosjulgamento/get/" + value;

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaRegimeExecucao = () => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/avisolicitacao/regimesexecucao";

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getRegimeExecucao = (value) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/avisolicitacao/regimesexecucao/get/" + value;

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaTipoAjudicacao = () => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/avisolicitacao/tiposajudicacao";

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getTipoAjudicacao = (value) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/avisolicitacao/tiposajudicacao/get/" + value;

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaTipoProcedimentoLicitatorio = () => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/avisolicitacao/tiposprocedimentolicitatorio";

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getTipoProcedimentoLicitatorio = (value) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/avisolicitacao/tiposprocedimentolicitatorio/get/" + value;

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getAvisoLicitacao = (value) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/avisolicitacao/get/" + value;

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getContrato = (value) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/contrato/get/" + value;

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getAllContratosByNumeroProcesso = (value) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const dados = {
        numeroProcessoMae: value
      }

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(dados),
      };

      const url = window.servidor + "/v1/protegido/contrato/findall";

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaTipoAditivoContrato = () => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/contrato/tiposaditivocontrato";

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getTipoAditivoContrato = (value) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/contrato/tiposaditivocontrato/get/" + value;

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaTipoAquisicao = () => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/contrato/tiposaquisicao";

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getTipoAquisicao = (value) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/contrato/tiposaquisicao/get/" + value;

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaSubtipoAquisicao = () => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/contrato/subtiposaquisicao";

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getSubtipoAquisicao = (value) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/contrato/subtiposaquisicao/get/" + value;

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaUnidadesGestoras = () => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/contrato/unidadesgestoras";

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getUnidadesGestoras = (value) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/contrato/unidadesgestoras/get/" + value;

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getPesquisaDePrecos = (value) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/pesquisadeprecos/get/" + value;

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getPesquisaDePrecosPorAnalise = (value) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/pesquisadeprecos/find?analiseId=" + value;

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getPenalidade = (value) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/penalidade/get/" + value;

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getTipoPenalidade = (value) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/penalidade/tipospenalidade/get/" + value;

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }

  getListaTipoPenalidade = () => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      const url = window.servidor + "/v1/protegido/penalidade/tipospenalidade";

      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }


  getAnalisesAbertasIndividual = (qparams) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };
  
      let searchParams = (qparams != null && qparams !== "") ? `?${qparams.toString()}` : "";
      const url = window.servidor + "/v1/protegido/filas/porusuario/analisesabertas/get" + searchParams;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }


  getQtdAnalisesAbertasIndividual = (dias) => {
    return new Promise((resolve, reject) => {
      const token = Autenticar.getLocalToken();

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      };

      let d = dias != null && dias !== "" && dias instanceof Number ? dias : 21;
  
      const url = window.servidor + "/v1/protegido/analise/qtdAnalisesAbertas/" + d;
  
      fetch(url, requestOptions)
        .then((response) => {
          if(response.ok){
            return response.json();
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((erro) => {
          reject(erro);
        });
    })
  }





}




// const requisicaoInstance = new Requisicao();

// export default requisicaoInstance;

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new Requisicao();